import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import minus from '../../../asset/icon/btn_delect.svg';

const Btn = styled.button`  
    width: 19px;
    height: 19px;
    border: none;
    background-color:transparent;
    background-image: url(${minus});
    background-repeat: no-repeat;
    background-size: cover;
`;

function MinusBtn(props) {
    return (
        <Btn type={props.type} className={props.className} style={props.style} onClick={(e) => props.onClick(e)} ></Btn>        
    );
};

MinusBtn.propTypes = {
    /**
    * 버튼 타입
    */
    type: PropTypes.string,
    /**
    * class명
    */
    className: PropTypes.string,
    /**
    * 클릭 이벤트
    */
    onClick: PropTypes.func,
    /**
    * style 속성
    */
    style: PropTypes.object,
};

MinusBtn.defaultProps = {
    type : 'button',
    className : null,
    style : {},
    onClick : () => {}
};

export default MinusBtn;

