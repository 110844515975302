import { runInAction, observable, toJS } from "mobx";
import stepService from "@service/stepService";
import companyInfoModel from '@model/establish/companyInfoModel';
import smartformAppModel from "@model/smartformAppModel";
import formDataToJson from '@common/module/submit';

class viewModel {
    constructor() {
        this.state = observable({
            companyAddressModal : false,
            postAddressModal : false
        });
    }
    submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            param.companyAddress.address = companyInfoModel.companyAddress.address;
            if(formDataToJson(e.target).data.headOfficeDeliveryYn === 'false') {
                param.deliveryAddress.address = companyInfoModel.deliveryAddress.address;
            }
            stepService.saveStep(param);
        }
    }
    get smartFormType() {
        return smartformAppModel.smartFormType;
    }
    get companyAddressModal() {
        return this.state.companyAddressModal; 
    }
    get postAddressModal() {
        return this.state.postAddressModal; 
    }
    openCompanyAddressModal(openYn) {
        this.state.companyAddressModal = openYn;
    }
    openPostAddressModal(openYn) {
        this.state.postAddressModal = openYn;
    }
    get companyAddress() {
        return companyInfoModel.companyAddress;
    }
    companyAddressComplate(address) {
        runInAction(() => {
            companyInfoModel.companyAddress.address = address;
            companyInfoModel.companyAddress.detailAddress = "";
            companyInfoModel.companyAddress.nameOfBuilding = address.buildingName;
        })
        this.openCompanyAddressModal(false);
    }
    get deliveryAddress() {
        return companyInfoModel.deliveryAddress;
    }
    postAddressComplate(address) {
        runInAction(() => {
            companyInfoModel.deliveryAddress.address = address;
            companyInfoModel.deliveryAddress.detailAddress = "";
            companyInfoModel.deliveryAddress.nameOfBuilding = address.buildingName;
        })
        this.openPostAddressModal(false);
    }
    companyAddress_detailAddress(e) {
        companyInfoModel.companyAddress.detailAddress = e.target.value;
    }
    companyAddress_nameOfBuilding(e) {
        companyInfoModel.companyAddress.nameOfBuilding = e.target.value;
    }
    get companyAddress_bnameFontSizeChange() {
        const bName = companyInfoModel.companyAddress?.address?.bname;
        if(bName) {
            if(bName.length > 3) return true; //bname이 3글자보다 길면 true
            else return false;
        } else {
            return false;
        }
    }
    deliveryAddress_detailAddress(e) {
        companyInfoModel.deliveryAddress.detailAddress = e.target.value;
    }
    deliveryAddress_nameOfBuilding(e) {
        companyInfoModel.deliveryAddress.nameOfBuilding = e.target.value;
    }
    get deliveryAddress_bnameFontSizeChange() {
        const bName = companyInfoModel.deliveryAddress?.address?.bname;
        if(bName) {
            if(bName.length > 3) return true; //bname이 3글자보다 길면 true
            else return false;
        } else {
            return false;
        }
    }
    get headOfficeDeliveryYn() {
        return companyInfoModel.headOfficeDeliveryYn;
    }
    setHeadOfficeDeliveryYn(e) {
        companyInfoModel.headOfficeDeliveryYn = e.target.value === 'true';
    }
    get qnaContent() {
        const formType = this.smartFormType;
        switch(formType) {
            case 'ESTABLISHMENT_STOCK_COMPANY': return { name: '주주', officeYn: true, houseEstablish: true, houseRental: true };
            case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY': return { name: '주주', officeYn: false, houseEstablish: false, houseRental: false, smartFormTypeText: '농업회사법인' };
            case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY': return { name: '주주', officeYn: false, houseEstablish: false, houseRental: false, smartFormTypeText: '어업회사법인' };
            case 'ESTABLISHMENT_LIMITED_COMPANY': return { name: '출자자', officeYn: true, houseEstablish: true, houseRental: true };
            case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY': return { name: '출자자', officeYn: true, houseEstablish: true, houseRental: true };
        }
    }
}

export default new viewModel();