import { useObserver } from 'mobx-react';
import Cst from '../../../FinalCheck/FinalCheck.module.scss';
import clsx from 'clsx';
import { addCommas } from '@common/module/replaceNumber';

function StandbyParValueDivisionStep(props) {
    return useObserver(() => (
        <>
            <div className={clsx(Cst.row)}> 
                <div className={Cst.dName}>변경할 액면금</div>
                <div className={Cst.data}>
                    {addCommas(props.step.newParValue) + ' 원' || "-"}
                </div>
            </div>
            <div className={clsx(Cst.row)}> 
                <div className={Cst.dName}>스탠바이 입력<br/>내용 일치여부</div>
                <div className={Cst.data}>
                    {props.step.keepGoingYn ? "네" : "아니오"}
                </div>
            </div>
        </>
    ));
}
export default StandbyParValueDivisionStep;

