import { runInAction, observable, toJS } from "mobx";
import portalModel from '@model/portalModel';
import shareholderAndExecutiveInfoModel from "@model/establish/shareholderAndExecutiveInfoModel";
import companyInfoModel from "@model/establish/companyInfoModel";
import { onlyNumber } from '@common/module/replaceNumber';
import ResidenceRegistrationModal from '@pages/ModalContent/establish/Executive/ResidenceRegistrationModal';
import ForeignerCertificationModal from '@pages/ModalContent/establish/Executive/ForeignerCertificationModal';
import ExecutiveModal from '@pages/ModalContent/establish/Executive/ExecutiveModal';
import stepService from "@service/stepService";
import smartformAppModel from "@model/smartformAppModel";
import ExecutiveCntModal from '@pages/ModalContent/establish/Executive/ExecutiveCntModal';
import ExecutiveInvestmentModal from '@pages/ModalContent/establish/Executive/ExecutiveInvestmentModal';
import formDataToJson, { errorFocus, findPos }  from '@common/module/submit';
import regExp from '@common/module/regExp';
import { _alert, _confirm } from "@model/dialogModel";

class viewModel {
    constructor() {
        this.state = observable({
            openIdx : null,
            addressModal: false,  
            addressIdx: null,
            errorArr: []
        });
    }
    errorState() {
        const error = document.querySelectorAll('[data-error="error"]');
        let errorIdx = [];
        error.forEach((error) => {
            errorIdx.push(Number(error.closest('[data-eq]').dataset.eq))
        })
        errorIdx = errorIdx.filter((elem, idx) => {
            return errorIdx.indexOf(elem) === idx;
        })
        this.state.errorArr = errorIdx;
    }
    submit(e) {
        runInAction(() => {
            e.preventDefault();
            if(formDataToJson(e.target).error) {
                if(this.companySize === "OTHER") {
                    if(shareholderAndExecutiveInfoModel.executiveMembers.length > 1) {
                        this.state.openIdx = null;
                    }
                }
                this.errorState();
                this.state.openIdx = this.state.errorArr[0];
                const findError = setInterval(() => {
                    if(document.querySelector('.open')) {
                        clearInterval(findError);
                        errorFocus();
                    }
                }, 30)
                return
            } else {
                this.state.errorArr = [];
                this.state.openIdx = null;
                //주식회사, 농업회사법인, 어업회사법인의 회사 설립인원 3명 이상인 경우
                if(this.smartFormType === 'ESTABLISHMENT_STOCK_COMPANY' || this.smartFormType === 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY' || this.smartFormType === 'ESTABLISHMENT_FISHERY_STOCK_COMPANY') {
                    if(this.companySize === 'OTHER') {
                        //주식을 가지고 있지 않은 임원을 입력하지 않은 경우
                        let sharehoderIdCnt = 0;
                        shareholderAndExecutiveInfoModel.executiveMembers.forEach((executive, idx) => {
                            if(executive.shareholderId !== "new" && executive.shareholderId !== null) { 
                                sharehoderIdCnt++;
                            }
                        })
                        if(sharehoderIdCnt === shareholderAndExecutiveInfoModel.executiveMembers.length) return this.openModal('ExecutiveModal');
                        //설립 인원수가 3명 미만인 경우
                        // let shareholderCnt = 0, executiveCnt = 0; //주주, 임원
                        // shareholderAndExecutiveInfoModel.shareholders.forEach(() => {
                        //     shareholderCnt++; 
                        // })
                        // shareholderAndExecutiveInfoModel.executiveMembers.forEach(() => {
                        //     executiveCnt++;                        
                        // })
                        // if(shareholderCnt === 1 && executiveCnt < 2) return this.openModal('ExecutiveCntModal', shareholderCnt, executiveCnt); //설립 인원이 3명 미만인 경우
                        

                        //감사1명이상 사내이사3명이상이 아닌경우
                        let 감사Count = 0;
                        let 이사Count = 0;
                        shareholderAndExecutiveInfoModel.executiveMembers.forEach((executive) => {
                            if(executive.position === "감사") {
                                감사Count += 1;
                            }else{
                                이사Count += 1;
                            }
                        })
                        if(shareholderAndExecutiveInfoModel.capital >= 1000000000) {
                            if(감사Count<1 || 이사Count<3) {
                                portalModel.modalContent = ExecutiveInvestmentModal.modalContent;
                                portalModel.btn = ExecutiveInvestmentModal.btn ?? null;
                                portalModel.closeBtn = ExecutiveInvestmentModal.closeBtn ?? true;
                                return 
                            }
                        }   

                        
                        //이사에서 농업인이 3분의1이상이어야함.
                        let 농어업인Count = 0;
                        if(this.smartFormType === 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY' || this.smartFormType === 'ESTABLISHMENT_FISHERY_STOCK_COMPANY') {
                            shareholderAndExecutiveInfoModel.executiveMembers.forEach((executive) => {
                                if(executive.position !== "감사") {
                                    if(executive.shareholderId !== "new" || executive.isFarmerOrFisherman) {
                                        농어업인Count += 1;
                                    }
                                }
                            })
                            if(농어업인Count/이사Count < 0.33) {
                                return _alert('농업회사법인은 최소한 이사의 1/3이상이 농업인이어야 합니다. 이사님 중 농업인이 1/3이 되지 않으므로, 농업인인 이사를 추가해주세요. (감사는 이사에 포함하지 않으므로, 농업인인 감사를 추가하는 것은 불필요합니다.)');
                            }
                        }
                    }
                }
                //대표(단독대표, 각자대표, 공동대표) 선택시 불가능한 경우
                if(this.companySize === 'OTHER' || this.smartFormType === 'ESTABLISHMENT_LIMITED_COMPANY' || this.smartFormType === 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY') {
                    let single_representative = 0, each_representative = 0, union_representative = 0, 대표업무집행자 = 0, 공동대표업무집행자 = 0;//단독대표, 각자대표, 공동대표, 대표업무집행자, 공동대표업무집행자
                    shareholderAndExecutiveInfoModel.executiveMembers.forEach((executive) => {
                        if(executive.position === "단독대표") {
                            single_representative++;
                        }
                        else if(executive.position === "각자대표") {
                            each_representative++;
                        }
                        else if(executive.position === "공동대표") {
                            union_representative++;
                        }
                        else if(executive.position === "대표업무집행자") {
                            대표업무집행자++;
                        }
                        else if(executive.position === "공동대표업무집행자") {
                            공동대표업무집행자++;
                        }
                    })
                    if(!single_representative && !each_representative && !union_representative && !대표업무집행자 && !공동대표업무집행자) return _alert('대표(단독대표, 각자대표, 공동대표)는 1명 이상 있어야합니다.'); //대표가 1명도 없는 경우    
                    if(single_representative === 1 && (each_representative || union_representative || 대표업무집행자 || 공동대표업무집행자)) return _alert('대표(단독대표, 각자대표, 공동대표) 직책은 한 가지만 선택 가능합니다.'); //단독대표만 있지 않은 경우
                    if(each_representative > 1 && (single_representative || union_representative || 대표업무집행자 || 공동대표업무집행자)) return _alert('대표(단독대표, 각자대표, 공동대표) 직책은 한 가지만 선택 가능합니다.'); //각자대표 이외 대표가 있는 경우
                    if(union_representative > 1 && (single_representative || each_representative || 대표업무집행자 || 공동대표업무집행자)) return _alert('대표(단독대표, 각자대표, 공동대표) 직책은 한 가지만 선택 가능합니다.'); //단독대표 이외 대표가 있는 경우     
                    if(대표업무집행자 > 1 && (single_representative || each_representative || union_representative || 공동대표업무집행자)) return _alert('대표(단독대표, 각자대표, 공동대표) 직책은 한 가지만 선택 가능합니다.'); //단독대표 이외 대표가 있는 경우     
                    if(공동대표업무집행자 > 1 && (single_representative || each_representative || union_representative || 대표업무집행자)) return _alert('대표(단독대표, 각자대표, 공동대표) 직책은 한 가지만 선택 가능합니다.'); //단독대표 이외 대표가 있는 경우     
                    if(single_representative > 1) return _alert('단독대표는 한명만 가능합니다.'); //단독대표가 여러명인 경우
                    if(each_representative === 1) return _alert('각자대표는 2명 이상이어야 합니다.'); //각자대표가 1명인 경우
                    if(union_representative === 1) return _alert('공동대표는 2명 이상이어야 합니다.'); //공동대표가 1명인 경우  
                    if(대표업무집행자 === 1) return _alert('각자대표는 2명 이상이어야 합니다.'); //각자대표(대표업무집행자)가 1명인 경우  
                    if(공동대표업무집행자 === 1) return _alert('공동대표는 2명 이상이어야 합니다.'); //공동대표(공동대표업무집행자)가 1명인 경우  
                }
                //올바른 데이터를 입력한 경우
                let param = formDataToJson(e.target).data;
                param.executiveMembers.map((executiveMembers, idx) => {
                    if(executiveMembers.shareholderId === "new") { //새로운 분 선택시, sharholderId에 들어간 new 삭제
                        param.executiveMembers[idx].shareholderId = null;
                    }
                    if(executiveMembers.address) { //도로명주소 입력했을 경우, 주소 데이터 삽입
                        param.executiveMembers[idx].address = shareholderAndExecutiveInfoModel.executiveMembers[idx].address;
                    }
                    if(executiveMembers.koreanYn === "false") { //외국인 임원일 때, 입력한 생년월일 ex. 1997년3월10일 형태로 변경
                        param.executiveMembers[idx].birth = executiveMembers.year + '년' + executiveMembers.month + '월' + executiveMembers.day + '일';
                        delete executiveMembers.year; delete executiveMembers.month; delete executiveMembers.day;
                    }
                    if(executiveMembers.residenceRegistrationYn) { //거소등록여부 데이터 삭제
                        delete executiveMembers.residenceRegistrationYn;
                    }
                    if(executiveMembers.foreignerCertificationYn) { //인감증명서 또는 본인서명사실확인서 제출 여부 데이터 삭제
                        delete executiveMembers.foreignerCertificationYn;
                    }
                })
                stepService.saveStep(param);
            }
        })  
    }
    isFarmerOrFishermanDisplayYn(idx) {
        const executiveMember = shareholderAndExecutiveInfoModel.executiveMembers[idx];
        if((this.smartFormType === "ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY" || this.smartFormType === "ESTABLISHMENT_FISHERY_STOCK_COMPANY") && (executiveMember.shareholderId === "new" || this.companySize === "TWO")) {
            if(this.companySize === "TWO") {
                if(companyInfoModel.initiatorRole === "REPRESENTATIVE_AUDITOR") {
                    return false;
                }else{
                    return true;
                }
            }else{
                if((!executiveMember.position) || executiveMember.position === "감사") {
                    return false;
                }else{
                    return true;
                }
            }
            return true;
        }else{
            return false;
        }
    }
    openModal(content, shareholderCnt, executiveCnt) {
        let modal = {};
        let shareholder = 0, executive = 0;
        if(content === 'ResidenceRegistrationModal') {
            modal = ResidenceRegistrationModal;
        } 
        else if(content === 'ExecutiveModal') {
            modal = ExecutiveModal;
        }
        else if(content === 'ExecutiveCntModal'){
            modal = ExecutiveCntModal;
            shareholder = shareholderCnt;
            executive = executiveCnt;
        } 
        else if(content === 'ForeignerCertification') {
            modal = ForeignerCertificationModal;
        }
        runInAction(() => {
            portalModel.modalContent = content === 'ExecutiveCntModal' ? modal.modalContent(shareholder, executive) : modal.modalContent;
            portalModel.btn = modal.btn ?? null;
            portalModel.closeBtn = modal.closeBtn ?? true;
        })
    }
    get smartFormType() {
        return smartformAppModel.smartFormType;
    }
    get executiveMembers() {
        return shareholderAndExecutiveInfoModel.executiveMembers;
    }
    get shareholders() {
        return shareholderAndExecutiveInfoModel.shareholders;
    }
    get companySize() {
        return companyInfoModel.companySize;
    }
    get initiatorRole() { 
        return companyInfoModel.initiatorRole;
    }
    get executiveDefaultValue() {
        var val = {
            address: {},
            year: "",
            month: "",
            day: "",
            detailAddress: "",
            englishName:  "",
            id: "",
            koreanName: "",
            koreanYn: null,
            isFarmerOrFisherman : null,
            kssn: "",
            nameOfBuilding: "",
            nationalityOthers: "",
            nationalityType: "",
            position: "",
            resignationAfterEstablishmentYn: null,
            shareholderId: null,
            residenceRegistrationYn: null,
            foreignerCertificationYn: null
        }
        return val
    }
    addExecutive() {
        runInAction(() => {
            shareholderAndExecutiveInfoModel.executiveMembers.push(this.executiveDefaultValue)
            this.state.openIdx = this.executiveMembers.length-1;
            const newPerson = setInterval(() => {
                clearInterval(newPerson);
                if(this.state.openIdx !== 0) {
                    let top = findPos(document.querySelector('.open'));
                    window.scroll(0, top)
                }
            }, 30)
        })
    }
    get titleName() {
        let initiatorRole = this.initiatorRole;
        switch(initiatorRole) {
            case 'REPRESENTATIVE_DIRECTOR': return '이사님';
            case 'REPRESENTATIVE_AUDITOR': return '감사님';
            case 'REPRESENTATIVE_SHAREHOLDERS': case 'REPRESENTATIVE_STOCKED_DIRECTOR': case 'REPRESENTATIVE_STOCKED_AUDITOR': return '대표님';
            default: return '새로운 임원분';
        }
    }
    setTitle(idx) { //dropdown title
        const executive = shareholderAndExecutiveInfoModel.executiveMembers;
        let shareholderId = Number(executive[idx].shareholderId); //주주Id
        let position = executive[idx].position; //직책
        let koreanName = executive[idx].koreanName; //임원 이름
        let shareholderKoreanName = this.canExecutiveName(shareholderId); //주주 이름
        let title = '';
        if(this.smartFormType === 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY') { //유한책임회사일 때, 각자대표 공동대표 value 값 수정
            if(position === '대표업무집행자') {
                position = '각자대표';
            } else if(position === '공동대표업무집행자') {
                position = '공동대표';
            }
        }
        if(shareholderId) { //주주인 경우
            if(!position) {
                title = '직책을 선택해 주세요 : ' + shareholderKoreanName + `님 (${this.text.name})`;
            } else {
                title = position + ' : ' + shareholderKoreanName + `님 (${this.text.name})`;
            }
        }
        else { //새로운 분
            if(!position && !koreanName) {
                let initiatorRole = this.initiatorRole;
                switch(initiatorRole) {
                    case 'REPRESENTATIVE_DIRECTOR':
                        title = '이사님의 정보를 입력해 주세요.';
                        break;
                    case 'REPRESENTATIVE_AUDITOR':
                        title = '감사님의 정보를 입력해 주세요.';
                        break;
                    case 'REPRESENTATIVE_SHAREHOLDERS':
                    case 'REPRESENTATIVE_STOCKED_DIRECTOR':
                    case 'REPRESENTATIVE_STOCKED_AUDITOR':
                        title = '대표님의 정보를 입력해 주세요.';
                        break;
                    default:
                        title = (idx+1) + '번째 임원분의 정보를 입력해 주세요.';
                        break;
                }
            }
            else if(!position) {
                if(this.companySize === "TWO") {
                    let initiatorRole = this.initiatorRole;
                    switch(initiatorRole) {
                        case 'REPRESENTATIVE_DIRECTOR':
                            title = '이사' + ' : ' + koreanName;
                            break;
                        case 'REPRESENTATIVE_AUDITOR':
                            title = '감사' + ' : ' + koreanName;
                            break;
                        case 'REPRESENTATIVE_SHAREHOLDERS':
                        case 'REPRESENTATIVE_STOCKED_DIRECTOR':
                        case 'REPRESENTATIVE_STOCKED_AUDITOR':
                            title = '대표' + ' : ' + koreanName;
                            break;
                        default:
                            title = (idx+1) + '번째 임원분의 정보를 입력해 주세요.';
                            break;
                    }
                } else {
                    title = '직책을 선택해 주세요' + ' : ' + koreanName;
                }
            }
            else if(!koreanName) {
                title = position + ' : ' + '임원 (성함을 입력해 주세요.)';
            }
            else {
                    title = position + ' : ' + koreanName;
            }
        }
        return title;
    }
    async setCloseBtn(idx) { 
        const executive = shareholderAndExecutiveInfoModel.executiveMembers;
        let name = executive[idx].koreanName;
        if(!name) {
            name = '해당 임원';
        }
        
        if(await _confirm(name+'님의 정보를 삭제하시겠습니까?')) {
            if(executive.length === 1) {
                    runInAction(() => {
                        shareholderAndExecutiveInfoModel.executiveMembers = [];
                        shareholderAndExecutiveInfoModel.executiveMembers.push(this.executiveDefaultValue);
                    })
                
            } else {
                shareholderAndExecutiveInfoModel.executiveMembers.splice(idx,1);
            }
        }
    }
    setIsOpen(idx) {
        return this.state.openIdx === idx;
    }
    setDropOpen(idx) {
        if(this.state.openIdx === idx) {
            this.state.openIdx = null;
            this.errorState();
        } else {
            this.state.openIdx = idx;
        }
    }
    personErrorYn(idx) {
        return this.state.errorArr.indexOf(idx) !== -1;
    }
    canExecutive(shareholderId) { //임원이 될 수 있는 주주
        const can = [];
        shareholderAndExecutiveInfoModel.shareholders.map((shareholders) => { 
            if(shareholders.shareholderType === 'KOREAN_PERSON' || (shareholders.shareholderType === 'FOREIGNER_PERSON' && shareholders.residenceRegistrationYn === true)) {
                if(shareholders.id) {
                    can.push(shareholders.id)
                    if(Number(shareholderId) !== shareholders.id) {
                        shareholderAndExecutiveInfoModel.executiveMembers.map((executive) => {
                            if(Number(executive.shareholderId) === shareholders.id) {
                                return can.splice(can.indexOf(executive.shareholderId),1)
                            }
                        })
                    }
                }
            }  
        })
        return can;
    }
    setShareholderId(e, idx) { //주주Id
        const value = e.target.value;
        const shareholderId = shareholderAndExecutiveInfoModel.executiveMembers[idx].shareholderId;
        if(shareholderId) {
            if(shareholderId !== value) {
                shareholderAndExecutiveInfoModel.executiveMembers[idx] = this.executiveDefaultValue;
            } 
        }
        shareholderAndExecutiveInfoModel.executiveMembers[idx].shareholderId = value;
    }
    canExecutiveName(id) { //주주이름
        let koreanName = '';
        shareholderAndExecutiveInfoModel.shareholders.forEach((shareholders) => {
            if(shareholders.id === Number(id)) {
                koreanName = shareholders.koreanName;
            }
        })
        return koreanName;
    }
    shareholderKoreanYn(id) {
        let koreanYn = null;
        shareholderAndExecutiveInfoModel.shareholders.forEach((shareholders) => {
            if(shareholders.id === Number(id)) {
                if(shareholders.shareholderType === 'FOREIGNER_PERSON') {
                    koreanYn = false;
                }
            }
        })
        return koreanYn;
    }
    setKoreanName(e, idx) { //이름
        shareholderAndExecutiveInfoModel.executiveMembers[idx].koreanName = e.target.value;
    }
    setIsFarmerOrFisherman(e, idx) { //농어업인 여부
        shareholderAndExecutiveInfoModel.executiveMembers[idx].isFarmerOrFisherman = e.target.value === "true";
    }
    setKoreanYn(e, idx) { //한국인 여부
        shareholderAndExecutiveInfoModel.executiveMembers[idx].koreanYn = e.target.value === "true";
    }
    setKssn(e, idx) { //주민등록번호
        shareholderAndExecutiveInfoModel.executiveMembers[idx].kssn = e.target.value;
    }
    setPosition(e, idx) { //직책
        shareholderAndExecutiveInfoModel.executiveMembers[idx].position = e.target.value;
    }
    //도로명 주소
    openAddressModal(openYn, idx) {
        runInAction(() => {
            this.state.addressIdx = idx;
            this.state.addressModal = openYn;
        })
    }
    get addressModal() {
        return this.state.addressModal; 
    }
    get addressIdx() {
        return this.state.addressIdx;
    }
    koreanAddressComplate(address) {
        runInAction(() => {
            shareholderAndExecutiveInfoModel.executiveMembers[this.addressIdx].address = address;
            shareholderAndExecutiveInfoModel.executiveMembers[this.addressIdx].detailAddress = "";
            shareholderAndExecutiveInfoModel.executiveMembers[this.addressIdx].nameOfBuilding = address.buildingName;
        })
        this.openAddressModal(false,this.addressIdx);
    }
    koreanAddress_detailAddress(e,idx) { //상세주소
        shareholderAndExecutiveInfoModel.executiveMembers[idx].detailAddress = e.target.value;
    }
    koreanAddress_nameOfBuilding(e,idx) { //건물명
        shareholderAndExecutiveInfoModel.executiveMembers[idx].nameOfBuilding = e.target.value;
    }
    koreanAddress_bnameFontSizeChange(idx) {
        const bName = shareholderAndExecutiveInfoModel.executiveMembers[idx].address.bname;
        if(bName) {
            if(bName.length > 3) return true; //bname이 3글자보다 길면 true
            else return false;
        } else {
            return false;
        }
    }
    residenceRegistrationYn(idx) { //거소등록 여부
        return shareholderAndExecutiveInfoModel.executiveMembers[idx].residenceRegistrationYn;
    }
    setResidenceRegistrationYn(e, idx) { //거소등록 여부
        shareholderAndExecutiveInfoModel.executiveMembers[idx].residenceRegistrationYn = e.target.value === "true";
    }
    setForeignerCertificationYn(e, idx) { //인감증명서 또는 본인서명사실확인서 제출 여부
        shareholderAndExecutiveInfoModel.executiveMembers[idx].foreignerCertificationYn = e.target.value === "true";
    }
    setNationalityType(e, idx) { //국적 선택
        shareholderAndExecutiveInfoModel.executiveMembers[idx].nationalityType = e.target.value;
    }
    setNationalityOthers(e, idx) { //국적 입력
        shareholderAndExecutiveInfoModel.executiveMembers[idx].nationalityOthers = e.target.value;
    }
    setEnglishName(e, idx) {  //로마자 성함 입력
        shareholderAndExecutiveInfoModel.executiveMembers[idx].englishName = e.target.value;
    }
    setYear(e, idx){ //연도
        shareholderAndExecutiveInfoModel.executiveMembers[idx].year = onlyNumber(e.target.value);
    }
    setMonth(e, idx){ //월
        shareholderAndExecutiveInfoModel.executiveMembers[idx].month = onlyNumber(e.target.value);
    }
    monthBlur(e,idx) { 
        let month = e.target.value;
        if(month && month.length===1){
            e.target.value = "0" + month;
        }
        shareholderAndExecutiveInfoModel.executiveMembers[idx].month = e.target.value;
    }
    setDay(e, idx){ //일(날짜)
        shareholderAndExecutiveInfoModel.executiveMembers[idx].day = onlyNumber(e.target.value);
    }
    dayBlur(e,idx) {
        let day = e.target.value;
        if(day && day.length===1){
            e.target.value = "0" + day;
        }
        shareholderAndExecutiveInfoModel.executiveMembers[idx].day = e.target.value;
    }
    setResignationAfterEstablishmentYn(e, idx){ //설립직후 사임 여부
        shareholderAndExecutiveInfoModel.executiveMembers[idx].resignationAfterEstablishmentYn = e.target.value === "true";
    }
    get text() { //폼타입별 문구
        const formType = this.smartFormType;
        switch(formType) {
            case 'ESTABLISHMENT_STOCK_COMPANY': return { formType: '주식회사', name: '주주', director: '사내이사'}
            case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY': return { formType: '농업회사법인', name: '주주', director: '사내이사', person: '농업인' }
            case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY': return { formType: '어업회사법인', name: '주주', director: '사내이사', person: '어업인'}
            case 'ESTABLISHMENT_LIMITED_COMPANY': return { formType: '유한회사', name: '출자자', director: '이사'}
            case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY': return { formType: '유한책임회사', name: '출자자', director: '업무집행자'}
        }
    }
    //임원에서 새로운 분만 입력하는 경우_주주 중에서 한국인, 거소등록 외국인이 없는 경우(임원이 될 수 있는 주주가 없는 경우)
    get isOnlyNew() { 
        if(this.companySize === 'OTHER' || this.smartFormType === 'ESTABLISHMENT_LIMITED_COMPANY' || this.smartFormType === 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY') {
            let canExecutive = 0;
            shareholderAndExecutiveInfoModel.shareholders.forEach((shareholders, idx) => {
                if(shareholders.shareholderType === 'KOREAN_PERSON' || (shareholders.shareholderType === 'FOREIGNER_PERSON' && shareholders.residenceRegistrationYn === true)) {
                    canExecutive++;
                }
            }) 
            if(canExecutive > 0) {
                return false;
            } else {
                return true;
            }
        } else if(this.companySize === 'TWO') {
            return true;
        } 
    }
    get addYn() { //설립인원 3명이상이거나 유한회사, 유한책임회사일 경우_임원 추가버튼, 삭제버튼
        if(this.companySize === 'OTHER') return true;
        else if(this.smartFormType === 'ESTABLISHMENT_LIMITED_COMPANY') return true;
        else if(this.smartFormType === 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY') return true;
        else return false;
    }
    get positionList() { //직책
        const formType = this.smartFormType;
        switch(formType) {
            case 'ESTABLISHMENT_STOCK_COMPANY': 
            return [{text: '단독 대표', value: '단독대표'}, {text: '각자 대표(대표 2명 이상)', value: '각자대표'}, {text: '공동 대표(대표 2명 이상)', value: '공동대표'}, {text: '사내이사', value: '사내이사'}, {text: '감사', value: '감사'}];
            case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY': return [{text: '단독 대표', value: '단독대표'}, {text: '각자 대표(대표 2명 이상)', value: '각자대표'}, {text: '공동 대표(대표 2명 이상)', value: '공동대표'}, {text: '사내이사', value: '사내이사'}, {text: '감사', value: '감사'}];
            case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY': return [{text: '단독 대표', value: '단독대표'}, {text: '각자 대표(대표 2명 이상)', value: '각자대표'}, {text: '공동 대표(대표 2명 이상)', value: '공동대표'}, {text: '사내이사', value: '사내이사'}, {text: '감사', value: '감사'}];
            case 'ESTABLISHMENT_LIMITED_COMPANY': return [{text: '단독 대표', value: '단독대표'}, {text: '각자 대표(대표 2명 이상)', value: '각자대표'}, {text: '공동 대표(대표 2명 이상)', value: '공동대표'}, {text: '이사', value: '이사'}, {text: '감사', value: '감사'}];
            case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY': return [{text: '단독 대표', value: '단독대표'}, {text: '각자 대표(대표 2명 이상)', value: '대표업무집행자'}, {text: '공동 대표(대표 2명 이상)', value: '공동대표업무집행자'}, {text: '업무집행자', value: '업무집행자'}];
        }
    }
    executiveTipsDisplayYn(idx) { //임원 등록하기 tips
        const formType = this.smartFormType;
        if(this.executiveMembers[idx].shareholderId !== "new" || this.isOnlyNew) {
            switch(formType) {
                case 'ESTABLISHMENT_STOCK_COMPANY': return true;
                case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY': return true;
                case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY': return true;
                case 'ESTABLISHMENT_LIMITED_COMPANY': return false;
                case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY': return false;
            }
        } 
    }
    addressDisplayYn(idx) { //도로명 주소 입력
        const executive = this.executiveMembers[idx];
        if(executive.position === '단독대표' || executive.position === '각자대표' || executive.position === '공동대표' || executive.position === '대표업무집행자' || executive.position === '공동대표업무집행자') return true;
        else if((executive.koreanYn || executive.residenceRegistrationYn) && (this.initiatorRole === 'REPRESENTATIVE_SHAREHOLDERS' || this.initiatorRole === 'REPRESENTATIVE_STOCKED_DIRECTOR' || this.initiatorRole === 'REPRESENTATIVE_STOCKED_AUDITOR')) return true;
        else return false;
    }
    resignationDisplayYn(idx) { //조사보고자 사임 여부
        const executive = this.executiveMembers[idx];
        if((executive.koreanYn || executive.residenceRegistrationYn) && (this.initiatorRole === 'REPRESENTATIVE_DIRECTOR' || this.initiatorRole === 'REPRESENTATIVE_AUDITOR')) return true;
        else return false;
    } 
    positionDisplayYn(idx) { //직책 선택
        const executive = this.executiveMembers[idx];
        if(executive.koreanYn || executive.residenceRegistrationYn) {
            if(this.companySize === 'OTHER') return true;
            else if(this.smartFormType === 'ESTABLISHMENT_LIMITED_COMPANY') return true;
            else if(this.smartFormType === 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY') return true;
        }
        else return false;
    }
    positionExecutiveName(idx) { //직책 선택시 임원 이름
        const shareholderId = this.executiveMembers[idx].shareholderId;
        const koreanName = this.executiveMembers[idx].koreanName;
        if(shareholderId !== "new" && shareholderId) {
            return this.canExecutiveName(shareholderId)+'님';
        }        
        else {
            if(koreanName) return koreanName+'님';
            else return this.titleName;
        }
    }
    displayBirthErrorMessage(idx, type) { 
        const year = this.executiveMembers[idx].year;
        const month = this.executiveMembers[idx].month;

        if(type === 'month') {
            if(year && regExp.year().test(year)) { //연도가 제대로 입력되었을 때, 월에 대한 error 메시지
                return true;
            } else {
                return false;
            }
        } else if(type === 'day') {
            if(year && regExp.year().test(year) && month && regExp.month().test(month)) { // 연도, 월이 제대로 입력 되었을 때, 일(날짜)에 대한 error 메시지
                return true;
            } else {
                return false;
            }
        }
    }
}

export default new viewModel();