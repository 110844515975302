import { toJS, runInAction, observable } from "mobx";
import passwordService from '@service/changeRegistration/passwordService';
import passwordModel from '@model/changeRegistration/passwordModel';
import formDataToJson from '@common/module/submit';
import smartformAppModel from '@model/smartformAppModel';
import portalModel from '@model/portalModel';
import PasswordModal from '@pages/ModalContent/changeRegistration/Password/PasswordModal';

class viewModel {
    constructor() {
        this.state = observable({
            canSubmit : false
        });
    }
    get hasPassword() {
        return passwordModel.hasPassword;
    }
    setNewPassword(e) {
        passwordModel.newPassword = e.target.value;
    }
    get newPassword() {
        return passwordModel.newPassword;
    }
    setPasswordCheck(e) {
        passwordModel.passwordCheck = e.target.value;
    }
    setPassword(e) {
        passwordModel.password = e.target.value;
    }
    get passwordCheck() {
        return passwordModel.passwordCheck;
    }
    get password() {
        return passwordModel.password;
    }
    isSame() {
        if(this.passwordCheck === this.newPassword) {
            return true;
        }else{
            return false;
        }
    }
    canSubmit() {
        let result = false;
        const error = document.querySelectorAll('input[data-error="error"]');
        const yet = document.querySelectorAll('input[data-error="yet"]');
        if(error.length === 0 && yet.length === 0) {
            result = true;
        }
        this.state.canSubmit = result;
    }
    async submit(e) {
        e.preventDefault();
        let token;
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            if(this.hasPassword) {
                token = await passwordService.checkPassword(param.password)
            }else{
                token = await passwordService.setPassword(param.passwordCheck, param.newPassword)
            }
        }

        if(token) {
            this.setToken(token)
        }
    }
    setToken(token) {
        smartformAppModel.setUserToken(token);
        sessionStorage.setItem('userToken', token);
    }
    forgetPassWordPopUp() {
        const modal = PasswordModal;
        runInAction(() => {
            portalModel.modalContent = modal.modalContent;
            portalModel.btn = modal.btn ?? null;
            portalModel.closeBtn = modal.closeBtn ?? true;
        })
    }
    get sendEmailLoading() {
        return passwordModel.sendEmailLoading;
    }
}

export default new viewModel();