import { useObserver } from 'mobx-react';
import vm from './StandbyParValueDivisionCheckVm';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './StandbyParValueDivisionCheck.module.scss';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import { addCommas } from '@common/module/replaceNumber';

function StandbyParValueDivisionCheck() {
    return useObserver(() => (
        <form onSubmit={(e) => vm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            <Question>
                <ContentBox className={Qst.spaceBetween}>
                    <div className={Qst.infoSection}>
                        <div className={Qst.titleWrap}>
                            <h2 className={Qst.title}>아래는 고객님이 <span className={Qst.bold}>스탠바이</span>에서 입력하신 내용입니다.</h2>
                        </div>
                        <table className={st.parValueTable}>
                            <thead>
                                <tr>
                                    <th>등기부상 액면금</th>
                                    <th>변경 후 액면금</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{addCommas(vm.parValueOnRegister)}원 / 1주</td>
                                    <td>{addCommas(vm.newParValue)}원 / 1주</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ContentBox>
            </Question>
            <Question>
                <ContentBox className={Qst.spaceBetween}>
                    <div className={Qst.infoSection}>
                        <div className={Qst.titleWrap}>
                            <h2 className={Qst.title}><span className={Qst.bold}>위 내용대로 진행하는 것이 맞나요?</span></h2>
                        </div>
                        <ul className={Qst.answerList}>
                            <li>
                                <Radio className={Qst.answerLabel} value={true} activeBackground checked={vm.keepGoingYn === true} onChange={(e) => vm.setKeepGoingYn(e)} name="keepGoingYn">네. 위 내용대로 진행합니다.</Radio>
                            </li>
                            <li><Radio className={Qst.answerLabel} value={false} activeBackground checked={vm.keepGoingYn === false} onChange={(e) => vm.setKeepGoingYn(e)} name="keepGoingYn">아니오. 위 내용과 다르게 진행합니다.</Radio></li>
                        </ul>
                        <Error name="keepGoingYn" value={vm.keepGoingYn} errorCase={{required:'진행하실 방법을 선택해주세요.'}}/>
                    </div>
                </ContentBox>
            </Question>
        </form>
    ));
}
export default StandbyParValueDivisionCheck;