import { toJS } from 'mobx';
import { Fragment } from 'react';
import { useObserver } from 'mobx-react';
import clsx from 'clsx';
import st from '../FileUploadStep.module.scss';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import Icon from '@comComponents/atoms/Icon/Icon';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import Upload from '@comComponents/molecules/FileUpload/FileUpload';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';

function StockholdersList(props) {
    return useObserver(() => (
        <Question>
            <ContentBox className={Qst.spaceBetween}>
                {props.item.details.map((detail, idx2) => (
                    <Fragment key={idx2}>
                        <div className={Qst.infoSection}>
                            <div className={Qst.titleWrap}>
                                <h2 className={Qst.title}>
                                    <span className={Qst.bold}>기존 주주명부</span>를 업로드해 주세요.
                                </h2>
                            </div>
                            <div className={st.stockholdersListInfoBox}>
                                <img className={st.stockholdersListInfoImg} src="/images/stockholdersList_info.png" alt="주주명부 안내" />
                            </div>
                            {detail.fileType === '주주명부_서면결의' &&
                                <div className={st.stockholdersListBox}>
                                    <img className={st.stockholdersListBoxImg} src="/images/stockholdersList.png"
                                    alt="주주명부 예시" />
                                </div>
                            }
                            {(detail.fileType === '주주명부_공증' || detail.fileType === '주주명부_서면결의') &&
                                <>
                                    <div className={st.stockholdersFileInfoBox}>
                                        <img className={st.stockholdersFileInfoImg} src="/images/stockholders_file_info.png" alt="파일 확장자 안내" />
                                    </div>
                                    <Upload className={clsx(st.fileUpload, `${detail.fileType}`)} multiple
                                    model={props.parentVm.fileGroupTypes[props.idx].details[idx2].files}
                                    uploadModel={props.parentVm.fileGroupTypes[props.idx].details[idx2].uploadFiles}
                                    deleteModel={props.parentVm.deleteIds}
                                    files={toJS(props.parentVm.files(props.idx, idx2))} uploadFiles={toJS(props.parentVm.uploadFiles(props.idx, idx2))}     
                                    explainText={detail.fileType === '주주명부_서면결의' && <div><span className="bold">모든 주주의 주민/법인등록번호</span>가 기재된 파일이 필요합니다.</div>}
                                    customEx={<li>워드 / 한글 / PDF를 권장합니다.</li>}
                                    >
                                        <span className={st.bold}>여기</span>를 눌러 업로드해 주세요.
                                    </Upload>
                                    {(props.parentVm.state.errorCheckYn && props.parentVm.displayErrorText(detail.fileType)) && <ErrorText>기존 주주명부를 업로드해 주세요.</ErrorText>}
                                </> 
                            }
                        </div>
                        <div className={Qst.qnaSection}>
                            <Qna className={Qst.noTitle}>
                                <div className={Qst.qnaBox}>
                                    <QText>기존 주주명부란 어떤 것을 말하나요?</QText>
                                    <div className="answerBox">
                                        <Answer className={Qst.answer}>현재 시점의 주주명부를 말합니다.</Answer>
                                        <TextBox className={Qst.textBox} title="예시">
                                            <div className={clsx(Qst.textWrap, st.textWrap)}>
                                                <span className={st.text}>이번에 헬프미에 신주발행<br/>업무를 의뢰한 경우</span><Icon icon="arrow" className={st.arrow}/><span className={st.bold}>신주발행이 반영되지<br/>않은 주주명부</span>
                                            </div>
                                            <div className={clsx(Qst.textWrap, st.textWrap)}>
                                                <span className={st.text}>변경 일자(결의 일자)가<br/>과거로 정해진 경우</span><Icon icon="arrow" className={st.arrow}/><span className={st.bold}>해당 변경 일자<br/>기준의 주주명부</span>
                                            </div>
                                        </TextBox>
                                    </div>
                                </div>
                                {detail.fileType === '주주명부_서면결의' && 
                                    <div className={Qst.qnaBox}>
                                        <QText>모든 주주의 주민/법인등록번호는 왜 필요한가요?</QText>
                                        <div className="answerBox">
                                            <Answer className={Qst.answer}>등기소에서 등기를 진행할 때 모든 주주의 주민/법인등록번호를 요청합니다.</Answer>
                                        </div>
                                    </div>
                                }
                            </Qna>
                        </div>
                    </Fragment>
                ))}
            </ContentBox>
        </Question>
    ));
}

export default StockholdersList;