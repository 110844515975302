class AddressVm {
    displayKoreanName(item, originYn) {
        if(originYn) { //기존 임원
            return item.koreanName;
        } else { //신규 임원
            if(item.koreanName) {
                return item.koreanName;
            } else {
                return `신규 ${item.newPosition}`;
            }
        }
    }
    bnameFontSizeChange(item, idx) {
        const bName = item.newAddresses[idx].bname;
        if(bName) {
            if(bName.length > 3) return true; //bname이 3글자보다 길면 true
            else return false;
        } else {
            return false;
        }
    }    
}

export default new AddressVm();