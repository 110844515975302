import { useObserver } from 'mobx-react';
import ParValue from './ParValue/ParValue';
import Shareholder from './Shareholder/Shareholder';
import ShareholderInvestment from './ShareholderInvestment/ShareholderInvestment';
import Executive from './Executive/Executive';
import { Route } from "react-router-dom";

function ShareholderAndExecutiveInfo() {
  return useObserver(() => (
    <>
        {/* 1주당 금액 */}
        <Route path="/step/establish/ShareholderAndExecutiveInfo/ParValue" component={ParValue}></Route>
        {/* 주주 */}
        <Route path="/step/establish/ShareholderAndExecutiveInfo/Shareholder" component={Shareholder}></Route>
        {/* 주주 투자금 */}
        <Route path="/step/establish/ShareholderAndExecutiveInfo/ShareholderInvestment" component={ShareholderInvestment}></Route>
        {/* 임원 */}
        <Route path="/step/establish/ShareholderAndExecutiveInfo/Executive" component={Executive}></Route>
    </>
  ));
}

export default ShareholderAndExecutiveInfo;
