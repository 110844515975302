import { useObserver } from 'mobx-react';
import TCAgreeAndDeliveryAddressVm from './TCAgreeAndDeliveryAddressVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import clsx from 'clsx';
import st from './TCAgreeAndDeliveryAddress.module.scss';
import Check from '@comComponents/atoms/Input/Check/Check';
import HyperText from '@comComponents/atoms/HyperLink/HyperLink/HyperLink';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import SquareBtn from '@comComponents/atoms/Button/SquareBtn/SquareBtn';
import SearchAddress from '@comComponents/atoms/SearchAddress/SearchAddress';
import regExp from '@common/module/regExp';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import { Fragment } from 'react';
import { toJS } from 'mobx';

function TCAgreeAndDeliveryAddress() {
    return useObserver(() => {
        if(TCAgreeAndDeliveryAddressVm.tcagreements.length) {
            return (
                <form onSubmit={(e) => {TCAgreeAndDeliveryAddressVm.submit(e)}}>
                    <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
                    <Question>
                        <ContentBox>
                            <div className={Qst.spaceBetween}>
                                <div className={Qst.infoSection}>
                                    <div className={Qst.titleWrap}>
                                        <h2 className={Qst.title}>
                                            아래<span className={Qst.bold}> 이용약관 동의</span>를 부탁드립니다.
                                        </h2>
                                    </div>
                                    <div className={clsx(st.agreeBox, TCAgreeAndDeliveryAddressVm.agreeAll ? st.allActive : null)} key={TCAgreeAndDeliveryAddressVm.agreeAll}>
                                        <Check className={clsx(st.allCheck, TCAgreeAndDeliveryAddressVm.agreeAll ? st.active : null)} style={{top:'3px'}} boxType="circle" checked={TCAgreeAndDeliveryAddressVm.agreeAll} onChange={(e) => TCAgreeAndDeliveryAddressVm.setAgreeAll(e)}>전체동의</Check>
                                        <ul className={st.checkList}>
                                            {TCAgreeAndDeliveryAddressVm.tcagreements.map((item, idx)=> {
                                            return (
                                                <Fragment key={idx}>
                                                <li>
                                                    <input type="hidden" name={`tcagreements[${idx}].id`} value={item.id}/>
                                                    <input type="hidden" name={`tcagreements[${idx}].termId`} value={item.termId}/>
                                                    <Check className={clsx(st.check, item.agreeYn ? st.active : null)} style={{top:'3px'}} 
                                                    requiredText={item.required} boxType="circle" 
                                                    name={`tcagreements[${idx}].agreeYn`} 
                                                    value={item.agreeYn ?? false} 
                                                    checked={item.agreeYn === true} 
                                                    onChange={(e) => TCAgreeAndDeliveryAddressVm.setAgreeYn(e, idx)}>{item.required ? '헬프미 법인등기 위임계약 약관' : '법인운영에 유용한 뉴스레터 등 홍보 약관'}</Check>
                                                    <HyperText title="약관보기로 이동" className={st.hyper} onClick={() => TCAgreeAndDeliveryAddressVm.openModal(item.required ? 'required' : 'unRequired')}>약관보기</HyperText>
                                                </li>
                                                </Fragment>
                                            )
                                            })}
                                        </ul>
                                    </div>
                                    <Error name="tcagreements[0].agreeYn" value={TCAgreeAndDeliveryAddressVm.tcagreements[0].agreeYn} errorCase={{required:'헬프미 법인등기 위임계약 약관에 동의해 주세요.', type: 'boolean'}} />
                                </div>
                            </div>
                            <div className={Qst.spaceBetween}>
                                <div className={Qst.infoSection}>
                                    <div className={Qst.titleWrap}>
                                        <h2 className={Qst.title}>
                                            등기 완료 후 <span className={Qst.bold}>우편물을 받을 주소</span>를 알려주세요.
                                        </h2>
                                        <div className={Qst.titleExplain}>등기완료 후 72시간 내에 등기부등본(발급용) 등 서류를 빠른 등기로 보내드립니다.</div>
                                        <div className={Qst.titleExplain}>세금계산서 영수증 등은 이메일로 보내드립니다.</div>
                                    </div>
                                    <ul className={Qst.answerList}>
                                        <li><Radio className={Qst.answerLabel} value={true} activeBackground checked={TCAgreeAndDeliveryAddressVm.deliveryToCompanyAddressYn === true} onChange={(e) => TCAgreeAndDeliveryAddressVm.setDeliveryToCompanyAddressYn(e)} name="deliveryToCompanyAddressYn">현재 등기부상 주소 :<br/>{TCAgreeAndDeliveryAddressVm.originAddress}</Radio></li>
                                        <li><Radio className={Qst.answerLabel} value={false} activeBackground checked={TCAgreeAndDeliveryAddressVm.deliveryToCompanyAddressYn === false} onChange={(e) => TCAgreeAndDeliveryAddressVm.setDeliveryToCompanyAddressYn(e)} name="deliveryToCompanyAddressYn">그 밖의 주소</Radio></li>
                                    </ul>
                                    <Error name="deliveryToCompanyAddressYn" value={TCAgreeAndDeliveryAddressVm.deliveryToCompanyAddressYn} errorCase={{required:'우편물 받을 주소를 선택해 주세요.'}} />
                                    {TCAgreeAndDeliveryAddressVm.deliveryToCompanyAddressYn === false && 
                                    <>
                                        <div className={Qst.rowWrap}>
                                            <InputText className={Qst.inputText} style={{width:'380px'}} explain="도로명 주소" value={TCAgreeAndDeliveryAddressVm.address?.address || ''} readOnly onClick={() => TCAgreeAndDeliveryAddressVm.openAddressModal(true)}  name="deliveryAddress" errorText={<Error name="deliveryAddress" value={TCAgreeAndDeliveryAddressVm.address?.address} errorCase={{required:'도로명 주소를 입력해 주세요.'}} />}/>
                                            <SquareBtn onClick={() => TCAgreeAndDeliveryAddressVm.openAddressModal(true)}>도로명 주소 찾기</SquareBtn>
                                            {TCAgreeAndDeliveryAddressVm.state.addressModal && 
                                                <SearchAddress onClose={() => TCAgreeAndDeliveryAddressVm.openAddressModal(false)} onComplete={(data) => TCAgreeAndDeliveryAddressVm.addressComplate(data)}></SearchAddress>
                                            }
                                        </div>
                                        <div className={Qst.rowWrap}>
                                            <InputText className={Qst.inputText} style={{width:'273px'}} explain="상세 주소" placeholder="상세주소 없을 시, [없음] 입력" value={TCAgreeAndDeliveryAddressVm.detailAddress} onChange={(e) => TCAgreeAndDeliveryAddressVm.setDetailAddress(e)} name="detailAddress" errorText={<Error name="detailAddress" value={TCAgreeAndDeliveryAddressVm.detailAddress} errorCase={{
                                                required:'상세 주소를 입력해 주세요.',
                                                pattern : {
                                                    value: regExp.onlyKorNum_hyphen_comma().test(TCAgreeAndDeliveryAddressVm.detailAddress),
                                                    message: <span>임대차 계약서상 영문이 있다면, <br/>한글로 옮겨적어주세요.<br/>EX) B동 &gt; 비동</span>
                                                }
                                            }}/>} />
                                        <div className={Qst.symbol}>
                                            <span className={Qst.symbolIcon}>&#40;&nbsp;</span><span className={clsx(Qst.symbolText, TCAgreeAndDeliveryAddressVm.bnameFontSizeChange ? Qst.fontSize : null)}>{TCAgreeAndDeliveryAddressVm.address?.bname || '법정동'},&nbsp;</span><InputText style={{width:'200px'}} explain="건물명" placeholder="없을 시, [없음] 입력" value={TCAgreeAndDeliveryAddressVm.nameOfBuilding} onChange={(e) => TCAgreeAndDeliveryAddressVm.setNameOfBuilding(e)} name="nameOfBuilding" errorText={<Error name="nameOfBuilding" value={TCAgreeAndDeliveryAddressVm.nameOfBuilding} style={{width:'260px'}} errorCase={{
                                                required:'건물명을 입력해 주세요.',
                                                pattern : {
                                                    value: regExp.onlyKorNum_hyphen_comma().test(TCAgreeAndDeliveryAddressVm.nameOfBuilding),
                                                    message: <span>건물명이 영문인 경우, 한글<br/>발음으로 옮겨적어 주세요. <br/>EX) AB타워 &gt; 에이비 타워</span>
                                                }
                                            }}/>} /><span className={Qst.symbolIcon}>&nbsp;&#41;</span>
                                            </div>
                                        </div>
                                    </>
                                }
                                </div>
                            </div>
                        </ContentBox>
                    </Question>
                </form>
            )
        }else{
            return <></>
        }
        
    });
}
export default TCAgreeAndDeliveryAddress;