import { useObserver } from 'mobx-react';
import FileUpload from './FileUpload/FileUpload';
import { Route } from "react-router-dom";

function FileUploadStep() {
    return useObserver(() => (
        <>
            {/* 파일업로드 */}
            <Route path="/step/changeRegistration/FileUploadStep/FileUpload" component={FileUpload}></Route>
        </>
    ));
}

export default FileUploadStep;