import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TextBoxWrap = styled.div`
    display: inline-flex;
`;

const Title = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: ${(props) => props.titleWidth};
    background-color: #d4d8dc; 
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: ${(props) => props.warn === true ? '#eb4d59' : '#6c6c6c'};
    font-size: 14px;
    font-weight: 500;
    
`;
const Content = styled.div`
    flex-grow: 1;
    padding: 18px 20px;
    background-color: #e9ecf0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 15px;
    font-weight: 500; 
    
`;

function TextBox(props) {
    return (
        <TextBoxWrap className={props.className}>
            <Title titleWidth={props.titleWidth} warn={props.warn}>
                {props.title}
            </Title>
            <Content style={props.style}>
                {props.children}
            </Content>
        </TextBoxWrap>
    );
};

TextBox.propTypes = {
    /**
    * class명 
    */
    className: PropTypes.string,
    /**
    * style 속성
    */
    style: PropTypes.object,
    /**
    * title 
    */
    title: PropTypes.string,
    /**
    * title 너비
    */
    titleWidth: PropTypes.string,
    /**
    * title 경고
    */
    warn: PropTypes.bool,
    /**
    * 입력 텍스트
    */
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
};

TextBox.defaultProps = {
    className: null,
    style: {},
    title: 'Title Empty',
    titleWidth: '102px',
    warn: false,
    children: 'Empty'
};

export default TextBox;

