class PaidInCapitalIncreaseStepVm {
    newShareAssignmentMethod(method) {
        switch(method) {
            case "제3자_배정":
                return '재무구조 개선 등 경영상 목적 위하여(제3자 배정)';
            case "주주_배정":
                return '주주의 권리를 사용해서(주주 배정)';
        }
    }
    issuingNewShareProcess(process, noticeType) {
        switch(process) {
            case "주주_동의서_제출":
                return '주주의 동의를 받아, 바로 신주발행 진행';
            case "신주발행_주주_통지":
                return '주주에게 신주발행통지를 하고, 14일 대기';
            case "신주발행_홈페이지_신문사_공고":
                return `${noticeType === "신문사" ? "신문사" : "홈페이지"}에 신주발행 공고를 올리고, 14일 대기`;
        }
    }
}
export default new PaidInCapitalIncreaseStepVm();