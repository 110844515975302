import { runInAction, observable, toJS } from "mobx";
import CorporateTaxStepModel from '@model/changeRegistration/CorporateTaxStepModel';
import smartformAppModel from "@model/smartformAppModel";
import regExp from '@common/module/regExp';
import formDataToJson from '@common/module/submit';
import stepService from "@service/stepService";

class CorporateTaxVm {
    submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error === false) {
            let param = formDataToJson(e.target).data;
            stepService.saveStep(param);
        }
    }
    get freeCounselYn() {
        return CorporateTaxStepModel.freeCounselYn;
    }
    setFreeCounselYn(e) {
        runInAction(() => {
            CorporateTaxStepModel.freeCounselYn = e.target.value === 'true';
        })
    }

}
export default new CorporateTaxVm();