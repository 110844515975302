import { useObserver } from 'mobx-react';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import clsx from 'clsx';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import SquareBtn from '@comComponents/atoms/Button/SquareBtn/SquareBtn';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import regExp from '@common/module/regExp';
import _CauseDateVm from './CauseDateVm';

function CauseDate(props) {
    let CauseDateVm = new _CauseDateVm(props.model);
    return useObserver(() => (
        <div className={Qst.spaceBetween} style={{marginBottom:'-27px'}}>
            <div className={Qst.infoSection}>
                <div className={Qst.titleWrap}>
                    <h2 className={Qst.title}>
                        {props.title}
                    </h2>
                </div>
                <Badge className={Qst.registrationBadge} title="예시" type="example" border={false}>
                    <div className={Qst.registartionBadgeBox}>
                        <img className={Qst.registrationBadgeImg} src="/images/registration_date.png" alt="일자 입력 예시" />
                        <div className={Qst.registrationBadgeTextBox}>
                            <div className={Qst.registrationBadgeText}>
                                <div className={Qst.text}>
                                    <span className={Qst.boldBlue}>안정적으로 등기신청하기 위해 지점 변경 날짜는 오늘(질문지 제출일)을 기준으로,<br/>1주 전 ~ 3주 이내로 입력해 주세요.</span>
                                </div>
                                <div className={Qst.text}>
                                    <span className={Qst.bold}>예시) 오늘(질문 제출일)이 3월 23일</span>이라면, <span className={Qst.bold}>안정적으로 등기신청 가능한 지점 변경 날짜는 3월 16일 ~ 4월 12일</span> 입니다.
                                </div>
                            </div>
                            <div className={Qst.registrationBadgeTextExplain}>
                                특별한 사정이 없다면, <span className={Qst.bold}>오늘 날짜(질문 제출일)로 입력하는 것을 추천</span>드립니다.<br/>
                                입력한 지점 변경 날짜로 부터 2주 이내 접수해야 과태료가 부가되지 않습니다.
                            </div>
                        </div>     
                    </div>                 
                </Badge>
                <div>
                    <div className={Qst.birthWrap}>
                        <InputText style={{width:'130px'}} placeholder="0000" name={`${props.name}.year`} value={CauseDateVm.year} onChange={(e) => CauseDateVm.setCauseDateYear(e)} maxLength="4" />
                        <span className={Qst.birthText}>년</span>
                    </div>
                    <div className={Qst.birthWrap}>
                        <InputText style={{width:'108px'}} placeholder="00" name={`${props.name}.month`} value={CauseDateVm.month} onChange={(e) => CauseDateVm.setCauseDateMonth(e)} maxLength="2" onBlur={(e) => CauseDateVm.causeDateBlur(e, 'month')} />
                        <span className={Qst.birthText}>월</span>
                    </div>
                    <div className={Qst.birthWrap}>
                        <InputText style={{width:'108px'}} placeholder="00" name={`${props.name}.day`} value={CauseDateVm.day} onChange={(e) => CauseDateVm.setCauseDateDay(e)} maxLength="2" onBlur={(e) => CauseDateVm.causeDateBlur(e, 'day')} />
                        <span className={Qst.birthText}>일</span>
                    </div>
                </div>
                <div style={{height:'27px'}}>
                    <Error style={{marginTop:'-27px'}} name={`${props.name}.year`} value={CauseDateVm.year} 
                    errorCase={{
                        required:'연도를 입력해 주세요.',
                        pattern: {
                        value: regExp.year().test(CauseDateVm.year),
                        message: '올바른 연도를 입력해 주세요'               
                        }
                    }}
                    />
                    <Error style={{marginTop:'-27px'}} name={`${props.name}.month`} value={CauseDateVm.month} 
                    errorCase={{
                        required: CauseDateVm.displayCauseDateErrorMessage('month') ? '월을 입력해 주세요.' : null,
                        pattern: {
                        value: CauseDateVm.displayCauseDateErrorMessage('month') ? regExp.month().test(CauseDateVm.month) : null,
                        message: '올바른 월을 입력해 주세요'               
                        }
                    }}
                    />
                    <Error style={{marginTop:'-27px'}} name={`${props.name}.day`} value={CauseDateVm.day} 
                    errorCase={{
                        required: CauseDateVm.displayCauseDateErrorMessage('day') ? '일(날짜)을 입력해 주세요.' : null,
                        pattern: {
                        value: CauseDateVm.displayCauseDateErrorMessage('day') ? regExp.day().test(CauseDateVm.day) : null,
                        message: '올바른 일(날짜)을 입력해 주세요.'           
                        }
                    }}
                    />
                </div>
            </div>
        </div>
    ));
}

export default CauseDate;