import { useObserver } from 'mobx-react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import clsx from 'clsx';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './CompanySize.module.scss';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import CompanySizeVm from './CompanySizeVm';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import Ul from '@comComponents/atoms/Text/UnorderListText/UnorderList';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import { useEffect } from 'react';

function CompanySize() {
    useEffect(() => {
        CompanySizeVm.beforeData.companySize = CompanySizeVm.companySize;
        CompanySizeVm.beforeData.initiatorRole = CompanySizeVm.initiatorRole || null;
    }, [])
    return useObserver(() => (
    <form onSubmit={(e) => CompanySizeVm.submit(e)}>
        <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
        {(CompanySizeVm.smartFormType === 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY' || CompanySizeVm.smartFormType === 'ESTABLISHMENT_FISHERY_STOCK_COMPANY') && //농업회사법인&어업회사법인
            <Question>
                <ContentBox className={Qst.spaceBetween}>
                    <div className={Qst.infoSection}>
                        <div className={Qst.titleWrap}>
                            <div className={st.infoImg}>
                                <img src='/images/preApproval.png' alt='농(어)업회사법인 설립전 사전승인 제도 안내'/>
                            </div>
                        </div>
                    </div>
                </ContentBox>
                <ContentBox className={Qst.spaceBetween}>
                    <div className={Qst.infoSection}>
                        <div className={Qst.titleWrap}>
                            <h2 className={Qst.title}>
                                <span className={Qst.bold}>농(어)업회사법인 사전신고 제도</span>를 확인하셨나요?
                            </h2>
                        </div>
                            <ul className={Qst.answerList}>
                                <li><Radio className={Qst.answerLabel} activeBackground value={true} checked={CompanySizeVm.checkPreReportingSystemYn === true} onChange={CompanySizeVm.setCheckPreReportingSystemYn} name="checkPreReportingSystemYn">네</Radio></li>
                            </ul>
                            <Error name="checkPreReportingSystemYn" value={CompanySizeVm.checkPreReportingSystemYn} errorCase={{required:'사전신고 제도 확인여부를 체크해주세요.'}} />
                    </div>
                    <div className={Qst.qnaSection}>
                        <Qna className={Qst.noTitle}>
                            <div className={Qst.qnaBox}>
                                <QText>농(어)업회사법인 설립시 사전신고가 필요한 이유가 무엇<br/>인가요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>농어업경영체 육성 및 지원에 관한 법률이 개정되어 사전신고가 필요합니다.</Answer>
                                    <div className={Qst.explain}>
                                        농(어)업회사법인의 경우 세제혜택이 많아서, 그 동안 규제가 더 필요하다는 논의가 진행되던 중, 법률이 개정되었습니다. 개정법률에 따라 설립등기 접수 전에 사전신고를 해야 하며, 사전신고에 필요한 서류는 헬프미에서 모두 준비해드립니다. (농어업경영체 육성 및 지원에 관한 법률 시행령 19조 2항)
                                    </div>
                                </div>
                            </div>
                        </Qna>
                    </div>
                </ContentBox>
            </Question>
        }
        {CompanySizeVm.smartFormType === 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY' && //농업회사법인
        <Question>
            <ContentBox className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    <div className={Qst.titleWrap}>
                        <h2 className={Qst.title}>
                            <span className={Qst.bold}>주주가 모두 농업인(또는 농업회사법인, 영농조합법인)</span><br/>이 맞나요?
                        </h2>
                    </div>
                    <ul className={Qst.answerList}>
                        <li><Radio className={Qst.answerLabel} activeBackground value={true} checked={CompanySizeVm.allFarmWorkersYn === true} onChange={CompanySizeVm.setAllFarmWorkersYn} name="allFarmWorkersYn">네</Radio></li>
                        <li><Radio className={Qst.answerLabel} checked={false} onClick={() => CompanySizeVm.openModal('Farmer')}>아니오</Radio></li>
                    </ul>
                    <Error name="allFarmWorkersYn" value={CompanySizeVm.allFarmWorkersYn} errorCase={{required:'주주가 모두 농업인이 맞는지 선택해 주세요.'}} />
                </div>
                <div className={Qst.qnaSection}>
                    <Qna tip="농업회사법인 설립" className={Qst.qnaTitle}>
                        <div className={Qst.qnaBox}>
                            <QText>농업회사법인이란 무엇인가요?</QText>
                            <div className="answerBox">
                                <Answer className={Qst.answer}>농업 경영, 농산물 유통&middot;가공&middot;판매 등의 목적으로 설립하는 법인입니다.</Answer>
                                <div className={Qst.explain}>
                                    <Ul>
                                        <li>따라서 농업회사법인은 농업과 관련된 사업만 진행할 수 있습니다. (ex 농업경영업, 농어촌관광 휴양사업, 농산물의 유통, 가공, 판매업 등)</li>
                                        <li>농업회사법인을 취득하면 많은 세금 감면 혜택이 있고(양도소득세, 법인세 등), 법인 명의로 농지를 적법하게 취득할 수 있습니다.</li>
                                    </Ul>
                                </div>
                            </div>
                        </div>
                        <div className={Qst.qnaBox}>
                            <QText>누구나 농업회사법인을 설립할 수 있나요?</QText>
                            <div className="answerBox">
                                <Answer className={Qst.answer}>아니오. 설립할 때에는 농업인만 농업회사법인의 주주가 될 수 있습니다.</Answer>
                                <div className={Qst.explain}>
                                    <Ul>
                                        <li>농업회사법인은 많은 혜택이 있는 대신, 설립할 때 제한이 있습니다. 설립할 때에는 국가로부터 '농업인' 확인을 받거나 '농업경영체등록확인서'를 받은 사람만 농업회사법인의 주주가 될 수 있습니다. 또한 설립할 때에는 농업회사법인, 영농조합법인은 농업회사법인의 주주가 될 수 있지만, 일반 법인이나 외국법인은 주주가 될 수 없습니다.</li>
                                        <li>만약 농업인이 아닌 사람이나, 일반법인, 외국법인이 주주가 되어야 한다면, 일단 설립한 후에 지분양도절차를 진행해 주세요. 참고로 설립한 이후에도 농업인이 10%이상 주식을 가지고 있어야 하므로, 90% 범위에서만 지분양도가 가능합니다.</li>
                                        <li>그리고 이사의 1/3 이상이 농업인이어야 합니다.</li>
                                    </Ul>
                                </div>
                                <LikeBtn className={Qst.likeBtn} href="https://blog.help-me.kr/2018/02/농업회사법인-법인설립-모든것/" target="_blank">농업회사법인 더 알아보기</LikeBtn>
                            </div>
                        </div>
                    </Qna>
                </div>
            </ContentBox>
        </Question>
        }
        {CompanySizeVm.smartFormType === 'ESTABLISHMENT_FISHERY_STOCK_COMPANY' && //어업회사법인
        <Question>
            <ContentBox className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    <div className={Qst.titleWrap}>
                        <h2 className={Qst.title}>
                            <span className={Qst.bold}>주주가 모두 어업인(또는 어업회사법인, 영어조합법인)</span><br/>이 맞나요?
                        </h2>
                    </div>
                    <ul className={Qst.answerList}>
                        <li><Radio className={Qst.answerLabel} value={true} activeBackground checked={CompanySizeVm.allFishermenYn === true} onChange={CompanySizeVm.setAllFishermenYn} name="allFishermenYn">네</Radio></li>
                        <li><Radio className={Qst.answerLabel} checked={false} onClick={() => CompanySizeVm.openModal('Fisher')}>아니오</Radio></li>
                    </ul>
                    <Error name="allFishermenYn" value={CompanySizeVm.allFishermenYn} errorCase={{required:'주주가 모두 어업인이 맞는지 선택해 주세요.'}} />
                </div>
                <div className={Qst.qnaSection}>
                    <Qna tip="어업회사법인 설립" className={Qst.qnaTitle}>
                        <div className={Qst.qnaBox}>
                            <QText>어업회사법인이란 무엇인가요?</QText>
                            <div className="answerBox">
                                <Answer className={Qst.answer}>어업 경영, 수산물 유통&middot;가공&middot;판매 등의 목적으로 설립하는 법인입니다.</Answer>
                                <div className={Qst.explain}>
                                    <Ul>
                                        <li>따라서 어업회사법인은 어업과 관련된 사업만 진행할 수 있습니다. (ex 어업경영업, 농어촌관광 휴양사업, 수산물의 유통, 가공, 판매업 등)</li>
                                        <li>어업회사법인을 취득하면 많은 세금 감면 혜택이 있습니다. (양도소득세, 법인세 등)</li>
                                    </Ul>
                                </div>
                            </div>
                        </div>
                        <div className={Qst.qnaBox}>
                            <QText>누구나 어업회사법인을 설립할 수 있나요?</QText>
                            <div className="answerBox">
                                <Answer className={Qst.answer}>아니오. 설립할 때에는 어업인만 어업회사법인의 주주가 될 수 있습니다.</Answer>
                                <div className={Qst.explain}>
                                    <Ul>
                                        <li>어업회사법인은 많은 혜택이 있는 대신, 설립할 때 제한이 있습니다. 설립할 때에는 국가로부터 '어업인' 확인을 받거나 '어업경영체등록확인서'를 받은 사람만 어업회사법인의 주주가 될 수 있습니다. 또한 설립할 때에는 어업회사법인, 영어조합법인은 어업회사법인의 주주가 될 수 있지만, 일반법인이나 외국법인은 주주가 될 수 없습니다.</li>
                                        <li>만약 어업인이 아닌 사람이나, 일반법인, 외국법인이 주주가 되어야 한다면, 일단 설립한 후에 지분양도절차를 진행해 주세요. 참고로 설립한 이후에도 어업인이 10%이상 주식을 가지고 있어야 하므로, 90% 범위에서만 지분양도가 가능합니다.</li>
                                        <li>그리고 이사의 1/3 이상이 어업인이어야 합니다.</li>
                                    </Ul>
                                </div>
                                <LikeBtn className={Qst.likeBtn} href="https://blog.help-me.kr/2018/02/어업회사법인-법인설립-모든것/" target="_blank">어업회사법인 더 알아보기</LikeBtn>
                            </div>
                        </div>
                    </Qna>
                </div>
            </ContentBox>
        </Question>
        }
        <Question>
            <ContentBox className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    <div className={Qst.titleWrap}>
                        <h2 className={Qst.title}>
                            <span className={Qst.bold}>회사설립에는 몇명이 참여</span>하나요?
                        </h2>
                    </div>
                    <ul className={Qst.answerList}>
                        <li><Radio className={Qst.answerLabel} value="ONE" activeBackground checked={CompanySizeVm.companySize === "ONE"} onChange={CompanySizeVm.setCompanySize} name="companySize">1명</Radio></li>
                        <li><Radio className={clsx(Qst.answerLabel, st.best)} value="TWO" activeBackground checked={CompanySizeVm.companySize === "TWO"} onChange={CompanySizeVm.setCompanySize} name="companySize">2명</Radio></li>
                        <li><Radio className={Qst.answerLabel} value="OTHER" activeBackground checked={CompanySizeVm.companySize === "OTHER"} onChange={CompanySizeVm.setCompanySize} name="companySize">3명 이상</Radio></li>
                    </ul>
                    <Error name="companySize" value={CompanySizeVm.companySize} errorCase={{required:'회사설립 인원수를 입력해 주세요.'}} />
                    {/* 회사설립 1명 참여 선택하는 경우 */}
                    {CompanySizeVm.companySize === "ONE" && 
                        <div className={st.onePerson}>
                            <img className={st.titleImg} src="/images/title.svg" 
                            alt="1명 선택 시, 진행이 어렵습니다. 1인 설립을 원한다면! 2인 설립 후, 1명은 즉시 사임을!" />
                            <dl className={st.def}>
                                <dt className={st.term}>
                                    주식회사 설립에 1명만 참여하면 최소 160만 원 이상이 추가됩니다!
                                </dt>
                                <dd className={st.des}> 
                                    상법상 주식회사를 설립할 때에는 발기인총회에서 조사보고를 할 사람이 필요합니다. 조사보고자는 주식을 가지고 있지 않은 임원 또는 공증 변호사만 맡을 수 있습니다. 주식회사 설립에 <strong className={st.strong}>1명만 참여하면, 공증변호사를 선임해야 하는데 이 때 최소 160만 원 이상의 비용이 추가 발생합니다.</strong>
                                </dd>
                                <dt className={st.term}>
                                    2인 설립 후, 1명은 즉시 사임하면 저렴하게 1인 설립이 가능합니다!
                                </dt>
                                <dd className={st.des}> 
                                    비용을 절약하기 위해 2명 설립을 하는 경우가 많지만, 반드시 1인 설립을 원하시는 경우, <strong className={st.strong}>2인 법인설립 직후 1명을 사임시키는 방법</strong>이 있습니다. (사임시 세금, 수수료 발생) 헬프미에서는 법인설립 직후 1명을 사임시키는 경우 <strong className={st.strong}>수수료 할인혜택</strong>을 드리고 있습니다. (전자등기 기준 세금 53,240원, 수수료 <span className={st.lineThrough}>149,000</span> &rarr; 129,000원/ 부가세 별도, 서류등기 진행시에는 비용이 증액됩니다.)
                                </dd>
                                <dt className={st.term}>
                                    1명 사임신청은 뒷 부분 질문 [임원] 작성시, 아래 질문에 체크하시면 됩니다!
                                </dt>
                                
                                <dd className={st.des}> 
                                <Badge className={st.badge} title="예시" type="example" border={false}>
                                        <img className={st.badgeImg} src="/images/example_img1.png"
                                        alt="...님을 설립 직후 사임시키시겠어요? 해당 질문의 답변에 [네] 체크" />
                                </Badge>
                                </dd>
                            </dl>
                        </div>
                    }
                </div>
                <div className={Qst.qnaSection}>
                    <Qna tip="규모 설정방법" className={Qst.qnaTitle}>
                        <div className={Qst.qnaBox}>
                            <QText>왜 2명으로 설립하는 경우를 추천하나요?</QText>
                            <div className="answerBox">
                                <Answer className={Qst.answer}>2명으로 설립하면 나중에 회사운영이 간편해지기 때문에, 추천드립니다.</Answer>
                                <p className={Qst.explain}>
                                    2명으로 설립하면, 상법상 주식회사를 운영할 때 지켜야 하는 절차들을 간소화할 수 있습니다.
                                    특히 이사가 3명 이상인 경우, 이사회를 항상 열어야 하는 등 운영 절차가 까다로워 집니다.
                                </p>
                            </div>
                        </div>
                        <div className={Qst.qnaBox}>
                            <QText>부모님, 배우자, 가족도 회사 설립에 참여할 수 있나요?</QText>
                            <div className="answerBox">
                                <Answer className={Qst.answer}>네. 부모님, 배우자, 가족도 회사설립에 참여할 수 있습니다.</Answer>
                                <p className={Qst.explain}>
                                    다만 다른 직장이 있거나, 다른 회사의 임원인 경우 약간의 제약이 있습니다.
                                </p>
                            </div>
                        </div>
                        <div className={Qst.qnaBox}>
                            <QText>설립에만 참여했다가, 바로 사임할 수도 있나요?</QText>
                            <div className="answerBox">
                                <Answer className={Qst.answer}>네. 설립할 때만 참여했다가, 설립직후 사임해도 무방합니다.</Answer>
                                <p className={Qst.explain}>
                                    다만 이 경우 사임등기 비용이 발생합니다. 사임등기 비용은 세금포함 약 20만 원입니다.
                                </p>
                            </div>
                        </div>
                        <div className={Qst.qnaBox}>
                            <QText>공무원, 직장인, 개인사업자는 회사 설립에 참여할 수 있나요?</QText>
                            <div className="answerBox">
                                <Answer className={Qst.answer} iconText="A1">공무원은 원칙적으로 불가합니다.</Answer>
                                <p className={Qst.explain}>
                                    국가공무원 복무규정 제25조가 금지하고 있기 때문입니다. 또한 군복무 중인 자녀도 공무원에 해당하므로 임원이 될 수 없습니다.
                                </p>
                                <Answer className={Qst.answer} iconText="A2">직장인은 원칙적으로 가능합니다.</Answer>
                                <p className={Qst.explain}>
                                    원칙적으로 가능하지만, 사기업에서는 취업규칙 등에 이중 취업금지를 정하고 있는 경우가 많습니다. 따라서 회사에 알려지면 징계를 받을 수도 있습니다. 물론 임원이 되었다고 해서 기존 직장에 통지하는 제도는 없습니다. (이중으로 월급 지급시 노출될 가능성 있음)
                                </p>
                                <Answer className={Qst.answer} iconText="A3">개인사업자는 제한없이 가능합니다.</Answer>
                                <Answer className={Qst.answer} iconText="A4">다른회사 임원은 원칙적으로 가능합니다.</Answer>
                                <p className={Qst.explain}>
                                    다만 다른 회사 임원이 동종영업을 하는 회사의 이사가 되려면 이사회의 승인을 받아야 하고, 금융회사의 임원은 겸직이 제한되는 경우가 있습니다. 자세한 내용은 아래 링크를 참고해주세요.
                                </p>
                                <LikeBtn className={Qst.likeBtn} href="https://blog.help-me.kr/2018/09/%EB%93%B1%EA%B8%B0%EC%9D%B4%EC%82%AC-%EA%B2%B8%EC%A7%81%EA%B8%88%EC%A7%80/" target="_blank">등기이사 겸직금지 총정리 바로가기</LikeBtn>
                            </div>
                        </div>
                    </Qna>
                </div>
            </ContentBox>
        </Question>
        {CompanySizeVm.companySize === "TWO" && 
            <Question>
                <ContentBox className={Qst.spaceBetween}>
                    <div className={Qst.infoSection}>
                        <div className={Qst.titleWrap}>
                            <h2 className={Qst.title}>
                                <span className={Qst.bold}>설립에 참여하는 2명은 어떤 역할</span>을 맡나요?
                            </h2>
                        </div>
                        <ul className={Qst.answerList}>
                            <li><Radio className={Qst.answerLabel} name="initiatorRole" value="REPRESENTATIVE_DIRECTOR" activeBackground checked={CompanySizeVm.initiatorRole === "REPRESENTATIVE_DIRECTOR"} onChange={CompanySizeVm.setInitiatorRole}>대표(지분 100%) + 이사(지분 0%)<br/><span className={st.sizeExplain}>설립 후 사임하는 사람 없이 운영할 경우 추천</span></Radio></li>
                            <li><Radio className={Qst.answerLabel} name="initiatorRole" value="REPRESENTATIVE_AUDITOR" activeBackground checked={CompanySizeVm.initiatorRole === "REPRESENTATIVE_AUDITOR"} onChange={CompanySizeVm.setInitiatorRole}>대표(지분 100%) + 감사(지분 0%)<br/><span className={st.sizeExplain}>설립 직후 대표만 남기고 나머지 1명 사임시키는 경우 추천</span></Radio></li>
                            <li><Radio className={Qst.answerLabel} name="initiatorRole" value="REPRESENTATIVE_SHAREHOLDERS" activeBackground checked={CompanySizeVm.initiatorRole === "REPRESENTATIVE_SHAREHOLDERS"} onChange={CompanySizeVm.setInitiatorRole}>대표(지분 0%) + 주주(지분 100%)<br/><span className={st.sizeExplain}>법인의 소유자와 대표가 다른 경우 추천</span></Radio></li>
                            <li><Radio className={Qst.answerLabel} name="initiatorRole" value="REPRESENTATIVE_STOCKED_DIRECTOR" activeBackground checked={CompanySizeVm.initiatorRole === "REPRESENTATIVE_STOCKED_DIRECTOR"} onChange={CompanySizeVm.setInitiatorRole}>대표(지분 0%) + 이사(지분 100%)</Radio></li>
                            <li><Radio className={Qst.answerLabel} name="initiatorRole" value="REPRESENTATIVE_STOCKED_AUDITOR" activeBackground checked={CompanySizeVm.initiatorRole === "REPRESENTATIVE_STOCKED_AUDITOR"} onChange={CompanySizeVm.setInitiatorRole}>대표(지분 0%) + 감사(지분 100%)</Radio></li>
                        </ul>
                        <Error name="initiatorRole" value={CompanySizeVm.initiatorRole} errorCase={{required:'설립에 참여하는 2명의 역할을 입력해 주세요.'}} />
                    </div>
                    <div className={Qst.qnaSection}>
                        <Qna className={Qst.noTitle}>
                            <div className={Qst.qnaBox}>
                                <QText>2명 모두 주주가 될 수는 없나요?</QText>
                                <div className="answerBox"> 
                                    <Answer className={Qst.answer}>2명이 주식회사를 설립하는 경우, 2명 모두 주주가 되기는 어렵습니다.</Answer>
                                    <p className={Qst.explain}>
                                        상법상 주식회사를 설립할 때에는 발기인총회에서 조사보고를 할 사람("조사보고자")이 필요하기 때문입니다. 조사보고자는 주식을 가지고 있지 않은 임원 또는 공증변호사만이 맡을 수 있습니다. 2명이 모두 주주가 되는 경우, 조사보고자를 맡을 사람이 없기 때문에, 공증변호사를 선임해야 합니다. 이 경우 160만 원 가량의 추가비용이 들기 때문에, 2명이 모두 주주가 되는 경우는 추천드리지 않습니다.
                                    </p>
                                </div>
                            </div>
                            <div className={Qst.qnaBox}>
                                <QText>어떤 구성을 추천하나요?</QText>
                                <div className="answerBox"> 
                                    <Answer className={Qst.answer}>상황에 따라 추천하는 구성이 다릅니다.</Answer>
                                    <p className={Qst.explain}>
                                        우선 설립한 후지분 없는 임원은 즉시 사임한다면, [대표(지분100%) + 감사(지분 0%)]를 추천드립니다. 이 경우 보다 간단하게 사임등기를 진행할 수 있기 때문입니다.
                                    </p>
                                    <p className={Qst.explain}>
                                        설립한 후, 사임하는 사람 없이 계속 함께 운영을 할 예정이라면,  [대표(지분100%) + 이사(지분 0%)]를 추천드립니다. 감사는 이사와 임기가 다를 수 있기 때문에, 나중에 재취임 등기를 할 때, 2번 등기해야 하는 번거로움이 줄어들 수 있습니다.
                                    </p>
                                    <p className={Qst.explain}>
                                        법인의 실제 소유자와 대표자가 다른 경우에는 [대표(지분0%) + 주주(지분100%)]를 선택하시면 됩니다. 이 경우 주주의 인적사항은 등기부등본에 나오지 않아서, 외부에 쉽게 공개되지 않습니다.
                                    </p>
                                </div>
                            </div>
                            </Qna>
                    </div>
                </ContentBox>
            </Question>
        }
    </form>
    ));
}

export default CompanySize;
