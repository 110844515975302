import { useObserver } from 'mobx-react';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import clsx from 'clsx';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import SquareBtn from '@comComponents/atoms/Button/SquareBtn/SquareBtn';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import regExp from '@common/module/regExp';
import SearchAddress from '@comComponents/atoms/SearchAddress/SearchAddress';
import _AddressVm from './AddressVm';
import { useMemo } from 'react';


function Address(props) {
    const AddressVm = useMemo(() => new _AddressVm(props.model), [props.model])
    return useObserver(() => (
        <>  
            <div className={Qst.spaceBetween} style={{paddingBottom:'30px'}}>
                <div className={Qst.infoSection}> 
                    {AddressVm.koreanYn ?
                        <>
                            <div className={Qst.titleWrap}>
                                <h2 className={Qst.title}>
                                    {props.title}
                                </h2>
                            </div>
                            <Badge className={Qst.addressBadge} title="예시" type="example" border={false}>
                                <img className={Qst.addressBadgeImg} src="/images/person_address_example.png"
                                alt="주민등록상 도로명주소 예시" />
                            </Badge>
                        </>
                        :
                        <div className={Qst.titleWrap}>
                            <h2 className={Qst.title}>
                                {props.title}
                            </h2>
                            <div className={clsx(Qst.titleExplain, Qst.noneMark)} style={{"color":"#fd6200"}}>
                                반드시 국내 거소신고(외국인등록) 사실증명과 정확히 일치하는 주소를 입력해주세요.
                            </div>
                            <div className={clsx(Qst.titleExplain, Qst.noneMark)} style={{"color":"#fd6200"}}>
                                거소신고(외국인등록) 사실증명상 주소와 일치하지 않는 경우, 등기신청이 거절됩니다. (5~7일 더 걸림)
                            </div>
                        </div>
                    }
                    <div className={Qst.rowWrap}>
                        <InputText className={Qst.inputText} style={{width:'380px'}} explain="도로명 주소" 
                        value={AddressVm.address?.address || ''} 
                        readOnly 
                        onClick={() => AddressVm.openAddressModal(true)} 
                        name={`${props.name}.address`}
                        errorText={<Error name={`${props.name}.address`} value={AddressVm.address?.address || ''} errorCase={{required:'도로명 주소를 입력해 주세요.'}}/>}/>
                        <SquareBtn onClick={() => AddressVm.openAddressModal(true)}>도로명 주소 찾기</SquareBtn>
                        {AddressVm.state.addressModal &&
                            <SearchAddress onClose={() => AddressVm.openAddressModal(false)} onComplete={(data) => AddressVm.addressComplate(data)} />
                        }
                    </div>
                    <div className={Qst.rowWrap}>
                        <InputText className={Qst.inputText} style={{width:'273px'}} explain="상세 주소" errorText={<Error name={`${props.name}.detailAddress`} value={AddressVm.detailAddress} errorCase={{
                            required:'상세 주소를 입력해 주세요.',
                            pattern : {
                                value: regExp.onlyKorNum_hyphen_comma().test(AddressVm.detailAddress),
                                message: <span>임대차 계약서상 영문이 있다면, <br/>한글로 옮겨적어주세요.<br/>EX) B동 &gt; 비동</span>
                            }
                        }}/>} placeholder="상세주소 없을 시, [없음] 입력" value={AddressVm.detailAddress} onChange={(e) => AddressVm.setDetailAddress(e)} name={`${props.name}.detailAddress`}/>
                        <div className={Qst.symbol}>
                            <span className={Qst.symbolIcon}>&#40;&nbsp;</span><span className={clsx(Qst.symbolText, AddressVm.bnameFontSizeChange ? Qst.fontSize : null)}>{AddressVm.address?.bname || '법정동'},&nbsp;</span>
                            <InputText style={{width:'200px'}} explain="건물명" placeholder="없을 시, [없음] 입력" value={AddressVm.nameOfBuilding} onChange={(e) => AddressVm.setNameOfBuilding(e)} name={`${props.name}.nameOfBuilding`} errorText={<Error name={`${props.name}.nameOfBuilding`}  value={AddressVm.nameOfBuilding} style={{width:'260px'}} errorCase={{
                                required:'건물명을 입력해 주세요.',
                                pattern : {
                                    value: regExp.onlyKorNum_hyphen_comma().test(AddressVm.nameOfBuilding),
                                    message: <span>건물명이 영문인 경우, 한글<br/>발음으로 옮겨적어 주세요. <br/>EX) AB타워 &gt; 에이비 타워</span>
                                }
                            }}/>}/>
                            <span className={Qst.symbolIcon}>&nbsp;&#41;</span>
                        </div>
                    </div>
                </div>
            </div>
            {props.reportDate &&
                <div className={Qst.spaceBetween} style={{marginTop:'70px', marginBottom:'-27px'}}>
                    <div className={Qst.infoSection}>
                        {AddressVm.koreanYn ?
                        <>
                            <div className={Qst.titleWrap}>
                                <h2 className={Qst.title}>
                                    <span className={Qst.bold}>{AddressVm.name}님</span>의 전입신고일자를 입력해 주세요.
                                </h2>
                            </div>
                            <Badge className={Qst.reportDateBadge} title="예시" type="example" border={false}>
                                <img className={Qst.reportDateBadgeImg} src="/images/causedate_example.png"
                                alt="주민등록상 전입신고일자 예시" />
                            </Badge>
                        </>
                        :
                            <div className={Qst.titleWrap}>
                                <h2 className={Qst.title}>
                                    <span className={Qst.bold}>{AddressVm.name}님</span>의 <span className={Qst.bold}>외국인 서류상 주소변경일자</span>를 입력해 주세요.
                                </h2>
                            </div>
                        }
                        <div>
                            <div className={Qst.birthWrap}>
                                <InputText style={{width:'130px'}} placeholder="0000" name={`${props.name}.causeDate.year`} value={AddressVm.causeDate.year} onChange={(e) => AddressVm.setReportDateYear(e)} maxLength="4" />
                                <span className={Qst.birthText}>년</span>
                            </div>
                            <div className={Qst.birthWrap}>
                                <InputText style={{width:'108px'}} placeholder="00" name={`${props.name}.causeDate.month`} value={AddressVm.causeDate.month} onChange={(e) => AddressVm.setReportDateMonth(e)} maxLength="2" onBlur={(e) => AddressVm.reportDateBlur(e, 'month')} />
                                <span className={Qst.birthText}>월</span>
                            </div>
                            <div className={Qst.birthWrap}>
                                <InputText style={{width:'108px'}} placeholder="00" name={`${props.name}.causeDate.day`} value={AddressVm.causeDate.day} onChange={(e) => AddressVm.setReportDateDay(e)} maxLength="2" onBlur={(e) => AddressVm.reportDateBlur(e, 'day')} />
                                <span className={Qst.birthText}>일</span>
                            </div>
                        </div>
                        <div style={{height:'27px'}}>
                            <Error style={{marginTop:'-27px'}} name={`${props.name}.causeDate.year`} value={AddressVm.causeDate.year} 
                            errorCase={{
                                required:'연도를 입력해 주세요.',
                                pattern: {
                                value: regExp.year().test(AddressVm.causeDate.year),
                                message: '올바른 연도를 입력해 주세요'               
                                }
                            }}
                            />                                            
                            <Error style={{marginTop:'-27px'}} name={`${props.name}.causeDate.month`} value={AddressVm.causeDate.month} 
                            errorCase={{
                                required: AddressVm.displayReportDateErrorMessage('month') ? '월을 입력해 주세요.' : null,
                                pattern: {
                                value: AddressVm.displayReportDateErrorMessage('month') ? regExp.month().test(AddressVm.causeDate.month) : null,
                                message: '올바른 월을 입력해 주세요'               
                                }
                            }}
                            />
                            <Error style={{marginTop:'-27px'}} name={`${props.name}.causeDate.day`} value={AddressVm.causeDate.day} 
                            errorCase={{
                                required: AddressVm.displayReportDateErrorMessage('day') ? '일(날짜)을 입력해 주세요.' : null,
                                pattern: {
                                value: AddressVm.displayReportDateErrorMessage('day') ? regExp.day().test(AddressVm.causeDate.day) : null,
                                message: '올바른 일(날짜)을 입력해 주세요.'           
                                }
                            }}
                            />
                        </div>
                    </div>
                </div>
            }
        </>
    ));
}

export default Address;