import { useObserver } from 'mobx-react';
import Cst from '../../../FinalCheck/FinalCheck.module.scss';
import st from './BranchOfficeInstallStep.module.scss';
import clsx from 'clsx';
import { Fragment } from 'react';

function BranchOfficeInstallStep(props) {
    return useObserver(() => (
        <div className={clsx(Cst.row, st.branchOfficeInstall)} style={{display:'block'}}>
            {props.step.branches.map((item, idx) => {
                return (
                    <div className={st.installInfoBox} key={idx}>
                        <div className={st.installBranchInfo}>
                            <div className={st.dName}>신규지점 {props.step.branches.length > 1 && idx+1}</div>
                            <div className={st.data}>
                                <div>주소 : {item.address}</div>
                                <div>지점명 : {item.name}</div>
                                <div>지점 설치 일자 : {item.installDate}</div>
                            </div>
                        </div>
                        <div className={st.installBranchManagerInfo}>
                            <div className={st.dName}>지배인 추가</div>
                            <div className={st.data}>
                                {item.hasManagerYn ?
                                <>
                                    <div>이름 : {item.manager.name}</div>
                                    <div>{item.manager.koreanYn? '주민등록번호' : '생년월일'} : {item.manager.kssnOrBirth}</div>
                                    {item.manager.koreanYn === false && 
                                        <div className={st.managerNationality}>국적 : {item.manager.nationality}</div>
                                    }
                                    <div className={st.managerAddress}>주소 : {item.manager.address}</div>
                                </>
                                :
                                <>
                                    <div>추가하지 않음</div>
                                </>
                                }
                                
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    ));
}

export default BranchOfficeInstallStep;