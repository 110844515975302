import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import vm from './StandByBoardMeetingParticipantVm';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './StandByBoardMeetingParticipant.module.scss';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Check from '@comComponents/atoms/Input/Check/Check';
import { addCommas } from '@common/module/replaceNumber';
import clsx from 'clsx';

function StandByBoardMeetingParticipant() {
    return useObserver(() => (
        <form onSubmit={(e) => vm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            <Question>
                <ContentBox>
                    <div className={Qst.spaceBetween}>
                        <div className={Qst.infoSection}>
                            <div className={Qst.titleWrap}>
                                <h2 className={Qst.title}>
                                    아래는 <span className={Qst.bold}>이사회에 출석</span>한 <span className={Qst.bold}>임원 명단</span>입니다.<br/>
                                    <span className={Qst.bold}>찬성여부</span>와 <span className={Qst.bold}>인감증명서 제공여부</span>를 선택해주세요.
                                </h2>
                                <div className={Qst.titleExplain}>찬성하지 않은 분은 인감증명서를 제공할 수 없습니다.</div>
                            </div>
                            <table className={st.table}>
                                <colgroup>
                                    <col width="77"/>
                                    <col width="108"/>
                                    <col width="363"/>
                                    <col />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>
                                            <Check className={st.check} style={{paddingLeft:'30px', fontSize:'16px', fontWeight:'500', letterSpacing: '-0.48px', whiteSpace:'pre'}} top="0" checked={vm.agreeAllChecked} onChange={(e) => vm.setAgreeAllChecked(e)}>찬성</Check>
                                        </th>
                                        <th>
                                            <Check className={clsx(st.check, vm.agreeAllChecked === false && st.disabled)} style={{paddingLeft:'30px', fontSize:'16px', fontWeight:'500', letterSpacing: '-0.48px', lineHeight: '1.25',  whiteSpace:'pre'}} top="0" checked={vm.sealAllChecked} onChange={(e) => vm.setSealAllChecked(e)} disabled={!vm.agreeAllChecked}>인감증명서<br/>제공</Check>
                                        </th>
                                        <th>이름</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {vm.participants?.map((participant, idx) => (
                                        <tr key={idx}>
                                            <td>
                                                <Check onlyCheckBox className={st.check} checked={participant.agreeYn} onChange={(e) => vm.setAgreeYn(e, idx)} name={`participants[${idx}].agreeYn`} value={participant.agreeYn} />
                                            </td>
                                            <td>
                                                <div style={{width:'22px', margin: '0 auto'}}>
                                                    <Check onlyCheckBox className={clsx(st.check, participant.agreeYn === false && st.disabled)} checked={participant.  sealProvidingYn} onChange={(e) => vm.setSealProvidingYn(e, idx)} name={`participants[${idx}].sealProvidingYn`} value={participant.sealProvidingYn} disabled={!participant.agreeYn} disabledBackground={false} />
                                                </div>
                                            </td>
                                            <td>
                                                <input type="hidden" name={`participants[${idx}].id`} value={participant.id} />
                                                <span className={st.index}>{idx+1}</span>
                                                {participant.name}
                                                <span>{participant.position}</span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={3}>
                                            <div className={st.checkState}>
                                                {vm.checkState === "충분" && <div className={st.enough}>충분</div>}
                                                {vm.checkState === "부족" && <div className={st.lack}>부족</div>}
                                                <div className={st.explain}>
                                                    <div className={st.bold}>의결정족수 자동계산</div>
                                                    <div>이사를 선택하면, 상법에 맞는지 자동으로 계산해드려요.</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </ContentBox>
            </Question> 
        </form>
    ));
}
export default StandByBoardMeetingParticipant;