import st from './ConfirmAndGuidanceModal.module.scss';
import {addCommas} from '@common/module/replaceNumber';

const modal = {
    modalContent: 
    <>
        <div className={st.finalEstimate}>
            <div className={st.title}>최종견적서 (설립등기)</div>
            <table className={st.estimateTable}>      
                <tbody className={st.utility}>
                    <tr>
                        <th scope="row">공과금</th>
                        <td>소계 {addCommas(430000)}</td>
                    </tr>
                    <tr>
                        <th scope="row">등록면허세</th>
                        <td>{addCommas(405000)}</td>
                    </tr>
                    <tr>
                        <th scope="row">법원 수수료</th>
                        <td>{addCommas(25000)}</td>
                    </tr>
                </tbody>
                <tbody className={st.commission}>
                    <tr>
                        <th scope="row">수수료</th>
                        <td>소계 {addCommas(543400)}</td>
                    </tr>
                    <tr>
                        <th scope="row">주식회사 설립등기</th>
                        <td>{addCommas(444400)}</td>
                    </tr>
                    <tr>
                        <th scope="row">주주 중 외국 법인 추가</th>
                        <td>{addCommas(38500)}</td>
                    </tr>
                    <tr>
                        <th scope="row">주주, 임원 중 외국인 추가</th>
                        <td>{addCommas(33000)}</td>
                    </tr>
                    <tr>
                        <th scope="row">주주, 임원 7인 초과</th>
                        <td>{addCommas(5500)}</td>
                    </tr>
                    <tr>
                        <th scope="row">목적 20개 초과</th>
                        <td>{addCommas(11000)}</td>
                    </tr>
                    <tr>
                        <th scope="row">법인 주주 1곳 추가</th>
                        <td>{addCommas(11000)}</td>
                    </tr>
                    <tr>
                        <th scope="row">인감도장 제작비</th>
                        <td>무료(기본형 1개)</td>
                    </tr>
                    <tr>
                        <th scope="row">제증명 / 교통비 / 일당</th>
                        <td>없음</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </>
    ,
    btn: [{title: '확인', type: 'positive', event: ()=> {alert('버튼 이벤트')}}]
}

export default modal;