import { observable, runInAction } from 'mobx';

const PaidInCapitalIncreaseStepModel = observable({
    newSharesAcquirers: [], //신주인수인
    newShares: [],
    sharesOnRegister: {
        classStocksOnRegister: [],
        totalCapitalStock: null,
        totalNumberOfSharesOnRegister: null
    },
    existingShareholders: [],
    issuingNewShareProcess: null,
    newShareAssignmentType: null,
    noticeDate: {
        year: "",
        month: "",
        day: ""
    },
    noticeType: "",
    setNewSharesAcquirer(data) {
        runInAction(() => {
            if(data?.newSharesAcquirers && data?.newSharesAcquirers.length){
                this.newSharesAcquirers = data.newSharesAcquirers.map((newSharesAcquirer) => {
                    let year = "", month = "", day = "";
                    if(newSharesAcquirer.birth) {
                        year = newSharesAcquirer.birth.substring(0,4);
                        month = newSharesAcquirer.birth.substring(5,7);
                        day = newSharesAcquirer.birth.substring(8,10);
                    } 
                    return {
                        year: year || "",
                        month: month || "",
                        day: day || "",
                        detailAddress: newSharesAcquirer.detailAddress || "",
                        englishName: newSharesAcquirer.englishName || "",
                        koreanAddress: newSharesAcquirer.koreanAddress || "",
                        englishAddress: newSharesAcquirer.englishAddress || "",
                        foreignAddress: {
                            koreanAddress: newSharesAcquirer?.foreignAddress?.koreanAddress || "",
                            englishAddress: newSharesAcquirer?.foreignAddress?.englishAddress || ""
                        },
                        foreignerCompanyRepresentatives: newSharesAcquirer.foreignerCompanyRepresentatives || [{
                            id: "", 
                            englishName: "", 
                            koreanName: ""
                        }],
                        id: newSharesAcquirer.id ?? "",
                        koreanCompanyRepresentatives: newSharesAcquirer.koreanCompanyRepresentatives || [{
                            id: "", 
                            name: "", 
                            position: ""
                        }],
                        koreanName: newSharesAcquirer.koreanName || "",
                        kssn: newSharesAcquirer.kssn || "",
                        nameOfBuilding: newSharesAcquirer.nameOfBuilding || "",
                        nationalityOthers: newSharesAcquirer.nationalityOthers || "",
                        nationalityType: newSharesAcquirer.nationalityType || "",
                        residenceRegistrationYn: newSharesAcquirer.residenceRegistrationYn ?? null,
                        shareholderType: newSharesAcquirer.shareholderType || "",
                        resourceType: newSharesAcquirer.resourceType || null,
                        existingShareholdersYn: newSharesAcquirer.existingShareholdersYn ?? null,
                        representatives: newSharesAcquirer.representatives || [{
                            id: "",
                            name: ""
                        }]
                    }
                })
            } else {
                this.newSharesAcquirers = [];
            }
        })
    },
    setClassStockAndInvestmentt(data) {
        runInAction(() => {
            if(data?.newShares && data?.newShares.length) {
                this.newShares = data.newShares.map((newShare) => {
                    return {
                        id: newShare.id ?? "",
                        name: newShare.name || "",
                        가수금증자: newShare.가수금증자 ?? null,
                        유상증자: newShare.유상증자 ?? null
                    }
                })
            } else {
                this.newShares = [];
            }
            this.sharesOnRegister.classStocksOnRegister = data?.sharesOnRegister?.classStocksOnRegister || [];
            this.sharesOnRegister.totalCapitalStock = data?.sharesOnRegister?.totalCapitalStock || null;
            this.sharesOnRegister.totalNumberOfSharesOnRegister = data?.sharesOnRegister?.totalNumberOfSharesOnRegister || null;
        })
    },
    setMethodOfIssuingNewSharest(data) {
        runInAction(() => {
            if(data.existingShareholders && data.existingShareholders.length !== 0) {
                this.existingShareholders = data.existingShareholders.map((item) => {
                    return {
                        name: item.name || "",
                        stocks: item.stocks || [{
                            stockType: "",
                            amount: null
                        }]
                    }   
                })
            } else {
                this.existingShareholders = [];
            }            
            this.issuingNewShareProcess = data?.issuingNewShareProcess || null;
            this.requiresNoti = data?.requiresNoti ?? false;
            this.newShareAssignmentType = data?.newShareAssignmentType || null;
            this.noticeDate = data?.noticeDate || {
                year: "",
                month: "",
                day: ""
            }
            this.noticeType = data?.noticeType || "";
        })
    }
});

export default PaidInCapitalIncreaseStepModel;

