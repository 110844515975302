import { useObserver } from 'mobx-react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import st from './ParValue.module.scss';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import Select from '@comComponents/atoms/Input/Select/Select';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import ParValueVm from './ParValueVm'
import { addCommas } from '@common/module/replaceNumber';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';

function ParValue() {
    return useObserver(() => (
        <form onSubmit={(e) => ParValueVm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            <Question>
                <ContentBox className={Qst.spaceBetween}>
                    <div className={Qst.infoSection}>
                        <div className={Qst.titleWrap}>
                            <h2 className={Qst.title}>
                                <span className={Qst.bold}>{ParValueVm.corporationYn ? '1주당 금액' : '출자 1좌의 금액'}을 선택</span>해 주세요.
                            </h2>
                            <div className={Qst.titleExplain}>
                                상법상 100원 단위로 입력가능합니다.
                            </div>
                        </div>
                        <div className={st.selectWrap}>
                            <Select className={Qst.select} explain={ParValueVm.corporationYn ? '1주당 금액(액면금)' : '출자 1좌의 금액'} name="parValue" value={ParValueVm.parValue ?? ''} onChange={(e) => ParValueVm.setParValue(e)} errorText={<Error name="parValue" value={ParValueVm.parValue} errorCase={{required:ParValueVm.corporationYn ? '1주당 금액을 입력해 주세요.' : '출자 1좌의 금액을 입력해 주세요.'}}/>}>
                                <option value="">-</option> 
                                <option value="100">100원 (Best)</option>
                                <option value="500">500원</option>
                                <option value="1000">1,000원</option>
                                <option value="10000">10,000원</option>
                                <option value="direct">직접입력</option>
                            </Select>
                        </div>
                        {ParValueVm.parValue === "direct" &&
                            <div className={Qst.rowWrap}>
                                <InputText className={st.input} style={{display:'block', width:'277px', height:'58px', fontSize:'20px'}} placeholder="0" placeholderAlign="right" name="directParValue" value={addCommas(String(ParValueVm.directParValue))} onChange={(e) => ParValueVm.setDirectParValue(e)} errorText={<Error name="directParValue" value={ParValueVm.directParValue} errorCase={{
                                    required: ParValueVm.corporationYn ? '1주당 금액을 입력해 주세요.' : '출자 1좌의 금액을 입력해 주세요.',
                                    pattern: {
                                        value: regExp.priceCheck().test(addCommas(String(ParValueVm.directParValue))),
                                        message: '100원 단위로 입력해 주세요.'
                                    }
                                }}/>}/>
                                <div className={st.won}>원</div> 
                            </div>
                        }
                    </div>
                    <div className={Qst.qnaSection}>
                        {ParValueVm.corporationYn ? 
                        <Qna tip="1주당 금액 설정방법" className={Qst.qnaTitle}>
                            <div className={Qst.qnaBox}>
                                <QText>1주당 금액이란?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>1주당 금액은 회사에서 발행하는 1주의 가격(=액면금)을 말합니다.</Answer>
                                    <p className={Qst.explain}>
                                        시장에서 거래되는 가격이 아니라 회사의 정관에서 정하고, 등기부에 기재하는 액면가입니다. 실제 주식의 가치는 1주당 금액과 다른 경우가 매우 많습니다.
                                    </p>
                                </div>
                            </div>
                            <div className={Qst.qnaBox}>
                                <QText>왜 100원을 추천하나요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>1주당 금액이 작을수록 주식을 쪼개서 팔기 쉽기 때문에, 상법상 최소한인 100원을 추천드립니다.</Answer>
                                    <p className={Qst.explain}>
                                        2018년에 삼성전자 주식을 1/50으로 낮추는 액면분할을 했던 것 기억하시나요? 1주당 금액이 높으면 나중에 액면분할을 해야할 수 있기 때문에, 처음부터 최소한으로 설정하는 것이 좋습니다. 액면분할을 하는 경우, 비용과 시간이 많이 듭니다.
                                    </p>
                                </div>
                            </div>
                            <div className={Qst.qnaBox}>
                                <QText>100원보다 작게 설정할 수 없나요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>상법상 최소 100원 이상, 100원 단위로 설정가능합니다.</Answer>
                                    <p className={Qst.explain}>
                                        대한민국에서는 상법상 1주의 최저 액면금액은 100원이기 때문에, 100원보다 작게 설정할 수 없고, 100원 단위로만 설정가능합니다.
                                    </p>
                                </div>
                            </div>
                        </Qna>
                        :
                        <Qna tip="출자 1좌의 금액 설정방법" className={Qst.qnaTitle}>
                            <div className={Qst.qnaBox}>
                                <QText>출자 1좌의 금액이란 무엇인가요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>출자 1좌의 금액이란 유한회사 설립시 투자하는 구좌 1개당 금액입니다.</Answer>
                                    <p className={Qst.explain}>
                                        출자 1좌의 금액은 100원 단위로 설정가능하고, 일반적으로 100원으로 정합니다.
                                    </p>
                                </div>
                            </div>
                        </Qna>
                        }
                    </div>
                </ContentBox>
            </Question>
        </form>
    ));
}

export default ParValue;
