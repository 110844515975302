import { useObserver } from 'mobx-react';
import ExecutiveMemberChangeOriginChangeVm from './ExecutiveMemberChangeOriginChangeVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './ExecutiveMemberChangeOriginChange.module.scss'
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import SearchAddress from '@comComponents/atoms/SearchAddress/SearchAddress';
import Check from '@comComponents/atoms/Input/Check/Check';
import TextBtn from '@comComponents/atoms/Button/TextBtn/TextBtn';
import Kssn from '../Kssn/Kssn';
import AddressCheck from '../AddressCheck/AddressCheck';
import Address from '../Address/Address';
import NewAddress from '../NewAddress/NewAddress';
import { Fragment } from 'react';

function ExecutiveMemberChangeOriginChange() {
    return useObserver(() => (
        <form onSubmit={(e) => ExecutiveMemberChangeOriginChangeVm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            {ExecutiveMemberChangeOriginChangeVm.keepProceedYn === false && //견적과 다르게 진행하는 경우_변경등기를 진행할 임원 선택
                <Question>
                    <ContentBox>
                        <div className={Qst.spaceBetween}>
                            <div className={Qst.infoSection}>
                                <div className={Qst.titleWrap}>
                                    <h2 className={Qst.title}>
                                        아래는 <span className={Qst.bold}>기존 등기부상 임원</span>입니다. <span className={Qst.bold}>변경등기를 할 분을 모두 선택</span>해 주세요.
                                    </h2>
                                    <div className={Qst.titleExplain}>
                                        과거에 그만둔 분이 아직 포함되어 있다면 꼭 체크를 해주세요. 아직 탈퇴처리가 마쳐지지 않은 상태입니다.
                                    </div>
                                    <div className={Qst.titleExplain}>
                                        변경할 분이 없다면 변경할 사람 없음에 체크를 해주세요.
                                    </div>
                                </div>
                                <div>
                                    <Check name="noChangeYn" className={st.noChange} style={{fontSize:'20px', fontWeight:'500', letterSpacing:'-0.5px', whiteSpace:'pre'}} boxType="square" value={ExecutiveMemberChangeOriginChangeVm.noChangeYn} checked={ExecutiveMemberChangeOriginChangeVm.noChangeYn} onChange={(e) => ExecutiveMemberChangeOriginChangeVm.setNoChangeYn(e)}>변경할 사람 없음</Check>
                                </div>
                                <div className={st.changeExecutiveMemberBox}>
                                    <div className={st.unCheck}><TextBtn className={st.unCheckBtn} onClick={() => {ExecutiveMemberChangeOriginChangeVm.unChecked()}}>선택해제</TextBtn></div>
                                    <ul className={st.changeExecutiveMemberList}>
                                        {ExecutiveMemberChangeOriginChangeVm.changeExecutiveMembers.map((item, idx) => {
                                            return (
                                                <li key={idx}>
                                                    <input type="hidden" name={`executiveMembers[${idx}].id`} value={item.id}/>
                                                    <Check name={`executiveMembers[${idx}].checkedYn`} className={st.changeExecutiveMember} style={{fontSize:'18px', letterSpacing:'-0.54px', whiteSpace:'pre'}}  value={item.checkedYn} checked={item.checkedYn} onChange={(e) => ExecutiveMemberChangeOriginChangeVm.setCheckedYn(e, idx)} activeBackground disabled={ExecutiveMemberChangeOriginChangeVm.noChangeYn}>
                                                        {item.koreanName} {ExecutiveMemberChangeOriginChangeVm.displayOriginPosition(idx)}님
                                                    </Check>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ContentBox>
                </Question>
            }
            {ExecutiveMemberChangeOriginChangeVm.changeExecutiveMembers.map((item, idx) => (
                <Fragment key={idx}>
                    {(ExecutiveMemberChangeOriginChangeVm.keepProceedYn === true || item.checkedYn === true) && //견적대로 진행 or 견적과 다르게 진행하는 경우에 변경등기 진행하는 임원
                        <Question>
                            <ContentBox>
                                {ExecutiveMemberChangeOriginChangeVm.keepProceedYn === true && <input type="hidden" name={`executiveMembers[${idx}].id`} value={item.id}/>}
                                {ExecutiveMemberChangeOriginChangeVm.keepProceedYn === false && //견적과 다르게 진행하는 경우_진행할 변경 등기 종류 선택
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={Qst.title}>
                                                    <span className={Qst.bold}>{item.koreanName}님</span>은 어떤 등기를 하나요?
                                                </h2>
                                            </div>
                                            <ul className={Qst.answerList}>
                                                {ExecutiveMemberChangeOriginChangeVm.display임기연장() &&
                                                    <li><Radio className={Qst.answerLabel} value="임기연장" activeBackground checked={item.changeType === '임기연장'} onChange={(e) => ExecutiveMemberChangeOriginChangeVm.setChangeType(e, idx)} name={`executiveMembers[${idx}].changeType`}>임기연장<br/><span className={st.regExplain}>예시) 기존에 이사를 맡았는데, 같은 직위(이사)로 재취임하는 경우</span></Radio></li>
                                                }
                                                <li><Radio className={Qst.answerLabel} value="보직변경" activeBackground checked={item.changeType === '보직변경'} onChange={(e) => ExecutiveMemberChangeOriginChangeVm.setChangeType(e, idx)} name={`executiveMembers[${idx}].changeType`}>보직변경<br/><span className={st.regExplain}>{ExecutiveMemberChangeOriginChangeVm.display보직변경Text()}</span></Radio></li>
                                                <li><Radio className={Qst.answerLabel} value="사임_퇴임" activeBackground checked={item.changeType === '사임_퇴임'} onChange={(e) => ExecutiveMemberChangeOriginChangeVm.setChangeType(e, idx)} name={`executiveMembers[${idx}].changeType`}>사임/퇴임<br/><span className={st.regExplain}>등기부에서 삭제</span></Radio></li>
                                                {ExecutiveMemberChangeOriginChangeVm.originRepresentativeYn(idx) === true &&
                                                    <li><Radio className={Qst.answerLabel} value="집주소변경" activeBackground checked={item.changeType === '집주소변경'} onChange={(e) => ExecutiveMemberChangeOriginChangeVm.setChangeType(e, idx)} name={`executiveMembers[${idx}].changeType`}>집주소 변경<br/><span className={st.regExplain}>다른 변경 없이 집주소만 변경 (임기연장, 보직변경을 하면서 동시에 집주소를 변경하는 경우에는 임기연장, 보직변경 보기를 선택해주시면 됩니다.)</span></Radio></li>
                                                }
                                            </ul>
                                            <Error name={`executiveMembers[${idx}].changeType`} value={item.changeType} errorCase={{required:'진행하실 등기를 선택해주세요.'}}/>
                                        </div>
                                    </div>
                                }
                                {(ExecutiveMemberChangeOriginChangeVm.keepProceedYn === false && item.changeType === '보직변경') && //견적과 다르게 진행하는 경우_보직변경 등기를 진행할 때
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={Qst.title}>
                                                    <span className={Qst.bold}>{item.koreanName}님</span>은 앞으로 어떤 직책을 맡나요?
                                                </h2>
                                            </div>
                                            <ul className={Qst.answerList}>
                                                {ExecutiveMemberChangeOriginChangeVm.newPositionList(idx).map((position, idx2) => {
                                                    return (
                                                        <li key={idx2}><Radio className={Qst.answerLabel} value={position.value} activeBackground checked={item.newPosition === position.value} onChange={(e) => ExecutiveMemberChangeOriginChangeVm.setNewPosition(e, idx)} name={`executiveMembers[${idx}].newPosition`}>{position.text}</Radio></li>
                                                    )
                                                })}
                                            </ul>
                                            <Error name={`executiveMembers[${idx}].newPosition`} value={item.newPosition} errorCase={{required:'현재 주민등록상 주소가 등기부상 주소와 같은지 선택해 주세요.'}}/>
                                        </div>                                    
                                        <div className={Qst.qnaSection}>
                                            <Qna className={Qst.noTitle}>
                                                <div className={Qst.qnaBox}>
                                                    <QText>단독대표, 각자대표, 공동대표의 차이점은 무엇인가요?</QText>
                                                    <div className="answerBox">
                                                        <Answer className={Qst.answer} iconText="A1">단독대표는 1명이 회사의 대표를 맡는 경우입니다.</Answer>
                                                        <p className={Qst.explain}>
                                                            90% 고객님들은 단독대표를 선택합니다.
                                                        </p>
                                                        <Answer className={Qst.answer} iconText="A2">각자대표는 2명 이상의 대표가 각각 독립적으로 회사의 업무를 결정하는 경우입니다.</Answer>
                                                        <p className={Qst.explain}>
                                                            각자 대표의 경우, 각 대표가 단독으로 무엇이든 결정할 수 있기에, 회사 경영이 편리합니다. 다만 대표가 잘못된 결정을 할 때, 사전에 막기 어렵다는 단점이 있습니다.
                                                        </p>
                                                        <Answer className={Qst.answer} iconText="A3">공동대표는 2명 이상의 대표가 항상 함께 회사의 업무를 결정하는 경우입니다.</Answer>
                                                        <p className={Qst.explain}>
                                                            공동대표는 항상 함께 결정해야 하고, 계약서에도 공동대표의 도장이 모두 들어가야 하므로, 회사 경영시, 번거롭습니다. 하지만 경영권을 감시하기 위한 목적에는 적합합니다.
                                                        </p>
                                                    </div>
                                                </div>
                                            </Qna>
                                        </div>
                                    </div>
                                }
                                {ExecutiveMemberChangeOriginChangeVm.displayKssn(idx) && //주민등록번호 입력
                                    <Kssn name='executiveMembers' idx={idx} item={item} parentVm={ExecutiveMemberChangeOriginChangeVm} />
                                }
                                {ExecutiveMemberChangeOriginChangeVm.displayAddressCheck(idx) && //주소 확인
                                    <AddressCheck name='executiveMembers' idx={idx} item={item} parentVm={ExecutiveMemberChangeOriginChangeVm}/>
                                }
                                {ExecutiveMemberChangeOriginChangeVm.displayAddress(idx) && //변경된 주소 입력 및 전입신고or거소등록일자
                                    <Address name='executiveMembers' idx={idx} item={item} parentVm={ExecutiveMemberChangeOriginChangeVm} origin/>
                                }
                                {ExecutiveMemberChangeOriginChangeVm.displayNewAddress(idx) &&  //신규주소 입력
                                    <NewAddress name='executiveMembers' idx={idx} item={item} parentVm={ExecutiveMemberChangeOriginChangeVm} origin />
                                }
                            </ContentBox>
                        </Question>
                    }
                </Fragment>                
                )
            )}
            {/* 도로명주소 팝업 */}
            {ExecutiveMemberChangeOriginChangeVm.addressModal && 
                <SearchAddress onClose={() => ExecutiveMemberChangeOriginChangeVm.openAddressModal(false,null,null)} onComplete={(data) => ExecutiveMemberChangeOriginChangeVm.addressComplate(data)} />
            }
        </form>
    ));
}

export default ExecutiveMemberChangeOriginChange;