import ConvertibleBondStepModel from '@model/changeRegistration/ConvertibleBondStepModel';
import stepService from "@service/stepService";
import formDataToJson from '@common/module/submit';
import { runInAction } from 'mobx';

class ConvertibleBondDepositProofVm {
    get convertibleBondDepositProofType() {
        return ConvertibleBondStepModel.convertibleBondDepositProofType;
    }
    setConvertibleBondDepositProofType(e) {
        runInAction(() => {
            ConvertibleBondStepModel.convertibleBondDepositProofType = e.target.value;
        })
    }
    submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            stepService.saveStep(param);
        }
    }
} 

export default new ConvertibleBondDepositProofVm();