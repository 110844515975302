import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import down from '../../asset/icon/btn_info_down.svg';
import up from '../../asset/icon/btn_info_up.svg';
import clsx from 'clsx';

const QnaSection = styled.div`
    position: relative;
    &.hide {
        .answerBox {
            height: 0;
            overflow: hidden;
            transition: padding 0.15s ease-out;
        }
    }
    &.view {
        .answerBox {
            margin-top: -11px;
            padding-top: 11px;
            height: auto;
            transition: padding 0.15s ease-out;
        }
    }
`;

const Title = styled.div`
    cursor: pointer;
    .tip {
        margin-left: 9px;
    }
`;

const OpenButton = styled.button`
    position: absolute;
    right: 0;
    top: -2px;
    &:active {
        right: -1px;
        top: -1px;
    }
    padding: 1px 27px 1px 0; 
    color: #006ac3;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.32px;
    background-color: transparent;   
    background-image: url(${(props) => props.isOpen ? up : down});
    background-repeat: no-repeat;
    background-size: 20px;
    background-position-x: 65px;
    background-position-y: 4px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
        padding: 4px 27px 4px 0;
    }
`;


/**
* 열고 닫히는 부분 &lt;div className="answerBox"&gt;&lt;/div&gt;으로 감싸주기
*/

function QnaBox(props) {

    const [click, setClick] = useState(false);

    const isOpen = (e) => {
        e.preventDefault();
        if(click) {
            setClick(false);
        } else {
            setClick(true);
        }
    }

    return (
        <QnaSection className={clsx(click ? "view" : "hide", props.className)} style={props.style}>
            {props.tip ? 
                <Title className={props.className}  onClick={(e) => isOpen(e)}>{props.tip}
                    <OpenButton type="button" onClick={(e) => isOpen(e)} isOpen={click} tip={props.tip}>
                        {click ? '답변닫기' : '답변보기'}
                    </OpenButton>
                </Title> 
                : 
                <OpenButton type="button" onClick={(e) => isOpen(e)} isOpen={click} tip={props.tip}>
                    {click ? '답변닫기' : '답변보기'}
                </OpenButton>
            }

            {/* {props.children} */}
            {click ? 
                <div>{props.children}</div>
                : 
                <div style={{cursor:'pointer'}} onClick={(e) => isOpen(e)}>{props.children}</div>
            }
        </QnaSection>
    );
};

QnaBox.propTypes = {
    /**
    *  class명
    */
    className: PropTypes.string,
    /**
    * style 속성
    */
    style: PropTypes.object,
    /**
    * 클릭 이벤트
    */
    onClick: PropTypes.func,
    /**
    * tip 내용 <br/>
    * ex) 도장제작 방법
    */
    tip: PropTypes.string,
    /**
    * Qna 콘텐츠
    */
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.any,
    ])
};

QnaBox.defaultProps = {
    className: null,
    style: {},    
    onClick: () => {},
    tip: null,
    children: 'Empty',
};

export default QnaBox;

