import StandbyPaidInCapitalIncreaseStepModel from '@model/changeRegistration/StandbyPaidInCapitalIncreaseStepModel';
import { runInAction, observable } from 'mobx';
import { _alert, _confirm } from "@model/dialogModel";

class FundAndInvestmentAssociationVm {
    setKoreanName(e,idx) {
        runInAction(() => {
            StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanName = e.target.value;
        })
    } 
    setKoreanAddress(e,idx) {
        runInAction(() => {
            StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanAddress = e.target.value;
        })
    }
    setRepresentativesName(e, idx, idx2) {    
        runInAction(() => {
            StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].representatives[idx2].name = e.target.value;
        })
    }
    addRepresentatives(idx) {
        StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].representatives.push({
            id: "",
            name: ""
        })
    }
    async setRepresentativesCloseBtn(idx, idx2) {
        const representatives = StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].representatives;
        let name = representatives[idx2].name;
        if(!name) {
            name = '해당 대표';
        }
        const conf = await _confirm(name+'님의 정보를 삭제하시겠습니까?')
        if(conf){
            return representatives.splice(idx2,1);
        } else {
            return 
        }
    }
}

export default new FundAndInvestmentAssociationVm();