import { useObserver } from 'mobx-react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './BusinessPurpose.module.scss';
import clsx from 'clsx';
import HyperLink from '@comComponents/atoms/HyperLink/HyperLink/HyperLink';
import Check from '@comComponents/atoms/Input/Check/Check';
import TextBtn from '@comComponents/atoms/Button/TextBtn/TextBtn';
import Plus from '@comComponents/atoms/Button/PlusBtn/PlusBtn';
import Minus from '@comComponents/atoms/Button/MinusBtn/MinusBtn';
import SortBtn from '@comComponents/atoms/Button/SortBtn/SortBtn';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import SquareBtn from '@comComponents/atoms/Button/SquareBtn/SquareBtn';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import Icon from '@comComponents/atoms/Icon/Icon';
import {addCommas} from '@common/module/replaceNumber';
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import BusinessPurposeVm from './BusinessPurposeVm.js';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';

const DragHandle = sortableHandle(() => <SortBtn className={st.sortBtn}/>);
const SortableItem = sortableElement(({businessPurpose, idx, sortYn, length}) => {
  return useObserver(() => (
      <li key={idx} className={clsx(st.sortableItem, businessPurpose?.checked ? st.checkedItem : null)}>
          <input key={idx+length+'checkbox'+businessPurpose?.checked} type="checkbox" id={`businessPurpose${idx}`} value={businessPurpose?.checked || false} className={st.checkBox} checked={businessPurpose?.checked || false} onChange={(e) => BusinessPurposeVm.checked(e, idx)}/>
          <label className={st.check} htmlFor={`businessPurpose${idx}`}/>
          <label className={st.businessPurposeNumber}>1. </label>
          <textarea key={idx+length+'text'} name={`businessPurposes[${idx}].content`} className={st.inputText} value={businessPurpose.content} onChange={(e) => BusinessPurposeVm.content(e, idx)} onBlur={(e) => BusinessPurposeVm.enterText(e, idx)} data-error={BusinessPurposeVm.dataError(businessPurpose.content).errorState}/>
          <input type="hidden" key={idx+length+'hidden'} value={businessPurpose.id || ''} name={`businessPurposes[${idx}].id`}></input>
          <div className={st.btnWrap}>
            <Plus className={st.plus} onClick={() => BusinessPurposeVm.addRow(idx)}/>
            <Minus className={st.minus} onClick={() => BusinessPurposeVm.deleteRow(idx)}/>
          </div>
          {(BusinessPurposeVm.dataError(businessPurpose.content).errorState === 'error' && BusinessPurposeVm.state.isChanged) && 
            <div className={st.errorArea}>
              <ErrorText>{BusinessPurposeVm.dataError(businessPurpose.content).errorMessage}</ErrorText>
            </div>
          }
          {sortYn && <DragHandle></DragHandle>}            
      </li>
  ))
})
const SortableContainer = sortableContainer(({children}) => {
  return <ul>{children}</ul>;
});
function BusinessPurpose() {
  //const [, updateState] = useState();
  //const forceUpdate = useCallback(() => updateState({}), []);
  return useObserver(() => (
    <form onSubmit={(e) => BusinessPurposeVm.submit(e)}>
      <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
      <Question>
        <ContentBox className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
              <div className={Qst.titleWrap}>
                <h2 className={Qst.title}>
                  <span className={Qst.bold}>헬프미 추천 목적을 참고하여 사업목적을 입력</span>해 주세요.
                </h2>
                <div className={Qst.titleExplain}>
                  20개까지 무료이며, 초과시 개당 5,500원의 추가비용이 발생합니다.
                </div>
                <div className={Qst.titleExplain}>
                  <HyperLink color="blue" title="헬프미 추천목록으로 이동" href="https://docs.google.com/spreadsheets/d/1ihUpBpoLdOg5xXXFUqB8nkVkVnzL_k78euttBu9D_Ug/edit#gid=1735182456" target="_blank">헬프미 추천목적</HyperLink>을 참고하시면, 쉽고 빠르게 선택하실 수 있습니다.
                </div>
              </div>
              <div className={st.checkHeader}>
                <div>
                  <Check style={{fontSize:'17px', top:'1px', whiteSpace:'pre'}} boxType="allSquare" value={BusinessPurposeVm.isCheckedAll} checked={BusinessPurposeVm.isCheckedAll} onChange={(e) => BusinessPurposeVm.checkedAll(e)}>전체선택 ({BusinessPurposeVm.checkedLength()}/{BusinessPurposeVm.businessPurposes.length})</Check>
                </div>
                <div>
                  <TextBtn onClick={() => {BusinessPurposeVm.selectedDelete()}}>선택삭제</TextBtn>
                </div>
              </div>
              <ul className={clsx(Qst.answerList, st.answerList)}>
                  <SortableContainer onSortEnd={(e) => BusinessPurposeVm.onSortEnd(e)} useDragHandle helperClass={st.helperClass}>
                    {BusinessPurposeVm.businessPurposes.map((businessPurpose, idx) => (
                      <SortableItem businessPurpose={businessPurpose} index={idx} idx={idx} key={idx} length={BusinessPurposeVm.businessPurposes.length} sortYn={BusinessPurposeVm.businessPurposes.length > 1}></SortableItem>
                    ))}
                    <li className={clsx(st.sortableItem, st.fixedText)}>
                      <span className={st.number}>1.</span>위 각호에 관련된 부대사업 일체
                    </li>
                  </SortableContainer>
                  <li><button type="button" onClick={() => BusinessPurposeVm.addBusinessPurposes()} className={st.businessPurposeAddBtn}>사업목적 추가하기<Icon icon="addGray" className={st.addGray}/></button></li>
              </ul>
            </div>
            <div className={Qst.qnaSection}>
              <Qna tip="사업목적" className={Qst.qnaTitle}>
                <div className={Qst.qnaBox}>
                  <QText>사업목적이란 무엇인가요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>사업목적은 앞으로 회사가 운영할 사업내용입니다.</Answer>
                    <p className={Qst.explain}>
                      설립할 때, 당장 시작할 사업 뿐만 아니라, 장래에 운영할 가능성이 있는 사업목적까지 넣는 편을 추천드립니다. 설립 이후에 사업목적을 추가할 때, 세금과 수수료가 들기 때문입니다.
                    </p>
                  </div>
                </div>
                {BusinessPurposeVm.smartFormType === 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY' && //농업회사법인
                <div className={Qst.qnaBox}>
                  <QText>농업회사법인의 사업목적을 자유롭게 정할 수 있나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>아니요. 농업과 관련한 사업목적만 쓸 수 있습니다.</Answer>
                    <p className={Qst.explain}>
                      농업회사법인의 경우, 아래 목적사항만 추가할 수 있습니다. (농림축산식품부 입장)
                    </p>
                    <TextBox title="예시" className={Qst.textBox}>
                      <ul className={st.purpose}>
                          <li>농업경영업</li>
                          <li>농산물의 출하, 유통, 가공, 판매 및 수출업</li>
                          <li>농작업 대행업</li>
                          <li>농어촌관광휴양사업</li>
                          <li>농촌융복합산업</li>
                          <li>영농에 필요한 자재의 생산 및 공급사업</li>
                          <li>영농에 필요한 종자생산 및 종균배양사업</li>
                          <li>농산물의 구매 및 비축사업</li>
                          <li>농업기계 기타 장비의 임대, 수리, 보관업</li>
                          <li>소규모 관개시설의 수탁, 관리사업</li>
                          <li>농업과 관련된 공동이용시설의 설치 및 운영업</li>
                      </ul>
                    </TextBox>
                    <p className={Qst.explain}>
                    농촌융복합산업은 농촌융복합산업 육성 및 지원에 관한 법률 제8조에 따라 인증받은자를 의미하며, 인증을 받아야 등기부상 목적 기재가 가능한 점 참고해주세요.
                    인증을 받지 않으셨을 경우 제외하고 기재하시는 것을 권유드립니다.
                    </p>
                  </div>
                </div>
                }
                {BusinessPurposeVm.smartFormType === 'ESTABLISHMENT_FISHERY_STOCK_COMPANY' && //어업회사법인
                <div className={Qst.qnaBox}>
                  <QText>어업회사법인의 사업목적을 자유롭게 정할 수 있나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>아니요. 어업과 관련한 사업목적만 쓸 수 있습니다.</Answer>
                    <p className={Qst.explain}>
                      어업회사법인의 경우, 아래 목적사항만 추가할 수 있습니다. (농림축산식품부 입장)
                    </p>
                    <TextBox title="예시" className={Qst.textBox}>
                      <ul className={st.purpose}>
                        <li>어업경영업</li>
                        <li>수산물의 유통, 가공, 판매업</li>
                        <li>영어에 필요한 자재의 생산 및 공급사업</li>
                        <li>영어에 필요한 종묘생산사업</li>
                        <li>수산물의 구매 및 비축사업</li>
                        <li>수산장비 등의 임대&middot;수리 및 보관사업</li>
                        <li>어촌관광휴양사업</li>
                      </ul>
                    </TextBox>
                  </div>
                </div>
                }
                <div className={Qst.qnaBox}>
                  <QText>사업목적은 어떻게 정하나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer} iconText="A1">헬프미 추천목적에서 선택해보세요.</Answer>
                    <p className={Qst.explain}>
                      헬프미에서는 30,000개 이상의 등기부를 분석하여 각 업종 별 가장 많이 사용되는 목적을 정리했습니다. 아래 [헬프미 추천목록 바로가기] 버튼을 클릭해서, 사업목적을 선택하면 쉽고 빠르게 결정할 수 있습니다.
                    </p>
                    <SquareBtn addimage="document" className={st.squareBtn} onClick={() => window.open('https://docs.google.com/spreadsheets/d/1ihUpBpoLdOg5xXXFUqB8nkVkVnzL_k78euttBu9D_Ug/edit#gid=1735182456')}>헬프미 추천목적 바로가기</SquareBtn>
                    <Answer className={Qst.answer} iconText="A2">직접 사업목적을 정해보세요.</Answer>
                    <dl className={clsx(Qst.defList, st.defList)}>
                      <dt className={clsx(Qst.term, st.term)}>[구체적인 사업분야] + [일반적인 사업종류]를 조합해 목적을 정해보세요.</dt>
                      <dd className={Qst.def}>
                        예를 들면 건강식품을 도매로 사고파는 사업을 하고 싶다면, 건강식품 + 도소매업 &rarr; "건강식품 도소매업" 이렇게 정하면 됩니다.
                      </dd>
                      <dd className={Qst.def}>
                        <TextBox title="예시" className={Qst.textBox}>
                          <div className={clsx(Qst.textWrap, st.textWrap)}>
                            <div className={st.wrap}>
                              <div className={st.title}>사업분야</div>
                              <div className={st.content}>건강식품</div>
                            </div>
                            <Icon icon="plusGray" className={st.plusGray}/>
                            <div className={st.wrap}>
                              <div className={st.title}>사업종류</div>
                              <div className={st.content}>도소매업</div>
                            </div>
                            <Icon icon="arrow" className={st.arrow}/>
                            <div className={st.wrap}>
                              <div className={st.title}>사업목적</div>
                              <div className={st.content}>건강식품 도소매업</div>
                            </div>
                          </div>
                          <div className={clsx(Qst.textWrap, st.exampleWrap)}>
                            <div className={st.area}>구체적인<br/>사업분야</div>
                            <div className={st.areaExample}><span className={st.bold}>건강식품</span>, 의류, 화장품, 광고, 데이터 베이스, 식자재, 식품, 전자기기, 자동차, 소프트웨어, 부동산, 인터넷, 캐릭터, 홍보&middot;&middot;&middot;</div>
                          </div>
                          <div className={clsx(Qst.textWrap, st.exampleWrap)}>
                            <div className={st.area}>일반적인<br/>사업종류</div>
                            <div className={st.areaExample}><span className={st.bold}>도소매업</span>, 연구개발업, 수출입업, 제조판매업, 판매업, 유통업, 공급업, 제조업, 서비스업, 유통업, 임대업, 유지 및 보수업&middot;&middot;&middot;</div>
                          </div>
                        </TextBox>
                      </dd>
                      <dt className={clsx(Qst.term, st.term)} style={{marginTop:'16px'}}>구체적인 사업분야를 꼭 넣어주세요.</dt>
                      <dd className={Qst.def}>
                        구체적인 사업분야를 포함시키지 않고, 일반적인 사업종류만 넣는 경우, 지나치게 추상적이어서 법원에서 허용해주지 않습니다.
                      </dd>
                      <dd className={Qst.def}>
                        <TextBox title="예시" className={Qst.textBox}>
                          <div className={Qst.textWrap}>
                            <span className={Qst.false}>도매업</span><Icon icon="arrow" className={Qst.iconArrow}/><span className={Qst.true}>컴퓨터부품 도매업</span>
                          </div>
                          <div className={Qst.textWrap}>
                            <span className={Qst.false}>무역업</span><Icon icon="arrow" className={Qst.iconArrow}/><span className={Qst.true}>농수산물 무역업</span>
                          </div>
                        </TextBox>
                      </dd>
                      <dt className={clsx(Qst.term, st.term)} style={{marginTop:'16px'}}>영어 기재는 한글을 먼저 쓰고 괄호 안에 영어를 써주세요.</dt>
                      <dd className={Qst.def}>
                        물론 한글로만 적어도 괜찮습니다.
                      </dd>
                      <dd>
                        <TextBox title="예시" className={Qst.textBox}>
                          <div className={Qst.textWrap}>
                            <span className={Qst.false}>It System 제공업</span><Icon icon="arrow" className={Qst.iconArrow}/><span className={Qst.true}>아이티 시스템(It System) 제공업</span>
                          </div>
                        </TextBox>
                      </dd>
                    </dl>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>온라인 판매예정이라면 꼭 넣어야 하는 목적이 있나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>온라인 판매 예정이라면 '위 각호에 관련된 전자상거래업 및 통신판매업'을 가장 마지막에 넣어주세요.</Answer>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>부동산 임대업을 목적에 넣어도 불이익이 없나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>일반적으로 목적에 부동산 임대업을 넣어도 불이익이 없습니다. (일부 예외 존재)</Answer>
                    <p className={Qst.explain}>
                      특별한 불이익은 없으므로, 필요한 경우, [주거용, 비주거용 부동산 임대업 및 전대업]이라고 추가하는 편을 추천드립니다. 단 특수한 목적을 가진 법인(부동산 중개법인, 대부업, 대부중개업 법인, 건축사사무소 법인 등)은 이러한 목적을 넣을 수 없으므로, 주의가 필요합니다. 또한 소프트웨어 개발 목적으로만 설립하는 경우, 설립시 납부하는 공과금을 감면받을 수 있는데, 부동산 임대업 목적을 넣으면 감면을 받을 수 없으므로, 이 부분도 주의가 필요합니다.
                    </p>
                    <p className={Qst.explain}>
                      목적에 넣은 후 반드시 사업자등록증에 포함시킬 필요가 없기 때문에, 나중에 부동산을 직접 매입해서 임대를 시작할 수 있는 상황이 되면 사업자등록증에 포함시키면 됩니다. 사업자등록증에 포함시키는 절차는 국세청 홈택스를 통해 직접 진행하시거나, 담당 세무사님을 통해 진행해주시면 됩니다.
                    </p>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>부동산 임대, 매매목적 법인이라면 어떤 목적을 추천하나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>부동산 임대, 매매목적 법인이라면 다음 목적을 추천드립니다.</Answer>
                    <TextBox title="예시" className={Qst.textBox}>
                      <ul className={st.purpose}>
                        <li>부동산 매매업</li>
                        <li>
                          주거용, 비주거용 부동산 임대업 및 전대업<br/>
                          <span className={st.purposeExplain}>다만 법인소유의 부동산이 있는 경우에만 부동산 임대업을 사업자등록증에 추가 가능합니다. 따라서 설립을 마치고 부동산을 취득한 후에 사업자등록증에 임대업을 추가할 수 있습니다. 또한 관할에 따라 임대하려는 물건지를 지점으로 설치해야 할 수도 있고, 주택의 경우 별도의 임대사업자 등록이 필요합니다.</span>
                        </li>
                        <li>부동산 경매 및 공매 입찰업</li>
                        <li>주거용, 비주거용 부동산 관리업</li>
                        <li>
                          부동산 개발업 및 시행업<br/>
                          <span className={st.purposeExplain}>건축물의 연면적이 2천㎡ 또는 연간 5천㎡ 이상이거나 토지의 면적이 3천㎡ 또는 연간 1만㎡ 이상인 경우, 후후 부동산 개발업 등록을 해야 하고, 최저자본금을 3억 원 이상으로 설정해야 합니다.  또한 이 경우 정식 사무실을 갖춰야 합니다.</span>
                        </li>
                        <li>부동산 분양업</li>
                        <li>부동산 관련 콘텐츠 개발 및 판매업</li>
                        <li>부동산 컨설팅업</li>
                        <li>
                          주택 신축 판매업<br/>
                          <span className={st.purposeExplain}>연간 20세대 이상 (도시형 생활주택의 경우 30세대 이상) 이상의 주택 사업을 시행하려는 경우, 최저자본금을 3억 원 이상으로 설정하여야 하고, 추후 주택건설업 면허 등록이 필요합니다.</span>
                        </li>
                      </ul>
                    </TextBox>
                    <LikeBtn className={Qst.likeBtn} href="https://blog.help-me.kr/2019/01/부동산임대업·부동산매매업-법인설립-qa-9가지/" target="_blank">부동산 임대, 매매법인 더 알아보기</LikeBtn>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>부동산중개법인이라면 어떤 목적을 넣을 수 있나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>부동산중개법인이라면 다음 목적만 넣을 수 있습니다.<br/> 부동산 임대업X 부동산 매매업X</Answer>
                    <p className={Qst.explain}>
                      법인인 공인중개사는 법률에 정해진 업무 외에 다른 업무를 할 수 없습니다. 중개법인이 가능한 목적은 아래와 같습니다.
                    </p>
                    <TextBox title="예시" className={Qst.textBox}>
                      <ul className={st.purpose}>
                        <li>부동산중개업</li>
                        <li>상업용 건축물 및 주택의 임대관리 등 부동산의 관리대행</li>
                        <li>부동산의 이용 및 개발에 관한 상담</li>
                        <li>개업공인중개사를 대상으로 한 중개업의 경영기법 및 경영정보의 제공</li>
                        <li>중개의뢰인의 의뢰에 따른 도배, 이사업체의 소개 등 주거이전에 부수되는 용역의 알선</li>
                        <li>상업용 건축물과 주택의 분양대행</li>
                        <li>경매 또는 공매대상 부동산에 대한 권리분석 및 취득의 알선과 매수신청 또는 입찰신청의 대리</li>
                      </ul>
                    </TextBox>
                    <LikeBtn className={Qst.likeBtn} href="https://blog.help-me.kr/2018/06/부동산중개법인/" target="_blank">부동산중개법인 더 알아보기</LikeBtn>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>대부업, 대부중개업 법인의 경우, 주의할 점이 있나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>대부업, 대부중개업 법인의 경우, 회사 이름에 대부, 대부중개를 넣어주세요.</Answer>
                    <p className={Qst.explain}>
                      목적에 대부업, 대부중개업을 넣을 뿐만 아니라, 회사 이름에도 넣어야 한다는 점 꼭 기억해주세요.
                    </p>
                    <LikeBtn className={Qst.likeBtn} href="https://info.help-me.kr/hc/ko/articles/360029986411" target="_blank">대부업 법인 더 알아보기</LikeBtn><br/>
                    <LikeBtn className={Qst.likeBtn} style={{marginTop:'10px'}} href="https://info.help-me.kr/hc/ko/articles/360029651072" target="_blank">대부중개업 법인 더 알아보기</LikeBtn>
                  </div>
                </div>              
              </Qna>
            </div>
        </ContentBox>
      </Question>
      {BusinessPurposeVm.businessPurposes.length > 20 &&
        <Question>
          <ContentBox className={Qst.spaceBetween}>
              <div className={Qst.infoSection}>
                <div className={Qst.titleWrap}>
                  <h2 className={Qst.title}>
                    <span className={Qst.bold}>목적 추가금액</span>
                  </h2>
                  <div className={Qst.titleExplain}>
                    법인설립 금액과 합산되어 마지막에 결제됩니다.
                  </div>
                </div>
                <table className={Qst.priceTable}>
                  <colgroup>
                    <col width="132px"/>
                    <col width="242px"/>
                    <col width="186px"/>
                  </colgroup>
                  <thead>
                    <tr>
                      <th>추가 개수</th>
                      <th>개당 금액</th>
                      <th>목적 추가금액</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={Qst.priceContentRow}>
                      <td>
                        <div className={Qst.priceContentWrap}>  
                          {BusinessPurposeVm.businessPurposes.length - 20}<span className={Qst.text}>개</span><Icon icon="mul" className={Qst.mul}/>
                        </div>
                      </td>
                      <td>
                        <div className={Qst.priceContentWrap}>
                          {addCommas(5500)}<span className={Qst.text}>원</span>
                        </div>
                      </td>
                      <td className={Qst.resultPrice}>
                        <div className={Qst.priceContentWrap}>
                          <Icon icon="result" className={Qst.result}/>
                          {addCommas((BusinessPurposeVm.businessPurposes.length - 20) * 5500)}<span className={Qst.resultText}>원</span>
                      </div>
                      </td>
                    </tr>
                  </tbody>
                </table>             
              </div>
          </ContentBox>
        </Question>
      }
    </form>
  ));
}

export default BusinessPurpose;
