import { useObserver } from 'mobx-react';
import { Fragment } from 'react';
import FileUploadVm from './FileUploadVm';
import RegisterBusiness from '../RegisterBusiness/RegisterBusiness';
import AOA from '../AOA/AOA';
import StockholdersList from '../StockholdersList/StockholdersList';
import LimitStockholdersList from '../LimitStockholdersList/LimitStockholdersList';
import Seal from '../Seal/Seal';
import InvestmentContract from '../InvestmentContract/InvestmentContract';
import BalanceCertificate from '../BalanceCertificate/BalanceCertificate';
import TRAccount from '../TRAccount/TRAccount';
import MinorDocument from '../MinorDocument/MinorDocument';
import ForeignDocument from '../ForeignDocument/ForeignDocument';
import FinancialPosition from '../FinancialPosition/FinancialPosition';
import FreeIssueOfNewSharesDocument from '../FreeIssueOfNewSharesDocument/FreeIssueOfNewSharesDocument';

function FileUpload() {
    return useObserver(() => (
        <form onSubmit={(e) => FileUploadVm.upload(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            {FileUploadVm.fileGroupTypes.map((item, idx) => (
                <Fragment key={idx}>
                    {item.fileGroupType === '사업자등록증' && <RegisterBusiness idx={idx} item={item} parentVm={FileUploadVm}/>}
                    {item.fileGroupType === '기존_정관' && <AOA idx={idx} item={item} parentVm={FileUploadVm}/>}
                    {item.fileGroupType === '주주명부' && <StockholdersList idx={idx} item={item} parentVm={FileUploadVm}/>}               
                    {item.fileGroupType === '사원명부' && <LimitStockholdersList idx={idx} item={item} parentVm={FileUploadVm}/>}
                    {item.fileGroupType === '인감대지' && <Seal idx={idx} item={item} parentVm={FileUploadVm}/>}
                    {item.fileGroupType === '투자계약서' && <InvestmentContract idx={idx} item={item} parentVm={FileUploadVm}/>}
                    {item.fileGroupType === '잔고증명서' && <BalanceCertificate idx={idx} item={item} parentVm={FileUploadVm}/>}
                    {item.fileGroupType === '가수금_계정별원장' && <TRAccount idx={idx} item={item} parentVm={FileUploadVm}/>}
                    {item.fileGroupType === '미성년서류' && <MinorDocument idx={idx} item={item} parentVm={FileUploadVm}/>}
                    {item.fileGroupType === '외국인서류' && <ForeignDocument idx={idx} item={item} parentVm={FileUploadVm}/>}
                    {item.fileGroupType === '재무상태표_재산목록' && <FinancialPosition idx={idx} item={item} parentVm={FileUploadVm}/>}
                    {item.fileGroupType === '무상증자_서류' && <FreeIssueOfNewSharesDocument idx={idx} item={item} parentVm={FileUploadVm}/>}
                </Fragment>
            ))}
        </form>
    ));
}
export default FileUpload;