import { toJS } from 'mobx';
import { Fragment } from 'react';
import { useObserver } from 'mobx-react';
import st from '../StandbyFileUpload/StandbyFileUpload.module.scss';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import Upload from '@comComponents/molecules/FileUpload/FileUpload';
import clsx from 'clsx';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';

function InvestmentContract(props) {
    return useObserver(() => (
        <Question>
            <ContentBox className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    {props.file.details.map((detail, idx2) => (
                        <Fragment key={idx2}>
                            {detail.fileType === '투자계약서' &&
                                <>
                                    <div className={Qst.titleWrap}>
                                        <h2 className={Qst.title}>
                                            <span className={Qst.bold}>투자계약서</span>를 업로드해 주세요.
                                        </h2>
                                    </div>
                                    <Upload id={detail.fileType} className={st.fileUpload} multiple accept=".docx, .hwp"
                                    model={props.parentVm.fileGroupTypes[props.idx].details[idx2].files}
                                    uploadModel={props.parentVm.fileGroupTypes[props.idx].details[idx2].uploadFiles}
                                    deleteModel={props.parentVm.deleteIds}
                                    files={toJS(props.parentVm.files(props.idx, idx2))} uploadFiles={toJS(props.parentVm.uploadFiles(props.idx, idx2))}       
                                    explainText={
                                        <>
                                            <div>
                                                <span className="bold">워드, 한글 파일만 업로드 가능</span>합니다. (PDF, JPG 파일 불가)
                                            </div>
                                            <div>
                                                영문이라면 <span className="bold">한글 번역본</span>을 꼭 첨부해 주세요.
                                            </div>
                                        </>
                                    } uploadText={false}
                                    >
                                        <span className={st.bold}>여기</span>를 눌러 업로드해 주세요.
                                    </Upload>
                                    {(props.parentVm.state.errorCheckYn && props.parentVm.displayErrorText(detail.fileType)) && <ErrorText>투자계약서를 업로드해 주세요.</ErrorText>}
                                </> 
                            }
                        </Fragment>
                    ))}
                </div>
            </ContentBox>
        </Question>
    ));
}

export default InvestmentContract;