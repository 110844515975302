import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import CorporateTaxVm from './CorporateTaxVm';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './CorporateTax.module.scss';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import clsx from 'clsx';

function CorporateTax() {
    return useObserver(() => (
        <form onSubmit={(e) => CorporateTaxVm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            <div className={st.corpTaxAdvertisement}>
                <div className={st.advertisement}>잘 몰라서 손해 본 <span className={st.bold}>법인세 돌려받으세요!</span></div>
                <div className={st.advertisementSub}>매년 <span className={st.bold}>평균 675만원</span>의 세금을 <span className={st.bold}>더 내고 있다는 사실</span>, 알고 계셨나요?</div>
                <img src='/images/accountant_1.jpg' className={st.accountantFirst} />
                <span className={st.accountantFirstTag}>서울대 경영대<br/>99학번</span>
                <img src='/images/accountant_2.jpg' className={st.accountantSecond} />
                <span className={st.accountantSecondTag}>서울대 경영대<br/>04학번</span>
            </div>
            <Question>
                <ContentBox className={Qst.spaceBetween} style={{paddingBottom:'30px'}}>
                    <div className={Qst.infoSection}>
                        <div className={Qst.titleWrap}>
                            <h2 className={Qst.title}>
                                법인세 환급 무료상담을 해드릴까요?
                            </h2>
                            <div className={clsx(Qst.titleExplain, st.titleExplain)}>무료상담을 받으시려면, <a href="https://docs.google.com/document/d/1ckkozKeS9k0U1tnUzhdThom7C37bliTQWAYZ2H5Dinw/edit" target="_blank" className={st.aLink}>제3자 정보제공 약관 동의</a>가 필요합니다.</div>
                        </div>
                        <ul className={Qst.answerList}>
                            <li>
                                <Radio className={Qst.answerLabel} value={true} activeBackground checked={CorporateTaxVm.freeCounselYn === true} onChange={(e) => CorporateTaxVm.setFreeCounselYn(e)} name="freeCounselYn" style={{letterSpacing:'-1.2px'}}>
                                    서울대 경영대출신 회계사에게 세금환급 무료상담을 받겠습니다.
                                    <div className={st.radioLabelExplain}>(제3자 정보제공 동의 포함)</div>
                                </Radio>
                            </li>
                            <li><Radio className={Qst.answerLabel} value={false} activeBackground checked={CorporateTaxVm.freeCounselYn === false} onChange={(e) => CorporateTaxVm.setFreeCounselYn(e)} name="freeCounselYn">세금환급 무료상담을 받지 않겠습니다.</Radio></li>
                        </ul>
                        <Error name="freeCounselYn" value={CorporateTaxVm.freeCounselYn} errorCase={{required:'법인세 환급 무료상담 여부를 선택해 주세요.'}}/>
                        <img src="/images/corporateTax.jpg" style={{marginTop: '50px'}} className={st.corporateTaxImg}/>
                    </div>
                    <div className={Qst.qnaSection}>
                        <Qna tip="법인세 환급" className={Qst.qnaTitle}>
                            <div className={Qst.qnaBox}>
                                <QText>법인세 환급이란 무엇인가요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>더 낸 법인세를 돌려받는 것입니다.</Answer>
                                    <p className={Qst.explain}>
                                        법인세 환급이란, 고객님의 법인이 최근 5년 간 받을 수 있었던 공제혜택이 있었는지 확인하고, 그동안 공제 받지 못한 세금을 다시 청구하여 돌려받는 것입니다.
                                    </p>
                                </div>
                            </div>
                            <div className={Qst.qnaBox}>
                                <QText>국가에서 법인세를 왜 돌려주나요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>공제혜택이 주어지기 때문입니다.</Answer>
                                    <p className={Qst.explain}>
                                        매년 국가에서 제공하는 세제 혜택이 있습니다만, 많은 대표님이 본인의 법인이 세제 혜택의 대상인지 미처 확인하지 못하여 환급을 받지 못하십니다. 실무적으로 매년 바뀌는 공제항목을 일일이 검토하여 적용한다는 것은 세무전문가에게도 쉽지 않은 일입니다. 이 때문에 경험이 풍부하고 노하우를 가지고 있는 법인세 전문 회계사에게 환급 혜택이 없는지 상담을 받아보시길 권장드립니다.
                                    </p>
                                </div>
                            </div>
                            <div className={Qst.qnaBox}>
                                <QText>무조건 법인세를 환급받을 수 있나요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>무조건은 아닙니다.</Answer>
                                    <p className={Qst.explain}>
                                        업종과 법인세 신고내역, 기타 검토사항에 따라 환급여부가 결정됩니다.
                                    </p>
                                </div>
                            </div>
                            <div className={Qst.qnaBox}>
                                <QText>어떻게 환급 받나요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>회계사가 직접 검토후 경정청구 해드립니다.</Answer>
                                    <p className={Qst.explain}>
                                        헬프미와 제휴하고 있는 회계법인의 회계사님이 직접 상담을 통해 알아봐드립니다. 여태까지 납부하신 법인세 내역을 살펴본 후, 고객님의 법인이 국가에서 정한 환급대상인지 검토한 뒤 세금을 돌려받을 수 있도록 경정청구를 도와드립니다. 경정청구가 끝나면 관할 세무서에서 환급금을 입금해드립니다.
                                    </p>
                                    <div className={st.keyWordExplain}>
                                        <div className={st.keyWord}>경정청구란?</div>
                                        <div className={st.explain}>
                                            경정청구란 납세신고를 한 날부터 5년 이내에 세무서에 청구하여야 세금 환급이 가능한 제도입니다. 따라서 5년이 지나면, 적용 대상이 아닙니다.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={Qst.qnaBox}>
                                <QText>세금 납부 시점 이후 5년이 지났습니다. 그래도 환급이 될까요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>빠른 확인이 필요합니다.</Answer>
                                    <p className={Qst.explain}>
                                        환급액 경정 청구는 5년 이내만 가능합니다. 그러니 최대한 빠르게 검토해야 합니다.
                                    </p>
                                </div>
                            </div>
                            <div className={Qst.qnaBox}>
                                <QText>환급청구를 하려면 세무대리인을 바꿔야 하나요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>세무대리인 변경 없이 환급청구가 가능합니다!</Answer>
                                    <p className={Qst.explain}>
                                        세금 환급은 기존 세무대리인 유무와 상관 없이 받을 수 있습니다.<br/>
                                        법인의 세금 환급은 전문 회계사가 진행하는게 정확하고 안전합니다. 세법에 따라 기업에 맞는 세제 혜택을 찾고 적용해야 정확하고 안전하게 환급 받을 수 있습니다.
                                    </p>
                                </div>
                            </div>
                            <div className={Qst.qnaBox}>
                                <QText>세금 환급 받으면 세무조사 받지 않을까요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>세금을 환급받는다고 세무조사를 받지 않습니다.</Answer>
                                    <p className={Qst.explain}>
                                        국세청은 세금 환급 관련 항목에 대해서만 검토할 수 있기 때문에 세무조사 대상 여부를 판단할 수 없습니다. 세금 환급 전문 회계사가 정확하게 진단하고, 진행하는 것이기에 안전합니다.
                                    </p>
                                </div>
                            </div>
                            <div className={Qst.qnaBox}>
                                <QText>기존에 기장 서비스를 받고 있는데, 무료상담을 받을 필요가 있나요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>네. 기존 기장 서비스를 받고 있더라도 세금환급이 발생할 수 있어서, 무료상담을 받아보세요.</Answer>
                                    <p className={Qst.explain}>
                                        세무대리인의 관점과 해석에 따라 세액공제 적용 여부가 다를 수 있습니다. 따라서 무료 상담을 통해 점검해보시기를 추천드립니다.
                                    </p>
                                </div>
                            </div>
                        </Qna>
                    </div>
                </ContentBox>
            </Question>
        </form>
    ));
}
export default CorporateTax;