import StandByBoardMeetingInfoStepModel from '@model/changeRegistration/StandByBoardMeetingInfoStepModel';
import stepService from "@service/stepService";
import formDataToJson from '@common/module/submit';
import { runInAction } from 'mobx';
import portalModel from '@model/portalModel';
import BoardMeetingAgreeModal from '@pages/ModalContent/changeRegistration/StandByBoardMeetingParticipant/BoardMeetingAgreeModal';

class StandByBoardMeetingParticipantVm {
    submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error === false) {
            let param = formDataToJson(e.target).data;
            if(this.checkState === "부족") {
                const totalParticipant = StandByBoardMeetingInfoStepModel.participants.length;
                const agreeCnt = StandByBoardMeetingInfoStepModel.participants.filter(elem => elem.agreeYn === true).length; 
                const sealProvidingAgreeYn = StandByBoardMeetingInfoStepModel.participants.filter(elem => elem.sealProvidingYn === true).length; 

                if(!(totalParticipant/2 < agreeCnt)) {
                    runInAction(() => {
                        portalModel.modalContent = <BoardMeetingAgreeModal agreeType="찬성"/>;
                        portalModel.btn = [{title: '확인', type: 'positive', event: ()=> {portalModel.setModalClose()}}];
                        portalModel.closeBtn = true;
                    })
                } else if(!(totalParticipant/2 < sealProvidingAgreeYn)) {
                    runInAction(() => {
                        portalModel.modalContent = <BoardMeetingAgreeModal agreeType="인감증명서"/>;
                        portalModel.btn = [{title: '확인', type: 'positive', event: ()=> {portalModel.setModalClose()}}];
                        portalModel.closeBtn = true;
                    })
                }

                return;
            }
            stepService.saveStep(param);
        }
    }
    get participants() {
        return StandByBoardMeetingInfoStepModel.participants;
    }
    get agreeAllChecked() {
        let allChecked = true;
        StandByBoardMeetingInfoStepModel.participants.forEach((participant) => {
            if(participant.agreeYn === false) {
                participant.sealProvidingYn = false;
                allChecked = false;
            }
        })
        return allChecked;
    }
    setAgreeAllChecked(e) {
        runInAction(() => {
            StandByBoardMeetingInfoStepModel.participants.forEach((participant) => {
                participant.agreeYn = e.target.checked;
            })
        })
    }
    get sealAllChecked() {
        let allChecked = true;
        StandByBoardMeetingInfoStepModel.participants.forEach((participant) => {
            if(participant.sealProvidingYn === false) {
                allChecked = false;
            }
        })
        return allChecked;
    }
    setSealAllChecked(e) {
        runInAction(() => {
            StandByBoardMeetingInfoStepModel.participants.forEach((participant) => {
                participant.sealProvidingYn = e.target.checked;
            })
        })
    }
    setAgreeYn(e, idx) {
        runInAction(() => {
            StandByBoardMeetingInfoStepModel.participants[idx].agreeYn = e.target.checked;
        })
    } 
    setSealProvidingYn(e, idx) {
        runInAction(() => {
            StandByBoardMeetingInfoStepModel.participants[idx].sealProvidingYn = e.target.checked;
        })
    } 
    get checkState() {
        let state = "부족";

        const totalParticipant = StandByBoardMeetingInfoStepModel.participants.length;
        let agreeParticipantCnt = StandByBoardMeetingInfoStepModel.participants.filter(elem => elem.sealProvidingYn === true).length; 

        if(totalParticipant/2 < agreeParticipantCnt) {
            state = "충분";
        }

        return state;
    }
}

export default new StandByBoardMeetingParticipantVm();