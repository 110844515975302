import { runInAction, observable, toJS } from "mobx";
import HeadOfficeRelocationStepModel from '@model/changeRegistration/HeadOfficeRelocationStepModel';
import smartformAppModel from "@model/smartformAppModel";
import regExp from '@common/module/regExp';
import formDataToJson from '@common/module/submit';
import stepService from "@service/stepService";
class HeadOfficeAddressRelocationVm {
    constructor() {
        this.state = observable({
            companyAddressModal : false
        });
    }
    get address() {
        return HeadOfficeRelocationStepModel.address;
    }
    openCompanyAddressModal(openYn) {
        runInAction(() => {
            this.state.companyAddressModal = openYn;
        })
    }
    companyAddressComplate(address) {
        runInAction(() => {
            HeadOfficeRelocationStepModel.address = address;
            HeadOfficeRelocationStepModel.detailAddress = "";
            HeadOfficeRelocationStepModel.nameOfBuilding = address.buildingName;
        })
        this.openCompanyAddressModal(false);
    }
    get detailAddress() {
        return HeadOfficeRelocationStepModel.detailAddress;
    }
    setDetailAddress(e) {
        runInAction(() => {
            HeadOfficeRelocationStepModel.detailAddress = e.target.value;
        })
    }
    get nameOfBuilding() {
        return HeadOfficeRelocationStepModel.nameOfBuilding;
    }
    setNameOfBuilding(e) {
        runInAction(() => {
            HeadOfficeRelocationStepModel.nameOfBuilding = e.target.value;
        })
    }
    get bnameFontSizeChange() {
        const bName = HeadOfficeRelocationStepModel.address.bname;
        if(bName) {
            if(bName.length > 3) return true; //bname이 3글자보다 길면 true
            else return false;
        } else {
            return false;
        }
    }
    get qnaContent() {
        const formType = smartformAppModel.smartFormType;
        switch(formType) {
            case 'STOCK_COMPANY': return { name: '주주', officeYn: true, houseEstablish: true, houseRental: true };
            case 'AGRICULTURAL_STOCK_COMPANY': return { name: '주주', officeYn: false, houseEstablish: false, houseRental: false, smartFormTypeText: '농업회사법인' };
            case 'FISHERY_STOCK_COMPANY': return { name: '주주', officeYn: false, houseEstablish: false, houseRental: false, smartFormTypeText: '어업회사법인' };
            case 'LIMITED_COMPANY': return { name: '출자자', officeYn: true, houseEstablish: true, houseRental: true };
            case 'LIMITED_LIABILITY_COMPANY': return { name: '출자자', officeYn: true, houseEstablish: true, houseRental: true };
        }
    }
    get causeDateYear() {
        return HeadOfficeRelocationStepModel.causeDateYear;
    }
    setCauseDateYear(e) {
        runInAction(() => {
            HeadOfficeRelocationStepModel.causeDateYear = e.target.value;
        })
    }
    get causeDateMonth() {
        return HeadOfficeRelocationStepModel.causeDateMonth;
    }
    setCauseDateMonth(e) {
        runInAction(() => {
            HeadOfficeRelocationStepModel.causeDateMonth = e.target.value;
        })
    }
    get causeDateDay() {
        return HeadOfficeRelocationStepModel.causeDateDay;
    }
    setCauseDateDay(e) {
        runInAction(() => {
            HeadOfficeRelocationStepModel.causeDateDay = e.target.value;
        })
    }
    dateBlur(e, target) { 
        let val = e.target.value;
        if(val && val.length===1){
            e.target.value = "0" + val;
        }
        if(target === "month") {
            HeadOfficeRelocationStepModel.causeDateMonth = e.target.value;
        }
        if(target === "day") {
            HeadOfficeRelocationStepModel.causeDateDay = e.target.value;
        }
    }
    displayBirthErrorMessage(type) { 
        const year = this.causeDateYear;
        const month = this.causeDateMonth;

        if(type === 'month') {
            if(year && regExp.year().test(year)) { //연도가 제대로 입력되었을 때, 월에 대한 error 메시지
                return true;
            } else {
                return false;
            }
        } else if(type === 'day') {
            if(year && regExp.year().test(year) && month && regExp.month().test(month)) { // 연도, 월이 제대로 입력 되었을 때, 일(날짜)에 대한 error 메시지
                return true;
            } else {
                return false;
            }
        }
    }
    submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error === false) {
            let param = formDataToJson(e.target).data;
            param.address = HeadOfficeRelocationStepModel.address;
            param.causeDate = param.causeDateYear+"년"+param.causeDateMonth+"월"+param.causeDateDay+"일";
            delete param.causeDateYear;
            delete param.causeDateMonth;
            delete param.causeDateDay;
            stepService.saveStep(param);
        }
    }
}
export default new HeadOfficeAddressRelocationVm();