import { useObserver } from 'mobx-react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './ChooseStamp.module.scss';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import Icon from '@comComponents/atoms/Icon/Icon';
import NumberUpdown from '@comComponents/atoms/Input/NumberUpdown/NumberUpdown';
import {addCommas} from '@common/module/replaceNumber';
import ChooseStampVm from './ChooseStampVm';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import Select from '@comComponents/atoms/Input/Select/Select';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import clsx from 'clsx';
import { useEffect } from 'react';

function ChooseStamp() {
  useEffect(() => {
    if(ChooseStampVm.currentSymbolList) {
      ChooseStampVm.state.signList = ChooseStampVm.state.signList.filter((sign) => {
        return !ChooseStampVm.currentSymbolList.includes(sign)
      })
    }
    if(ChooseStampVm.usageSealNormalCount !== 0 && ChooseStampVm.normalUsageSealSymbols.length === 0) {
      ChooseStampVm.setNormalUsageSealSymbols();
    }
    if(ChooseStampVm.usageSealPremiumCount !== 0 && ChooseStampVm.premiumUsageSealSymbols.length === 0) {
      ChooseStampVm.setPremiumUsageSealSymbols();
    }
  }, []);

  return useObserver(() => (
    <form onSubmit={(e) => ChooseStampVm.submit(e)}>
      <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
      <Question>
        <ContentBox className={Qst.spaceBetween}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>법인인감 종류를 선택</span>해 주세요.
              </h2>
              <div className={Qst.titleExplain}>
                인원에 상관 없이 기본형 도장 1개(14,300원)만 무료로 제공됩니다.
              </div>
            </div>
            {ChooseStampVm.establishmentCorporateSeals.map((item, idx) => {
              return (
                <div className={st.stampWrap} key={idx}>
                  <input type="hidden" name={`establishmentCorporateSeals[${idx}].executiveId`} value={item.executiveId}/>
                  <input type="hidden" name={`establishmentCorporateSeals[${idx}].executiveName`} value={item.executiveName}/>
                  <input type="hidden" name={`establishmentCorporateSeals[${idx}].position`} value={item.position}/>
                  <div className={st.name}>{item.executiveName} 대표님</div>
                  <div className={st.stamp}>
                    <label className={st.stampLabel} htmlFor={`free[${idx}]`}>
                      <input type="radio" className={st.radio} id={`free[${idx}]`} value={true} checked={item.defaultYn === true} onChange={(e) => ChooseStampVm.setDefaultYn(e, idx)} name={`establishmentCorporateSeals[${idx}].defaultYn`}/>
                      <Badge  title="기본" active={item.defaultYn ? true : false}>
                        <img className={st.badgeImg} src="/images/basic.jpg" alt="기본형 도장" />
                        <div className={clsx(st.free, item.defaultYn ? st.active : null)}>{idx === 0 ? '무료제공' : `+ 14,300원 추가`}</div>
                      </Badge>
                    </label>
                    <label className={st.stampLabel} htmlFor={`stamp[${idx}]`}>
                      <input type="radio" className={st.radio} id={`stamp[${idx}]`} value={false} checked={item.defaultYn === false} onChange={(e) => ChooseStampVm.setDefaultYn(e, idx)} name={`establishmentCorporateSeals[${idx}].defaultYn`}/>
                      <Badge  title="고급" active={item.defaultYn === false ? true : false}>
                        <img className={st.badgeImg} src="/images/premium.jpg" alt="고급형 도장" />  
                        <div className={clsx(st.addPrice, item.defaultYn === false ? st.active : null)}>{idx === 0 ? '+ 22,000원 추가' : '+ 36,300원 추가'}</div>
                      </Badge>
                    </label>
                  </div>
                </div>
              )
            })}
            <div className={clsx(Qst.titleWrap, Qst.marginTop)}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>비표(도장 모양)를 선택</span>해 주세요.
              </h2>
              {ChooseStampVm.corporateSealsMultiYn && <div className={Qst.titleExplain}>대표님마다 비표는 다르게 선택해 주세요.</div>}
            </div>
            <Badge className={ChooseStampVm.corporateSealsMultiYn ? st.multiSignBadge : st.signBadge} title="예시" type="example" border={false}>
              <img className={ChooseStampVm.corporateSealsMultiYn ? st.multiSignBadgeImg : st.signBadgeImg} src={ChooseStampVm.corporateSealsMultiYn ? `/images/stamp_mutliSign_example.png` : `/images/stamp_sign_example.png`} alt="비표 예시" />
            </Badge>
            {ChooseStampVm.establishmentCorporateSeals.map((item, idx) => {
              return (
                <div className={st.corporateSealsSign} key={idx}>
                  {ChooseStampVm.corporateSealsMultiYn && <div className={st.executiveName}>{item.executiveName} {item.position}님</div>}
                  <Select className={clsx(Qst.select, st.select)} style={ChooseStampVm.corporateSealsMultiYn ? {width:'334px'} : {width:'220px'}} name={`establishmentCorporateSeals[${idx}].symbol`} value={item.symbol ?? ''} onChange={(e) => ChooseStampVm.setSealSymbols(e, idx, 'corp')} errorText={<Error name={`establishmentCorporateSeals[${idx}].symbol`} value={item.symbol} errorCase={{required:'비표(도장 모양)를 선택해 주세요.'}} style={{width:'270px'}}/>}>
                    <option value="">-</option>
                    {item.symbol && <option value={item.symbol}>{ChooseStampVm.signText(item.symbol)}</option>}
                    {ChooseStampVm.state.signList.map((sign, idx) => (
                      <option value={sign} key={idx}>{ChooseStampVm.signText(sign)}</option>
                    ))}
                  </Select>
                </div>
              )
            })}
          </div>
          <div className={Qst.qnaSection}>
            <Qna tip="도장제작 방법" className={Qst.qnaTitle}>  
              <div className={Qst.qnaBox}>
                <QText>법인인감이란 무엇인가요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>법인인감이란 등기소에 등록하는 공식 회사도장입니다.</Answer>
                  <p className={Qst.explain}>
                    법인인감은 법인 인감증명서에 나오는 중요한 도장이므로, 일반적으로 대표님이 직접 보관하거나, 금고에 안전하게 보관합니다. 법인인감이 도용되면 심각한 문제가 발생할 수 있으므로, 법인인감은 오픈된 공간에 두거나, 일상적으로 사용하는 것은 추천드리지 않습니다.
                  </p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>왜 법인인감을 여러 개 제작해야 하나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>회사를 설립 할 때에는 대표 1명당 1개의 법인인감을 제작해야 합니다.</Answer>
                  <p className={Qst.explain}>
                    2명의 대표님이 있다면, 법인인감 1개는 무료로 제공되고, 다른 1개는 제작비용이 추가됩니다.
                  </p>
                  </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>직접 제작한 도장을 법인인감으로 등록할 수 있나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>헬프미 방침상 설립시 직접 제작한 도장을 법인인감으로 등록하기 어렵습니다.</Answer>
                  <p className={Qst.explain}>
                    분실우려가 있고, 업무지연이 발생하기 때문에 정책상 설립할 때에는 헬프미 제작 도장을 법인인감으로 등록해드립니다. 직접 제작한 도장이 있다면, 그 도장은 사용인감으로 사용해 주세요.
                  </p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>기본형 도장과 고급형 도장은 어떻게 다른가요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>고급형 도장은 벽조목으로 제작되고 전용 고급케이스도 함께 제공됩니다.</Answer>
                  <p className={Qst.explain}>
                    기본형 도장은 흑인조로 제작되고, 일반형 케이스도 함께 제공됩니다.
                  </p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>대표가 여러명인 경우, 비표를 동일하게 선택할 수 있나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>대표가 여러명인 경우, 비표는 모두 다르게 선택해 주세요.</Answer>
                  <p className={Qst.explain}>
                    대표님 마다 법인인감도장이 다른 모양으로 제작되어야만 등기소에 등록이 가능합니다. 만약 같은 모앙이라면, 등기소에 등록이 되지 않습니다. (인감의 제출 관리 및 인감증명서 발급에 관한 업무처리지침 2항)
                  </p>
                  </div>
              </div>
            </Qna>
          </div>    
        </ContentBox>
      </Question>
      <Question>
        <ContentBox className={Qst.spaceBetween}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>사용인감을 추가</span>하시나요?
              </h2>
              <div className={Qst.titleExplain}>
                각 인감당 최대 10개씩 가능합니다.
              </div>
            </div>
            <div className={st.addStamp}>
              <div className={st.addStampWrap}>
                <div className={st.stampImg}>
                  <img className={st.basicStamp} src="/images/basic.jpg" alt="기본형 도장" />
                </div>
                <div className={st.stampInfo}>
                  <div className={st.stamp}>
                    <div className={st.stampNamePrice}>
                      <div className={st.stampeName}>기본형 도장</div>
                      <div className={st.stampPrice}><Icon icon="won" className={st.won} />{addCommas(14300)}원</div>
                    </div>
                    <NumberUpdown value={ChooseStampVm.usageSealNormalCount} name="usageSealNormalCount" onMinus={(e) => ChooseStampVm.usageSealCountMinus(e, 'normal')} onPlus={(e) => ChooseStampVm.usageSealCountPlus(e, 'normal')} onChange={(e) => ChooseStampVm.setUsageSealCount(e, 'normal')} disabledCount="10"/> 
                    <div className={st.stampeTotal}>
                      {addCommas(ChooseStampVm.usageSealCountSum('normal'))}<span className={st.totalText}>원</span>
                    </div>
                  </div>
                  <div className={st.usageSealSign}>
                    {ChooseStampVm.normalUsageSealSymbols.length > 0 && <div className={st.usageSealSignExplain}>※ 사용인감 비표는 법인인감과 다르게 선택해 주세요.</div>}
                      {ChooseStampVm.normalUsageSealSymbols.map((normal, idx) => {
                        return (
                          <div className={st.sign} key={idx}>
                            <div className={st.signTitle}>사용인감{idx+1} 비표선택</div>
                            <input type="hidden" name={`normalUsageSealSymbols[${idx}].id`} value={normal.id}/>
                            <Select className={clsx(Qst.select, st.usageSealSignSelect)} style={{width:'165px'}} name={`normalUsageSealSymbols[${idx}].symbol`} value={normal.symbol ?? ''} onChange={(e) => ChooseStampVm.setSealSymbols(e, idx, 'normal')} errorText={<Error name={`normalUsageSealSymbols[${idx}].symbol`} value={normal.symbol} errorCase={{required:'비표(도장 모양)를 선택해 주세요.'}} style={{width:'270px'}}/>}>
                              <option value="">-</option>
                              {normal.symbol && <option value={normal.symbol}>{ChooseStampVm.signText(normal.symbol)}</option>}
                              {ChooseStampVm.state.signList.map((sign, idx) => (
                                  <option value={sign} key={idx}>{ChooseStampVm.signText(sign)}</option>
                                ))}
                            </Select>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
              <div className={st.addStampWrap}>
                <div className={st.stampImg}>
                  <img className={st.basicStamp} src="/images/premium.jpg" alt="고급형 도장" /> 
                </div>
                <div className={st.stampInfo}>
                  <div className={st.stamp}>
                    <div className={st.stampNamePrice}>
                      <div className={st.stampeName}>고급형 도장</div>
                      <div className={st.stampPrice}><Icon icon="won" className={st.won} />{addCommas(36300)}원</div>
                    </div>
                    <NumberUpdown value={ChooseStampVm.usageSealPremiumCount} name="usageSealPremiumCount" onMinus={(e) => ChooseStampVm.usageSealCountMinus(e, 'premium')} onPlus={(e) => ChooseStampVm.usageSealCountPlus(e, 'premium')} onChange={(e) => ChooseStampVm.setUsageSealCount(e, 'premium')} disabledCount="10"/> 
                    <div className={st.stampeTotal}>
                      {addCommas(ChooseStampVm.usageSealCountSum('premium'))}<span className={st.totalText}>원</span>
                    </div>
                  </div>
                  <div className={st.usageSealSign}>
                    {ChooseStampVm.premiumUsageSealSymbols.length > 0 && <div className={st.usageSealSignExplain}>※ 사용인감 비표는 법인인감과 다르게 선택해 주세요.</div>}
                      {ChooseStampVm.premiumUsageSealSymbols.map((premium, idx) => {
                        return (
                          <div className={st.sign} key={idx}>
                            <div className={st.signTitle}>
                              사용인감{idx+1} 비표선택
                              <div className={st.signType}>(고급형)</div>
                            </div>
                            <input type="hidden" name={`premiumUsageSealSymbols[${idx}].id`} value={premium.id}/>
                            <Select className={clsx(Qst.select, st.usageSealSignSelect)} style={{width:'165px'}} name={`premiumUsageSealSymbols[${idx}].symbol`} value={premium.symbol ?? ''} onChange={(e) => ChooseStampVm.setSealSymbols(e, idx, 'premium')} errorText={<Error name={`premiumUsageSealSymbols[${idx}].symbol`} value={premium.symbol} errorCase={{required:'비표(도장 모양)를 선택해 주세요.'}} style={{width:'270px'}}/>}>
                              <option value="">-</option>
                              {premium.symbol && <option value={premium.symbol}>{ChooseStampVm.signText(premium.symbol)}</option>}
                              {ChooseStampVm.state.signList.map((sign, idx) => (
                                  <option value={sign} key={idx}>{ChooseStampVm.signText(sign)}</option>
                                ))}
                            </Select>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={Qst.qnaSection}>
            <div className={Qst.qnaBox}>
              <Qna className={Qst.qnaTitle}>
                <QText>사용인감이란 무엇인가요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>사용인감은 등기소에 등록하지 않고, 수시로 사용하는 회사도장입니다.</Answer>
                  <p className={Qst.explain}>
                    일반적으로 막도장, 보통도장으로 부릅니다. 사용인감은 공개된 장소에 비치하고, 직원들이 수시로 사용합니다. 법인을 운영하다보면 도장을 찍을 일이 많습니다. 예를 들어 재직증명서 같은 사소한 서류를 발급할 때에도 도장을 찍어야 합니다. 따라서 사용인감을 추가로 제작하는 것을 추천드립니다.
                  </p>
                </div>
                <div className={Qst.qnaBox}>
                <QText>법인인감과 사용인감의 비표를 동일하게 선택할 수 있나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>법인인감과 사용인감의 비표를 다르게 선택해 주세요.</Answer>
                  <p className={Qst.explain}>
                    법인인감과 사용인감의 비표를 동일하게 선택하면, 보안상 문제가 발생할 수 있습니다. 이 경우 법인인감의 모양과 완전히 동일한 도장이 1개 더 생기게 되는 것이기 때문에, 사소한 서류를 발급할 때 자유롭게 사용하기 위하여 제작한다는 사용인감의 제작목적을 살릴 수 없습니다.
                  </p>
                  </div>
              </div>
              </Qna>
            </div>
          </div>
        </ContentBox>
      </Question>
      <Question>
        <ContentBox className={Qst.spaceBetween}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>도장 추가금액</span>
              </h2>
              <div className={Qst.titleExplain}>
                법인설립 금액과 합산되어 마지막에 결제됩니다.
              </div>
            </div>
            <table className={Qst.priceTable}>
              <colgroup>
                <col width="145px"/>
                <col width="228px"/>
                <col width="187px"/>
              </colgroup>
              <thead>
                <tr>
                  <th>법인인감</th>
                  <th>사용인감</th>
                  <th>도장 추가금액</th>
                </tr>
              </thead>
              <tbody>
                <tr className={Qst.priceContentRow}>
                  <td>
                    <div className={Qst.priceContentWrap}>
                      {addCommas(ChooseStampVm.usageSealTotal('corp'))}<span className={Qst.text}>원</span><Icon icon="plusBlack" className={Qst.plusBlack}/>
                    </div>
                  </td>
                  <td>
                    <div className={Qst.priceContentWrap}>
                      {addCommas(ChooseStampVm.usageSealTotal('use'))}<span className={Qst.text}>원</span>
                    </div>
                  </td>
                  <td className={Qst.resultPrice}>
                    <div className={Qst.priceContentWrap}>
                      <Icon icon="result" className={Qst.result}/>
                      {addCommas(ChooseStampVm.usageSealTotal('total'))}<span className={Qst.resultText}>원</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ContentBox>
      </Question>
    </form>
  ));
}

export default ChooseStamp;
