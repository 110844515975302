import { toJS } from 'mobx';
import { Fragment } from 'react';
import { useObserver } from 'mobx-react';
import st from '../StandbyFileUpload/StandbyFileUpload.module.scss';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import Ul from '@comComponents/atoms/Text/UnorderListText/UnorderList';
import Upload from '@comComponents/molecules/FileUpload/FileUpload';
import clsx from 'clsx';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';

function MinorDocument(props) {
    return useObserver(() => (
        <Question>
            <ContentBox className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    {props.file.details.map((detail, idx2) => (
                        <Fragment key={idx2}>
                            {detail.fileType === '미성년_기본증명서' &&
                                <div className={Qst.titleWrap}>
                                    <h2 className={Qst.title}>
                                        <span className={Qst.bold}>미성년 주주/임원</span>의 <span className={Qst.bold}>기본증명서(상세)</span>를 업로드해 주세요.
                                    </h2>
                                </div>
                            }
                            {detail.fileType === '미성년_가족관계증명서' &&
                                <div className={Qst.titleWrap}>
                                    <h2 className={Qst.title}>
                                        <span className={Qst.bold}>미성년 주주/임원</span>의 <span className={Qst.bold}>가족관계증명서(상세)</span>를 업로드해 주세요.
                                    </h2>
                                </div>
                            }
                            <Upload id={detail.fileType} className={st.fileUpload} multiple accept=".pdf, .jpg, .png"
                            model={props.parentVm.fileGroupTypes[props.idx].details[idx2].files}
                            uploadModel={props.parentVm.fileGroupTypes[props.idx].details[idx2].uploadFiles}
                            deleteModel={props.parentVm.deleteIds}
                            files={toJS(props.parentVm.files(props.idx, idx2))} uploadFiles={toJS(props.parentVm.uploadFiles(props.idx, idx2))}       
                            explainText={<div>미성년자 <span className="bold">전원</span>의 서류를 업로드해 주세요.</div>}
                            >
                                <span className={st.bold}>여기</span>를 눌러 업로드해 주세요.
                            </Upload>
                            {(props.parentVm.state.errorCheckYn && props.parentVm.displayErrorText(detail.fileType)) && <ErrorText>미성년자 전원의 서류를 업로드해 주세요.</ErrorText>}
                        </Fragment> 
                    ))}
                </div>
                <div className={Qst.qnaSection}>
                    <Qna className={Qst.noTitle}>
                        <div className={Qst.qnaBox}>
                            <QText>미성년 주주/임원의 서류는 어떻게 발급받나요?</QText>
                            <div className="answerBox">
                                <Answer className={Qst.answer}>미성년 주주/임원의 서류는 온라인(전자가족관계등록시스템) 또는 주민센터에서 발급 가능합니다.</Answer>
                                <div className={Qst.explain}>
                                <Ul>
                                    <li>아래 [전자가족관계등록시스템] 링크 에서 미성년 주주/임원 부모님의 공동인증서(구 공인인증서)로 로그인 후, 발급대상자를 "가족"으로 선택해서 미성년 주주/임원 본인을 선택해 주세요.</li>
                                    <li>상세증명서, 주민등록번호 전부공개를 꼭 선택해 주세요.</li>
                                </Ul>
                                </div>
                                <a className={st.aLink} href="https://efamily.scourt.go.kr/index.jsp" target="_blank" title="전자가족관계등록시스템으로 이동" />
                                <img className={st.efamilyImg} src="/images/efamily_example.png" alt="가족관계등록부 열람/발급 신청 예시" />
                            </div>
                        </div>
                    </Qna>
                </div>
            </ContentBox>
        </Question>
    ));
}

export default MinorDocument;