import { toJS } from 'mobx';
import { Fragment } from 'react';
import { useObserver } from 'mobx-react';
import st from '../StandbyFileUpload/StandbyFileUpload.module.scss';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import Upload from '@comComponents/molecules/FileUpload/FileUpload';
import clsx from 'clsx';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';

function ForeignDocument(props) {
    return useObserver(() => (
        <Question>
            <ContentBox className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    {props.file.details.map((detail, idx2) => (
                        <Fragment key={idx2}>
                            {detail.fileType === '외국인서류' &&
                                <>
                                    <div className={Qst.titleWrap}>
                                        <h2 className={Qst.title}>
                                            <span className={Qst.bold}>외국인 임원</span>의 <span className={Qst.bold}>국내거소신고 사실증명 또는 외국인등록사실증명서</span>를 업로드해 주세요.
                                        </h2>
                                    </div>
                                    <Upload id={detail.fileType} className={st.fileUpload} multiple accept=".pdf, .jpg, .png"
                                    model={props.parentVm.fileGroupTypes[props.idx].details[idx2].files}
                                    uploadModel={props.parentVm.fileGroupTypes[props.idx].details[idx2].uploadFiles}
                                    deleteModel={props.parentVm.deleteIds}
                                    files={toJS(props.parentVm.files(props.idx, idx2))} uploadFiles={toJS(props.parentVm.uploadFiles(props.idx, idx2))}       
                                    explainText={<div>외국인 <span className="bold">전원</span>의 서류를 업로드해 주세요.</div>}
                                    >
                                        <span className={st.bold}>여기</span>를 눌러 업로드해 주세요.
                                    </Upload>
                                    {(props.parentVm.state.errorCheckYn && props.parentVm.displayErrorText(detail.fileType)) && <ErrorText>외국인 전원의 서류를 업로드해 주세요.</ErrorText>}
                                </> 
                            }
                        </Fragment>
                    ))}
                </div>
                <div className={Qst.qnaSection}>
                    <Qna className={Qst.noTitle}>
                        <div className={Qst.qnaBox}>
                            <QText>외국인의 서류는 어떻게 발급 받나요?</QText>
                            <div className="answerBox">
                                <Answer className={Qst.answer} iconText="A1">국내거소신고 사실증명은 온라인(정부24)에서 발급 가능합니다.</Answer>
                                <LikeBtn className={Qst.likeBtn} href="https://www.gov.kr/mw/AA020InfoCappView.do?CappBizCD=12700000091" target="_blank">국내거소신고 사실증명 발급방법 알아보기</LikeBtn>
                            </div>
                            <div className="answerBox" style={{paddingTop:'20px'}}>
                                <Answer className={Qst.answer} iconText="A2">외국인등록사실 증명서는 온라인(정부24)에서 발급 가능합니다.</Answer>
                                <LikeBtn className={Qst.likeBtn} href="https://www.gov.kr/mw/AA020InfoCappView.do?CappBizCD=12700000027" target="_blank">외국인등록사실 증명서 발급방법 알아보기</LikeBtn>
                            </div>
                        </div>
                    </Qna>
                </div>
            </ContentBox>
        </Question>
    ));
}

export default ForeignDocument;