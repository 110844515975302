import pageService from "@service/pageService";
import minorDocumentUploadModel from '@model/minorDocumentUploadModel'
import { runInAction, toJS, observable } from "mobx";
import pageStepModel, {pageRouter} from '@model/pageStepModel';
import smartformAppModel from "@model/smartformAppModel";
import { _alert, _confirm } from "@model/dialogModel";

class viewModel {
    constructor() {
        this.state = observable({
            submitBtnDisabledYn: null
        });
    }
    loadMinorShareholderDocuments() {
        runInAction(() => {
            minorDocumentUploadModel.familyRelationshipCertificateUploadFiles = [];
            minorDocumentUploadModel.familyRelationshipCertificateDeleteFiles = [];
        })
        pageService.loadMinorShareholderDocuments();
    }
    pre() {
        pageRouter.replace('/MinorDocumentUpload/BasicCertificate');
    }
    get familyRelationshipCertificate() {
        return minorDocumentUploadModel.familyRelationshipCertificate;
    }
    get familyRelationshipCertificateUploadFiles() {
        return minorDocumentUploadModel.familyRelationshipCertificateUploadFiles;
    }
    get familyRelationshipCertificateDeleteFiles() {
        return minorDocumentUploadModel.familyRelationshipCertificateDeleteFiles;
    }
    async upload() {
        if(minorDocumentUploadModel.familyRelationshipCertificateUploadFiles.length === 0 && minorDocumentUploadModel.familyRelationshipCertificate.length === 0) { //파일 업로드를 하지 않는 경우
            await _alert('파일을 업로드해 주세요.')
        }
        else if(minorDocumentUploadModel.familyRelationshipCertificateUploadFiles.length !== 0 || minorDocumentUploadModel.familyRelationshipCertificateDeleteFiles.length !== 0) { //업로드할 파일이 있는 경우(처음 파일을 업로드 하는 경우, 기존 파일을 변경한 경우)
            this.state.submitBtnDisabledYn = true;
            await pageService.uploadMinorShareholderDocumentsFamilyRelationship();
            await _alert('파일을 업로드 하였습니다.');
            this.state.submitBtnDisabledYn = false;
            if(smartformAppModel.corpId) { //대시보드에서 열었을 때(corpId가 있을 때)
                if(window.opener) {
                    window.opener.close()
                }
                sessionStorage.clear();
                window.open(`${process.env.REACT_APP_DASHBOARD_URL}corps/${smartformAppModel.corpId}/progress-stages/request-documents`, '_blank');
                window.close()
            } else { //어드민에서 열었을 때(corpId가 없을 때)
                sessionStorage.clear();
                window.close()
            }
            
        } 
        else { //기존 파일을 변경 없이 그대로 업로드 하는 경우
            if(await _confirm('기존 파일 그대로 업로드하시겠습니까?')) {
                await _alert('기존 파일을 업로드 하였습니다.')
                if(smartformAppModel.corpId) { //대시보드에서 열었을 때(corpId가 있을 때)
                    if(window.opener) {
                        window.opener.close()
                    }
                    sessionStorage.clear();
                    window.open(`${process.env.REACT_APP_DASHBOARD_URL}corps/${smartformAppModel.corpId}/progress-stages/request-documents`, '_blank');
                    window.close()
                } else { //어드민에서 열었을 때(corpId가 없을 때)
                    sessionStorage.clear();
                    window.close()
                }
            }  
        }
    }
    name() {
        const type = smartformAppModel.smartFormType;
        switch(type) {
            case 'ESTABLISHMENT_STOCK_COMPANY':
                return '주주/임원'
            case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY':
                return '주주/임원'
            case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY':
                return '주주/임원'
            case 'ESTABLISHMENT_LIMITED_COMPANY':
                return '출자자/임원'
            case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY':
                return '출자자/임원'
        }
    }  
}

export default new viewModel();