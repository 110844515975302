import { useObserver } from 'mobx-react';
import Cst from '../../../FinalCheck/FinalCheck.module.scss';
import st from './BranchOfficeDeleteStep.module.scss';

function BranchOfficeDeleteStep(props) {
    return useObserver(() => (
        <>
            <div className={Cst.row}> 
                <div className={Cst.dName}>폐지할 지점</div>
                <div className={Cst.data}>
                    {props.step.branches.map((item, idx) => {
                        return (
                            <div key={idx} className={st.address}>{idx+1}. {item.address}</div>
                        )
                    })}
                </div>
            </div>
        </>
    ));
}
export default BranchOfficeDeleteStep;