import { post } from '@common/module/httpRequest';
import smartformAppModel from '@model/smartformAppModel';
import { runInAction } from 'mobx';

class submitEstimateService {
    async submitEstimate() {
        const res = await post(`/smartform/${smartformAppModel.smartformId}/submit`, {}, {});
    }
}

export default new submitEstimateService();