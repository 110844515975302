import { onlyNumber } from '@common/module/replaceNumber';
import regExp from '@common/module/regExp';
import { observable, runInAction } from 'mobx';

class AddressVm {
    constructor(model) {
        this.model = model;
        this.state = observable({
            addressModal: false
        })
    }
    get address() {
        return this.model.address;
    }
    get addressModal() {
        return this.state.addressModal; 
    }
    openAddressModal(openYn) {   
        runInAction(() => {
            this.state.addressModal = openYn;
        })
    }
    addressComplate(address) {
        runInAction(() => {
            this.model.address = address;
            this.model.detailAddress = "";
            this.model.nameOfBuilding = address.buildingName;
        })
        this.openAddressModal(false);
    }
    get detailAddress() {
        return this.model.detailAddress;
    }
    setDetailAddress(e) {
        runInAction(() => {
            this.model.detailAddress = e.target.value;
        })
    }
    get nameOfBuilding() {
        return this.model.nameOfBuilding;
    }
    setNameOfBuilding(e) {
        runInAction(() => {
            this.model.nameOfBuilding = e.target.value;
        })
    }
    get bnameFontSizeChange() {
        const bName = this.model.address.bname;
        if(bName) {
            if(bName.length > 3) return true; //bname이 3글자보다 길면 true
            else return false;
        } else {
            return false;
        }
    }
    get koreanYn() {
        return this.model.koreanYn;
    }
    get name() {
        return this.model.name;
    }
    get causeDate() {
        return this.model.causeDate;
    }
    setReportDateYear(e) {
        runInAction(() => {
            this.model.causeDate.year = onlyNumber(e.target.value);
        })
    }
    setReportDateMonth(e) {
        runInAction(() => {
            this.model.causeDate.month = onlyNumber(e.target.value);
        })
    }
    setReportDateDay(e) { 
        runInAction(() => {
            this.model.causeDate.day = onlyNumber(e.target.value);
        })
    }
    reportDateBlur(e, target) { 
        let val = e.target.value;
        if(val && val.length===1){
            e.target.value = "0" + val;
        }
        if(target === "month") {
            this.model.causeDate.month = e.target.value;
        }
        if(target === "day") {
            this.model.causeDate.day = e.target.value;
        }
    }
    displayReportDateErrorMessage(type) { 
        const year =  this.model.causeDate.year;
        const month = this.model.causeDate.month;
        if(type === 'month') {
            if(year && regExp.year().test(year)) { //연도가 제대로 입력되었을 때, 월에 대한 error 메시지
                return true;
            } else {
                return false;
            }
        } else if(type === 'day') {
            if(year && regExp.year().test(year) && month && regExp.month().test(month)) { // 연도, 월이 제대로 입력 되었을 때, 일(날짜)에 대한 error 메시지
                return true;
            } else {
                return false;
            }
        }
    }
}

export default AddressVm;