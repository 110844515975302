import { useObserver } from 'mobx-react';
import Trademark from './Trademark/Trademark';
import { Route } from "react-router-dom";

function TrademarkStep() {
    return useObserver(() => (
        <>
            {/* 상표등록 */}
            <Route path="/step/changeRegistration/TrademarkStep/Trademark" component={Trademark}></Route>
        </>
    ));
}

export default TrademarkStep;