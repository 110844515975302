import { useObserver } from 'mobx-react';
import Cst from '../../../FinalCheck/FinalCheck.module.scss';
import st from './StockholdersMeetingSealProvidingStep.module.scss';
import clsx from 'clsx';
import StockholdersMeetingSealProvidingStepVm from './StockholdersMeetingSealProvidingStepVm';

function StockholdersMeetingSealProvidingStep(props) {
    return useObserver(() => (
        <>
            <div className={Cst.row}> 
                <div className={Cst.dName} style={{width:'370px'}}>{StockholdersMeetingSealProvidingStepVm.text}총회 공증용 인감증명서를 제공할 {StockholdersMeetingSealProvidingStepVm.text}님</div>
                <div className={Cst.data} style={{marginLeft:'20px'}}>
                    {props.step.stockholders.map((item, idx) => {
                        return (
                            <span key={idx} className={st.stockholders}>{idx+1}. {item}</span>
                        )
                    })}
                </div>
            </div>
        </>
    ));
}
export default StockholdersMeetingSealProvidingStep;