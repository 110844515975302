import { get, post, put } from '@common/module/httpRequest';
import smartformAppModel from '@model/smartformAppModel';
import adminFinalCheckViewModel from '@model/establish/adminFinalCheckViewModel'
import changeRegistrationAdminFinalCheckViewModel from '@model/changeRegistration/adminFinalCheckViewModel'
import { runInAction, toJS } from 'mobx';
import { _alert, _confirm} from "@model/dialogModel";

class adminService {
    //설립
    async loadAdminFinalSubmission() { //설립 폼2 어드민 확인 뷰
        const res = await get(`/admin/establishment/${smartformAppModel.smartformId}/FinalCheck`, {}, {});
        const progressInfo = await get(`admin/smartforms/${smartformAppModel.smartformId}/progressInfo`, {}, {});
        const data = res.data;
        const progressData = progressInfo.data;
        runInAction(() => {
            document.title = `${progressData.rnumber} ${progressData.companyName}`
            smartformAppModel.setSmartFormType(data.smartFormType);
            adminFinalCheckViewModel.setAdminFinalCheckView(data);
        })
    }
    async managerMode() {
        const res = await get(`/admin/establishment/${smartformAppModel.smartformId}/update`, {}, {});
        const data = res.data;
        return data.url;
    }
    async changeBalanceCertificate(balanceCertificate) {
        if(await _confirm('변경사항을 저장하시겠습니까?')) {
            const res = await put(`admin/smartforms/${smartformAppModel.smartformId}/balanceCertificate`, balanceCertificate, {});
            await _alert('변경사항을 저장하였습니다.')
        }        
    }
    async estimate() {
        if(await _confirm('수정사항을 견적에 반영하시겠습니까?')) {
            const res = await post(`/admin/smartforms/${smartformAppModel.smartformId}/estimate`, {}, {});
            await _alert('수정사항이 견적에 반영 되었습니다.')
        }
    }
    //변경
    async loadChangeRegistrationAdminFinalSubmission() { //변경등기 폼2 어드민 확인 뷰
        const res = await get(`/admin/changeRegistration/${smartformAppModel.smartformId}/FinalCheck`, {}, {});
        const progressInfo = await get(`admin/smartforms/${smartformAppModel.smartformId}/progressInfo`, {}, {});
        const data = res.data;
        const progressData = progressInfo.data;
        runInAction(() => {
            document.title = `${progressData.rnumber} ${progressData.companyName}`
            smartformAppModel.setSmartFormType(data.companyType);
            changeRegistrationAdminFinalCheckViewModel.setAdminFinalCheckView(data);
        })
    }
}

export default new adminService();

