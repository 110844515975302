import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import Vm from './StockholdersMeetingSealProvidingStockholdersCaseTwoVm';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import st from './StockholdersMeetingSealProvidingStockholdersCaseTwo.module.scss';
import Check from '@comComponents/atoms/Input/Check/Check';
import TextBtn from '@comComponents/atoms/Button/TextBtn/TextBtn';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import Icon from '@comComponents/atoms/Icon/Icon';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import clsx from 'clsx';
import {toJS} from 'mobx';
import Upload from '@comComponents/molecules/FileUpload/FileUpload';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';

function StockholdersMeetingSealProvidingStockholdersCaseTwo() {
    return useObserver(() => (
        <form onSubmit={(e) => Vm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            <Question>
                <ContentBox className={Qst.spaceBetween}>
                    <div className={Qst.infoSection}>
                        <div className={Qst.titleWrap}>
                            <h2 className={Qst.title}>
                                주주총회 인감증명서 준비방법
                            </h2>
                        </div>
                        {Vm.stockholderMeetingType === "NORMAL" &&
                            <>
                                <div className={st.explainImg}>
                                    <img src='/images/StockholdersMeetingSealProvidingStockholdersCaseTwo_explain.png'/>
                                </div>
                                <div className={st.explainImg} style={{marginTop:"10px"}}>
                                    <img src='/images/StockholdersMeetingSealProvidingStockholdersCaseTwo_explain2.png'/>
                                </div>
                            </>
                        }
                        {Vm.stockholderMeetingType === "SPECIAL" &&
                            <>
                                <div className={st.explainImg}>
                                    <img src='/images/StockholdersMeetingSealProvidingStockholdersCaseTwo_explain_special.png'/>
                                </div>
                                <div className={st.explainImg} style={{marginTop:"10px"}}>
                                    <img src='/images/StockholdersMeetingSealProvidingStockholdersCaseTwo_explain2.png'/>
                                </div>
                            </>
                        }
                    </div>
                </ContentBox>
                <ContentBox className={Qst.spaceBetween}>
                    <div className={Qst.infoSection}>
                        <div className={Qst.titleWrap}>
                            <h2 className={Qst.title}>
                            아래는 <span className={Qst.bold}>주주총회에 참석한(참석할) 주주 명단</span>입니다.<br/>이 중에서 <span className={Qst.bold}>인감증명서</span>를 <span className={Qst.bold}>제공할 주주님을 선택</span>해 주세요. 
                            </h2>
                        </div>
                        <div className={st.selectTable}>
                            <div className={st.tableBtnRow}>
                                <div><Check style={{fontSize:'17px', top:'1px', whiteSpace:'pre'}} boxType="allSquare" checked={Vm.isCheckedAll} onChange={(e) => Vm.checkedAll(e)}>전체선택 ({Vm.stockholders.filter((stockholder) => stockholder.sealProvidingYn).length}/{Vm.stockholders.filter((stockholder) => stockholder.sealProviderType !== "외국인" && stockholder.sealProviderType !== "외국법인").length})</Check></div>
                                <div><TextBtn onClick={() => Vm.sealProvidingYnFalse()}>선택해제</TextBtn></div>
                            </div>
                            <table className='selectTable'>
                                <colgroup>
                                    <col/>
                                    <col width="190px"/>
                                    <col width="140px"/>
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th style={{textAlign:"left"}}>성함</th>
                                        <th style={{textAlign:"left"}}>구별</th>
                                        <th style={{textAlign:"center"}}>인감증명서 제공여부</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Vm.stockholders.map((stockholder, index) => (
                                        <tr key={index}>
                                            <td style={{textAlign:"left", fontWeight:"500"}}>{stockholder.name}</td>
                                            <td style={{textAlign:"left"}}>{Vm.changeCodeSealProviderType(stockholder.sealProviderType)}</td>
                                            <td style={{textAlign:"center"}}>
                                                <input type='hidden' value={stockholder.id} name={`stockholders[${index}].id`}></input>
                                                <Check onlyCheckBox disabled={stockholder.sealProviderType === "외국인" || stockholder.sealProviderType === "외국법인"} value={true} onChange={(e) => Vm.setSealProvidingYn(e, index)} checked={stockholder.sealProvidingYn} name={`stockholders[${index}].sealProvidingYn`}></Check>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {(Vm.stockholders.filter((stockholder) => stockholder.sealProvidingYn).length === 0 && Vm.state.isChanged) && 
                                <ErrorText className=".errorText" data-error>인감증명서를 제공할 주주님을 선택해 주세요.</ErrorText>
                            }
                        </div>
                    </div>
                    <div className={Qst.qnaSection}>
                        <Qna className={Qst.noTitle}>
                            {Vm.stockholderMeetingType === "NORMAL" &&
                                <div className={Qst.qnaBox}>
                                    <QText>인감증명서가 얼마나 필요한가요?</QText>
                                    <div className="answerBox">
                                        <Answer className={Qst.answer}>[25%지분] 또는 [참석한 주주의 지분 중 50%를 초과] 주주들의 인감증명서가 필요합니다. (둘 중 높은 숫자에 해당하는 인감증명서가 필요함)</Answer>
                                        <p className={Qst.explain}>
                                            상법 제368조 1항은 총회의 결의는 이 법 또는 정관에 다른 정함이 있는 경우를 제외하고는 출석한 주주의 의결권의 과반수와 발행주식총수의 4분의 1 이상의 수로써 하여야 한다고 정하고 있습니다.
                                        </p>
                                        <TextBox className={Qst.textBox} title="예시">
                                            <ul className={st.questionList}>
                                                <li>
                                                    <span className={st.question}>
                                                        30% 지분 가진<br/>주주들이<br/>참석했다면?
                                                    </span>
                                                    <span className={st.answer}>
                                                        <span className={st.title}>25% 지분에 해당하는 인감증명서 필요</span>
                                                        <span className={st.explain}>
                                                            <Icon icon="arrow" className={st.arrow}/>
                                                            25% vs 15.1% (=30% 지분의 과반수)<br/>중에 25%가 높은 숫자이므로,<br/>25% 지분에 해당하는 인감증명서 필요
                                                        </span>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className={st.question}>
                                                        100% 지분 가진<br/>주주들이<br/>참석했다면?
                                                    </span>
                                                    <span className={st.answer}>
                                                        <span className={st.title}>50.1% 지분에 해당하는 인감증명서 필요</span>
                                                        <span className={st.explain}>
                                                            <Icon icon="arrow" className={st.arrow}/>
                                                            25% vs 50.1% (=100% 지분의 과반수)<br/>중에 50.1%가 높은 숫자이므로,<br/>50.1% 지분에 해당하는 인감증명서 필요
                                                        </span>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className={st.question}>
                                                        25% 지분 가진<br/>주주들이<br/>참석했다면?
                                                    </span>
                                                    <span className={st.answer}>
                                                        <span className={st.title}>25% 지분에 해당하는 인감증명서 필요</span>
                                                        <span className={st.explain}>
                                                            <Icon icon="arrow" className={st.arrow}/>
                                                            25% vs 12.51% (=25% 지분의 과반수)<br/>중에 25%가 높은 숫자이므로,<br/>25% 지분에 해당하는 인감증명서 필요
                                                        </span>
                                                    </span>
                                                </li>
                                            </ul>
                                        </TextBox>
                                    </div>
                                </div>
                            }
                            {Vm.stockholderMeetingType === "SPECIAL" &&
                                <div className={Qst.qnaBox}>
                                <QText>인감증명서가 얼마나 필요한가요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>[1/3지분] 또는 [참석한 주주 지분의 2/3 이상] 주주들의 인감증명서가 필요합니다. (둘 중 높은 숫자에 해당하는 인감증명서가 필요함)</Answer>
                                    <p className={Qst.explain}>
                                        상법 제434조는 주주총회 특별결의는 출석한 주주의 의결권의 3분의 2 이상의 수와 발행주식총수의 3분의 1 이상의 수로써 하여야 한다고 정하고 있습니다. 
                                    </p>
                                    <TextBox className={Qst.textBox} title="예시">
                                        <ul className={st.questionList}>
                                            <li>
                                                <span className={st.question}>
                                                    40% 지분 가진<br/>주주들이<br/>참석했다면?
                                                </span>
                                                <span className={st.answer}>
                                                    <span className={st.title}>33.33% 지분에 해당하는 인감증명서 필요</span>
                                                    <span className={st.explain}>
                                                        <Icon icon="arrow" className={st.arrow}/>
                                                        33.33% (=1/3지분) vs 26.66% (= 40%<br/>지분의 2/3) 중에 33.33%가 높은 숫자이므로,<br/> 33.33% 지분에 해당하는 인감증명서 필요
                                                    </span>
                                                </span>
                                            </li>
                                            <li>
                                                <span className={st.question}>
                                                    100% 지분 가진<br/>주주들이<br/>참석했다면?
                                                </span>
                                                <span className={st.answer}>
                                                    <span className={st.title}>66.66% 지분에 해당하는 인감증명서 필요</span>
                                                    <span className={st.explain}>
                                                        <Icon icon="arrow" className={st.arrow}/>
                                                        33.33% (=1/3지분) vs 66.66% (= 100%<br/>지분의 2/3) 중에 66.66%가 높은 숫자이므로,<br/> 66.66% 지분에 해당하는 인감증명서 필요
                                                    </span>
                                                </span>
                                            </li>
                                            <li>
                                                <span className={st.question}>
                                                    1/3 지분 가진<br/>주주들이<br/>참석했다면?
                                                </span>
                                                <span className={st.answer}>
                                                    <span className={st.title}>33.33% 지분에 해당하는 인감증명서 필요</span>
                                                    <span className={st.explain}>
                                                        <Icon icon="arrow" className={st.arrow}/>
                                                        33.33% (=1/3지분) vs 26.22% (=33.33% <br/>지분의 2/3) 중에 33.33%가 높은 숫자이므로,<br/> 33.33% 지분에 해당하는 인감증명서 필요
                                                    </span>
                                                </span>
                                            </li>
                                        </ul>
                                    </TextBox>
                                </div>
                            </div>
                            }
                            <div className={Qst.qnaBox}>
                                <QText>왜 대표님의 인감증명서가 필요한가요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>대표님은 주주총회에서 의장으로 참석하기 때문에 인감증명서가 필요합니다.</Answer>
                                    <p className={Qst.explain}>공증실에서는 주주총회에 참석한 이사들의 인감증명서를 요구합니다. 대표님이 주주인 경우, 주주총회에서 의장으로 참석해야 하기 때문에 인감증명서가 필요한 것입니다.</p>
                                    <p className={Qst.explain}>만약 대표님이 해외출장 등 주주총회에 참석하지 못할 상황이 있다면 채팅으로 문의주세요. 이 경우 고객님의 정관에 따라 다른 분이 주주총회 의장으로 참석하는 방식을 취해야 하는데, 저희가 정관을 파악한 후 고객님 상황에 맞게 처리해드리겠습니다. </p>
                                    <p className={Qst.explain}>또한 공동대표님의 경우, 모든 공동대표님들의 인감증명서가 필요하고, 각자대표님의 경우, 대표님 중 1분의 인감증명서가 필요합니다. </p>
                                </div>
                            </div>
                            <div className={Qst.qnaBox}>
                                <QText>거주등록을 하지 않은 외국인 또는 외국법인은 인감증명서를 제공할 수 있나요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>거주등록을 하지 않은 외국인 또는 외국법인 주주의 경우, 인감증명서를 만들 수 없습니다.</Answer>
                                    <p className={Qst.explain}>거주등록을 하지 않은 외국인 또는 외국법인 주주의 경우, 인감증명서를 만들 수 없기 때문에, 주주총회에서 찬성하려면 복잡한 절차를 거쳐야 합니다. (찬성한다는 내용의 위임장을 거주하는 국가의 언어로 작성한 후, 현지 공증 또는 아포스티유 인증을 거쳐야 함) 따라서 절차의 간소화를 위하여 국내법인, 국내성인, 외국인 거주등록을 마친 외국인 주주가 인감증명서를 제공하는 것으로 내부 조율을 하는 것을 추천드립니다.  </p>
                                    <p className={Qst.explain}>헬프미에서는 현지 공증 또는 아포스티유 인증을 거치는 절차에 관한 서비스를 제공하고 있지 않으므로, 양해 부탁드립니다.  </p>
                                </div>
                            </div>
                        </Qna>
                    </div>
                </ContentBox>
                {Vm.stockholders.filter((stockholder) => stockholder.sealProviderType === "펀드_투자조합" && stockholder.sealProvidingYn).length !== 0 &&     
                    <ContentBox className={Qst.spaceBetween}>
                        <div className={Qst.infoSection}>
                            <div className={Qst.titleWrap}>
                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                    펀드/투자조합의 <span className={Qst.bold}>등록원부</span> 또는 <span className={Qst.bold}>고유번호증</span>을 업로드해 주세요.
                                </h2>
                            </div>
                            <Badge title="예시" type="example" border={false} style={{padding:"8px", backgroundColor:"#ecf0f8", marginBottom:"10px"}}>
                                <img src="/images/fundFileEx.png"
                                alt="등록원부, 고유번호증 예시" />
                            </Badge>
                            <Upload className={clsx(st.fileUpload, 'fileUpload')} multiple accept=".pdf, .jpg, .png"
                                model={Vm.files}
                                uploadModel={Vm.uploadFiles}
                                deleteModel={Vm.deleteFileIds}
                                files={toJS(Vm.files)} uploadFiles={toJS(Vm.uploadFiles)}
                                >
                                <span className={st.bold}>여기</span>를 눌러 업로드해 주세요.
                            </Upload>
                        </div>
                    </ContentBox>
                }
            </Question>
        </form>
    ));
}
export default StockholdersMeetingSealProvidingStockholdersCaseTwo;