import { useObserver } from 'mobx-react';
import BoardMeetingSealProviding from './BoardMeetingSealProviding/BoardMeetingSealProviding'
import { Route } from "react-router-dom";

function BoardMeetingSealProvidingStep() {
    return useObserver(() => (
    <>
        {/* 이사회 정보 입력 */}
        <Route path="/step/changeRegistration/BoardMeetingSealProvidingStep/BoardMeetingSealProviding" component={BoardMeetingSealProviding}></Route>
    </>
    ));
}

export default BoardMeetingSealProvidingStep;