import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import { onlyNumber } from '@common/module/replaceNumber';
import regExp from '@common/module/regExp';
import { runInAction, observable } from 'mobx';

class CauseDateVm {
    constructor(model) {
        this.model = model;
    }
    get year() {
        return this.model.year;
    }
    get month() {
        return this.model.month;
    }
    get day() {
        return this.model.day;
    }
    setCauseDateYear(e) {
        runInAction(() => {
            this.model.year = onlyNumber(e.target.value);
        })
    }
    setCauseDateMonth(e) {
        runInAction(() => {
            this.model.month = onlyNumber(e.target.value);
        })
    }
    setCauseDateDay(e) { 
        runInAction(() => {
            this.model.day = onlyNumber(e.target.value);
        })
    }
    causeDateBlur(e, target) { 
        let val = e.target.value;
        if(val && val.length===1){
            e.target.value = "0" + val;
        }
        if(target === "month") {
            this.model.month = e.target.value;
        }
        if(target === "day") {
            this.model.day = e.target.value;
        }
    }
    displayCauseDateErrorMessage(type) { 
        const year =  this.model.year;
        const month = this.model.month;

        if(type === 'month') {
            if(year && regExp.year().test(year)) { //연도가 제대로 입력되었을 때, 월에 대한 error 메시지
                return true;
            } else {
                return false;
            }
        } else if(type === 'day') {
            if(year && regExp.year().test(year) && month && regExp.month().test(month)) { // 연도, 월이 제대로 입력 되었을 때, 일(날짜)에 대한 error 메시지
                return true;
            } else {
                return false;
            }
        }
    }
}

export default CauseDateVm;