import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import updown from '../../../asset/icon/btn_up_down.svg';

const Span = styled.span`  
    width: 18px;
    height: 16px;
    display: inline-block;
    background-color: transparent;
    background-image: url(${updown});
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    
`;

function SortBtn(props) {
    return (
        <Span type={props.type} className={props.className} style={props.style}></Span>
    );
};

SortBtn.propTypes = {
    /**
    * 버튼 타입
    */
    type: PropTypes.string,
    /**
    * class명
    */
    className: PropTypes.string,
    /**
    * style 속성
    */
    style: PropTypes.object,
};

SortBtn.defaultProps = {
    type : 'button',
    className : null,
    style : {},
};

export default SortBtn;

