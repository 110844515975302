import { observable, runInAction, when } from 'mobx';

let dialogModel = observable({
    type: null, //dialog 종류: alert, confirm
    content: null,
    confirmYn: null,
    btn: {
        alertBtn: null,
        confirmBtn: null,
        cancelBtn: null
    }
});

const alertBtn = () => {
    runInAction(() => {
        dialogModel.type = null;
        dialogModel.content = null;
        dialogModel.confirmYn = null;
        dialogModel.btn = {
            alertBtn: null,
            confirmBtn: null,
            cancelBtn: null
        }
    })
}

const confirmBtn = () => {
    runInAction(() => {
        dialogModel.type = null;
        dialogModel.content = null;
        dialogModel.confirmYn = true;
        dialogModel.btn = {
            alertBtn: null,
            confirmBtn: null,
            cancelBtn: null
        }
    })
}

const cancelBtn = () => {
    runInAction(() => {
        dialogModel.type = null;
        dialogModel.content = null;        
        dialogModel.confirmYn = false;
        dialogModel.btn = {
            alertBtn: null,
            confirmBtn: null,
            cancelBtn: null
        }
    })
}

const _alert = async (text) => {
    runInAction(() => {
        dialogModel.type = 'alert';
        dialogModel.content = text;
        dialogModel.btn.alertBtn = alertBtn;
    })
    await when(() => {
        return !dialogModel.type;
    })
}

const _confirm = async(text) => {
    runInAction(() => {
        dialogModel.type = 'confirm';
        dialogModel.content = text;
        dialogModel.btn.confirmBtn = confirmBtn;
        dialogModel.btn.cancelBtn = cancelBtn;
    })
    await when(() => {
        return !dialogModel.type;
    })
    return dialogModel.confirmYn;
}

export { dialogModel, _alert, _confirm, alertBtn, confirmBtn, cancelBtn};