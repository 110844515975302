import { useObserver } from 'mobx-react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './Shareholder.module.scss';
import clsx from 'clsx';
import AddBtn from '@comComponents/atoms/Button/AddBtn/AddBtn';
import Drop from '@comComponents/molecules/Dropdown/Dropdown';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import SquareBtn from '@comComponents/atoms/Button/SquareBtn/SquareBtn';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import Icon from '@comComponents/atoms/Icon/Icon';
import Select from '@comComponents/atoms/Input/Select/Select';
import CloseBtn from '@comComponents/atoms/Button/CloseBtn/CloseBtn';
import ShareholderVm from './ShareholderVm';
import SearchAddress from '@comComponents/atoms/SearchAddress/SearchAddress';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import Ul from '@comComponents/atoms/Text/UnorderListText/UnorderList';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';
import { useEffect } from 'react';

function Shareholder() {
  useEffect(() => {
    if(ShareholderVm.shareholders.length === 0) { //주주 처음 입력할 때, 처음 리스트 추가 및 열기
      ShareholderVm.addShareholder();
      ShareholderVm.state.openIdx = 0;
    }
  }, [])

  return useObserver(() => (
    <form onSubmit={(e) => ShareholderVm.submit(e)}>
      <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
        <Question list={true}>
          <ContentBox>
            <div className={Qst.dropHeader}>
              <div className={Qst.dropTitleWrap}>
                  <div className={Qst.dropTitle}>{ShareholderVm.displayTitle}</div>
                  <div className={Qst.dropTitleExplain}>고객님께서 작성하신 {ShareholderVm.text.name}분들 리스트입니다.</div>
                  <div className={clsx(Qst.dropTitleExplain, Qst.executiveWarn)}>{ShareholderVm.shareholders.length === 0 ? null : '※ 임원정보는 뒷부분에서 작성해 주세요.'}</div>
              </div>
              {ShareholderVm.addYn && <AddBtn onClick={() => ShareholderVm.addShareholder()}>{ShareholderVm.text.name} 추가하기</AddBtn>}
            </div>
          </ContentBox>
        </Question>
        {ShareholderVm.shareholders.map((item, idx) => {
          return (
            <Question list={true} key={idx}>
              <ContentBox>
                <Drop dataEq={`${idx}`} className={clsx(Qst.dropDown, ShareholderVm.setIsOpen(idx) ? 'open' : 'close')} title={ShareholderVm.setTitle(idx)} onClick={() => ShareholderVm.setDropOpen(idx)} isOpen={ShareholderVm.setIsOpen(idx)} closeBtn={ShareholderVm.addYn ? () => ShareholderVm.setCloseBtn(idx) : null} error={ShareholderVm.personErrorYn(idx)}>
                  <input type="hidden" name={`shareholders[${idx}].id`} value={item.id}/>
                    <div className={Qst.spaceBetween}>
                      <div className={Qst.infoSection}>
                        <div className={Qst.titleWrap}>
                          <h2 className={clsx(Qst.title, Qst.inside)}>
                            <span className={Qst.bold}>어떤 사람{(ShareholderVm.koreanCompanyDisplayYn || ShareholderVm.foreignerCompanyDisplayYn) ? '(법인)' : ''}</span> 인가요?
                          </h2>
                          <div className={Qst.titleExplain}>
                            임원은 다음 페이지에서 입력합니다. {ShareholderVm.text.name}부터 입력해 주세요.
                          </div>
                        </div>
                        <ul className={Qst.answerList} key={idx+item.shareholderType}>
                          <li><Radio className={Qst.answerLabel} value="KOREAN_PERSON" activeBackground checked={item.shareholderType === "KOREAN_PERSON"} onChange={(e) => ShareholderVm.setShareholderType(e,idx)} name={`shareholders[${idx}].shareholderType`}>한국인입니다.</Radio></li>
                          <li><Radio className={Qst.answerLabel} value="FOREIGNER_PERSON" activeBackground checked={item.shareholderType === "FOREIGNER_PERSON"} onChange={(e) => ShareholderVm.setShareholderType(e,idx)} name={`shareholders[${idx}].shareholderType`}>외국인입니다.</Radio></li>
                          {ShareholderVm.koreanCompanyDisplayYn &&
                            <li><Radio className={Qst.answerLabel} value="KOREAN_COMPANY" activeBackground checked={item.shareholderType === "KOREAN_COMPANY"} onChange={(e) => ShareholderVm.setShareholderType(e,idx)} name={`shareholders[${idx}].shareholderType`}>한국법인입니다.</Radio></li>
                          }
                          {ShareholderVm.foreignerCompanyDisplayYn &&
                            <li><Radio className={Qst.answerLabel} value="FOREIGNER_COMPANY" activeBackground checked={item.shareholderType === "FOREIGNER_COMPANY"} onChange={(e) => ShareholderVm.setShareholderType(e,idx)} name={`shareholders[${idx}].shareholderType`}>외국법인입니다.</Radio></li>
                          }
                        </ul>
                        <Error name={`shareholders[${idx}].shareholderType`} value={item.shareholderType} errorCase={{required:'사람(법인)을 선택해 주세요.'}}/>
                      </div>
                      <div className={Qst.qnaSection}>
                        {(ShareholderVm.smartFormType === 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY' || ShareholderVm.smartFormType === 'ESTABLISHMENT_FISHERY_STOCK_COMPANY') && //농업회사법인, 어업회사법인일 경우
                        <Qna tip={ShareholderVm.text.workType+'회사법인의 주주'} className={Qst.qnaTitle}>
                          <div className={Qst.qnaBox}>
                            <QText>누구나 {ShareholderVm.text.workType}회사법인을 설립할 수 있나요?</QText>
                            <div className="answerBox">
                              <Answer className={Qst.answer}>아니오. 설립할 때에는 {ShareholderVm.text.workType}인만 {ShareholderVm.text.workType}회사법인의 주주가 될 수 있습니다.</Answer>
                              <div className={Qst.explain}>
                                <Ul>
                                  <li>{ShareholderVm.text.workType}회사법인을 설립할 때에는 국가로부터 '{ShareholderVm.text.workType}인' 확인을 받거나 '{ShareholderVm.text.workType}경영체등록확인서'를 받은 사람만 {ShareholderVm.text.workType}회사법인의 주주가 될 수 있습니다. 또한 설립할 때에는 {ShareholderVm.text.workType === '농업' ? '농업회사법인, 영농조합법인은' : '어업회사법인, 영어조합법인'}은 {ShareholderVm.text.workType}회사법인의 주주가 될 수 있지만, 일반 법인이나 외국법인은 주주가 될 수 없습니다.</li>
                                  <li>만약 {ShareholderVm.text.workType}인이 아닌 사람이나, 일반법인, 외국법인이 주주가 되어야 한다면, 일단 설립한 후에 지분양도전차를 진행해 주세요. 참고로 설립한 이후에도 {ShareholderVm.text.workType}인이 10%이상 주식을 가지고 있어야 하므로, 전체 지분의 90%만 양도가능합니다.</li>
                                </Ul>
                              </div>
                            </div>
                          </div>
                          <div className={Qst.qnaBox}>
                            <QText>법인도 제한없이 {ShareholderVm.text.workType}회사법인의 주주가 될 수 있나요?</QText>
                            <div className="answerBox">
                              <Answer className={Qst.answer}>아니오. 법정요건을 갖춘 법인만 주주가 될 수 있습니다.</Answer>
                              <p className={Qst.explain}>
                                법인이 {ShareholderVm.text.workType}회사법인의 주주가 되기 위해서는 엄격한 법정요건이 있습니다. 자본금 1억 원 이상의 {ShareholderVm.text.workType}회사법인 등만 주주가 될 수 있습니다.
                              </p>
                              {ShareholderVm.smartFormType === 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY' && <LikeBtn className={Qst.likeBtn} href="https://www.law.go.kr/행정규칙/농림축산식품부장관이정하는요건을갖춘생산자단체범위" target="_blank">법인이 {ShareholderVm.text.workType}회사법인의 주주가 되기 위한 법정요건 바로가기</LikeBtn>}
                              {ShareholderVm.smartFormType === 'ESTABLISHMENT_FISHERY_STOCK_COMPANY' && <LikeBtn className={Qst.likeBtn} href="https://www.law.go.kr/LSW//lumLsLinkPop.do?lspttninfSeq=77184&chrClsCd=010202" target="_blank">법인이 {ShareholderVm.text.workType}회사법인의 주주가 되기 위한 법정요건 바로가기</LikeBtn>}
                            </div>
                          </div>
                        </Qna>
                        }
                        {ShareholderVm.shareholderRegisterDisplayYn && 
                        <Qna tip="주주 등록하기" className={Qst.qnaTitle} style={(ShareholderVm.smartFormType === 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY' || ShareholderVm.smartFormType === 'ESTABLISHMENT_FISHERY_STOCK_COMPANY') ? {marginTop:'57px'} : null}>
                          <div className={Qst.qnaBox}>
                            <QText>주주와 임원의 차이점은 무엇인가요?</QText>
                            <div className="answerBox">
                              <Answer className={Qst.answer} iconText="A1">주주는 회사에 자본금을 내고, 주식을 받는 사람입니다.</Answer>
                              <p className={Qst.explain}>
                                주주는 주식회사의 주인이라고 할 수 있습니다. 회사가 성장해서 가치가 높아지면, 주주가 가진 주식의 가치가 높아지고, 주주는 회사가 번 수익에 대한 배당을 받습니다. 주주가 임원을 겸하는 경우가 많지만, 반드시 일치해야 하는 것은 아닙니다. 월급사장도 존재하듯이, 주주가 임원이 아닐 수도 있습니다.
                              </p>
                              <Answer className={Qst.answer} iconText="A2">임원은 회사에서 근무하면서, 업무를 처리하는 사람입니다.</Answer>
                              <p className={Qst.explain}>
                                임원은 회사에서 각종 보직을 맡아 근무하면서, 주요 업무를 처리하는 사람입니다. 임원은 주식을 가질수도 있고, 가지지 않을 수도 있습니다.
                              </p>
                            </div>
                          </div>
                          <div className={Qst.qnaBox}>
                            <QText>공무원도 회사설립시 주주로 참여할 수 있나요?</QText>
                            <div className="answerBox">
                              <Answer className={Qst.answer}>공무원은 원칙적으로 회사설립시 주주, 임원으로 참여할 수 없습니다.</Answer>
                              <p className={Qst.explain}>
                                회사설립시 주주로 참여하는 경우 "발기인"역할을 맡게 되는데, 국가공무원 복무규정 제 25조는 공무원이 발기인이 되는 것을 금지하고 있습니다. 또한 군복무 중인 자녀도 공무원에 해당하므로 임원이 될 수 없습니다. 다만 회사 설립 후 공무원에게 주식을 양도하는 것은 가능합니다. 공무원을 주주로 참여시키고 싶다면, 일단 비공무원으로 설립을 마친 후, 주식양도절차를 진행해 주세요. 
                              </p>
                            </div>
                          </div>
                          <div className={Qst.qnaBox}>
                            <QText>주주를 정할 때 주의할 점이 있나요?</QText>
                            <div className="answerBox">
                              <Answer className={Qst.answer}>주식회사를 설립할 때에는 주식을 가지지 않은 임원이 필요합니다.</Answer>
                              <p className={Qst.explain}>
                                따라서 모든 참여하는 모든 사람이 주주가 될 수 없습니다. 상법상 주식회사를 설립할 때에는 발기인총회에서 조사보고를 할 사람("조사보고자")이 필요하기 때문입니다. 조사보고자는 주식을 가지고 있지 않은 임원 또는 공증변호사만이 맡을 수 있습니다. 모든 임원이 주주가 되는 경우, 조사보고자를 맡을 사람이 없기 때문에, 공증변호사를 선임해야 합니다. 이 경우 160만 원 가량의 추가비용이 들기 때문에, 경제적이지 않습니다.
                              </p>
                            </div>
                          </div>
                        </Qna>
                        }
                        {(ShareholderVm.smartFormType === 'ESTABLISHMENT_LIMITED_COMPANY' || ShareholderVm.smartFormType === 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY') && //유한회사, 유한책임회사일 경우
                        <Qna tip="출자자 등록하기" className={Qst.qnaTitle}>
                          <div className={Qst.qnaBox}>
                            <QText>출자자와 {ShareholderVm.text.workType}의 차이점은 무엇인가요?</QText>
                            <div className="answerBox">
                              <Answer className={Qst.answer} iconText="A1">출자자는 회사에 자본금을 내고, 지분을 받는 사람입니다.</Answer>
                              <p className={Qst.explain}>
                                출자자(=사원)는 회사의 주인이라고 할 수 있습니다. 회사가 성장해서 가치가 높아지면, 출자자가 가진 지분의 가치가 높아지고, 출자자는 회사가 번 수익에 대한 배당을 받습니다. 출자자가 {ShareholderVm.text.workType}을 겸하는 경우가 많지만, 반드시 일치해야 하는 것은 아닙니다. 월급사장도 존재하듯이, 출자자가 {ShareholderVm.text.workType}이 아닐 수도 있습니다.
                              </p>
                              <Answer className={Qst.answer} iconText="A2">{ShareholderVm.text.workType}은 회사에서 근무하면서, 업무를 처리하는 사람입니다.</Answer>
                              <p className={Qst.explain}>
                                {ShareholderVm.text.workType}{ShareholderVm.smartFormType === 'ESTABLISHMENT_LIMITED_COMPANY' ? '은' : '는'} 회사에서 각종 보직을 맡아 근무하면서, 주요 업무를 처리하는 사람입니다. {ShareholderVm.text.workType}{ShareholderVm.smartFormType === 'ESTABLISHMENT_LIMITED_COMPANY' ? '은' : '는'} 지분을 가질수도 있고, 가지지 않을 수도 있습니다.
                              </p>
                            </div>
                          </div>
                          <div className={Qst.qnaBox}>
                            <QText>공무원도 회사설립시 출자자로 참여할 수 있나요?</QText>
                            <div className="answerBox">
                              <Answer className={Qst.answer}>공무원은 원칙적으로 회사설립시 출자자, {ShareholderVm.text.workType}으로 참여할 수 없습니다.</Answer>
                              <p className={Qst.explain}>
                                회사설립시 출자자로 참여하는 경우 "발기인"역할을 맡게 되는데, 국가공무원 복무규정 제 25조는 공무원이 발기인이 되는 것을 금지하고 있습니다. 또한 군복무 중인 자녀도 공무원에 해당하므로 {ShareholderVm.text.workType}이 될 수 없습니다. 다만 회사 설립 후 공무원에게 지분을 양도하는 것은 가능합니다. 공무원을 출자자로 참여시키고 싶다면, 일단 비공무원으로 설립을 마친 후, 지분양도절차를 진행해 주세요. 
                              </p>
                            </div>
                          </div>
                        </Qna>
                        }
                      </div>
                    </div>
                    {item.shareholderType === "KOREAN_PERSON" && //한국인
                    <>
                      <div className={Qst.spaceBetween}>
                        <div className={Qst.infoSection}>
                          <div className={Qst.titleWrap}>
                            <h2 className={clsx(Qst.title, Qst.inside)}>
                              <span className={Qst.bold}>성함을 입력</span>해 주세요.
                            </h2>
                          </div>
                          <InputText placeholder="ex)홍길동" style={{width:'316px'}} value={item.koreanName} onChange={(e) => ShareholderVm.setKoreanName(e,idx)} name={`shareholders[${idx}].koreanName`} errorText={<Error name={`shareholders[${idx}].koreanName`} value={item.koreanName} errorCase={{required:'성함을 입력해 주세요.'}}/>}/><span className={Qst.nameInput}>님</span>
                        </div>
                      </div>
                      <div className={Qst.spaceBetween}>
                        <div className={Qst.infoSection}>
                          <div className={Qst.titleWrap}>
                            <h2 className={clsx(Qst.title, Qst.inside)}>
                              <span className={Qst.bold}>{item.koreanName? item.koreanName : ShareholderVm.text.name}님의 주민등록번호를 입력</span>해 주세요.
                            </h2>
                            <div className={Qst.titleExplain}>
                              주민등록번호는 헬프미 스마트 보안 시스템에 의하여 안전하게 보관됩니다.
                            </div>
                          </div>
                          <InputText placeholder="111111-1111111" style={{width:'316px'}} textType="kssn" value={item.kssn} onChange={(e) => ShareholderVm.setKssn(e,idx)} name={`shareholders[${idx}].kssn`} errorText={<Error name={`shareholders[${idx}].kssn`} value={item.kssn} errorCase={{
                            required: '주민등록번호를 입력해 주세요.',
                            validate: {
                              func: regExp.kssn(item.kssn),
                              message: '올바른 주민등록번호를 입력해 주세요.'
                            }
                          }}/>} />
                        </div>
                      </div>
                      <div className={Qst.spaceBetween} style={{paddingBottom:'30px'}}>
                        <div className={Qst.infoSection}>
                          <div className={Qst.titleWrap}>
                            <h2 className={clsx(Qst.title, Qst.inside)}>
                              {item.koreanName? item.koreanName : ShareholderVm.text.name}님의 <span className={Qst.bold}>주민등록등(초)본상 도로명주소</span>를 입력해 주세요. 
                            </h2>
                          </div>
                          <Badge className={Qst.addressBadge} title="예시" type="example" border={false}>
                              <img className={Qst.addressBadgeImg} src="/images/person_address_example.png"
                              alt="주민등록상 도로명주소 예시" />
                          </Badge>
                          <div className={Qst.rowWrap}>
                              <InputText className={Qst.inputText} style={{width:'380px'}} explain="도로명 주소" value={item?.koreanAddress?.address || ''} readOnly onClick={() => ShareholderVm.openKoreanAddressModal(true,idx)} name={`shareholders[${idx}].koreanAddress.address`} errorText={<Error name={`shareholders[${idx}].koreanAddress.address`} value={item?.koreanAddress?.address} errorCase={{required:'도로명 주소를 입력해 주세요.'}}/>} /><SquareBtn onClick={() => ShareholderVm.openKoreanAddressModal(true,idx)}>도로명 주소 찾기</SquareBtn>
                          </div>
                          <div className={Qst.rowWrap}>
                              <InputText className={Qst.inputText} style={{width:'273px'}} explain="상세 주소" placeholder="상세주소 없을 시, [없음] 입력" value={item.detailAddress} onChange={(e) => ShareholderVm.koreanAddress_detailAddress(e, idx)} name={`shareholders[${idx}].detailAddress`} errorText={<Error name={`shareholders[${idx}].detailAddress`} value={item.detailAddress} errorCase={{
                                required:'상세 주소를 입력해 주세요.',
                                pattern : {
                                  value: regExp.onlyKorNum_hyphen_comma().test(item.detailAddress),
                                  message: <span>임대차 계약서상 영문이 있다면, <br/>한글로 옮겨적어주세요.<br/>EX) B동 &gt; 비동</span>
                                }  
                              }} />} />
                              <div className={Qst.symbol}>
                                <span className={Qst.symbolIcon}>&#40;&nbsp;</span><span className={clsx(Qst.symbolText, ShareholderVm.koreanAddress_bnameFontSizeChange(idx) ? Qst.fontSize : null)}>{item?.koreanAddress?.bname || '법정동'},&nbsp;</span>
                                <InputText style={{width:'200px'}} explain="건물명" placeholder="없을 시, [없음] 입력" value={item.nameOfBuilding} onChange={(e) => ShareholderVm.koreanAddress_nameOfBuilding(e, idx)} name={`shareholders[${idx}].nameOfBuilding`} errorText={<Error name={`shareholders[${idx}].nameOfBuilding`} value={item.nameOfBuilding} errorCase={{
                                  required:'건물명을 입력해 주세요.',
                                  pattern : {
                                    value: regExp.onlyKorNum_hyphen_comma().test(item.nameOfBuilding),
                                    message: <span>건물명이 영문인 경우, 한글<br/>발음으로 옮겨적어 주세요. <br/>EX) AB타워 &gt; 에이비 타워</span>
                                  }   
                                }}/>}/><span className={Qst.symbolIcon}>&nbsp;&#41;</span>
                              </div>
                          </div>
                        </div>
                        <div className={Qst.qnaSection}>
                          <Qna className={Qst.noTitle}>
                            <div className={Qst.qnaBox}>
                              <QText>주민등록상 주소는 어디서 확인하나요?</QText>
                              <div className="answerBox">
                                <Answer className={Qst.answer}>정부24 홈페이지에서 무료로 확인가능합니다.</Answer>
                                <p className={Qst.explain}>
                                  정부24 홈페이지에 로그인하기 위해서는 금융인증서, 공동인증서(구 공인인증서), 간편인증서 등이 필요합니다.
                                </p>
                                <LikeBtn className={Qst.likeBtn} href="https://www.gov.kr/mw/AA020InfoCappView.do?CappBizCD=13100000015&HighCtgCD=A01010001&Mcode=10200" target="_blank">정부24 홈페이지 바로가기</LikeBtn>
                              </div>
                            </div>
                            <div className={Qst.qnaBox}>
                              <QText>주소에는 영어를 쓸 수 있나요?</QText>
                              <div className="answerBox">
                                <Answer className={Qst.answer}>등기소에서는 주소를 적을 때, 한글 숫자만 허용합니다. (영어X)</Answer>
                                <p className={Qst.explain}>
                                  주소에 영어가 들어가 있다면, 한글로 변환해 주세요.
                                </p>
                                <TextBox className={clsx(Qst.textBox, Qst.addressTextBox)} title="예시">
                                  <div className={Qst.textWrap}>
                                    <span className={Qst.false}>B동, 202-1001</span><Icon icon="arrow" className={Qst.iconArrow}/><span className={Qst.true}>비동, 202동 1001호</span>
                                  </div>
                                </TextBox>
                              </div>
                            </div>
                            <div className={Qst.qnaBox}>
                              <QText>주민등록상 주소와 다른주소를 입력하면 어떻게 되나요?</QText>
                              <div className="answerBox">
                                <Answer className={Qst.answer}>주민등록상 주소와 약간이라도 다른 주소를 입력하는 경우, 등기소에서 법인설립 거절을 합니다.</Answer>
                                <p className={Qst.explain}>
                                  대표님의 주소는 등기부에 기재하기 때문에, 주민등록상 주소와 완벽하게 일치해야 합니다. 만약 약간이라도 다른 주소를 입력하는 경우, 등기소에서 법인설립 거절을 하고, 이 경우 모든 서류를 바꾸어 재접수를 해야 합니다. (5~7일 시간 더 걸림) 따라서 꼭 주민등록상 주소를 정확히 확인한 후, 입력해주세요. 일반적으로 사용하는 아파트 이름 등이 주민등록상 기재된 주소와 약간 다른 경우가 존재하기 때문에, 확실히 확인이 필요합니다.
                                </p>
                              </div>
                            </div>
                          </Qna>
                        </div>
                      </div>
                    </>
                    }
                    {item.shareholderType === "FOREIGNER_PERSON" && //외국인
                    <>
                      <div className={Qst.spaceBetween}>
                        <div className={Qst.infoSection}>
                          <div className={Qst.titleWrap}>
                            <h2 className={clsx(Qst.title, Qst.inside)}>
                              <span className={Qst.bold}>한국에 거소등록 또는 외국인등록을 한 외국인</span> 인가요?
                            </h2>
                          </div>
                          <ul className={Qst.answerList}>
                            <li><Radio className={Qst.answerLabel} value={true} activeBackground checked={ShareholderVm.residenceRegistrationYn(idx) === true} onChange={(e) => ShareholderVm.setResidenceRegistrationYn(e, idx)} name={`shareholders[${idx}].residenceRegistrationYn`}>네</Radio></li>
                            {ShareholderVm.companySize === "TWO" ? 
                              <li><Radio className={Qst.answerLabel} checked={false} onClick={() => ShareholderVm.openModal('ResidenceRegistrationModal')}>아니오</Radio></li>
                              :
                              <li><Radio className={Qst.answerLabel} value={false} activeBackground checked={ShareholderVm.residenceRegistrationYn(idx) === false} onChange={(e) => ShareholderVm.setResidenceRegistrationYn(e, idx)} name={`shareholders[${idx}].residenceRegistrationYn`}>아니오</Radio></li>
                            }
                          </ul>
                          <Error name={`shareholders[${idx}].residenceRegistrationYn`} value={ShareholderVm.residenceRegistrationYn(idx)} errorCase={{required:'거소등록 또는 외국인등록 여부를 선택해 주세요.'}} />
                        </div>
                        <div className={Qst.qnaSection}>
                          <Qna className={Qst.noTitle}>
                            <QText>거소등록(외국인등록)하지 않은 외국인은 법인설립에<br/> 참여할 때 제약이 있나요?</QText>
                            <div className="answerBox">
                              <Answer className={Qst.answer}>한국에서 거소등록(외국인등록)하지 않은 외국인의 경우, 몇 가지 제약이 있습니다.</Answer>
                              <p className={Qst.explain}>
                                거소등록(외국인등록)하지 않은 외국인이 {ShareholderVm.text.name}로 참여하는 경우, 한국인 {ShareholderVm.text.name} 또는 거소등록(외국인등록)한 외국인 {ShareholderVm.text.name} 또는 한국법인 {ShareholderVm.text.name}가 1명 이상 더 있다면, 서비스 제공이 가능합니다. 하지만 그렇지 않은 경우, 서비스 제공이 어렵습니다. 거소등록(외국인등록)하지 않은 외국인이 단독 {ShareholderVm.text.name}가 되기 위해서는 아포스티유 인증 또는 영사관 인증이 필요하고, 절차가 상당히 복잡하기 때문에, 서비스를 제공하고 있지 않습니다. 양해 부탁드립니다.
                              </p>
                              <p className={Qst.explain}>
                                또한 거소등록(외국인등록)하지 않은 외국인을 임원으로 등록하는 경우, 서비스 제공이 어렵습니다. 거소등록(외국인등록)하지 않은 외국인이 임원으로 등록되려면, 아포스티유 인증 또는 영사관 인증이 필요하고, 절차가 상당히 복잡하기 때문에, 서비스를 제공하고 있지 않습니다. 양해 부탁드립니다.
                              </p>
                            </div>
                          </Qna>
                        </div>
                      </div>
                      <div className={Qst.spaceBetween}>
                        <div className={Qst.infoSection}>
                          <div className={Qst.titleWrap}>
                            <h2 className={clsx(Qst.title, Qst.inside)}>
                              <span className={Qst.bold}>국적을 선택</span>해 주세요.
                            </h2>
                            <div className={Qst.titleExplain}>
                              외국인인 경우 국적, 외국법인인 경우 본점이 위치한 나라를 선택해 주세요.
                            </div>
                          </div>
                          <Select className={Qst.select} style={{width:'142px'}} name={`shareholders[${idx}].nationalityType`} value={item.nationalityType ?? ''} onChange={(e) => ShareholderVm.setNationalityType(e,idx)} errorText={<Error name={`shareholders[${idx}].nationalityType`} value={item.nationalityType} errorCase={{required:'국적을 선택해 주세요.'}} style={{width:'157px'}}/>}>
                            <option value="">-</option>
                            <option value="일본">일본</option>
                            <option value="미국">미국</option>
                            <option value="영국">영국</option>
                            <option value="중국">중국(본토)</option>
                            <option value="프랑스">프랑스</option>
                            <option value="독일">독일</option>
                            <option value="캐나다">캐나다</option>
                            <option value="홍콩">홍콩</option>
                            <option value="기타">기타</option>
                          </Select>
                          {item.nationalityType === "기타" &&
                            <div className={clsx(Qst.rowWrap, st.rowWrap)}>
                              <InputText className={Qst.inputText} style={{width:'276px'}} explain="기타 국적" name={`shareholders[${idx}].nationalityOthers`} value={item.nationalityOthers} onChange={(e) => ShareholderVm.setNationalityOthers(e,idx)} errorText={<Error name={`shareholders[${idx}].nationalityOthers`} value={item.nationalityOthers} errorCase={{required:'기타국적을 입력해 주세요.'}}/>}/>
                            </div>
                          }
                        </div>
                      </div>
                      <div className={Qst.spaceBetween}>
                        <div className={Qst.infoSection}>
                          <div className={Qst.titleWrap}>
                            <h2 className={clsx(Qst.title, Qst.inside)}>
                              <span className={Qst.bold}>{ShareholderVm.text.name}님의 이름을 로마자와 한글로 입력</span>해 주세요.
                            </h2>
                            <div className={Qst.titleExplain}>
                              외국인의 정식 명칭을 로마자(영문)와 한글 발음으로 입력해 주세요.
                            </div>
                            <div className={Qst.titleExTextBox}>
                                <TextBox title="로마자(영문)" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>Jane Austen</span></TextBox>
                                <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>제인 오스틴</span></TextBox>
                              </div>
                            <div className={Qst.titleExplain}>
                              영문이 아닌 외국성함은 로마자(영문)으로 변환하여 한글 발음으로 입력해 주세요.
                            </div>
                            <div className={Qst.titleExTextBox}>
                                <TextBox title="로마자(영문)로 변환" titleWidth="145px" className={Qst.nameTextBox} style={{width:'171px', height:'40px', padding:'9px 20px'}}>
                                  <span className={Qst.text}>高竹</span><Icon icon="arrow" className={Qst.arrow} /><span className={Qst.text}>Takamura</span>
                                </TextBox>
                                <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'134px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>타카무라</span></TextBox>
                              </div>
                          </div>
                          <div className={Qst.rowWrap}>
                              <InputText className={Qst.inputText} style={{width:'276px'}} explain="로마자(영문) 표기" name={`shareholders[${idx}].englishName`} value={item.englishName} onChange={(e) => ShareholderVm.setEnglishName(e,idx)} errorText={<Error name={`shareholders[${idx}].englishName`} value={item.englishName} errorCase={{
                                required: '이름을 로마자로 입력해 주세요.',
                                pattern: {
                                  value: regExp.onlyEnglish().test(item.englishName),
                                  message: '로마자로 입력해 주세요.'
                                }
                              }}/>} 
                              />
                              <InputText className={Qst.inputText} style={{width:'276px'}} explain="한글 발음 표기" name={`shareholders[${idx}].koreanName`} value={item.koreanName} onChange={(e) => ShareholderVm.setKoreanName(e,idx)} errorText={<Error name={`shareholders[${idx}].koreanName`} value={item.koreanName} errorCase={{
                                required: '이름을 한글로 입력해 주세요.',
                                pattern: {
                                  value: regExp.onlyKorean().test(item.koreanName),
                                  message: '한글로 입력해 주세요.'
                                }
                              }}/>} 
                              />
                          </div>
                        </div>
                        <div className={Qst.qnaSection}>
                          <Qna className={Qst.noTitle}>
                            <QText>어떤 문서에 나온 이름을 입력해야 하나요?</QText>
                            <div className="answerBox">
                              <Answer className={Qst.answer}>한국에서 거소등록(외국인등록)을 한 외국인이라면, 거소등록증(외국인등록증) 또는 인감증명서에 기재된 성함으로 입력해 주세요.</Answer>
                            </div>
                          </Qna>   
                        </div>
                      </div>
                      <div className={Qst.spaceBetween}>
                        <div className={Qst.infoSection}>
                          <div className={Qst.titleWrap}>
                            <h2 className={clsx(Qst.title, Qst.inside)}>
                              <span className={Qst.bold}>{item.koreanName? item.koreanName : ShareholderVm.text.name}님의 여권에 기재된 생년월일을 선택</span>해 주세요.
                            </h2>
                          </div>
                          <div>
                            <div className={Qst.birthWrap}>
                              <InputText style={{width:'130px'}} placeholder="0000" name={`shareholders[${idx}].year`} value={item.year} onChange={(e) => ShareholderVm.setYear(e,idx)} maxLength="4" />
                              <span className={Qst.birthText}>년</span>
                            </div>
                            <div className={Qst.birthWrap}>
                              <InputText style={{width:'108px'}} placeholder="00" name={`shareholders[${idx}].month`} value={item.month} onChange={(e) => ShareholderVm.setMonth(e,idx)} maxLength="2" onBlur={(e) => ShareholderVm.monthBlur(e,idx)} />
                              <span className={Qst.birthText}>월</span>
                            </div>
                            <div className={Qst.birthWrap}>
                              <InputText style={{width:'108px'}} placeholder="00" name={`shareholders[${idx}].day`} value={item.day} onChange={(e) => ShareholderVm.setDay(e,idx)} maxLength="2" onBlur={(e) => ShareholderVm.dayBlur(e,idx)} />
                              <span className={Qst.birthText}>일</span>
                            </div>
                          </div>
                          <div style={{marginTop:'-27px'}}>
                            <Error name={`shareholders[${idx}].year`} value={item.year} 
                            errorCase={{
                              required:'연도를 입력해 주세요.',
                              pattern: {
                                value: regExp.year().test(item.year),
                                message: '올바른 연도를 입력해 주세요'               
                              }
                            }}
                            />
                            <Error name={`shareholders[${idx}].month`} value={item.month} 
                            errorCase={{
                              required: ShareholderVm.displayBirthErrorMessage(idx, 'month') ? '월을 입력해 주세요.' : null,
                              pattern: {
                                value: ShareholderVm.displayBirthErrorMessage(idx, 'month') ? regExp.month().test(item.month) : null,
                                message: '올바른 월을 입력해 주세요'               
                              }
                            }}
                            />
                            <Error name={`shareholders[${idx}].day`} value={item.day} 
                            errorCase={{
                              required: ShareholderVm.displayBirthErrorMessage(idx, 'day') ? '일(날짜)을 입력해 주세요.' : null,
                              pattern: {
                                value: ShareholderVm.displayBirthErrorMessage(idx, 'day') ? regExp.day().test(item.day) : null,
                                message: '올바른 일(날짜)을 입력해 주세요.'           
                              }
                            }}
                            />
                          </div>
                        </div>
                      </div>
                      {item.residenceRegistrationYn === true && 
                        <div className={Qst.spaceBetween} style={{paddingBottom:'30px'}}>
                          <div className={Qst.infoSection}>
                            <div className={Qst.titleWrap}>
                              <h2 className={clsx(Qst.title, Qst.inside)}>
                                {item.koreanName? item.koreanName : ShareholderVm.text.name}님의 <span className={Qst.bold}>거소신고(외국인등록) 사실증명상 도로명주소</span>를 입력해 주세요.
                              </h2>
                              <div className={clsx(Qst.titleExplain, Qst.noneMark)} style={{"color":"#fd6200"}}>
                                반드시 국내 거소신고(외국인등록) 사실증명과 정확히 일치하는 주소를 입력해주세요.
                              </div>
                              <div className={clsx(Qst.titleExplain, Qst.noneMark)} style={{"color":"#fd6200"}}>
                                거소신고(외국인등록) 사실증명상 주소와 일치하지 않는 경우, 법인설립이 거절됩니다. (5~7일 더 걸림)
                              </div>
                            </div>
                            <div className={Qst.rowWrap}>
                              <InputText className={Qst.inputText} style={{width:'380px'}} explain="도로명 주소" value={item?.koreanAddress?.address || ''} readOnly onClick={() => ShareholderVm.openKoreanAddressModal(true,idx)} name={`shareholders[${idx}].koreanAddress.address`} errorText={<Error name={`shareholders[${idx}].koreanAddress.address`} value={item?.koreanAddress?.address} errorCase={{required:'도로명 주소를 입력해 주세요.'}}/>} /><SquareBtn onClick={() => ShareholderVm.openKoreanAddressModal(true,idx)}>도로명 주소 찾기</SquareBtn>
                            </div>
                            <div className={Qst.rowWrap}>
                              <InputText className={Qst.inputText} style={{width:'273px'}} explain="상세 주소" placeholder="상세주소 없을 시, [없음] 입력" value={item.detailAddress} onChange={(e) => ShareholderVm.koreanAddress_detailAddress(e, idx)} name={`shareholders[${idx}].detailAddress`} errorText={<Error name={`shareholders[${idx}].detailAddress`} value={item.detailAddress} errorCase={{
                                required:'상세 주소를 입력해 주세요.',
                                pattern : {
                                  value: regExp.onlyKorNum_hyphen_comma().test(item.detailAddress),
                                  message: <span>임대차 계약서상 영문이 있다면, <br/>한글로 옮겨적어주세요.<br/>EX) B동 &gt; 비동</span>
                                }   
                              }}/>}/>
                              <div className={Qst.symbol}>
                                <span className={Qst.symbolIcon}>&#40;&nbsp;</span><span className={clsx(Qst.symbolText, ShareholderVm.koreanAddress_bnameFontSizeChange(idx) ? Qst.fontSize : null)}>{item?.koreanAddress?.bname || '법정동'},&nbsp;</span>
                                <InputText style={{width:'200px'}} explain="건물명" placeholder="없을 시, [없음] 입력" value={item.nameOfBuilding} onChange={(e) => ShareholderVm.koreanAddress_nameOfBuilding(e, idx)} name={`shareholders[${idx}].nameOfBuilding`} errorText={<Error name={`shareholders[${idx}].nameOfBuilding`} value={item.nameOfBuilding} errorCase={{
                                  required:'건물명을 입력해 주세요.',
                                  pattern : {
                                    value: regExp.onlyKorNum_hyphen_comma().test(item.nameOfBuilding),
                                    message: <span>건물명이 영문인 경우, 한글<br/>발음으로 옮겨적어 주세요. <br/>EX) AB타워 &gt; 에이비 타워</span>
                                  }                                
                                }}/>}/><span className={Qst.symbolIcon}>&nbsp;&#41;</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      {item.residenceRegistrationYn === false && 
                        <div className={Qst.spaceBetween}>
                          <div className={Qst.infoSection}>
                            <div className={Qst.titleWrap}>
                              <h2 className={clsx(Qst.title, Qst.inside)}>
                                <span className={Qst.bold}>{item.koreanName? item.koreanName : ShareholderVm.text.name}님의 주소를 로마자와 한글로 입력</span>해 주세요.
                              </h2>
                              <div className={Qst.titleExplain}>
                                외국 주소를 로마자(영문)와 한글 발음으로 입력해 주세요.
                              </div>
                              <div className={Qst.titleExTextBox}>
                                  <TextBox title="로마자(영문)" className={Qst.foreignAddressTextBox} style={{width:'458px', height:'40px', padding:'9px 20px'}}>
                                    <span className={Qst.text}>28445 tomless Ave Torrance CA United States</span>
                                  </TextBox>
                                  <TextBox title="한글 발음" className={Qst.foreignAddressTextBox} style={{width:'458px', height:'40px', padding:'9px 20px'}}>
                                    <span className={Qst.text}>미국 캘리포니아주 토랜스시 애비뉴 28445</span>
                                  </TextBox>
                                </div>
                            </div>
                            <div className={Qst.rowWrap}>
                              <InputText explain="로마자(영문) 표기" style={{width:'560px'}} value={item?.foreignAddress?.englishAddress} onChange={(e) => ShareholderVm.foreignAddress_englishAddress(e,idx)} name={`shareholders[${idx}].foreignAddress.englishAddress`} errorText={<Error name={`shareholders[${idx}].foreignAddress.englishAddress`} value={item?.foreignAddress?.englishAddress} errorCase={{
                                required: '주소를 로마자로 입력해 주세요.',
                                pattern: {
                                  value: regExp.onlyEngNum().test(item?.foreignAddress?.englishAddress),
                                  message: '로마자로 입력해 주세요.'
                                }
                              }}/>}/>
                            </div>
                            <div className={Qst.rowWrap}>
                              <InputText explain="한글 발음 표기" style={{width:'560px'}} value={item?.foreignAddress?.koreanAddress} onChange={(e) => ShareholderVm.foreignAddress_koreanAddress(e,idx)} name={`shareholders[${idx}].foreignAddress.koreanAddress`} errorText={<Error name={`shareholders[${idx}].foreignAddress.koreanAddress`} value={item?.foreignAddress?.koreanAddress} errorCase={{
                                required: '주소를 한글로 입력해 주세요.',
                                pattern: {
                                  value: regExp.onlyKorNum().test(item?.foreignAddress?.koreanAddress),
                                  message: '한글로 입력해 주세요.'
                                }
                              }}/>}/>
                            </div>
                          </div>
                        </div>  
                      }
                    </>
                    }
                    {item.shareholderType === "KOREAN_COMPANY" && //한국법인
                    <>
                      <div className={Qst.spaceBetween}>
                        <div className={Qst.infoSection}>
                          <div className={Qst.titleWrap}>
                            <h2 className={clsx(Qst.title, Qst.inside)}>
                              <span className={Qst.bold}>회사 이름을 입력</span>해 주세요.
                            </h2>
                            <div className={Qst.titleExplain}>
                              정식명칭을 입력해 주세요. ex) 주식회사 신한은행, 현대산업개발 유한회사
                            </div>
                          </div>
                          <div className={Qst.rowWrap}>
                              <InputText style={{width:'316px'}} name={`shareholders[${idx}].koreanName`} value={item.koreanName} onChange={(e) => ShareholderVm.setKoreanName(e,idx)} errorText={<Error name={`shareholders[${idx}].koreanName`} value={item.koreanName} errorCase={{required:'회사 이름을 입력해 주세요.'}} />}/>
                          </div>
                        </div>
                      </div>
                      <div className={Qst.spaceBetween}>
                        <div className={Qst.infoSection}>
                          <div className={Qst.titleWrap}>
                            <h2 className={clsx(Qst.title, Qst.inside)}>
                              <span className={Qst.bold}>{item.koreanName? item.koreanName : '회사'}의 등기부상 대표자를 입력</span>해 주세요.
                            </h2>
                            <div className={Qst.titleExplain}>
                              대표이사가 2명 이사인 경우, 모두 입력해 주세요.
                            </div>
                          </div>
                          <Badge className={st.CompanyExecutiveBadge} title="예시" type="example" border={false}>
                            <img className={st.CompanyExecutiveBadgeImg} src="/images/executive_example.png"
                            alt="회사 등기부상 대표자 예시" />
                          </Badge>
                          {item.koreanCompanyRepresentatives.map((item2, idx2) => {
                            return (
                              <div className={Qst.rowWrap} key={idx2+item.koreanCompanyRepresentatives}>
                                <input type="hidden" name={`shareholders[${idx}].koreanCompanyRepresentatives[${idx2}].id`} value={item2?.id || ''}/>
                                <Select className={Qst.select} style={{width:'278px'}} name={`shareholders[${idx}].koreanCompanyRepresentatives[${idx2}].position`} value={item2?.position ?? ''} explain={idx2 === 0 ? "직책" : null} onChange={(e) => ShareholderVm.koreanCompanyRepresentatives_position(e,idx)} errorText={<Error name={`shareholders[${idx}].koreanCompanyRepresentatives[${idx2}].position`} value={item2?.position} errorCase={{required:'직책을 선택해 주세요.'}}/>} disabled={idx2 === 0 ? false : true}>
                                  <option value="">-</option> 
                                  <option value="대표이사">대표이사</option>
                                  <option value="공동대표이사">공동대표이사</option>
                                  <option value="사내이사">사내이사</option>
                                  <option value="대표업무집행자">대표업무집행자</option>
                                  <option value="공동대표업무집행자">공동대표업무집행자</option>
                                  <option value="업무집행자">업무집행자</option>
                                  <option value="업무집행조합원">업무집행조합원</option>
                                  <option value="공동업무집행조합원">공동업무집행조합원</option>
                                  <option value="대표자">대표자</option>
                                  <option value="공동대표자">공동대표자</option>
                                </Select>
                                <div className={st.compoWrap}>
                                  <InputText className={clsx(Qst.inputText,st.inputText)} style={{width:'278px'}} explain={idx2 === 0 ? "성함" : null} 
                                  name={`shareholders[${idx}].koreanCompanyRepresentatives[${idx2}].name`} 
                                  value={item2?.name} 
                                  onChange={(e) => ShareholderVm.koreanCompanyRepresentatives_name(e,idx,idx2)} 
                                  errorText={<Error name={`shareholders[${idx}].koreanCompanyRepresentatives[${idx2}].name`} value={item2?.name} errorCase={{required:'성함을 입력해 주세요.'}}/>}/>
                                  {idx2 !== 0  &&
                                    <CloseBtn className={st.closeBtn } onClick={() => ShareholderVm.setKoreanCompanyRepresentativesCloseBtn(idx,idx2)} />
                                  }
                                </div>
                              </div>  
                            )
                          })}
                          <div className={clsx(Qst.rowWrap, st.representativeAddRowWrap)}>
                            <button type="button" className={st.representativeAddBtn} onClick={() => ShareholderVm.addKoreanCompanyRepresentatives(idx)} >대표자 추가하기<Icon icon="addGray" className={st.addGray} /></button>
                          </div>
                        </div>
                      </div>
                      <div className={Qst.spaceBetween} style={{paddingBottom:'30px'}}>
                        <div className={Qst.infoSection}>
                          <div className={Qst.titleWrap}>
                            <h2 className={clsx(Qst.title, Qst.inside)}>
                              <span className={Qst.bold}>{item.koreanName? item.koreanName : '회사'}의 등기부 등본상 도로명주소를 입력</span>해 주세요.
                            </h2>
                          </div>
                          <Badge className={st.CompanyAddressBadge} title="예시" type="example" border={false}>
                              <img className={st.CompanyAddressBadgeImg} src="/images/company_address_example.png"
                              alt="회사 등기부 등록상 도로명주소 예시" />
                          </Badge>
                          <div className={Qst.rowWrap}>
                              <InputText className={Qst.inputText} style={{width:'380px'}} explain="도로명 주소" value={item?.koreanAddress?.address || ''} readOnly onClick={() => ShareholderVm.openKoreanAddressModal(true,idx)} name={`shareholders[${idx}].koreanAddress.address`} errorText={<Error name={`shareholders[${idx}].koreanAddress.address`} value={item?.koreanAddress?.address} errorCase={{required:'도로명 주소를 입력해 주세요.'}}/>}/><SquareBtn onClick={() => ShareholderVm.openKoreanAddressModal(true,idx)}>도로명 주소 찾기</SquareBtn>
                          </div>
                          <div className={Qst.rowWrap}>
                              <InputText className={Qst.inputText} style={{width:'273px'}} explain="상세 주소" placeholder="상세주소 없을 시, [없음] 입력" value={item.detailAddress} onChange={(e) => ShareholderVm.koreanAddress_detailAddress(e, idx)} name={`shareholders[${idx}].detailAddress`} errorText={<Error name={`shareholders[${idx}].detailAddress`} value={item.detailAddress} errorCase={{
                                required:'상세 주소를 입력해 주세요.',
                                pattern : {
                                  value: regExp.onlyKorNum_hyphen_comma().test(item.detailAddress),
                                  message: <span>임대차 계약서상 영문이 있다면, <br/>한글로 옮겨적어주세요.<br/>EX) B동 &gt; 비동</span>
                                }   
                              }}/>} />
                              <div className={Qst.symbol}>
                                  <span className={Qst.symbolIcon}>&#40;&nbsp;</span><span className={clsx(Qst.symbolText, ShareholderVm.koreanAddress_bnameFontSizeChange(idx) ? Qst.fontSize : null )}>{item?.koreanAddress?.bname || '법정동'},&nbsp;</span>
                                  <InputText style={{width:'200px'}} explain="건물명" placeholder="없을 시, [없음] 입력" value={item.nameOfBuilding} onChange={(e) => ShareholderVm.koreanAddress_nameOfBuilding(e, idx)} name={`shareholders[${idx}].nameOfBuilding`} errorText={<Error name={`shareholders[${idx}].nameOfBuilding`} value={item.nameOfBuilding} errorCase={{
                                    required:'건물명을 입력해 주세요.',
                                    pattern : {
                                      value: regExp.onlyKorNum_hyphen_comma().test(item.nameOfBuilding),
                                      message: <span>건물명이 영문인 경우, 한글<br/>발음으로 옮겨적어 주세요. <br/>EX) AB타워 &gt; 에이비 타워</span>
                                    }   
                                  }}/>}/><span className={Qst.symbolIcon}>&nbsp;&#41;</span>
                              </div>
                          </div>
                        </div>
                      </div>
                    </>
                    }
                    {item.shareholderType === "FOREIGNER_COMPANY" && //외국법인
                    <>
                      <div className={Qst.spaceBetween}>
                        <div className={Qst.infoSection}>
                          <div className={Qst.titleWrap}>
                            <h2 className={clsx(Qst.title, Qst.inside)}>
                              <span className={Qst.bold}>국적을 선택</span>해 주세요.
                            </h2>
                            <div className={Qst.titleExplain}>
                              외국인인 경우 국적, 외국법인인 경우 본점이 위치한 나라를 선택해 주세요.
                            </div>
                          </div>
                          <Select className={Qst.select} style={{width:'142px'}} name={`shareholders[${idx}].nationalityType`} value={item.nationalityType ?? ''} onChange={(e) => ShareholderVm.setNationalityType(e,idx)} errorText={<Error name={`shareholders[${idx}].nationalityType`} value={item.nationalityType} errorCase={{required:'국적을 선택해 주세요.'}} style={{width:'152px'}}/>}>
                              <option value="">-</option>
                              <option value="일본">일본</option>
                              <option value="미국">미국</option>
                              <option value="영국">영국</option>
                              <option value="중국">중국(본토)</option>
                              <option value="프랑스">프랑스</option>
                              <option value="독일">독일</option>
                              <option value="캐나다">캐나다</option>
                              <option value="홍콩">홍콩</option>
                              <option value="기타">기타</option>
                            </Select>
                            {item.nationalityType === "기타" &&
                              <div className={clsx(Qst.rowWrap, st.rowWrap)}>
                                <InputText className={Qst.inputText} style={{width:'276px'}} explain="기타 국적" name={`shareholders[${idx}].nationalityOthers`} value={item.nationalityOthers} onChange={(e) => ShareholderVm.setNationalityOthers(e,idx)} errorText={<Error name={`shareholders[${idx}].nationalityOthers`} value={item.nationalityOthers} errorCase={{required:'기타국적을 입력해 주세요.'}}/>}/>
                              </div>
                            }
                        </div>
                        <div className={Qst.qnaSection}>
                          <Qna className={Qst.noTitle}>
                            <QText>외국법인은 법인설립에 참여할 때 제약이 있나요?</QText>
                              <div className="answerBox">
                                <Answer className={Qst.answer}>외국법인이 {ShareholderVm.text.name}로 참여할 경우, 몇 가지 제약이 있습니다.</Answer>
                                <p className={Qst.explain}>
                                  외국법인이 {ShareholderVm.text.name}로 참여하는 경우, 한국인 {ShareholderVm.text.name} 또는 거소등록한 외국인 {ShareholderVm.text.name} 또는 한국법인 {ShareholderVm.text.name}가 1명 더 있다면, 서비스 제공이 가능합니다. 하지만 그렇지 않은 경우 서비스 제공이 어렵습니다. 외국법인이 단독 {ShareholderVm.text.name}가 되기 위해서는 아포스티유 인증 또는 영사관 인증이 필요하고, 절차가 상당히 복잡하기 때문에, 서비스를 제공하고 있지 않습니다. 양해 부탁드립니다.
                                </p>
                              </div>
                          </Qna>
                        </div>
                      </div>
                      <div className={Qst.spaceBetween}>
                        <div className={Qst.infoSection}>
                          <div className={Qst.titleWrap}>
                            <h2 className={clsx(Qst.title, Qst.inside)}>
                              <span className={Qst.bold}>회사 이름을 로마자와 한글로 입력</span>해 주세요.
                            </h2>
                            <div className={Qst.titleExplain}>
                              외국법인의 정식 명칭을 로마자(영문)와 한글 발음으로 입력해 주세요.
                            </div>
                            <div className={Qst.titleExTextBox}>
                              <TextBox title="로마자(영문)" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>Apple</span></TextBox>
                              <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>애플</span></TextBox>
                            </div>
                            <div className={Qst.titleExplain} style={{marginTop:'8px'}}>
                              영문이 아닌 회사이름은, 로마자(영문)으로 변환하여 한글 발음으로 입력해 주세요.
                            </div>
                            <div className={Qst.titleExTextBox}>
                              <TextBox title="로마자(영문)로 변환" titleWidth="145px" className={Qst.nameTextBox} style={{width:'171px', height:'40px', padding:'9px 20px'}}>
                                <span className={Qst.text}>高竹</span><Icon icon="arrow" className={Qst.arrow} /><span className={Qst.text}>Takamura</span>
                              </TextBox>
                              <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'134px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>타카무라</span></TextBox>
                            </div>
                          </div>   
                          <div className={Qst.rowWrap}>
                              <InputText className={Qst.inputText} style={{width:'276px'}} explain="로마자(영문) 표기" name={`shareholders[${idx}].englishName`} value={item.englishName}
                              onChange={(e) => ShareholderVm.setEnglishName(e, idx)} errorText={<Error name={`shareholders[${idx}].englishName`} value={item.englishName} errorCase={{
                                required: '회사 이름을 로마자로 입력해 주세요.',
                                pattern: {
                                  value: regExp.onlyEngNum().test(item.englishName),
                                  message: '로마자로 입력해 주세요.'
                                }
                              }}/>} />
                              <InputText className={Qst.inputText} style={{width:'276px'}} explain="한글 발음 표기" name={`shareholders[${idx}].koreanName`} value={item.koreanName} onChange={(e) => ShareholderVm.setKoreanName(e,idx)} errorText={<Error name={`shareholders[${idx}].koreanName`} value={item.koreanName} errorCase={{
                                required: '회사 이름을 한글로 입력해 주세요.',
                                pattern: {
                                  value: regExp.onlyKorNum().test(item.koreanName),
                                  message: '한글로 입력해 주세요.'
                                }
                              }}/>} />
                          </div> 
                        </div>
                      </div>
                      <div className={Qst.spaceBetween}>
                        <div className={Qst.infoSection}>
                          <div className={Qst.titleWrap}>
                            <h2 className={clsx(Qst.title, Qst.inside)}>
                              <span className={Qst.bold}>{item.koreanName? item.koreanName : '회사'}의 대표자 성함을 로마자와 한글로 입력</span>해 주세요.
                            </h2>
                            <div className={Qst.titleExplain}>
                              영문이 아닌 성함은 변환하여 로마자(영문)와 한글 발음으로 입력해 주세요.
                            </div>
                          </div>
                          {item.foreignerCompanyRepresentatives.map((item2, idx2) => {
                            return (
                              <div className={Qst.rowWrap} key={idx2+item.foreignerCompanyRepresentatives}>
                                <input type="hidden" name={`shareholders[${idx}].foreignerCompanyRepresentatives[${idx2}].id`} value={item2?.id || ''} />
                                <InputText explain={idx2===0 ? "로마자(영문) 표기" : null} className={Qst.inputText} style={{width:'276px'}} name={`shareholders[${idx}].foreignerCompanyRepresentatives[${idx2}].englishName`} value={item2?.englishName} onChange={(e) => ShareholderVm.foreignerCompanyRepresentatives_englishName(e,idx,idx2)} errorText={<Error name={`shareholders[${idx}].foreignerCompanyRepresentatives[${idx2}].englishName`} value={item2?.englishName} errorCase={{
                                  required: '성함을 로마자로 입력해 주세요.',
                                  pattern: {
                                    value: regExp.onlyEnglish().test(item2?.englishName),
                                    message: '로마자로 입력해 주세요.'
                                  }
                                }}/>} />
                                <div className={st.compoWrap}>
                                  <InputText explain={idx2===0 ? "한글 발음 표기" : null} className={clsx(Qst.inputText, st.inputText)} style={{width:'276px'}} name={`shareholders[${idx}].foreignerCompanyRepresentatives[${idx2}].koreanName`} value={item2?.koreanName} onChange={(e) => ShareholderVm.foreignerCompanyRepresentatives_koreanName(e,idx,idx2)} errorText={<Error name={`shareholders[${idx}].foreignerCompanyRepresentatives[${idx2}].koreanName`} value={item2?.koreanName} errorCase={{
                                    required: '성함을 한글로 입력해 주세요.',
                                    pattern: {
                                      value: regExp.onlyKorean().test(item2?.koreanName),
                                      message: '한글로 입력해 주세요.'
                                    }
                                  }}/>} />
                                  {idx2 !== 0 && 
                                    <CloseBtn className={st.closeBtn} onClick={() => ShareholderVm.setForeignerCompanyRepresentativesCloseBtn(idx,idx2)}/>
                                  }
                                </div>
                              </div>
                            )
                          })}
                          <div className={clsx(Qst.rowWrap, st.representativeAddRowWrap)}>
                            <button type="button" className={st.representativeAddBtn} onClick={() => ShareholderVm.foreignerCompanyRepresentatives(idx)}>대표자 추가하기<Icon icon="addGray" className={st.addGray} /></button>
                          </div>
                        </div>
                      </div>
                      <div className={Qst.spaceBetween}>
                        <div className={Qst.infoSection}>
                          <div className={Qst.titleWrap}>
                            <h2 className={clsx(Qst.title, Qst.inside)}>
                              <span className={Qst.bold}>{item.koreanName? item.koreanName : '회사'} 주소를 로마자와 한글로 입력</span>해 주세요.
                            </h2>
                            <div className={Qst.titleExplain}>
                              외국 주소를 로마자(영문)와 한글 발음으로 입력해 주세요.
                            </div>
                            <div className={Qst.titleExTextBox}>
                              <TextBox title="로마자(영문)" className={Qst.foreignAddressTextBox} style={{width:'458px', height:'40px', padding:'9px 20px'}}>
                                <span className={Qst.text}>28445 tomless Ave Torrance CA United States</span>
                              </TextBox>
                              <TextBox title="한글 발음" className={Qst.foreignAddressTextBox} style={{width:'458px', height:'40px', padding:'9px 20px'}}>
                                <span className={Qst.text}>미국 캘리포니아주 토랜스시 애비뉴 28445</span>
                              </TextBox>
                            </div>
                          </div>
                          <div className={Qst.rowWrap}>
                            <InputText explain="로마자(영문) 표기" className={Qst.inputText} style={{width:'560px'}} name={`shareholders[${idx}].foreignAddress.englishAddress`} value={item?.foreignAddress?.englishAddress} onChange={(e) => ShareholderVm.foreignAddress_englishAddress(e,idx)} errorText={<Error name={`shareholders[${idx}].foreignAddress.englishAddress`} value={item?.foreignAddress?.englishAddress} errorCase={{
                              required: '회사 주소를 로마자로 입력해 주세요.',
                              pattern: {
                                value: regExp.onlyEngNum().test(item?.foreignAddress?.englishAddress),
                                message: '로마자로 입력해 주세요.'
                              }
                            }}/>} />
                          </div>
                          <div className={Qst.rowWrap}>
                            <InputText explain="한글 발음 표기" className={Qst.inputText} style={{width:'560px'}} name={`shareholders[${idx}].foreignAddress.koreanAddress`} value={item?.foreignAddress?.koreanAddress} onChange={(e) => ShareholderVm.foreignAddress_koreanAddress(e,idx)} errorText={<Error name={`shareholders[${idx}].foreignAddress.koreanAddress`} value={item?.foreignAddress?.koreanAddress} errorCase={{
                              required: '회사 주소를 한글로 입력해 주세요.',
                              pattern: {
                                value: regExp.onlyKorNum().test(item?.foreignAddress?.koreanAddress),
                                message: '한글로 입력해 주세요.'
                              }
                            }}/>} />
                          </div>
                        </div>
                      </div>
                    </>
                    }
                </Drop>
              </ContentBox>
            </Question>
          )
        })}
        {ShareholderVm.addYn &&
          <Question list={true}>
            <ContentBox>
              <button type="button" className={Qst.stockholderAddBtn} onClick={() => ShareholderVm.addShareholder()}>{ShareholderVm.text.name} 추가하기<Icon icon="addGray" className={Qst.addGray}/></button>
            </ContentBox>
          </Question>
        }  
        {/* 도로명주소 팝업 */}
        {ShareholderVm.koreanAddressModal && 
          <SearchAddress onClose={() => ShareholderVm.openKoreanAddressModal(false,null)} onComplete={(data) => ShareholderVm.koreanAddressComplate(data)} />
        }
    </form>
  ));
}

export default Shareholder;