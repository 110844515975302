class StandbyFreeIssueOfNewSharesStepVm {
    sum(freeIssueOfNewSharesAllotmentInfos) {
        let sum = 0;
        freeIssueOfNewSharesAllotmentInfos.forEach((info) => {
            sum = sum + info.newStockAmount;
        })
        return sum;
    }
}

export default new StandbyFreeIssueOfNewSharesStepVm();