import { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import StandbySimpleNewSharesAcquirerVm from './StandbySimpleNewSharesAcquirerVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './StandbySimpleNewSharesAcquirer.module.scss';
import clsx from 'clsx';
import AddBtn from '@comComponents/atoms/Button/AddBtn/AddBtn';
import Drop from '@comComponents/molecules/Dropdown/Dropdown';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';
import KoreanPerson from './KoreanPerson/KoreanPerson';
import ForeignerPerson from './ForeignerPerson/ForeignerPerson';
import KoreanCompany from './KoreanCompany/KoreanCompany';
import ForeignerCompany from './ForeignerCompany/ForeignerCompany';
import FundAndInvestmentAssociation from './FundAndInvestmentAssociation/FundAndInvestmentAssociation';

function StandbySimpleNewSharesAcquirer() {

    useEffect(() => {
        StandbySimpleNewSharesAcquirerVm.load();
    }, []);

    return useObserver(() => (
        <form onSubmit={(e) => StandbySimpleNewSharesAcquirerVm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            <Question list={true}>
                <ContentBox>
                    <div className={Qst.dropHeader}>
                        <div className={Qst.dropTitleWrap}>
                            <div className={Qst.dropTitle}>신주인수인(=투자자)님의 정보를 입력해 주세요.</div>
                            <div className={Qst.dropTitleExplain}>고객님께서 작성하신 신주인수인의 리스트입니다.</div>
                        </div>
                    </div>
                </ContentBox>
            </Question>
            {StandbySimpleNewSharesAcquirerVm.newSharesAcquirers?.map((item, idx) => {
                return (
                    <Question list={true} key={idx}>
                        <ContentBox>
                        <Drop dataEq={`${idx}`} className={clsx(Qst.dropDown, StandbySimpleNewSharesAcquirerVm.setIsOpen(idx) ? 'open' : 'close')} title={StandbySimpleNewSharesAcquirerVm.setTitle(idx)} onClick={() => StandbySimpleNewSharesAcquirerVm.setDropOpen(idx)} edit isOpen={StandbySimpleNewSharesAcquirerVm.setIsOpen(idx)} error={StandbySimpleNewSharesAcquirerVm.personErrorYn(idx)}>
                            <input type="hidden" name={`newSharesAcquirers[${idx}].id`} value={item.id}/>
                            <div className={Qst.spaceBetween}>
                                <div className={Qst.infoSection}>
                                    <div className={Qst.titleWrap}>
                                        <h2 className={clsx(Qst.title, Qst.inside)}>
                                            신주인수인님은 <span className={Qst.bold}> 어떤 사람(법인)</span>인가요?
                                        </h2>
                                    </div>
                                    <ul className={Qst.answerList} key={idx+item.shareholderType}>
                                        {(item.shareholderType === "KOREAN_PERSON" || StandbySimpleNewSharesAcquirerVm.state.reviseInfo) && 
                                            <li><Radio className={Qst.answerLabel} value="KOREAN_PERSON" activeBackground checked={item.shareholderType === "KOREAN_PERSON"} onChange={(e) => StandbySimpleNewSharesAcquirerVm.setShareholderType(e,idx)} name={`newSharesAcquirers[${idx}].shareholderType`}>한국인입니다.</Radio></li>
                                        }
                                        {(item.shareholderType === "FOREIGNER_PERSON" || StandbySimpleNewSharesAcquirerVm.state.reviseInfo) &&     
                                            <li><Radio className={Qst.answerLabel} value="FOREIGNER_PERSON" activeBackground checked={item.shareholderType === "FOREIGNER_PERSON"} onChange={(e) => StandbySimpleNewSharesAcquirerVm.setShareholderType(e,idx)} name={`newSharesAcquirers[${idx}].shareholderType`}>외국인입니다.</Radio></li>
                                        }
                                        {(item.shareholderType === "KOREAN_COMPANY" || StandbySimpleNewSharesAcquirerVm.state.reviseInfo) &&     
                                            <li><Radio className={Qst.answerLabel} value="KOREAN_COMPANY" activeBackground checked={item.shareholderType === "KOREAN_COMPANY"} onChange={(e) => StandbySimpleNewSharesAcquirerVm.setShareholderType(e,idx)} name={`newSharesAcquirers[${idx}].shareholderType`}>한국법인입니다.</Radio></li>
                                        }
                                        {(item.shareholderType === "FOREIGNER_COMPANY" || StandbySimpleNewSharesAcquirerVm.state.reviseInfo) &&     
                                            <li><Radio className={Qst.answerLabel} value="FOREIGNER_COMPANY" activeBackground checked={item.shareholderType === "FOREIGNER_COMPANY"} onChange={(e) => StandbySimpleNewSharesAcquirerVm.setShareholderType(e,idx)} name={`newSharesAcquirers[${idx}].shareholderType`}>외국법인입니다.</Radio></li>
                                        }
                                        {(item.shareholderType === "FUND_AND_INVESTMENT_ASSOCIATION" || StandbySimpleNewSharesAcquirerVm.state.reviseInfo) &&     
                                            <li><Radio className={Qst.answerLabel} value="FUND_AND_INVESTMENT_ASSOCIATION" activeBackground checked={item.shareholderType === "FUND_AND_INVESTMENT_ASSOCIATION"} onChange={(e) => StandbySimpleNewSharesAcquirerVm.setShareholderType(e,idx)} name={`newSharesAcquirers[${idx}].shareholderType`}>펀드/투자조합입니다.</Radio></li>
                                        }
                                        
                                    </ul>
                                    {item.shareholderType && 
                                        <Error name={`newSharesAcquirers[${idx}].shareholderType`} value={item.shareholderType} errorCase={{required:'사람을 선택해 주세요.'}}/>
                                    }
                                    {StandbySimpleNewSharesAcquirerVm.state.reviseInfo === false && 
                                        <button type="button" className={st.reviseBtn} onClick={() => StandbySimpleNewSharesAcquirerVm.reviseInfo()}><span>답변 수정하기</span></button>
                                    }
                                </div>
                                <div className={Qst.qnaSection}>
                                    <Qna tip="신주인수인 입력" className={Qst.qnaTitle}>
                                        <div className={Qst.qnaBox}>
                                            <QText>신주인수인은 누구인가요?</QText>
                                            <div className="answerBox">
                                                <Answer className={Qst.answer}>신주인수인은 투자자 또는 이번 신주발행시 회사에 자본금을 내고 주식을 받을 사람/법인을 말합니다.</Answer>
                                            </div>
                                        </div>
                                    </Qna>
                                </div>
                            </div>
                            {item.shareholderType === "KOREAN_PERSON" && 
                                <KoreanPerson info={item} idx={idx} reviseInfoList={StandbySimpleNewSharesAcquirerVm.state.reviseInfoList} setReviseInfoList={(idx, infoType) => StandbySimpleNewSharesAcquirerVm.setReviseInfoList(idx, infoType)} /> 
                            }
                            {item.shareholderType === "FOREIGNER_PERSON" && 
                                <ForeignerPerson info={item} idx={idx} reviseInfoList={StandbySimpleNewSharesAcquirerVm.state.reviseInfoList} setReviseInfoList={(idx, infoType) => StandbySimpleNewSharesAcquirerVm.setReviseInfoList(idx, infoType)}/> 
                            }
                            {item.shareholderType === "KOREAN_COMPANY" && 
                                <KoreanCompany info={item} idx={idx} reviseInfoList={StandbySimpleNewSharesAcquirerVm.state.reviseInfoList} setReviseInfoList={(idx, infoType) => StandbySimpleNewSharesAcquirerVm.setReviseInfoList(idx, infoType)}/> 
                            }
                            {item.shareholderType === "FOREIGNER_COMPANY" && 
                                <ForeignerCompany info={item} idx={idx} reviseInfoList={StandbySimpleNewSharesAcquirerVm.state.reviseInfoList} setReviseInfoList={(idx, infoType) => StandbySimpleNewSharesAcquirerVm.setReviseInfoList(idx, infoType)}/> 
                            }
                            {item.shareholderType === "FUND_AND_INVESTMENT_ASSOCIATION" && 
                                <FundAndInvestmentAssociation info={item} idx={idx} reviseInfoList={StandbySimpleNewSharesAcquirerVm.state.reviseInfoList} setReviseInfoList={(idx, infoType) => StandbySimpleNewSharesAcquirerVm.setReviseInfoList(idx, infoType)}/> 
                            }
                        </Drop>
                        </ContentBox>
                    </Question>
                )
            })}
        </form>
    ));
}
export default StandbySimpleNewSharesAcquirer;