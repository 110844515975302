import { useObserver } from 'mobx-react';
import ExecutiveMemberChangeNewChangeVm from './ExecutiveMemberChangeNewChangeVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './ExecutiveMemberChangeNewChange.module.scss';
import clsx from 'clsx';
import AddBtn from '@comComponents/atoms/Button/AddBtn/AddBtn';
import Drop from '@comComponents/molecules/Dropdown/Dropdown';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import Icon from '@comComponents/atoms/Icon/Icon';
import Select from '@comComponents/atoms/Input/Select/Select';
import SearchAddress from '@comComponents/atoms/SearchAddress/SearchAddress';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';
import NewAddress from '../NewAddress/NewAddress';
import NewPosition from '../NewPosition/NewPosition';

function ExecutiveMemberChangeNewChange() {
    return useObserver(() => (
        <form onSubmit={(e) => ExecutiveMemberChangeNewChangeVm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            <Question list={true}>
                <ContentBox>
                    <div className={Qst.dropHeader}>
                        <div className={Qst.dropTitleWrap}>
                            <div className={Qst.dropTitle}>{ExecutiveMemberChangeNewChangeVm.displayDropTitle}</div>
                            <div className={Qst.dropTitleExplain}>고객님께서 작성하신 신규 임원의 리스트입니다.</div>
                        </div>
                        {ExecutiveMemberChangeNewChangeVm.keepProceedYn === false && <AddBtn onClick={() => ExecutiveMemberChangeNewChangeVm.addNewExecutiveMembers()}>신규 임원 추가하기</AddBtn>}
                    </div>
                </ContentBox>
            </Question>
            {ExecutiveMemberChangeNewChangeVm.newExecutiveMembers.map((item, idx) => {
                return (
                    <Question list={true} key={idx}>
                        <ContentBox>
                            <Drop dataEq={`${idx}`} className={clsx(Qst.dropDown, ExecutiveMemberChangeNewChangeVm.setIsOpen(idx) ? 'open' : 'close')} title={ExecutiveMemberChangeNewChangeVm.setTitle(idx)} onClick={() => ExecutiveMemberChangeNewChangeVm.setDropOpen(idx)} edit={!ExecutiveMemberChangeNewChangeVm.keepProceedYn} isOpen={ExecutiveMemberChangeNewChangeVm.keepProceedYn ? true : ExecutiveMemberChangeNewChangeVm.setIsOpen(idx)} closeBtn={ExecutiveMemberChangeNewChangeVm.keepProceedYn === false ? () => ExecutiveMemberChangeNewChangeVm.setCloseBtn(idx) : null} error={ExecutiveMemberChangeNewChangeVm.personErrorYn(idx)}>
                                <input type="hidden" name={`executiveMembers[${idx}].id`} value={item.id}/>
                                <div className={Qst.spaceBetween}>
                                    <div className={Qst.infoSection}>
                                        <div className={Qst.titleWrap}>
                                            <h2 className={clsx(Qst.title, Qst.inside)}>
                                                <span className={Qst.bold}>신규 {ExecutiveMemberChangeNewChangeVm.displayPosition(idx)}님</span>은 어떤 사람인가요?
                                            </h2>
                                        </div>
                                        <ul className={Qst.answerList} key={idx+item.foreignerYn}>
                                            <li><Radio className={Qst.answerLabel} value={false} activeBackground checked={item.foreignerYn === false} onChange={(e) => ExecutiveMemberChangeNewChangeVm.setForeignerYn(e,idx)} name={`executiveMembers[${idx}].foreignerYn`}>한국인</Radio></li>
                                            <li><Radio className={Qst.answerLabel} value={true} activeBackground checked={item.foreignerYn === true} onChange={(e) => ExecutiveMemberChangeNewChangeVm.setForeignerYn(e,idx)} name={`executiveMembers[${idx}].foreignerYn`}>외국인</Radio></li>
                                        </ul>
                                        <Error name={`executiveMembers[${idx}].foreignerYn`} value={item.foreignerYn} errorCase={{required:'사람을 선택해 주세요.'}}/>
                                    </div>
                                </div>
                                {item.foreignerYn === false && //한국인
                                <>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    <span className={Qst.bold}>신규 {ExecutiveMemberChangeNewChangeVm.displayPosition(idx)}님</span>의 <span className={Qst.bold}>성함</span>을 입력해주세요.
                                                </h2>
                                            </div>
                                            <InputText placeholder="ex)홍길동" style={{width:'316px'}} value={item.koreanName} onChange={(e) => ExecutiveMemberChangeNewChangeVm.setKoreanName(e,idx)} name={`executiveMembers[${idx}].koreanName`} errorText={<Error name={`executiveMembers[${idx}].koreanName`} value={item.koreanName} errorCase={{required:'성함을 입력해 주세요.'}}/>}/><span className={Qst.nameInput}>님</span>
                                        </div>
                                    </div>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    <span className={Qst.bold}>{ExecutiveMemberChangeNewChangeVm.displayKoreanName(idx)}님</span>의 <span className={Qst.bold}>주민등록번호</span>를 입력해주세요.
                                                </h2>
                                                <div className={Qst.titleExplain}>주민등록번호는 헬프미 스마트 보안 시스템에 의하여 안전하게 보관됩니다.</div>
                                            </div>
                                            <InputText placeholder="111111-1111111" style={{width:'316px'}} textType="kssn" value={item.kssn} onChange={(e) => ExecutiveMemberChangeNewChangeVm.setKssn(e,idx)} name={`executiveMembers[${idx}].kssn`} errorText={<Error name={`executiveMembers[${idx}].kssn`} value={item.kssn} errorCase={{
                                                required: '주민등록번호를 입력해 주세요.',
                                                validate: {
                                                func: regExp.kssn(item.kssn),
                                                message: '올바른 주민등록번호를 입력해 주세요.'
                                                }
                                            }}/>} />
                                        </div>
                                    </div>
                                    {ExecutiveMemberChangeNewChangeVm.displayNewPosition && <NewPosition idx={idx} item={item} parentVm={ExecutiveMemberChangeNewChangeVm}/>}
                                    {ExecutiveMemberChangeNewChangeVm.displayAddress(idx) && <NewAddress name='executiveMembers' idx={idx} item={item} parentVm={ExecutiveMemberChangeNewChangeVm}/>}
                                </>
                                }
                                {item.foreignerYn && //외국인
                                <>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    <span className={Qst.bold}>신규 {ExecutiveMemberChangeNewChangeVm.displayPosition(idx)}님은 한국에 거소등록 또는 외국인등록을 한 외국인</span> 인가요?
                                                </h2>
                                            </div>
                                            <ul className={Qst.answerList}>
                                                <li><Radio className={Qst.answerLabel} value={true} activeBackground checked={item.residenceReportYn === true} onChange={(e) => ExecutiveMemberChangeNewChangeVm.setResidenceReportYn(e, idx)} name={`executiveMembers[${idx}].residenceReportYn`}>네</Radio></li>
                                                <li><Radio className={Qst.answerLabel} checked={false} onClick={() => ExecutiveMemberChangeNewChangeVm.openModal('ResidenceRegistration')}>아니오</Radio></li>
                                            </ul>
                                            <Error name={`executiveMembers[${idx}].residenceReportYn`} value={item.residenceReportYn} errorCase={{required:'거소등록 또는 외국인등록 여부를 선택해 주세요.'}} />
                                        </div>
                                        <div className={Qst.qnaSection}>
                                            <Qna className={Qst.noTitle}>
                                                <div className={Qst.qnaBox}>
                                                    <QText>거소등록(외국인등록)하지 않은 외국인은 임원이 되는데<br/>제약이 있나요?</QText>
                                                    <div className="answerBox">
                                                        <Answer className={Qst.answer}>한국에서 거소등록(외국인등록)하지 않은 외국인의 경우, 서비스제공이 제한됩니다.</Answer>
                                                        <p className={Qst.explain}>
                                                            거소등록(외국인등록)하지 않은 외국인을 임원으로 등록하는 경우, 서비스 제공이 어렵습니다. 거소등록(외국인등록)하지 않은 외국인이 임원으로 등록되려면, 아포스티유 인증 또는 영사관 인증이 필요하고, 절차가 상당히 복잡하기 때문에, 서비스를 제공하고 있지 않습니다. 양해 부탁드립니다.
                                                        </p>
                                                    </div>
                                                </div>
                                            </Qna>
                                        </div>
                                    </div>

                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                            <h2 className={clsx(Qst.title, Qst.inside)}>
                                                <span className={Qst.bold}>인감증명서</span> 또는 <span className={Qst.bold}>본인서명사실확인서</span>를 제출할 수 있는 외국인인가요?
                                            </h2>
                                            <div className={Qst.titleExplain}>설립의 경우, 인감증명서 준비를 추천드립니다. (본인서명사실확인서의 경우 서명할 문서가 많아서 번거로울 수 있습니다.)</div>
                                            </div>
                                            <ul className={Qst.answerList}>
                                                <li><Radio className={Qst.answerLabel} value={true} activeBackground checked={item.foreignerCertificationYn === true} onChange={(e) => ExecutiveMemberChangeNewChangeVm.setForeignerCertificationYn(e, idx)} name={`executiveMembers[${idx}].foreignerCertificationYn`}>네</Radio></li>
                                                <li><Radio className={Qst.answerLabel} checked={false} onClick={() => ExecutiveMemberChangeNewChangeVm.openModal('ForeignerCertification')}>아니오</Radio></li>
                                            </ul>
                                            <Error name={`executiveMembers[${idx}].foreignerCertificationYn`} value={item.foreignerCertificationYn} errorCase={{required:'인감증명서 또는 본인서명사실확인서 제출 여부를 선택해 주세요.'}}/>
                                        </div>
                                        <div className={Qst.qnaSection}>
                                            <Qna className={Qst.noTitle}>
                                                <div className={Qst.qnaBox}>
                                                    <QText>인감증명서 또는 본인서명사실확인서가 꼭 필요한가요?</QText>
                                                    <div className="answerBox">
                                                        <Answer className={Qst.answer}>등기시 외국인 임원의 인감증명서 또는 본인서명확인서가 꼭 필요합니다.</Answer>
                                                        <p className={Qst.explain}>
                                                            법인설립 또는 임원등기시 외국인 임원의 인감증명서 또는 본인서명사실확인서 제출이 꼭 필요하므로, 시간을 절약하기 위해 미리 준비해주세요.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className={Qst.qnaBox}>
                                                    <QText>외국인의 경우, 인감증명서 또는 본인서명사실확인서를 어떻게 발급받나요?</QText>
                                                    <div className="answerBox">
                                                        <Answer className={Qst.answer}>본인이 주소지 관할 주민센터에 서류를 가지고 방문하면 바로 발급받습니다.</Answer>
                                                        <div className={st.explainBox}>
                                                            <div className={st.title}>인감증명서의 경우</div>
                                                            <ul className={st.list}>
                                                                <li>최초발급 : 본인이 인감도장, 외국인등록증(거소사실증명서), 여권을 가지고 거주지관할 주민센터에 방문하면 됩니다.</li>
                                                                <li>추가발급 : 본인이 외국인등록증, 여권을 가지고 주민센터에 방문하면 됩니다.</li>
                                                            </ul>
                                                        </div>
                                                        <div className={st.explainBox}>
                                                            <div className={st.title}>본인서명사실확인서의 경우</div>
                                                            <ul className={st.list}>
                                                                <li>최초발급 : 본인이 외국인등록증(거소사실증명서), 여권을 가지고 주민센터에 방문하면 됩니다.</li>
                                                                <li>추가발급 : 외국인등록증(거소사실증명서), 여권을 가지고 주민센터를 방문하거나, 정부24 사이트에서 온라인 발급이 가능합니다. (행정기관 제출용도만 온라인발급 가능)</li>
                                                                <li>발급용도는 "주식회사 OOO 설립등기"등으로 구체적으로 적어야 하고, 대리인도 기재를 해야 합니다. 발급받기 전에 담당매니저에게 용도란, 대리인란에 적을 문구를 확인받고 발급해주세요.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Qna>
                                        </div>
                                    </div>

                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    <span className={Qst.bold}>신규 {ExecutiveMemberChangeNewChangeVm.displayPosition(idx)}님의 국적을 선택</span>해 주세요.
                                                </h2>
                                                <div className={Qst.titleExplain}>
                                                    외국인인 경우 국적, 외국법인인 경우 본점이 위치한 나라를 선택해 주세요.
                                                </div>
                                            </div>
                                            <Select className={Qst.select} style={{width:'142px'}} name={`executiveMembers[${idx}].nationalityType`} value={item.nationalityType ?? ''} onChange={(e) => ExecutiveMemberChangeNewChangeVm.setNationalityType(e,idx)} errorText={<Error name={`executiveMembers[${idx}].nationalityType`} value={item.nationalityType} errorCase={{required:'국적을 선택해 주세요.'}} style={{width:'157px'}}/>}>
                                                <option value="">-</option>
                                                <option value="일본">일본</option>
                                                <option value="미국">미국</option>
                                                <option value="영국">영국</option>
                                                <option value="중국">중국(본토)</option>
                                                <option value="프랑스">프랑스</option>
                                                <option value="독일">독일</option>
                                                <option value="캐나다">캐나다</option>
                                                <option value="홍콩">홍콩</option>
                                                <option value="기타">기타</option>
                                            </Select>
                                            {item.nationalityType === "기타" &&
                                                <div className={clsx(Qst.rowWrap, st.rowWrap)}>
                                                    <InputText className={Qst.inputText} style={{width:'276px'}} explain="기타 국적" name={`executiveMembers[${idx}].nationalityOthers`} value={item.nationalityOthers} onChange={(e) => ExecutiveMemberChangeNewChangeVm.setNationalityOthers(e,idx)} errorText={<Error name={`executiveMembers[${idx}].nationalityOthers`} value={item.nationalityOthers} errorCase={{required:'기타국적을 입력해 주세요.'}}/>}/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    <span className={Qst.bold}>신규 {ExecutiveMemberChangeNewChangeVm.displayPosition(idx)}님의 이름을 로마자와 한글로 입력</span>해 주세요.
                                                </h2>
                                                <div className={Qst.titleExplain}>
                                                    외국인의 정식 명칭을 로마자(영문)와 한글 발음으로 입력해 주세요.
                                                </div>
                                                <div className={Qst.titleExTextBox}>
                                                    <TextBox title="로마자(영문)" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>Jane Austen</span></TextBox>
                                                    <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>제인 오스틴</span></TextBox>
                                                </div>
                                                <div className={Qst.titleExplain}>
                                                    영문이 아닌 외국성함은 로마자(영문)으로 변환하여 한글 발음으로 입력해 주세요.
                                                </div>
                                                <div className={Qst.titleExTextBox}>
                                                    <TextBox title="로마자(영문)로 변환" titleWidth="145px" className={Qst.nameTextBox} style={{width:'171px', height:'40px', padding:'9px 20px'}}>
                                                    <span className={Qst.text}>高竹</span><Icon icon="arrow" className={Qst.arrow} /><span className={Qst.text}>Takamura</span>
                                                    </TextBox>
                                                    <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'134px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>타카무라</span></TextBox>
                                                </div>
                                            </div>
                                            <div className={Qst.rowWrap}>
                                                <InputText className={Qst.inputText} style={{width:'276px'}} explain="로마자(영문) 표기" name={`executiveMembers[${idx}].englishName`} value={item.englishName} onChange={(e) => ExecutiveMemberChangeNewChangeVm.setEnglishName(e,idx)} errorText={<Error name={`executiveMembers[${idx}].englishName`} value={item.englishName} errorCase={{
                                                    required: '이름을 로마자로 입력해 주세요.',
                                                    pattern: {
                                                    value: regExp.onlyEnglish().test(item.englishName),
                                                    message: '로마자로 입력해 주세요.'
                                                    }
                                                }}/>} 
                                                />
                                                <InputText className={Qst.inputText} style={{width:'276px'}} explain="한글 발음 표기" name={`executiveMembers[${idx}].koreanName`} value={item.koreanName} onChange={(e) => ExecutiveMemberChangeNewChangeVm.setKoreanName(e,idx)} errorText={<Error name={`executiveMembers[${idx}].koreanName`} value={item.koreanName} errorCase={{
                                                    required: '이름을 한글로 입력해 주세요.',
                                                    pattern: {
                                                    value: regExp.onlyKorean().test(item.koreanName),
                                                    message: '한글로 입력해 주세요.'
                                                    }
                                                }}/>} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    <span className={Qst.bold}>{ExecutiveMemberChangeNewChangeVm.displayKoreanName(idx)}님의 여권에 기재된 생년월일을 선택</span>해 주세요.
                                                </h2>
                                            </div>
                                            <div>
                                                <div className={Qst.birthWrap}>
                                                    <InputText style={{width:'130px'}} placeholder="0000" name={`executiveMembers[${idx}].birth.year`} value={item.birth.year} onChange={(e) => ExecutiveMemberChangeNewChangeVm.setYear(e,idx)} maxLength="4" />
                                                    <span className={Qst.birthText}>년</span>
                                                </div>
                                                <div className={Qst.birthWrap}>
                                                    <InputText style={{width:'108px'}} placeholder="00" name={`executiveMembers[${idx}].birth.month`} value={item.birth.month} onChange={(e) => ExecutiveMemberChangeNewChangeVm.setMonth(e,idx)} maxLength="2" onBlur={(e) => ExecutiveMemberChangeNewChangeVm.monthBlur(e,idx)} />
                                                    <span className={Qst.birthText}>월</span>
                                                </div>
                                                <div className={Qst.birthWrap}>
                                                    <InputText style={{width:'108px'}} placeholder="00" name={`executiveMembers[${idx}].birth.day`} value={item.birth.day} onChange={(e) => ExecutiveMemberChangeNewChangeVm.setDay(e,idx)} maxLength="2" onBlur={(e) => ExecutiveMemberChangeNewChangeVm.dayBlur(e,idx)} />
                                                    <span className={Qst.birthText}>일</span>
                                                </div>
                                            </div>
                                            <div>
                                                <Error name={`executiveMembers[${idx}].birth.year`} value={item.birth.year} style={{marginTop:'-27px'}}
                                                errorCase={{
                                                required:'연도를 입력해 주세요.',
                                                pattern: {
                                                    value: regExp.year().test(item.birth.year),
                                                    message: '올바른 연도를 입력해 주세요'               
                                                }
                                                }}
                                                />
                                                <Error name={`executiveMembers[${idx}].birth.month`} value={item.birth.month} style={{marginTop:'-27px'}}
                                                errorCase={{
                                                required: ExecutiveMemberChangeNewChangeVm.displayBirthErrorMessage(idx, 'month') ? '월을 입력해 주세요.' : null,
                                                pattern: {
                                                    value: ExecutiveMemberChangeNewChangeVm.displayBirthErrorMessage(idx, 'month') ? regExp.month().test(item.birth.month) : null,
                                                    message: '올바른 월을 입력해 주세요'               
                                                }
                                                }}
                                                />
                                                <Error name={`executiveMembers[${idx}].birth.day`} value={item.birth.day} style={{marginTop:'-27px'}}
                                                errorCase={{
                                                required: ExecutiveMemberChangeNewChangeVm.displayBirthErrorMessage(idx, 'day') ? '일(날짜)을 입력해 주세요.' : null,
                                                pattern: {
                                                    value: ExecutiveMemberChangeNewChangeVm.displayBirthErrorMessage(idx, 'day') ? regExp.day().test(item.birth.day) : null,
                                                    message: '올바른 일(날짜)을 입력해 주세요.'           
                                                }
                                                }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {ExecutiveMemberChangeNewChangeVm.displayNewPosition && <NewPosition idx={idx} item={item} parentVm={ExecutiveMemberChangeNewChangeVm}/>}
                                    {ExecutiveMemberChangeNewChangeVm.displayAddress(idx) && <NewAddress name='executiveMembers' idx={idx} item={item} parentVm={ExecutiveMemberChangeNewChangeVm}/>}
                                </>
                                }
                                {(item.foreignerYn !== null && ExecutiveMemberChangeNewChangeVm.displayFarmerFisherman(idx)) &&
                                <div className={Qst.spaceBetween}>
                                    <div className={Qst.infoSection}>
                                        <div className={Qst.titleWrap}>
                                            <h2 className={clsx(Qst.title, Qst.inside)}>
                                                <span className={Qst.bold}>{ExecutiveMemberChangeNewChangeVm.displayKoreanName(idx)}님</span>은 <span className={Qst.bold}>{ExecutiveMemberChangeNewChangeVm.displayFarmerFishermanText}인</span>인가요?
                                            </h2>
                                        </div>
                                        <ul className={Qst.answerList}>
                                            <li><Radio className={Qst.answerLabel} value={true} activeBackground checked={item.farmerFishermanYn === true} onChange={(e) => ExecutiveMemberChangeNewChangeVm.setFarmerFishermanYn(e,idx)} name={`executiveMembers[${idx}].farmerFishermanYn`}>네. {ExecutiveMemberChangeNewChangeVm.displayFarmerFishermanText}인입니다.</Radio></li>
                                            <li><Radio className={Qst.answerLabel} value={false} activeBackground checked={item.farmerFishermanYn === false} onChange={(e) => ExecutiveMemberChangeNewChangeVm.setFarmerFishermanYn(e,idx)} name={`executiveMembers[${idx}].farmerFishermanYn`}>아니오. {ExecutiveMemberChangeNewChangeVm.displayFarmerFishermanText}인이 아닙니다.</Radio></li>
                                        </ul>
                                        <Error name={`executiveMembers[${idx}].farmerFishermanYn`} value={item.farmerFishermanYn} errorCase={{required:'사람을 선택해 주세요.'}}/>
                                    </div>
                                    <div className={Qst.qnaSection}>
                                        <Qna className={Qst.noTitle}>
                                            <div className={Qst.qnaBox}>
                                                <QText>누구나 {ExecutiveMemberChangeNewChangeVm.displayFarmerFishermanText}회사법인의 임원이 될 수 있나요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>최소한 이사의 1/3이상이 {ExecutiveMemberChangeNewChangeVm.displayFarmerFishermanText}인이어야 합니다.</Answer>
                                                    <p className={Qst.explain}>
                                                        따라서 이사가 1명 뿐이라면 반드시 {ExecutiveMemberChangeNewChangeVm.displayFarmerFishermanText}인이어야 합니다. 감사는 {ExecutiveMemberChangeNewChangeVm.displayFarmerFishermanText}인일 필요가 없습니다. 대표는 {ExecutiveMemberChangeNewChangeVm.displayFarmerFishermanText}인이 아니어도 무방합니다.
                                                    </p>
                                                </div>
                                            </div>
                                        </Qna>
                                    </div>
                                </div>
                                }
                            </Drop>
                        </ContentBox>
                    </Question>
                )
            })}
            {ExecutiveMemberChangeNewChangeVm.keepProceedYn === false &&
                <Question list={true}>
                    <ContentBox>
                    <button type="button" className={Qst.stockholderAddBtn} onClick={() => ExecutiveMemberChangeNewChangeVm.addNewExecutiveMembers()}>신규 임원 추가하기<Icon icon="addGray" className={Qst.addGray}/></button>
                    </ContentBox>
                </Question>
            }  
            {/* 도로명주소 팝업 */}
            {ExecutiveMemberChangeNewChangeVm.addressModal && 
                <SearchAddress onClose={() => ExecutiveMemberChangeNewChangeVm.openAddressModal(false,null)} onComplete={(data) => ExecutiveMemberChangeNewChangeVm.addressComplate(data)} />
            }
        </form>
    ));
}
export default ExecutiveMemberChangeNewChange;