import { useObserver } from 'mobx-react';
import Ast from '../../AdminFinalCheckView.module.scss';
import copyText from '@common/module/copyText';
import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';
import Icon from '@comComponents/atoms/Icon/Icon';
import HeadOfficeRelocationStepVm from './HeadOfficeRelocationStepVm';

function HeadOfficeRelocationStep(props) {
    return useObserver(() => (
        <>
            <div className={Ast.row}> 
                <div className={Ast.dName}>이전하는 주소</div>
                <div className={Ast.data}>
                    {props?.step?.address !== null ?
                    <>
                        <CopyBtn onClick={() => copyText(props.step.address?.sido)} className={Ast.addressCopyBtn}>{props.step.address?.sido}</CopyBtn>
                        <CopyBtn onClick={() => copyText(props.step.address?.sigungu)} className={Ast.addressCopyBtn}>{props.step.address?.sigungu}</CopyBtn>
                        {props.step.address?.bname1 && <CopyBtn onClick={() => copyText(props.step.address?.bname1)} className={Ast.addressCopyBtn}>{props.step.address?.bname1}</CopyBtn>}
                        <CopyBtn onClick={() => copyText(props.step.address?.roadname)} className={Ast.addressCopyBtn}>{props.step.address?.roadname}</CopyBtn>
                        <CopyBtn onClick={() => copyText(props.step.address?.buildingNo)} className={Ast.addressCopyBtn}>{props.step.address?.buildingNo}</CopyBtn>,
                        <CopyBtn onClick={() => copyText(props.step.address?.detailAddress)} className={Ast.addressCopyBtn}>{props.step.address?.detailAddress}</CopyBtn>
                        <div className={Ast.addressCopyBracket}>
                            (<CopyBtn onClick={() => copyText(props.step.address?.bname)} className={Ast.addressCopyBtn}>{props.step.address?.bname}</CopyBtn>,
                            <CopyBtn onClick={() => copyText(props.step.address?.nameOfBuilding)} className={Ast.addressCopyBtn}>{props.step.address?.nameOfBuilding}</CopyBtn>)
                        </div>
                        <CopyBtn onClick={() => copyText(props.step.address?.fullAddress)} className={Ast.addressCopyAllBtn}><Icon icon="copy" className={Ast.copyIcon}/></CopyBtn>
                    </>
                    :
                    '-'
                    }
                </div>
            </div>
            <div className={Ast.row}> 
                <div className={Ast.dName}>이전일자</div>
                <div className={Ast.data}>
                    {props?.step?.relocationDate ?
                        <CopyBtn onClick={() => copyText(props?.step?.relocationDate)}>
                            {props?.step?.relocationDate}
                        </CopyBtn>
                        :
                        '-'
                    }
                </div>
            </div>
            <div className={Ast.row}> 
                <div className={Ast.dName}></div>
                <div className={Ast.data}>
                    <CopyBtn onClick={() => copyText(HeadOfficeRelocationStepVm.본점이전Text(props.step))}>
                        [기타사항 복사]
                    </CopyBtn>
                </div>
            </div>
        </>
    ));
}
export default HeadOfficeRelocationStep;

