import { useObserver } from 'mobx-react';
import CorpNameChange from './CorpNameChange/CorpNameChange';
import { Route } from "react-router-dom";

function CorpNameStep() {
  return useObserver(() => (
    <>
        {/* 상호 */}
        <Route path="/step/changeRegistration/CorpNameStep/CorpNameChange" component={CorpNameChange}></Route>
    </>
  ));
}

export default CorpNameStep;