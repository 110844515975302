import { useObserver } from 'mobx-react';
import CheckAsEstimate from './CheckAsEstimate/CheckAsEstimate';
import StandbySimpleNewSharesAcquirer from './StandbySimpleNewSharesAcquirer/StandbySimpleNewSharesAcquirer';
import StandbyNewSharesAcquirer from './StandbyNewSharesAcquirer/StandbyNewSharesAcquirer';
import StandbyCapitalIncrease from './StandbyCapitalIncrease/StandbyCapitalIncrease';
import StandbyMethodOfIssuingNewShares from './StandbyMethodOfIssuingNewShares/StandbyMethodOfIssuingNewShares';
import { Route } from "react-router-dom";

function StandbyPaidInCapitalIncreaseStep() {
    return useObserver(() => (
    <>
        {/* 유상증자 */}
        <Route path="/step/changeRegistration/StandbyPaidInCapitalIncreaseStep/CheckAsEstimate" component={CheckAsEstimate}></Route>
        {/* 신주발행 - 견적대로 진행 */}
        <Route path="/step/changeRegistration/StandbyPaidInCapitalIncreaseStep/StandbySimpleNewSharesAcquirer" component={StandbySimpleNewSharesAcquirer}></Route>
        {/* 신주발행 - 견적대로 진행X */}
        <Route path="/step/changeRegistration/StandbyPaidInCapitalIncreaseStep/StandbyNewSharesAcquirer" component={StandbyNewSharesAcquirer}></Route>
        {/* 주식종류, 투자금  */}
        <Route path="/step/changeRegistration/StandbyPaidInCapitalIncreaseStep/StandbyCapitalIncrease" component={StandbyCapitalIncrease}></Route>
        {/* 신주발행 방법  */}
        <Route path="/step/changeRegistration/StandbyPaidInCapitalIncreaseStep/StandbyMethodOfIssuingNewShares" component={StandbyMethodOfIssuingNewShares}></Route>
    </>
    ));
}

export default StandbyPaidInCapitalIncreaseStep;