import { useObserver } from 'mobx-react';
import st from '../../AdminFinalCheckView.module.scss';
import clsx from 'clsx';
import copyText from '@common/module/copyText';
import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';
import Icon from '@comComponents/atoms/Icon/Icon';

function TCAgreeAndDeliveryAddressStep(props) {
    return useObserver(() => (
        <div className={clsx(st.row, st.before)}> 
            <div className={st.dName}>주소</div>
            <div className={st.data}>
                {props.step.address !== null ?
                <>
                    {props.step.address.sido &&
                    <>
                        <CopyBtn onClick={() => copyText(props.step.address?.sido)} className={st.addressCopyBtn}>{props.step.address?.sido}</CopyBtn>
                        <CopyBtn onClick={() => copyText(props.step.address?.sigungu)} className={st.addressCopyBtn}>{props.step.address?.sigungu}</CopyBtn>
                        {props.step.address?.bname1 && <CopyBtn onClick={() => copyText(props.step.address?.bname1)} className={st.addressCopyBtn}>{props.step.address?.bname1}</CopyBtn>}
                        <CopyBtn onClick={() => copyText(props.step.address?.roadname)} className={st.addressCopyBtn}>{props.step.address?.roadname}</CopyBtn>
                        <CopyBtn onClick={() => copyText(props.step.address?.buildingNo)} className={st.addressCopyBtn}>{props.step.address?.buildingNo}</CopyBtn>,
                        <CopyBtn onClick={() => copyText(props.step.address?.detailAddress)} className={st.addressCopyBtn}>{props.step.address?.detailAddress}</CopyBtn>
                        <div className={st.addressCopyBracket}>
                            (<CopyBtn onClick={() => copyText(props.step.address?.bname)} className={st.addressCopyBtn}>{props.step.address?.bname}</CopyBtn>,
                            <CopyBtn onClick={() => copyText(props.step.address?.nameOfBuilding)} className={st.addressCopyBtn}>{props.step.address?.nameOfBuilding}</CopyBtn>)
                        </div>
                        <CopyBtn onClick={() => copyText(props.step.address?.fullAddress)} className={st.addressCopyAllBtn}><Icon icon="copy" className={st.copyIcon}/></CopyBtn>
                    </>
                    }
                    {!props.step.address.sido &&
                    <>
                        {props.step.address?.fullAddress}
                        <CopyBtn onClick={() => copyText(props.step.address?.fullAddress)} className={st.addressCopyAllBtn}><Icon icon="copy" className={st.copyIcon}/></CopyBtn>
                    </>
                    }
                </>
                :
                "-"
                }
            </div>
        </div>
    ));
}

export default TCAgreeAndDeliveryAddressStep;