import ParValueDivisionStepModel from "@model/changeRegistration/ParValueDivisionStepModel";
import stepService from "@service/stepService";
import formDataToJson from '@common/module/submit';
import { runInAction, observable } from 'mobx';
import { removeCommas, onlyNumber } from '@common/module/replaceNumber';

class ParValueDivisionVm {
    submit(e) {
        runInAction(() => {
            e.preventDefault();
            if(formDataToJson(e.target).error === false) {
                let param = formDataToJson(e.target).data;
                param.newParValue = removeCommas(param.newParValue);
                stepService.saveStep(param);
            }
        })
    }
    get parValueOnRegister() {
        return ParValueDivisionStepModel.parValueOnRegister;
    }
    get newParValue() {
        return ParValueDivisionStepModel.newParValue;
    }
    setNewParValue(e) {
        runInAction(() => {
            ParValueDivisionStepModel.newParValue = onlyNumber(removeCommas(e.target.value));
        })
    }
    newParValueValidation(data){

    }
}

export default new ParValueDivisionVm();