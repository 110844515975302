import { useObserver } from 'mobx-react';
import Cst from '../../../FinalCheck/FinalCheck.module.scss';
import st from './BranchOfficeChangeStep.module.scss';
import clsx from 'clsx';
import BranchOfficeChangeStepVm from './BranchOfficeChangeStepVm';
import { Fragment } from 'react';

function BranchOfficeChangeStep(props) {
    return useObserver(() => (
        <div className={clsx(Cst.row, st.branchOfficeChange)} style={{display:'block'}}>
            {props.step.branches.map((item, idx) => {
                return (
                    <div className={st.changeInfoBox} key={idx}>
                        <div className={st.changeBranchInfo}> 
                            <div className={st.dName}>변경할 지점</div>
                            <div className={st.data}>{item.branchAddress}</div>
                        </div>
                        <div className={st.changeInfo}> 
                            <div className={st.dName}>변경할 내용</div>
                            <div className={st.data}>
                                {item.newBranchName &&
                                    <div className={st.changeInfoDetail}>
                                        <div className={st.newBranch}>지점명 변경</div>
                                        <div className={st.newBranchName}>
                                            <div>지점명 : {item.newBranchName}</div>
                                            <div>지점명 변경일자 : {item.causeDate}</div>
                                        </div>
                                    </div>
                                }
                                {item.managerChangeType !== "NONE" &&
                                    <div className={st.changeInfoDetail}>
                                        <div className={st.managerChange}>{BranchOfficeChangeStepVm.managerChangeType(item.managerChangeType)}</div>
                                        <div className={st.managerChangeInfo}>
                                            <div>{item.manager.name} ({item.manager.kssnOrBirth})</div>
                                            {item.manager.koreanYn === false && <div>국적 : {item.manager.nationality}</div>}
                                            {BranchOfficeChangeStepVm.displayAddressYn(item.managerChangeType) &&
                                                <div>{item.managerChangeType === 'CHANGE_ADDRESS' ? '변경된 주소' : '주소'} : {item.manager.address}</div>
                                            }
                                            {BranchOfficeChangeStepVm.displayManagerChangeDateYn(item.managerChangeType) &&
                                                <div>지배인 변경일자 : {item.manager.causeDate}</div>
                                            }
                                            {BranchOfficeChangeStepVm.displayManagerCauseDateYn(item.managerChangeType) &&
                                                <div>전입신고 일자 : {item.manager.causeDate}</div>
                                            }
                                            {item.managerChangeType === 'CHANGE_MANAGER' &&
                                                <div>기존 지배인의 주민등록번호 : {item.manager.originName}님 ({item.manager.originKssnOrBirth})</div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    ));
}

export default BranchOfficeChangeStep;