class StockholderListVm {
    fileExtension(fileName) {
        if(fileName) {
            return fileName.split(".")[fileName.split(".").length-1];
        } else {
            return '';        
        }
    }
}

export default new StockholderListVm();