import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import btn_next_arrow_big from '../../../asset/icon/btn_next_arrow_big.svg';

const Btn = styled.button`  
    position: relative;
    padding: ${props => props.arrow ? '31px 75px 31px 33px' : '31px 33px'};
    background-color: #006ac3;
    border-radius: 160px;
    color: #fff;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.13;
    letter-spacing: -0.6px;
    ${props => props.arrow &&
    `
        &:after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 25px;
            right: 29px;
            width: 41px;
            height: 41px;
            background-image: url(${btn_next_arrow_big});
            background-repeat: no-repeat;
            background-size: cover;
        }   
    `
    }
`;

function LastStepBtn(props) {
    return (
        <Btn type={props.type} className={props.className} style={props.style} onClick={(e) => props.onClick(e)} arrow={props.arrow} disabled={props.disabled}>
            {props.children}
        </Btn>        
    );
};

LastStepBtn.propTypes = {
    /**
    * 버튼 타입
    */
    type: PropTypes.string,
    /**
    * class명
    */
    className: PropTypes.string,
    /**
    * 클릭 이벤트
    */
    onClick: PropTypes.func,
    /**
    * style 속성
    */
    style: PropTypes.object,
    /**
    * 화살표 여부
    */
    arrow: PropTypes.bool,
    /**
    * 버튼 disabled 여부
    */
    disabled: PropTypes.bool,
    /**
     * 버튼 텍스트 값
     */
    children: PropTypes.string,
};

LastStepBtn.defaultProps = {
    type : 'button',
    className : null,
    style : {},
    onClick : () => {},
    arrow : true,
    disabled: false,
    children : 'Empty',
};

export default LastStepBtn;

