import { toJS } from 'mobx';
import { Fragment } from 'react';
import { useObserver } from 'mobx-react';
import st from '../FileUploadStep.module.scss';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import Upload from '@comComponents/molecules/FileUpload/FileUpload';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';
import clsx from 'clsx';

function RegisterBusiness(props) {
    return useObserver(() => (
        <Question>
            <ContentBox className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    {props.item.details.map((detail, idx2) => (
                        <Fragment key={idx2}>
                            {detail.fileType === '사업자등록증' &&
                                <>
                                    <div className={Qst.titleWrap}>
                                        <h2 className={Qst.title}>
                                            <span className={Qst.bold}>사업자등록증</span>을 업로드해 주세요. (세금계산서 발급용)
                                        </h2>
                                    </div>
                                    <Upload className={st.fileUpload} multiple accept=".pdf, .jpg, .png"
                                    model={props.parentVm.fileGroupTypes[props.idx].details[idx2].files}
                                    uploadModel={props.parentVm.fileGroupTypes[props.idx].details[idx2].uploadFiles}
                                    deleteModel={props.parentVm.deleteIds}
                                    files={toJS(props.parentVm.files(props.idx, idx2))} uploadFiles={toJS(props.parentVm.uploadFiles(props.idx, idx2))}  
                                    >
                                        <span className={st.bold}>여기</span>를 눌러 업로드해 주세요.
                                    </Upload>
                                </> 
                            }
                        </Fragment>
                    ))}
                </div>
                <div className={Qst.qnaSection}>
                    <Qna className={Qst.noTitle}>
                        <div className={Qst.qnaBox}>
                        <QText>사업자등록증이 없는 경우 어떻게 해야 하나요?</QText>
                        <div className="answerBox">
                            <Answer className={Qst.answer}>대표님 명의로 세금계산서 또는 현금영수증 발행이 가능합니다. 파일을 업로드하지 않고 넘어가 주세요.</Answer>
                            <div className={Qst.explain}>
                                사업자등록증이 없는 경우, 오른쪽 하단에 채팅 상담하기를 눌러서 대표님 명의의 세금계산서 또는 현금영수증 발급을 신청해주세요. 사업자등록증을 업로드하지 않고 다음 화면으로 넘어가실 수 있습니다.
                            </div>
                        </div>
                        </div>
                    </Qna>
                </div>
            </ContentBox>
        </Question>
    ));
}

export default RegisterBusiness;