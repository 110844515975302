import { runInAction } from "mobx";
import smartformAppModel from '@model/smartformAppModel';
import StandbyFileUploadStepModel from '@model/changeRegistration/StandbyFileUploadStepModel';
import formDataToJson from '@common/module/submit';
import stepService from "@service/stepService";
import { findPos } from '@common/module/submit'
import { observable } from "mobx";;

class StandbyFileUploadVm {
    constructor() {
        this.state = observable({
            errorCheckYn: false
        })
    }
    uploadValidation() {
        let errorFileList = [];
        let ruleEmptyYn = false;
        let exceptionEmptyYn = false;
        StandbyFileUploadStepModel.fileGroupTypes.forEach((item) => {
            item.details.forEach((detail) => {
                if(detail.files.length === 0 && detail.uploadFiles.length === 0) {
                    if(detail.fileType === '무상증자_최종_재무제표') {
                        ruleEmptyYn = true;
                    } else if(detail.fileType === '무상증자_예외') {
                        exceptionEmptyYn = true;
                    } else {
                        errorFileList.push(detail.fileType);
                    }
                } else if(detail.files.length !== 0 && !detail.files[0]?.fileName) {
                    errorFileList.push(detail.fileType);
                }
                if(ruleEmptyYn && exceptionEmptyYn) { //무상증자_최종_재무제표, 무상증자_예외 둘 중 하나만 파일 업로드하면 됨. 둘다 파일 업로드가 안된 경우
                    errorFileList.push('무상증자_최종_재무제표');
                    errorFileList.push('무상증자_예외');
                }
            })
        })
        if(errorFileList.length !== 0) {
            return errorFileList;
        } else {
            return true;
        }
    }
    displayErrorText(fileType) {
        if(this.uploadValidation() !== true) {
            let errorList = this.uploadValidation();
            if(errorList.includes(fileType)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    async submit(e) {
        e.preventDefault();
        if(this.uploadValidation() === true) {
            let formData = new FormData();
            let index = 0;
            formData.append('smartformId', smartformAppModel.smartformId);
            formData.append('deleteIds', StandbyFileUploadStepModel.deleteIds);
            StandbyFileUploadStepModel.fileGroupTypes?.forEach((item) => {
                item.details?.forEach((detail) => {
                    if(item.fileGroupType === "사업자등록증" || item.fileGroupType === "기존_정관" || item.fileGroupType === '주주명부') {
                        if(detail.files[0].uploadFiles) {
                            formData.append(`files[${index}].fileType`, detail.fileType);
                            formData.append(`files[${index}].file`, detail.files[0].uploadFiles);
                            index++;
                        }
                    } else {
                        if(detail.uploadFiles.length !== 0) {
                            for(let i=0; i<detail.uploadFiles.length; i++) {
                                formData.append(`files[${index}].fileType`, detail.fileType);
                                formData.append(`files[${index}].file`, detail.uploadFiles[i]);
                                index++;
                            }
                        }
                    }
                    
                })
            })
            stepService.saveStep(formData);    
        } else {
            this.state.errorCheckYn = true;
            let errorList = this.uploadValidation();
            const fileCheck = setInterval(() => {
                clearInterval(fileCheck);
                let top = findPos(document.getElementById(`${errorList[0]}`)) - 185;
                window.scroll(0, top);
            }, 30)
        }
    }
    get fileGroupTypes() {
        return StandbyFileUploadStepModel.fileGroupTypes;
    }
    get deleteIds() {
        return StandbyFileUploadStepModel.deleteIds;
    }
    files(idx, idx2) {
        return StandbyFileUploadStepModel.fileGroupTypes[idx].details[idx2].files;
    }
    uploadFiles(idx, idx2) {
        return StandbyFileUploadStepModel.fileGroupTypes[idx].details[idx2].uploadFiles;
    }
    existingFile(fileGroupType) {
        let displayFileYn = false;
        if(StandbyFileUploadStepModel.fileGroupTypes.some(item => item.fileGroupType === fileGroupType)) {
            displayFileYn = true;
        }

        return displayFileYn;
    }
    existingFileIdx(fileGroupType) {
        return StandbyFileUploadStepModel.fileGroupTypes.findIndex(item => item.fileGroupType === fileGroupType);
    }
    get regularShareholderMeetingYn() {
        return StandbyFileUploadStepModel.regularShareholderMeetingYn;
    }
}
export default new StandbyFileUploadVm();