import { useObserver } from 'mobx-react';
import FreeIssueOfNewShares from './FreeIssueOfNewShares/FreeIssueOfNewShares'
import { Route } from "react-router-dom";

function FreeIssueOfNewSharesStep() {
    return useObserver(() => (
        <>
            {/* 무상증자 */}
            <Route path="/step/changeRegistration/FreeIssueOfNewSharesStep/FreeIssueOfNewShares" component={FreeIssueOfNewShares}></Route>
        </>
    ));
}

export default FreeIssueOfNewSharesStep;