import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import st from '../../../FinalCheck/FinalCheck.module.scss';
import clsx from 'clsx';

function AnnounceMethodStep(props) {
    return useObserver(() => (
        <>
            {props.step.hasHomepageYn && 
                <div className={clsx(st.row, st.before)}> 
                    <div className={st.dName}>홈페이지 주소</div>
                    <div className={st.data}>
                        {props.step.homepageUrl || "-"}
                    </div>
                </div>
            }
            <div className={clsx(st.row, st.before)}> 
                <div className={st.dName}>공고 신문사</div>
                <div className={st.data}>
                    {props?.step?.newspaperCompany || "-"}
                </div>
            </div>
        </>
    ));
}
export default AnnounceMethodStep;
