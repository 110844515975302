import { observable } from 'mobx';
import companyInfoModel from "@model/establish/companyInfoModel";

const shareholderAndExecutiveInfoModel = observable({
    parValue: "", //1주당 금액
    shareholders: [], //주주
    investments: [], //자본금
    executiveMembers: [], //임원
    capital : 0,

    setParValue(parValue) {
        this.parValue = parValue || null;
    },
    setShareholders(shareholders) {
        if(shareholders && shareholders.length !== 0){
            this.shareholders = shareholders.map((shareholders) => {
                let year = "", month = "", day = "";
                if(shareholders.birth) {
                    year = shareholders.birth.substring(0,4);
                    month = shareholders.birth.substring(5,7);
                    day = shareholders.birth.substring(8,10);
                } 
                return {
                    year: year || "",
                    month: month || "",
                    day: day || "",
                    detailAddress: shareholders.detailAddress || "",
                    englishName: shareholders.englishName || "",
                    foreignAddress: shareholders.foreignAddress || {
                        englishAddress: "",
                        koreanAddress: ""
                    },
                    foreignerCompanyRepresentatives: shareholders.foreignerCompanyRepresentatives || [{
                        id: "", 
                        englishName: "", 
                        koreanName: ""
                    }],
                    id: shareholders.id ?? "",
                    isExecutive : shareholders.isExecutive ?? false,
                    koreanAddress: shareholders.koreanAddress || {},
                    koreanCompanyRepresentatives: shareholders.koreanCompanyRepresentatives || [{
                        id: "", 
                        name: "", 
                        position: ""
                    }],
                    koreanName: shareholders.koreanName || "",
                    kssn: shareholders.kssn || "",
                    nameOfBuilding: shareholders.nameOfBuilding || "",
                    nationalityOthers: shareholders.nationalityOthers || "",
                    nationalityType: shareholders.nationalityType || "",
                    residenceRegistrationYn: shareholders.residenceRegistrationYn ?? null,
                    shareholderType: shareholders.shareholderType || ""
                }
            })
        } else {
            this.shareholders = [];
        }
    },
    setInvestments(investments, capital) {
        if(investments && investments.length !== 0) {
            this.investments = investments.map((investments) => {
                return {
                    id: investments.id || null,
                    investment: investments.investment ?? ""
                }
            })
        } else {
            this.investments = [];
        }
        this.capital = capital ?? 0
    },
    setExecutiveMembers(executiveMembers) {
        if(executiveMembers && executiveMembers.length !== 0) {
            this.executiveMembers = executiveMembers.map((executiveMembers) => {
                let year = "", month = "", day = "";
                let residenceRegistrationYn = null;
                let foreignerCertificationYn = null;
                if(executiveMembers.birth) {
                    year = executiveMembers.birth.substring(0,4);
                    month = executiveMembers.birth.substring(5,7);
                    day = executiveMembers.birth.substring(8,10);
                } 
                if(executiveMembers.koreanYn === false) {
                    residenceRegistrationYn = true;
                    foreignerCertificationYn = true;
                }
                if(executiveMembers.shareholderId === null) { //임원 새로운 분
                    executiveMembers.shareholderId = "new" ;
                }
                if(companyInfoModel.companySize === "TWO" && companyInfoModel.initiatorRole === 'REPRESENTATIVE_SHAREHOLDERS'){
                    executiveMembers.position = '대표';
                }
                return {
                    isFarmerOrFisherman: executiveMembers.isFarmerOrFisherman ?? null,
                    address: executiveMembers.address || {},
                    year: year || "",
                    month: month || "",
                    day: day || "",
                    detailAddress: executiveMembers.detailAddress || "",
                    englishName: executiveMembers.englishName || "",
                    id: executiveMembers.id ?? "",
                    koreanName: executiveMembers.koreanName || "",
                    koreanYn: executiveMembers.koreanYn ?? null,
                    kssn: executiveMembers.kssn || "",
                    nameOfBuilding: executiveMembers.nameOfBuilding || "",
                    nationalityOthers: executiveMembers.nationalityOthers || "",
                    nationalityType: executiveMembers.nationalityType || "",
                    position: executiveMembers.position || "",
                    resignationAfterEstablishmentYn: executiveMembers.resignationAfterEstablishmentYn ?? null,
                    shareholderId: executiveMembers.shareholderId || null,
                    residenceRegistrationYn: residenceRegistrationYn,
                    foreignerCertificationYn: foreignerCertificationYn,
                }
            })
        } else {
            this.executiveMembers = [];
        }
    }
});

export default shareholderAndExecutiveInfoModel;

