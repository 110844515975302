import finalSubmissionModel from "@model/establish/finalSubmissionModel";
import smartformAppModel from "@model/smartformAppModel";
import { runInAction, observable } from "mobx";
import st from './AfterFinalCheck.module.scss';
import {addCommas} from '@common/module/replaceNumber';

class viewModel {
    constructor() {
        this.state = observable({
            secretMode: false
        });
    }
    companySize() { //참여 인원
        const companySize = finalSubmissionModel.corp.companySize;
        const shareholderCnt = finalSubmissionModel.shareholder.shareholders.length; //주주 인원
        const executiveCnt = finalSubmissionModel.executive.executives.length; //임원 인원
        let shareholder_executive = 0; //주주+임원 인원
        finalSubmissionModel.executive.executives.forEach((executive, idx) => {
            if(executive.isShareholder) {
                shareholder_executive++;
            }
        })
        if(companySize === "TWO") {
            return '2명'
        }
        else if(companySize === "OTHER") {
            return (shareholderCnt + executiveCnt - shareholder_executive) + '명' 
        }
    }
    initiatorRole() { //참여자 역할
        const initiatorRole = finalSubmissionModel.corp.initiatorRole;
        if(initiatorRole === "REPRESENTATIVE_DIRECTOR") {
            return '대표(지분 100%) + 이사(지분 0%)'
        }
        else if(initiatorRole === "REPRESENTATIVE_AUDITOR") {
            return '대표(지분 100%) + 감사(지분 0%)'
        }
        else if(initiatorRole === 'REPRESENTATIVE_SHAREHOLDERS') {
            return '대표(지분 0%) + 주주(지분 100%)'
        }
        else if(initiatorRole === 'REPRESENTATIVE_STOCKED_DIRECTOR') {
            return '대표(지분 0%) + 이사(지분 100%)'
        }
        else if(initiatorRole === 'REPRESENTATIVE_STOCKED_AUDITOR') {
            return '대표(지분 0%) + 감사(지분 100%)'
        }
    }
    get koreanCorpName() { //한글 회사명
        return finalSubmissionModel.corp.koreanCorpName;
    }
    get englishCorpName() { //영문 회사명
        return finalSubmissionModel.corp.englishCorpName;
    }
    get corpAddress() { //회사 주소
        return finalSubmissionModel.corp.corpAddress;
    }
    get deliveryAddress() { //우편물 받을 주소
        const corpAddress = finalSubmissionModel.corp.corpAddress;
        const deliveryAddress = finalSubmissionModel.corp.deliveryAddress;
        if(corpAddress === deliveryAddress) {
            return '회사주소와 같음'
        } else {
            return finalSubmissionModel.corp.deliveryAddress;
        }
    }
    get parValue() { //1주당 금액
        return String(finalSubmissionModel.shareholder.parValue);
    }
    get capital() { //자본금
        return String(finalSubmissionModel.shareholder.capital);
    }
    get changeSecretMode() { //주주, 임원 개인정보 secretMode on/off
        if(this.state.secretMode) {
            this.state.secretMode = false
        }else{
            this.state.secretMode = true
        }
    }
    //주주정보
    get shareholders() {
        return finalSubmissionModel.shareholder.shareholders;
    }
    shareholderBirth(idx, secretMode) { //주주 생년월일
        let birth = finalSubmissionModel.shareholder.shareholders[idx].birth;
        if(birth) { //생년월일이 있는 경우(한국인, 외국인)
            if(birth.includes('-')) { //생년월일이 주민등록번호인 경우 ex)111111-1111111
                if(secretMode) { //시크릿 모드일 때 (주민등록번호 모두 보임)
                    return birth;
                } else { //시크릿 모드가 아닐 때 (주민등록번호 뒷자리 안보임)
                    return (birth.match(/^([0-9]{6}-)[0-9]{7}$/))[1]+'*******';
                }
            } else { //외국인 생년월일
                return birth;
            }
        } else { //생년월일이 없는 경우(한국법인, 외국법인)
            return null;
        }
    }
    investmentPercent(idx) {
       const capital = finalSubmissionModel.shareholder.capital;
       const investment = finalSubmissionModel.shareholder.shareholders[idx].investment;
       return (investment / capital) * 100 ;
    }
    stock(idx) {
        const parValue = finalSubmissionModel.shareholder.parValue;
        const investment = finalSubmissionModel.shareholder.shareholders[idx].investment;
        return (investment / parValue) ;
    }
    get executives() { //임원정보
        return finalSubmissionModel.executive.executives;
    }
    executiveBirth(idx, secretMode) { //임원 생년월일
        let birth = finalSubmissionModel.executive.executives[idx].birth;
        if(birth.includes('-')) {
            if(secretMode) { //시크릿 모드일 때 (주민등록번호 모두 보임)
                return birth;
            } else { //시크릿 모드가 아닐 때(주민등록번호 뒷자리 안보임)
                return (birth.match(/^([0-9]{6}-)[0-9]{7}$/))[1]+'*******';
            }
        } else {
            return birth;
        }
    }
    isShareholder(idx) { //주주+임원 여부
        return finalSubmissionModel.executive.executives[idx].isShareholder;
    }
    isFarmerOrFisherman(idx) {
        return finalSubmissionModel.executive.executives[idx].isFarmerOrFisherman;
    }
    positionInfoDisplay(idx) { //사내이사(대표), 이사(대표), 업무집행자(대표)일 경우, 안내 문구 추가
        let 대표Cnt = 0, 사내이사Cnt = 0, 이사Cnt = 0, 업무집행자Cnt = 0;
        finalSubmissionModel.executive.executives.forEach((executive) => {
            if(executive.position === '대표') {
                대표Cnt++;
            } else if(executive.position === '사내이사') {
                사내이사Cnt++;
            } else if(executive.position === '이사') {
                이사Cnt++;
            } else if(executive.position === '업무집행자') {
                업무집행자Cnt++;
            }
        })

        let total = 대표Cnt + 사내이사Cnt + 이사Cnt + 업무집행자Cnt;

        if(total === 1 && this.executives[idx].position === '대표') {
            if(this.smartFormType() === '유한회사') { //유한회사인 경우
                return '유한회사'
            } else if(this.smartFormType() !== '유한회사' && this.smartFormType() !== '유한책임회사') { //주식회사인 경우(농어업 포함)
                return '주식회사'
            }
        }
    }
    get purposes() { //사업목적
        return finalSubmissionModel.etc.purposes;
    }
    stockOptionYn() { //스톡옵션
        const stockOptionYn = finalSubmissionModel.etc.stockOptionYn;
        if(stockOptionYn) {
            return '스톡옵션 추가'
        } else {
            return '추가하지 않음'
        }
    }
    shareTransferRestrictionsYn() { //주식양도제한
        const shareTransferRestrictionsYn = finalSubmissionModel.etc.shareTransferRestrictionsYn;
        if(shareTransferRestrictionsYn) {
            return '주식양도제한 추가'
        } else {
            return '추가하지 않음'
        }
    }
    get announcementMethod() { //공고방법
        return finalSubmissionModel.etc.announcementMethod;
    }
    prospectiveSocialEnterpriseYn() { //예비 사회적 기업 여부
        const prospectiveSocialEnterpriseYn = finalSubmissionModel.etc.prospectiveSocialEnterpriseYn;
        if(prospectiveSocialEnterpriseYn) {
            return '예비 사회적 기업으로 설립'
        } else {
            return '추가하지 않음'
        }
    }
    signText(signValue) {
        switch(signValue) {
            case '동글':
                return '동글(●)';
            case '별':
                return '별(★)';
            case '마름모':
                return '마름모(◆)';
            case '클로버':
                return '클로버(♣)';
            case '스페이드':
                return '스페이드(♠)';
            case '하트':
                return '하트(♥)';
            case '네모':
                return '네모(■)';
            case '삼각형':
                return '삼각형(▲)';
            case '동글1':
                return '동글1(①)';
            case '동글2':
                return '동글2(②)';
            case '동글3':
                return '동글3(③)';
            case '동글4':
                return '동글4(④)';
            case '동글5':
                return '동글5(⑤)';
            case '동글6':
                return '동글6(⑥)';
            case '동글7':
                return '동글7(⑦)';
            case '동글8':
                return '동글8(⑧)';
            case '동글9':
                return '동글9(⑨)';
        }
    }
    corpSeal() { //법인인감
        let corpSealsList = finalSubmissionModel.etc.corpSeals.map((corpSeal, idx) => {
            if(idx === 0) {
                return  <div key={idx}>
                            {corpSeal.name} 대표님 : {corpSeal.type === '기본형' ? '기본형 무료제공' : '고급형 선택 (+22,000원)'} {corpSeal?.symbol && <span className={st.stampSymbol}>비표 {this.signText(corpSeal.symbol)}</span>}
                        </div>
            } 
            else {
                return  <div key={idx} className={st.stampData}>
                            {corpSeal.name} 대표님 : {corpSeal.type === '기본형' ? '기본형 선택 (+14,300원)' : '고급형 선택 (+36,300원)'} {corpSeal?.symbol && <span className={st.stampSymbol}>비표 {this.signText(corpSeal.symbol)}</span>}
                        </div>
            }
        })
        return corpSealsList;
    }
    usageSeal() { //사용인감
        const normal = finalSubmissionModel.etc.usageSeal.normalSealCount;
        const premium = finalSubmissionModel.etc.usageSeal.premiumSealCount;
        if(normal && premium) { 
            return  <div>
                        기본형 도장 {normal}개 추가, 고급형 도장 {premium}개 추가 (+{addCommas(String(normal*14300 + premium*36300))}원)
                        {finalSubmissionModel.etc.usageSeal.usageSealInfo.length !== 0 &&
                            <div>
                                <div style={{marginTop: '13px'}}>
                                    기본형 도장 비표 :
                                    {finalSubmissionModel.etc.usageSeal.usageSealInfo.map((item, idx) => {
                                        if(item.defaultYn === '기본형') {
                                            return <span className={st.stampSymbol}>{this.signText(item.symbol)}</span>
                                        }
                                    })}
                                </div>
                                <div style={{marginTop: '8px'}}>
                                    고급형 도장 비표 :
                                    {finalSubmissionModel.etc.usageSeal.usageSealInfo.map((item, idx) => {
                                        if(item.defaultYn === '고급형') {
                                            return <span className={st.stampSymbol}>{this.signText(item.symbol)}</span>
                                        }
                                    })}
                                </div>
                            </div>
                        }
                    </div>
        } 
        else if(normal !== 0 && premium === 0) {  
            return  <div>
                        기본형 도장 {normal}개 추가 (+{addCommas(String(normal*14300))}원)
                        {finalSubmissionModel.etc.usageSeal.usageSealInfo.length !== 0 &&
                            <div>
                                <div style={{marginTop: '13px'}}>
                                    기본형 도장 비표 :
                                    {finalSubmissionModel.etc.usageSeal.usageSealInfo.map((item, idx) => {
                                        if(item.defaultYn === '기본형') {
                                            return <span className={st.stampSymbol}>{this.signText(item.symbol)}</span>
                                        }
                                    })}
                                </div>
                            </div>
                        }   
                    </div> 
        }
        else if(normal === 0 && premium !== 0) {
            return  <div>
                        고급형 도장 {premium}개 추가 (+{addCommas(String(premium*36300))}원)
                        {finalSubmissionModel.etc.usageSeal.usageSealInfo.length !== 0 &&
                            <div>
                                <div style={{marginTop: '13px'}}>
                                    고급형 도장 비표 :
                                    {finalSubmissionModel.etc.usageSeal.usageSealInfo.map((item, idx) => {
                                        if(item.defaultYn === '고급형') {
                                            return <span className={st.stampSymbol}>{this.signText(item.symbol)}</span>
                                        }
                                    })}
                                </div>
                            </div>
                        }                           
                    </div> 
        }
        else if(normal === 0 || premium === 0) {
            return '추가하지 않음'
        }
    }
    canUseYn() { //공인인증서
        const canUseYn = finalSubmissionModel.etc.canUseYn;
        if(canUseYn) {
            return '모두 사용가능 (전자등기로 진행)'
        } else if(canUseYn === false){
            return '사용하지 않음 (서류등기로 진행)'
        } else {
            return canUseYn; //공인인증서 페이지가 없는 경우
        }
    }
    freeLicenseRegistrationYn() { //무료사업자등록
        const freeLicenseRegistrationYn = finalSubmissionModel.etc.freeLicenseRegistrationYn;
        if(freeLicenseRegistrationYn) {
            return '무료사업자등록 신청'
        } else {
            return '신청하지 않음'
        }
    }
    noticeOfEstablishmentYn() { //설립통지 여부
        const noticeOfEstablishmentYn = finalSubmissionModel.etc.noticeOfEstablishmentYn;
        if(noticeOfEstablishmentYn) {
            return '확인 완료'
        }
        return ''
    }
    get finalPayment() { //최종 결제금액
        return String(finalSubmissionModel.finalPayment)
    }
    get submittedAt() {
        return finalSubmissionModel.submittedAt
    }
    get isResponse() { //설립 최종 제출 데이터 조회 여부
        return finalSubmissionModel.isResponse;
    }
    submit() {
        window.close();
    }
    smartFormType() { //스마트폼 타입
        const type = smartformAppModel.smartFormType;
        switch(type) {
            case 'ESTABLISHMENT_STOCK_COMPANY':
                return '주식회사'
            case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY':
                return '농업회사법인'
            case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY':
                return '어업회사법인'
            case 'ESTABLISHMENT_LIMITED_COMPANY':
                return '유한회사'
            case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY':
                return '유한책임회사'
        }
    } 
    name() {
        const type = smartformAppModel.smartFormType;
        switch(type) {
            case 'ESTABLISHMENT_STOCK_COMPANY':
                return '주주'
            case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY':
                return '주주'
            case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY':
                return '주주'
            case 'ESTABLISHMENT_LIMITED_COMPANY':
                return '출자자'
            case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY':
                return '출자자'
        }
    }
}
export default new viewModel();