import { observable, runInAction } from 'mobx';

const corpNameStepModel = observable({
    orininalCorpName : {
        englishName : null,
        koreanName : null
    },
    changeKoreanCorpNameYn : null,
    시군구 : "",
    광역자치단체 : "",
    소재지List : {
        광역자치단체s : []
    },
    newKoreanName : "",
    corpNameDuplicationCheckType : null,
    prefixType : null,
    changeEnglishCorpNameYn : null,
    newEnglishName : "",
    englishNotation : "",
    checkPronunciation : null,
    setCorpNameStepModel(data) {
        runInAction(() => {
            this.orininalCorpName.englishName = data?.orininalCorpName?.englishName || '없음'
            this.orininalCorpName.koreanName = data?.orininalCorpName?.koreanName || '없음'
            this.changeKoreanCorpNameYn = data?.changeKoreanCorpNameYn ?? null
            this.광역자치단체 = data?.광역자치단체 || ""
            this.시군구 = data?.시군구 || ""
            this.소재지List.광역자치단체s = data?.소재지List?.광역자치단체s || []
            this.newKoreanName = data?.newKoreanName || ""
            this.corpNameDuplicationCheckType = data?.corpNameDuplicationCheckType || null
            this.prefixType = data?.prefixType || null
            this.changeEnglishCorpNameYn = data?.changeEnglishCorpNameYn ?? null
            this.newEnglishName = data?.newEnglishName || ""
            this.englishNotation = data?.englishNotation || ""
            this.checkPronunciation = data?.checkPronunciation ?? null
        })
    }
});

export default corpNameStepModel;