import adminFinalCheckViewModel from "@model/changeRegistration/adminFinalCheckViewModel";
import adminService from "@service/adminService";
import { post } from '@common/module/httpRequest';
import smartformAppModel from '@model/smartformAppModel';

class viewModel {
    loadChangeRegistrationAdminFinalSubmission() {
        adminService.loadChangeRegistrationAdminFinalSubmission();
    }
    get steps() {
        return adminFinalCheckViewModel.steps;
    }
    async changeReflaction() {
        await post(`/admin/smartforms/${smartformAppModel.smartformId}/managerSubmit`, {}, {});
    }
    copyText() {
        return '위 등기목적에 기재한 사항을 다음 등기할 사항과 같이 변경하였으므로 그 변경등기를 구함.';
    }
    get agendaTitles() {
        return adminFinalCheckViewModel.agendaTitles;
    }
    get standbyYn() {
        return adminFinalCheckViewModel.standbyYn;
    }
}
export default new viewModel();