import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import vm from './StandByStockholderMeetingParticipantVm';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './StandByStockholderMeetingParticipant.module.scss';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Check from '@comComponents/atoms/Input/Check/Check';
import { addCommas } from '@common/module/replaceNumber';
import clsx from 'clsx';

function StandByStockholderMeetingParticipant() {
    return useObserver(() => (
        <form onSubmit={(e) => vm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            <Question>
                <ContentBox>
                    <div className={Qst.spaceBetween} >
                        <div className={Qst.infoSection}>
                            <div className={Qst.titleWrap}>
                                <h2 className={Qst.title}>주주총회 인감증명서 준비방법</h2>
                            </div>
                            <div className={st.stockholderMeetingSealInfo}>
                                <img src='/images/standby_stockholderMeetingSeal_explain.png' />
                            </div>
                        </div>
                    </div>
                    <div className={Qst.spaceBetween}>
                        <div className={Qst.infoSection}>
                            <div className={Qst.titleWrap}>
                                <h2 className={Qst.title}>
                                    아래는 <span className={Qst.bold}>주주총회 참석한(참석할) 주주 명단</span>입니다.<br/>
                                    <span className={Qst.bold}>찬성여부</span>와 <span className={Qst.bold}>인감증명서 제공여부</span>를 선택해주세요.
                                </h2>
                                <div className={Qst.titleExplain}>찬성하지 않은 분은 인감증명서를 제공할 수 없습니다.</div>
                            </div>
                        </div>
                    </div>
                    <table className={st.table}>
                        <colgroup>
                            <col width="77"/>
                            <col width="108"/>
                            <col width="363"/>
                            <col width="295"/>
                            <col width="197"/>
                            <col />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    <Check className={st.check} style={{paddingLeft:'30px', fontSize:'16px', fontWeight:'500', letterSpacing: '-0.48px', whiteSpace:'pre'}} top="0" checked={vm.agreeAllChecked} onChange={(e) => vm.setAgreeAllChecked(e)}>찬성</Check>
                                </th>
                                <th>
                                    <Check className={clsx(st.check, vm.agreeAllChecked === false && st.disabled)} style={{paddingLeft:'30px', fontSize:'16px', fontWeight:'500', letterSpacing: '-0.48px', lineHeight: '1.25',  whiteSpace:'pre'}} top="0" checked={vm.sealAllChecked} onChange={(e) => vm.setSealAllChecked(e)} disabled={!vm.agreeAllChecked}>인감증명서<br/>제공</Check>
                                </th>
                                <th>주주명</th>
                                <th>주식종류</th>
                                <th>주식수</th>
                                <th>지분율</th>
                            </tr>
                        </thead>
                        {vm.participants?.map((participant, idx) => (
                            <tbody key={idx}>
                                {participant?.stocks?.map((stock, idx2) => (
                                    <tr key={idx2}>
                                        {idx2 === 0 && 
                                        <>
                                            <td rowSpan={participant.stocks.length}>
                                                <Check onlyCheckBox className={st.check} checked={participant.agreeYn} onChange={(e) => vm.setAgreeYn(e, idx)} name={`participants[${idx}].agreeYn`} value={participant.agreeYn} />
                                            </td>
                                            <td rowSpan={participant.stocks.length}>
                                                <div style={{width:'22px', margin: '0 auto'}}>
                                                    <Check onlyCheckBox className={clsx(st.check, participant.agreeYn === false && st.disabled)} checked={participant.  sealProvidingYn} onChange={(e) => vm.setSealProvidingYn(e, idx)} name={`participants[${idx}].sealProvidingYn`} value={participant.sealProvidingYn} disabled={!participant.agreeYn} disabledBackground={false} />
                                                </div>
                                            </td>
                                            <td rowSpan={participant.stocks.length}><span className={st.index}>
                                                <input type="hidden" name={`participants[${idx}].id`} value={participant.id} />
                                                {idx+1}</span>{participant.name}
                                            </td>
                                        </>
                                        }
                                        <td className={st.stock}>{stock.stockType}</td>
                                        <td className={st.amount}>{addCommas(stock.amount)}주</td>
                                        <td className={st.rate}>{vm.rate(idx, idx2)}%</td>
                                    </tr>
                                ))

                                }
                            </tbody>
                        ))}
                        <tfoot>
                            <tr>
                                <td colSpan={4}>
                                    <div className={st.checkState}>
                                        {vm.checkState === "충분" && <div className={st.enough}>충분</div>}
                                        {vm.checkState === "부족" && <div className={st.lack}>부족</div>}
                                        <div className={st.explain}>
                                            <div className={st.bold}>의결정족수 자동계산</div>
                                            <div>주주를 선택하면, 상법에 맞는지 자동으로 계산해드려요.</div>
                                        </div>
                                    </div>
                                </td>
                                <td className={st.totalAmount}>총 {addCommas(vm.participantsTotalAmount)}주</td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </ContentBox>
            </Question> 
        </form>
    ));
}
export default StandByStockholderMeetingParticipant;