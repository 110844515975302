import { useObserver } from 'mobx-react';
import ForeignerCompanyVm from './ForeignerCompanyVm';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from '../StandbySimpleNewSharesAcquirer.module.scss';
import clsx from 'clsx';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import CloseBtn from '@comComponents/atoms/Button/CloseBtn/CloseBtn';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import Select from '@comComponents/atoms/Input/Select/Select';
import Icon from '@comComponents/atoms/Icon/Icon';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import regExp from '@common/module/regExp';

function ForeignerCompany(props) {
    return useObserver(() => (
        <>
            <div className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    <div className={Qst.titleWrap}>
                        <h2 className={clsx(Qst.title, Qst.inside)}>
                            <span className={Qst.bold}>국적을 선택</span>해 주세요.
                        </h2>
                        <div className={Qst.titleExplain}>
                            외국인인 경우 국적, 외국법인인 경우 본점이 위치한 나라를 선택해 주세요.
                        </div>
                    </div>
                    <Select className={clsx(Qst.select, st.select)} style={{width:'142px'}} name={`newSharesAcquirers[${props.idx}].nationalityType`} value={props.info.nationalityType ?? ''} onChange={(e) => ForeignerCompanyVm.setNationalityType(e,props.idx)} disabled={!props.reviseInfoList[props.idx]?.nationalityType} errorText={<Error name={`newSharesAcquirers[${props.idx}].nationalityType`} value={props.info.nationalityType} errorCase={{required:'국적을 선택해 주세요.'}} style={{width:'157px'}}/>}>
                        <option value="">-</option>
                        <option value="일본">일본</option>
                        <option value="미국">미국</option>
                        <option value="영국">영국</option>
                        <option value="중국">중국(본토)</option>
                        <option value="프랑스">프랑스</option>
                        <option value="독일">독일</option>
                        <option value="캐나다">캐나다</option>
                        <option value="홍콩">홍콩</option>
                        <option value="기타">기타</option>
                    </Select>
                    {props.info.nationalityType === "기타" &&
                        <div className={clsx(Qst.rowWrap, st.rowWrap)}>
                            <InputText className={Qst.inputText} style={{width:'276px'}} explain="기타 국적" name={`newSharesAcquirers[${props.idx}].nationalityOthers`} value={props.info.nationalityOthers} onChange={(e) => ForeignerCompanyVm.setNationalityOthers(e,props.idx)} disabled={!props.reviseInfoList[props.idx]?.nationalityType} errorText={<Error name={`newSharesAcquirers[${props.idx}].nationalityOthers`} value={props.info.nationalityOthers} errorCase={{required:'기타국적을 입력해 주세요.'}}/>}/>
                        </div>
                    }
                    {!props.reviseInfoList[props.idx]?.nationalityType && 
                        <button type="button" className={st.reviseBtn} onClick={() => props.setReviseInfoList(props.idx, "nationalityType")}><span>답변 수정하기</span></button>
                    }
                </div>
            </div>
            <div className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    <div className={Qst.titleWrap}>
                        <h2 className={clsx(Qst.title, Qst.inside)}>
                            <span className={Qst.bold}>회사 이름을 로마자와 한글로 입력</span>해 주세요.
                        </h2>
                        <div className={Qst.titleExplain}>
                            외국법인의 정식 명칭을 로마자(영문)와 한글 발음으로 입력해 주세요.
                        </div>
                        <div className={Qst.titleExTextBox}>
                            <TextBox title="로마자(영문)" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>Apple</span></TextBox>
                            <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>애플</span></TextBox>
                        </div>
                        <div className={Qst.titleExplain} style={{marginTop:'8px'}}>
                            영문이 아닌 회사이름은, 로마자(영문)으로 변환하여 한글 발음으로 입력해 주세요.
                        </div>
                        <div className={Qst.titleExTextBox}>
                            <TextBox title="로마자(영문)로 변환" titleWidth="145px" className={Qst.nameTextBox} style={{width:'171px', height:'40px', padding:'9px 20px'}}>
                            <span className={Qst.text}>高竹</span><Icon icon="arrow" className={Qst.arrow} /><span className={Qst.text}>Takamura</span>
                            </TextBox>
                            <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'134px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>타카무라</span></TextBox>
                        </div>
                    </div>   
                    <div className={Qst.rowWrap}>
                        <InputText className={Qst.inputText} style={{width:'276px'}} explain="로마자(영문) 표기" name={`newSharesAcquirers[${props.idx}].englishName`} value={props.info.englishName}
                        onChange={(e) => ForeignerCompanyVm.setEnglishName(e, props.idx)} disabled={!props.reviseInfoList[props.idx]?.name} errorText={<Error name={`newSharesAcquirers[${props.idx}].englishName`} value={props.info.englishName} errorCase={{
                            required: '회사 이름을 로마자로 입력해 주세요.',
                            pattern: {
                            value: regExp.onlyEngNum().test(props.info.englishName),
                            message: '로마자로 입력해 주세요.'
                            }
                        }}/>} />
                        <InputText className={Qst.inputText} style={{width:'276px'}} explain="한글 발음 표기" name={`newSharesAcquirers[${props.idx}].koreanName`} value={props.info.koreanName} onChange={(e) => ForeignerCompanyVm.setKoreanName(e,props.idx)} disabled={!props.reviseInfoList[props.idx]?.name} errorText={<Error name={`newSharesAcquirers[${props.idx}].koreanName`} value={props.info.koreanName} errorCase={{
                            required: '회사 이름을 한글로 입력해 주세요.',
                            pattern: {
                            value: regExp.onlyKorNum().test(props.info.koreanName),
                            message: '한글로 입력해 주세요.'
                            }
                        }}/>} />
                    </div> 
                    {!props.reviseInfoList[props.idx]?.name && 
                        <button type="button" className={st.reviseBtn} onClick={() => props.setReviseInfoList(props.idx, "name")}><span>답변 수정하기</span></button>
                    }
                </div>
            </div>
            <div className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    <div className={Qst.titleWrap}>
                        <h2 className={clsx(Qst.title, Qst.inside)}>
                        <span className={Qst.bold}>{props.info.koreanName? props.info.koreanName : '회사'}의 주소를 로마자와 한글로 입력</span>해 주세요.
                        </h2>
                        <div className={Qst.titleExplain}>
                        외국 주소를 로마자(영문)와 한글 발음으로 입력해 주세요.
                        </div>
                        <div className={Qst.titleExTextBox}>
                        <TextBox title="로마자(영문)" className={Qst.foreignAddressTextBox} style={{width:'458px', height:'40px', padding:'9px 20px'}}>
                            <span className={Qst.text}>28445 tomless Ave Torrance CA United States</span>
                        </TextBox>
                        <TextBox title="한글 발음" className={Qst.foreignAddressTextBox} style={{width:'458px', height:'40px', padding:'9px 20px'}}>
                            <span className={Qst.text}>미국 캘리포니아주 토랜스시 애비뉴 28445</span>
                        </TextBox>
                        </div>
                    </div>
                    <div className={Qst.rowWrap}>
                        <InputText explain="로마자(영문) 표기" className={Qst.inputText} style={{width:'560px'}} name={`newSharesAcquirers[${props.idx}].foreignAddress`} value={props.info?.foreignAddress} onChange={(e) => ForeignerCompanyVm.setForeignAddress(e,props.idx)} disabled={!props.reviseInfoList[props.idx]?.address} errorText={<Error name={`newSharesAcquirers[${props.idx}].foreignAddress`} value={props.info?.foreignAddress} errorCase={{
                        required: '회사 주소를 로마자로 입력해 주세요.',
                        pattern: {
                            value: regExp.onlyEngAddress().test(props.info?.foreignAddress),
                            message: '로마자로 입력해 주세요.'
                        }
                        }}/>} />
                    </div>
                    <div className={Qst.rowWrap}>
                        <InputText explain="한글 발음 표기" className={Qst.inputText} style={{width:'560px'}} name={`newSharesAcquirers[${props.idx}].koreanAddress`} value={props.info?.koreanAddress} onChange={(e) => ForeignerCompanyVm.setKoreanAddress(e,props.idx)} disabled={!props.reviseInfoList[props.idx]?.address} errorText={<Error name={`newSharesAcquirers[${props.idx}].koreanAddress`} value={props.info?.koreanAddress} errorCase={{
                        required: '회사 주소를 한글로 입력해 주세요.',
                        pattern: {
                            value: regExp.onlyKorNum().test(props.info?.koreanAddress),
                            message: '한글로 입력해 주세요.'
                        }
                        }}/>} />
                    </div>
                    {!props.reviseInfoList[props.idx]?.address && 
                        <button type="button" className={st.reviseBtn} onClick={() => props.setReviseInfoList(props.idx, "address")}><span>답변 수정하기</span></button>
                    }
                </div>
            </div>
            <div className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    <div className={Qst.titleWrap}>
                        <h2 className={clsx(Qst.title, Qst.inside)}>
                        <span className={Qst.bold}>{props.info.koreanName? props.info.koreanName : '회사'}의 대표자 성함을 로마자와 한글로 입력</span>해 주세요.
                        </h2>
                        <div className={Qst.titleExplain}>
                        영문이 아닌 성함은 변환하여 로마자(영문)와 한글 발음으로 입력해 주세요.
                        </div>
                    </div>
                    {props.info.foreignerCompanyRepresentatives.map((item, idx2) => {
                        return (
                            <div className={Qst.rowWrap} key={idx2+props.info.foreignerCompanyRepresentatives}>
                                <input type="hidden" name={`newSharesAcquirers[${props.idx}].foreignerCompanyRepresentatives[${idx2}].id`} value={item?.id || ''} />
                                <InputText explain={idx2===0 ? "로마자(영문) 표기" : null} className={Qst.inputText} style={{width:'276px'}} name={`newSharesAcquirers[${props.idx}].foreignerCompanyRepresentatives[${idx2}].englishName`} value={item?.englishName} onChange={(e) => ForeignerCompanyVm.foreignerCompanyRepresentatives_englishName(e,props.idx,idx2)} disabled={!props.reviseInfoList[props.idx]?.representative} errorText={<Error name={`newSharesAcquirers[${props.idx}].foreignerCompanyRepresentatives[${idx2}].englishName`} value={item?.englishName} errorCase={{
                                required: '성함을 로마자로 입력해 주세요.',
                                pattern: {
                                    value: regExp.onlyEnglish().test(item?.englishName),
                                    message: '로마자로 입력해 주세요.'
                                }
                                }}/>} />
                                <div className={st.compoWrap}>
                                    <InputText explain={idx2===0 ? "한글 발음 표기" : null} className={clsx(Qst.inputText, st.inputText)} style={{width:'276px'}} name={`newSharesAcquirers[${props.idx}].foreignerCompanyRepresentatives[${idx2}].koreanName`} value={item?.koreanName} onChange={(e) => ForeignerCompanyVm.foreignerCompanyRepresentatives_koreanName(e,props.idx,idx2)} disabled={!props.reviseInfoList[props.idx]?.representative} errorText={<Error name={`newSharesAcquirers[${props.idx}].foreignerCompanyRepresentatives[${idx2}].koreanName`} value={item?.koreanName} errorCase={{
                                        required: '성함을 한글로 입력해 주세요.',
                                        pattern: {
                                        value: regExp.onlyKorean().test(item?.koreanName),
                                        message: '한글로 입력해 주세요.'
                                        }
                                    }}/>} />
                                    {idx2 !== 0 && 
                                        <CloseBtn className={st.closeBtn} onClick={() => ForeignerCompanyVm.setForeignerCompanyRepresentativesCloseBtn(props.idx,idx2)}/>
                                    }
                                </div>
                            </div>
                        )
                    })}
                    <div className={clsx(Qst.rowWrap, st.representativeAddRowWrap)}>
                        <button type="button" className={st.representativeAddBtn} onClick={() => ForeignerCompanyVm.foreignerCompanyRepresentatives(props.idx)} disabled={!props.reviseInfoList[props.idx]?.representative}>대표자 추가하기<Icon icon="addGray" className={st.addGray} /></button>
                    </div>
                    {!props.reviseInfoList[props.idx]?.representative && 
                        <button type="button" className={st.reviseBtn} onClick={() => props.setReviseInfoList(props.idx, "representative")}><span>답변 수정하기</span></button>
                    }
                </div>
            </div>
        </>
    ));
}
export default ForeignerCompany;