import { runInAction, toJS } from "mobx";
import portalModel from '@model/portalModel';
import RequiredModal from '@pages/ModalContent/establish/TCAgree/RequiredModal';
import UnRequiredModal from '@pages/ModalContent/establish/TCAgree/UnRequiredModal';
import termsAndConditionsAgreeModel from "@model/establish/termsAndConditionsAgreeModel";
import stepService from "@service/stepService";
import formDataToJson from '@common/module/submit';

class viewModel {
    submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            runInAction(() => {
                let param = formDataToJson(e.target).data;
                if(param?.agreeAll) {
                    delete param.agreeAll;
                }
                stepService.saveStep(param);
            })
        }
    }
    openModal(type) {
        let modal = {};
        let content = "";
        if(type === 'required') {
            modal = RequiredModal;
            content = termsAndConditionsAgreeModel.tcagreements[0].content;
        }
        else if(type === 'unRequired') {
            modal = UnRequiredModal;
            content = termsAndConditionsAgreeModel.tcagreements[1].content;
        }
        runInAction(() => {
            portalModel.modalContent = modal.modalContent(content);
            portalModel.btn = modal.btn ?? null;
            portalModel.closeBtn = modal.closeBtn ?? true;
        })
    }
    get tcagreements() {
        return termsAndConditionsAgreeModel.tcagreements;
    }
    setAgreeYn(e, idx) {
        if(termsAndConditionsAgreeModel.tcagreements[idx].agreeYn) {
            termsAndConditionsAgreeModel.tcagreements[idx].agreeYn = false;
        } else {
            termsAndConditionsAgreeModel.tcagreements[idx].agreeYn = true;
        }
    }
    get agreeAll() {
        let isCheckAll = true;
        let agreeCnt = 0;
        termsAndConditionsAgreeModel.tcagreements.forEach((tcagreements) => {
            if(tcagreements.agreeYn) {
                agreeCnt++;
            } else if(!tcagreements.agreeYn) {
                isCheckAll = false;
            }
        })
        if(agreeCnt === 0) {
            return false;
        }   
        return isCheckAll;
    }
    setAgreeAll(e) {
        const value = e.target.checked;
        runInAction(() => {
            termsAndConditionsAgreeModel.tcagreements.forEach(tcagreements => {
                tcagreements.agreeYn = value;
            })
        })
    }
}

export default new viewModel();