import TrademarkStepModel from '@model/changeRegistration/TrademarkStepModel';
import formDataToJson from '@common/module/submit';
import stepService from "@service/stepService";
import { runInAction } from 'mobx';

class viewModel {
    get agreeYn() {
        return TrademarkStepModel.agreeYn;
    }
    setAgreeYn(e) {
        runInAction(() => {
            TrademarkStepModel.agreeYn = e.target.value === "true";
        })
    }
    submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error === false) {
            let param = formDataToJson(e.target).data;
            stepService.saveStep(param);
        }
    }
}
export default new viewModel();