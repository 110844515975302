import { useObserver } from 'mobx-react';
import { Route } from "react-router-dom";
import StandByStockholderMeetingBasicInfo from './StandByStockholderMeetingBasicInfo/StandByStockholderMeetingBasicInfo';
import StandByStockholderMeetingParticipant from './StandByStockholderMeetingParticipant/StandByStockholderMeetingParticipant';


function StandByStockholderMeetingInfoStep() {
    return useObserver(() => (
    <>
        {/* 주주총회-출석주주 */}
        <Route path="/step/changeRegistration/StandByStockholderMeetingInfoStep/StandByStockholderMeetingBasicInfo" component={StandByStockholderMeetingBasicInfo}></Route>
        {/* 주주총회-찬성여부&인감증명서 */}
        <Route path="/step/changeRegistration/StandByStockholderMeetingInfoStep/StandByStockholderMeetingParticipant" component={StandByStockholderMeetingParticipant}></Route>
    </>
    ));
}

export default StandByStockholderMeetingInfoStep;