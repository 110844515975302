import { useObserver } from 'mobx-react';
import React, { useState, useEffect, useCallback } from 'react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Icon from '@comComponents/atoms/Icon/Icon';
import StepBtn from '@comComponents/atoms/Button/StepBtn/StepBtn';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import clsx from 'clsx';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './ForeignDocument.module.scss';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import ForeignDocumentVm from './ForeignDocumentVm';
import FileUpload from '@comComponents/molecules/FileUpload/FileUpload';
import { toJS } from 'mobx';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import Loading from '@comComponents/atoms/Loading/Loading';

function ForeignDocument() {
  const [isHidden, setIsHidden] = useState(false);
  const onScroll = useCallback(() => {
    if(window.scrollY > 10){ 
        setIsHidden(true);
    } else{
        setIsHidden(false);
    }
  }, []);
  useEffect(()=>{
    ForeignDocumentVm.loadForeignDocuments();
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);  
  return useObserver(() => (
    <div>
      <header className={clsx(st.questionHeader, isHidden ? st.hidden : null)}>
        <div className={st.content}>       
            <div className={st.leftBg}></div>
            <div className={st.rightBg}></div>        
            <ContentBox>
                <div className={st.titleBox}>
                    <h1 className={st.title}><Icon icon="q"/><span className={st.text}>외국인(거소등록 완료) 서류 업로드</span></h1>
                    <div className={st.btnArea}>
                        <StepBtn step="next" size={isHidden ? 'small' : 'medium'} onClick={() => ForeignDocumentVm.upload()} className={st.stepBtn} disabled={ForeignDocumentVm.state.submitBtnDisabledYn}>업로드 후 완료</StepBtn>
                    </div>
                </div>
            </ContentBox>
        </div>
      </header>
      <div className={clsx("mainContent", st.foreign)} style={{top:'177px'}}>
        <Question>
          {ForeignDocumentVm.state.submitBtnDisabledYn && <Loading />}
          <ContentBox className={Qst.spaceBetween}>
            <div className={Qst.infoSection} style={{width:'560px'}}>
              <div className={Qst.titleWrap}>
                <h2 className={Qst.title}>
                  <span className={Qst.bold}>모든 외국인 임원, 주주</span>의 <span className={Qst.bold}>국내거소신고 사실증명</span> 또는 <span className={Qst.bold}>외국인등록 사실증명서</span>를 업로드해 주세요.
                </h2>
              </div>
              <FileUpload className={st.fileUpload} multiple accept=".pdf, .jpg, .png" 
              model={ForeignDocumentVm.documentsFiles}
              uploadModel={ForeignDocumentVm.documentsUploadFiles}
              deleteModel={ForeignDocumentVm.documentsDeleteFiles}
              files={toJS(ForeignDocumentVm.documentsFiles)} uploadFiles={toJS(ForeignDocumentVm.documentsUploadFiles)}
              explainText={<span>외국인 <span className="bold">전원</span>의 서류를 업로드해 주세요.</span>}
              >
                  <span className={st.bold}>여기</span>를 눌러 업로드해 주세요.
              </FileUpload> 
            </div>
            <div className={Qst.qnaSection}>
              <Qna tip="외국인 서류 업로드" className={Qst.qnaTitle}>
                <div className={Qst.qnaBox}>
                  <QText>외국인의 서류는 어떻게 발급받나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>국내거소신고 사실증명은 온라인(정부24)에서 발급 가능합니다.</Answer>
                    <LikeBtn className={Qst.likeBtn} href="https://www.gov.kr/mw/AA020InfoCappView.do?CappBizCD=12700000091" target="_blank">국내거소신고 사실증명 발급방법 알아보기</LikeBtn>
                    <Answer className={Qst.answer}>외국인등록사실 증명서는 온라인(정부24)에서 발급 가능합니다.</Answer>
                    <LikeBtn className={Qst.likeBtn} href="https://www.gov.kr/mw/AA020InfoCappView.do?CappBizCD=12700000027" target="_blank">외국인등록사실 증명서 발급방법 알아보기</LikeBtn>
                  </div>
                </div>
              </Qna>
            </div>
          </ContentBox>
        </Question>
      </div>
    </div>
  ));
}

export default ForeignDocument;