class StandbyPaidInCapitalIncreaseStepVm {
    displayExistingShareholdersYn(existingShareholdersYn, newShareAssignmentMethod) {
        if(existingShareholdersYn) {
            if(newShareAssignmentMethod === "주주_제3자_혼합") {
                return "제3자(기존주주)";
            }
            else {
                return "기존주주"
            }
        } else {
            return "제3자";
        }
    }
    dateYn(shareholders) {
        let displayYn = false;
        shareholders.forEach((item) => {
            if(item.subscriptionDate || item.paymentDate) {
                displayYn = true;
            }
        })

        return displayYn;
    }
}
export default new StandbyPaidInCapitalIncreaseStepVm();