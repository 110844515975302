import { useObserver } from 'mobx-react';
import Cst from '../../../FinalCheck/FinalCheck.module.scss';
import clsx from 'clsx';
import { addCommas } from '@common/module/replaceNumber';

function NumOfSharesToBeIssuedStep(props) {
    return useObserver(() => (
        <>
            <div className={Cst.row}> 
                <div className={Cst.dName}>발행예정주식수</div>
                {props.step?.sharesToBeIssuedType === "HELP_ME" &&
                    <div className={Cst.data}>헬프미에서 적절한 숫자로 변경</div> 
                }
                {props.step?.sharesToBeIssuedType !== "HELP_ME" &&
                    <div className={Cst.data}>{addCommas(props.step.numOfShersToBeIssued)}주</div> 
                }
            </div>
        </>
    ));
}

export default NumOfSharesToBeIssuedStep;