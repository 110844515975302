import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import HeadOfficeAddressRelocationVm from './HeadOfficeAddressRelocationVm';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './HeadOfficeAddressRelocation.module.scss';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import SearchAddress from '@comComponents/atoms/SearchAddress/SearchAddress';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import SquareBtn from '@comComponents/atoms/Button/SquareBtn/SquareBtn';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import Icon from '@comComponents/atoms/Icon/Icon';
import clsx from 'clsx';
function HeadOfficeAddressRelocation() {
    return useObserver(() => (
        <form onSubmit={(e) => HeadOfficeAddressRelocationVm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            <Question>
                <ContentBox className={Qst.spaceBetween} style={{paddingBottom:'30px'}}>
                    <div className={Qst.infoSection}>
                        <div className={Qst.titleWrap}>
                            <h2 className={Qst.title}>
                                이전하는 <span className={Qst.bold}>본점 도로명 주소</span>를 입력해 주세요.
                            </h2>
                        </div>
                        <Badge className={st.badge} title="예시" type="example" border={false}>
                            <img className={st.badgeImg} src="/images/contract_example.png"
                            alt="임대차계약서 예시" />
                        </Badge>
                        <div className={Qst.rowWrap}>
                            <InputText className={Qst.inputText} style={{width:'380px'}} explain="도로명 주소" 
                            value={HeadOfficeAddressRelocationVm.address?.address || ''} 
                            readOnly 
                            onClick={() => HeadOfficeAddressRelocationVm.openCompanyAddressModal(true)} 
                            name="address" 
                            errorText={<Error name="address" value={HeadOfficeAddressRelocationVm.address?.address || ''} errorCase={{required:'도로명 주소를 입력해 주세요.'}}/>}/>
                            <SquareBtn onClick={() => HeadOfficeAddressRelocationVm.openCompanyAddressModal(true)}>도로명 주소 찾기</SquareBtn>
                            {HeadOfficeAddressRelocationVm.state.companyAddressModal && 
                                <SearchAddress 
                                onClose={() => HeadOfficeAddressRelocationVm.openCompanyAddressModal(false)} 
                                onComplete={(data) => HeadOfficeAddressRelocationVm.companyAddressComplate(data)}></SearchAddress>
                            }
                        </div>
                        <div className={Qst.rowWrap}>
                            <InputText className={Qst.inputText} style={{width:'273px'}} explain="상세 주소" errorText={<Error name="detailAddress" value={HeadOfficeAddressRelocationVm.detailAddress} errorCase={{
                                required:'상세 주소를 입력해 주세요.',
                                pattern : {
                                    value: regExp.onlyKorNum_hyphen_comma().test(HeadOfficeAddressRelocationVm.detailAddress),
                                    message: <span>임대차 계약서상 영문이 있다면, <br/>한글로 옮겨적어주세요.<br/>EX) B동 &gt; 비동</span>
                                }
                            }}/>} placeholder="상세주소 없을 시, [없음] 입력" value={HeadOfficeAddressRelocationVm.detailAddress} onChange={HeadOfficeAddressRelocationVm.setDetailAddress} name="detailAddress"/>
                            <div className={Qst.symbol}>
                                <span className={Qst.symbolIcon}>&#40;&nbsp;</span><span className={clsx(Qst.symbolText, HeadOfficeAddressRelocationVm.bnameFontSizeChange ? Qst.fontSize : null)}>{HeadOfficeAddressRelocationVm.address?.bname || '법정동'},&nbsp;</span>
                                <InputText style={{width:'200px'}} explain="건물명" placeholder="없을 시, [없음] 입력" value={HeadOfficeAddressRelocationVm.nameOfBuilding} onChange={HeadOfficeAddressRelocationVm.setNameOfBuilding} name="nameOfBuilding" errorText={<Error name="nameOfBuilding" value={HeadOfficeAddressRelocationVm.nameOfBuilding} style={{width:'260px'}} errorCase={{
                                    required:'건물명을 입력해 주세요.',
                                    pattern : {
                                        value: regExp.onlyKorNum_hyphen_comma().test(HeadOfficeAddressRelocationVm.nameOfBuilding),
                                        message: <span>건물명이 영문인 경우, 한글<br/>발음으로 옮겨적어 주세요. <br/>EX) AB타워 &gt; 에이비 타워</span>
                                    }
                                }}/>}/>
                                <span className={Qst.symbolIcon}>&nbsp;&#41;</span>
                            </div>
                        </div>
                    </div>
                    <div className={Qst.qnaSection}>
                        <Qna tip="주소 설정방법" className={Qst.qnaTitle}>
                            <div className={Qst.qnaBox}>
                                <QText>지번주소를 어떻게 도로명 주소로 바꾸나요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>도로명주소 안내시스템 사이트에서 바꿀 수 있습니다.</Answer>
                                    <LikeBtn className={Qst.likeBtn} href="https://www.juso.go.kr/openIndexPage.do" target="_blank">도로명주소 안내시스템 사이트 바로가기</LikeBtn>
                                </div>
                            </div>
                            <div className={Qst.qnaBox}>
                                <QText>주소에는 영어를 쓸 수 있나요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>등기소에서는 주소를 적을 때, 한글 숫자만 허용합니다. (영어 X)</Answer>
                                    <p className={Qst.explain}>
                                        임대차계약서상 영어가 들어가 있다면, 한글로 변환해 주세요.
                                    </p>
                                    <TextBox className={Qst.textBox} title="예시">
                                        <div className={Qst.textWrap}>
                                            <span className={Qst.false}>B동, 202-1001</span><Icon icon="arrow" className={Qst.iconArrow}/><span className={Qst.true}>비동, 202동 1001호</span>
                                        </div>
                                    </TextBox>
                                </div>
                            </div>
                            <div className={Qst.qnaBox}>
                                <QText>임대차계약서를 사전에 준비해야 할까요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>임대차계약서를 미리 준비하는 것을 추천드립니다.</Answer>
                                    <p className={Qst.explain}>
                                        본점이전등기시에는 임대차계약서를 제출하지 않아도 되지만, 이전등기 후 사업자등록을 변경할 때 세무서에 제출해야 합니다. 이 때 임대차계약서의 주소와 등기부 주소가 일치하지 않으면 사업자등록이 거부됩니다.
                                    </p>
                                </div>
                            </div>
                            {HeadOfficeAddressRelocationVm.qnaContent.officeYn &&
                            <div className={Qst.qnaBox}>
                                <QText>공유오피스 주소로 본점주소를 이전할 수 있나요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>원칙적으로 공유오피스로 본점주소를 이전할 수 있지만, 제한이 있습니다.</Answer>
                                    <p className={Qst.explain}>
                                        세무서는 일부 업종의 경우, 공유오피스에서 영업할 수 없다고 판단해서 사업자등록을 허용하지 않습니다. (가정집보다는 사업자등록이 되는 경우가 더 많습니다.) 그 판단은 세무서마다 다릅니다. 따라서 관할 세무서에 미리 문의한 후, 진행하는 것을 추천드립니다. 이때 계약기간은 최소 3개월로 해 주세요. 계약 기간이 1개월이면 세무서에서 기간이 짧다는 이유로 3개월 혹은 6개월 이상의 계약서를 다시 제출하라고 요구하는 경우가 있습니다.
                                    </p>
                                </div>
                            </div>
                            }
                            <div className={Qst.qnaBox}>
                                <QText>가정집 주소로 본점주소 이전이 가능한가요?</QText>
                                {HeadOfficeAddressRelocationVm.qnaContent.houseEstablish &&
                                    <div className="answerBox">
                                        <Answer className={Qst.answer}>원칙적으로 가정집으로 본점주소 이전이 가능하지만, 제한이 있습니다.</Answer>
                                        <p className={Qst.explain}>
                                            세무서는 일부 업종의 경우, 주거용 건물에서 영업할 수 없다고 판단해서 사업자등록을 허용하지 않습니다. 가정집에서 사업자등록이 어려운 업종의 경우, 다른 주소를 선택해 주세요.
                                        </p>
                                        <dl className={Qst.defList}>
                                            <dt className={Qst.term}>가정집에 본점주소를 두는 것이 어려운 업종</dt>
                                            <dd className={Qst.def}>
                                                도매업, 소매업, 판매업, 음식점업, 공장업, 제조업, 창고업, 건설업 등
                                            </dd>
                                            <dt className={Qst.term} style={{marginTop:'10px'}}>가정집에 본점주소를 두는 것이 가능한 업종</dt>
                                            <dd className={Qst.def}>
                                                통신판매업, 소프트웨어 제작업, 컨설팅업 등
                                            </dd>
                                        </dl>
                                    </div>
                                }
                                {(!HeadOfficeAddressRelocationVm.qnaContent.houseEstablish) &&
                                    <div className="answerBox">
                                    <Answer className={Qst.answer}>{HeadOfficeAddressRelocationVm.qnaContent.smartFormTypeText}은 가정집에서 설립할 수 없습니다.</Answer>
                                    <p className={Qst.explain}>
                                        일반 주식회사는 업종에 따라 가정집에서 설립할 수 있는 경우도 있지만, 세무서에서는 {HeadOfficeAddressRelocationVm.qnaContent.smartFormTypeText}은 주거용 건물에서 영업할 수 없다고 판단해서 사업자등록을 허용하지 않습니다.
                                    </p>
                                </div>
                                }
                            </div>
                            {HeadOfficeAddressRelocationVm.qnaContent.houseRental &&
                            <div className={Qst.qnaBox}>
                                <QText>전세집 주소로 본점주소 이전이 가능한가요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>원칙적으로 전세집으로 본점주소를 이전할 수 있지만, 제한이 있습니다.</Answer>
                                    <p className={Qst.explain}>
                                        가정집 으로 본점주소 이전이 가능한 업종이어야 할 뿐만 아니라, 집주인으로부터 전대차동의서를 미리 받아두어야 합니다. 나중에 본점주소 이전 후 집주인이 전대차동의서를 써주지 않는다면, 사업자등록을 할 수 없습니다. 이 경우 비용을 들여 주소이전을 해야 하므로, 꼭 주의해 주세요. 전대차동의서 양식이 필요하다면 채팅상담으로 요청해 주세요.
                                    </p>
                                </div>
                            </div>
                            }
                            <div className={Qst.qnaBox}>
                                <QText>임대차와 전대차 무엇이 다른가요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer} iconText="A1">임대차는 회사가 건물주에게 직접 장소를 임차한 경우입니다.</Answer>
                                    <Answer className={Qst.answer} iconText="A2">전대차는 회사가 건물주가 아닌 기존 임차인으로부터 다시 임차한 경우입니다.</Answer>
                                    <p className={Qst.explain}>
                                        임대인과 신규 회사가 계약 당사자가 되어 전대차계약서를 작성합니다. 이 경우 반드시 건물주의 전대차 동의서를 첨부해야 합니다. 나중에 건물주가 전대차 동의서를 써주지 않는다면 사업자등록을 할 수 없습니다. 이 경우 비용을 들여 주소이전을 해야 하므로, 꼭 주의해 주세요.
                                    </p>
                                </div>
                            </div>
                        </Qna>
                    </div>
                </ContentBox>
            </Question>
            <Question>
                <ContentBox className={Qst.spaceBetween} style={{paddingBottom:'30px'}}>
                    <div className={Qst.infoSection}>
                        <div className={Qst.titleWrap}>
                            <h2 className={Qst.title}>
                                본점 주소 <span className={Qst.bold}>이전 일자</span>를 입력해 주세요.
                            </h2>
                        </div>
                        <Badge className={Qst.registrationBadge} title="예시" type="example" border={false}>
                            <div className={Qst.registartionBadgeBox}>
                                <img className={Qst.registrationBadgeImg} src="/images/registration_date.png" alt="본점 주소 이전 일자 예시" />
                                <div className={Qst.registrationBadgeTextBox}>
                                    <div className={Qst.registrationBadgeText}>
                                        <div className={Qst.text}>
                                            <span className={Qst.boldBlue}>안정적으로 등기신청하기 위해 이전 날짜는 오늘(질문지 제출일)을 기준으로,<br/>1주 전 ~ 3주 이내로 입력해 주세요.</span>
                                        </div>
                                        <div className={Qst.text}>
                                            <span className={Qst.bold}>예시) 오늘(질문 제출일)이 3월 23일</span>이라면, <span className={Qst.bold}>안정적으로 등기신청 가능한 주소 이전 날짜는 3월 16일 ~ 4월 12일</span> 입니다.
                                        </div>
                                    </div>
                                    <div className={Qst.registrationBadgeTextExplain}>
                                        특별한 사정이 없다면, <span className={Qst.bold}>오늘 날짜(질문 제출일)로 입력하는 것을 추천</span>드립니다.<br/>
                                        입력한 주소 이전 날짜로 부터 2주 이내 접수해야 과태료가 부가되지 않습니다.
                                    </div>
                                </div>     
                            </div>                 
                        </Badge>
                        <div>
                            <div className={Qst.birthWrap}>
                                <InputText style={{width:'130px'}} placeholder="0000" name="causeDateYear" value={HeadOfficeAddressRelocationVm.causeDateYear} onChange={(e) => HeadOfficeAddressRelocationVm.setCauseDateYear(e)} maxLength="4" />
                                <span className={Qst.birthText}>년</span>
                            </div>
                            <div className={Qst.birthWrap}>
                                <InputText style={{width:'108px'}} placeholder="00" name="causeDateMonth" value={HeadOfficeAddressRelocationVm.causeDateMonth} onChange={(e) => HeadOfficeAddressRelocationVm.setCauseDateMonth(e)} maxLength="2" onBlur={(e) => HeadOfficeAddressRelocationVm.dateBlur(e, 'month')} />
                                <span className={Qst.birthText}>월</span>
                            </div>
                            <div className={Qst.birthWrap}>
                                <InputText style={{width:'108px'}} placeholder="00" name="causeDateDay" value={HeadOfficeAddressRelocationVm.causeDateDay} onChange={(e) => HeadOfficeAddressRelocationVm.setCauseDateDay(e)} maxLength="2" onBlur={(e) => HeadOfficeAddressRelocationVm.dateBlur(e, 'day')} />
                                <span className={Qst.birthText}>일</span>
                            </div>
                        </div>
                        <div style={{marginTop:'-27px'}}>
                            <Error name="causeDateYear" value={HeadOfficeAddressRelocationVm.causeDateYear} 
                            errorCase={{
                                required:'연도를 입력해 주세요.',
                                pattern: {
                                value: regExp.year().test(HeadOfficeAddressRelocationVm.causeDateYear),
                                message: '올바른 연도를 입력해 주세요'               
                                }
                            }}
                            />
                            <Error name="causeDateMonth" value={HeadOfficeAddressRelocationVm.causeDateMonth} 
                            errorCase={{
                                required: HeadOfficeAddressRelocationVm.displayBirthErrorMessage('month') ? '월을 입력해 주세요.' : null,
                                pattern: {
                                value: HeadOfficeAddressRelocationVm.displayBirthErrorMessage('month') ? regExp.month().test(HeadOfficeAddressRelocationVm.causeDateMonth) : null,
                                message: '올바른 월을 입력해 주세요'               
                                }
                            }}
                            />
                            <Error name="causeDateDay" value={HeadOfficeAddressRelocationVm.causeDateDay} 
                            errorCase={{
                                required: HeadOfficeAddressRelocationVm.displayBirthErrorMessage('day') ? '일(날짜)을 입력해 주세요.' : null,
                                pattern: {
                                value: HeadOfficeAddressRelocationVm.displayBirthErrorMessage('day') ? regExp.day().test(HeadOfficeAddressRelocationVm.causeDateDay) : null,
                                message: '올바른 일(날짜)을 입력해 주세요.'           
                                }
                            }}
                            />
                        </div>
                    </div>
                    <div className={Qst.qnaSection}>
                        <Qna className={Qst.qnaTitle}>
                            <div className={Qst.qnaBox}>
                                <QText>주소 이전 일자는 임(전)대차 계약서상 일자랑 같아야<br/> 하나요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>주소 이전 일자는 임(전)대차 계약서상 일자와 1~2달 차이가 나도<br/>괜찮습니다.</Answer>
                                    <p className={Qst.explain}>
                                        미리 임차를 한 후, 나중에 이사를 하는 경우도 충분히 존재할 수 있기 때문에, 주소 이전 일자는 임(전)대차 계약서상 일자와 1~2달 차이가 나도 괜챃습니다. 단 임(전)대차 계약서의 일자가 등기부에 꼭 기재되어야 하는 사정이 있는 경우, 임(전)대차 계약서의 일자를 입력해주세요.
                                    </p>
                                </div>
                            </div>
                        </Qna>
                    </div>
                </ContentBox>
            </Question>
        </form>
    ));
}
export default HeadOfficeAddressRelocation;