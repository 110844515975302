import st from './ExecutiveMemberChangeStep.module.scss';
import adminFinalCheckViewModel from '@model/changeRegistration/adminFinalCheckViewModel';

class ExecutiveMemberChangeStepVm {
    setClassNameAndTitle(changeType) {
        let _title = ''
        let _className = null;
        switch(changeType) {
            case '임기연장':
                _title = '임기연장';
                _className = st.blue;                                       
                break;
            case '보직변경':
                _title = '보직변경';
                _className = st.blue; 
                break;
            case '집주소변경':
                _title = '집주소변경';
                _className = st.blue; 
                break;
            case '사임_퇴임':
                _title = '사임/퇴임';
                _className = st.red; 
                break;
            case '신규':
                _title = '신규';
                _className = st.green; 
                break;
            default:
                _title = '변경없음';
                _className = st.gray; 
                break; 
        }
        return {
            className: _className,
            title: _title
        }
    }
    displayAddress(data) {
        const changeTypeList = data.changeTypes;
        const representativeYn = data.representativeYn;
        const addressSameYn = data.addressSameYn;
        if(representativeYn) {
            if(changeTypeList.includes('보직변경')) {
                if(data.newAddress) {
                    return `주소 : ${data.newAddress}`
                }
                if(addressSameYn) {
                    return
                }
            }
            if(addressSameYn) {
                return '주소 : 변경없음'
            } else {
                return `주소 : 변경있음 [${data.newAddress}]`
            }
        }
    }
    displayNewRepresentative(data) {
        let newCnt = 0;
        data.executiveMembers.forEach((item) => {
            if(item.newYn) {
                newCnt++;
            }
        })
        if(newCnt > 0) {
            return true;
        } else {
            return false;
        }
    }
    get standbyYn() {
        return adminFinalCheckViewModel.standbyYn;
    }
}

export default new ExecutiveMemberChangeStepVm();