import { useObserver } from 'mobx-react';
import PasswordVm from './PasswordVm';
import { useCallback, useEffect, useState } from 'react';
import st from './Password.module.scss';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import Loading from '@comComponents/atoms/Loading/Loading';
import clsx from 'clsx';

function Password() {
    useEffect(() => {
        PasswordVm.canSubmit()
    })
    return useObserver(() => (
        <div className={st.passwordWrap}>
            {/* <NotiPopup /> */}
            {PasswordVm.sendEmailLoading && <Loading/>}
            <div className={st.content}>
                <div className={st.Advertisement}>
                    <a href='https://reg.help-me.kr' target="_blank" className={st.logo}><img src='/images/fullTextLogo.svg' alt='헬프미로고' title='헬프미로고'/></a>
                    <div className={st.text}>
                        <span className={st.thin}>쉽고 빠른</span><br/>법인 등기 서비스
                    </div>
                </div>
                <div className={clsx(st.password, PasswordVm.hasPassword ? st.hasPassword : '')}>
                    <div className={st.content}>
                        <div className={st.logo}><img src='/images/simbolLogo.svg' alt='헬프미로고' title='헬프미로고'/></div>
                        <h1 className={st.title}>{PasswordVm.hasPassword ? '등기정보입력을 위한 비밀번호 입력' : '등기정보입력을 위한 새로운 비밀번호 설정'}</h1>
                        <h2 className={st.subTitle}>{PasswordVm.hasPassword ? <>등기 정보 입력을 시작할 때, 설정한 비밀번호를 입력해<br/>주세요. 홈페이지 비밀번호와 다를 수 있습니다.</> : '개인 정보 보호를 위하여 비밀번호를 설정해주세요.'}</h2>
                        <ul className={st.hintList}>
                            <li>홈페이지와 다른 비밀번호를 입력하여도 괜찮습니다.</li>
                            <li>영문, 숫자, 특수문자를 함께 사용하고 8자 이상 16자 이하로 입력해주세요.</li>
                        </ul>
                        {PasswordVm.hasPassword === false && 
                            <form onSubmit={(e) => PasswordVm.submit(e)}>
                                <div className={st.row}>
                                    <InputText maxLength="16" errorFixed={true} wrapStyle={{width:"100%"}} type="password" style={{width:"100%"}} explainReauired={false} explain="비밀번호" name="newPassword" value={PasswordVm.newPassword} onChange={PasswordVm.setNewPassword} errorText={<Error name={`newPassword`} value={PasswordVm.newPassword} errorCase={{
                                        required: '비밀번호를 입력해 주세요.',
                                        pattern: {
                                            value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?])[A-Za-z\d`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?]{8,16}$/.test(PasswordVm.newPassword),
                                            message: '영문, 숫자, 특수문자를 함께 사용하고 8자 이상 16자 이하로 입력해주세요.'
                                        }
                                    }}/>}/>
                                </div>
                                <div className={st.row}>
                                    <InputText maxLength="16" errorFixed={true} wrapStyle={{width:"100%"}} type="password" style={{width:"100%"}} explainReauired={false} explain="비밀번호 재입력" name="passwordCheck" value={PasswordVm.passwordCheck} onChange={PasswordVm.setPasswordCheck} errorText={<Error name={`passwordCheck`} value={PasswordVm.passwordCheck} errorCase={{
                                        required: '비밀번호 확인을 입력해 주세요.',
                                        validate: {
                                            func: PasswordVm.isSame(),
                                            message: '동일한 비밀번호를 입력해주세요.'
                                        }
                                    }}/>}/>
                                </div>
                                <button className={st.submit} disabled={!PasswordVm.state.canSubmit}><span>등기 정보 입력 시작하기</span></button>
                            </form>
                        }
                        {PasswordVm.hasPassword === true && 
                            <form  onSubmit={(e) => PasswordVm.submit(e)}>
                                <div className={st.row}>
                                    <button type="button" className={st.forgetPassword} onClick={() => PasswordVm.forgetPassWordPopUp()}>※ 비밀번호를 잊으셨나요?</button>
                                    <InputText maxLength="16" errorFixed={true} wrapStyle={{width:"100%"}} type="password" style={{width:"100%"}} explainReauired={false} explain="비밀번호" name="password" value={PasswordVm.password} onChange={PasswordVm.setPassword} errorText={<Error name={`password`} value={PasswordVm.password} errorCase={{
                                        required: '비밀번호를 입력해 주세요.'
                                    }}/>}/>
                                </div>
                                <button className={st.submit} disabled={!PasswordVm.state.canSubmit}><span>등기 정보 입력 시작하기</span></button>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    ));
}
export default Password;