import { observable, runInAction } from 'mobx';

const ExecutiveMemberChangeStepModel = observable({
    originExecutiveMembers: [], 
    changeExecutiveMembers: [],
    newExecutiveMembers: [],
    keepProceedYn: null,
    noChangeYn: null,
    standbyFormYn: null,
    setExecutiveMemberChangeCheck(data) {
        runInAction(() => {
            if(data.executiveMembers && data.executiveMembers.length) {
                this.originExecutiveMembers = data.executiveMembers.map((executiveMember) => {
                    return {
                        koreanName: executiveMember.koreanName || "",
                        englishName: executiveMember.englishName || "",
                        changeTypes: executiveMember.changeTypes || [],
                        originPosition: executiveMember.originPosition || "",
                        newPosition: executiveMember.newPosition || "",
                        newPositionTag: executiveMember.newPositionTag || "",
                        originPositionTag: executiveMember.originPositionTag || "",
                        originPositionOnRegister: executiveMember.originPositionOnRegister || "",
                        newPositionOnRegister: executiveMember.newPositionOnRegister || "",
                        requiresDescriptionYn: executiveMember.requiresDescriptionYn ?? null,
                        descriptionType: executiveMember.descriptionType || ""
                    }
                })
            } else {
                this.originExecutiveMembers = [];
            }
            this.keepProceedYn = data.keepProceedYn ?? null;
            this.standbyFormYn = data.standbyFormYn ?? false;
        })
    },
    setExecutiveMemberChangeOriginChange(data) {
        runInAction(() => {
            if(data.executiveMembers && data.executiveMembers.length) {
                this.changeExecutiveMembers = data.executiveMembers.map((executiveMember) => {
                    return {
                        id: executiveMember.id || "",
                        checkedYn: executiveMember.checkedYn ?? false,
                        koreanName: executiveMember.koreanName || "",
                        englishName: executiveMember.englishName || "",
                        changeType: executiveMember.changeType || "",
                        foreignerYn: executiveMember.foreignerYn ?? null,
                        originPosition: executiveMember.originPosition || "",
                        newPosition: executiveMember.newPosition || "",
                        kssn: executiveMember.kssn || "",
                        originAddress: executiveMember.originAddress || "",
                        addressSameYn: executiveMember.addressSameYn ?? null,
                        newAddresses: executiveMember.newAddresses || [{
                            id: "",
                            causeDate: {
                                year: "",
                                month: "",
                                day: ""
                            },
                            detailAddress: "",
                            nameOfBuilding: "",
                            newAddress: {}
                        }],
                        originCauseDate: executiveMember.originCauseDate || "",
                        originCauseDateByEstablishmentYn: executiveMember.originCauseDateByEstablishmentYn || ""
                    }
                })
            } else {
                this.changeExecutiveMembers = [];
            }
            this.keepProceedYn = data.keepProceedYn ?? null;
            this.noChangeYn = data.noChangeYn ?? false;
        })
    },
    setExecutiveMemberChangeNewChange(data) {
        runInAction(() => {
            if(data.executiveMembers && data.executiveMembers.length) {
                this.newExecutiveMembers = data.executiveMembers.map((executiveMember) => {
                    return {
                        id: executiveMember.id || "",
                        koreanName: executiveMember.koreanName || "",
                        englishName: executiveMember.englishName || "",
                        newPosition: executiveMember.newPosition || "",
                        foreignerYn: executiveMember.foreignerYn ?? null,
                        kssn: executiveMember.kssn || "",
                        residenceReportYn: executiveMember.residenceReportYn ?? null,
                        foreignerCertificationYn: executiveMember.foreignerCertificationYn ?? null,
                        nationalityType: executiveMember.nationalityType || "",
                        nationalityOthers : executiveMember.nationalityOthers || "",
                        birth: executiveMember.birth || {
                            day: "",
                            month: "",
                            year: ""
                        },
                        newAddress: executiveMember.newAddress || {},
                        nameOfBuilding: executiveMember.nameOfBuilding || "",
                        detailAddress: executiveMember.detailAddress || "",
                        farmerFishermanYn: executiveMember.farmerFishermanYn ?? null,
                        newPositionTag: executiveMember.newPositionTag || ""
                    }
                })
            } else {
                this.newExecutiveMembers = [];
            }
            this.keepProceedYn = data.keepProceedYn ?? null;
        })
    }
});

export default ExecutiveMemberChangeStepModel;
