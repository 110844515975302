import { useObserver } from 'mobx-react';
import st from './Case1Modal.module.scss';
import vm from './StockholdersMeetingMusterNoticeVm';

function Case1Modal(props) {
    return useObserver(() => (
        <div className={st.modalContent}>
            <div className={st.title}>아직 동의 안 받았다면, <span className={st.bold}>사후 동의 꼭 받으셔야</span> 합니다!</div>
            <div className={st.steps}>
                <div className={st.stepWrap}>
                    <div className={st.step}>
                        <div className={st.stepContent}>
                            소집통지는 <span className={st.bold}>우편발송</span>이<br/>
                            <span className={st.bold}>원칙</span>입니다.
                        </div>
                    </div>
                </div>
                <div className={st.stepWrap}>
                    <div className={st.step}>
                        <div className={st.stepContent}>
                            주주 <span className={st.bold}>동의를 받아야</span><br/>
                            <span className={st.bold}>온라인 발송</span>이 <span className={st.bold}>가능</span>합니다.
                        </div>
                    </div>
                </div>
                <div className={st.stepWrap}>
                    <div className={st.step}>
                        <div className={st.stepContent}>
                            <span className={st.bold}>사후동의도 인정</span>되므로,<br/>
                            지금 동의를 받아두세요.
                        </div>
                    </div>
                </div>
            </div>
            <div className={st.explain}>※ 동의없이 온라인 발송하면 통지의 효과가 없으니 주의하세요.</div>
            <div className={st.btnArea}>
                <button type="button" className={st.directBtn} onClick={() => vm.direct(props.formData)}>
                    <span className={st.text}>직접 주주들에게<br/>동의를 받겠습니다.</span>
                </button>
                <button type="button" className={st.serviceBtn} onClick={() => vm.service(props.formData)}>
                    <span className={st.text}><span className={st.bold}>온라인</span>으로 간편하게 <span className={st.bold}>사후동의</span>를 받겠습니다.</span>
                    <span className={st.tag}>추천</span>
                    <span className={st.btnExplain}>※ 서비스 이용시 별도 회원가입이 필요해요.</span>
                </button>
            </div>
        </div>
    ))
}

export default Case1Modal;

