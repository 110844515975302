import { useObserver } from 'mobx-react';
import CorpNameStepVm from './CorpNameStepVm';
import Ast from '../../AdminFinalCheckView.module.scss';
import st from './CorpNameStep.module.scss';
import copyText from '@common/module/copyText';
import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';

function CorpNameStep(props) {
    return useObserver(() => (
        <>
            <div className={Ast.row}> 
                <div className={Ast.dName}>한글상호</div>
                <div className={Ast.data}>
                    {props.step.changeKoreanCorpNameYn ? 
                        <CopyBtn onClick={() => copyText(CorpNameStepVm.koreanName(props.step))}>
                            <span>{CorpNameStepVm.koreanName(props.step)}</span>
                        </CopyBtn>
                        :
                        <span className={st.noChange}>{CorpNameStepVm.koreanName(props.step)}</span>
                    }
                </div>
            </div>
            <div className={Ast.row}> 
                <div className={Ast.dName}>영문상호</div>
                <div className={Ast.data}>
                    {props.step.changeEnglishCorpNameYn ?
                        <CopyBtn onClick={() => copyText(CorpNameStepVm.englishName(props.step))}>
                            <span className={props.step.changeEnglishCorpNameYn ? null : st.noChange}>{CorpNameStepVm.englishName(props.step)}</span>
                        </CopyBtn>
                        :
                        <span className={props.step.changeEnglishCorpNameYn ? null : st.noChange}>{CorpNameStepVm.englishName(props.step)}</span>
                    }
                </div>
            </div>
        </>     
    ));
}

export default CorpNameStep;