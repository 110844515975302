import { useObserver } from 'mobx-react';
import Ast from '../../AdminFinalCheckView.module.scss';
import { addCommas } from '@common/module/replaceNumber';
import copyText from '@common/module/copyText';
import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';

function NumOfSharesToBeIssuedStep(props) {
    return useObserver(() => (
        <>
            <div className={Ast.row}> 
                <div className={Ast.dName}>발행예정주식수</div>
                <div className={Ast.data}>
                    {props.step.numOfShersToBeIssued ?
                    <>
                        {
                            props.step?.sharesToBeIssuedType === "HELP_ME" &&
                            <div>헬프미에서 적절한 숫자로 변경</div> 
                        }
                        {
                            props.step?.sharesToBeIssuedType !== "HELP_ME" &&
                            <><CopyBtn onClick={() => copyText(props.step.numOfShersToBeIssued)}>{addCommas(props.step.numOfShersToBeIssued)}</CopyBtn> 주</> 
                        }
                    </>
                    :
                    '-' 
                    }
                    
                </div>
            </div>
        </>
    ));
}

export default NumOfSharesToBeIssuedStep;