import { useObserver } from 'mobx-react';
import ParValueDivision from './ParValueDivision/ParValueDivision'
import { Route } from "react-router-dom";

function ParValueDivisionStep() {
    return useObserver(() => (
        <>  
            {/* 액면금 변경 */}
            <Route path="/step/changeRegistration/ParValueDivisionStep/ParValueDivision" component={ParValueDivision}></Route>
        </>
    ));
}

export default ParValueDivisionStep;