import { useObserver } from 'mobx-react';
import vm from './StandbyFreeIssueOfNewSharesCheckVm';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './StandbyFreeIssueOfNewSharesCheck.module.scss';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import { addCommas } from '@common/module/replaceNumber';

function StandbyFreeIssueOfNewSharesCheck() {
    return useObserver(() => (
        <form onSubmit={(e) => vm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            <Question>
                <ContentBox>
                    <div className={st.rowContent}>
                        <h2 className={st.title}>아래는 고객님이 <span className={st.bold}>스탠바이</span>에서 입력하신 내용입니다.</h2>
                    </div>
                    <div className={st.rowContent}>
                        <div className={st.title}>무상증자 방식</div>
                        <div className={st.extra}>{vm.freeIssueOfNewSharesType}으로 무상증자</div>
                    </div>
                    <div className={st.rowContent}>
                        <div className={st.title}>무상증자주식 배정표</div>
                        <div className={st.sub}>※ 주식수는 소수점이 불가</div>
                        <table className={st.freeIssueTable}>
                            <colgroup>
                                <col width="300"/>
                                <col />
                                <col width="315"/>
                                <col width="320"/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>주주명</th>
                                    <th>지분율(=배당률)</th>
                                    <th>신주식 수</th>
                                    <th>무상증자할 주식수</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vm.standbyFreeIssueOfNewSharesAllotmentInfos?.map((item, idx) => (
                                    <tr key={idx}>
                                        <td><span className={st.index}>1</span>{item.name}</td>
                                        <td>{item.shareRatio}%</td>
                                        {idx === 0 && <td rowSpan={vm.standbyFreeIssueOfNewSharesAllotmentInfos.length} className={st.issue}>{addCommas(vm.sum)}</td>}
                                        <td className={st.stock}>{addCommas(item.newStockAmount)}주</td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>합계</td>
                                    <td>100.00%</td>
                                    <td></td>
                                    <td>총 {addCommas(vm.sum)}주</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className={st.rowContent}>
                        <div className={st.title}>등기부상 자본금 변동</div>
                        <ul className={st.capitalChange}>
                            <li>
                                <div>기존</div>
                                <div>{addCommas(vm.capital)}원</div>
                            </li>
                            <li>
                                <div>무상증자</div>
                                <div>+{addCommas(vm.freeIssueOfNewSharesCapital)}원</div>
                            </li>
                            <li>
                                <div>신규</div>
                                <div>{addCommas(vm.capital+vm.freeIssueOfNewSharesCapital)}원</div>
                            </li>
                        </ul>
                    </div>
                </ContentBox>
            </Question>
            <Question>
                <ContentBox className={Qst.spaceBetween}>
                    <div className={Qst.infoSection}>
                        <div className={Qst.titleWrap}>
                            <h2 className={Qst.title}><span className={Qst.bold}>위 내용대로 진행하는 것이 맞나요?</span></h2>
                        </div>
                        <ul className={Qst.answerList}>
                            <li>
                                <Radio className={Qst.answerLabel} value={true} activeBackground checked={vm.keepGoingYn === true} onChange={(e) => vm.setKeepGoingYn(e)} name="keepGoingYn">네. 위 내용대로 진행합니다.</Radio>
                            </li>
                            <li><Radio className={Qst.answerLabel} value={false} activeBackground checked={vm.keepGoingYn === false} onChange={(e) => vm.setKeepGoingYn(e)} name="keepGoingYn">아니오. 위 내용과 다르게 진행합니다.</Radio></li>
                        </ul>
                        <Error name="keepGoingYn" value={vm.keepGoingYn} errorCase={{required:'진행하실 방법을 선택해주세요.'}}/>
                    </div>
                </ContentBox>
            </Question>
        </form>
    ));
}
export default StandbyFreeIssueOfNewSharesCheck;