import { useObserver } from 'mobx-react';
import ConfirmAndGuidanceVm from './ConfirmAndGuidanceVm';
import {addCommas} from '@common/module/replaceNumber';
import clsx from 'clsx';
import st from './ConfirmAndGuidance.module.scss';
import CircleBtn from '@comComponents/atoms/Button/CircleBtn/CircleBtn';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import SignleQuestionHeader from '@commonComponents/SingleQuestionHeader/SingleQuestionHeader';

function ConfirmAndGuidance() {
    return useObserver(() => (
    <>
        <SignleQuestionHeader>
            <h1 className="title">견적확인 및 절차안내</h1>
            <div className="explain">다음 절차는 [잔고증명서 업로드] 입니다. 아래 절차안내 정독 후 진행해 주세요.</div>
        </SignleQuestionHeader>
        <div className="mainContent">
            <Question>
                <ContentBox>
                    <div className={st.estimate}>
                        <div className={st.finEstimate}>
                            <div className={st.estimatePrice}>
                                <div className={st.priceTitle}><span className={st.bold}>최종 견적 비용</span>(공과금+수수료)입니다.</div>
                                <div className={st.price}>{addCommas(699900)}<span className={st.won}>원</span></div>
                            </div>
                            <div className={st.estimateBtn}><CircleBtn onClick={() => ConfirmAndGuidanceVm.openModal()}>자세히 보기</CircleBtn></div>
                        </div>
                        <div className={st.estimateInfo}>
                            <div className={st.infoWrap}>
                                <div className={st.infoTitle}>계좌번호</div>
                                <div className={st.info}>신한은행 110-376-546170</div>
                            </div>
                            <div className={st.infoWrap}>
                                <div className={st.infoTitle}>예금주</div>
                                <div className={st.info}>박효연 (헬프미)</div>
                            </div>
                            <div className={st.infoWrap}>
                                <div className={st.infoTitle}>입금자명</div>
                                <div className={st.info}>전명주 <span className={st.explain}>(※ 담당자명으로 입금 필수)</span></div>
                            </div>
                        </div>
                    </div>
                    <div className={st.estimateExplain}>
                        <div className={st.explain}>※ 간단견적서와 최종 금액이 다를 수 있습니다. (자본금, 지역, 방법 변경시) 그 경우 최종 견적 비용을 입금해 주세요.</div>
                    </div>
                    <div className={st.estimateExplain}>
                        <div className={st.explain}>※ 최종 견적비용은 카카오톡으로 발송해드렸습니다. 메일로 발송 드리겠습니다. (업무시간 중 30분 이내)</div>
                    </div>
                </ContentBox>
            </Question>  
            <Question style={{borderRadius:'4px'}}>
                <ContentBox>
                    <div className={st.procedureWrap}>
                        <div className={st.procedureTitle}>절차안내</div>
                        <div className={st.procedure}>
                            <div className={st.procedureExplain}>
                                다음 절차는 <span className={st.bold}>잔고증명서 업로드</span>입니다.<br/>
                                하단 절차안내에서 고객님의 하실 일을 확인해 주시기 바랍니다.
                            </div>
                            <div className={st.procedureStep}>
                                <div className={clsx(st.stepBox, st.helpme)}>
                                    <div>STEP1</div>
                                    <div className={st.stepContent}>
                                        법인정보 입력 (완료)<br/>
                                        <span className={st.bold}>잔고증명서 업로드 (진행)</span><br/>
                                        최종견적 입금
                                    </div>
                                </div>
                                <div className={st.stepBox}>
                                    <div>STEP2</div>
                                    <div className={st.stepContent}>
                                        법률 검토 . 조언<br/>
                                        실시간 채팅상담<br/>
                                        설립 법률서류 작성<br/>
                                        도장제작
                                    </div>
                                </div>  
                                <div className={clsx(st.stepBox, st.helpme)}>
                                    <div>STEP3</div>
                                    <div className={st.stepContent}>
                                        고객님 전자 서명
                                    </div>
                                </div>  
                                <div className={st.stepBox}>
                                    <div>STEP4</div>
                                    <div className={st.stepContent}>
                                        등기소 접수처리<br/>
                                        등기소 대관업무<br/>
                                        보정명령 처리<br/>
                                        실시간 모니터링
                                    </div>
                                </div>  
                                <div className={st.stepBox}>
                                    <div>STEP5</div>
                                    <div className={st.stepContent}>
                                        대법원에서 등기완료
                                    </div>
                                </div>  
                                <div className={st.stepBox}>
                                    <div>STEP6</div>
                                    <div className={st.stepContent}>
                                        등기소 방문 . 인감카드 발급<br/>
                                        인감증명서 및 등기부 발급<br/>
                                        정관 등 필수서류 출력<br/>
                                        빠른등기로 배송처리
                                    </div>
                                </div>  
                                <div className={st.stepBox}>
                                    <div>STEP7</div>
                                    <div className={st.stepContent}>
                                        무료 사업자 등록
                                    </div>  
                                </div>
                                <div className={st.workBox}>
                                    <div className={st.work}>
                                        <div className={st.customWork}>고객님의 일</div>
                                        <div className={st.helpmeWork}>헬프미의 일</div>
                                    </div> 
                                </div> 
                            </div>
                            <div className={st.procedureGuidance}>
                                <ul className={st.unOrder}>
                                    <li><span className={st.bold}>다음 단계에서 잔고증명서 업로드</span>를 해 주세요. 미성년 {ConfirmAndGuidanceVm.name()}가 있는 경우, 기본 증명서(상세)와 가족관계 증명서(상세)도 업로드해 주세요.</li>
                                    <li>입급 및 파일업로드가 확인되면, <span className={st.bold}>전담 매니저가 법률검토를 마친 후, 최종확인을 요청하는 메세지를 보내</span>드립니다.<br/>이 때, 검토 후 문제 되는 부분이 있으면 정정 요청을 드리고, 적용하면 좋을 팁 들이 있으면 함께 알려 드립니다.</li>
                                    <li>최종확인 후, 전담 매니저가 필요한 서류를 작성해 대법원 사이트에 업로드한 후, <span className={st.bold}>고객님께 전자서명 요청</span>을 드립니다.<br/>그 후, {ConfirmAndGuidanceVm.name()} &middot; 임원분들이 <span className={st.bold}>각자 집에서 대법원 사이트에 접속해서 은행용 공동인증서(구 공인인증서) 비밀번호를 넣어</span>주시면 됩니다.</li>
                                    <li><span className={st.bold}>전자서명 후, 헬프미가 등기접수</span>를 합니다. 등기 완료 후, 사업자등록 절차를 진행해 드립니다. (요청고객 한정)</li>
                                    <li>입금 및 잔고증명서 업로드 부터 법인설립 완료 까지 : <span className={st.bold}>영업일 5일 소요</span></li>
                                    <li>법인설립 완료부터 사업자등록 완료까지 : <span className={st.bold}>영업일 3 -5 일 소요</span></li>
                                </ul>
                                <div className={st.supply}>
                                    잔고 증명서 업로드 준비물
                                    <ol className={st.order}>
                                        <li>{ConfirmAndGuidanceVm.name()} &middot; 임원의 <span className={st.bold}>은행용 공동인증서(구 공인인증서)</span> (미성년자는 부모님의 공동인증서)</li>
                                        <li><span className={st.bold}>잔고 증명서</span> ({ConfirmAndGuidanceVm.name()} 중 1명 명의로 은행 발급, 개인사업자용 계좌 불가)</li>
                                    </ol>
                                </div>
                                <div className={st.backgroundImg}/>
                            </div>
                        </div>
                    </div>
                </ContentBox>
            </Question>
        </div>
    </>
    ));
}

export default ConfirmAndGuidance;
