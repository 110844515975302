import smartformAppModel from '@model/smartformAppModel';
import changeRegistrationService from '@service/changeRegistrationService';
import pageStepModel from '@model/pageStepModel';

class viewModel {
    loadChangeRegistrationStep() {
        changeRegistrationService.loadChangeRegistrationStep();
    }
    get nowPage() {
        return pageStepModel.nowPage;
    }
    get isLoadData() {
        return smartformAppModel.isLoadData;
    }
}
export default new viewModel();
