import finalCheckService from '@service/changeRegistration/finalCheckService';
import { observable, runInAction, toJS } from "mobx";
import finalCheckModel from '@model/changeRegistration/finalCheckModel';
import st from '../../FinalCheck/FinalCheck.module.scss';
import { _alert, _confirm } from "@model/dialogModel";
import smartformAppModel from '@model/smartformAppModel';

class BeforeFinalCheckVm {
    constructor() {
        this.state = observable({
            checkPage: 'start',
            submitBtnDisabledYn : false
        });
    }
    async submit() {
        if(await _confirm('최종제출 하시겠습니까?')) {
            this.state.submitBtnDisabledYn = true;
            await finalCheckService.submitFinalCheck();
            //await _alert('최종제출 하였습니다.')
            this.state.submitBtnDisabledYn = false;
            window.location.href="/changeRegistration/AfterFinalCheck";
        } 
    }
    loadFinalCheck() {
        finalCheckService.before_load();
    }
    get steps() {
        return finalCheckModel.steps;
    }
    get smartFormType() {
        return smartformAppModel.smartFormType;
    }
    get text() {
        const smartFormType = this.smartFormType;
        switch(smartFormType) {
            case 'STOCK_COMPANY': return {name: '주주', stock: '대주주'}
            case 'AGRICULTURAL_STOCK_COMPANY': return {name: '주주', stock: '대주주'}
            case 'FISHERY_STOCK_COMPANY': return {name: '주주', stock: '대주주'}
            case 'LIMITED_COMPANY': return {name: '사원', stock: '최대 지분사원'}
            case 'LIMITED_LIABILITY_COMPANY': return {name: '주주', stock: '최대 지분사원'}
        }
    }
    Question(key) {
        switch(key) {
            case 'start':
                return {
                    key: key,
                    question: <span>수고하셨습니다.<br/><span className={st.questionBold}>마지막으로 입력 내용을 확인</span>해볼까요?</span>,
                    link: null
                }
            case 'CorpNameStep': 
                return {
                    key: key,
                    question: <span>이 내용대로 <span className={st.questionBold}>상호 변경등기</span>를 진행할까요?</span>,
                    link: this.editUrl(key)
                }
            case 'HeadOfficeRelocationStep': 
                return {
                    key: key,
                    question: <span>
                                이 내용대로 <span className={st.questionBold}>본점이전</span>을 진행할까요?<br/>
                                주소가 <span className={st.questionBold}>임대차 계약서 또는 전대차 계약서에 나온 주소와 일치</span>하나요?<br/>
                                특히 <span className={st.questionBold}>층, 호수, 건물이름이 일치</span>하는지 확인해주세요.<br/>
                                (계약서에는 지번주소로 나와 있다면 도로명으로 변환한 주소가 일치하면 됩니다.)
                            </span>,
                    link: this.editUrl(key)
                }
            case 'PaidInCapitalIncreaseStep': 
                return {
                    key: key,
                    question: <span>이 내용대로 <span className={st.questionBold}>신주발행</span>을 진행할까요?</span>,
                    link: this.editUrl(key)
                }
            case 'NumOfSharesToBeIssuedStep': 
                return {
                    key: key,
                    question: <span>이 내용대로 <span className={st.questionBold}>발행예정주식수</span>를 진행할까요?</span>,
                    link: this.editUrl(key)
                }
            case 'ExecutiveMemberChangeStep': 
                return {
                    key: key,
                    question: <span>이 내용대로 <span className={st.questionBold}>임원변경</span>을 진행할까요?</span>,
                    link: this.editUrl(key)
                }
            case 'BusinessPurposeStep': 
                return {
                    key: key,
                    question: <span>이 내용대로 <span className={st.questionBold}>목적 변경등기</span>를 진행할까요?</span>,
                    link: this.editUrl(key)
                }
            case 'AddStockOptionStep': 
                return {
                    key: key,
                    question: <span>이 내용대로 <span className={st.questionBold}>스톡옵션</span>규정 변경등기를 진행할까요?</span>,
                    link: this.editUrl(key)
                }
            case 'AnnounceMethodStep': 
                return {
                    key: key,
                    question: <span>이 내용대로 <span className={st.questionBold}>공고방법 변경등기</span>를 진행할까요?</span>,
                    link: this.editUrl(key)
                }
            case 'BranchOfficeDeleteStep': 
                return {
                    key: key,
                    question: <span>이 내용대로 <span className={st.questionBold}>지점폐지</span>를 진행할까요?</span>,
                    link: this.editUrl(key)
                }
            case 'BranchOfficeRelocateStep': 
                return {
                    key: key,
                    question: <span>
                                이 내용대로 <span className={st.questionBold}>지점이전</span>을 진행할까요?<br/>
                                주소가 <span className={st.questionBold}>임대차 계약서 또는 전대차 계약서에 나온 주소와 일치</span>하나요?<br/>
                                특히 <span className={st.questionBold}>층, 호수, 건물이름이 일치</span>하는지 확인해주세요.<br/>
                                (계약서에는 지번주소로 나와 있다면 도로명으로 변환한 주소가 일치하면 됩니다.)
                            </span>,
                    link: this.editUrl(key)
                }
            case 'BranchOfficeChangeStep': 
                return {
                    key: key,
                    question: <span>이 내용대로 <span className={st.questionBold}>지점변경</span>을 진행할까요?</span>,
                    link: this.editUrl(key)
                }
            case 'BranchOfficeInstallStep': 
                return {
                    key: key,
                    question: <span>
                                이 내용대로 <span className={st.questionBold}>지점설치</span>를 진행할까요?<br/>
                                주소가 <span className={st.questionBold}>임대차 계약서 또는 전대차 계약서에 나온 주소와 일치</span>하나요?<br/>
                                특히 <span className={st.questionBold}>층, 호수, 건물이름이 일치</span>하는지 확인해주세요.<br/>
                                (계약서에는 지번주소로 나와 있다면 도로명으로 변환한 주소가 일치하면 됩니다.)
                            </span>,
                    link: this.editUrl(key)
                }
            case 'BoardMeetingSealProvidingStep': 
                return {
                    key: key,
                    question: <span>이사회 공증용 <span className={st.questionBold}>인감증명서를 제공할 이사</span>님 명단입니다.<br/><span className={st.questionBold}>50%를 초과하는 이사님의 성함</span>을 입력하였나요? (감사의 인감증명서는 이용불가)</span>,
                    link: this.editUrl(key)
                }
            case 'StockholdersMeetingSealProvidingStep': 
                let title = null;
                let question1 = null;
                let question2 = null;
                const smartFormType = this.smartFormType;
                this.steps.forEach((step) => {
                    if(step.code === key) {
                        if(smartFormType === 'LIMITED_COMPANY' || smartFormType === 'LIMITED_LIABILITY_COMPANY') { //유한,유한책임 회사
                            title = <span>사원총회 공증용 <span className={st.questionBold}>인감증명서를 제공할 사원</span>분 명단입니다.</span>
                            if(step.stockholderMeetingType === "SPECIAL") { //사원총회 특별
                                question1 = <span>입력한 사원분들의 지분이 <span className={st.questionBold}>합계 75%</span>를 넘고,<br/>인감증명서를 제공하는 사원은 <span className={st.questionBold}>총 사원 숫자의 50%</span> 이상인가요?</span>
                            } else { //사원총회 일반
                                question1 = <span>입력한 사원분들의 지분이 <span className={st.questionBold}>합계 50.1%</span> 이상인가요?</span>
                            }
                            if(step.containsAuditorInaugurationYn) {
                                question2 = <span>
                                                <br/>
                                                <span className={st.questionBold}>감사의 취임, 재취임시</span>에는<br/>사원당 발행주식의 <span className={st.questionBold}>3%만큼만 의결권을 행사</span>할 수 있습니다.<br/>
                                                따라서 <span className={st.questionBold}>최대 지분사원의 인감증명서만으로 등기가 불가능</span>하므로,<br/> 이 경우 사원의 <span className={st.questionBold}>과반수가 인감증명서를 제공</span>해주셔야 합니다.<br/>
                                                <span className={st.questionBold}>사원의 과반수가 명단에 포함</span>되었나요?
                                            </span>
                            }
                        } else { //주식회사
                            title = <span>주주총회 공증용 <span className={st.questionBold}>인감증명서를 제공할 주주</span>님 명단입니다.</span>
                            if(step.stockholderMeetingType === "SPECIAL") { //주주총회 특별
                                question1 = <span>입력한 주주님들의 지분이 <span className={st.questionBold}>합계 66.7%</span>를 초과하나요?</span>
                            } else { //주주총회 일반
                                question1 = <span>입력한 주주님들의 지분이 <span className={st.questionBold}>합계 50.1%</span>를 초과하나요?</span>
                            }
                            if(step.containsAuditorInaugurationYn) {
                                question2 = <span>
                                                <br/>
                                                <span className={st.questionBold}>감사의 취임, 재취임시</span>에는<br/>주주당 발행주식의 <span className={st.questionBold}>3%만큼만 의결권을 행사</span>할 수 있습니다.<br/>
                                                따라서 <span className={st.questionBold}>대주주의 인감증명서만으로 등기가 불가능</span>하므로,<br/> 이 경우 주주의 <span className={st.questionBold}>과반수가 인감증명서를 제공</span>해주셔야 합니다.<br/>
                                                <span className={st.questionBold}>주주의 과반수가 명단에 포함</span>되었나요?
                                            </span>
                            }
                        }
                    }
                })
                return {
                    key: key,
                    question: <span>
                                {title}<br/>
                                {question1}<br/>
                                {question2}
                            </span>,
                    link: this.editUrl(key)
                }   
            case 'RepresentativeAddressChangeStep': 
                return {
                    key: key,
                    question: <span>
                                대표님의 <span className={st.questionBold}>주민등록번호, 집주소 변동내역</span>이 맞는지 확인 부탁드립니다.<br/><br/>
                                대표님의 <span className={st.questionBold}>주소가 초본(등본)과 한글자라도 다른 경우</span>,<br/>등기소에서는 <span className={st.questionBold}>보정명령</span>을 내립니다.<br/>이 경우 <span className={st.questionBold}>5~7일의 시간이 더 걸리고</span>, 서류 수정 수수료(3~5만원)가<br/>발생하므로 확실히 확인 부탁드립니다.<br/><br/><span className={st.questionBold}>동, 호수, 아파트, 빌라 이름</span>을 <span className={st.questionBold}>특히 주의</span>해주세요.<br/>초본(등본)에는 도로명주소 사이트에 뜨는 건물 이름과 다르게<br/>등록되어 있는 경우가 많습니다.
                            </span>,
                    link: this.editUrl(key)
                }
            case 'TCAgreeAndDeliveryAddressStep': 
                return {
                    key: key,
                    question: <span>이 주소로 <span className={st.questionBold}>등기완료 후 우편물</span>을 보내드릴까요?</span>,
                    link: this.editUrl(key)
                }
            case 'FreeIssueOfNewSharesStep': 
                return {
                    key: key,
                    question: <span>이 내용대로 <span className={st.questionBold}>무상증자</span>를 진행할까요?</span>,
                    link: this.editUrl(key)
                }
            case 'CapitalIncreaseStep': 
                return {
                    key: key,
                    question: <span>이 내용대로 <span className={st.questionBold}>신주발행</span>을 진행할까요?</span>,
                    link: this.editUrl(key)
                }
            case 'ParValueDivisionStep': 
                return {
                    key: key,
                    question: <span>이 내용대로 <span className={st.questionBold}>액면금 변경</span>을 진행할까요?</span>,
                    link: this.editUrl(key)
                }
            case 'LiquidationStep': 
                return {
                    key: key,
                    question: <span>이 내용대로 <span className={st.questionBold}>법인해산, 청산</span>을 진행할까요?</span>,
                    link: this.editUrl(key)
                }
            case 'StockCompanySealStep': 
                let StockCompanySealStepTitle = null;
                let StockCompanySealStepquestion = null;
                this.steps.forEach((step) => {
                    if(step.code === key) {
                        StockCompanySealStepTitle = <span>고객님이 입력하신 <span className={st.questionBold}>주주총회 진행</span> 관련 정보입니다.<br/>입력한 내용이 모두 정확한가요?</span>
                        if(step.hasAuditorInaugurationYn) {
                            StockCompanySealStepquestion = <span>
                                            <br/>
                                            <span className={st.questionBold}>감사의 취임, 재취임시</span>에는<br/>주주당 발행주식의 <span className={st.questionBold}>3%만큼만 의결권을 행사</span>할 수 있습니다.<br/>
                                            따라서 <span className={st.questionBold}>대주주의 인감증명서만으로 등기가 불가능</span>하므로,<br/> 이 경우 주주의 <span className={st.questionBold}>과반수가 인감증명서를 제공</span>해주셔야 합니다.<br/>
                                            <span className={st.questionBold}>주주의 과반수가 명단에 포함</span>되었나요?
                                        </span>
                        }
                    }
                })
                return {
                    key: key,
                    question: <span>
                                {StockCompanySealStepTitle}
                                {StockCompanySealStepquestion}
                            </span>,
                    link: this.editUrl(key)
                } 
            case 'ConvertibleBondStep': 
                return {
                    key: key,
                    question: <span>이 내용대로 <span className={st.questionBold}>전환사채 발행</span>을 진행할까요?</span>,
                    link: this.editUrl(key)
                }  
            case 'StandbyParValueDivisionStep': 
                return {
                    key: key,
                    question: <span>이 내용대로 <span className={st.questionBold}>액면분할</span>을 진행할까요?</span>,
                    link: this.editUrl(key)
                }
            case 'StandbyFreeIssueOfNewSharesStep': 
                return {
                    key: key,
                    question: <span>이 내용대로 <span className={st.questionBold}>무상증자</span>를 진행할까요?</span>,
                    link: this.editUrl(key)
                }
            case 'StandByStockholderMeetingInfoStep': 
                return {
                    key: key,
                    question: <span>이 내용대로 <span className={st.questionBold}>주주총회</span>를 진행하나요?</span>,
                    link: this.editUrl(key)
                }
            case 'StandByBoardMeetingInfoStep': 
                return {
                    key: key,
                    question: <span>이 내용대로 <span className={st.questionBold}>이사회</span>를 진행하나요?</span>,
                    link: this.editUrl(key)
                }
            case 'StandbyPaidInCapitalIncreaseStep': 
                return {
                    key: key,
                    question: <span>이 내용대로 <span className={st.questionBold}>신주발행</span>을 진행할까요?</span>,
                    link: this.editUrl(key)
                }
        }
    }
    editUrl(code) {
        let link;
        this.steps.forEach((step) => {
            if(step.code === code) {
                link = step.editUrl
            }
        })
        return link;
    }
    get stepList() {
        let stepList = ['start'];
        this.steps.forEach((step) => {
            stepList.push(step.code);
        })
        return stepList;
    }
    btnYes() { 
        const checkpage = this.state.checkPage;
        this.stepList.forEach((item, idx) => {
            if(item === checkpage) {
                if(idx === this.stepList.length-1) {
                    this.state.checkPage = null;
                } 
                this.state.checkPage = this.stepList[idx+1];
            }
        })
    } 
    btnBack() {
        const checkpage = this.state.checkPage;
        this.stepList.forEach((item, idx) => {
            if(item === checkpage) {
                this.state.checkPage = this.stepList[idx-1];
            }
        })
    }
}
export default new BeforeFinalCheckVm();