import { runInAction, observable } from "mobx";
import shareholderAndExecutiveInfoModel from "@model/establish/shareholderAndExecutiveInfoModel";
import stepService from "@service/stepService";
import { addCommas, removeCommas, onlyNumber} from '@common/module/replaceNumber';
import smartformAppModel from "@model/smartformAppModel";
import regExp from '@common/module/regExp';
import formDataToJson from '@common/module/submit';

class viewModel {
    constructor() {
        this.state = observable({ //직접입력
            directValue: ''
        });
    }
    submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            if(param?.directParValue) {
                param.parValue = removeCommas(param.directParValue);
                delete param.directParValue;
            }
            stepService.saveStep(param);
        }
    }
    get smartFormType() {
        return smartformAppModel.smartFormType;
    }
    get parValueOptionList() { //select option 값
        return ['', 100, 500, 1000, 10000, 'direct']
    }
    get parValue() {
        const parValue = shareholderAndExecutiveInfoModel.parValue;
        if(parValue === null || this.parValueOptionList.includes(parValue)) { //parValue의 값이 없거나 select에 있는 값일 때
            return shareholderAndExecutiveInfoModel.parValue;
        } else { //selet에 없는 값일 때. 즉, 직접입력한 값일 때(ex. 15000)
            runInAction(() => {
                this.state.directValue = parValue;
                shareholderAndExecutiveInfoModel.parValue = 'direct';
            })            
            return shareholderAndExecutiveInfoModel.parValue;
        }
    }
    setParValue(e) {
        if(e.target.value === '' || e.target.value === 'direct') { //string 타입은 string으로 넘겨줌.
            shareholderAndExecutiveInfoModel.parValue = e.target.value; 
        } else {
            shareholderAndExecutiveInfoModel.parValue = Number(e.target.value); //Number 타입은 Number로 넘겨줌.
        }        
    }
    get directParValue() {
        return this.state.directValue;
    }
    setDirectParValue(e) {
        this.state.directValue = onlyNumber(removeCommas(e.target.value));
    }
    get corporationYn() {
        const formType = this.smartFormType;
        switch(formType) {
            case 'ESTABLISHMENT_STOCK_COMPANY': return true;
            case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY': return true;
            case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY': return true;
            case 'ESTABLISHMENT_LIMITED_COMPANY': return false;
        }
    }
}

export default new viewModel();