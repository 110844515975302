import st from './StandByStockholderMeetingBasicInfoModal.module.scss';
import portalModel from '@model/portalModel';

const modal = {
    modalContent: 
        <div className={st.Modal}>
            <div className={st.title}>
                <span className={st.bold}>고객님!</span> 출석한 주주가 부족해요.
            </div>
            <div className={st.modalContent}>
                주주총회 일반결의를 하려면, 발행주식총수의 1/4 이상이 출석해야 해요. (의결권 있는 주식 기준)
            </div>
        </div>
    ,
    btn: [{title: '확인', type: 'positive', event: ()=> {portalModel.setModalClose()}}]
}

export default modal;

