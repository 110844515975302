import { useObserver } from 'mobx-react';
import st from './AdminFinalCheckView.module.scss';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import AdminFinalCheckViewVm from './AdminFinalCheckViewVm';
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import CorpNameStep from './steps/CorpNameStep/CorpNameStep';
import HeadOfficeRelocationStep from './steps/HeadOfficeRelocationStep/HeadOfficeRelocationStep';
import PaidInCapitalIncreaseStep from './steps/PaidInCapitalIncreaseStep/PaidInCapitalIncreaseStep';
import NumOfSharesToBeIssuedStep from './steps/NumOfSharesToBeIssuedStep/NumOfSharesToBeIssuedStep';
import ExecutiveMemberChangeStep from './steps/ExecutiveMemberChangeStep/ExecutiveMemberChangeStep';
import BusinessPurposeStep from './steps/BusinessPurposeStep/BusinessPurposeStep';
import AddStockOptionStep from './steps/AddStockOptionStep/AddStockOptionStep';
import AnnounceMethodStep from './steps/AnnounceMethodStep/AnnounceMethodStep';
import BranchOfficeDeleteStep from './steps/BranchOfficeDeleteStep/BranchOfficeDeleteStep';
import BranchOfficeRelocateStep from './steps/BranchOfficeRelocateStep/BranchOfficeRelocateStep';
import BranchOfficeChangeStep from './steps/BranchOfficeChangeStep/BranchOfficeChangeStep';
import BranchOfficeInstallStep from './steps/BranchOfficeInstallStep/BranchOfficeInstallStep';
import BoardMeetingSealProvidingStep from './steps/BoardMeetingSealProvidingStep/BoardMeetingSealProvidingStep';
import StockholdersMeetingSealProvidingStep from './steps/StockholdersMeetingSealProvidingStep/StockholdersMeetingSealProvidingStep';
import RepresentativeAddressChangeStep from './steps/RepresentativeAddressChangeStep/RepresentativeAddressChangeStep';
import TCAgreeAndDeliveryAddressStep from './steps/TCAgreeAndDeliveryAddressStep/TCAgreeAndDeliveryAddressStep';
import FreeIssueOfNewSharesStep from './steps/FreeIssueOfNewSharesStep/FreeIssueOfNewSharesStep';
import FileUploadStep from './steps/FileUploadStep/FileUploadStep';
import CapitalIncreaseStep from './steps/CapitalIncreaseStep/CapitalIncreaseStep';
import ParValueDivisionStep from './steps/ParValueDivisionStep/ParValueDivisionStep';
import LiquidationStep from './steps/LiquidationStep/LiquidationStep';
import StockCompanySealStep from './steps/StockCompanySealStep/StockCompanySealStep';
import StandbyPaidInCapitalIncreaseStep from './steps/StandbyPaidInCapitalIncreaseStep/StandbyPaidInCapitalIncreaseStep';
import StandbyFreeIssueOfNewSharesStep from './steps/StandbyFreeIssueOfNewSharesStep/StandbyFreeIssueOfNewSharesStep';
import StandbyParValueDivisionStep from './steps/StandbyParValueDivisionStep/StandbyParValueDivisionStep';
import StandByStockholderMeetingInfoStep from './steps/StandByStockholderMeetingInfoStep/StandByStockholderMeetingInfoStep';
import StandByBoardMeetingInfoStep from './steps/StandByBoardMeetingInfoStep/StandByBoardMeetingInfoStep';
import ConvertibleBondStep from './steps/ConvertibleBondStep/ConvertibleBondStep';
import StandbyFileUploadStep from './steps/StandbyFileUploadStep/StandbyFileUploadStep';
import copyText from '@common/module/copyText';
import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';

function AdminFinalCheckView() {
  useEffect(()=>{
    AdminFinalCheckViewVm.loadChangeRegistrationAdminFinalSubmission();
  }, []);

  return useObserver(() => (
    <>
      <div className="mainContent" style={{top:'14px'}}>
        <Question style={{marginBottom:'30px', width:'1019px'}}>
          <ContentBox>
            <div className={st.changeReflectionBtnArea}>
              <div className={st.changeReflactionBtnBox}>
                {AdminFinalCheckViewVm.standbyYn && <div className={st.standby}>스탠바이 등기대행</div>}
                <button type="button" className={st.changeReflectionBtn} onClick={() => AdminFinalCheckViewVm.changeReflaction()}>수정사항 진행 반영</button>
              </div>
              <div className={st.causeBtnBox}>
                <div className={st.causeCopyBtn}>등기사유 <CopyBtn onClick={() => copyText(AdminFinalCheckViewVm.copyText())}>[보통등기]</CopyBtn></div>
              </div>
            </div>
            {AdminFinalCheckViewVm.standbyYn && <div className={st.smartForm}>스마트폼</div>}
            {AdminFinalCheckViewVm.steps.map((step, index) => {
              return (
                <div className={st.table} key={index}>
                  <div className={st.btnArea}>
                    <div className={st.btnBox}>
                      <Link to={step.editUrl}><button type="button" className={st.managerModeBtn}>매니저 모드로 수정하기</button></Link>
                    </div>
                  </div>
                  <div className={st.title}>
                    {step.title}
                    {step.deletedYn && <span className={st.titleAlert}>견적에서 삭제됨</span>}
                  </div>
                  <div className={st.info}>
                    {/* 헬프미 */}
                    { step.code === "CorpNameStep" && <CorpNameStep step={step} />}
                    { step.code === "HeadOfficeRelocationStep" && <HeadOfficeRelocationStep step={step}/> }
                    { step.code === "PaidInCapitalIncreaseStep" && <PaidInCapitalIncreaseStep step={step}/> }
                    { step.code === "NumOfSharesToBeIssuedStep" && <NumOfSharesToBeIssuedStep step={step}/> }
                    { step.code === "ExecutiveMemberChangeStep" && <ExecutiveMemberChangeStep step={step}/> }
                    { step.code === "BusinessPurposeStep" && <BusinessPurposeStep step={step}/> }
                    { step.code === "AddStockOptionStep" && <AddStockOptionStep step={step}/> }
                    { step.code === "AnnounceMethodStep" && <AnnounceMethodStep step={step}/> }
                    { step.code === "BranchOfficeDeleteStep" && <BranchOfficeDeleteStep step={step}/> }
                    { step.code === "BranchOfficeRelocateStep" && <BranchOfficeRelocateStep step={step}/> }
                    { step.code === "BranchOfficeChangeStep" && <BranchOfficeChangeStep step={step}/> }
                    { step.code === "BranchOfficeInstallStep" && <BranchOfficeInstallStep step={step}/> }
                    { step.code === "BoardMeetingSealProvidingStep" && <BoardMeetingSealProvidingStep step={step}/> }
                    { step.code === "StockholdersMeetingSealProvidingStep" && <StockholdersMeetingSealProvidingStep step={step}/> }
                    { step.code === "RepresentativeAddressChangeStep" && <RepresentativeAddressChangeStep step={step}/> }
                    { step.code === "TCAgreeAndDeliveryAddressStep" && <TCAgreeAndDeliveryAddressStep step={step}/> }
                    { step.code === "FreeIssueOfNewSharesStep" && <FreeIssueOfNewSharesStep step={step}/> }
                    { step.code === "FileUploadStep" && <FileUploadStep step={step} />}                   
                    { step.code === "CapitalIncreaseStep" && <CapitalIncreaseStep step={step} />}
                    { step.code === "ParValueDivisionStep" && <ParValueDivisionStep step={step}/> }        
                    { step.code === "LiquidationStep" && <LiquidationStep step={step}/> }     
                    { step.code === "StockCompanySealStep" && <StockCompanySealStep step={step}/> }
                    { step.code === "ConvertibleBondStep" && <ConvertibleBondStep step={step}/> }
                    {/* 스탠바이 */}
                    { step.code === "StandbyPaidInCapitalIncreaseStep" && <StandbyPaidInCapitalIncreaseStep step={step}/> }
                    { step.code === "StandbyFreeIssueOfNewSharesStep" && <StandbyFreeIssueOfNewSharesStep step={step}/> }
                    { step.code === "StandbyParValueDivisionStep" && <StandbyParValueDivisionStep step={step}/> }
                    { step.code === "StandByStockholderMeetingInfoStep" && <StandByStockholderMeetingInfoStep step={step}/> }
                    { step.code === "StandByBoardMeetingInfoStep" && <StandByBoardMeetingInfoStep step={step}/> }
                    { step.code === "StandbyFileUploadStep" && <StandbyFileUploadStep step={step}/> }
                  </div>
                </div>
              )
            })}
            {AdminFinalCheckViewVm.agendaTitles.length !== 0 && 
              <div className={st.agendaArea}>
                <div className={st.agendaTitle}>기타 등기가 불필요한 안건들</div>
                <ul>
                  {AdminFinalCheckViewVm.agendaTitles.map((agenda, idx) => (
                    <li key={idx}>{agenda}</li>
                  ))}
                </ul>
                <div className={st.explainBox}>
                  <div className={st.explain}>고객이 입력한 내용은 헬스대시보드를 통해 확인하세요.</div>
                  <div className={st.explain}>고객이 입력한 내용은 도비에서 자동 불러오기를 할 수 있습니다.</div>
                  <div className={st.explain}>
                    헬프미 상담 과정에서 고객이 진행을 취소한 안건의 경우, 도비에서 삭제해주세요.<br/>
                    (폼2 결과 확인화면 및 도비에서는 헬프미 견적과 무관하게 스탠바이 추가 입력내용 전체를 불러옵니다.)
                  </div>
                </div>
              </div>
            }
          </ContentBox>
        </Question>  
      </div>
    </>
  ));
}

export default AdminFinalCheckView;
