import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import st from '../../../FinalCheck/FinalCheck.module.scss';
import clsx from 'clsx';
import {addCommas, percent} from '@common/module/replaceNumber';

function CorpNameStep(props) {
    return useObserver(() => (
        <>
            <div className={clsx(st.row, st.before)}> 
                <div className={st.dName}>
                    기존목적 삭제
                    <div style={{marginTop:"5px"}}>({props.step.originalBusinessPurposes.filter(item => item.deleteYn).length}개 삭제)</div>
                </div>
                <div className={clsx(st.data, st.businessPurpose)}>
                    {props.step.originalBusinessPurposes.length !== 0 &&
                        props.step.originalBusinessPurposes.map((item, idx) => (
                            <span className={clsx(st.purpose, item.deleteYn ? st.lineThrough : null)} key={idx}>{item.content} {item.autoDeleteYn ? "(자동삭제)" : ""}</span>
                        ))
                    }
                    {props.step.originalBusinessPurposes.length === 0&&
                        <span className={st.purpose}>없음</span>
                    }
                </div>
            </div>
            <div className={clsx(st.row, st.before)}> 
                <div className={st.dName}>
                    신규목적 추가
                    <div style={{marginTop:"5px"}}>({props.step.newBusinessPurposes.filter(item => !item.autoDeleteYn).length}개 추가)</div>
                    <div style={{marginTop:"8px", fontSize:'16px'}}>{props.step.newBusinessPurposes.filter(item => item.autoDeleteYn).length}개 자동추가 과금X</div>
                </div>
                <div className={clsx(st.data, st.businessPurpose)}>
                    {props.step.newBusinessPurposes.length !== 0&&
                        props.step.newBusinessPurposes.map((item, idx) => (
                            <span className={st.purpose} key={idx}>1. {item.content} {item.autoDeleteYn ? "(자동추가)" : ""}</span>
                        ))
                    }
                    
                    {props.step.newBusinessPurposes.length === 0&&
                        <span className={st.purpose}>없음</span>
                    }
                </div>
            </div>
            {props.step.newBusinessPurposes.filter(item => !item.autoDeleteYn).length > 20 && 
                <div className={clsx(st.row, st.before)}> 
                    <div className={st.dName}>20개 초과
                        <div style={{marginTop:"5px"}}>추가비용</div>
                    </div>
                    <div className={st.data} >
                        {props.step.newBusinessPurposes.filter(item => !item.autoDeleteYn).length - 20}개 x 5,500 = {addCommas((props.step.newBusinessPurposes.filter(item => !item.autoDeleteYn).length - 20) * 5500)}원 추가비용
                        <div style={{marginTop:"5px"}}>(총 {props.step.newBusinessPurposes.filter(item => !item.autoDeleteYn).length}개 추가)</div>
                    </div>
                </div>
            }
        </>
    ));
}
export default CorpNameStep;