import st from './TCAgreeModal.module.scss';
import { runInAction } from 'mobx';
import portalModel from '@model/portalModel';
import clsx from 'clsx';

const modal = {
    modalContent: function(data) {
        const content = () => {
            return {__html : data}
        }
        return (
            <article className={st.Modal}>
                <h1 className={st.title} style={{width:'390px'}}>
                    법인운영에 유용한 뉴스레터 등 홍보 약관 <span className={st.unRequired}>(선택)</span>
                </h1>
                <div className={clsx(st.modalContent, st.termsBox, st.termsUnRequired)}>
                    <div dangerouslySetInnerHTML={content()}/>                    
                </div>
            </article>
        )
    },
    btn: [{title: '법인운영에 유용한 뉴스레터 등 홍보 약관', type: 'positive', event: ()=> {portalModel.setModalClose()}}]
}

export default modal;