
import { useObserver } from 'mobx-react';
import StandbyFileUpload from './StandbyFileUpload/StandbyFileUpload';
import { Route } from "react-router-dom";

function StandbyFileUploadStep() {
    return useObserver(() => (
    <>
        {/* 파일 업로드 */}
        <Route path="/step/changeRegistration/StandbyFileUploadStep/StandbyFileUpload" component={StandbyFileUpload}></Route>
    </>
    ));
}

export default StandbyFileUploadStep;