import { useObserver } from 'mobx-react';
import ChangeRegistrationVm from './ChangeRegistrationVm';
import { useCallback, useEffect, useState } from 'react';
import Password from './Password/Password';
import QuestionHeader from '@commonComponents/QuestionHeader/QuestionHeader';
import { Route, withRouter } from "react-router-dom";
import ChangeRegistrationStep from './ChangeRegistrationStep/ChangeRegistrationStep';
import BeforeFinalCheck from './FinalCheck/BeforeFinalCheck/BeforeFinalCheck';
import AfterFinalCheck from './FinalCheck/AfterFinalCheck/AfterFinalCheck';
import smartformAppModel from '@model/smartformAppModel';

function ChangeRegistration({history}) {
    useEffect(() => { 
        ChangeRegistrationVm.lunchChangeRegistrationService();
    }, [])
    useEffect(() => {
        ChangeRegistrationVm.mainContentTop(); 
    }, [history.location.pathname])
    return useObserver(() => {
        if(ChangeRegistrationVm.smartformId && ChangeRegistrationVm.smartFormType) {
            if(ChangeRegistrationVm.userToken) { //유저토큰이 있으면 비밀번호 창 안띄움 로그인 되어있는 상태
                if(ChangeRegistrationVm.isStep === true) {
                    return(
                        <>
                            {ChangeRegistrationVm.isLoadData && <QuestionHeader/>}
                            <div className="mainContent" style={{top : ChangeRegistrationVm.state.mainContentTop+"px"}}>
                                <Route path="/step/ChangeRegistration" component={ChangeRegistrationStep}></Route>
                            </div>
                        </>
                    )
                }else{
                    return(
                        <>
                            {/* 변경등기 최종제출 전 내용 확인 */}
                            <Route path="/changeRegistration/BeforeFinalCheck" component={BeforeFinalCheck}></Route>
                            {/* 변경등기 최종제출 후 내용 확인 */}
                            <Route path="/changeRegistration/AfterFinalCheck" component={AfterFinalCheck}></Route>
                        </>
                    )
                }
            }else {
                return <Password/>
            }
        }else{
            return <></>
        }
    });
}
export default withRouter(ChangeRegistration);

