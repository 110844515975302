import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const Btn = styled.button` 
    cursor: pointer;
    color: #006ac3;
    text-decoration-line: underline; 
    font-size: 13px;
    letter-spacing: -0.5px;
    background-color: transparent;   
    &:hover {
        font-weight: 500;
    }
`;

function CopyBtn(props) {
    return (
        <Btn type={props.type} className={props.className} style={props.style} onClick={(e) => props.onClick(e)}>
            {props.children}
        </Btn>
    );
};

CopyBtn.propTypes = {
    /**
    * 버튼 타입
    */
    type: PropTypes.string,
    /**
    * class명
    */
    className: PropTypes.string,
    /**
    * style 속성
    */
    style: PropTypes.object,
    /**
    * 클릭 이벤트
    */
    onClick: PropTypes.func,
    
    /**
    * 버튼 텍스트 값
    */
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.array,
        PropTypes.any
    ]),
};

CopyBtn.defaultProps = {
    type : 'button',
    className : null,
    style : {},
    onClick : () => {},
    children: 'Empty'
};

export default CopyBtn;

