import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { Fragment, useEffect } from 'react';
import ExecutiveMemberChangeStepVm from './ExecutiveMemberChangeStepVm';
import Cst from '../../../FinalCheck/FinalCheck.module.scss';
import st from './ExecutiveMemberChangeStep.module.scss';
import clsx from 'clsx';
import Icon from '@comComponents/atoms/Icon/Icon';

import { toJS } from 'mobx';

function ExecutiveMemberChangeStep(props) {
    return useObserver(() => (
        <>
            {props.step.keepProceedYn &&
                <>
                    <div className={clsx(Cst.row, st.registerInfo)} style={{display:'block'}}> 
                        {props.step.executiveMembers.map((item, idx) => {
                            return (
                                <div className={st.row} key={idx}>
                                    <div className={st.registerTitle}>{idx === 0 && '기존 등기부'}</div>
                                    <div className={st.beforeInfo}>
                                        {item.newYn === false &&
                                        <>
                                            <div>{item.koreanName} {item.originPosition}님</div>
                                            <div>{item.englishName && `(${item.englishName})`}</div>
                                        </>
                                        }
                                    </div>
                                    <div className={st.registerTitle}>{idx === 0 && '신규 등기부'}</div>
                                    <div className={clsx(st.afterInfo, item.changeTypes.includes('사임_퇴임') ? st.delete : null)}>{item.koreanName} {item.newPosition? item.newPosition : item.originPosition}님</div>
                                    <div className={st.changeType}>
                                        {item.changeTypes.map((changeType, idx2) => {
                                            return (
                                                <span className={ExecutiveMemberChangeStepVm.setClassNameAndTitle(changeType).className} key={idx2}>
                                                    {idx2 !== 0 && ', '}{ExecutiveMemberChangeStepVm.setClassNameAndTitle(changeType).title}
                                                    {changeType === '보직변경' &&
                                                        <>
                                                            ({item.originPositionTag} &rarr; {item.newPositionTag})
                                                        </>
                                                    }
                                                    {(changeType === '변경없음' && item.descriptionType !== 'NONE') && 
                                                        <>
                                                            (등기부상 대표직위만 {item.originPositionTag} &rarr; {item.newPositionTag} 로 변경)
                                                        </>
                                                    }
                                                </span>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </>
            }
            <div className={clsx(Cst.row, st.executiveInfo, props.step.keepProceedYn ? st.keep : st.notKeep)} style={{display:'block'}}>
                {(props.step.keepProceedYn && ExecutiveMemberChangeStepVm.standbyYn) && 
                    <div className={Cst.row}> 
                        <div className={Cst.dName}>스탠바이 입력<br/>내용 일치여부</div>
                        <div className={Cst.data}>{props.step.keepProceedYn ? '네' : '아니오'}</div> 
                    </div>
                }
                {props.step.keepProceedYn && <div className={st.executiveInfoTitle}>임원 인적사항</div>}
                <div className={clsx(st.executive, props.step.keepProceedYn ? st.keep : null)}>
                    <div className={st.title}>기존임원</div>
                    <div className={st.executiveDetail}>
                        {props.step.executiveMembers.map((item, idx) => {
                            return (
                                <Fragment key={idx}>
                                    {(item.newYn === false && item.changedYn) &&
                                        <div className={st.executiveDetailInfo}>
                                            <div className={st.infoBox}>
                                                <div className={st.namePosition}>
                                                    <div className={item.changeTypes.includes('사임_퇴임') ? st.delete : null}>{item.koreanName} {item.newPosition ? item.newPosition : item.originPosition}님</div>
                                                    {props.step.keepProceedYn === false &&
                                                    <div className={st.changeTypeBox}>
                                                        {item.changeTypes.map((changeType, idx2) => {
                                                            return (
                                                                <div className={clsx(ExecutiveMemberChangeStepVm.setClassNameAndTitle(changeType).className, st.changeType)} key={idx2}>
                                                                    {ExecutiveMemberChangeStepVm.setClassNameAndTitle(changeType).title}
                                                                    {changeType === '보직변경' && 
                                                                        <div className={st.changePosition}>
                                                                            ({item.originPositionTag} &rarr; {item.newPositionTag})
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    }
                                                </div>
                                                <div className={st.detail}>
                                                    {item.birth && <div>{item.foreignerYn ? '생년월일' : '주민등록번호'} : {item.birth}</div>}
                                                    {item.foreignerYn && <div>국적 : {item.nationality}</div>}
                                                    {item.representativeYn && 
                                                    <>
                                                        <div className={st.newAddressInfo}>
                                                            <div className={st.newAddress}>주소 : {ExecutiveMemberChangeStepVm.displayAddress(item)}</div>
                                                            {ExecutiveMemberChangeStepVm.displayDetailAddress(item) &&
                                                                <div className={st.newAddressBox}>
                                                                    {item.newAddresses.map((item2) => {
                                                                        return (
                                                                            <div>
                                                                                [{item2.addressCauseDate} {item2.newAddress}]
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            }
                                                        </div>
                                                        
                                                    </>
                                                    }
                                                    {item.addressCauseDate && <div>전입신고 일자 : {item.addressCauseDate}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </Fragment>
                            )
                        })}
                    </div>
                </div>
                <div className={st.newExecutive}>
                    <div className={st.title}>신규임원</div>
                    <div className={st.newExecutiveDetail}>
                        {ExecutiveMemberChangeStepVm.displayNewRepresentative(props.step) ?
                        <>
                            {props.step.executiveMembers.map((item, idx) => {
                                return (
                                    <Fragment key={idx}>
                                        {item.newYn &&
                                            <div className={st.newExecutiveDetailInfo}>
                                                <div className={st.infoBox}>
                                                    <div className={st.namePosition}>
                                                        {item.koreanName} {item.newPosition}님
                                                        {item.englishName && <div>({item.englishName})</div>}
                                                    </div>
                                                    <div className={st.detail}>
                                                        <div>{item.foreignerYn ? '생년월일' : '주민등록번호'} : {item.birth}</div>
                                                        {item.foreignerYn && <div>국적 : {item.nationality}</div>}
                                                        {item.representativeYn && <div>주소 : {item.newAddresses[0].newAddress}</div>}
                                                        {item.farmerFishermanYn === true && <div className={st.farmerFishermanCheck}>{ExecutiveMemberChangeStepVm.farmerFisherText()}인 O</div>} 
                                                        {item.farmerFishermanYn === false && <div className={st.farmerFishermanCheck}>{ExecutiveMemberChangeStepVm.farmerFisherText()}인 X</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </Fragment>
                                )
                            })}
                        </>
                        :
                        <div className={st.noNewRepresentative}>
                            없음
                        </div>
                        }
                        
                    </div>
                </div>
                {(props.step.keepProceedYn === false && ExecutiveMemberChangeStepVm.standbyYn) && 
                    <div className={Cst.row}> 
                        <div className={Cst.dName}>스탠바이 입력<br/>내용 일치여부</div>
                        <div className={Cst.data}>{props.step.keepProceedYn ? '네' : '아니오'}</div> 
                    </div>
                }
            </div>
        </>
    ));
}

export default ExecutiveMemberChangeStep;