import { useObserver } from 'mobx-react';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import regExp from '@common/module/regExp';
import KssnVm from './KssnVm';

function Kssn(props) {
    return useObserver(() => (
        <div className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
                <div className={Qst.titleWrap}>
                    <h2 className={Qst.title}>
                        {KssnVm.displayKssnTitle(props.idx)}
                    </h2>
                    {KssnVm.displayTitleExplain() &&
                        <div className={Qst.titleExplain}>
                            현재 등기부상 대표님이 청산인이 됩니다.
                        </div>
                    }
                    <div className={Qst.titleExplain}>
                        주민등록번호는 헬프미 스마트 보안 시스템에 의하여 안전하게 보관됩니다.
                    </div>
                </div>
                <InputText placeholder="111111-1111111" style={{width:'316px'}} textType="kssn" value={props.item.kssn} onChange={(e) => props.parentVm.setKssn(e,props.idx)} name={`${props.name}[${props.idx}].kssn`} errorText={<Error name={`${props.name}[${props.idx}].kssn`} value={props.item.kssn} errorCase={{
                    required: '주민등록번호를 입력해 주세요.',
                    validate: {
                    func: regExp.kssn(props.item.kssn),
                    message: '올바른 주민등록번호를 입력해 주세요.'
                    }
                }}/>} />
            </div>
        </div>
    ));
}

export default Kssn;