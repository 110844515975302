import { useObserver } from 'mobx-react';
import st from './StandByBoardMeetingParticipantModal.module.scss';

function BoardMeetingAgreeModal(props) {
    return useObserver(() => (
        <>
            {props.agreeType === "찬성" && 
                <div className={st.Modal}>
                    <div className={st.title}>
                        <span className={st.bold}>고객님!</span> 찬성한 이사가 부족해요.
                    </div>
                    <div className={st.modalContent}>
                        출석한 이사의 과반수(=50% 초과)가 찬성해야 해요.
                    </div>
                </div>    
            }
            {props.agreeType === "인감증명서" && 
                <div className={st.Modal}>
                    <div className={st.title}>
                        <span className={st.bold}>고객님!</span> 인감증명서를 제공할 이사가 부족합니다.
                    </div>
                    <div className={st.modalContent}>
                        공증을 위하여 출석한 이사의 과반수(=50% 초과)가 인감증명서를 제공해야 합니다.
                    </div>
                </div>    
            }
        </>
    ))
}

export default BoardMeetingAgreeModal;

