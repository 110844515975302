import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled, {keyframes} from 'styled-components';
import CloseBtn from '../../atoms/Button/CloseBtn/CloseBtn';
import ModalBtn from '../../atoms/Button/ModalBtn/ModalBtn';

const Box = styled.div`
    display : flex;
    justify-content: center;
    align-items: center;
    position : fixed;
    top: 0;
    left: 0;
    z-index : 4500;
    width : 100%;
    height : 100%;
    box-sizing : border-box;
    background-color : rgba(0,0,0,0.6); 
`;

const Appear = keyframes`
from {
    opacity: 0;
    transform : translateY(20%);
    transform: scale(0.5);
}
to {
    opacity: 1;
    transform : translateY(0);
    transform: scale(1);
}
`;

const BoxWrap = styled.div`
    animation: ${Appear} 0.4s cubic-bezier(0.57, 0.11, 0.12, 1.18);
`;

const BoxContainer = styled.div`
    position: relative;
    padding: 40px;
    z-index : 5000;
    min-width : 517px;
    min-height: 130px;
    box-sizing : border-box;
    background-color : #fff;  
    border-top-right-radius: 4px;  
    border-top-left-radius: 4px;  

    ${(props) => props.btn === null && `border-radius:4px;`}

    .close {
        position: absolute;
        top: 35px;
        right: 24px;
    }
`;

const Text = styled.div`

`;

const BtnWrap = styled.div`
    display: flex;
    width: 100%;

    .modalBtn {
        &:first-child{
            border-bottom-left-radius: 4px;
        }
        &:last-child{
            border-bottom-right-radius: 4px;
        }
    }
`;

function Modal(props) {
    
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return() => {
            document.body.style.overflow = 'auto';
        }
    })

    if(props.modalContent) {
        return ReactDOM.createPortal(
            <Box>
                <BoxWrap>
                    <BoxContainer modalName={props.name} className={props.className} style={props.style} btn={props.btn}>
                        <Text>{props.modalContent}</Text>
                        {props.closeBtn && 
                            <CloseBtn className="close" use="modal" onClick={props.onClose} />
                        }
                    </BoxContainer>
                    <BtnWrap>
                        {props.btn !== null &&
                            props.btn.map((item,idx) => {
                            return (
                                <ModalBtn key={idx} className="modalBtn" btnType={item.type} size={item.size} onClick={item.event}>
                                    {item.title}
                                </ModalBtn>
                            )
                        })}
                    </BtnWrap>
                </BoxWrap>
            </Box>,
            document.getElementById('modal')
        );
    }

    return null;
};

Modal.propTypes = {
    /**
    * class명
    */
    className: PropTypes.string,
    /**
    * style 속성
    */
    style: PropTypes.object,
    /**
    * 클릭 이벤트
    */
    onClick: PropTypes.func,
    /**
    * 닫기 버튼 이벤트
    */
    onClose: PropTypes.func,
    /**
    * modal에 들어가 버튼 속성 <br/>
    * [{title: 버튼 text, <br/> type: 버튼 type, <br/> event: 버튼 클릭 이벤트}]
    */
    btn: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
    /**
    * 닫기 버튼 여부
    */
    closBtn: PropTypes.bool,
    /**
    * modal 콘텐츠
    */
    modalContent: PropTypes.object,
};

Modal.defaultProps = {
    className: null,
    style: {},
    onClick: () => {},
    onClose: () => {},
    btn: null,
    closeBtn: true,
    modalContent: null,
};

export default Modal;

