import { observable } from 'mobx';

const foreignDocumentModel = observable({
    //외국인 서류 업로드
    foreignDocuments: [],
    foreignDocumentsUploadFiles: [], //파일 업로드
    foreignDocumentsDeleteFiles: [], //파일 삭제
    setForeignDocuments(foreignDocumentsFiles) {
        if(foreignDocumentsFiles.foreignDocuments && foreignDocumentsFiles?.foreignDocuments.length !== 0) {
            this.foreignDocuments = foreignDocumentsFiles.foreignDocuments.map((foreignDocument) => {
                return {
                    fileName: foreignDocument.fileName || "",
                    fileSize: foreignDocument.fileSize ?? null,
                    id: foreignDocument.id || null,
                    url: foreignDocument.url || ""
                }
            })
        } else {
            this.foreignDocuments = [];
        }
    }
});

export default foreignDocumentModel;