import { useObserver } from 'mobx-react';
import NumOfSharesToBeIssuedVm from './NumOfSharesToBeIssuedVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import Icon from '@comComponents/atoms/Icon/Icon';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import clsx from 'clsx';
import st from './NumOfSharesToBeIssued.module.scss'
import { addCommas } from '@common/module/replaceNumber';

function NumOfSharesToBeIssued() {
    return useObserver(() => (
        <>
            <form onSubmit={(e) => {NumOfSharesToBeIssuedVm.submit(e)}}>
                <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
                {NumOfSharesToBeIssuedVm.case === 'CASE_2' &&
                    <Question>
                        <ContentBox className={Qst.spaceBetween}>
                            <div className={Qst.infoSection}>
                                <div className={Qst.titleWrap}>
                                    <h2 className={Qst.title}>
                                        회사의 <span className={Qst.bold}>기존 등기부 상태</span>를 확인해주세요.
                                    </h2>
                                </div>
                                <div className={st.numOfSharesToBeIssuedTable}>
                                    <div className={st.numOfSharesBox}>
                                        <Icon className={st.plusCircle} icon='plusCircle'/>
                                        <div className={st.onRegisterBox}>
                                            <div className={st.title}>등기부상 주식수</div>
                                            <div className={st.onRegister}>{addCommas(NumOfSharesToBeIssuedVm.numOfSharesOnRegister)}<span className={st.text}>주</span></div>
                                        </div>
                                        <div className={st.newSharesBox}>
                                            <div className={st.title}>신규 발행 주식수</div>
                                            <div className={st.newShares}>{addCommas(NumOfSharesToBeIssuedVm.numOfNewShares)}<span className={st.text}>주</span></div>
                                        </div>
                                        <div className={st.sumBox}>
                                            <div>합계</div>
                                            <div className={st.sum}>{addCommas(NumOfSharesToBeIssuedVm.sumShares())}<span className={st.text}>주</span></div>
                                        </div>
                                    </div>
                                    <div className={st.arrowBox}><Icon className={st.icon} icon='greater_large'/></div>
                                    <div className={st.numOfSharesToBeIssuedBox}>
                                        <div>등기부상<br/>발행예정<br/>주식수</div>
                                        <div className={st.numOfSharesToBeIssued}>{addCommas(NumOfSharesToBeIssuedVm.numOfSharesToBeIssuedOnRegister)}<span className={st.text}>주</span></div>
                                    </div>
                                </div>
                                <div className={st.tableExplain}>
                                    {addCommas(NumOfSharesToBeIssuedVm.sharesCalc())}주 부족하므로 변경등기 필요
                                </div>
                            </div>
                            <div className={Qst.qnaSection}>
                                <Qna tip='발행예정주식수 입력' className={Qst.qnaTitle}>
                                    <div className={Qst.qnaBox}>
                                        <QText>발행예정주식수란?</QText>
                                        <div className="answerBox">
                                            <Answer className={Qst.answer}>발행예정주식수란 미래에 새로운 주식을 발행할 수 있는 상한선입니다.</Answer>
                                            <p className={Qst.explain}>
                                                상법상 회사의 채권자나 주주들이 장래에 회사의 주식수가 얼마나 늘어날지 미리 짐작할 수 있도록 발행예정주식수를 미리 정해두도록 하고 있고, 등기부에도 나와 있습니다.
                                            </p>
                                        </div>
                                    </div>
                                    <div className={Qst.qnaBox}>
                                        <QText>발행예정주식수를 왜 변경해야 하나요?</QText>
                                        <div className="answerBox">
                                            <Answer className={Qst.answer}>이미 발행된 주식수와 새로 발행할 주식수의 합이 발행예정주식수를 초과하면, 등기를 허용하지 않기 때문에 변경해야 합니다.</Answer>
                                        </div>
                                    </div>
                                    <div className={Qst.qnaBox}>
                                        <QText>발행예정주식수 변경 절차는 어떻게 되나요?</QText>
                                        <div className="answerBox">
                                            <Answer className={Qst.answer}>발행예정주식수 변경은 정관과 등기부를 변경해야 하는 것이기 때문에, 주주총회 특별결의가 필요합니다.</Answer>
                                        </div>
                                    </div>
                                </Qna>
                            </div>
                        </ContentBox>
                    </Question>
                }
                <Question>
                    <ContentBox className={Qst.spaceBetween}>
                        <div className={Qst.infoSection}>
                            <div className={Qst.titleWrap}>
                                <h2 className={Qst.title}>
                                    <span className={Qst.bold}>발행예정주식수</span>를 <span className={Qst.bold}>어떻게 변경</span>해 드릴까요?
                                </h2>
                            </div>
                            <ul className={Qst.answerList}>
                                <li><Radio className={Qst.answerLabel} name="sharesToBeIssuedType" value="HELP_ME" activeBackground checked={NumOfSharesToBeIssuedVm.sharesToBeIssuedType === "HELP_ME"} onChange={NumOfSharesToBeIssuedVm.setSharesToBeIssuedType} >헬프미에서 적절한 숫자로 알아서 변경해주세요.</Radio></li>
                                <li><Radio className={Qst.answerLabel} name="sharesToBeIssuedType" value="DIRECT" activeBackground checked={NumOfSharesToBeIssuedVm.sharesToBeIssuedType === "DIRECT"} onChange={NumOfSharesToBeIssuedVm.setSharesToBeIssuedType} >직접 입력하겠습니다.</Radio></li>
                            </ul>
                            <Error name="sharesToBeIssuedType" value={NumOfSharesToBeIssuedVm.sharesToBeIssuedType} errorCase={{required: '발행예정주식수 변경 방법을 선택해 주세요.'}}/>
                        </div>
                        <div className={Qst.qnaSection}>
                            {NumOfSharesToBeIssuedVm.case !== 'CASE_3' ? 
                                <Qna className={Qst.noTitle}>
                                    <div className={Qst.qnaBox}>
                                        <QText>발행예정주식수의 한도가 있나요?</QText>
                                        <div className="answerBox">
                                            <Answer className={Qst.answer}>발행예정주식수의 한도는 없습니다.</Answer>
                                            <p className={Qst.explain}>
                                                총 주식수의 1,000 ~ 10,000배 정도를 입력하시면 됩니다.
                                            </p>
                                        </div>
                                    </div>
                                </Qna>
                                :
                                <Qna tip='발행예정주식수 입력' className={Qst.qnaTitle}>
                                    <div className={Qst.qnaBox}>
                                        <QText>발행예정주식수란?</QText>
                                        <div className="answerBox">
                                            <Answer className={Qst.answer}>발행예정주식수란 미래에 새로운 주식을 발행할 수 있는 상한선입니다.</Answer>
                                            <p className={Qst.explain}>
                                                상법상 회사의 채권자나 주주들이 장래에 회사의 주식수가 얼마나 늘어날지 미리 짐작할 수 있도록 발행예정주식수를 미리 정해두도록 하고 있고, 등기부에도 나와 있습니다.
                                            </p>
                                        </div>
                                    </div>
                                    <div className={Qst.qnaBox}>
                                        <QText>발행예정주식수를 왜 변경해야 하나요?</QText>
                                        <div className="answerBox">
                                            <Answer className={Qst.answer}>이미 발행된 주식수와 새로 발행할 주식수의 합이 발행예정주식수를 초과하면, 등기를 허용하지 않기 때문에 변경해야 합니다.</Answer>
                                        </div>
                                    </div>
                                    <div className={Qst.qnaBox}>
                                        <QText>발행예정주식수 변경 절차는 어떻게 되나요?</QText>
                                        <div className="answerBox">
                                            <Answer className={Qst.answer}>발행예정주식수 변경은 정관과 등기부를 변경해야 하는 것이기 때문에, 주주총회 특별결의가 필요합니다.</Answer>
                                        </div>
                                    </div>
                                    <div className={Qst.qnaBox}>
                                        <QText>발행예정주식수의 한도가 있나요?</QText>
                                        <div className="answerBox">
                                            <Answer className={Qst.answer}>발행예정주식수의 한도는 없습니다.</Answer>
                                            <p className={Qst.explain}>
                                                총 주식수의 1,000 ~ 10,000배 정도를 입력하시면 됩니다.
                                            </p>
                                        </div>
                                    </div>
                                </Qna>
                            }
                        </div>
                    </ContentBox>
                </Question>
                {NumOfSharesToBeIssuedVm.sharesToBeIssuedType === "DIRECT" && 
                    <Question>
                        <ContentBox className={Qst.spaceBetween}>
                            <div className={Qst.infoSection}>
                                <div className={Qst.titleWrap}>
                                    <h2 className={Qst.title}>
                                        <span className={Qst.bold}>발행예정주식수</span>를 <span className={Qst.bold}>입력</span>해 주세요.
                                    </h2>
                                </div>
                                <div className={Qst.rowWrap}>
                                    <InputText className={Qst.inputText} placeholder="0" placeholderAlign="right" style={{width: '383px'}} name="newNumOfSharesToBeIssued"  value={addCommas(NumOfSharesToBeIssuedVm.newNumOfSharesToBeIssued)} onChange={(e) => NumOfSharesToBeIssuedVm.setNewNumOfSharesToBeIssued(e)} errorText={<Error name="newNumOfSharesToBeIssued" value={NumOfSharesToBeIssuedVm.newNumOfSharesToBeIssued} errorCase={{
                                        required:'발행예정주식수를 입력해 주세요.',
                                        validate: {
                                            func: NumOfSharesToBeIssuedVm.newNumOfSharesToBeIssuedValidation(NumOfSharesToBeIssuedVm.newNumOfSharesToBeIssued),
                                            message: '올바른 발행예정주식수를 입력해 주세요.'
                                        }
                                    }}/>} 
                                    />
                                    <div className={st.sharesText}>주</div> 
                                </div>
                            </div>
                        </ContentBox>
                    </Question>
                }
            </form>
        </>
    ));
}

export default NumOfSharesToBeIssued;