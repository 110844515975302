import { toJS } from 'mobx';
import { Fragment } from 'react';
import { useObserver } from 'mobx-react';
import st from '../StandbyFileUpload/StandbyFileUpload.module.scss';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import Upload from '@comComponents/molecules/FileUpload/FileUpload';
import clsx from 'clsx';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';

function TRAccount(props) {
    return useObserver(() => (
        <Question>
            <ContentBox className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    {props.file.details.map((detail, idx2) => (
                        <Fragment key={idx2}>
                            {detail.fileType === '가수금_계정별원장' &&
                                <>
                                    <div className={Qst.titleWrap}>
                                        <h2 className={Qst.title}>
                                            <span className={Qst.bold}>가수금 계정별원장</span>을 업로드해 주세요.
                                        </h2>
                                    </div>
                                    <Badge className={st.ledgerBadge} title="예시" type="example" border={false}>
                                        <img className={st.ledgerBadgeImg} src="/images/ledgerAccount.png"
                                        alt="계정별원장 예시" />
                                    </Badge>
                                    <Upload id={detail.fileType} className={st.fileUpload} multiple accept=".docx, .hwp, .pdf"
                                    model={props.parentVm.fileGroupTypes[props.idx].details[idx2].files}
                                    uploadModel={props.parentVm.fileGroupTypes[props.idx].details[idx2].uploadFiles}
                                    deleteModel={props.parentVm.deleteIds}
                                    files={toJS(props.parentVm.files(props.idx, idx2))} uploadFiles={toJS(props.parentVm.uploadFiles(props.idx, idx2))}       
                                    >
                                        <span className={st.bold}>여기</span>를 눌러 업로드해 주세요.
                                    </Upload>
                                    {(props.parentVm.state.errorCheckYn && props.parentVm.displayErrorText(detail.fileType)) && <ErrorText>가수금 계정별원장을 업로드해 주세요.</ErrorText>}
                                </> 
                            }
                        </Fragment>
                    ))}
                </div>
                <div className={Qst.qnaSection}>
                    <Qna className={Qst.noTitle}>
                        <div className={Qst.qnaBox}>
                            <QText>가수금 계정별 원장이 무엇인가요?</QText>
                            <div className="answerBox">
                                <Answer className={Qst.answer}>회계장부 중 가수금의 내역이 나와있는 원장을 말합니다.</Answer>
                                <div className={Qst.explain}>
                                    담당 세무사에게 가수금 계정별 원장을 전달해달라고 이야기하시면 됩니다.
                                </div>
                            </div>
                        </div>
                    </Qna>
                </div>
            </ContentBox>
        </Question>
    ));
}

export default TRAccount;