import './App.scss';
import { useObserver } from 'mobx-react';
import { useCallback, useEffect } from 'react';
import AppVm from './AppVm';
import { Route, withRouter } from "react-router-dom";
import FinalSubmission from "@pages/Establish/FinalSubmission/FinalSubmission";
import ConfirmAndGuidance from "@pages/Establish/ConfirmAndGuidance/ConfirmAndGuidance";
import BalanceCertificate from "@pages/BalanceCertificate/BalanceCertificate";
import MinorDocumentUpload from "@pages/MinorDocumentUpload/MinorDocumentUpload";
import AgriculturalFisheryDocumentUpload from "@pages/AgriculturalFisheryDocumentUpload/AgriculturalFisheryDocumentUpload";
import Modal from '@comComponents/molecules/Modal/Modal';
import Dialog from '@comComponents/molecules/Dialog/Dialog';
import {cancelBtn} from "@model/dialogModel";
import ForeignDocument from "@pages/ForeignDocument/ForeignDocument";
import ChangeRegistration from "@pages/ChangeRegistration/ChangeRegistration";
import Establishment from "@pages/Establish/Establishment"
import AdminFinalCheckView from "@pages/Establish/AdminFinalCheckView/AdminFinalCheckView";
import ChangeRegistrationAdminFinalCheckView from "@pages/ChangeRegistration/AdminFinalCheckView/AdminFinalCheckView";
import "react-datepicker/dist/react-datepicker.css";

function App({history}) {

  useEffect(() => {
    AppVm.lunchService();
  },[])


  useEffect(() => {
    AppVm.setPageState();
    window.onbeforeunload = function() {
      window.scrollTo(0, 0);
    }
    window.scrollTo(0, 0);
  }, [history.location.pathname])

  const onKeydown = useCallback((e) => {
    if(AppVm.dialogType === 'confirm' && e.code === 'Escape') {
      cancelBtn();
    }
  }, []);

  useEffect(() => { //confirm창이 생겼을 때, esc에 대한 이벤트 리스너
    if(AppVm.dialogType) {
      window.addEventListener('keydown', onKeydown);
    } else if(AppVm.dialogType === null) {
      window.removeEventListener('keydown', onKeydown);
    }
  },[AppVm.dialogType])

  return useObserver(() => {
      if(AppVm.isEstablishment === true) {
        return <div id="app"><Establishment/><AppCommon/></div>
      }else if(AppVm.isEstablishment === false) {
        return <div id="app"><ChangeRegistration/><AppCommon/></div>
      }else{
        return  <></>
      }
    });
}

function AppCommon() {
  return useObserver(() => (
    <>
      {AppVm.modalContent &&
        <Modal modalContent={AppVm.modalContent} onClose={() => AppVm.modalClose()} btn={AppVm.btn} closeBtn={AppVm.closeBtn}/>
      }
      {AppVm.dialogType &&
        <Dialog type={AppVm.dialogType} content={AppVm.dialogContent} btn={AppVm.dialogBtn} />
      }
      {AppVm.isStep === false && (AppVm.userToken && AppVm.smartformId) &&
          <>
            {/* 설립 최종 제출 페이지 */}
            <Route path="/establish" component={FinalSubmission}></Route>
            {/* 견적확인 및 절차안내 */}
            <Route path="/establish/ConfirmAndGuidance" component={ConfirmAndGuidance}></Route>
            {/* 잔고 증명서 업로드 */}
            <Route path="/BalanceCertificate" component={BalanceCertificate}></Route>
            {/* 미성년자 서류 업로드 */}
            <Route path="/MinorDocumentUpload" component={MinorDocumentUpload}></Route>
            {/* 농업 어업 서류 업로드 */}
            <Route path="/AgriculturalFisheryDocumentUpload" component={AgriculturalFisheryDocumentUpload}></Route>
            {/* 외국인 서류 업로드 */}
            <Route path="/ForeignDocument" component={ForeignDocument}></Route>
            {/* 설립 폼2 어드민 확인 뷰  */}
            <Route path="/smartform/managerMode" component={AdminFinalCheckView}></Route>
            {/* 변경등기 폼2 어드민 확인 뷰  */}
            <Route path="/changeRegistration/smartform/managerMode" component={ChangeRegistrationAdminFinalCheckView}></Route>
          </>
        }
    </>
  ));
}

export default withRouter(App);
