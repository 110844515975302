import st from './SingleQuestionHeader.module.scss';
import { useObserver } from 'mobx-react';
import ContentBox from '@commonComponents/ContentBox/ContentBox';

function QuestionHeader(props) {
    return useObserver(() => (
    <header className={st.questionHeader}>
        <div className={st.content}>
            <div className={st.leftBg}></div>
            <div className={st.rightBg}></div>
            <ContentBox>
                <div className={st.titleBox}>
                    {props.children}
                </div>
            </ContentBox>
        </div>
    </header>
    ));
}

export default QuestionHeader;
