import { useObserver } from 'mobx-react';
import BranchOfficeRelocateVm from './BranchOfficeRelocateVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './BranchOfficeRelocate.module.scss';
import clsx from 'clsx';
import Check from '@comComponents/atoms/Input/Check/Check';
import TextBtn from '@comComponents/atoms/Button/TextBtn/TextBtn';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import Icon from '@comComponents/atoms/Icon/Icon';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import SquareBtn from '@comComponents/atoms/Button/SquareBtn/SquareBtn';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import regExp from '@common/module/regExp';
import SearchAddress from '@comComponents/atoms/SearchAddress/SearchAddress';
import { Fragment } from 'react';
import Address from '../Address/Address';

function BranchOfficeRelocate() {
    return useObserver(() => (
        <form onSubmit={(e) => BranchOfficeRelocateVm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
                <Question>
                    <ContentBox className={Qst.spaceBetween}>
                        <div className={Qst.infoSection}>
                            <div className={Qst.titleWrap}>
                                <h2 className={Qst.title}>
                                    아래는 <span className={Qst.bold}>등기부상 지점 목록</span>입니다.<br/><span className={Qst.bold}>주소를 이전할 지점</span>을 <span className={Qst.bold}>모두 선택</span>해 주세요.
                                </h2>
                                <div className={Qst.titleExplain}>고객님이 견적단계에서 말씀하신 이전할 지점이 미리 선택되어 있습니다.</div>
                                <div className={Qst.titleExplain}>만약 견적단계에서 말씀하신 이전할 지점 숫자 또는 이전하는 위치가 달라지는 경우, 공과금 및 수수료가 달라질 수 있습니다.</div>
                            </div>
                            <div className={st.checkHeader}>
                                <div>
                                    <Check className={st.checkAll} style={{fontSize:'17px', fontWeight:'500', top:'1px', whiteSpace:'pre'}} checked={BranchOfficeRelocateVm.checkAll} onChange={(e) => BranchOfficeRelocateVm.setCheckAll(e)}>전체선택({BranchOfficeRelocateVm.checkCount}/{BranchOfficeRelocateVm.branchOffices.length})</Check>
                                </div>
                                <div>
                                    <TextBtn className={st.unCheckAll} onClick={() => BranchOfficeRelocateVm.setCheckAll(false)}>선택해제</TextBtn>
                                </div>
                            </div>
                            <ul className={st.branchAddressList}>
                                {BranchOfficeRelocateVm.branchOffices.map((item, idx) => {
                                    return (
                                        <Fragment key={idx}>
                                            <input type="hidden" name={`branchOffices[${idx}].id`} value={item.id}/>
                                            <li className={item.checkedYn ? st.checkLi : null}>
                                                <Check style={{fontSize:"18px", lineHeight:'1.3'}} name={`branchOffices[${idx}].checkedYn`} value={item.checkedYn} checked={item.checkedYn === true} onChange={(e) => BranchOfficeRelocateVm.setCheckedYn(e, idx)}>{item.originAddress}</Check>
                                            </li>
                                        </Fragment>
                                        
                                    )
                                })}
                            </ul>
                            {BranchOfficeRelocateVm.checkedYnError() && <div><ErrorText>이전할 지점을 선택해 주세요.</ErrorText></div>}
                        </div>
                    </ContentBox>
                </Question>
                {BranchOfficeRelocateVm.branchOffices.map((item,idx) => (
                    <Fragment key={idx}>
                        {item.checkedYn && 
                            <Question>
                                <ContentBox>
                                    <Address model={BranchOfficeRelocateVm.branchOffices[idx]} name={`branchOffices[${idx}]`} originAddress={item.originAddress} />
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={Qst.title}>
                                                    지점 주소 <span className={Qst.bold}>이전 일자</span>를 입력해 주세요.
                                                </h2>
                                            </div>
                                            <Badge className={Qst.registrationBadge} title="예시" type="example" border={false}>
                                                <div className={Qst.registartionBadgeBox}>
                                                    <img className={Qst.registrationBadgeImg} src="/images/registration_date.png" alt="지점 주소 이전 일자 예시" />
                                                    <div className={Qst.registrationBadgeTextBox}>
                                                        <div className={Qst.registrationBadgeText}>
                                                            <div className={Qst.text}>
                                                                <span className={Qst.boldBlue}>안정적으로 등기신청하기 위해 이전 날짜는 오늘(질문지 제출일)을 기준으로,<br/>1주 전 ~ 3주 이내로 입력해 주세요.</span>
                                                            </div>
                                                            <div className={Qst.text}>
                                                                <span className={Qst.bold}>예시) 오늘(질문 제출일)이 3월 23일</span>이라면, <span className={Qst.bold}>안정적으로 등기신청 가능한 주소 이전 날짜는 3월 16일 ~ 4월 12일</span> 입니다.
                                                            </div>
                                                        </div>
                                                        <div className={Qst.registrationBadgeTextExplain}>
                                                            특별한 사정이 없다면, <span className={Qst.bold}>오늘 날짜(질문 제출일)로 입력하는 것을 추천</span>드립니다.<br/>
                                                            입력한 주소 이전 날짜로 부터 2주 이내 접수해야 과태료가 부가되지 않습니다.
                                                        </div>
                                                    </div>     
                                                </div>                 
                                            </Badge>
                                            <div>
                                                <div className={Qst.birthWrap}>
                                                    <InputText style={{width:'130px'}} placeholder="0000" name={`branchOffices[${idx}].year`} value={item.year} onChange={(e) => BranchOfficeRelocateVm.setYear(e, idx)} maxLength="4" />
                                                    <span className={Qst.birthText}>년</span>
                                                </div>
                                                <div className={Qst.birthWrap}>
                                                    <InputText style={{width:'108px'}} placeholder="00" name={`branchOffices[${idx}].month`} value={item.month} onChange={(e) => BranchOfficeRelocateVm.setMonth(e, idx)} maxLength="2" onBlur={(e) => BranchOfficeRelocateVm.dateBlur(e, idx, 'month')} />
                                                    <span className={Qst.birthText}>월</span>
                                                </div>
                                                <div className={Qst.birthWrap}>
                                                    <InputText style={{width:'108px'}} placeholder="00" name={`branchOffices[${idx}].day`} value={item.day} onChange={(e) => BranchOfficeRelocateVm.setDay(e, idx)} maxLength="2" onBlur={(e) => BranchOfficeRelocateVm.dateBlur(e, idx, 'day')} />
                                                    <span className={Qst.birthText}>일</span>
                                                </div>
                                            </div>
                                            <div style={{marginTop:'-27px'}}>
                                                <Error name={`branchOffices[${idx}].year`} value={item.year} 
                                                errorCase={{
                                                    required:'연도를 입력해 주세요.',
                                                    pattern: {
                                                    value: regExp.year().test(item.year),
                                                    message: '올바른 연도를 입력해 주세요'               
                                                    }
                                                }}
                                                />
                                                <Error name={`branchOffices[${idx}].month`} value={item.month} 
                                                errorCase={{
                                                    required: BranchOfficeRelocateVm.displayBirthErrorMessage(idx, 'month') ? '월을 입력해 주세요.' : null,
                                                    pattern: {
                                                    value: BranchOfficeRelocateVm.displayBirthErrorMessage(idx, 'month') ? regExp.month().test(item.month) : null,
                                                    message: '올바른 월을 입력해 주세요'               
                                                    }
                                                }}
                                                />
                                                <Error name={`branchOffices[${idx}].day`} value={item.day} 
                                                errorCase={{
                                                    required: BranchOfficeRelocateVm.displayBirthErrorMessage(idx, 'day') ? '일(날짜)을 입력해 주세요.' : null,
                                                    pattern: {
                                                    value: BranchOfficeRelocateVm.displayBirthErrorMessage(idx, 'day') ? regExp.day().test(item.day) : null,
                                                    message: '올바른 일(날짜)을 입력해 주세요.'           
                                                    }
                                                }}
                                                />
                                            </div>
                                        </div>
                                        <div className={Qst.qnaSection}>
                                            <Qna className={Qst.noTitle}>
                                                <div className={Qst.qnaBox}>
                                                    <QText>주소 이전 일자는 임(전)대차 계약서상 일자랑 같아야<br/>하나요?</QText>
                                                    <div className="answerBox">
                                                        <Answer className={Qst.answer}>주소 이전 일자는 임(전)대차 계약서상 일자와 1~2달 차이가 나도 괜찮습니다.</Answer>
                                                        <p className={Qst.explain}>
                                                            미리 임차를 한 후, 나중에 이사를 하는 경우도 충분히 존재할 수 있기 때문에, 주소 이전 일자는 임(전)대차 계약서상 일자와 1~2달 차이가 나도 괜찮습니다. 단 임(전)대차 계약서의 일자가 등기부에 꼭 기재되어야 하는 사정이 있는 경우, 임(전)대차 계약서의 일자를 입력해주세요. 
                                                        </p>
                                                    </div>
                                                </div>
                                            </Qna>
                                        </div>
                                    </div>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={Qst.title}>
                                                    이전 후 사용할 지점 명칭을 입력해 주세요. (ex 은평지점)
                                                </h2>
                                                <div className={Qst.titleExplain}>
                                                    지점명칭을 변경하지 않는 경우, 기존 지점명칭을 입력해 주세요.
                                                </div>
                                                <div className={Qst.titleExplain}>
                                                    지점명이 없는 경우 "없음"이라고 입력해 주세요.
                                                </div>
                                            </div>
                                            <InputText style={{width:'560px'}} name={`branchOffices[${idx}].officeName`} value={item.officeName} onChange={(e) => BranchOfficeRelocateVm.setOfficeName(e, idx)} errorIconNon errorText={<Error name={`branchOffices[${idx}].officeName`} value={item.officeName} errorCase={{required:'지점 명칭을 입력해 주세요. 지점명이 없는 경우 "없음"이라고 입력해 주세요.'}}/>}/>
                                        </div>
                                        <div className={Qst.qnaSection}>
                                            <Qna className={Qst.noTitle}>
                                                <div className={Qst.qnaBox}>
                                                    <QText>지점명칭은 어떻게 정하는 편이 좋나요?</QText>
                                                    <div className="answerBox">
                                                        <Answer className={Qst.answer}>지점명칭은 지역명을 넣되, 법인명을 넣지 않는 편이 좋습니다.</Answer>
                                                        <p className={Qst.explain}>
                                                            예를 들어 강남구에 있던 지점을 은평구로 이전하는 경우, 강남지점 → 은평지점으로 명칭을 변경하는 편을 추천드립니다. 다만 지점명칭에 법인명을 넣지 않는 것을 추천드립니다. 추후 법인명을 변경하게 되는 경우, 지점명칭을 모두 변경해야 하는데, 그 경우 비용이 많이 들고 번거로울 수 있기 때문입니다. 
                                                        </p>
                                                    </div>
                                                </div>
                                            </Qna>
                                        </div>
                                    </div>
                                </ContentBox>
                            </Question>
                        }
                    </Fragment>
                ))}
        </form>
    ));
}
export default BranchOfficeRelocate;