import pageStepModel, {pageRouter, pageDepth} from '@model/pageStepModel';
import {runInAction, observable} from 'mobx';
import { PropTypes } from 'mobx-react';
import smartformAppModel from '@model/smartformAppModel';

class viewModel {
    get saveState() {
        return pageStepModel.saveState;
    }
    pre() {
        runInAction(() => {
            let moveStep = '';
            let movePage = '';
            const steps = pageDepth.cause[this.cause].steps;
            steps.forEach((step, index) => {
                if(step.code === this.now.step) {
                    step.pages.forEach((page, index2) => {
                        if(page.code === this.now.page) {
                            if(index2 !== 0) {
                                moveStep = step.code;
                                movePage = step.pages[index2-1].code;
                            }else{
                                moveStep = steps[index-1].code;
                                movePage = steps[index-1].pages[steps[index-1].pages.length-1].code;
                            }
                        }
                    })
                }
            })
            pageRouter.replace(`/step/${this.cause}/${moveStep}/${movePage}`);
        })
    }
    get isLoadData() {
        return smartformAppModel.isLoadData;
    }
    get isSumbitData() {
        return smartformAppModel.isSubmitData;
    }
    nextBtnEvent(isSumbitData) {
        if(isSumbitData) {
            document.getElementById('stepForm').click()
        }
    }
    get canPre() {
        let stepIndex = this.stepIdx;
        let pageIndex = this.pageIdx;
        if(stepIndex === 0 && pageIndex === 0) {
            return false;
        }
        return true;
    }
    get canNext() {
        let stepIndex = this.stepIdx;
        let pageIndex = this.pageIdx;
        const steps = pageDepth.cause[this.cause].steps;
        const stepsLength = steps.length;
        if(stepsLength-1 === stepIndex && steps[stepsLength-1].pages.length-1 === pageIndex) {
            return false;
        }
        return true;
    }
    get cause() {
        return pageStepModel.cause;
    }
    get now() {
        return {
            step : pageStepModel.nowStep,
            page : pageStepModel.nowPage
        }
    }
    get stepIdx() {
        let stepIdx = 0;
        pageDepth.cause[this.cause].steps.forEach((step, index) => {
            if(step.code === this.now.step){
                stepIdx = index;
            }
        })
        return stepIdx;
    }
    get pageIdx() {
        let pageIdx = 0;
        const steps = pageDepth.cause[this.cause].steps;
        steps.forEach((step, index) => {
            if(step.code === this.now.step) {
                step.pages.forEach((page, index2) => {
                    if(page.code === this.now.page) {
                        pageIdx = index2;
                    }
                })
            }
        })
        return pageIdx;
    }
    get progressItem() {
        return pageDepth.cause[this.cause].steps.map((item) => {
            let title = item.title;
            if(item.code === this.now.step) {
                item.pages.forEach((item2, index2) => {
                    if(item2.code === this.now.page) {
                        title += ` ( ${index2+1} / ${item.pages.length} )`
                    }
                })
            }
            return {
                title : title
            }
        })
    }
    get pageName() {
        let pageName = "";
        pageDepth.cause[this.cause].steps.forEach((step) => {
            if(step.code === this.now.step) {
                step.pages.forEach((page) => {
                    if(page.code === this.now.page) {
                        pageName = page.title;
                    }
                })
            }
        })
        return pageName;
    }
    get pageCnt() {
        let pageCnt = 0;
        pageDepth.cause[this.cause].steps.forEach((step) => {
            step.pages.forEach(() => {
                pageCnt++;
            })
        })
        return pageCnt;
    }
    get pageStackIdx() {
        let stack = 0;
        let pageStackIdx = 0;
        pageDepth.cause[this.cause].steps.forEach((step) => {
            step.pages.forEach((page) => {
                stack++;
                if(page.code === this.now.page) {
                    pageStackIdx = stack;
                }
            })
        })
        return pageStackIdx;
    }
}
export default new viewModel();
