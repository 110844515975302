import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DaumPostcode from 'react-daum-postcode';
const Wrap = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    background-color : rgba(0,0,0,0.6);
`;

const Daum_Postcode = styled(DaumPostcode)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
const themeObj = {
    //bgColor: "", //바탕 배경색
    searchBgColor: "#006ac3", //검색창 배경색
    //contentBgColor: "", //본문 배경색(검색결과,결과없음,첫화면,검색서제스트)
    //pageBgColor: "", //페이지 배경색
    //textColor: "", //기본 글자색
    queryTextColor: "#FFFFFF" //검색창 글자색
    //postcodeTextColor: "", //우편번호 글자색
    //emphTextColor: "", //강조 글자색
    //outlineColor: "", //테두리
};
function SearchAddress(props) {
    return (
        <Wrap onClick={() => props.onClose()}>
            <Daum_Postcode width="730px" theme={themeObj} animation onComplete={(data) => {props.onComplete(data)}}></Daum_Postcode>
        </Wrap>
    );
};

SearchAddress.propTypes = {
    /**
     * 검색완료했을때 해당함수의 첫번째 파라미터로 address 관련 객체가 넘어옴
    */
    onComplete : PropTypes.func,
    /**
     * 검은배경누르거나 esc누를때 실행되는함수
    */
    onClose : PropTypes.func
};

SearchAddress.defaultProps = {
    onComplete : () => {alert('onComplete함수가 정의되지 않았습니다.')},
    onClose : () => {}
};

export default SearchAddress;