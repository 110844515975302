import { runInAction, observable, toJS } from "mobx";
import StandByUpdateStepModel from '@model/changeRegistration/StandByUpdateStepModel';
import smartformAppModel from "@model/smartformAppModel";
import regExp from '@common/module/regExp';
import formDataToJson from '@common/module/submit';
import stepService from "@service/stepService";

class StandByUpdateVm {
    submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error === false) {
            let param = formDataToJson(e.target).data;
            stepService.saveStep(param);
        }
    }
    get updateYn() {
        return StandByUpdateStepModel.updateYn;
    }
    setUpdateYn(e) {
        runInAction(() => {
            StandByUpdateStepModel.updateYn = e.target.value === "true";
        })
    }
}
export default new StandByUpdateVm();