import st from './CompanySizeAlertModal.module.scss';
import { runInAction } from "mobx";
import portalModel from '@model/portalModel';

const modal = {
    modalContent: 
        <div className={st.Modal}>
            <div className={st.title}>
                <span className={st.bold}>주식회사</span>의 경우, <span className={st.bold}>1명으로는 설립이 어렵습니다.</span> 1인으로 법인운영을 희망하신다면, <span className={st.bold}>2명을 선택</span>하신 후, <span className={st.bold}>1명은 설립 직후 사임등기</span> 신청을 해 주세요.
            </div>
            <div className={st.modalContent}>
                <p className={st.content}>
                    주식회사 설립에 1명만 참여한다면, 상법상 발기인총회에서 조사보고 역할을 맡기기 위하여 공증변호사를 선임해야 하고, 최소 160만 원 이상이 추가됩니다. 따라서 2명으로 주식회사 운영을 하는 경우가 많지만, 꼭 1명으로 주식회사 운영을 희망하신다면, 일단 2명이 참여해서 법인을 설립한 후, 1명은 즉시 사임등기를 신청해 주세요. <br/>(사임등기 비용 별도 - 세금 65,420원 / 수수료 <s>119,000</s>원 &rarr; 89,000원 / 부가세 별도)
                </p>
            </div>
        </div>
    ,
    btn: [{title: '확인', type: 'positive', event: ()=> {portalModel.setModalClose()}}]
}

export default modal;