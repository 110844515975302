import { useObserver } from 'mobx-react';
import vm from './ConvertibleBondDepositProofVm';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './ConvertibleBondDepositProof.module.scss';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';

function ConvertibleBondDepositProof() {
    return useObserver(() => (
        <form onSubmit={(e) => vm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            <Question>
                <ContentBox className={Qst.spaceBetween}>
                    <div className={Qst.infoSection}>
                        <div className={Qst.titleWrap}>
                            <h2 className={Qst.title}>사채대금 입금을 증명할 방법을 선택해주세요.</h2>
                        </div>
                        <ul className={Qst.answerList}>
                            <li>
                                <Radio activeBackground className={Qst.answerLabel} value="잔고증명서" checked={vm.convertibleBondDepositProofType === "잔고증명서"} name="convertibleBondDepositProofType" onChange={(e) => vm.setConvertibleBondDepositProofType(e)}><span className={st.radioImportant}>
                                    잔고증명서</span> 제출<span className={st.radioTag}>추천</span>
                                </Radio>
                            </li>
                            <li>
                                <Radio activeBackground className={Qst.answerLabel} value="사채금납입증명서" checked={vm.convertibleBondDepositProofType === "사채금납입증명서"} name="convertibleBondDepositProofType" onChange={(e) => vm.setConvertibleBondDepositProofType(e)}>
                                    <span className={st.radioImportant}>사채금납입증명서</span>에 <span className={st.radioImportant}>법인인감 날인</span>하여 제출
                                </Radio>
                            </li>
                        </ul>
                        <Error name="convertibleBondDepositProofType" value={vm.convertibleBondDepositProofType} errorCase={{required: `신주발행 절차를 선택해 주세요.`}}/>
                    </div>
                    <div className={Qst.qnaSection}>
                        <Qna tip="전환사채인수인" className={Qst.qnaTitle}>   
                            <div className={Qst.qnaBox}>
                                <QText>잔고증명서 vs 사채금납입증명서 중 어떤 방법이 나을까요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>가급적 잔고증명서 제출을 추천드립니다.</Answer>
                                    <div className={Qst.explain}>
                                        원칙적으로 두 가지 방법을 모두 사용할 수 있습니다. 하지만 사채금납입증명서를 제출하게 되면, 계약서에 기재된 납입일자로 전환사채 발행을 진행하게 됩니다. 그 경우 실제 입금일자와 전환사채 발행일이 일치하지 않아서, 간혹 경정등기를 진행하는 경우가 생깁니다. 따라서 잔고증명서 제출을 추천드립니다.
                                    </div>
                                </div>
                            </div>
                        </Qna>
                    </div>
                </ContentBox>
            </Question>
        </form>
    ));
}
export default ConvertibleBondDepositProof;