import ConvertibleBondStepModel from '@model/changeRegistration/ConvertibleBondStepModel';
import stepService from "@service/stepService";
import formDataToJson from '@common/module/submit';
import { runInAction } from 'mobx';
import { removeCommas, onlyNumber} from '@common/module/replaceNumber';

class ConvertibleBondAcquisitionAmountVm {
    get convertibleBondAcquisitionAmountInfos() {
        return ConvertibleBondStepModel.convertibleBondAcquisitionAmountInfos;
    }
    get sum() {
        let sum = 0;
        ConvertibleBondStepModel.convertibleBondAcquisitionAmountInfos.forEach((data) => {
            sum = sum + Number(data.acquisitionAmount);
        })
        return sum;
    }
    setAcquisitionAmount(e, idx) {
        runInAction(() => {
            ConvertibleBondStepModel.convertibleBondAcquisitionAmountInfos[idx].acquisitionAmount = onlyNumber(removeCommas(e.target.value));
        })
    }
    submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            param.convertibleBondAcquisitionAmountInfos.forEach((item) => {
                item.acquisitionAmount = removeCommas(item.acquisitionAmount);
            })
            stepService.saveStep(param);
        }
    }
} 

export default new ConvertibleBondAcquisitionAmountVm();