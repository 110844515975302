import { useObserver } from 'mobx-react';
import { Fragment } from 'react';
import CapitalIncreaseVm from './CapitalIncreaseVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import st from './CapitalIncrease.module.scss'
import clsx from 'clsx';
import { addCommas } from '@common/module/replaceNumber';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import Select from '@comComponents/atoms/Input/Select/Select';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import PlusBtn from '@comComponents/atoms/Button/PlusBtn/PlusBtn';
import MinusBtn from '@comComponents/atoms/Button/MinusBtn/MinusBtn';

function CapitalIncrease() {
    return useObserver(() => (
        <form onSubmit={(e) => CapitalIncreaseVm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            <Question>
                <ContentBox>
                    <div className={st.titleWrap}>
                        <h2 className={st.title}>회사의 <span className={st.bold}>기존 등기부 상태</span>를 확인해주세요.</h2>
                    </div>
                    {CapitalIncreaseVm.limitCompanyYn ?
                        <table className={st.sharesOnRegisterTable}>
                            <thead className={st.thead}>
                                <tr>
                                    <th>출자 1좌의 금액</th>
                                    <th>출자좌수</th>
                                    <th>자본금</th>
                                </tr>
                            </thead>
                            <tbody className={st.tbody}>
                                <tr>
                                    {CapitalIncreaseVm.limitCompanyYn ? <td>{addCommas(CapitalIncreaseVm.parValue)}원</td>: null}
                                    <td>{addCommas(CapitalIncreaseVm.totalCapital/CapitalIncreaseVm.parValue)}좌</td>
                                    <td>{addCommas(CapitalIncreaseVm.totalCapital)}원</td>
                                </tr>
                            </tbody>
                        </table>
                    :
                        <table className={st.limitedLiabilityTable}>
                            <tbody>
                                <tr>
                                    <th>등기부상 자본금</th>
                                    <td>{addCommas(CapitalIncreaseVm.totalCapital)}원</td>
                                </tr>
                            </tbody>
                        </table>
                    }
                </ContentBox>
            </Question>
            <Question>
                <ContentBox>
                    <div className={st.titleWrap}>
                        <h2 className={st.title}>
                            {CapitalIncreaseVm.limitCompanyYn ?
                            <>
                                <span className={st.bold}>투자금, 출자좌수</span>를 입력해주세요.
                            </>
                            :
                            <>
                                <span className={st.bold}>투자금</span>을 입력해주세요.
                            </>
                            }
                        </h2>
                        {CapitalIncreaseVm.limitCompanyYn &&
                        <>
                            <div className={st.explainBox}>
                                <div className={st.exContent}>
                                    <div className={st.title}>
                                        입력방법
                                    </div>
                                    <div className={st.content}>
                                        <div className={st.title}>1. 투자금 입력하기</div>
                                        <div className={st.content}>- 정확한 금액O 대략적 금액X<br/>- 가수금 증자의 경우, 증자할 금액만!</div>
                                        <div className={st.title}>2. 주식수 입력하기</div>
                                    </div>
                                </div>
                                <div className={st.exContent}>
                                    <div className={st.title}>
                                        자동계산된<br/>발행가액<br/>확인방법
                                    </div>
                                    <div className={st.content}>
                                        <div className={st.title}>1. <a className={st.link} href="https://info.help-me.kr/hc/ko/articles/360023764852" target="_blank">발행가액</a> = 투자금 ÷ 출자좌수</div>
                                        <div className={st.title}>2. 발행가액은 소수점이 불가능!</div>
                                        <div className={st.title}>3. 발행가액이 소수점인 경우 해결방법</div>
                                        <div className={st.content}>
                                            1) 투자자에게 추가 입금을 받거나, 소액의 투자금을 반환하기<br/>
                                            2) 출자좌수를 줄이거나 늘리기<br/>
                                            3) 외국돈 → 원화 환전시 소수점이 된 경우, 반올림하기
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={st.titleExplain}>
                                발행가액이 투자자마다 달라도 무방하지만, 자본금 변경 시기가 비슷하거나(6개월~1년 미만), 투자자끼리 특수 관계가 있다면 증여세가 발생할 수도 있습니다.
                            </div>
                            <div className={st.titleExplain}>
                                여러 번에 나누어 자본금 변경을 해야 하는 경우, 담당 매니저에게 말씀해주세요.
                            </div>
                        </>
                        }
                        
                    </div>
                    <table className={CapitalIncreaseVm.limitCompanyYn ? st.newSharesTable : st.limitedNewSharesTable}>
                        <colgroup>
                            <col width="200px"/>
                            <col width="140px" />
                            {CapitalIncreaseVm.limitCompanyYn ? <col /> : null}
                            {CapitalIncreaseVm.limitCompanyYn ? <col /> : null}
                            {CapitalIncreaseVm.limitCompanyYn ? <col width="220px"/> : null}
                        </colgroup>
                        <thead className={st.thead}>
                            <tr>
                                <th>신주인수인</th>
                                <th>발행방법</th>
                                <th>투자금</th>
                                {CapitalIncreaseVm.limitCompanyYn ? <th>출자좌수</th> : null}
                                {CapitalIncreaseVm.limitCompanyYn ? <th>발행가액(자동계산)</th> : null}
                            </tr>
                        </thead>
                        <tbody className={st.tbody}>
                            {CapitalIncreaseVm.newCapitals.map((newCapital, index) => {
                                return (
                                    <Fragment key={index}> 
                                        {newCapital?.유상증자 &&
                                            <tr className={index % 2 === 0 ? st.even : st.odd}>
                                                <td className={st.name}>
                                                    <input type="hidden" name={`newCapitals[${index}].id`} value={newCapital.id}/>
                                                    <input type="hidden" name={`newCapitals[${index}].name`} value={newCapital.name}/>
                                                    {newCapital.name}
                                                </td>
                                                <td className={st.resourceType}>
                                                    유상증자
                                                </td>
                                                <td>
                                                    <InputText className={st.stockNumber} placeholder="0" placeholderAlign="right" name={`newCapitals[${index}].유상증자.investment`} value={addCommas(newCapital?.유상증자.investment)} style={{width:'160px' , height:"40px", fontSize:"16px"}} onChange={(e) => CapitalIncreaseVm.set유상증자Investment(e, index)} errorIconNon errorFixed errorText={<Error name={`newCapitals[${index}].유상증자.investment`} value={newCapital?.유상증자.investment} style={CapitalIncreaseVm.유상증자투자금ValidateFunc(index).numberCheckYn ? {width: '175px', fontSize:"16px", textAlign:"left"} : {width: '515px', fontSize:"16px", textAlign:"left"}} 
                                                    errorCase={{
                                                        required:'투자금을 입력해 주세요.',
                                                        validate : {
                                                            func: CapitalIncreaseVm.유상증자투자금ValidateFunc(index).validation,
                                                            message: CapitalIncreaseVm.유상증자투자금ValidateFunc(index).message
                                                        }
                                                    }}/>}/><span className={st.text}>원</span>
                                                </td>
                                                {CapitalIncreaseVm.limitCompanyYn &&
                                                    <td>
                                                        <InputText className={st.stockNumber} placeholder="0" placeholderAlign="right" name={`newCapitals[${index}].유상증자.number`} value={addCommas(newCapital?.유상증자.number)} style={{width:'160px' , height:"40px", fontSize:"16px"}} onChange={(e) => CapitalIncreaseVm.set유상증자Number(e, index)} errorIconNon errorFixed errorText={<Error name={`newCapitals[${index}].유상증자.number`} value={newCapital?.유상증자.number} style={{width:'175px', fontSize:"16px", textAlign:"left"}} 
                                                        errorCase={{
                                                            required:'출자좌수를 입력해 주세요.',
                                                            validate : {
                                                                func: CapitalIncreaseVm.유상증자출자좌수ValidateFunc(index).validation,
                                                                message: CapitalIncreaseVm.유상증자출자좌수ValidateFunc(index).message
                                                            }
                                                        }}/>}/><span className={st.text}>좌</span>
                                                    </td>
                                                }
                                                {CapitalIncreaseVm.limitCompanyYn &&
                                                    <td>
                                                        {CapitalIncreaseVm.유상증자issuePrice(index)?.point ? 
                                                            <span>{addCommas(CapitalIncreaseVm.유상증자issuePrice(index).issuePrice)}<span className={st.issuePriceError}>.{addCommas(CapitalIncreaseVm.유상증자issuePrice(index).point)}</span></span>
                                                            :
                                                            addCommas(CapitalIncreaseVm.유상증자issuePrice(index))
                                                        }원
                                                        {
                                                            CapitalIncreaseVm.parValue === CapitalIncreaseVm.유상증자issuePrice(index) && 
                                                            <div className={st.successPrice}>액면가</div>
                                                        }  
                                                    </td>
                                                }
                                            </tr>
                                        }
                                        {newCapital?.가수금 &&
                                            <tr className={clsx(index % 2 === 0 ? st.even : st.odd, newCapital?.유상증자 ? st.second : null)}>
                                                {!newCapital?.유상증자 ?
                                                    <td className={st.name}>
                                                        <input type="hidden" name={`newCapitals[${index}].id`} value={newCapital.id}/>
                                                        <input type="hidden" name={`newCapitals[${index}].name`} value={newCapital.name}/>
                                                        {newCapital.name}
                                                    </td>
                                                :
                                                    <td></td>
                                                }
                                                <td className={st.resourceType}>
                                                    가수금증자
                                                </td>
                                                <td>
                                                    <InputText className={st.stockNumber} placeholder="0" placeholderAlign="right" name={`newCapitals[${index}].가수금.investment`} value={addCommas(newCapital?.가수금.investment)} style={{width:'160px' , height:"40px", fontSize:"16px"}} onChange={(e) => CapitalIncreaseVm.set가수금Investment(e, index)} errorIconNon errorFixed errorText={<Error name={`newCapitals[${index}].가수금.investment`} value={newCapital?.가수금.investment} style={CapitalIncreaseVm.가수금투자금ValidateFunc(index).numberCheckYn ? {width: '175px', fontSize:"16px", textAlign:"left"} : {width: '515px', fontSize:"16px", textAlign:"left"}} 
                                                    errorCase={{
                                                        required:'투자금을 입력해 주세요.',
                                                        validate : {
                                                            func: CapitalIncreaseVm.가수금투자금ValidateFunc(index).validation,
                                                            message: CapitalIncreaseVm.가수금투자금ValidateFunc(index).message
                                                        }
                                                    }}/>}/><span className={st.text}>원</span>
                                                </td>
                                                {CapitalIncreaseVm.limitCompanyYn &&
                                                    <td>
                                                        <InputText className={st.stockNumber} placeholder="0" placeholderAlign="right" name={`newCapitals[${index}].가수금.number`} value={addCommas(newCapital?.가수금.number)} style={{width:'160px' , height:"40px", fontSize:"16px"}} onChange={(e) => CapitalIncreaseVm.set가수금Number(e, index)} errorIconNon errorFixed errorText={<Error name={`newCapitals[${index}].가수금.number`} value={newCapital?.가수금.number} style={{width:'175px', fontSize:"16px", textAlign:"left"}} 
                                                        errorCase={{
                                                            required:'출자좌수를 입력해 주세요.',
                                                            validate : {
                                                                func: CapitalIncreaseVm.가수금출자좌수ValidateFunc(index).validation,
                                                                message: CapitalIncreaseVm.가수금출자좌수ValidateFunc(index).message
                                                            }
                                                        }}/>}/><span className={st.text}>좌</span>
                                                    </td>
                                                }
                                                {CapitalIncreaseVm.limitCompanyYn &&
                                                    <td>
                                                        {CapitalIncreaseVm.가수금issuePrice(index)?.point ? 
                                                            <span>{addCommas(CapitalIncreaseVm.가수금issuePrice(index).issuePrice)}<span className={st.issuePriceError}>.{addCommas(CapitalIncreaseVm.가수금issuePrice(index).point)}</span></span>
                                                            :
                                                            addCommas(CapitalIncreaseVm.가수금issuePrice(index))
                                                        }원
                                                        {
                                                            CapitalIncreaseVm.parValue === CapitalIncreaseVm.가수금issuePrice(index) && 
                                                            <div className={st.successPrice}>액면가</div>
                                                        }  
                                                    </td>
                                                }
                                            </tr>
                                        }
                                    </Fragment>
                                )
                            })}
                            <tr className={st.sumRow}>
                                <td className={st.sum}>합계</td>
                                <td></td>
                                <td>{CapitalIncreaseVm.investmentSum()}<span className={st.tagText}>원</span></td>
                                {CapitalIncreaseVm.limitCompanyYn ? <td>{CapitalIncreaseVm.numberSum()}<span className={st.tagText}>좌</span></td> : null}
                                {CapitalIncreaseVm.limitCompanyYn ? <td></td> : null}
                            </tr>
                        </tbody>
                    </table>
                </ContentBox>
            </Question>
        </form>
    ));
}

export default CapitalIncrease;