import st from './BoardMeetingSealProvidingModal.module.scss';
import { runInAction } from "mobx";
import portalModel from '@model/portalModel';
import Icon from '@comComponents/atoms/Icon/Icon';

const modal = {
    modalContent: 
        <div className={st.Modal}>
            <div className={st.title}>
                <span className={st.bold}>고객님!</span> 인감증명서를 제공할 이사님의 성함을 최소 2명 이상 기재해 주세요.
            </div>
            <div className={st.modalContent}>
                50%를 초과하는 이사의 인감증명서가 필요합니다.
                <div className={st.explainBox}>
                    <div className={st.explinTitle}>예시)</div>
                    <div className={st.box}>
                        <div className={st.explain}>
                            이사 3명<Icon icon="arrow" className={st.arrow}/><span className={st.bold}>2명 이상 선택</span>
                        </div>
                        <div className={st.explain}>
                            이사 4명<Icon icon="arrow" className={st.arrow}/><span className={st.bold}>3명 이상 선택</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ,
    btn: [{title: '확인', type: 'positive', event: ()=> {portalModel.setModalClose()}}]
}

export default modal;

