import { useObserver } from 'mobx-react';
import BoardMeetingSealProvidingVm from './BoardMeetingSealProvidingVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './BoardMeetingSealProviding.module.scss';
import clsx from 'clsx';
import AddBtn from '@comComponents/atoms/Button/AddBtn/AddBtn';
import Drop from '@comComponents/molecules/Dropdown/Dropdown';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import Icon from '@comComponents/atoms/Icon/Icon';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';
import { useEffect } from 'react';

function BoardMeetingSealProviding() {
    useEffect(() => {
        if(BoardMeetingSealProvidingVm.directors.length === 0) { //이사회 인감제공 처음 입력할 때, 처음 리스트 추가 및 열기
            BoardMeetingSealProvidingVm.addDirectors();
            BoardMeetingSealProvidingVm.state.openIdx = 0;
        }
    }, [])

    return useObserver(() => (
        <form onSubmit={(e) => BoardMeetingSealProvidingVm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            <Question list={true}>
                <ContentBox>
                    <div className={Qst.dropHeader}>
                    <div className={Qst.dropTitleWrap}>
                        <div className={Qst.dropTitle}>인감증명서를 제공할 이사분의 정보를 입력해 주세요.</div>
                        <div className={Qst.dropTitleExplain}>고객님께서 작성하신 이사분들 리스트입니다.</div>
                    </div>
                    <AddBtn onClick={() => BoardMeetingSealProvidingVm.addDirectors()}>이사 추가하기</AddBtn>
                    </div>
                </ContentBox>
            </Question>
            {BoardMeetingSealProvidingVm.directors.map((item,idx) => {
                return (
                    <Question list key={idx}>
                        <ContentBox>
                            <Drop dataEq={`${idx}`} className={clsx(Qst.dropDown, BoardMeetingSealProvidingVm.setIsOpen(idx) ? 'open' : 'close')} title={BoardMeetingSealProvidingVm.setTitle(idx)} onClick={() => BoardMeetingSealProvidingVm.setDropOpen(idx)} isOpen={BoardMeetingSealProvidingVm.setIsOpen(idx)} closeBtn={() => BoardMeetingSealProvidingVm.setCloseBtn(idx)} error={BoardMeetingSealProvidingVm.personErrorYn(idx)}> 
                                <input type="hidden" name={`sealProvidingDirectors[${idx}].id`} value={item.id}/>
                                <div className={Qst.spaceBetween}>
                                    <div className={Qst.infoSection}>
                                        <div className={st.explainImgBox}> 
                                            <img src="/images/BoardMeetingSealProviding_explain.png" alt="이사회 참석할 이사 입력방법"/>
                                        </div>
                                        <div className={st.explain}>
                                            <div>
                                                <div className={st.title}>
                                                    ※ 50% 초과 계산방법 예시
                                                </div>
                                                <div className={st.content}>
                                                회사의 이사가 3명인 경우 : 2명 입력<br/>회사의 이사가 4명인 경우 : 3명 입력
                                                </div>
                                            </div>
                                            <div>
                                                <div className={st.title}>
                                                    ※ 신규이사가 있는 경우 계산방법 예시
                                                </div>
                                                <div className={st.content}>
                                                    기존이사 3명 + 신규이사 1명인 경우 : 3명 입력<br/>
                                                    기존이사 4명 + 신규이사 2명인 경우 : 4명 입력
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={Qst.qnaSection}>
                                        <Qna tip="이사회 정보 입력" className={Qst.qnaTitle}>
                                            <div className={Qst.qnaBox}>
                                                <QText>감사의 인감증명서로 대체할 수 없나요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>이사회 결의를 위하여는 이사의 인감증명서만 제출이 가능하고, 감사의 인감증명서로 대체할 수 없습니다.</Answer>
                                                    <p className={Qst.explain}>
                                                        감사는 이사회의 소집통지를 받고, 참석할 권리는 있지만, 투표권이 없기 때문에, 감사의 인감증명서로 대체할 수 없습니다.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={Qst.qnaBox}>
                                                <QText>50% 초과란 어떤 의미인가요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>이사회 인원이 짝수인 경우, 50%보다 1명 많은 경우를 말합니다.</Answer>
                                                    <p className={Qst.explain}>
                                                        50% 초과란 50%보다는 많아야 함을 의미합니다. 이사회 인원이 짝수인 경우, 전체 인원의 50%보다 1명 많아야 함을 주의해주세요.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={Qst.qnaBox}>
                                                <QText>이사회 총원은 어떻게 계산하나요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>이사회 총원은 사내이사, 사외이사 숫자로 계산하고, 감사 숫자는 포함하지 않습니다.</Answer>
                                                    <p className={Qst.explain}>
                                                        이사회의 구성원은 사내이사, 사외이사이고, 감사는 이사회의 구성원이 아닙니다. 또한 대표이사님도 사내이사를 겸직하므로, 대표이사도 사내이사 숫자에 포함됩니다.
                                                    </p>
                                                    <p className={Qst.explain}>
                                                        따라서 50% 초과를 계산할 때, 이사회 총원은 사내이사, 사외이사 숫자로 계산해주세요. 감사의 숫자는 포함하지 않습니다.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={Qst.qnaBox}>
                                                <QText>신규이사가 있다면, 어떻게 계산해야 하나요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>신규이사가 있다면, 신규이사를 이사회 총원에 포함시켜 50% 초과를 계산해주세요.</Answer>
                                                </div>
                                            </div>
                                        </Qna>
                                    </div>
                                </div>
                                <div className={Qst.spaceBetween}>
                                    <div className={Qst.infoSection}>
                                        <div className={Qst.titleWrap}>
                                            <h2 className={clsx(Qst.title, Qst.inside)}>
                                                해당 이사는 <span className={Qst.bold}>어떤 사람</span>인가요?
                                            </h2>
                                            <div className={Qst.titleExplain}>
                                                외국인 거주등록을 하지 않은 외국인은 인감증명서 제공이 불가능합니다. 위임장을 현지에서 공증받는 절차가 필요하니 담당 매니저에게 채팅으로 문의주세요.
                                            </div>
                                        </div>
                                        <ul className={Qst.answerList}>
                                            <li><Radio className={Qst.answerLabel} value="한국인" activeBackground checked={item.sealProviderType === "한국인"} onChange={(e) => BoardMeetingSealProvidingVm.setSealProviderType(e,idx)} name={`sealProvidingDirectors[${idx}].sealProviderType`}>한국인</Radio></li>
                                            <li><Radio className={Qst.answerLabel} value="외국인_거주등록" activeBackground checked={item.sealProviderType === "외국인_거주등록"} onChange={(e) => BoardMeetingSealProvidingVm.setSealProviderType(e,idx)} name={`sealProvidingDirectors[${idx}].sealProviderType`}>외국인 거주등록을 마친 국내 거주 외국인</Radio></li>
                                        </ul>
                                        <Error name={`sealProvidingDirectors[${idx}].sealProviderType`} value={item.sealProviderType} errorCase={{required:'사람을 선택해 주세요.'}}/>
                                    </div>
                                    <div className={Qst.qnaSection}>
                                        <Qna className={Qst.noTitle}>
                                            <div className={Qst.qnaBox}>
                                                <QText>거주등록을 하지 않은 외국인 이사가 이사회에서 찬성하<br/>려면 어떻게 해야 하나요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>거주등록을 하지 않은 외국인 이사의 경우, 인감증명서를 만들 수 없습니다.</Answer>
                                                    <div className={Qst.explain}>
                                                        이 경우 찬성한다는 내용의 위임장을 거주하는 국가의 언어로 작성한 후, 현지 공증 또는 아포스티유 인증을 거치는 복잡한 절차가 필요합니다. 해당 절차가 필요한 경우 담당 매니저에게 채팅으로 문의주세요.
                                                    </div>
                                                </div>
                                            </div>
                                        </Qna>
                                    </div>
                                </div>
                                <div className={Qst.spaceBetween}>
                                    <div className={Qst.infoSection}>
                                        <div className={Qst.titleWrap}>
                                            <h2 className={clsx(Qst.title, Qst.inside)}>
                                                <span className={Qst.bold}>성함을 입력</span>해 주세요.
                                            </h2>
                                            <div className={Qst.titleExplain}>
                                                등기부등본에 등록된 한글 성함을 그대로 입력해 주세요.
                                            </div>
                                        </div>
                                        <InputText placeholder="ex)데이비드김" style={{width:'316px'}} value={item.name} onChange={(e) => BoardMeetingSealProvidingVm.setName(e,idx)} name={`sealProvidingDirectors[${idx}].name`} errorText={<Error name={`sealProvidingDirectors[${idx}].name`} value={item.name} errorCase={{
                                            required:'성함을 입력해 주세요.',
                                            pattern : {
                                                value: regExp.onlyKorean().test(item.name),
                                                message: '성함을 한글로 입력해 주세요.'
                                            }
                                        }}/>}/><span className={Qst.nameInput}>님</span>
                                    </div>
                                </div>
                            </Drop>
                        </ContentBox>
                    </Question>
                )
            })}
            <Question list={true}>
                <ContentBox>
                <button type="button" className={Qst.stockholderAddBtn} onClick={() => BoardMeetingSealProvidingVm.addDirectors()}>이사 추가하기<Icon icon="addGray" className={Qst.addGray}/></button>
                </ContentBox>
            </Question>
        </form>
    ));
}
export default BoardMeetingSealProviding;