import { useObserver } from 'mobx-react';
import BranchOfficeInstallVm from './BranchOfficeInstallVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './BranchOfficeInstall.module.scss';
import clsx from 'clsx';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import Icon from '@comComponents/atoms/Icon/Icon';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import SquareBtn from '@comComponents/atoms/Button/SquareBtn/SquareBtn';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import regExp from '@common/module/regExp';
import SearchAddress from '@comComponents/atoms/SearchAddress/SearchAddress';
import Drop from '@comComponents/molecules/Dropdown/Dropdown';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Select from '@comComponents/atoms/Input/Select/Select';
import AddBtn from '@comComponents/atoms/Button/AddBtn/AddBtn';
import { useEffect } from 'react';

function BranchOfficeInstall() {
    useEffect(() => {
        if(BranchOfficeInstallVm.branchOffices.length === 0) { 
            BranchOfficeInstallVm.addNewBranchOffice();
        } 
        BranchOfficeInstallVm.state.openIdx = 0;
    }, [])

    return useObserver(() => (
        <form onSubmit={(e) => BranchOfficeInstallVm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            <Question list>
                <ContentBox>
                    <div className={Qst.dropHeader}>
                        <div className={Qst.dropTitleWrap}>
                            <div className={Qst.dropTitle}>신규 지점의 정보를 입력해 주세요.</div>
                            <div className={Qst.dropTitleExplain}>고객님께서 작성하신 신규 지점의 리스트입니다.</div>
                        </div>
                        <AddBtn onClick={() => BranchOfficeInstallVm.addNewBranchOffice()}>신규지점 추가하기</AddBtn>
                    </div>
                </ContentBox>
            </Question>
            {BranchOfficeInstallVm.branchOffices.map((item, idx) => {
                return (
                    <Question list key={idx}>
                        <ContentBox>
                            <Drop dataEq={`${idx}`} className={clsx(Qst.dropDown, BranchOfficeInstallVm.setIsOpen(idx) ? 'open' : 'close')} title={BranchOfficeInstallVm.setTitle(idx)} onClick={() => BranchOfficeInstallVm.setDropOpen(idx)} isOpen={BranchOfficeInstallVm.setIsOpen(idx)} edit={false} error={BranchOfficeInstallVm.personErrorYn(idx)} closeBtn={() => BranchOfficeInstallVm.setCloseBtn(idx)}>
                                <input type="hidden" name={`branchOfficeInstalls[${idx}].id`} value={item.id} />
                                <div className={Qst.spaceBetween}>
                                    <div className={Qst.infoSection}>
                                        <div className={Qst.titleWrap}>
                                            <h2 className={clsx(Qst.title, Qst.inside)}>
                                                신규 지점의 임대차계약서상 <span className={Qst.bold}>신규주소를 입력</span>해 주세요.
                                            </h2>
                                        </div>
                                        <Badge className={st.addressBadge} title="예시" type="example" border={false}>
                                            <img className={st.addressBadgeImg} src="/images/contract_example.png"
                                            alt="임대차계약서 예시" />
                                        </Badge>
                                        <div className={Qst.rowWrap}>
                                            <InputText className={Qst.inputText} style={{width:'380px'}} explain="도로명 주소" 
                                            value={item.address?.address || ''} 
                                            readOnly 
                                            onClick={() => BranchOfficeInstallVm.openBranchAddressModal(true, idx)} 
                                            name={`branchOfficeInstalls[${idx}].address`}
                                            errorText={<Error name={`branchOfficeInstalls[${idx}].address`} value={item.address?.address || ''} errorCase={{required:'도로명 주소를 입력해 주세요.'}}/>}/>
                                            <SquareBtn onClick={() => BranchOfficeInstallVm.openBranchAddressModal(true, idx)}>도로명 주소 찾기</SquareBtn>
                                        </div>
                                        <div className={Qst.rowWrap}>
                                            <InputText className={Qst.inputText} style={{width:'273px'}} explain="상세 주소" errorText={<Error name={`branchOfficeInstalls[${idx}].detailAddress`} value={item.detailAddress} errorCase={{
                                                required:'상세 주소를 입력해 주세요.',
                                                pattern : {
                                                    value: regExp.onlyKorNum_hyphen_comma().test(item.detailAddress),
                                                    message: <span>임대차 계약서상 영문이 있다면, <br/>한글로 옮겨적어주세요.<br/>EX) B동 &gt; 비동</span>
                                                }
                                            }}/>} placeholder="상세주소 없을 시, [없음] 입력" value={item.detailAddress} onChange={(e) => BranchOfficeInstallVm.branchAddress_detailAddress(e, idx)} name={`branchOfficeInstalls[${idx}].detailAddress`}/>
                                            <div className={Qst.symbol}>
                                                <span className={Qst.symbolIcon}>&#40;&nbsp;</span><span className={clsx(Qst.symbolText, BranchOfficeInstallVm.branchAddress_bnameFontSizeChange(idx) ? Qst.fontSize : null)}>{item.address?.bname || '법정동'},&nbsp;</span>
                                                <InputText style={{width:'200px'}} explain="건물명" placeholder="없을 시, [없음] 입력" value={item.nameOfBuilding} onChange={(e) => BranchOfficeInstallVm.branchAddress_nameOfBuilding(e, idx)} name={`branchOfficeInstalls[${idx}].nameOfBuilding`} errorText={<Error name={`branchOfficeInstalls[${idx}].nameOfBuilding`}  value={item.nameOfBuilding} style={{width:'260px'}} errorCase={{
                                                    required:'건물명을 입력해 주세요.',
                                                    pattern : {
                                                        value: regExp.onlyKorNum_hyphen_comma().test(item.nameOfBuilding),
                                                        message: <span>건물명이 영문인 경우, 한글<br/>발음으로 옮겨적어 주세요. <br/>EX) AB타워 &gt; 에이비 타워</span>
                                                    }
                                                }}/>}/>
                                                <span className={Qst.symbolIcon}>&nbsp;&#41;</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={Qst.qnaSection}>
                                        <Qna tip='주소 설정방법' className={Qst.qnaTitle}>
                                            <div className={Qst.qnaBox}>
                                                <QText>지번주소를 어떻게 도로명 주소로 바꾸나요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>도로명주소 안내시스템 사이트에서 바꿀 수 있습니다.</Answer>
                                                    <LikeBtn className={Qst.likeBtn} href="https://www.juso.go.kr/openIndexPage.do" target="_blank">도로명주소 안내시스템 사이트 바로가기</LikeBtn>
                                                </div>
                                            </div>
                                            <div className={Qst.qnaBox}>
                                                <QText>주소에는 영어를 쓸 수 있나요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>등기소에서는 주소를 적을 때, 한글 숫자만 허용합니다. (영어 X)</Answer>
                                                    <p className={Qst.explain}>
                                                        임대차계약서상 영어가 들어가 있다면, 한글로 변환해 주세요.
                                                    </p>
                                                    <TextBox className={Qst.textBox} title="예시">
                                                        <div className={Qst.textWrap}>
                                                            <span className={Qst.false}>B동, 202-1001</span><Icon icon="arrow" className={Qst.iconArrow}/><span className={Qst.true}>비동, 202동 1001호</span>
                                                        </div>
                                                    </TextBox>
                                                </div>
                                            </div>
                                            <div className={Qst.qnaBox}>
                                                <QText>임대차계약서를 사전에 준비해야 할까요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>임대차계약서를 미리 준비하는 것을 추천드립니다.</Answer>
                                                    <p className={Qst.explain}>
                                                        지점설치등기 시에는 임대차계약서를 제출하지 않아도 되지만, 지점설치등기 후 사업자등록을 변경할 때 세무서에 제출해야 합니다. 이 때 임대차계약서의 주소와 등기부 주소가 일치하지 않으면 사업자등록이 거부됩니다.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={Qst.qnaBox}>
                                                <QText>공유오피스 주소로 지점을 설치할 수 있나요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>원칙적으로 공유오피스로 지점을 설치할 수 있지만, 제한이 있습니다.</Answer>
                                                    <p className={Qst.explain}>
                                                        세무서는 일부 업종의 경우, 공유오피스에서 영업할 수 없다고 판단해서 사업자등록을 허용하지 않습니다. (가정집보다는 사업자등록이 되는 경우가 더 많습니다.) 그 판단은 세무서마다 다릅니다. 따라서 관할 세무서에 미리 문의한 후, 진행하는 것을 추천드립니다. 이때 계약기간은 최소 3개월로 해 주세요. 계약 기간이 1개월이면 세무서에서 기간이 짧다는 이유로 3개월 혹은 6개월 이상의 계약서를 다시 제출하라고 요구하는 경우가 있습니다.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={Qst.qnaBox}>
                                                <QText>가정집 주소로 지점 설치가 가능한가요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>원칙적으로 가정집으로 지점 설치가 가능하지만, 제한이 있습니다.</Answer>
                                                    <p className={Qst.explain}>
                                                        세무서는 일부 업종의 경우, 주거용 건물에서 영업할 수 없다고 판단해서 사업자등록을 허용하지 않습니다. 가정집에서 사업자등록이 어려운 업종의 경우, 다른 주소를 선택해 주세요.
                                                    </p>
                                                    <dl className={Qst.defList}>
                                                        <dt className={Qst.term}>가정집에 지점을 두는 것이 어려운 업종</dt>
                                                        <dd className={Qst.def}>
                                                            도매업, 소매업, 판매업, 음식점업, 공장업, 제조업, 창고업, 건설업 등
                                                        </dd>
                                                        <dt className={Qst.term} style={{marginTop:'10px'}}>가정집에 지점을 두는 것이 가능한 업종</dt>
                                                        <dd className={Qst.def}>
                                                            통신판매업, 소프트웨어 제작업, 컨설팅업 등
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                            <div className={Qst.qnaBox}>
                                                <QText>전세집 주소로 지점 설치가 가능한가요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>원칙적으로 전세집으로 지점 설치를 할 수 있지만, 제한이 있습니다.</Answer>
                                                    <p className={Qst.explain}>
                                                        가정집으로 지점 설치가 가능한 업종이어야 할 뿐만 아니라, 집주인으로부터 전대차동의서를 미리 받아두어야 합니다. 나중에 지점 설치 후 집주인이 전대차동의서를 써주지 않는다면, 사업자 등록을 할 수 없습니다. 이 경우 비용을 들여 주소이전을 해야 하므로, 꼭 주의해주세요. 전대차동의서 양식이 필요하다면 채팅상담으로 요청해주세요.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={Qst.qnaBox}>
                                                <QText>임대차와 전대차 무엇이 다른가요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer} iconText="A1">임대차는 회사가 건물주에게 직접 장소를 임차한 경우입니다.</Answer>
                                                    <Answer className={Qst.answer} iconText="A2">전대차는 회사가 건물주가 아닌 기존 임차인으로부터 다시 임차한 경우입니다.</Answer>
                                                    <p className={Qst.explain}>
                                                        임대인과 신규 회사가 계약 당사자가 되어 전대차계약서를 작성합니다. 이 경우 반드시 건물주의 전대차 동의서를 첨부해야 합니다. 나중에 건물주가 전대차 동의서를 써주지 않는다면 사업자등록을 할 수 없습니다. 이 경우 비용을 들여 주소이전을 해야 하므로, 꼭 주의해 주세요.
                                                    </p>
                                                </div>
                                            </div>
                                        </Qna>
                                    </div>
                                </div>
                                <div className={Qst.spaceBetween}>
                                    <div className={Qst.infoSection}>
                                        <div className={Qst.titleWrap}>                                            
                                            <h2 className={clsx(Qst.title, Qst.inside)}>
                                                지점 설치 <span className={Qst.bold}>일자</span>를 입력해 주세요.
                                            </h2>
                                        </div>
                                        <Badge className={Qst.registrationBadge} title="예시" type="example" border={false}>
                                            <div className={Qst.registartionBadgeBox}>
                                                <img className={Qst.registrationBadgeImg} src="/images/registration_date.png" alt="지점 설치 일자 예시" />
                                                <div className={Qst.registrationBadgeTextBox}>
                                                    <div className={Qst.registrationBadgeText}>
                                                        <div className={Qst.text}>
                                                            <span className={Qst.boldBlue}>안정적으로 등기신청하기 위해 지점 설치 날짜는 오늘(질문지 제출일)을 기준으로,<br/>1주 전 ~ 3주 이내로 입력해 주세요.</span>
                                                        </div>
                                                        <div className={Qst.text}>
                                                            <span className={Qst.bold}>예시) 오늘(질문 제출일)이 3월 23일</span>이라면, <span className={Qst.bold}>안정적으로 등기신청 가능한 지점 설치 날짜는 3월 16일 ~ 4월 12일</span> 입니다.
                                                        </div>
                                                    </div>
                                                    <div className={Qst.registrationBadgeTextExplain}>
                                                        특별한 사정이 없다면, <span className={Qst.bold}>오늘 날짜(질문 제출일)로 입력하는 것을 추천</span>드립니다.<br/>
                                                        입력한 지점 설치 날짜로 부터 2주 이내 접수해야 과태료가 부가되지 않습니다.
                                                    </div>
                                                </div>     
                                            </div>                 
                                        </Badge>
                                        <div>
                                            <div className={Qst.birthWrap}>
                                                <InputText style={{width:'130px'}} placeholder="0000" name={`branchOfficeInstalls[${idx}].causeDateYear`} value={item.causeDateYear} onChange={(e) => BranchOfficeInstallVm.setCauseDateYear(e, idx)} maxLength="4" />
                                                <span className={Qst.birthText}>년</span>
                                            </div>
                                            <div className={Qst.birthWrap}>
                                                <InputText style={{width:'108px'}} placeholder="00" name={`branchOfficeInstalls[${idx}].causeDateMonth`} value={item.causeDateMonth} onChange={(e) => BranchOfficeInstallVm.setCauseDateMonth(e, idx)} maxLength="2" onBlur={(e) => BranchOfficeInstallVm.causeDateBlur(e, idx, 'month')} />
                                                <span className={Qst.birthText}>월</span>
                                            </div>
                                            <div className={Qst.birthWrap}>
                                                <InputText style={{width:'108px'}} placeholder="00" name={`branchOfficeInstalls[${idx}].causeDateDay`} value={item.causeDateDay} onChange={(e) => BranchOfficeInstallVm.setCauseDateDay(e, idx)} maxLength="2" onBlur={(e) => BranchOfficeInstallVm.causeDateBlur(e, idx, 'day')} />
                                                <span className={Qst.birthText}>일</span>
                                            </div>
                                        </div>
                                        <div style={{marginTop:'-27px'}}>
                                            <Error name={`branchOfficeInstalls[${idx}].causeDateYear`} value={item.causeDateYear} 
                                            errorCase={{
                                                required:'연도를 입력해 주세요.',
                                                pattern: {
                                                value: regExp.year().test(item.causeDateYear),
                                                message: '올바른 연도를 입력해 주세요'               
                                                }
                                            }}
                                            />
                                            <Error name={`branchOfficeInstalls[${idx}].causeDateMonth`} value={item.causeDateMonth} 
                                            errorCase={{
                                                required: BranchOfficeInstallVm.displayCauseDateErrorMessage(idx, 'month') ? '월을 입력해 주세요.' : null,
                                                pattern: {
                                                value: BranchOfficeInstallVm.displayCauseDateErrorMessage(idx, 'month') ? regExp.month().test(item.causeDateMonth) : null,
                                                message: '올바른 월을 입력해 주세요'               
                                                }
                                            }}
                                            />
                                            <Error name={`branchOfficeInstalls[${idx}].causeDateDay`} value={item.causeDateDay} 
                                            errorCase={{
                                                required: BranchOfficeInstallVm.displayCauseDateErrorMessage(idx, 'day') ? '일(날짜)을 입력해 주세요.' : null,
                                                pattern: {
                                                value: BranchOfficeInstallVm.displayCauseDateErrorMessage(idx, 'day') ? regExp.day().test(item.causeDateDay) : null,
                                                message: '올바른 일(날짜)을 입력해 주세요.'           
                                                }
                                            }}
                                            />
                                        </div>
                                    </div>
                                    <div className={Qst.qnaSection}>
                                        <Qna className={Qst.noTitle}>
                                            <div className={Qst.qnaBox}>
                                                <QText>지점 설치 일자는 임(전)대차 계약서상 일자랑 같아야<br/>하나요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>지점 설치 일자는 임(전)대차 계약서상 일자와 1~2달 차이가 나도 괜찮습니다.</Answer>
                                                    <p className={Qst.explain}>
                                                        미리 임차를 한 후, 나중에 이사를 하는 경우도 충분히 존재할 수 있기 때문에, 지점 설치 일자는 임(전)대차 계약서상 일자와 1~2달 차이가 나도 괜찮습니다. 단 임(전)대차 계약서의 일자가 등기부에 꼭 기재되어야 하는 사정이 있는 경우, 임(전)대차 계약서의 일자를 입력해주세요. 
                                                    </p>
                                                </div>
                                            </div>
                                        </Qna>
                                    </div>
                                </div>
                                <div className={Qst.spaceBetween}>
                                    <div className={Qst.infoSection}>
                                        <div className={Qst.titleWrap}>
                                            <h2 className={clsx(Qst.title, Qst.inside)}>
                                                <span className={Qst.bold}>신규 지점 명칭</span>을 입력해 주세요. (선택사항)
                                            </h2>
                                            <div className={Qst.titleExplain}>
                                                지점명칭은 없어도 됩니다. 넣지 않는 경우, "없음"이라고 입력해 주세요.
                                            </div>
                                        </div>
                                        <InputText style={{width:'560px'}} name={`branchOfficeInstalls[${idx}].officeName`} value={item.officeName} onChange={(e) => BranchOfficeInstallVm.setOfficeName(e, idx)} errorIconNon errorText={<Error name={`branchOfficeInstalls[${idx}].officeName`} value={item.officeName} errorCase={{required:'지점명칭을 넣지 않는 경우, "없음"이라고 입력해 주세요.'}}/>}/>
                                    </div>
                                    <div className={Qst.qnaSection}>
                                        <Qna className={Qst.noTitle}>
                                            <div className={Qst.qnaBox}>
                                                <QText>지점명칭은 어떻게 정하는 편이 좋나요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>지점명칭은 지역명을 넣되, 법인명을 넣지 않는 편이 좋습니다.</Answer>
                                                    <p className={Qst.explain}>
                                                        예를 들어 강남구에 있던 지점을 은평구로 이전하는 경우, 강남지점 → 은평지점으로 명칭을 변경하는 편을 추천드립니다. 다만 지점명칭에 법인명을 넣지 않는 것을 추천드립니다. 추후 법인명을 변경하게 되는 경우, 지점명칭을 모두 변경해야 하는데, 그 경우 비용이 많이 들고 번거로울 수 있기 때문입니다. 
                                                    </p>
                                                </div>
                                            </div>
                                        </Qna>
                                    </div>
                                </div>
                                <div className={Qst.spaceBetween}>
                                    <div className={Qst.infoSection}>
                                        <div className={Qst.titleWrap}>
                                            <h2 className={clsx(Qst.title, Qst.inside)}>
                                                신규 지점에 <span className={Qst.bold}>지배인</span>을 두실 것인가요? (선택사항)
                                            </h2>
                                            <div className={Qst.titleExplain}>
                                                일반적으로 지배인을 두지 않는 경우가 더 많습니다.
                                            </div>
                                        </div>
                                        <ul className={Qst.answerList}>
                                            <li><Radio className={Qst.answerLabel} value={true} activeBackground checked={item.hasManagerYn === true} onChange={(e) => BranchOfficeInstallVm.setHasManagerYn(e, idx)} name={`branchOfficeInstalls[${idx}].hasManagerYn`}>네</Radio></li>
                                            <li><Radio className={Qst.answerLabel} value={false} activeBackground checked={item.hasManagerYn === false} onChange={(e) => BranchOfficeInstallVm.setHasManagerYn(e, idx)} name={`branchOfficeInstalls[${idx}].hasManagerYn`}>아니오</Radio></li>
                                        </ul>
                                        <Error name={`branchOfficeInstalls[${idx}].hasManagerYn`} value={item.hasManagerYn} errorCase={{required:'지점명칭을 변경하실지 선택해 주세요.'}}/>
                                    </div>
                                    <div className={Qst.qnaSection}>
                                        <Qna className={Qst.noTitle}>
                                            <div className={Qst.qnaBox}>
                                                <QText>지배인은 무엇인가요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>지배인은 회사대표를 대신해서 지점에 관한 모든 영업행위를 할 수 있는 사람을 말합니다.</Answer>
                                                    <p className={Qst.explain}>
                                                        보통 은행의 경우 지점장을 지배인으로 두고, 필요한 업무를 자율적으로 할 수 있게 합니다. 
                                                    </p>
                                                    <LikeBtn className={Qst.likeBtn} href="https://blog.help-me.kr/2018/11/지배인-총정리/" target="_blank">지배인에 대해 더 알아보기</LikeBtn>
                                                </div>
                                            </div>
                                            <div className={Qst.qnaBox}>
                                                <QText>지배인을 꼭 두어야 할까요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>지배인을 두지 않는 경우가 더 많습니다. 지배인은 필수사항이 아닙니다.</Answer>
                                                    <p className={Qst.explain}>
                                                        지배인을 두는 경우, 취임 및 사임시 등기비용이 들고, 지배인의 주소변경시에도 등기를 해야 하기 떄문에 지배인을 두면 번거로운 경우가 많습니다.
                                                    </p>
                                                    <p className={Qst.explain}>
                                                        또한 등기된 지배인은 막강한 권한을 행사할 수 있기 떄문에, 꼭 필요한 경우가 아니라면 추천드리지 않습니다.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={Qst.qnaBox}>
                                                <QText>지배인을 두는데 비용이 더 드나요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>지배인을 두는데 공과금, 수수료가 발생합니다.</Answer>
                                                    <p className={Qst.explain}>
                                                        지배인을 두려면 등기를 해야 하기 때문에, 국가에 내는 공과금, 수수료가 발생합니다. 견적을 받을 당시에 포함되어 있지 않다면, 헬프미에서 추후 알려드리겠습니다.
                                                    </p>
                                                </div>
                                            </div>
                                        </Qna>
                                    </div>
                                </div>
                                {item.hasManagerYn &&
                                <>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    신규 지배인님은 <span className={Qst.bold}>어떤 사람</span>인가요?
                                                </h2>
                                            </div>
                                            <ul className={Qst.answerList}>
                                                <li><Radio className={Qst.answerLabel} value={true} activeBackground checked={item.manager.koreanYn === true} onChange={(e) => BranchOfficeInstallVm.setKoreanYn(e, idx)} name={`branchOfficeInstalls[${idx}].manager.koreanYn`}>한국인</Radio></li>
                                                <li><Radio className={Qst.answerLabel} value={false} activeBackground checked={item.manager.koreanYn === false} onChange={(e) => BranchOfficeInstallVm.setKoreanYn(e, idx)} name={`branchOfficeInstalls[${idx}].manager.koreanYn`}>외국인</Radio></li>
                                            </ul>
                                            <Error name={`branchOfficeInstalls[${idx}].manager.koreanYn`} value={item.manager.koreanYn} errorCase={{required:'사람을 선택해 주세요.'}}/>
                                        </div>
                                    </div> 
                                    {item.manager.koreanYn && //한국인
                                    <>
                                        <div className={Qst.spaceBetween}>
                                            <div className={Qst.infoSection}>
                                                <div className={Qst.titleWrap}>
                                                    <h2 className={clsx(Qst.title, Qst.inside)}>
                                                        <span className={Qst.bold}>신규 지배인님</span>의 <span className={Qst.bold}>성함</span>을 입력해 주세요.
                                                    </h2>
                                                </div>
                                                <InputText placeholder="ex)홍길동" style={{width:'316px'}} value={item.manager.name} onChange={(e) => BranchOfficeInstallVm.setName(e,idx)} name={`branchOfficeInstalls[${idx}].manager.name`} errorText={<Error name={`branchOfficeInstalls[${idx}].manager.name`} value={item.manager.name} errorCase={{required:'성함을 입력해 주세요.'}}/>}/><span className={Qst.nameInput}>님</span>
                                            </div>
                                        </div> 
                                        <div className={Qst.spaceBetween}>
                                            <div className={Qst.infoSection}>
                                                <div className={Qst.titleWrap}>
                                                    <h2 className={clsx(Qst.title, Qst.inside)}>
                                                        <span className={Qst.bold}>{item.manager.name ? item.manager.name : '신규 지배인'}님</span>의 <span className={Qst.bold}>주민등록번호</span>을 입력해 주세요.
                                                    </h2>
                                                    <div className={Qst.titleExplain}>
                                                        주민등록번호는 헬프미 스마트 보안 시스템에 의하여 안전하게 보관됩니다.
                                                    </div>
                                                </div>
                                                <InputText placeholder="111111-1111111" style={{width:'316px'}} textType="kssn" value={item.manager.kssn} onChange={(e) => BranchOfficeInstallVm.setKssn(e,idx)} name={`branchOfficeInstalls[${idx}].manager.kssn`} errorText={<Error name={`branchOfficeInstalls[${idx}].manager.kssn`} value={item.manager.kssn} errorCase={{
                                                    required: '주민등록번호를 입력해 주세요.',
                                                    validate: {
                                                    func: regExp.kssn(item.manager.kssn),
                                                    message: '올바른 주민등록번호를 입력해 주세요.'
                                                    }
                                                }}/>} />
                                            </div>
                                        </div>
                                        <div className={Qst.spaceBetween} style={{paddingBottom:'30px'}}>
                                            <div className={Qst.infoSection}>
                                                <div className={Qst.titleWrap}>
                                                    <h2 className={clsx(Qst.title, Qst.inside)}>
                                                        <span className={Qst.bold}>{item.manager.name ? item.manager.name : '신규 지배인'}님</span>의 <span className={Qst.bold}>주민등록등본상 주소</span>를 입력해 주세요.
                                                    </h2>
                                                </div>
                                                <Badge className={Qst.addressBadge} title="예시" type="example" border={false}>
                                                    <img className={Qst.addressBadgeImg} src="/images/person_address_example.png"
                                                    alt="주민등록상 도로명주소 예시" />
                                                </Badge>
                                                <div className={Qst.rowWrap}>
                                                    <InputText className={Qst.inputText} style={{width:'380px'}} explain="도로명 주소" 
                                                    value={item.manager.address?.address || ''} 
                                                    readOnly 
                                                    onClick={() => BranchOfficeInstallVm.openManagerAddressModal(true, idx)} 
                                                    name={`branchOfficeInstalls[${idx}].manager.address`}
                                                    errorText={<Error name={`branchOfficeInstalls[${idx}].manager.address`} value={item.manager.address?.address || ''} errorCase={{required:'도로명 주소를 입력해 주세요.'}}/>}/>
                                                    <SquareBtn onClick={() => BranchOfficeInstallVm.openManagerAddressModal(true, idx)}>도로명 주소 찾기</SquareBtn>
                                                </div>
                                                <div className={Qst.rowWrap}>
                                                    <InputText className={Qst.inputText} style={{width:'273px'}} explain="상세 주소" errorText={<Error name={`branchOfficeInstalls[${idx}].manager.detailAddress`} value={item.manager.detailAddress} errorCase={{
                                                        required:'상세 주소를 입력해 주세요.',
                                                        pattern : {
                                                            value: regExp.onlyKorNum_hyphen_comma().test(item.manager.detailAddress),
                                                            message: <span>임대차 계약서상 영문이 있다면, <br/>한글로 옮겨적어주세요.<br/>EX) B동 &gt; 비동</span>
                                                        }
                                                    }}/>} placeholder="상세주소 없을 시, [없음] 입력" value={item.manager.detailAddress} onChange={(e) => BranchOfficeInstallVm.managerAddress_detailAddress(e, idx)} name={`branchOfficeInstalls[${idx}].manager.detailAddress`}/>
                                                    <div className={Qst.symbol}>
                                                        <span className={Qst.symbolIcon}>&#40;&nbsp;</span><span className={clsx(Qst.symbolText, BranchOfficeInstallVm.managerAddress_bnameFontSizeChange(idx) ? Qst.fontSize : null)}>{item.manager.address?.bname || '법정동'},&nbsp;</span>
                                                        <InputText style={{width:'200px'}} explain="건물명" placeholder="없을 시, [없음] 입력" value={item.manager.nameOfBuilding} onChange={(e) => BranchOfficeInstallVm.managerAddress_nameOfBuilding(e, idx)} name={`branchOfficeInstalls[${idx}].manager.nameOfBuilding`} errorText={<Error name={`branchOfficeInstalls[${idx}].manager.nameOfBuilding`}  value={item.nameOfBuilding} style={{width:'260px'}} errorCase={{
                                                            required:'건물명을 입력해 주세요.',
                                                            pattern : {
                                                                value: regExp.onlyKorNum_hyphen_comma().test(item.manager.nameOfBuilding),
                                                                message: <span>건물명이 영문인 경우, 한글<br/>발음으로 옮겨적어 주세요. <br/>EX) AB타워 &gt; 에이비 타워</span>
                                                            }
                                                        }}/>}/>
                                                        <span className={Qst.symbolIcon}>&nbsp;&#41;</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>    
                                    }
                                    {item.manager.koreanYn === false && //외국인
                                    <>
                                        <div className={Qst.spaceBetween}>
                                            <div className={Qst.infoSection}>
                                                <div className={Qst.titleWrap}>
                                                    <h2 className={clsx(Qst.title, Qst.inside)}>    
                                                        <span className={Qst.bold}>신규 지배인님은 한국에서 거소등록 또는 외국인등록을 한 외국인</span>인가요?
                                                    </h2>
                                                </div>
                                                <ul className={Qst.answerList}>
                                                    <li><Radio className={Qst.answerLabel} value={true} activeBackground checked={item.manager.residenceRegistrationYn === true} onChange={(e) => BranchOfficeInstallVm.setResidenceReportYn(e, idx)} name={`branchOfficeInstalls[${idx}].manager.residenceRegistrationYn`}>네</Radio></li>
                                                    <li><Radio className={Qst.answerLabel} checked={false} onClick={() => BranchOfficeInstallVm.openModal()}>아니오</Radio></li>
                                                </ul>
                                                <Error name={`branchOfficeInstalls[${idx}].manager.residenceRegistrationYn`} value={item.manager.residenceRegistrationYn} errorCase={{required:'거소등록 또는 외국인등록 여부를 선택해 주세요.'}} />
                                                </div>
                                            <div className={Qst.qnaSection}>
                                                <Qna className={Qst.noTitle}>
                                                    <div className={Qst.qnaBox}>
                                                        <QText>거소등록(외국인등록)하지 않은 외국인은 지배인이 되는<br/>데 제약이 있나요?</QText>
                                                        <div className="answerBox">
                                                            <Answer className={Qst.answer}>한국에서 거소등록(외국인등록)하지 않은 외국인의 경우, 서비스제공이 제한됩니다.</Answer>
                                                            <p className={Qst.explain}>
                                                                거소등록(외국인등록)하지 않은 외국인을 지배인으로 등록하는 경우, 서비스 제공이 어렵습니다. 거소등록(외국인등록)하지 않은 외국인이 지배인으로 등록되려면, 아포스티유 인증 또는 영사관 인증이 필요하고, 절차가 상당히 복잡하기 떄문에, 서비스를 제공하고 있지 않습니다. 양해 부탁드립니다.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Qna>
                                            </div>
                                        </div>
                                        <div className={Qst.spaceBetween}>
                                            <div className={Qst.infoSection}>
                                                <div className={Qst.titleWrap}>
                                                    <h2 className={clsx(Qst.title, Qst.inside)}>
                                                        <span className={Qst.bold}>신규 지배인님의 국적을 선택</span>해 주세요.
                                                    </h2>
                                                    <div className={Qst.titleExplain}>
                                                        외국인인 경우 국적, 외국법인인 경우 본점이 위치한 나라를 선택해 주세요.
                                                    </div>
                                                </div>  
                                                <Select className={Qst.select} style={{width:'142px'}} name={`branchOfficeInstalls[${idx}].manager.nationalityType`} value={item.manager.nationalityType ?? ''} onChange={(e) => BranchOfficeInstallVm.setNationalityType(e,idx)} errorText={<Error name={`branchOfficeInstalls[${idx}].manager.nationalityType`} value={item.manager.nationalityType} errorCase={{required:'국적을 선택해 주세요.'}} style={{width:'157px'}}/>}>
                                                    <option value="">-</option>
                                                    <option value="일본">일본</option>
                                                    <option value="미국">미국</option>
                                                    <option value="영국">영국</option>
                                                    <option value="중국">중국(본토)</option>
                                                    <option value="프랑스">프랑스</option>
                                                    <option value="독일">독일</option>
                                                    <option value="캐나다">캐나다</option>
                                                    <option value="홍콩">홍콩</option>
                                                    <option value="기타">기타</option>
                                                </Select>
                                                {item.manager.nationalityType === "기타" &&
                                                    <div className={clsx(Qst.rowWrap, st.rowWrap)}>
                                                        <InputText className={Qst.inputText} style={{width:'276px'}} explain="기타 국적" name={`branchOfficeInstalls[${idx}].manager.nationalityOthers`} value={item.manager.nationalityOthers} onChange={(e) => BranchOfficeInstallVm.setNationalityOthers(e,idx)} errorText={<Error name={`branchOfficeInstalls[${idx}].manager.nationalityOthers`} value={item.manager.nationalityOthers} errorCase={{required:'기타국적을 입력해 주세요.'}}/>}/>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className={Qst.spaceBetween}>
                                            <div className={Qst.infoSection}>
                                                <div className={Qst.titleWrap}>
                                                    <h2 className={clsx(Qst.title, Qst.inside)}>
                                                        <span className={Qst.bold}>신규 지배인님의 이름을 로마자와 한글로 입력</span>해 주세요.
                                                    </h2>
                                                    <div className={Qst.titleExplain}>
                                                        외국인의 정식 명칭을 로마자(영문)와 한글 발음으로 입력해 주세요.
                                                    </div>
                                                    <div className={Qst.titleExTextBox}>
                                                        <TextBox title="로마자(영문)" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>Jane Austen</span></TextBox>
                                                        <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>제인 오스틴</span></TextBox>
                                                    </div>
                                                    <div className={Qst.titleExplain}>
                                                        영문이 아닌 외국성함은 로마자(영문)으로 변환하여 한글 발음으로 입력해 주세요.
                                                    </div>
                                                    <div className={Qst.titleExTextBox}>
                                                        <TextBox title="로마자(영문)로 변환" titleWidth="145px" className={Qst.nameTextBox} style={{width:'171px', height:'40px', padding:'9px 20px'}}>
                                                        <span className={Qst.text}>高竹</span><Icon icon="arrow" className={Qst.arrow} /><span className={Qst.text}>Takamura</span>
                                                        </TextBox>
                                                        <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'134px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>타카무라</span></TextBox>
                                                    </div>
                                                </div>
                                                <div className={Qst.rowWrap}>
                                                    <InputText className={Qst.inputText} style={{width:'276px'}} explain="로마자(영문) 표기" name={`branchOfficeInstalls[${idx}].manager.englishName`} value={item.manager.englishName} onChange={(e) => BranchOfficeInstallVm.setEnglishName(e,idx)} errorText={<Error name={`branchOfficeInstalls[${idx}].manager.englishName`} value={item.manager.englishName} errorCase={{
                                                        required: '이름을 로마자로 입력해 주세요.',
                                                        pattern: {
                                                        value: regExp.onlyEnglish().test(item.manager.englishName),
                                                        message: '로마자로 입력해 주세요.'
                                                        }
                                                    }}/>} 
                                                    />
                                                    <InputText className={Qst.inputText} style={{width:'276px'}} explain="한글 발음 표기" name={`branchOfficeInstalls[${idx}].manager.name`} value={item.manager.name} onChange={(e) => BranchOfficeInstallVm.setName(e,idx)} errorText={<Error name={`branchOfficeInstalls[${idx}].manager.name`} value={item.manager.name} errorCase={{
                                                        required: '이름을 한글로 입력해 주세요.',
                                                        pattern: {
                                                        value: regExp.onlyKorean().test(item.manager.name),
                                                        message: '한글로 입력해 주세요.'
                                                        }
                                                    }}/>} 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={Qst.spaceBetween}>
                                            <div className={Qst.infoSection}>
                                                <div className={Qst.titleWrap}>
                                                    <h2 className={clsx(Qst.title, Qst.inside)}>
                                                        <span className={Qst.bold}>{item.manager.name ? item.manager.name : '신규 지배인'}님의 여권에 기재된 생년월일을 입력</span>해 주세요.
                                                    </h2>
                                                </div>
                                                <div>
                                                    <div className={Qst.birthWrap}>
                                                        <InputText style={{width:'130px'}} placeholder="0000" name={`branchOfficeInstalls[${idx}].manager.birthYear`} value={item.manager.birthYear} onChange={(e) => BranchOfficeInstallVm.setBirthYear(e, idx)} maxLength="4" />
                                                        <span className={Qst.birthText}>년</span>
                                                    </div>
                                                    <div className={Qst.birthWrap}>
                                                        <InputText style={{width:'108px'}} placeholder="00" name={`branchOfficeInstalls[${idx}].manager.birthMonth`} value={item.manager.birthMonth} onChange={(e) => BranchOfficeInstallVm.setBirthMonth(e, idx)} maxLength="2" onBlur={(e) => BranchOfficeInstallVm.birthDateBlur(e, idx, 'month')} />
                                                        <span className={Qst.birthText}>월</span>
                                                    </div>
                                                    <div className={Qst.birthWrap}>
                                                        <InputText style={{width:'108px'}} placeholder="00" name={`branchOfficeInstalls[${idx}].manager.birthDay`} value={item.manager.birthDay} onChange={(e) => BranchOfficeInstallVm.setBirthDay(e, idx)} maxLength="2" onBlur={(e) => BranchOfficeInstallVm.birthDateBlur(e, idx, 'day')} />
                                                        <span className={Qst.birthText}>일</span>
                                                    </div>
                                                </div>
                                                <div style={{marginTop:'-27px'}}>
                                                    <Error name={`branchOfficeInstalls[${idx}].manager.birthYear`} value={item.manager.birthYear} 
                                                    errorCase={{
                                                        required:'연도를 입력해 주세요.',
                                                        pattern: {
                                                        value: regExp.year().test(item.manager.birthYear),
                                                        message: '올바른 연도를 입력해 주세요'               
                                                        }
                                                    }}
                                                    />
                                                    <Error name={`branchOfficeInstalls[${idx}].manager.birthMonth`} value={item.manager.birthMonth} 
                                                    errorCase={{
                                                        required: BranchOfficeInstallVm.displayBirthErrorMessage(idx, 'month') ? '월을 입력해 주세요.' : null,
                                                        pattern: {
                                                        value: BranchOfficeInstallVm.displayBirthErrorMessage(idx, 'month') ? regExp.month().test(item.manager.birthMonth) : null,
                                                        message: '올바른 월을 입력해 주세요'               
                                                        }
                                                    }}
                                                    />
                                                    <Error name={`branchOfficeInstalls[${idx}].manager.birthDay`} value={item.manager.birthDay} 
                                                    errorCase={{
                                                        required: BranchOfficeInstallVm.displayBirthErrorMessage(idx, 'day') ? '일(날짜)을 입력해 주세요.' : null,
                                                        pattern: {
                                                        value: BranchOfficeInstallVm.displayBirthErrorMessage(idx, 'day') ? regExp.day().test(item.manager.birthDay) : null,
                                                        message: '올바른 일(날짜)을 입력해 주세요.'           
                                                        }
                                                    }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={Qst.spaceBetween} style={{paddingBottom:'30px'}}>
                                            <div className={Qst.infoSection}>
                                                <div className={Qst.titleWrap}>
                                                    <h2 className={clsx(Qst.title, Qst.inside)}>
                                                        <span className={Qst.bold}>{item.manager.name ? item.manager.name : '신규 지배인'}님</span>의 <span className={Qst.bold}>거소신고(외국인등록) 사실증명상 도로명 주소</span>를 입력해 주세요.
                                                    </h2>
                                                    <div className={clsx(Qst.titleExplain, Qst.noneMark)} style={{"color":"#fd6200"}}>
                                                        반드시 국내 거소신고(외국인등록) 사실증명과 정확히 일치하는 주소를 입력해주세요.
                                                    </div>
                                                    <div className={clsx(Qst.titleExplain, Qst.noneMark)} style={{"color":"#fd6200"}}>
                                                        거소신고(외국인등록) 사실증명상 주소와 일치하지 않는 경우, 등기신청이 거절됩니다. (5~7일 더 걸림)
                                                    </div>
                                                </div>
                                                <div className={Qst.rowWrap}>
                                                    <InputText className={Qst.inputText} style={{width:'380px'}} explain="도로명 주소" 
                                                    value={item.manager.address?.address || ''} 
                                                    readOnly 
                                                    onClick={() => BranchOfficeInstallVm.openManagerAddressModal(true, idx)} 
                                                    name={`branchOfficeInstalls[${idx}].manager.address`}
                                                    errorText={<Error name={`branchOfficeInstalls[${idx}].manager.address`} value={item.manager.address?.address || ''} errorCase={{required:'도로명 주소를 입력해 주세요.'}}/>}/>
                                                    <SquareBtn onClick={() => BranchOfficeInstallVm.openManagerAddressModal(true, idx)}>도로명 주소 찾기</SquareBtn>
                                                </div>
                                                <div className={Qst.rowWrap}>
                                                    <InputText className={Qst.inputText} style={{width:'273px'}} explain="상세 주소" errorText={<Error name={`branchOfficeInstalls[${idx}].manager.detailAddress`} value={item.manager.detailAddress} errorCase={{
                                                        required:'상세 주소를 입력해 주세요.',
                                                        pattern : {
                                                            value: regExp.onlyKorNum_hyphen_comma().test(item.manager.detailAddress),
                                                            message: <span>임대차 계약서상 영문이 있다면, <br/>한글로 옮겨적어주세요.<br/>EX) B동 &gt; 비동</span>
                                                        }
                                                    }}/>} placeholder="상세주소 없을 시, [없음] 입력" value={item.manager.detailAddress} onChange={(e) => BranchOfficeInstallVm.managerAddress_detailAddress(e, idx)} name={`branchOfficeInstalls[${idx}].manager.detailAddress`}/>
                                                    <div className={Qst.symbol}>
                                                        <span className={Qst.symbolIcon}>&#40;&nbsp;</span><span className={clsx(Qst.symbolText, BranchOfficeInstallVm.managerAddress_bnameFontSizeChange(idx) ? Qst.fontSize : null)}>{item.manager.address?.bname || '법정동'},&nbsp;</span>
                                                        <InputText style={{width:'200px'}} explain="건물명" placeholder="없을 시, [없음] 입력" value={item.manager.nameOfBuilding} onChange={(e) => BranchOfficeInstallVm.managerAddress_nameOfBuilding(e, idx)} name={`branchOfficeInstalls[${idx}].manager.nameOfBuilding`} errorText={<Error name={`branchOfficeInstalls[${idx}].manager.nameOfBuilding`}  value={item.nameOfBuilding} style={{width:'260px'}} errorCase={{
                                                            required:'건물명을 입력해 주세요.',
                                                            pattern : {
                                                                value: regExp.onlyKorNum_hyphen_comma().test(item.manager.nameOfBuilding),
                                                                message: <span>건물명이 영문인 경우, 한글<br/>발음으로 옮겨적어 주세요. <br/>EX) AB타워 &gt; 에이비 타워</span>
                                                            }
                                                        }}/>}/>
                                                        <span className={Qst.symbolIcon}>&nbsp;&#41;</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    }
                                </>
                                }
                            </Drop>
                        </ContentBox>
                    </Question>
                )
            })}
            <Question list={true}>
                <ContentBox>
                <button type="button" className={Qst.stockholderAddBtn} onClick={() => BranchOfficeInstallVm.addNewBranchOffice()}>신규지점 추가하기<Icon icon="addGray" className={Qst.addGray}/></button>
                </ContentBox>
            </Question>
            {/* 도로명주소 팝업 */}
            {BranchOfficeInstallVm.state.branchAddressModal && //신규 지점 주소
                <SearchAddress onClose={() => BranchOfficeInstallVm.openBranchAddressModal(false,null)} onComplete={(data) => BranchOfficeInstallVm.branchAddressComplate(data)} />
            }
            {BranchOfficeInstallVm.state.managerAddressModal && //지배인 주소
                <SearchAddress onClose={() => BranchOfficeInstallVm.openManagerAddressModal(false,null)} onComplete={(data) => BranchOfficeInstallVm.managerAddressComplate(data)} />
            }
        </form>
    ));
}
export default BranchOfficeInstall;