import { observable, runInAction } from 'mobx';

const StockCompanySealStepModel = observable({
    StockholdersMeetingMusterNotice : {
        stockholderMeetingMusterNoticeType : null,
        주주총회일 : {
            day : "",
            month : "",
            year : ""
        },
        address : "",
        nameOfBuilding : "",
        detailAddress : "",
        stockholderMeetingPlaceType : null,
        stockholderMeetingMusterNoticeFiles : [],
        uploadFiles : [],
        deleteFileIds : [],
        musterNoticeScheduledDate : {
            day : "",
            month : "",
            year : ""
        },
        noticeSendMethod: null,
    },
    StockholdersMeetingSealProvidingStockholdersCaseOne : {
        stockholderMeetingType : null,
        stockholders : []
    },
    StockholdersMeetingAttendStockholders : {
        stockholderMeetingType : null,
        stockholders : []
    },
    StockholdersMeetingSealProvidingStockholdersCaseTwo : {
        stockholderMeetingType : null,
        uploadFiles : [],
        deleteFileIds : [],
        files : [],
        stockholders : []
    },
    setStockholdersMeetingMusterNotice(pageDetailData) {
        let stockholdersMeetingMusterNotice = this.StockholdersMeetingMusterNotice;
        stockholdersMeetingMusterNotice.stockholderMeetingMusterNoticeType = pageDetailData?.stockholderMeetingMusterNoticeType || null;
        stockholdersMeetingMusterNotice.주주총회일.day = pageDetailData?.주주총회일?.day || "";
        stockholdersMeetingMusterNotice.주주총회일.month = pageDetailData?.주주총회일?.month || "";
        stockholdersMeetingMusterNotice.주주총회일.year = pageDetailData?.주주총회일?.year || "";
        stockholdersMeetingMusterNotice.stockholderMeetingPlaceType = pageDetailData?.stockholderMeetingPlaceType || null;
        stockholdersMeetingMusterNotice.address = pageDetailData.address || "";
        stockholdersMeetingMusterNotice.nameOfBuilding = pageDetailData.nameOfBuilding || "";
        stockholdersMeetingMusterNotice.detailAddress = pageDetailData.detailAddress || "";
        stockholdersMeetingMusterNotice.uploadFiles = [];
        stockholdersMeetingMusterNotice.stockholderMeetingMusterNoticeFiles = pageDetailData?.stockholderMeetingMusterNoticeFiles || [];
        stockholdersMeetingMusterNotice.musterNoticeScheduledDate.day = pageDetailData?.musterNoticeScheduledDate?.day || "";
        stockholdersMeetingMusterNotice.musterNoticeScheduledDate.month = pageDetailData?.musterNoticeScheduledDate?.month || "";
        stockholdersMeetingMusterNotice.musterNoticeScheduledDate.year = pageDetailData?.musterNoticeScheduledDate?.year || "";
        stockholdersMeetingMusterNotice.noticeSendMethod = pageDetailData?.noticeSendMethod || null;
    },
    setStockholdersMeetingSealProvidingStockholdersCaseOne(pageDetailData) {
        let StockholdersMeetingSealProvidingStockholdersCaseOne = this.StockholdersMeetingSealProvidingStockholdersCaseOne;
        StockholdersMeetingSealProvidingStockholdersCaseOne.stockholderMeetingType = pageDetailData.stockholderMeetingType || null;
        if(pageDetailData.stockholders.length) {
            StockholdersMeetingSealProvidingStockholdersCaseOne.stockholders = pageDetailData.stockholders.map((stockholder) => {
                return {
                    englishName : stockholder.englishName || "",
                    id : stockholder.id || "",
                    name : stockholder.name || "",
                    nationalityOthers : stockholder.nationalityOthers || "",
                    nationalityType : stockholder.nationalityType || "",
                    sealProviderType : stockholder.sealProviderType || "",
                    uploadFiles : [],
                    files : stockholder.files || [],
                    deleteFileIds : [],
                    filesSubmit : false
                }
            })
        }else{
            StockholdersMeetingSealProvidingStockholdersCaseOne.stockholders = []
        }
    },
    setStockholdersMeetingAttendStockholders(pageDetailData) {
        let StockholdersMeetingAttendStockholders = this.StockholdersMeetingAttendStockholders;
        StockholdersMeetingAttendStockholders.stockholderMeetingType = pageDetailData.stockholderMeetingType || null;
        if(pageDetailData.stockholders.length) {
            StockholdersMeetingAttendStockholders.stockholders = pageDetailData.stockholders.map((stockholder) => {
                return {
                    englishName : stockholder.englishName || "",
                    id : stockholder.id || "",
                    name : stockholder.name || "",
                    nationalityOthers : stockholder.nationalityOthers || "",
                    nationalityType : stockholder.nationalityType || "",
                    sealProviderType : stockholder.sealProviderType || "",
                }
            })
        }else{
            StockholdersMeetingAttendStockholders.stockholders = []
        }
    },
    setStockholdersMeetingSealProvidingStockholdersCaseTwo(pageDetailData) {
        let StockholdersMeetingSealProvidingStockholdersCaseTwo = this.StockholdersMeetingSealProvidingStockholdersCaseTwo;
        StockholdersMeetingSealProvidingStockholdersCaseTwo.stockholderMeetingType = pageDetailData.stockholderMeetingType || null;
        StockholdersMeetingSealProvidingStockholdersCaseTwo.uploadFiles = [];
        StockholdersMeetingSealProvidingStockholdersCaseTwo.files = pageDetailData?.files || [];
        if(pageDetailData.stockholders.length) {
            StockholdersMeetingSealProvidingStockholdersCaseTwo.stockholders = pageDetailData.stockholders.map((stockholder) => {
                return {
                    id : stockholder.id || "",
                    name : stockholder.name || "",
                    sealProviderType : stockholder.sealProviderType || "",
                    sealProvidingYn : stockholder.sealProvidingYn || false
                }
            })
        }else{
            StockholdersMeetingSealProvidingStockholdersCaseTwo.stockholders = []
        }
    }
});

export default StockCompanySealStepModel;