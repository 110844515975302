import { useObserver } from 'mobx-react';
import KoreanPersonVm from './KoreanPersonVm';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from '../StandbySimpleNewSharesAcquirer.module.scss';
import clsx from 'clsx';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';

function KoreanPerson(props) {
    return useObserver(() => (
        <>
            <div className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    <div className={Qst.titleWrap}>
                        <h2 className={clsx(Qst.title, Qst.inside)}>
                            <span className={Qst.bold}>성함을 입력</span>해 주세요.
                        </h2>
                    </div>
                    <InputText placeholder="ex)홍길동" style={{width:'316px'}} value={props.info.koreanName} onChange={(e) => KoreanPersonVm.setKoreanName(e,props.idx)} name={`newSharesAcquirers[${props.idx}].koreanName`} errorText={<Error name={`newSharesAcquirers[${props.idx}].koreanName`} value={props.info.koreanName} errorCase={{required:'성함을 입력해 주세요.'}}/>} disabled={KoreanPersonVm.disabledNameYn(props.idx, props.reviseInfoList[props.idx]?.koreanName)}/><span className={Qst.nameInput}>님</span>
                    {(props.info.isNewYn && props.reviseInfoList[props.idx]?.koreanName === false) && 
                        <button type="button" className={st.reviseBtn} onClick={() => props.setReviseInfoList(props.idx, "koreanName")}><span>답변 수정하기</span></button>
                    }
                </div>
            </div>
            <div className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    <div className={Qst.titleWrap}>
                        <h2 className={clsx(Qst.title, Qst.inside)}>
                            <span className={Qst.bold}>{props.info.koreanName? props.info.koreanName : '신주인수인'}님의 주민등록번호를 입력</span>해 주세요.
                        </h2>
                        <div className={Qst.titleExplain}>주민등록번호는 헬프미 스마트 보안 시스템에 의하여 안전하게 보관됩니다.</div>
                    </div>
                    <InputText placeholder="111111-1111111" style={{width:'316px'}} textType="kssn" value={props.info.kssn} onChange={(e) => KoreanPersonVm.setKssn(e,props.idx)} name={`newSharesAcquirers[${props.idx}].kssn`} disabled={!props.reviseInfoList[props.idx]?.kssn} errorText={<Error name={`newSharesAcquirers[${props.idx}].kssn`} value={props.info.kssn} errorCase={{
                        required: '주민등록번호를 입력해 주세요.',
                        validate: {
                        func: regExp.kssn(props.info.kssn),
                        message: '올바른 주민등록번호를 입력해 주세요.'
                        }
                    }}/>} />
                    {!props.reviseInfoList[props.idx]?.kssn && 
                        <button type="button" className={st.reviseBtn} onClick={() => props.setReviseInfoList(props.idx, "kssn")}><span>답변 수정하기</span></button>
                    }
                </div>
            </div>
            <div className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    <div className={Qst.titleWrap}>
                        <h2 className={clsx(Qst.title, Qst.inside)}>
                            신주인수인님의 <span className={Qst.bold}>주민등록상 도로명주소</span>를 입력해 주세요.
                        </h2>
                    </div>
                    <InputText style={{width:'560px'}} value={props.info.koreanAddress} onChange={(e) => KoreanPersonVm.setKoreanAddress(e,props.idx)} name={`newSharesAcquirers[${props.idx}].koreanAddress`} disabled={!props.reviseInfoList[props.idx]?.koreanAddress} errorText={<Error name={`newSharesAcquirers[${props.idx}].koreanAddress`} value={props.info.koreanAddress} errorCase={{required:'주소를 입력해 주세요.'}}/>}/>
                    {!props.reviseInfoList[props.idx]?.koreanAddress && 
                        <button type="button" className={st.reviseBtn} onClick={() => props.setReviseInfoList(props.idx, "koreanAddress")}><span>답변 수정하기</span></button>
                    }
                </div>
            </div>
        </>
    ));
}
export default KoreanPerson;