import ConvertibleBondStepModel from '@model/changeRegistration/ConvertibleBondStepModel';
import { runInAction } from 'mobx';
import { _alert, _confirm } from "@model/dialogModel";

class ForeignerCompanyVm {
    setNationalityType(e, idx) {
        runInAction(() => {
            ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].nationalityType = e.target.value;
        })
    }
    setNationalityOthers(e, idx) {
        runInAction(() => {
            ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].nationalityOthers = e.target.value;
        })
    }
    setEnglishName(e,idx) {
        runInAction(() => {
            ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].englishName = e.target.value;
        })
    }
    setKoreanName(e,idx) {
        runInAction(() => {
            ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].koreanName = e.target.value;
        })
    }
    setForeignAddress(e,idx) {
        runInAction(() => {
            ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].foreignAddress = e.target.value;
        })
    }
    setKoreanAddress(e,idx) {
        runInAction(() => {
            ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].koreanAddress = e.target.value;
        })
    }
    foreignerCompanyRepresentatives_englishName(e, idx, idx2) { //대표자 로마자 성함
        runInAction(() => {
            ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].foreignerCompanyRepresentatives[idx2].englishName = e.target.value;
        })
    }
    foreignerCompanyRepresentatives_koreanName(e, idx, idx2) { //대표자 한글 성함
        runInAction(() => {
            ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].foreignerCompanyRepresentatives[idx2].koreanName = e.target.value;
        })
    }
    foreignerCompanyRepresentatives(idx) {
        runInAction(() => {
            ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].foreignerCompanyRepresentatives.push({
                id: null,
                englishName: "",
                koreanName: ""
            })
        })
    }
    async setForeignerCompanyRepresentativesCloseBtn(idx,idx2) {
        const foreignerCompanyRepresentatives = ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].foreignerCompanyRepresentatives;
        let name = foreignerCompanyRepresentatives[idx2].koreanName; 
        if(!name) {
            name = '해당 대표';
        }
        const conf = await _confirm(name+'님의 정보를 삭제하시겠습니까?')
        if(conf){
            return foreignerCompanyRepresentatives.splice(idx2,1);
        } else {
            return 
        }
    }
}

export default new ForeignerCompanyVm();