import { observable } from 'mobx';

const portalModel = observable({
    modalContent: null,
    btn: null,
    closeBtn: true,
    setModalClose() {
        portalModel.modalContent = null;
        portalModel.btn = null;
        portalModel.closeBtn = true;
    }
});

export default portalModel;
