import st from './ExecutiveModal.module.scss';
import { runInAction } from "mobx";
import portalModel from '@model/portalModel';
import { pageRouter } from '@model/pageStepModel';

const shareholder = () => {
    runInAction(() => {
        pageRouter.replace(`/step/establish/ShareholderAndExecutiveInfo/Shareholder`);
        portalModel.modalContent = null;
        portalModel.btn = null;
        portalModel.closeBtn = true;
    })  
}

const executive = () => {
    runInAction(() => {
        portalModel.modalContent = null;
        portalModel.btn = null;
        portalModel.closeBtn = true;
    })
}

const modal = {
    modalContent: function(shareholder, executive) {
        return (
            <div className={st.Modal}>
                <div className={st.title} style={{width:'335px'}}>설립인원을 <span className={st.bold}>3명 이상</span> 입력해 주세요.</div>
                <div className={st.modalContent}>
                    <p className={st.content}>
                        고객님! 현재 설립인원을 3명 이상으로 선택하셨는데, 입력하신 설립인원은 총 {shareholder+executive}명입니다. 주주 또는 임원의 정보를 추가해 주세요. (주주 {shareholder}명, 임원 {executive}명 입력 완료)
                    </p>
                </div>
            </div>
        )
    },
    btn: [
        {title: '주주 추가하기', type: 'negative', event: ()=> {shareholder()}},
        {title: '임원 추가하기', type: 'positive', event: ()=> {executive()}},
    ]
}

export default modal;