import st from './StandByBoardMeetingBasicInfoModal.module.scss';
import portalModel from '@model/portalModel';

const modal = {
    modalContent: 
        <div className={st.Modal}>
            <div className={st.title}>
                <span className={st.bold}>고객님!</span> 출석한 이사가 부족해요.
            </div>
            <div className={st.modalContent}>
                이사 과반수(=50% 초과)가 출석해야 해요.
            </div>
        </div>
    ,
    btn: [{title: '확인', type: 'positive', event: ()=> {portalModel.setModalClose()}}]
}

export default modal;

