import finalSubmissionModel from "@model/establish/finalSubmissionModel";
import smartformAppModel from "@model/smartformAppModel";
import { observable, runInAction, toJS } from "mobx";
import st from './BeforeFinalCheck.module.scss';
import submitEstimateService from '@service/submitEstimateService';
import { _alert, _confirm } from "@model/dialogModel";
import {addCommas} from '@common/module/replaceNumber';

class viewModel {
    constructor() {
        this.state = observable({
            checkPage: 'start',
            submitBtnDisabledYn: false
        });
    }
    get pageDepth() {
        const type = smartformAppModel.smartFormType;
        switch(type) {
            case 'ESTABLISHMENT_STOCK_COMPANY':
                if(finalSubmissionModel.etc.canUseYn !== null) { //공인인증서 페이지가 있는 경우
                    return ['start', 'companySize', 'companyName', 'companyAddress', 'parValue', 'shareholderInvestment', 'shareholders', 'executiveMembers', 'businessPurpose', 'option', 'chooseStamp', 'certificate', 'freeLicenseRegistration']
                } else { //공인인증서 페이지가 없는 경우
                    return ['start', 'companySize', 'companyName', 'companyAddress', 'parValue', 'shareholderInvestment', 'shareholders', 'executiveMembers', 'businessPurpose', 'option', 'chooseStamp', 'freeLicenseRegistration']
                }
            case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY':
                if(finalSubmissionModel.etc.canUseYn !== null) { //공인인증서 페이지가 있는 경우
                    return ['start', 'companySize', 'companyName', 'companyAddress', 'parValue', 'shareholderInvestment', 'shareholders', 'executiveMembers', 'businessPurpose', 'option', 'chooseStamp', 'certificate', 'freeLicenseRegistration', 'agriculturalNoticeOfEstablishment']
                } else { //공인인증서 페이지가 없는 경우
                    return ['start', 'companySize', 'companyName', 'companyAddress', 'parValue', 'shareholderInvestment', 'shareholders', 'executiveMembers', 'businessPurpose', 'option', 'chooseStamp', 'freeLicenseRegistration', 'agriculturalNoticeOfEstablishment']
                }
            case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY':
                if(finalSubmissionModel.etc.canUseYn !== null) { //공인인증서 페이지가 있는 경우
                    return ['start', 'companySize', 'companyName', 'companyAddress', 'parValue', 'shareholderInvestment', 'shareholders', 'executiveMembers', 'businessPurpose', 'option', 'chooseStamp', 'certificate', 'freeLicenseRegistration', 'fisheryNoticeOfEstablishment']
                } else { //공인인증서 페이지가 없는 경우
                    return ['start', 'companySize', 'companyName', 'companyAddress', 'parValue', 'shareholderInvestment', 'shareholders', 'executiveMembers', 'businessPurpose', 'option', 'chooseStamp', 'freeLicenseRegistration', 'fisheryNoticeOfEstablishment']
                }
            case 'ESTABLISHMENT_LIMITED_COMPANY':
                if(finalSubmissionModel.etc.canUseYn !== null) { //공인인증서 페이지가 있는 경우
                    return ['start', 'companyName', 'companyAddress', 'parValue', 'shareholderInvestment', 'shareholders', 'executiveMembers', 'businessPurpose', 'chooseStamp', 'certificate', 'freeLicenseRegistration']
                } else { //공인인증서 페이지가 없는 경우
                    return ['start', 'companyName', 'companyAddress', 'parValue', 'shareholderInvestment', 'shareholders', 'executiveMembers', 'businessPurpose', 'chooseStamp', 'freeLicenseRegistration']
                }
            case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY':
                if(finalSubmissionModel.etc.canUseYn !== null) { //공인인증서 페이지가 있는 경우
                    return ['start', 'companyName', 'companyAddress', 'shareholderInvestment', 'shareholders', 'executiveMembers', 'businessPurpose', 'chooseStamp', 'certificate', 'freeLicenseRegistration']
                } else { //공인인증서 페이지가 없는 경우
                    return ['start', 'companyName', 'companyAddress', 'shareholderInvestment', 'shareholders', 'executiveMembers', 'businessPurpose', 'chooseStamp', 'freeLicenseRegistration']
                }
        }
    }
    page(page) { 
        switch(page) {
            case 'start':
                return {
                    key: page,
                    question: <span>수고하셨습니다.<br/><span className={st.questionBold}>마지막으로 입력 내용을 확인</span>해볼까요?</span>,
                    link: null
                }
            case 'companySize':
                return {
                    key: page,
                    question: <span>이 내용대로 <span className={st.questionBold}>법인설립을 진행</span>할까요?</span>,
                    link: '/step/establish/CompanyInfo/CompanySize'
                }
            case 'companyName':
                return {
                    key: page,
                    question: <span>이 <span className={st.questionBold}>회사이름으로 법인설립을 진행</span>할까요?</span>,
                    link: '/step/establish/CompanyInfo/CompanyName'
                }
            case 'companyAddress':
                return  {
                    key: page,
                    question: <span>회사 주소가 <span className={st.questionBold}>임대차 계약서 또는 전대차 계약서에 나온 주소와 일치</span>하나요?<br/> 특히 <span className={st.questionBold}>층, 호수, 건물이름이 일치</span>하는지 확인해주세요.<br/>(계약서에는 지번주소로 나와 있다면 도로명으로 변환한 주소가 일치하면 됩니다.)<br/><span className={st.questionBold}>우편물 받을 주소가 정확</span>한가요?</span>,
                    link: '/step/establish/CompanyInfo/CompanyAddress'
                }
            case 'parValue':
                return  {
                    key: page,
                    question: <span>이 <span className={st.questionBold}>{(this.smartFormType() === '유한회사' || this.smartFormType() === '유한책임회사') ? '출자 1좌의 금액' : '1주당 금액'}으로 법인설립을 진행</span>할까요?</span>,
                    link: '/step/establish/ShareholderAndExecutiveInfo/ParValue'
                }
            case 'shareholderInvestment':
                return {
                    key: page,
                    question: <span>이 <span className={st.questionBold}>자본금으로 법인설립을 진행</span>할까요?</span>,
                    link: '/step/establish/ShareholderAndExecutiveInfo/ShareholderInvestment'
                }
            case 'shareholders': 
                return  {
                    key: page,
                    question: 
                        <span>
                            {this.name()}{(this.smartFormType() === '유한회사' || this.smartFormType() === '유한책임회사') && '(=사원)'}의 <span className={st.questionBold}>성함, 주민등록번호(생년월일), 주소, 투자금이 정확</span>한가요?
                            {this.smartFormType() === '농업회사법인' &&
                            <><br/>주주분이 <span className={st.questionBold}>모두 농업인/농업회사법인/영농조합법인</span>이 맞는가요?</>
                            }
                            {this.smartFormType() === '어업회사법인' &&
                            <><br/>주주분이 <span className={st.questionBold}>모두 어업인/어업회사법인/영어조합법인</span>이 맞는가요?</>
                            }
                        </span>,
                    link: '/step/establish/ShareholderAndExecutiveInfo/Shareholder'
                }
            case 'executiveMembers':
                return {
                    key: page,
                    question: 
                        <span>임원의 <span className={st.questionBold}>성함, 주민등록번호(생년월일), 직책이 정확</span>하나요?<br/>대표님의 <span className={st.questionBold}>주소가 주민등록초본(또는 등본)과 정확히 일치</span>하나요?<br/><br/>대표님의 <span className={st.questionBold}>주소가 초본(등본)과 한글자라도 다른 경우</span>,<br/>등기소에서는 <span className={st.questionBold}>보정명령</span>을 내립니다.<br/>이 경우 <span className={st.questionBold}>5~7일의 시간이 더 걸리고</span>, 서류 수정 수수료(3~5만원)가<br/>발생하므로 확실히 확인 부탁드립니다.<br/><br/><span className={st.questionBold}>동, 호수, 아파트, 빌라 이름</span>을 <span className={st.questionBold}>특히 주의</span>해주세요.<br/>초본(등본)에는 도로명주소 사이트에 뜨는 건물 이름과 다르게<br/>등록되어 있는 경우가 많습니다.
                        {this.smartFormType() === '농업회사법인' && 
                        <><br/><br/><span className={st.questionBold}>이사, 대표 중 1/3 이상이 농업인</span>이 맞는가요?<br/>(대표가 꼭 농업인일 필요는 없지만, 1/3 이상이 농업인이어야 합니다.)</>
                        }
                        {this.smartFormType() === '어업회사법인' && 
                        <><br/><br/><span className={st.questionBold}>이사, 대표 중 1/3 이상이 어업인</span>이 맞는가요?<br/>(대표가 꼭 어업인일 필요는 없지만, 1/3 이상이 어업인이어야 합니다.)</>
                        }
                    </span>,
                    link: '/step/establish/ShareholderAndExecutiveInfo/Executive'
                }
            case 'businessPurpose':
                return {
                    key: page,
                    question: <span>이 <span className={st.questionBold}>사업목적으로 법인설립을 진행</span>할까요?</span>,
                    link: '/step/establish/EtcInfo/BusinessPurpose'
                }
            case 'option':
                return {
                    key: page,
                    question: <span><span className={st.questionBold}>스톡옵션, 주식양도제한, 공고방법, 사회적기업을 잘 선택</span>하셨나요?<br/>공고방법은 별도로 선택하지 않았다면, 아시아경제신문으로 자동선택 됩니다.</span>,
                    link: '/step/establish/EtcInfo/Option'
                }
                
            case 'chooseStamp':
                return {
                    key: page,
                    question: <span><span className={st.questionBold}>인감도장을 이대로 제작</span>할까요?</span>,
                    link: '/step/establish/EtcInfo/ChooseStamp'
                }
            case 'certificate':
                return {
                    key: page,
                    question: <span><span className={st.questionBold}>공동인증서(구 공인인증서) 사용여부를 잘 선택</span>하셨나요?</span>,
                    link: '/step/establish/EtcInfo/Certificate'
                }
            case 'freeLicenseRegistration':
                return {
                    key: page,
                    question: <span><span className={st.questionBold}>무료사업자등록 서비스 이용여부를 잘 선택</span>하셨나요?</span>,
                    link: '/step/establish/EtcInfo/FreeLicenseRegistration'
                }
            case 'agriculturalNoticeOfEstablishment':
                return {
                    key: page,
                    question: <span>나중에 <span className={st.questionBold}>직접 설립통지를 해야 한다는 점 확인</span>하셨나요?</span>,
                    link: '/step/establish/EtcInfo/AgriculturalNoticeOfEstablishment'
                }
            case 'fisheryNoticeOfEstablishment':
                return {
                    key: page,
                    question: <span>나중에 <span className={st.questionBold}>직접 설립통지를 해야 한다는 점 확인</span>하셨나요?</span>,
                    link: '/step/establish/EtcInfo/FisheryNoticeOfEstablishment'
                }
        }
    }
    btnYes() { 
        const checkpage = this.state.checkPage;
        this.pageDepth.forEach((item, idx) => {
            if(item === checkpage) {
                if(idx === this.pageDepth.length-1) {
                    this.state.checkPage = null;
                } 
                this.state.checkPage = this.pageDepth[idx+1];
            }
        })
    }
    btnBack() {
        const checkpage = this.state.checkPage;
        this.pageDepth.forEach((item, idx) => {
            if(item === checkpage) {
                this.state.checkPage = this.pageDepth[idx-1];
            }
        })
    }
    companySize() { //참여 인원
        const companySize = finalSubmissionModel.corp.companySize;
        const shareholderCnt = finalSubmissionModel.shareholder.shareholders.length; //주주 인원
        const executiveCnt = finalSubmissionModel.executive.executives.length; //임원 인원
        let shareholder_executive = 0; //주주+임원 인원
        finalSubmissionModel.executive.executives.forEach((executive, idx) => {
            if(executive.isShareholder) {
                shareholder_executive++;
            }
        })
        if(companySize === "TWO") {
            return '2명'
        }
        else if(companySize === "OTHER") {
            return (shareholderCnt + executiveCnt - shareholder_executive) + '명' 
        }
    }
    initiatorRole() { //참여자 역할
        const initiatorRole = finalSubmissionModel.corp.initiatorRole;
        if(initiatorRole === "REPRESENTATIVE_DIRECTOR") {
            return '대표(지분 100%) + 이사(지분 0%)'
        }
        else if(initiatorRole === "REPRESENTATIVE_AUDITOR") {
            return '대표(지분 100%) + 감사(지분 0%)'
        }
        else if(initiatorRole === 'REPRESENTATIVE_SHAREHOLDERS') {
            return '대표(지분 0%) + 주주(지분 100%)'
        }
        else if(initiatorRole === 'REPRESENTATIVE_STOCKED_DIRECTOR') {
            return '대표(지분 0%) + 이사(지분 100%)'
        }
        else if(initiatorRole === 'REPRESENTATIVE_STOCKED_AUDITOR') {
            return '대표(지분 0%) + 감사(지분 100%)'
        }
    }
    get koreanCorpName() { //한글 회사명
        return finalSubmissionModel.corp.koreanCorpName;
    }
    get englishCorpName() { //영문 회사명
        return finalSubmissionModel.corp.englishCorpName;
    }
    get corpAddress() { //회사 주소
        return finalSubmissionModel.corp.corpAddress;
    }
    get deliveryAddress() { //우편물 받을 주소
        const corpAddress = finalSubmissionModel.corp.corpAddress;
        const deliveryAddress = finalSubmissionModel.corp.deliveryAddress;
        if(corpAddress === deliveryAddress) {
            return '회사주소와 같음'
        } else {
            return finalSubmissionModel.corp.deliveryAddress;
        }
    }
    get parValue() { //1주당 금액
        return String(finalSubmissionModel.shareholder.parValue);
    }
    get capital() { //자본금
        return String(finalSubmissionModel.shareholder.capital);
    }
    //주주정보
    get shareholders() {
        return finalSubmissionModel.shareholder.shareholders;
    }
    investmentPercent(idx) {
       const capital = finalSubmissionModel.shareholder.capital;
       const investment = finalSubmissionModel.shareholder.shareholders[idx].investment;
       return (investment / capital) * 100 ;
    }
    stock(idx) {
        const parValue = finalSubmissionModel.shareholder.parValue;
        const investment = finalSubmissionModel.shareholder.shareholders[idx].investment;
        return (investment / parValue) ;
    }
    get executives() { //임원정보
        return finalSubmissionModel.executive.executives;
    }
    isShareholder(idx) { //주주+임원 여부
        return finalSubmissionModel.executive.executives[idx].isShareholder;
    }
    isFarmerOrFisherman(idx) {
        return finalSubmissionModel.executive.executives[idx].isFarmerOrFisherman;
    }
    positionInfoDisplay(idx) { //사내이사(대표), 이사(대표), 업무집행자(대표)일 경우, 안내 문구 추가
        let 대표Cnt = 0, 사내이사Cnt = 0, 이사Cnt = 0, 업무집행자Cnt = 0;
        finalSubmissionModel.executive.executives.forEach((executive) => {
            if(executive.position === '대표') {
                대표Cnt++;
            } else if(executive.position === '사내이사') {
                사내이사Cnt++;
            } else if(executive.position === '이사') {
                이사Cnt++;
            } else if(executive.position === '업무집행자') {
                업무집행자Cnt++;
            }
        })

        let total = 대표Cnt + 사내이사Cnt + 이사Cnt + 업무집행자Cnt;

        if(total === 1 && this.executives[idx].position === '대표') {
            if(this.smartFormType() === '유한회사') { //유한회사인 경우
                return '유한회사'
            } else if(this.smartFormType() !== '유한회사' && this.smartFormType() !== '유한책임회사') { //주식회사인 경우(농어업 포함)
                return '주식회사'
            }
        }
    }
    get purposes() { //사업목적
        return finalSubmissionModel.etc.purposes;
    }
    stockOptionYn() { //스톡옵션
        const stockOptionYn = finalSubmissionModel.etc.stockOptionYn;
        if(stockOptionYn) {
            return '스톡옵션 추가'
        } else {
            return '추가하지 않음'
        }
    }
    shareTransferRestrictionsYn() { //주식양도제한
        const shareTransferRestrictionsYn = finalSubmissionModel.etc.shareTransferRestrictionsYn;
        if(shareTransferRestrictionsYn) {
            return '주식양도제한 추가'
        } else {
            return '추가하지 않음'
        }
    }
    get announcementMethod() { //공고방법
        return finalSubmissionModel.etc.announcementMethod;
    }
    prospectiveSocialEnterpriseYn() { //예비 사회적 기업 여부
        const prospectiveSocialEnterpriseYn = finalSubmissionModel.etc.prospectiveSocialEnterpriseYn;
        if(prospectiveSocialEnterpriseYn) {
            return '예비 사회적 기업으로 설립'
        } else {
            return '추가하지 않음'
        }
    }
    signText(signValue) {
        switch(signValue) {
            case '동글':
                return '동글(●)';
            case '별':
                return '별(★)';
            case '마름모':
                return '마름모(◆)';
            case '클로버':
                return '클로버(♣)';
            case '스페이드':
                return '스페이드(♠)';
            case '하트':
                return '하트(♥)';
            case '네모':
                return '네모(■)';
            case '삼각형':
                return '삼각형(▲)';
            case '동글1':
                return '동글1(①)';
            case '동글2':
                return '동글2(②)';
            case '동글3':
                return '동글3(③)';
            case '동글4':
                return '동글4(④)';
            case '동글5':
                return '동글5(⑤)';
            case '동글6':
                return '동글6(⑥)';
            case '동글7':
                return '동글7(⑦)';
            case '동글8':
                return '동글8(⑧)';
            case '동글9':
                return '동글9(⑨)';
        }
    }
    corpSeals() { //법인인감
        let corpSealsList = finalSubmissionModel.etc.corpSeals.map((corpSeal, idx) => {
            if(idx === 0) {
                return  <div key={idx}>
                            {corpSeal.name} 대표님 : {corpSeal.type === '기본형' ? '기본형 무료제공' : '고급형 선택 (+22,000원)'} {corpSeal?.symbol && <span className={st.stampSymbol}>비표 {this.signText(corpSeal.symbol)}</span>}
                        </div>
            } 
            else {
                return <div key={idx} className={st.stampData}>
                            {corpSeal.name} 대표님 : {corpSeal.type === '기본형' ? '기본형 선택 (+14,300원)' : '고급형 선택 (+36,300원)'} {corpSeal?.symbol && <span className={st.stampSymbol}>비표 {this.signText(corpSeal.symbol)}</span>}
                        </div>
            }
        })
        return corpSealsList;
    }
    usageSeal() { //사용인감
        const normal = finalSubmissionModel.etc.usageSeal.normalSealCount;
        const premium = finalSubmissionModel.etc.usageSeal.premiumSealCount;
        if(normal && premium) { 
            return  <div>
                        기본형 도장 {normal}개 추가, 고급형 도장 {premium}개 추가 (+{addCommas(String(normal*14300 + premium*36300))}원)
                        {finalSubmissionModel.etc.usageSeal.usageSealInfo.length !== 0 &&
                            <div>
                                <div style={{marginTop: '13px'}}>
                                    기본형 도장 비표 :
                                    {finalSubmissionModel.etc.usageSeal.usageSealInfo.map((item, idx) => {
                                        if(item.defaultYn === '기본형') {
                                            return <span className={st.stampSymbol}>{this.signText(item.symbol)}</span>
                                        }
                                    })}
                                </div>
                                <div style={{marginTop: '8px'}}>
                                    고급형 도장 비표 :
                                    {finalSubmissionModel.etc.usageSeal.usageSealInfo.map((item, idx) => {
                                        if(item.defaultYn === '고급형') {
                                            return <span className={st.stampSymbol}>{this.signText(item.symbol)}</span>
                                        }
                                    })}
                                </div>
                            </div>
                        }
                    </div>
        } 
        else if(normal !== 0 && premium === 0) {  
            return  <div>
                        기본형 도장 {normal}개 추가 (+{addCommas(String(normal*14300))}원)
                        {finalSubmissionModel.etc.usageSeal.usageSealInfo.length !== 0 &&
                            <div>
                                <div style={{marginTop: '13px'}}>
                                    기본형 도장 비표 :
                                    {finalSubmissionModel.etc.usageSeal.usageSealInfo.map((item, idx) => {
                                        if(item.defaultYn === '기본형') {
                                            return <span className={st.stampSymbol}>{this.signText(item.symbol)}</span>
                                        }
                                    })}
                                </div>
                            </div>
                        }   
                    </div> 
        }
        else if(normal === 0 && premium !== 0) {
            return  <div>
                        고급형 도장 {premium}개 추가 (+{addCommas(String(premium*36300))}원)
                        {finalSubmissionModel.etc.usageSeal.usageSealInfo.length !== 0 &&
                            <div>
                                <div style={{marginTop: '13px'}}>
                                    고급형 도장 비표 :
                                    {finalSubmissionModel.etc.usageSeal.usageSealInfo.map((item, idx) => {
                                        if(item.defaultYn === '고급형') {
                                            return <span className={st.stampSymbol}>{this.signText(item.symbol)}</span>
                                        }
                                    })}
                                </div>
                            </div>
                        }    
                    </div> 
        }
        else if(normal === 0 || premium === 0) {
            return '추가하지 않음'
        }
    }
    canUseYn() { //공인인증서
        const canUseYn = finalSubmissionModel.etc.canUseYn;
        if(canUseYn) {
            return '모두 사용가능 (전자등기로 진행)'
        } else if(canUseYn === false){
            return '사용하지 않음 (서류등기로 진행)'
        } else {
            return canUseYn; //공인인증서 페이지가 없는 경우
        }
    }
    freeLicenseRegistrationYn() { //무료사업자등록
        const freeLicenseRegistrationYn = finalSubmissionModel.etc.freeLicenseRegistrationYn;
        if(freeLicenseRegistrationYn) {
            return '무료사업자등록 신청'
        } else {
            return '신청하지 않음'
        }
    }
    get finalPayment() { //최종 결제금액
        const finalPayment = finalSubmissionModel.finalPayment;
        if(finalPayment === 0) {
            this.state.checkPage = null;
            return finalPayment;
        }
        return String(finalPayment);
    }
    noticeOfEstablishmentYn() { //설립통지 여부
        const noticeOfEstablishmentYn = finalSubmissionModel.etc.noticeOfEstablishmentYn;
        if(noticeOfEstablishmentYn) {
            return '확인 완료'
        }
        return ''
    }
    get isResponse() { //설립 최종 제출 데이터 조회 여부
        return finalSubmissionModel.isResponse;
    }
    async submit() {
        if(this.finalPayment === 0) {
            await _alert('견적 생성에 실패했습니다. 새로고침을 해 주세요.');
        }
        else {
            if(await _confirm('최종제출 하시겠습니까?')) {
                this.state.submitBtnDisabledYn = true;
                await submitEstimateService.submitEstimate();
                await _alert('최종제출 하였습니다.')
                this.state.submitBtnDisabledYn = false;
                if(window.opener) {
                    window.opener.close()
                }
                sessionStorage.clear();
                window.open(`${process.env.REACT_APP_DASHBOARD_URL}corps/${smartformAppModel.corpId}/progress-stages/check-estimates`, '_blank');
                window.close()
            } 
            
        }
    }
    smartFormType() { //스마트폼 타입
        const type = smartformAppModel.smartFormType;
        switch(type) {
            case 'ESTABLISHMENT_STOCK_COMPANY':
                return '주식회사'
            case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY':
                return '농업회사법인'
            case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY':
                return '어업회사법인'
            case 'ESTABLISHMENT_LIMITED_COMPANY':
                return '유한회사'
            case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY':
                return '유한책임회사'
        }
    } 
    name() {
        const type = smartformAppModel.smartFormType;
        switch(type) {
            case 'ESTABLISHMENT_STOCK_COMPANY':
                return '주주'
            case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY':
                return '주주'
            case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY':
                return '주주'
            case 'ESTABLISHMENT_LIMITED_COMPANY':
                return '출자자'
            case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY':
                return '출자자'
        }
    }

}
export default new viewModel();