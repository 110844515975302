import { useObserver } from 'mobx-react';
import RepresentativeCheck from '../../RepresentativeCheck/RepresentativeCheck';

function ExecutiveMemberChangeRepresentativeCheck() {
    return useObserver(() => (
        <>
            {/* 대표자 주민번호 주소 확인 */}
            <RepresentativeCheck />
        </>
    ));
}
export default ExecutiveMemberChangeRepresentativeCheck;