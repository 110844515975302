import { observable, runInAction } from 'mobx';

const CorporateTaxStepModel = observable({
    freeCounselYn: null,
    setCorporateTax(data) {
        runInAction(() => {
            this.freeCounselYn = data?.freeCounselYn ?? null;
        })
    }
});

export default CorporateTaxStepModel;