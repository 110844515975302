import { useObserver } from 'mobx-react';
import CompanyInfo from '../CompanyInfo/CompanyInfo';
import ShareholderAndExecutiveInfo from '../ShareholderAndExecutiveInfo/ShareholderAndExecutiveInfo';
import EtcInfo from '../EtcInfo/EtcInfo';
import TermsAndConditionsAgree from '../TermsAndConditionsAgree/TermsAndConditionsAgree';
import { Route, withRouter } from "react-router-dom";
import { useEffect, Fragment } from 'react';
import EstablishStepVm from './EstablishStepVm';
import TrademarkAgree from '../TrademarkAgree/TrademarkAgree';

function Establish({history}) {
  useEffect(() => {
    EstablishStepVm.loadEstablish();
  }, [EstablishStepVm.nowPage])
  return useObserver(() => (
    EstablishStepVm.isLoadData ? 
    <Fragment key={EstablishStepVm.nowPage}>
        {/* 회사정보 */}
        <Route path="/step/establish/CompanyInfo" component={CompanyInfo}></Route>
        {/* 주주 및 임원정보 */}
        <Route path="/step/establish/ShareholderAndExecutiveInfo" component={ShareholderAndExecutiveInfo}></Route>
        {/* 기타정보 */}
        <Route path="/step/establish/EtcInfo" component={EtcInfo}></Route>
        {/* 상표등록 */}
        <Route path="/step/establish/TrademarkAgree" component={TrademarkAgree}></Route>
        {/* 이용약관동의 */}
        <Route path="/step/establish/TermsAndConditionsAgree" component={TermsAndConditionsAgree}></Route>
    </Fragment>
    : null
  ));
}

export default Establish;
