import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import btnRadioNo from '../../../asset/icon/btn_radio_n.svg';
import btnRadioCl from '../../../asset/icon/btn_radio_s.svg';
import clsx from 'clsx';

const Label = styled.label`
    display: block;
    position: relative;
    ${(props) => props.disabled ? `cursor:not-allowed;` : `cursor:pointer;`}
    width: 100%;
    height: 100%;
    ${(props) => (props.activeBackground && props.checked) && 
        `background-color:rgba(0, 106, 195, 0.1);`        
    }
`;

const Radioinput = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10000;
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + span {
        &:after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 5px;
            left: -28px;           
            width: 20px;
            height: 20px;
            background-image: url(${btnRadioCl});
            background-size: cover;
            background-repeat: no-repeat;
        }
        ${(props) => props.colorChange === true && 
        `
            color: #006ac3;

            .highlight {
                color: #006ac3;
            }
            .add {
                color: #006ac3;
            }
        `
        }
    }
`;

// const Radio = styled.span`
//     display: inline-block;
//     position: relative;
//     top: 5px;
//     margin-right: 8px;
//     width: 20px;
//     height: 20px;
//     background-image: url(${btnRadioNo});
//     background-size: cover;
//     background-repeat: no-repeat;
//     vertical-align: top;
// `;

const Text = styled.span`
    position: relative;
    display: inline-block;
    margin-left: 28px;
    color: #000;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: -0.5px;

    .highlight {
        color: #006ac3;
    }

    .add {
        position: relative;
        left: 4px;
        top: -2px;
        color: #555;
        font-size: 14px;
        letter-spacing: -0.84px;
    }

    &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 5px;
        left: -28px;
        margin-right: 8px;
        width: 20px;
        height: 20px;
        background-image: url(${btnRadioNo});
        background-size: cover;
        background-repeat: no-repeat;
        vertical-align: top;
    }
`;

const Input = React.forwardRef((props, ref) => {
    return (
        <Label className={props.className} disabled={props.disabled} activeBackground={props.activeBackground} checked={props.checked}>
            <Radioinput type="radio" required={props.required} name={props.name} id={props.id} checked={props.checked}
            value={props.value} onChange={(e) => props.onChange(e)} disabled={props.disabled} onClick={(e) => props.onClick(e)}
            colorChange={props.colorChange} ref={ref}/>
            {/* <Radio /> */}
            <Text style={props.style}>{props.children}</Text>
        </Label>
    )
    
});

Input.propTypes = {
    /**
     * 필수값
     */
    required: PropTypes.bool,
    /**
     * 라디오 이름
     */
    name: PropTypes.string,
    /**
     * 라디오 id값
     */
    id: PropTypes.string,
    /**
     * class명<br/>
     * 주식회사(.highlight), 제3자 정보제공 동의포함(.add)
     */
    className: PropTypes.string,
    /**
    * style 속성
    */
    style: PropTypes.object,
    /**
     * 라디오 defaultChecked
     */
    defaultChecked: PropTypes.bool,
    /**
     * 라디오 checked
     */
    checked: PropTypes.bool,
    /**
     * 라디오 value값
     */
    value: PropTypes.any,
    /**
     * change 이벤트
     */
    onChange: PropTypes.func,
    /**
     * radio disabled 여부
     */
    disabled: PropTypes.bool,
    /**
     * 라디오 체크시 텍스트 색상 변경에 대한 props값
     */
    colorChange: PropTypes.bool,
    /**
     * 라디오 텍스트
     */
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    /**
     * click 이벤트
     */
    onClick: PropTypes.func,
    /**
     * 라디오 선택할 때, 배경색
     */
    activeBackground: PropTypes.bool,
};

Input.defaultProps = {
    required: false,
    name: null,
    id: null,
    className: null,
    style: {},
    defaultChecked: null,
    checked: null,
    value: '',
    onChange : () => {},
    disabled: false,
    colorChange: true,
    children : 'Empty',
    onClick : () => {},
    activeBackground: false
};

export default Input;

