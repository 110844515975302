import { toJS } from 'mobx';
import { Fragment } from 'react';
import { useObserver } from 'mobx-react';
import st from '../StandbyFileUpload/StandbyFileUpload.module.scss';
import FileUpload from '@comComponents/molecules/FileUpload/FileUpload';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';
import vm from './BusinessRegistrationVm';

function BusinessRegistration(props) {
    return useObserver(() => (
        <div className={st.fileDetail}>
            {props.file.details.map((detail, idx2) => (
                <Fragment key={idx2}>
                    {detail.fileType === '사업자등록증' &&
                        <>
                            <div className={st.title}>
                                사업자등록증 
                                {((detail.finalSubmittedDate && props.parentVm.fileGroupTypes[props.idx].details[idx2].files[0]?.fileName) && !props.parentVm.fileGroupTypes[props.idx].details[idx2].files[0]?.uploadFiles) && 
                                    <span className={st.date}>
                                        ({detail.finalSubmittedDate?.year}년 {detail.finalSubmittedDate?.month}월 {detail.finalSubmittedDate?.day}일 발급)
                                    </span>
                                }
                            </div>
                            <FileUpload id={detail.fileType} className={st.fileUpload} accept=".pdf, .jpg, .png" downloadBtn
                            fileName={props.parentVm.fileGroupTypes[props.idx].details[idx2].files[0].fileName} 
                            fileSize={props.parentVm.fileGroupTypes[props.idx].details[idx2].files[0].fileSize} 
                            fileExtension={vm.fileExtension(props.parentVm.fileGroupTypes[props.idx].details[idx2].files[0].fileName)} 
                            fileUrl={props.parentVm.fileGroupTypes[props.idx].details[idx2].files[0].url}
                            model={props.parentVm.fileGroupTypes[props.idx].details[idx2].files[0]}>
                                <span className={st.bold}>여기</span>를 눌러 업로드해 주세요.
                            </FileUpload>
                            {(props.parentVm.state.errorCheckYn && props.parentVm.displayErrorText(detail.fileType)) && <ErrorText>사업자등록증을 업로드해 주세요.</ErrorText>}
                        </> 
                    }
                </Fragment>
            ))}
        </div>
    ));
}

export default BusinessRegistration;