import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createBrowserHistory } from "history";
import { syncHistoryWithStore } from 'mobx-react-router';
import { Router } from 'react-router';
import {pageRouter} from '@model/pageStepModel';
import * as Sentry from "@sentry/react";

const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, pageRouter);

Sentry.init({
  dsn: 'https://85888168d860a0e55ed56a9a18962405@o774769.ingest.sentry.io/4506301915004928',
  integrations: [
    new Sentry.Replay({
      networkDetailAllowUrls: [
        /^https:\/\/smartform\.api\.help-me\.kr/
      ],
      networkRequestHeaders: [
        'Authorization',
        'Accept',
        'User-Agent',
        'Cache-Control',
      ],
      networkResponseHeaders: [
        'Content-Type',
        'Date',
      ],
    }),
  ],
  environment : process.env.NODE_ENV,
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  maskAllText: false,
  maskAllInputs: false,
  blockAllMedia: false,
  ignoreErrors: [
    'AxiosError',
  ],
});

ReactDOM.render(
    <Router history={history}>
      <App />
    </Router>,
  document.getElementById('root')
);

