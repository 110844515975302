import { useObserver } from 'mobx-react';
import {addCommas, percent} from '@common/module/replaceNumber';
import st from '../../FinalCheck/FinalCheck.module.scss';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import SignleQuestionHeader from '@commonComponents/SingleQuestionHeader/SingleQuestionHeader';
import NextBtn from '@comComponents/atoms/Button/LastStepBtn/LastStepBtn';
import AfterFinalCheckVm from './AfterFinalCheckVm';
import clsx from 'clsx';
import Loading from '@comComponents/atoms/Loading/Loading';
import { useEffect, useRef } from 'react';
import CorpNameStep from '../steps/CorpNameStep/CorpNameStep';
import HeadOfficeRelocationStep from '../steps/HeadOfficeRelocationStep/HeadOfficeRelocationStep';
import PaidInCapitalIncreaseStep from '../steps/PaidInCapitalIncreaseStep/PaidInCapitalIncreaseStep';
import ExecutiveMemberChangeStep from '../steps/ExecutiveMemberChangeStep/ExecutiveMemberChangeStep';
import BusinessPurposeStep from '../steps/BusinessPurposeStep/BusinessPurposeStep';
import AddStockOptionStep from '../steps/AddStockOptionStep/AddStockOptionStep';
import AnnounceMethodStep from '../steps/AnnounceMethodStep/AnnounceMethodStep';
import BranchOfficeDeleteStep from '../steps/BranchOfficeDeleteStep/BranchOfficeDeleteStep';
import BranchOfficeRelocateStep from '../steps/BranchOfficeRelocateStep/BranchOfficeRelocateStep';
import BranchOfficeChangeStep from '../steps/BranchOfficeChangeStep/BranchOfficeChangeStep';
import BranchOfficeInstallStep from '../steps/BranchOfficeInstallStep/BranchOfficeInstallStep';
import BoardMeetingSealProvidingStep from '../steps/BoardMeetingSealProvidingStep/BoardMeetingSealProvidingStep';
import StockholdersMeetingSealProvidingStep from '../steps/StockholdersMeetingSealProvidingStep/StockholdersMeetingSealProvidingStep';
import RepresentativeAddressChangeStep from '../steps/RepresentativeAddressChangeStep/RepresentativeAddressChangeStep';
import TCAgreeAndDeliveryAddressStep from '../steps/TCAgreeAndDeliveryAddressStep/TCAgreeAndDeliveryAddressStep';
import NumOfSharesToBeIssuedStep from '../steps/NumOfSharesToBeIssuedStep/NumOfSharesToBeIssuedStep';
import FreeIssueOfNewSharesStep from '../steps/FreeIssueOfNewSharesStep/FreeIssueOfNewSharesStep';
import CapitalIncreaseStep from '../steps/CapitalIncreaseStep/CapitalIncreaseStep';
import ParValueDivisionStep from '../steps/ParValueDivisionStep/ParValueDivisionStep';
import LiquidationStep from '../steps/LiquidationStep/LiquidationStep';
import StockCompanySealStep from '../steps/StockCompanySealStep/StockCompanySealStep';
import StandbyParValueDivisionStep from '../steps/StandbyParValueDivisionStep/StandbyParValueDivisionStep';
import StandbyFreeIssueOfNewSharesStep from '../steps/StandbyFreeIssueOfNewSharesStep/StandbyFreeIssueOfNewSharesStep';
import StandByStockholderMeetingInfoStep from '../steps/StandByStockholderMeetingInfoStep/StandByStockholderMeetingInfoStep';
import StandByBoardMeetingInfoStep from '../steps/StandByBoardMeetingInfoStep/StandByBoardMeetingInfoStep';
import StandbyPaidInCapitalIncreaseStep from '../steps/StandbyPaidInCapitalIncreaseStep/StandbyPaidInCapitalIncreaseStep';
import ConvertibleBondStep from '../steps/ConvertibleBondStep/ConvertibleBondStep';

function AfterFinalCheck() {
    useEffect(() => {
        AfterFinalCheckVm.loadFinalCheck();
    }, [])
    return useObserver(() => (
    <>
        <SignleQuestionHeader>
            <h1 className="title">입력 정보가 <span className="bold">최종적으로 제출</span>되었습니다</h1>
            <div className="explain">[{AfterFinalCheckVm.submittedAt ? AfterFinalCheckVm.submittedAt : '0000.00.00 00:00'}] 에 제출되었습니다. 정보 수정을 원하실 경우, 매니저와 채팅상담을 진행해주세요.</div>
            <div className={st.importantExplain}>담당 매니저가 검토 후 영업일 기준 48시간 내에 연락드리겠습니다.</div>
            <div className={st.importantExplainClose}>내용 확인이 끝났다면, 이 화면을 닫고 종료하시면 됩니다.</div>
        </SignleQuestionHeader>
        <div className="mainContent" style={{top:"286px"}}>
        <Question>
            <ContentBox style={AfterFinalCheckVm.isResponse ? null : {height:window.innerHeight}}>
            {AfterFinalCheckVm.isResponse === false ? <Loading /> 
            :
            <div className={st.content}>
                {AfterFinalCheckVm.steps.map((step, index) => (
                    <div className={st.table} key={index}>
                        <div className={st.title}>{step.title}</div>
                        <div className={st.info}>
                            { step.code === "CorpNameStep" && <CorpNameStep step={step}/> }
                            { step.code === "HeadOfficeRelocationStep" && <HeadOfficeRelocationStep step={step}/> }
                            { step.code === "PaidInCapitalIncreaseStep" && <PaidInCapitalIncreaseStep step={step}/> }
                            { step.code === "NumOfSharesToBeIssuedStep" && <NumOfSharesToBeIssuedStep step={step}/> }
                            { step.code === "ExecutiveMemberChangeStep" && <ExecutiveMemberChangeStep step={step}/> }
                            { step.code === "BusinessPurposeStep" && <BusinessPurposeStep step={step}/> }
                            { step.code === "AddStockOptionStep" && <AddStockOptionStep step={step}/> }
                            { step.code === "AnnounceMethodStep" && <AnnounceMethodStep step={step}/> }
                            { step.code === "BranchOfficeDeleteStep" && <BranchOfficeDeleteStep step={step}/> }
                            { step.code === "BranchOfficeRelocateStep" && <BranchOfficeRelocateStep step={step}/> }
                            { step.code === "BranchOfficeChangeStep" && <BranchOfficeChangeStep step={step}/> }
                            { step.code === "BranchOfficeInstallStep" && <BranchOfficeInstallStep step={step}/> }
                            { step.code === "BoardMeetingSealProvidingStep" && <BoardMeetingSealProvidingStep step={step}/> }
                            { step.code === "StockholdersMeetingSealProvidingStep" && <StockholdersMeetingSealProvidingStep step={step}/> }
                            { step.code === "RepresentativeAddressChangeStep" && <RepresentativeAddressChangeStep step={step}/> }
                            { step.code === "TCAgreeAndDeliveryAddressStep" && <TCAgreeAndDeliveryAddressStep step={step}/> }
                            { step.code === "FreeIssueOfNewSharesStep" && <FreeIssueOfNewSharesStep step={step}/> }
                            { step.code === "CapitalIncreaseStep" && <CapitalIncreaseStep step={step}/> }
                            { step.code === "ParValueDivisionStep" && <ParValueDivisionStep step={step}/> }
                            { step.code === "LiquidationStep" && <LiquidationStep step={step}/> }
                            { step.code === "StockCompanySealStep" && <StockCompanySealStep step={step}/> }
                            { step.code === "ConvertibleBondStep" && <ConvertibleBondStep step={step}/> }
                            {/* 스탠바이 */}
                            { step.code === "StandbyParValueDivisionStep" && <StandbyParValueDivisionStep step={step}/> }
                            { step.code === "StandbyFreeIssueOfNewSharesStep" && <StandbyFreeIssueOfNewSharesStep step={step}/> }
                            { step.code === "StandByStockholderMeetingInfoStep" && <StandByStockholderMeetingInfoStep step={step}/> }
                            { step.code === "StandByBoardMeetingInfoStep" && <StandByBoardMeetingInfoStep step={step}/> }
                            { step.code === "StandbyPaidInCapitalIncreaseStep" && <StandbyPaidInCapitalIncreaseStep step={step}/> }
                        </div>
                    </div>
                ))}
            </div>
            }
            </ContentBox>
        </Question>  
        </div>
    </>
    ));
}

export default AfterFinalCheck;
