import { observable, configure } from 'mobx';
import { RouterStore } from 'mobx-react-router';
configure({  useProxies: "never" });

const model = observable({
    isStep : false,
    cause : "",
    nowStep : "",
    nowPage : "",
    singlePageUrl : "",
    saveState:"next"
});

export const pageDepth = observable({
    cause: {},
    setPageDepthEstablish(smartFormType, allKorean, capital) { //설립 스탭Depth세팅
        if(smartFormType === 'ESTABLISHMENT_STOCK_COMPANY') {
            this.cause = 주식회사;
        }
        else if(smartFormType === 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY') {
            this.cause = 농업주식회사;
        }
        else if(smartFormType === 'ESTABLISHMENT_FISHERY_STOCK_COMPANY') {
            this.cause = 어업주식회사;
        }
        else if(smartFormType === 'ESTABLISHMENT_LIMITED_COMPANY') {
            this.cause = 유한회사;
        }
        else if(smartFormType === 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY') {
            this.cause = 유한책임회사;
        }
        if(!allKorean) { //주주 또는 임원에 외국인, 외국법인, 한국법인이 있는 경우 공인인증서 페이지 삭제
            removePage('공동인증서(구 공인인증서)')
        }
        if(capital >= 1000000000) { //자본금이 10억 이상일 경우 공인인증서 페이지 삭제
            removePage('공동인증서(구 공인인증서)')
        }
    },
    setPageDepthChangeRegistration(steps) { //변경 스탭Depth세팅
        this.cause = {
            "changeRegistration" : {
                steps : steps || []
            }
        }
    }
});
function removePage(pageTitle) {
    let stepIdx = null, pageIdx = null;
    pageDepth.cause.establish.steps.forEach((step, idx) => {
        step.pages.forEach((page, idx2) => {
            if(page.title === pageTitle) {
                stepIdx = idx;
                pageIdx = idx2;
            }
        })
    })
    pageDepth.cause.establish.steps[stepIdx]?.pages.splice(pageIdx, 1)
}

const 주식회사 = {
    "establish" :  { //설립
        steps : [
            {
                code : "CompanyInfo",
                title : "회사정보",
                pages : [
                    {
                        code : "CompanySize",
                        title : "회사정보"
                    },
                    {
                        code : "CompanyAddress",
                        title : "회사주소"
                    },
                    {
                        code : "CompanyName",
                        title : "회사이름"
                    }
                ]
            },
            {
                code : "ShareholderAndExecutiveInfo",
                title : "주주 및 임원정보",
                pages : [
                    {
                        code : "ParValue",
                        title : "1주당 금액"
                    },
                    {
                        code : "Shareholder",
                        title : "주주"
                    },
                    {
                        code : "ShareholderInvestment",
                        title : "자본금"
                    },
                    {
                        code : "Executive",
                        title : "임원"
                    }
                ]
            },
            {
                code : "EtcInfo", 
                title : "기타정보",
                pages : [
                    {
                        code : "BusinessPurpose",
                        title : "사업목적"
                    },
                    {
                        code : "ChooseStamp",
                        title : "도장선택"
                    },
                    {
                        code : "Option",
                        title : "옵션"
                    },
                    {
                        code : "Certificate",
                        title : "공동인증서(구 공인인증서)"
                    },
                    {
                        code : "FreeLicenseRegistration",
                        title : "무료사업자등록"
                    }
                ]
            },
            {
                code : "TrademarkAgree",
                title : "상표등록",
                pages : [
                    {
                        code : "Trademark",
                        title : "상표등록"
                    }
                ]
            },
            {
                code : "TermsAndConditionsAgree",
                title : "이용약관동의",
                pages : [
                    {
                        code : "TCAgree",
                        title : "이용약관동의"
                    }
                ]
            }
        ]
    }    
};

const 농업주식회사 = {
    "establish" :  { //설립
        steps : [
            {
                code : "CompanyInfo",
                title : "회사정보",
                pages : [
                    {
                        code : "CompanySize",
                        title : "회사정보"
                    },
                    {
                        code : "CompanyAddress",
                        title : "회사주소"
                    },
                    {
                        code : "CompanyName",
                        title : "회사이름"
                    }
                ]
            },
            {
                code : "ShareholderAndExecutiveInfo",
                title : "주주 및 임원정보",
                pages : [
                    {
                        code : "ParValue",
                        title : "1주당 금액"
                    },
                    {
                        code : "Shareholder",
                        title : "주주"
                    },
                    {
                        code : "ShareholderInvestment",
                        title : "자본금"
                    },
                    {
                        code : "Executive",
                        title : "임원"
                    }
                ]
            },
            {
                code : "EtcInfo", 
                title : "기타정보",
                pages : [
                    {
                        code : "BusinessPurpose",
                        title : "사업목적"
                    },
                    {
                        code : "ChooseStamp",
                        title : "도장선택"
                    },
                    {
                        code : "Option",
                        title : "옵션"
                    },
                    {
                        code : "Certificate",
                        title : "공동인증서(구 공인인증서)"
                    },
                    {
                        code : "FreeLicenseRegistration",
                        title : "무료사업자등록"
                    },
                    {
                        code : "AgriculturalNoticeOfEstablishment",
                        title : "설립통지"
                    }
                ]
            },
            {
                code : "TrademarkAgree",
                title : "상표등록",
                pages : [
                    {
                        code : "Trademark",
                        title : "상표등록"
                    }
                ]
            },
            {
                code : "TermsAndConditionsAgree",
                title : "이용약관동의",
                pages : [
                    {
                        code : "TCAgree",
                        title : "이용약관동의"
                    }
                ]
            }
        ]
    }    
};

const 어업주식회사 = {
    "establish" :  { //설립
        steps : [
            {
                code : "CompanyInfo",
                title : "회사정보",
                pages : [
                    {
                        code : "CompanySize",
                        title : "회사정보"
                    },
                    {
                        code : "CompanyAddress",
                        title : "회사주소"
                    },
                    {
                        code : "CompanyName",
                        title : "회사이름"
                    }
                ]
            },
            {
                code : "ShareholderAndExecutiveInfo",
                title : "주주 및 임원정보",
                pages : [
                    {
                        code : "ParValue",
                        title : "1주당 금액"
                    },
                    {
                        code : "Shareholder",
                        title : "주주"
                    },
                    {
                        code : "ShareholderInvestment",
                        title : "자본금"
                    },
                    {
                        code : "Executive",
                        title : "임원"
                    }
                ]
            },
            {
                code : "EtcInfo", 
                title : "기타정보",
                pages : [
                    {
                        code : "BusinessPurpose",
                        title : "사업목적"
                    },
                    {
                        code : "ChooseStamp",
                        title : "도장선택"
                    },
                    {
                        code : "Option",
                        title : "옵션"
                    },
                    {
                        code : "Certificate",
                        title : "공동인증서(구 공인인증서)"
                    },
                    {
                        code : "FreeLicenseRegistration",
                        title : "무료사업자등록"
                    },
                    {
                        code : "FisheryNoticeOfEstablishment",
                        title : "설립통지"
                    }
                ]
            },
            {
                code : "TrademarkAgree",
                title : "상표등록",
                pages : [
                    {
                        code : "Trademark",
                        title : "상표등록"
                    }
                ]
            },
            {
                code : "TermsAndConditionsAgree",
                title : "이용약관동의",
                pages : [
                    {
                        code : "TCAgree",
                        title : "이용약관동의"
                    }
                ]
            }
        ]
    }    
};

const 유한회사 = {
    "establish" :  { //설립
        steps : [
            {
                code : "CompanyInfo",
                title : "회사정보",
                pages : [
                    {
                        code : "CompanyAddress",
                        title : "회사주소"
                    },
                    {
                        code : "CompanyName",
                        title : "회사이름"
                    }
                ]
            },
            {
                code : "ShareholderAndExecutiveInfo",
                title : "출자자 및 임원정보",
                pages : [
                    {
                        code : "ParValue",
                        title : "출자 1좌의 금액"
                    },
                    {
                        code : "Shareholder",
                        title : "출자자(=사원)"
                    },
                    {
                        code : "ShareholderInvestment",
                        title : "출자자 투자금"
                    },
                    {
                        code : "Executive",
                        title : "임원"
                    }
                ]
            },
            {
                code : "EtcInfo", 
                title : "기타정보",
                pages : [
                    {
                        code : "BusinessPurpose",
                        title : "사업목적"
                    },
                    {
                        code : "ChooseStamp",
                        title : "도장선택"
                    },
                    {
                        code : "Certificate",
                        title : "공동인증서(구 공인인증서)"
                    },
                    {
                        code : "FreeLicenseRegistration",
                        title : "무료사업자등록"
                    }
                ]
            },
            {
                code : "TrademarkAgree",
                title : "상표등록",
                pages : [
                    {
                        code : "Trademark",
                        title : "상표등록"
                    }
                ]
            },
            {
                code : "TermsAndConditionsAgree",
                title : "이용약관동의",
                pages : [
                    {
                        code : "TCAgree",
                        title : "이용약관동의"
                    }
                ]
            }
        ]
    }    
};

const 유한책임회사 = {
    "establish" :  { //설립
        steps : [
            {
                code : "CompanyInfo",
                title : "회사정보",
                pages : [
                    {
                        code : "CompanyAddress",
                        title : "회사주소"
                    },
                    {
                        code : "CompanyName",
                        title : "회사이름"
                    }
                ]
            },
            {
                code : "ShareholderAndExecutiveInfo",
                title : "출자자 및 임원정보",
                pages : [
                    {
                        code : "Shareholder",
                        title : "출자자(=사원)"
                    },
                    {
                        code : "ShareholderInvestment",
                        title : "출자자 투자금"
                    },
                    {
                        code : "Executive",
                        title : "임원"
                    }
                ]
            },
            {
                code : "EtcInfo", 
                title : "기타정보",
                pages : [
                    {
                        code : "BusinessPurpose",
                        title : "사업목적"
                    },
                    {
                        code : "ChooseStamp",
                        title : "도장선택"
                    },
                    {
                        code : "Certificate",
                        title : "공동인증서(구 공인인증서)"
                    },
                    {
                        code : "FreeLicenseRegistration",
                        title : "무료사업자등록"
                    }
                ]
            },
            {
                code : "TrademarkAgree",
                title : "상표등록",
                pages : [
                    {
                        code : "Trademark",
                        title : "상표등록"
                    }
                ]
            },
            {
                code : "TermsAndConditionsAgree",
                title : "이용약관동의",
                pages : [
                    {
                        code : "TCAgree",
                        title : "이용약관동의"
                    }
                ]
            }
        ]
    }    
};

export const pageRouter = new RouterStore();

export default model;

