import { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import clsx from 'clsx';
import vm from './ConvertibleBondAcquirerVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import AddBtn from '@comComponents/atoms/Button/AddBtn/AddBtn';
import Drop from '@comComponents/molecules/Dropdown/Dropdown';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import Icon from '@comComponents/atoms/Icon/Icon';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';

import KoreanPerson from './KoreanPerson/KoreanPerson';
import ForeignerPerson from './ForeignerPerson/ForeignerPerson';
import KoreanCompany from './KoreanCompany/KoreanCompany';
import ForeignerCompany from './ForeignerCompany/ForeignerCompany';
import FundAndInvestmentAssociation from './FundAndInvestmentAssociation/FundAndInvestmentAssociation';

function ConvertibleBondAcquirer() {
    
    useEffect(() => {
        vm.load();
    }, []);

    return useObserver(() => (
        <form onSubmit={(e) => {vm.submit(e)}}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            <Question list={true}>
                <ContentBox>
                    <div className={Qst.dropHeader}>
                    <div className={Qst.dropTitleWrap}>
                        <div className={Qst.dropTitle}>전환사채 인수인(=투자자)님의 정보를 입력해주세요.</div>
                        <div className={Qst.dropTitleExplain}>고객님께서 작성하신 전환사채 인수인의 리스트입니다.</div>
                    </div>
                    <AddBtn onClick={() => vm.addConvertibleBond()}>전환사채 인수인 추가하기</AddBtn>
                    </div>
                </ContentBox>
            </Question>
            {vm.convertibleBondAcquirerInfos.map((item, idx) => {
                return (
                    <Question list={true} key={idx}>
                        <ContentBox>
                            <Drop dataEq={`${idx}`} className={clsx(Qst.dropDown, vm.setIsOpen(idx) ? 'open' : 'close')} title={vm.setTitle(idx)} onClick={() => vm.setDropOpen(idx)} isOpen={vm.setIsOpen(idx)} closeBtn={() => vm.setCloseBtn(idx)} error={vm.personErrorYn(idx)}>
                                <input type="hidden" name={`convertibleBondAcquirerInfos[${idx}].id`} value={item.id || ""}/>
                                <div className={Qst.spaceBetween}>
                                    <div className={Qst.infoSection}>
                                        <div className={Qst.titleWrap}>
                                            <h2 className={clsx(Qst.title, Qst.inside)}>
                                                <span className={Qst.bold}>전환사채 인수인님 어떤 사람(법인)</span>인가요?
                                            </h2>
                                        </div>
                                        <ul className={Qst.answerList} key={idx+item.acquirerType}>
                                            <li><Radio className={Qst.answerLabel} value="KOREAN_PERSON" activeBackground checked={item.acquirerType === "KOREAN_PERSON"} onChange={(e) => vm.setShareholderType(e,idx)} name={`convertibleBondAcquirerInfos[${idx}].acquirerType`}>한국인입니다.</Radio></li>
                                            <li><Radio className={Qst.answerLabel} value="FOREIGNER_PERSON" activeBackground checked={item.acquirerType === "FOREIGNER_PERSON"} onChange={(e) => vm.setShareholderType(e,idx)} name={`convertibleBondAcquirerInfos[${idx}].acquirerType`}>외국인입니다.</Radio></li>
                                            <li><Radio className={Qst.answerLabel} value="KOREAN_COMPANY" activeBackground checked={item.acquirerType === "KOREAN_COMPANY"} onChange={(e) => vm.setShareholderType(e,idx)} name={`convertibleBondAcquirerInfos[${idx}].acquirerType`}>한국법인입니다.</Radio></li>
                                            <li><Radio className={Qst.answerLabel} value="FOREIGNER_COMPANY" activeBackground checked={item.acquirerType === "FOREIGNER_COMPANY"} onChange={(e) => vm.setShareholderType(e,idx)} name={`convertibleBondAcquirerInfos[${idx}].acquirerType`}>외국법인입니다.</Radio></li>
                                            <li><Radio className={Qst.answerLabel} value="FUND_AND_INVESTMENT_ASSOCIATION" activeBackground checked={item.acquirerType === "FUND_AND_INVESTMENT_ASSOCIATION"} onChange={(e) => vm.setShareholderType(e,idx)} name={`convertibleBondAcquirerInfos[${idx}].acquirerType`}>펀드/투자조합입니다.</Radio></li>
                                        </ul>
                                        <Error name={`convertibleBondAcquirerInfos[${idx}].acquirerType`} value={item.acquirerType} errorCase={{required:'사람(법인)을 선택해 주세요.'}}/>
                                    </div>
                                    <div className={Qst.qnaSection}>
                                        <Qna tip="전환사채 인수인 입력" className={Qst.qnaTitle}>
                                            <div className={Qst.qnaBox}>
                                                <QText>전환사채 인수인은 누구인가요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>전환사채 인수인은 투자자 또는 이번 전환사채 발행시 회사에 사채대금을 내고, 사채를 인수할 사람/법인을 말합니다.</Answer>
                                                </div>
                                            </div>
                                        </Qna>
                                    </div>
                                </div>
                                {item.acquirerType === "KOREAN_PERSON" && <KoreanPerson item={item} idx={idx}/> }
                                {item.acquirerType === "FOREIGNER_PERSON" && <ForeignerPerson item={item} idx={idx}/> }
                                {item.acquirerType === "KOREAN_COMPANY" && <KoreanCompany item={item} idx={idx}/> }
                                {item.acquirerType === "FOREIGNER_COMPANY" && <ForeignerCompany item={item} idx={idx}/> }
                                {item.acquirerType === "FUND_AND_INVESTMENT_ASSOCIATION" && <FundAndInvestmentAssociation item={item} idx={idx}/> } 
                                {item.acquirerType && 
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    {vm.displayKoreanName(item.shareholderType, item.koreanName)} <span className={Qst.bold}>기존 주주</span>인가요?
                                                </h2>
                                            </div>
                                            <ul className={Qst.answerList}>
                                                <li><Radio className={Qst.answerLabel} value={true} activeBackground checked={item.existingShareholdersYn === true} onChange={(e) => vm.setExistingShareholdersYn(e,idx)} name={`convertibleBondAcquirerInfos[${idx}].existingShareholdersYn`}>기존 주주입니다.</Radio></li>
                                                <li><Radio className={Qst.answerLabel} value={false} activeBackground checked={item.existingShareholdersYn === false} onChange={(e) => vm.setExistingShareholdersYn(e,idx)} name={`convertibleBondAcquirerInfos[${idx}].existingShareholdersYn`}>새로운 사람(법인)입니다. (기존 주주X)</Radio></li>
                                            </ul>
                                            <Error name={`convertibleBondAcquirerInfos[${idx}].existingShareholdersYn`} value={item.existingShareholdersYn} errorCase={{required:'기존 주주인지 신규 주주인지 선택해 주세요.'}}/>
                                        </div>
                                    </div>
                                }
                            </Drop>
                        </ContentBox>
                    </Question>
                )
            })}
            <Question list={true}>
                <ContentBox>
                <button type="button" className={Qst.stockholderAddBtn} onClick={() => vm.addConvertibleBond()}>전환사채 인수인 추가하기<Icon icon="addGray" className={Qst.addGray}/></button>
                </ContentBox>
            </Question>
        </form>
    ));
}

export default ConvertibleBondAcquirer;