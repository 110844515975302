import { useObserver } from 'mobx-react';
import AnnounceMethod from './AnnounceMethod/AnnounceMethod';
import { Route } from "react-router-dom";

function CorpNameStep() {
  return useObserver(() => (
    <>
        {/* 상호 */}
        <Route path="/step/changeRegistration/AnnounceMethodStep/AnnounceMethod" component={AnnounceMethod}></Route>
    </>
  ));
}

export default CorpNameStep;