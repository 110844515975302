import st from './QuestionHeader.module.scss';
import { useObserver } from 'mobx-react';
import StepBtn from '@comComponents/atoms/Button/StepBtn/StepBtn';
import Icon from '@comComponents/atoms/Icon/Icon';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import ProgressBar from '@comComponents/atoms/ProgressBar/ProgressBar';
import React, {useState, useEffect, useCallback} from 'react';
import clsx from 'clsx';
import QuestionHeaderVm from './QuestionHeaderVm';
import BuildingMove from '@commonComponents/BuildingMove/BuildingMove';

function QuestionHeader() {
    const [isHidden, setIsHidden] = useState(false);
    const onScroll = useCallback(() => {
        if(window.pageYOffset > 50){ 
            setIsHidden(true);
        } else{
            setIsHidden(false);
        }
    },[])
    
    useEffect(()=>{
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return useObserver(() => (
    <header className={clsx(st.questionHeader, isHidden ? st.hidden : null)}>
        <div className={st.content}>
            <div className={st.leftBg}></div>
            <div className={st.rightBg}></div>
            <ContentBox className={st.headerWrap}>
                <div className={st.titleBox}>
                    <h1 className={st.title}><Icon icon="q"/><span className={st.text}>{QuestionHeaderVm.pageName}</span></h1>
                    <div className={st.btnArea}>
                        {QuestionHeaderVm.canPre && 
                            <StepBtn step="pre" size={isHidden ? 'small' : 'medium'} onClick={() => QuestionHeaderVm.pre()} className={st.stepBtnPre}>이전단계</StepBtn>
                        }
                        <StepBtn step={QuestionHeaderVm.saveState} size={isHidden ? 'small' : 'medium'} type="stepForm" onClick={() => QuestionHeaderVm.nextBtnEvent(QuestionHeaderVm.isSumbitData)} className={st.stepBtn} disabled={!QuestionHeaderVm.isSumbitData}>{QuestionHeaderVm.canNext ? '저장 후 다음단계' : '작성내용 최종 확인 하러가기'}</StepBtn>
                    </div>
                </div>
            </ContentBox>
            <ContentBox className={st.progressWrap} id="headerNav">
                <ProgressBar item={QuestionHeaderVm.progressItem} attr={true} stepIdx={QuestionHeaderVm.stepIdx} pageStackIdx={QuestionHeaderVm.pageStackIdx} pageCnt={QuestionHeaderVm.pageCnt}></ProgressBar>
            </ContentBox>
        </div>
    </header>
    ));
}

export default QuestionHeader;
