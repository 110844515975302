import React,{ useState, useEffect } from 'react';
import { numToKorean } from 'num-to-korean';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { removeCommas } from '../../../../module/replaceNumber';
import './InputText.css';
import clsx from 'clsx';
import MaskedInput from 'react-text-mask';

const InputWrap = styled.div`
    position: relative;     
    display: inline-block;
    .text,
    .kssn {
        appearance: none;
        width: 100%;
        padding: 13px 18px 14px;
        outline: none;
        border: 1px solid #c6cede;
        border-radius: 2px;
        background-color: #fff;
        color: #000;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: -0.5px;
        text-align: ${(props) => props.placeholderAlign === 'left' ? 'left' : 'right'};
        &::placeholder{
            color: #999fac;
            font-size: 20px;
            letter-spacing: -0.5px;
            text-align: ${(props) => props.placeholderAlign === 'left' ? 'left' : 'right'};
        }

        ${(props) => props.error !== "error" && 
        `
        &:focus {
            outline: 1px solid #006ac3;
            border : 1px solid #006ac3;
            border-radius: 2px;
            ${(props) => props.placeholderAlign === 'left' ? 'padding-left: 17px' : 'padding-right: 17px'};
        }
        `
        }
        &:disabled {
            cursor: not-allowed;
            background-color: #f1f2f5;
            border: 1px solid #c6cbd5;
            opacity: 1;
        }
    }
    .kssn {
        text-align: left;
        font-size: 20px;
        letter-spacing: 5px;
    
        &::placeholder {
            vertical-align: super;
            text-align: center;
            letter-spacing: 5px;
        }
    }
`;

const Explain = styled.div`  
    ${(props) => props.explain !== null &&  
    `
    display: inline-block;
    position: relative;
    margin-bottom: 5px;
    height: 18px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.64px;
    color: #6c6c6c;
    `
    }
`;
const ExplainReauired = styled.div`  
    display: inline-block;
    position: absolute;
    right: -10px;
    top: 0;
    color: #fc526e;
`;


const ReadNumber = styled.div`  
    margin-top: 7px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 15px;
    font-weight: 400;
    color: #000;
    text-align: right;
    ${(props) => props.readNumber || `color: #999fac;`}
`;

const ErrorText = styled.div`
    position: absolute;
    bottom: 0;
    transform: ${(props) => props.errorFixed ? 'translateY(100%)' : 'translateY(calc(100% - 27px))'};;
`;

const InputText = styled.input`
    &::-ms-clear {
        display: none;
    }    
`;

/**
* 필수값으로 input name 필요
*/

const Input = React.forwardRef((props,ref) => {

    const [error, setError] = useState(null);
    
    useEffect(() => {
        if(document.querySelector(`[name="${props.name}"]`).hasAttribute("data-error")) {
            setError(document.querySelector(`[name="${props.name}"]`).getAttribute("data-error"));
        }
    });

    const type = () => {
        let returnVal;
        if(props.textType === 'kssn') {
            returnVal = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
        }
        return returnVal;
    }

    const inputAttr = {
        required : props.required,
        name: props.name,
        style: props.style,
        placeholder: props.placeholder,
        onChange: (e) => props.onChange(e),
        ref : ref,
        readOnly : props.readOnly,
        onClick : props.onClick,
        maxLength: props.maxLength,
        onBlur: props.onBlur,
        disabled: props.disabled,
    };

    if(props.value === null && props.defaultValue !== null) {
        inputAttr.defaultValue = props.defaultValue ?? '';
    }

    if(props.defaultValue === null && props.value !== null) {
        inputAttr.value = props.value ?? '';
    }
    
    const inputType = () => {
        if(props.textType === 'kssn') {
            return <MaskedInput className={clsx('kssn', props.inputClassName, props.placeholderAlign === "left" ? 'left' : 'right', props.errorFixed ? "errorFixed" : "", props.errorIconNon ? "errorIconNon" : "")} mask={type()} guid="true" {...inputAttr} autoComplete="off" />
        } else {
            return <InputText type={props.type} className={clsx('text', props.inputClassName, props.placeholderAlign === "left" ? 'left' : 'right', props.errorFixed ? "errorFixed" : "", props.errorIconNon ? "errorIconNon" : "")} readNumber={props.readNumber} {...inputAttr} autoComplete="off" />
        }
    }

    return (
        <InputWrap style={props.wrapStyle} className={clsx("inputWrap", props.className)} placeholderAlign={props.placeholderAlign} 
        textType={props.textType} 
        error={error} errorText={props.errorText}> 
            <div>
                {props.explain !== null &&
                    <Explain explain={props.explain}>{props.explain}{props.explainReauired ? <ExplainReauired>*</ExplainReauired> : ""}</Explain> 
                }
            </div>

            {inputType()}

            {(error !== "error" && props.readNumber) && 
                <ReadNumber readNumber={props.value} error={error}>
                    {(props.value === '0' || !props.value) ? '영 원' : `${numToKorean(Number(removeCommas(String(props.value))))} 원`}
                </ReadNumber>
            }

            {props.errorText && 
                <ErrorText errorFixed={props.errorFixed}>{props.errorText}</ErrorText>
            } 
        </InputWrap>
    )
});

Input.propTypes = {
    /**
    *  input type
    */
    type: PropTypes.string,
    /**
    *  필수값
    */
    required: PropTypes.bool,
    /**
    *  input name
    */
    name: PropTypes.string,
    /**
    *  class명
    */
    className: PropTypes.string,
    /**
    *  input class명
    */
    inputClassName: PropTypes.string,
    /**
    * style 속성
    */
    style: PropTypes.object,
    /**
    * wrap style 속성
    */
    wrapStyle: PropTypes.object,
    /**
    *  text defaultvalue값
    */
    defaultValue: PropTypes.any,
    /**
    *  text value값
    */
    value: PropTypes.any,
    /**
    *  change 이벤트
    */
    onChange: PropTypes.func,
    /**
    *  placeholder
    */
    placeholder: PropTypes.string,
    /**
    *  placeholder text 정렬 위치
    */
    placeholderAlign: PropTypes.oneOf(['left', 'right']),
    /**
    *  text 입력 종류 <br/>
    *  주민등록번호: kssn
    */
    textType: PropTypes.oneOf(['kssn']),
    /**
    *  text 설명 
    */
    explain: PropTypes.string,
    /**
    *  text 설명 *표시 
    */
    explainReauired: PropTypes.bool,
    /**
    *  금액 한글로 표시
    */
    readNumber: PropTypes.bool,
    /**
    *  &lt;ErrorText&gt; 사용 <br/>
    *  강조: className="bold"
    */
    errorText: PropTypes.object,
    /**
    *  에러메시지를 절대좌표로 띄울지 여부
    */
    errorFixed: PropTypes.bool,
    readOnly: PropTypes.bool,
    onClick: PropTypes.func,
    onBlur: PropTypes.func,
    errorIconNon: PropTypes.bool,
    disabled: PropTypes.bool,
};

Input.defaultProps = {
    type: 'text',
    required: false,
    name: null,
    className: null,
    inputClassName : null,
    style: {},
    wrapStyle: {},
    defaultValue : null,
    value : null,
    onChange : () => {},
    placeholder: null,
    placeholderAlign: 'left',
    textType: null, 
    explain: null,
    readNumber: false,
    errorText: null,
    readOnly: false,
    onClick: () => {},
    onBlur: () => {},
    explainReauired : true,
    errorFixed : false,
    errorIconNon: false,
    disabled: false
};

export default Input;