import establishService from "@service/establishService";
import smartformAppModel from '@model/smartformAppModel';
import pageStepModel from '@model/pageStepModel';

class viewModel {
    loadEstablish() {
        establishService.loadEstablish();
    }
    get isLoadData() {
        return smartformAppModel.isLoadData;
    }
    get nowPage() {
        return pageStepModel.nowPage;
    }
}
export default new viewModel();