import { runInAction, toJS, observable } from "mobx";
import etcInfoModel from "@model/establish/etcInfoModel";
import stepService from "@service/stepService";
import smartformAppModel from "@model/smartformAppModel";
import formDataToJson from '@common/module/submit';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import { _alert, _confirm } from "@model/dialogModel";

class viewModel {
    constructor() {
        this.state = observable({
            isChanged: false
        });
    }
    dataError(content) {
        let error = false;
        let errorMessage = '';
        let errorState = 'yet';
        if(!content) {
            error = true;
            errorMessage = "사업목적을 입력해 주세요."
        }else{
            const 업regExp = /업\s*$/;
            const deleteSpaceRegExp = /\s/gi;
            const 글자수 = content.trim().length;
            let 업excepYn = false;
            let is중복 = false;
            const 업excep = [ //'~업'이 들어가지 않아도 되는 사업목적
                '부동산중개업',
                '상업용 건축물 및 주택의 임대관리 등 부동산의 관리대행',
                '부동산의 이용 및 개발에 관한 상담',
                '개업공인중개사를 대상으로 한 중개업의 경영기법 및 경영정보의 제공',
                '중개의뢰인의 의뢰에 따른 도배, 이사업체의 소개 등 주거이전에 부수되는 용역의 알선',
                '상업용 건축물과 주택의 분양대행',
                '경매 또는 공매대상 부동산에 대한 권리분석 및 취득의 알선과 매수신청 또는 입찰신청의 대리'
            ]
            업excep.forEach((excepPurpose, idx) => {
                if(content.replace(deleteSpaceRegExp, "") === excepPurpose.replace(deleteSpaceRegExp, "")) {
                    업excepYn = true;
                }
            })
            let selectedLength = 0;
            etcInfoModel.businessPurposes.map((item) => {
                if(item.content === content) {
                    selectedLength += 1;
                }
            })
            if(selectedLength > 1) {
                is중복 = true;
            }
            if(업excepYn) { //~'업'이 들어가지 않아도 되는 사업목적인 경우
                error = false;
            } else {
                if(!업regExp.test(content)) {
                    error = true;
                    errorMessage = "사업목적의 마지막 문구가 '~업'으로 끝나도록 입력해주세요.";
                } else if(글자수 < 3 || 글자수 > 80) {
                    error = true;
                    errorMessage = "3글자 이상 80글자 이하로 입력해주세요.";
                } else if(is중복) {
                    error = true;
                    errorMessage = "중복된 목적입니다. 하나만 남기고 삭제해주세요.";
                }
            }         
        }
        if(error) {
            errorState = 'error'
        } else {
            errorState = 'success'
        }
        return {
            errorState,
            errorMessage
        }
    }
    get smartFormType() {
        return smartformAppModel.smartFormType;
    }
    get businessPurposes() {
        return etcInfoModel.businessPurposes;
    }
    addBusinessPurposes() {
        runInAction(() => {
            etcInfoModel.businessPurposes.push({
                id : "",
                content : "",
                checked : false
            })
        })
    }
    content(e, idx) {
        runInAction(() => {
            etcInfoModel.businessPurposes[idx].content = e.target.value;
            this.state.isChanged = true;
        })
    }
    enterText(e, index) {
        const value = e.target.value;
        let splitArr = [];
        value.split('\n').map((item) => {
            if(item.length) {
                splitArr.push(item)
            }            
        })
        runInAction(() => {
            if(splitArr.length > 1) {
                splitArr.map((item, idx) => {
                    if(idx === 0) {
                        let content = splitArr[0];
                        if(content.split('1. ').length !== 1) {
                            content = splitArr[0].split('1. ')[1];
                        }
                        etcInfoModel.businessPurposes[index].content = content
                    }else{
                        let content = splitArr[idx];
                        if(content.split('1. ').length !== 1) {
                            content = splitArr[idx].split('1. ')[1];
                        }
                        etcInfoModel.businessPurposes.push({
                            content: content,
                            checked : false,
                            id:null
                        });
                    }
                })
            }
        })
    }
    checked(e, idx) {
        if(etcInfoModel.businessPurposes[idx].checked) {
            etcInfoModel.businessPurposes[idx].checked = false;
        }else{
            etcInfoModel.businessPurposes[idx].checked = true;
        }
    }
    get isCheckedAll() {
        if(etcInfoModel.businessPurposes.length === 0) {
            return false;
        }
        let isCheckedAll = true;
        etcInfoModel.businessPurposes.map((item) => {
            if(!item.checked) {
                isCheckedAll = false;
            }
        })
        return isCheckedAll;
    }
    checkedAll(e) {
        const value = e.target.value !== "true";
        runInAction(() => {
            etcInfoModel.businessPurposes.forEach(item => {    
                item.checked = value;
            })
        })
    }
    checkedLength() {
        let length = 0;
        etcInfoModel.businessPurposes.forEach(item => {
            if(item.checked) {
                length+=1;
            }
        })
        return length;
    }
    async selectedDelete() {
        let newArr = [];
        let isDeleteYn = false;
        etcInfoModel.businessPurposes.forEach(item => {
            if(!item.checked) {
                newArr.push(item)
            } else{
                isDeleteYn = true;
            }
        })
        if(isDeleteYn === false) {
            return _alert('삭제할 목적을 선택해 주세요.')
        } else{
            if(await _confirm("선택한 목적을 삭제하시겠습니까?")) {
                etcInfoModel.businessPurposes = newArr;
            }
        }
    }
    async deleteRow(idx) {
        if(await _confirm("선택한 목적을 삭제하시겠습니까?")) {
            etcInfoModel.businessPurposes.splice(idx, 1);
        }
    }
    addRow(idx) {
        runInAction(() => {
            etcInfoModel.businessPurposes.splice(idx+1, 0, {
                id : "",
                content : "",
                checked : false
            });
        })
    }
    onSortEnd(e) {
        this.arraymove(etcInfoModel.businessPurposes, e.oldIndex, e.newIndex);
    }
    arraymove(arr, fromIndex, toIndex) {
        const element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    }
    submit(e) {
        e.preventDefault();
        this.state.isChanged = true;
        const regExp = /([^가-힣A-Za-z0-9\s.,\[\]{}()%$:\n-])+/;
        //폼벨리데이션 테스트
        if(formDataToJson(e.target).error) {
            return
        }

        let param = formDataToJson(e.target).data;
        if(!param.businessPurposes) {
            return _alert('사업목적을 추가해 주세요.');
        }
        //목적 정규식 테스트
        let businessPurposesValidateYn = true;
        param.businessPurposes.map((businessPurposes) => {
            if(regExp.test(businessPurposes.content)) {
                businessPurposesValidateYn = false;
            }
        })
        if(!businessPurposesValidateYn) {
            return _alert('목적에는 정해진 문자 이외에는 사용할 수 없습니다.\n정해진 문자: 한글/영문/숫자/하이픈/구두점/쉼표/스페이스/대괄호/중괄호/소괄호/퍼센트/달러/콜론/Enter');
        }

        param.businessPurposes.map((businessPurposes, idx) => { //param.businessPurposes 안에 들어있는 checked 삭제
            param.businessPurposes[idx].content = etcInfoModel.businessPurposes[idx].content.trim();
        })
        stepService.saveStep(param);
    }
}
export default new viewModel();