function onlyEnglish() { //영문
    return /^[a-zA-Z ]{0,}$/;
}

function onlyEnglish_symbol() { //영문, 작은 따옴표('), 특수기호(앤드(&), 아포스트로피(`), 쉼표(,), 대시(-), 마침표(.), 가운뎃점(·))
    return /^[a-zA-Z&`',-.·0-9 ]{0,}$/;
}

function onlyKorean() { //한글
    return /^[가-힣 ]{0,}$/;
}

function onlyEngNum() { //영문, 숫자
    return /^[a-zA-Z0-9 ]{0,}$/;
}

function onlyKorNum() { //한글, 숫자
    return /^[가-힣0-9 ]{0,}$/;
}

function onlyKorNum_noSpacing() { //한글, 숫자, 띄어쓰기 사용 금지 
    return /^[가-힣0-9]*[가-힣]+[가-힣0-9]*$/;
}

function onlyKorNum_hyphen_comma() { //한글, 숫자, 하이픈, 쉼표
    return /^[가-힣0-9-, ]{0,}$/;
}

function onlyEngAddress() { //영문, 숫자, 쉼표(,), 대시(-), 마침표(.)
    return /^[a-zA-Z0-9,-. ]{0,}$/;
}

function deleteGap(x) { //앞뒤 공백 제거
    return x.replace(/^\s+|\s+$/gm,"");
}

function year() { //연도 
    return /^(19|20)[0-9]{2}$/;
}

function month() { //월
    return /^[0]{0,1}[1-9]{1}$|^1[0-2]{1}$/;
}

function day() { //일(날짜)
    return /^[0]{0,1}[1-9]{1}$|^[1-2]{1}[0-9]{1}$|^3[0-1]{1}$/;
}

function kssn(value) { //주민등록번호
    let data = value.replace("-","");
    let 뒷자리에서첫번째숫자 = parseInt(data.charAt(6));
    let 생년월일 = parseInt(data.substr(0,4));
    if((뒷자리에서첫번째숫자 === 3 || 뒷자리에서첫번째숫자 === 4) && 생년월일 >= 2010 && data.replace(/_/gi,"").length === 13) { //2020년10월이후 출생자는 아무거나 입력해도 통과한다
        return true;
    }
    let kssn = [];
    let kssn_last = data.charAt(data.length-1);
    let num = [2,3,4,5,6,7,8,9,2,3,4,5];
    for(let i=0; i<data.length-1; i++) {
        kssn[i] = data.charAt(i) * num[i];
    }
    let sum = 0;
    for(let i=0; i<data.length-1; i++) {
        sum += kssn[i];
    }
    sum = 11- (sum % 11);
    if(sum > 9) {
        sum = sum % 10;
    }
    if(sum === Number(kssn_last)) return true;
    else return false;
}

function priceCheck() { //100원 단위로 입력
    return /^([1-9]{1}[0-9]{0,2},([0-9]{3},)*[0-9]{1}0{2})$|^[1-9]{1}0{2}$/;
}

function urlCheck() { //http:// https:// 포함하면 안됨.
    return /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/gi;
}

export default { onlyEnglish, onlyEnglish_symbol,onlyKorean, onlyEngNum, onlyKorNum, onlyKorNum_noSpacing, onlyKorNum_hyphen_comma, onlyEngAddress, deleteGap, year, month, day, kssn, priceCheck, urlCheck };
