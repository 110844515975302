import { useObserver } from 'mobx-react';
import Ast from '../../AdminFinalCheckView.module.scss';
import st from './BranchOfficeChangeStep.module.scss';
import clsx from 'clsx';
import BranchOfficeChangeStepVm from './BranchOfficeChangeStepVm';
import copyText from '@common/module/copyText';
import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';
import Icon from '@comComponents/atoms/Icon/Icon';

function BranchOfficeChangeStep(props) {
    return useObserver(() => (
        <div className={clsx(Ast.row, st.branchOfficeChange)} style={{display:'block'}}>
            {props.step.branches.map((item, idx) => {
                return (
                    <div className={st.changeInfoBox} key={idx}>
                        <div className={st.changeBranchInfo}> 
                            <div className={st.dName}>변경할 지점</div>
                            <div className={st.data}>{item.branchAddress ? item.branchAddress : '-'}</div>
                        </div>
                        <div className={st.changeInfo}> 
                            <div className={st.dName}>변경할 내용</div>
                            <div className={st.data}>
                                {item.newBranchName &&
                                    <div className={st.changeInfoDetail}>
                                        <div className={st.newBranch}>지점명 변경</div>
                                        <div className={st.newBranchName}>
                                            <div>지점명 : <CopyBtn onClick={() => copyText(item.newBranchName)}>{item.newBranchName}</CopyBtn></div>
                                            <div>지점명 변경일자 : <CopyBtn onClick={() => copyText(item.causeDate)}>{item.causeDate}</CopyBtn></div>
                                        </div>
                                    </div>
                                }
                                {item.managerChangeType !== "NONE" &&
                                    <div className={st.changeInfoDetail}>
                                        <div className={st.managerChange}>{item.managerChangeType ? BranchOfficeChangeStepVm.managerChangeType(item.managerChangeType) : '-'}</div>
                                        <div className={st.managerChangeInfo}>
                                            <div>
                                                {item.manager.name ?
                                                    <CopyBtn onClick={() => copyText(item.manager.name)}>{item.manager.name}</CopyBtn>
                                                    :
                                                    '-'
                                                }
                                                {item.manager.kssnOrBirth && <CopyBtn onClick={() => copyText(item.manager.kssnOrBirth)}>({item.manager.kssnOrBirth})</CopyBtn>}
                                            </div>
                                            {item.manager.koreanYn === false && <div>국적 : {item.manager.nationality}</div>}
                                            {BranchOfficeChangeStepVm.displayAddressYn(item.managerChangeType) &&
                                                <div>{item.managerChangeType === 'CHANGE_ADDRESS' ? '변경된 주소' : '주소'} : 
                                                    {item?.manager?.address !== null ?
                                                    <>
                                                        <CopyBtn onClick={() => copyText(item?.manager?.address?.sido)} className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)} style={{marginLeft:'3px'}}>{item?.manager?.address?.sido}</CopyBtn>
                                                        <CopyBtn onClick={() => copyText(item?.manager?.address?.sigungu)} className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}>{item?.manager?.address?.sigungu}</CopyBtn>
                                                        {item?.manager?.address?.bname1 && <CopyBtn onClick={() => copyText(item?.manager?.address?.bname1)} className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}>{item?.manager?.address?.bname1}</CopyBtn>}
                                                        <CopyBtn onClick={() => copyText(item?.manager?.address?.roadname)} className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}>{item?.manager?.address?.roadname}</CopyBtn>
                                                        <CopyBtn onClick={() => copyText(item?.manager?.address?.buildingNo)} className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}>{item?.manager?.address?.buildingNo}</CopyBtn>,
                                                        <CopyBtn onClick={() => copyText(item?.manager?.address?.detailAddress)} className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}>{item?.manager?.address?.detailAddress}</CopyBtn>
                                                        <div className={Ast.addressCopyBracket}>
                                                            (<CopyBtn onClick={() => copyText(item?.manager?.address?.bname)} className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}>{item?.manager?.address?.bname}</CopyBtn>,
                                                            <CopyBtn onClick={() => copyText(item?.manager?.address?.nameOfBuilding)} className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}>{item?.manager?.address?.nameOfBuilding}</CopyBtn>)
                                                        </div>
                                                        <CopyBtn onClick={() => copyText(item?.manager?.address?.fullAddress)} className={Ast.addressCopyAllBtn}><Icon icon="copy" className={Ast.copyIcon}/></CopyBtn>
                                                    </>
                                                    :
                                                    ' -'
                                                    }
                                                </div>
                                            }
                                            {BranchOfficeChangeStepVm.displayManagerChangeDateYn(item.managerChangeType) &&
                                                <div>지배인 변경일자 : <CopyBtn onClick={() => copyText(item.manager.causeDate)}>{item.manager.causeDate}</CopyBtn></div>
                                            }
                                            {BranchOfficeChangeStepVm.displayManagerCauseDateYn(item.managerChangeType) &&
                                                <div>전입신고 일자 : <CopyBtn onClick={() => copyText(item.manager.causeDate)}>{item.manager.causeDate}</CopyBtn></div>
                                            }
                                            {item.managerChangeType === 'CHANGE_MANAGER' &&
                                                <div>기존 지배인의 주민등록번호 : <CopyBtn onClick={() => copyText(item.manager.originName)}>{item.manager.originName}</CopyBtn>님 (<CopyBtn onClick={() => copyText(item.manager.originKssnOrBirth)}>{item.manager.originKssnOrBirth}</CopyBtn>)</div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    ));
}

export default BranchOfficeChangeStep;