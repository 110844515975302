import { toJS } from 'mobx';
import { Fragment } from 'react';
import { useObserver } from 'mobx-react';
import clsx from 'clsx';
import st from '../FileUploadStep.module.scss';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import Upload from '@comComponents/molecules/FileUpload/FileUpload';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';

function Seal(props) {
    return useObserver(() => (
        <Question>
            <ContentBox className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    {props.item.details.map((detail, idx2) => (
                        <Fragment key={idx2}>
                            {detail.fileType === '인감대지' &&
                                <>
                                    <div className={Qst.titleWrap}>
                                        <h2 className={Qst.title}>
                                            <span className={Qst.bold}>인감대지, 인감신고서</span>를 업로드해 주세요.
                                        </h2>
                                    </div>
                                    <Upload className={clsx(st.fileUpload, `${detail.fileType}`)} multiple accept=".pdf, .jpg, .png"
                                    model={props.parentVm.fileGroupTypes[props.idx].details[idx2].files}
                                    uploadModel={props.parentVm.fileGroupTypes[props.idx].details[idx2].uploadFiles}
                                    deleteModel={props.parentVm.deleteIds}
                                    files={toJS(props.parentVm.files(props.idx, idx2))} uploadFiles={toJS(props.parentVm.uploadFiles(props.idx, idx2))}       
                                    explainText={<div><span className="bold">도장을 최대한 선명하게</span> 찍어주세요. 찍은 인영이 인감증명서에 그대로 나옵니다.</div>}
                                    >
                                        <span className={st.bold}>여기</span>를 눌러 업로드해 주세요.
                                    </Upload>
                                    {(props.parentVm.state.errorCheckYn && props.parentVm.displayErrorText(detail.fileType)) && <ErrorText>인감대지, 인감신고서를 업로드해 주세요.</ErrorText>}
                                </> 
                            }
                        </Fragment>
                    ))}
                </div>
                <div className={Qst.qnaSection}>
                    <Qna className={Qst.noTitle}>
                        <div className={Qst.qnaBox}>
                            <QText>인감대지, 인감신고서는 어떻게 작성하나요?</QText>
                            <div className="answerBox">
                                <Answer className={Qst.answer}>아래의 절차에 따라 인감대지, 인감신고서를 작성해주세요.</Answer>
                                <ol className={st.orderList}>
                                    <li>
                                        이메일에 첨부된 인감대지, 인감신고서 양식을 출력해 주세요. 아래 링크에서도 다운로드 받을 수 있습니다.
                                        <LikeBtn className={st.likeBtn} href="https://sc.help-me.kr/smartformV3/인감대지_인감신고서_양식.docx" target="_blank">인감대지, 인감신고서 양식 다운로드</LikeBtn>
                                    </li>
                                    <li>출력한 양식에 법인인감도장을 찍어주세요.</li>
                                    <li>도장찍은 양식을 '고화질로 컬러스캔'해서 업로드해 주세요.</li>
                                </ol>
                            </div>
                        </div>
                        <div className={Qst.qnaBox}>
                            <QText>인감대지, 인감신고서가 왜 필요한가요?</QText>
                            <div className="answerBox">
                                <Answer className={Qst.answer}>대표권의 변경이 있는 경우, 등기절차상 필요합니다.</Answer>
                                <div className={Qst.explain}>
                                    대표권의 변경이 있는 경우, 법원은 보안관계상 법인인감신고를 다시 하도록 강제합니다.
                                </div>
                                <div className={Qst.explain}>
                                    대표님이 추가, 변경되거나, 퇴임/사임 후 재취임을 하거나, 대표님만 남고 나머지 분들이 퇴임/사임하는 경우는 대표권의 변경이 있는 경우로 취급합니다.
                                </div>
                            </div>
                        </div>
                        <div className={Qst.qnaBox}>
                            <QText>인감도장을 새로 만들어야 하나요?</QText>
                            <div className="answerBox">
                                <Answer className={Qst.answer}>아니요. 인감도장은 그대로 사용해도 좋습니다.</Answer>
                                <div className={Qst.explain}>
                                    대표권의 변경이 있는 경우, 법원은 보안관계상 법인인감신고를 다시 하도록 강제합니다.
                                </div>
                            </div>
                        </div>
                    </Qna>
                </div>
            </ContentBox>
        </Question>
    ));
}

export default Seal;