import { useObserver } from 'mobx-react';
import RepresentativeCheckVm from './RepresentativeCheckVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import SearchAddress from '@comComponents/atoms/SearchAddress/SearchAddress';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import regExp from '@common/module/regExp';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import clsx from 'clsx';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import SquareBtn from '@comComponents/atoms/Button/SquareBtn/SquareBtn';
import Icon from '@comComponents/atoms/Icon/Icon';
import CloseBtn from '@comComponents/atoms/Button/CloseBtn/CloseBtn';
import { useEffect } from 'react';

function RepresentativeCheck() {

    return useObserver(() => (
        <form onSubmit={(e) => RepresentativeCheckVm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            {RepresentativeCheckVm.representatives.map((item, idx) => (
                <Question key={idx}>
                    <ContentBox>
                        <input type="hidden" name={`representatives[${idx}].id`} value={item.id}/>
                        {item.foreignerYn === false && 
                            <div className={Qst.spaceBetween}>
                                <div className={Qst.infoSection}>
                                    <div className={Qst.titleWrap}>
                                        <h2 className={Qst.title}>
                                            <span className={Qst.bold}>{item.koreanName} 대표님</span>의 <span className={Qst.bold}>주민등록번호</span>를 입력해 주세요.
                                        </h2>
                                        <div className={Qst.titleExplain}>
                                            등기를 위해서는 주민등록번호가 필요합니다.
                                        </div>
                                        <div className={Qst.titleExplain}>
                                            주민등록번호는 헬프미 스마트 보안 시스템에 의하여 안전하게 보관됩니다.
                                        </div>
                                    </div>
                                    <InputText placeholder="111111-1111111" style={{width:'316px'}} textType="kssn" value={item.kssn} onChange={(e) => RepresentativeCheckVm.setKssn(e,idx)} name={`representatives[${idx}].kssn`} errorText={<Error name={`representatives[${idx}].kssn`} value={item.kssn} errorCase={{
                                        required: '주민등록번호를 입력해 주세요.',
                                        validate: {
                                        func: regExp.kssn(item.kssn),
                                        message: '올바른 주민등록번호를 입력해 주세요.'
                                        }
                                    }}/>} />
                                </div>
                            </div>
                        }
                        <div className={Qst.spaceBetween}>
                            <div className={Qst.infoSection}>
                                <div className={Qst.content}>   
                                    <div className={Qst.titleWrap}>
                                        <h2 className={Qst.title}>
                                            {item.koreanName} 대표님의 <span className={Qst.bold}>현재 주민등록상 주소</span>가 <span className={Qst.bold}>{item.foreignerYn ? '거소신고(외국인등록) 사실증명상 주소' : '등기부상 주소'}</span>와 같나요?
                                        </h2>
                                    </div>
                                    <div className={Qst.addressCheck}>
                                        <div className={Qst.address}>{item.koreanName} 대표님의 {item.foreignerYn ? '거소신고(외국인등록) 사실증명상 주소' : '등기부상 주소'}</div>
                                        <div className={Qst.originAddress}>{item.originAddress}</div>
                                        <div className={Qst.originCauseDate}>{item.originCauseDate} {item.originCauseDateByEstablishmentYn ? '설립' : item.foreignerYn ? '변경' : '전입'}</div>
                                    </div>
                                    <div className={Qst.addressCheckExpian}>주소변동시 <span className={Qst.bold}>{item.originCauseDate} 이후 주소변동내역</span>을 모두 입력해 주세요.</div>
                                    <ul className={Qst.answerList}>
                                        <li><Radio className={Qst.answerLabel} value={true} activeBackground checked={item.addressSameYn === true} onChange={(e) => RepresentativeCheckVm.setAddressSameYn(e, idx)} name={`representatives[${idx}].addressSameYn`}>네. 같습니다.</Radio></li>
                                        <li><Radio className={Qst.answerLabel} value={false} activeBackground checked={item.addressSameYn === false} onChange={(e) => RepresentativeCheckVm.setAddressSameYn(e, idx)} name={`representatives[${idx}].addressSameYn`}>아니오. 다릅니다.</Radio></li>
                                    </ul>
                                    <Error name={`representatives[${idx}].addressSameYn`} value={item.addressSameYn} errorCase={{required:'현재 주민등록상 주소가 등기부상 주소와 같은지 선택해 주세요.'}}/>
                                </div>
                            </div>
                            <div className={Qst.qnaSection}>
                                <Qna tip={item.foreignerYn ? '기존 등기부상 대표 변경시' : null} className={item.foreignerYn ? Qst.qnaTitle : Qst.noTitle}>
                                    <div className={Qst.qnaBox}>
                                        <QText>대표 주소가 변경된 경우 반드시 등기해야 하나요?</QText>
                                        <div className="answerBox">
                                            <Answer className={Qst.answer}>대표 주소가 변경된 경우 반드시 등기해야 합니다.</Answer>
                                            <p className={Qst.explain}>
                                                대표이사 주소가 변경된 경우, 전입신고일로부터 2주 내에 대표자주소변경등기를 접수해야 합니다. 만약 시기를 놓치는 경우, 매월 5~10만원의 과태료가 부과되므로 최대한 빠르게 등기를 접수해주세요.
                                            </p>
                                        </div>
                                    </div>
                                    {RepresentativeCheckVm.nowStep !== "ExecutiveMemberChangeStep" && 
                                    <>  
                                        <div className={Qst.qnaBox}>
                                            <QText>대표 주소가 변경된 경우, 등기할 때 추가비용이 드나요?</QText>
                                            <div className="answerBox">
                                                <Answer className={Qst.answer}>네. 견적에 미리 포함된 경우가 아니라면, 추가 비용이 발생합니다.</Answer>
                                                <p className={Qst.explain}>
                                                    견적에 대표자주소변경등기 견적이 포함되지 않은 경우, 109,000원(부가세 제외)의 수수료가 추가됩니다. 또한 여러 명의 대표님의 주소가 각각 변경된 경우, 인원수 X 109,000원(부가세 제외)의 수수료가 추가됩니다. 덧붙여 관외지점이 있는 경우, 관외지점 등기부도 변경해야 하기 때문에, 관외지점마다 추가비용이 발생합니다.
                                                </p>
                                                <p className={Qst.explain}>
                                                    이미 견적에 대표자주소변경 수수료가 포함된 경우에는 추가비용이 발생하지 않습니다.
                                                </p>
                                            </div>
                                        </div>
                                        <div className={Qst.qnaBox}>
                                            <QText>대표가 그만두는 경우에도 대표 주소변경 등기를 해야 하나요?</QText>
                                            <div className="answerBox">
                                                <Answer className={Qst.answer}>네. 대표가 그만두는 경우에도 대표 주소변경 등기를 접수해야 합니다.</Answer>
                                                <p className={Qst.explain}>
                                                    대표를 그만두는 경우에도, 사임/퇴임 등기와 동시에 대표자주소변경등기를 접수해야 합니다. 만약 동시에 신청하지 않는 경우, 법원에서는 보정명령을 내리고 사임/퇴임 등기를 마쳐주지 않습니다.
                                                </p>
                                            </div>
                                        </div>
                                        <div className={Qst.qnaBox}>
                                            <QText>대표님의 과거 주민등록상 집주소를 모두 등기해야 하나요?</QText>
                                            <div className="answerBox">
                                                <Answer className={Qst.answer}>대표님의 과거 주민등록상 집주소 변동내역은 모두 등기해야 합니다.</Answer>
                                                <p className={Qst.explain}>
                                                관련법령상 법인 대표자의 과거 주민등록상 집주소 변동내역은 모두 등기해야 하고, 반드시 한꺼번에 등기신청을 해야 합니다. 만일 과거 변동내역 등기는 생략하고, 최신 주민등록상 집주소만 기재하여 등기신청을 하는 경우, 법원에서는 보정명령을 내리고, 등기접수를 받아주지 않습니다. 이 경우 5~7일 시간이 더 걸리게 되고, 헬프미 보정명령 처리 수수료(30,000원(부가세 제외))가 추가되므로, 주의 부탁드립니다.
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                        
                                    }
                                    {RepresentativeCheckVm.nowStep === "ExecutiveMemberChangeStep" && 
                                    <>
                                        <div className={Qst.qnaBox}>
                                            <QText>대표 주소가 변경된 경우, 등기할 때 추가비용이 드나요?</QText>
                                            <div className="answerBox">
                                                <Answer className={Qst.answer}>네. 견적에 미리 포함된 경우가 아니라면, 추가 비용이 발생합니다.</Answer>
                                                <p className={Qst.explain}>
                                                    견적에 대표자주소변경등기 견적이 포함되지 않은 경우, 109,000원(부가세 제외)의 수수료가 추가됩니다. 또한 여러 명의 대표님의 주소가 각각 변경된 경우, 인원수 X 109,000원(부가세 제외)의 수수료가 추가됩니다. 덧붙여 관외지점이 있는 경우, 관외지점 등기부도 변경해야 하기 때문에, 관외지점마다 추가비용이 발생합니다.
                                                </p>
                                                <p className={Qst.explain}>
                                                    이미 견적에 대표자주소변경 수수료가 포함된 경우에는 추가비용이 발생하지 않습니다.
                                                </p>
                                            </div>
                                        </div>
                                        <div className={Qst.qnaBox}>
                                            <QText>대표가 그만두는 경우에도 대표 주소변경 등기를 해야 하나요?</QText>
                                            <div className="answerBox">
                                                <Answer className={Qst.answer}>네. 대표가 그만두는 경우에도 대표 주소변경 등기를 접수해야 합니다.</Answer>
                                                <p className={Qst.explain}>
                                                    대표를 그만두는 경우에도, 사임/퇴임 등기와 동시에 대표자주소변경등기를 접수해야 합니다. 만약 동시에 신청하지 않는 경우, 법원에서는 보정명령을 내리고 사임/퇴임 등기를 마쳐주지 않습니다.
                                                </p>
                                            </div>
                                        </div>
                                        <div className={Qst.qnaBox}>
                                            <QText>대표님의 과거 주민등록상 집주소를 모두 등기해야 하나요?</QText>
                                            <div className="answerBox">
                                                <Answer className={Qst.answer}>대표님의 과거 주민등록상 집주소 변동내역은 모두 등기해야 합니다.</Answer>
                                                <p className={Qst.explain}>
                                                    관련법령상 법인 대표자의 과거 주민등록상 집주소 변동내역은 모두 등기해야 하고, 반드시 한꺼번에 등기신청을 해야 합니다. 만일 과거 변동내역 등기는 생략하고, 최신 주민등록상 집주소만 기재하여 등기신청을 하는 경우, 법원에서는 보정명령을 내리고, 등기접수를 받아주지 않습니다. 이 경우 5~7일 시간이 더 걸리게 되고, 헬프미 보정명령 처리 수수료(30,000원(부가세 제외))가 추가되므로, 주의 부탁드립니다.
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                    }
                                </Qna>
                            </div>
                        </div>
                        {item.addressSameYn === false && 
                        <>
                            <div className={Qst.spaceBetween} style={{paddingBottom:'30px'}}>
                                <div className={Qst.infoSection}>
                                    {item.foreignerYn ?
                                        <div className={Qst.titleWrap}>
                                            <h2 className={Qst.title}>
                                                <span className={Qst.bold}>{item.koreanName}</span>님의 <span className={Qst.bold}>거소신고(외국인등록) 사실증명상 도로명주소</span>와 <span className={Qst.bold}>주소변경 일자</span>를 입력해 주세요.
                                            </h2>
                                            <div className={clsx(Qst.titleExplain, Qst.noneMark)} style={{"color":"#fd6200"}}>
                                                반드시 국내 거소신고(외국인등록) 사실증명과 정확히 일치하는 주소를 입력해주세요.
                                            </div>
                                            <div className={clsx(Qst.titleExplain, Qst.noneMark)} style={{"color":"#fd6200"}}>
                                                거소신고(외국인등록) 사실증명상 주소와 일치하지 않는 경우, 등기신청이 거절됩니다. (5~7일 더 걸림)
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={Qst.title}>
                                                <span className={Qst.bold}>{item.koreanName}</span>님의 <span className={Qst.bold}>주민등록등본상 신규 주소</span>와 <span className={Qst.bold}>전입신고 일자</span>를 입력해 주세요.
                                                </h2>
                                            </div>
                                            <div className={Qst.addressBadgeTitle}>주민등록상 주소 확인방법</div>
                                            <Badge className={Qst.addressBadge} title="예시" type="example" border={false}>
                                                <img className={Qst.addressBadgeImg} src="/images/person_address_example.png"
                                                alt="주민등록상 도로명주소 예시" />
                                            </Badge>
                                            <div className={Qst.reportDateBadgeTitle}>전입신고 일자 확인방법</div>
                                            <Badge className={Qst.reportDateBadge} title="예시" type="example" border={false}>
                                                <img className={Qst.reportDateBadgeImg} src="/images/causedate_example.png"
                                                alt="주민등록상 전입신고일자 예시" />
                                            </Badge>
                                            <div className={Qst.newAddressWrap}>
                                                <div className={Qst.title}>법인등기부상 {item.koreanName}님의 집주소 및 전입신고 일자</div>
                                                <div className={Qst.newAddressTable}>
                                                    <div className={Qst.address}>
                                                        <div className={Qst.addressTitle}>주민등록상 주소</div>
                                                        <div className={Qst.originAddress}>
                                                            <div>{item.originAddress}</div>
                                                            <div className={Qst.orignAddressExplain}>법인등기부상 {item.koreanName}님의 집주소</div>
                                                        </div>
                                                    </div>
                                                    <div className={Qst.date}>
                                                        <div className={Qst.dateTitle}>전입신고 일자</div>
                                                        <div className={Qst.originCauseDate}>{item.originCauseDate} 전입</div>
                                                    </div>
                                                </div>
                                                <div className={Qst.newAddressExplain}>
                                                    {item.koreanName}님의 주민등록상 주소가 마지막 등기된 때로부터 <span className={Qst.bold}>여러번 바뀌었다면 변동내역을 모두 입력</span>해 주세요. 법인등기부에는 대표님의 과거 주민등록상 집주소 변동내역이 모두 등기되어야 합니다. (최신주소만 입력해서 등기신청을 하는 경우, 보정명령이 내려짐)
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {item.newAddresses.map((item2, idx2) => {
                                        return (
                                            <div className={Qst.newAddressList} key={idx2}>
                                                <div className={Qst.newAddressTitle}>변동내역 {idx2+1}</div>
                                                <div className={Qst.newAddressBox}>
                                                    <div className={Qst.rowWrap}>
                                                        <InputText className={Qst.inputText} style={{width:'380px'}} explain="도로명 주소" value={item2?.newAddress?.address || ''} readOnly onClick={() => RepresentativeCheckVm.openAddressModal(true, idx, idx2)} name={`representatives[${idx}].newAddresses[${idx2}].newAddress.address`} errorText={<Error name={`representatives[${idx}].newAddresses[${idx2}].newAddress.address`} value={item2?.newAddress?.address} errorCase={{required:'도로명 주소를 입력해 주세요.'}}/>} /><SquareBtn onClick={() => RepresentativeCheckVm.openAddressModal(true,idx, idx2)}>도로명 주소 찾기</SquareBtn>
                                                    </div>
                                                    <div className={Qst.rowWrap}>
                                                        <InputText className={Qst.inputText} style={{width:'271px'}} explain="상세 주소" placeholder="상세주소 없을 시, [없음] 입력" value={item2.detailAddress} onChange={(e) => RepresentativeCheckVm.setDetailAddress(e, idx, idx2)} name={`representatives[${idx}].newAddresses[${idx2}].detailAddress`} errorText={<Error name={`representatives[${idx}].newAddresses[${idx2}].detailAddress`} value={item2.detailAddress} errorCase={{
                                                            required:'상세 주소를 입력해 주세요.',
                                                            pattern : {
                                                            value: regExp.onlyKorNum_hyphen_comma().test(item2.detailAddress),
                                                            message: <span>임대차 계약서상 영문이 있다면, <br/>한글로 옮겨적어주세요.<br/>EX) B동 &gt; 비동</span>
                                                            }  
                                                        }} />} />
                                                        <div className={Qst.symbol}>
                                                            <span className={Qst.symbolIcon}>&#40;&nbsp;</span><span className={clsx(Qst.symbolText, RepresentativeCheckVm.bnameFontSizeChange(idx, idx2) ? Qst.fontSize : null)}>{item2?.newAddress?.bname || '법정동'},&nbsp;</span>
                                                            <InputText style={{width:'200px'}} explain="건물명" placeholder="없을 시, [없음] 입력" value={item2.nameOfBuilding} onChange={(e) => RepresentativeCheckVm.setNameOfBuilding(e, idx, idx2)} name={`representatives[${idx}].newAddresses[${idx2}].nameOfBuilding`} errorText={<Error name={`representatives[${idx}].newAddresses[${idx2}].nameOfBuilding`} value={item2.nameOfBuilding} errorCase={{
                                                            required:'건물명을 입력해 주세요.',
                                                            pattern : {
                                                                value: regExp.onlyKorNum_hyphen_comma().test(item2.nameOfBuilding),
                                                                message: <span>건물명이 영문인 경우, 한글<br/>발음으로 옮겨적어 주세요. <br/>EX) AB타워 &gt; 에이비 타워</span>
                                                            }   
                                                            }}/>}/><span className={Qst.symbolIcon}>&nbsp;&#41;</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={Qst.causeDateBox}>
                                                    <div className={Qst.causeDateTitle}>{item.foreignerYn ? '주소변경 일자' : '전입신고 일자'}</div>
                                                    <div className={Qst.birthWrap} style={{marginLeft: '0'}}>
                                                        <InputText style={{width:'130px'}} placeholder="0000" name={`representatives[${idx}].newAddresses[${idx2}].causeDate.year`} value={item2.causeDate?.year} onChange={(e) => RepresentativeCheckVm.setYear(e, idx, idx2)} maxLength="4" />
                                                        <span className={Qst.birthText}>년</span>
                                                    </div>
                                                    <div className={Qst.birthWrap}>
                                                        <InputText style={{width:'108px'}} placeholder="00" name={`representatives[${idx}].newAddresses[${idx2}].causeDate.month`} value={item2.causeDate?.month} onChange={(e) => RepresentativeCheckVm.setMonth(e,idx, idx2)} maxLength="2" onBlur={(e) => RepresentativeCheckVm.monthBlur(e,idx, idx2)} />
                                                        <span className={Qst.birthText}>월</span>
                                                    </div>
                                                    <div className={Qst.birthWrap}>
                                                        <InputText style={{width:'108px'}} placeholder="00" name={`representatives[${idx}].newAddresses[${idx2}].causeDate.day`} value={item2.causeDate?.day} onChange={(e) => RepresentativeCheckVm.setDay(e, idx, idx2)} maxLength="2" onBlur={(e) => RepresentativeCheckVm.dayBlur(e, idx, idx2)} />
                                                        <span className={Qst.birthText}>일 {item.foreignerYn ? '변경' : '전입'}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Error style={{marginTop:'-27px'}} name={`representatives[${idx}].newAddresses[${idx2}].causeDate.year`} value={item2.causeDate?.year} 
                                                    errorCase={{
                                                    required:'연도를 입력해 주세요.',
                                                    pattern: {
                                                        value: regExp.year().test(item2.causeDate?.year),
                                                        message: '올바른 연도를 입력해 주세요'               
                                                    }
                                                    }}
                                                    />
                                                    <Error style={{marginTop:'-27px'}} name={`representatives[${idx}].newAddresses[${idx2}].causeDate.month`} value={item2.causeDate?.month} 
                                                    errorCase={{
                                                    required: RepresentativeCheckVm.displayBirthErrorMessage(idx, idx2, 'month') ? '월을 입력해 주세요.' : null,
                                                    pattern: {
                                                        value: RepresentativeCheckVm.displayBirthErrorMessage(idx, idx2, 'month') ? regExp.month().test(item2.causeDate?.month) : null,
                                                        message: '올바른 월을 입력해 주세요'               
                                                    }
                                                    }}
                                                    />
                                                    <Error style={{marginTop:'-27px'}} name={`representatives[${idx}].newAddresses[${idx2}].causeDate.day`} value={item2.causeDate?.day} 
                                                    errorCase={{
                                                    required: RepresentativeCheckVm.displayBirthErrorMessage(idx, idx2, 'day') ? '일(날짜)을 입력해 주세요.' : null,
                                                    pattern: {
                                                        value: RepresentativeCheckVm.displayBirthErrorMessage(idx, idx2, 'day') ? regExp.day().test(item2.causeDate?.day) : null,
                                                        message: '올바른 일(날짜)을 입력해 주세요.'           
                                                    }
                                                    }}
                                                    />
                                                </div>
                                                {idx2 !== 0 && 
                                                    <CloseBtn className={Qst.closeBtn} onClick={() => RepresentativeCheckVm.deleteNewAddress(idx,idx2)} />
                                                }
                                            </div>
                                        )
                                    })}
                                    <button type="button" className={Qst.newAddressAddBtn} onClick={() => RepresentativeCheckVm.addNewAddress(idx)}>{item.foreignerYn ? '거소신고 사실증명상 도로명 주소' : '주민등록상 집주소'} <span className={Qst.bold}>변동내역 추가</span>하기<Icon icon="addGray" className={Qst.addGray}/></button>
                                </div>
                            </div>
                        </>
                        }
                    </ContentBox>  
                </Question>
            ))}
            {/* 도로명주소 팝업 */}
            {RepresentativeCheckVm.addressModal && 
                <SearchAddress onClose={() => RepresentativeCheckVm.openAddressModal(false,null,null)} onComplete={(data) => RepresentativeCheckVm.addressComplate(data)} />
            }
        </form>
    ));
}

export default RepresentativeCheck;