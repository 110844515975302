import { observable, runInAction } from 'mobx';

const businessPurposeStepModel = observable({
    newBusinessPurposes : [],
    originalBusinessPurposes : [],
    setBusinessPurposeRevision(data) {
        runInAction(() => {
            this.originalBusinessPurposes = data?.originalBusinessPurposes || [];
            if(data?.newBusinessPurposes && data?.newBusinessPurposes.length) {
                this.newBusinessPurposes = [];
                data.newBusinessPurposes.forEach(item => {
                    this.newBusinessPurposes.push({
                        checked : false,
                        content : item.content || "",
                        id : item.id || ""
                    })       
                });
            }else{
                this.newBusinessPurposes = [{
                    content : "",
                    checked : false
                }];
            }
        })
    }
});

export default businessPurposeStepModel;