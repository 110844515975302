import { useObserver } from 'mobx-react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './FreeLicenseRegistration.module.scss';
import clsx from 'clsx';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import FreeLicenseRegistrationVm from './FreeLicenseRegistrationVm';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import HyperLink from '@comComponents/atoms/HyperLink/HyperLink/HyperLink';

function FreeLicenseRegistration() {
  return useObserver(() => (
    <form onSubmit={(e) => FreeLicenseRegistrationVm.submit(e)}>
        <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
        <Question>
          <ContentBox className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
              <div className={Qst.titleWrap}>
                <h2 className={Qst.title}>
                  <span className={Qst.bold}>헬프미 파트너사를 통해 무료 사업자등록을 </span>해드릴까요?
                </h2>
                <div className={Qst.titleExplain}>
                  무료 사업자등록 서비스는 파트너사의 사업자등록 프로그램을 통해 진행됩니다.
                </div>
              </div>
              <ul className={Qst.answerList}>
                <li><Radio className={Qst.answerLabel} name="freeLicenseRegistrationYn" value={true} activeBackground checked={FreeLicenseRegistrationVm.freeLicenseRegistrationYn === true} onChange={(e) => FreeLicenseRegistrationVm.setFreeLicenseRegistrationYn(e)}>헬프미 파트너사를 통해 사업자등록을 하겠습니다.<br/><span className={st.radioExplain}>(+사업자등록을 위한 <HyperLink color='blue' href="https://docs.google.com/document/d/1gexfnmth2bxTXAmI5Ly_sgpg77bVHruZef_YfsaH6IY/edit" target="_blank" title="제3자 정보제공 약관으로 이동">제3자 정보제공 동의</HyperLink> 포함)</span></Radio></li>
                <li><Radio className={Qst.answerLabel} name="freeLicenseRegistrationYn" value={false} activeBackground checked={FreeLicenseRegistrationVm.freeLicenseRegistrationYn === false} onChange={(e) => FreeLicenseRegistrationVm.setFreeLicenseRegistrationYn(e)}>사업자등록을 별도로 하겠습니다.</Radio></li>
              </ul>
              <Error name="freeLicenseRegistrationYn" value={FreeLicenseRegistrationVm.freeLicenseRegistrationYn} errorCase={{required:'무료 사업자등록 여부를 선택해 주세요.'}} />
            </div>
            <div className={Qst.qnaSection}>
              <Qna tip="사업자등록" className={Qst.qnaTitle}>
                <div className={Qst.qnaBox}>
                  <QText>법인설립과 사업자등록은 어떻게 다른가요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>법인설립은 등기소에서, 사업자등록은 세무서에서 처리합니다.</Answer>
                    <p className={Qst.explain}>
                      법인설립을 마쳐야만, 세무서에 사업자등록을 신청할 수 있습니다. 각각 처리기관이 다르기 때문에, 별도로 처리를 해야 합니다.
                    </p>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>무료 사업자등록을 맡기면, 얼마나 시간이 걸리나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>무료 사업자등록을 맡기면, 법인설립 후 3~5일이 더 걸립니다.</Answer>
                    <p className={Qst.explain}>
                      대리인이 사업자등록을 신청하는 경우, 세무서에서는 곧바로 처리해주지 않고, 3~5일 정도 후에 업무를 완료해줍니다. 대표자 본인이 필요서류를 가지고 직접 세무서를 방문하면 당일처리도 가능합니다.
                    </p>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>무료 사업자등록을 맡기면, 기장계약을 체결해야 하나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>아니요. 기장계약을 체결할 필요가 전혀 없습니다.</Answer>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>무료 사업자등록을 이용하면 인허가도 대신 해주나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>안타깝게도 인허가는 포함되어 있지 않습니다. 인허가가 필요한 경우, 직접 사업자등록을 진행해주셔야 합니다.</Answer>
                  </div> 
                </div>
                <div className={Qst.qnaBox}>
                  <QText>직접 사업자등록을 하려면 무엇을 준비해야 하나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>고객님이 준비하실 서류는 아래와 같습니다.</Answer>
                    <dl className={Qst.defList}>
                      <dt className={Qst.term}>임대차계약서(전대차계약서, 전대동의서), 대표자 신분증(대리인 방문하는 경우 대표자 신분증 및 대리인 신분증), 인허가증(필요시)</dt>
                    </dl>
                    <Answer className={Qst.answer}>헬프미에서 준비해드릴 서류는 아래와 같습니다</Answer>
                    <dl className={Qst.defList}>
                      <dt className={Qst.term}>법인등기부등본, 주주명부, 정관, 법인인감증명서, 법인인감도장</dt>
                      <dd className={Qst.def}>
                        설립등기가 완료되면 [헬프미에서 준비해드리는 서류]를 빠른등기로 보내드립니다.
                      </dd>
                    </dl>
                  </div>
                </div>
              </Qna>
            </div>
          </ContentBox>
        </Question>
    </form>
  ));
}

export default FreeLicenseRegistration;
