import { useObserver } from 'mobx-react';
import {addCommas, percent} from '@common/module/replaceNumber';
import st from './FinalUpload.module.scss';
import SignleQuestionHeader from '@commonComponents/SingleQuestionHeader/SingleQuestionHeader';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import BuildingMove from '@commonComponents/BuildingMove/BuildingMove';
import FinalUploadVm from './FinalUploadVm';
import clsx from 'clsx';

function FinalSubmissionUpload() {
    return useObserver(() => (
    <>
        <SignleQuestionHeader>
            <h1 className="title">업로드가 완료되었습니다!<br/><span className="bold">법인정보 검토후 연락</span>드리겠습니다.</h1>
        </SignleQuestionHeader>
        <div className="mainContent" style={{top:'228px'}}>
            <Question style={{padding:'21px 45px'}}>
                <ContentBox>
                    <div className={st.banner}>
                        <div className={st.content}>
                            입금 후 <span className={st.bold}>영업일 기준 48시간 내에 법률검토가 완료</span>됩니다.<br/>
                            잠시 기다려 주시면, <span className={st.bold}>법인정보 검토가 완료되는 대로 고객님께 알림톡</span>을 드리겠습니다.<br/>
                            아래에서 입력한 법인정보 내용을 확인 할 수 있습니다.
                        </div>
                        <div className={st.building}><BuildingMove/></div>
                    </div>
                </ContentBox>
            </Question>
            <Question style={{padding:'45px',borderRadius:'4px'}}>
                <ContentBox>
                    <div>
                        <div className={st.table}>
                            <div className={st.title}>회사정보</div>
                            <div className={st.info}>
                                {(FinalUploadVm.smartFormType() === "주식회사" || FinalUploadVm.smartFormType() === "농업회사법인" || FinalUploadVm.smartFormType() === "어업회사법인") ?
                                <>
                                <div className={st.row}>
                                    <div className={st.dName}>참여 인원</div>
                                    <div className={st.data}>{FinalUploadVm.companySize()}</div>
                                </div>
                                {FinalUploadVm.initiatorRole() &&
                                <div className={st.row}>
                                    <div className={st.dName}>참여자 역할</div>
                                    <div className={st.data}>{FinalUploadVm.initiatorRole()}</div>
                                </div>
                                }
                                </>
                                : null}
                                <div className={st.row}>
                                    <div className={st.dName}>한글 회사명</div>
                                    <div className={st.data}>{FinalUploadVm.koreanCorpName}</div>
                                </div>
                                {FinalUploadVm.englishCorpName ?
                                <div className={st.row}>
                                    <div className={st.dName}>영문 회사명</div>
                                    <div className={st.data}>{FinalUploadVm.englishCorpName}</div>
                                </div>
                                : null}
                                <div className={st.row}>
                                    <div className={st.dName}>회사 주소</div>
                                    <div className={st.data}>{FinalUploadVm.corpAddress}</div>
                                </div>
                                <div className={st.row}>
                                    <div className={st.dName}>우편물 받을 주소</div>
                                    <div className={st.data}>{FinalUploadVm.deliveryAddress}</div>
                                </div>
                            </div>
                        </div>
                        <div className={st.table}>
                            <div className={st.title}>{FinalUploadVm.name()}정보</div>
                            <div className={st.info}>
                                {FinalUploadVm.smartFormType() !== '유한책임회사' &&
                                    <div className={st.row}>
                                        <div className={st.dName}>{FinalUploadVm.smartFormType() === '유한회사' ? '출자 1좌의 금액' : '1주당 금액'}</div>
                                        <div className={st.data}>{addCommas(FinalUploadVm.parValue)}원</div>
                                    </div>
                                }
                                <div className={st.row}>
                                    <div className={st.dName}>자본금</div>
                                    <div className={st.data}>{addCommas(FinalUploadVm.capital)}원</div>
                                </div>
                                <div className={st.row}>
                                    <div className={st.dName}>{FinalUploadVm.name()}정보</div>
                                    <div className={st.data}>
                                        {FinalUploadVm.shareholders.map((item, idx) => {
                                            return (
                                                <div className={st.shareholder} key={idx}>
                                                <div className={st.shareholderPosition}>{FinalUploadVm.name()}</div>
                                                <div className={st.shareholderInfo}>
                                                    <div className={st.person}>
                                                        <span>{item.name} {item.birth ? `(${item.birth})` : null}</span>
                                                        <span>{percent(FinalUploadVm.investmentPercent(idx))} 지분</span>
                                                        {FinalUploadVm.smartFormType() !== '유한책임회사' && <span>{addCommas(FinalUploadVm.stock(idx))}주</span>}
                                                        <span>투자금 {addCommas(item.investment)}원</span>
                                                        {FinalUploadVm.smartFormType() === '농업회사법인' && <div className={st.personType}>농업인</div>}
                                                        {FinalUploadVm.smartFormType() === '어업회사법인' && <div className={st.personType}>어업인</div>}
                                                    </div>
                                                    <div className={st.address}>{item.address}</div>
                                                </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={st.table}>
                            <div className={st.title}>임원정보</div>
                            <div className={st.info}>
                                <div className={st.row}>
                                    <div className={st.dName}>임원정보</div>
                                    <div className={st.data}>
                                        {FinalUploadVm.executives.map((item, idx) => {
                                            return (
                                                <div className={st.executive} key={idx}>
                                                <div className={st.executivePosition}>{item.position}</div>
                                                <div className={st.executiveInfo}>
                                                    <div className={st.person}>
                                                        <span>{item.name} ({item.birth}) {item.resignationYn ? <span className={st.resignation}>설립후 이어서 사임등기 신청</span> : null}</span>
                                                        {(FinalUploadVm.smartFormType() === '농업회사법인' && FinalUploadVm.isShareholder(idx)) && <div className={st.personType}>농업인</div>}
                                                        {(FinalUploadVm.smartFormType() === '어업회사법인' && FinalUploadVm.isShareholder(idx)) && <div className={st.personType}>어업인</div>}
                                                    </div>
                                                    <div className={st.address}>{item.address}</div>
                                                    {FinalUploadVm.positionInfoDisplay(idx) === "unLimitedLiabilityCompany" &&
                                                        <div className={st.positionInfo}><span className={st.star}>*</span> 이사가 1명이기 때문에, 등기부에는 대표권 있는 사내이사로 표기됩니다.</div>
                                                    }
                                                    {FinalUploadVm.positionInfoDisplay(idx) === "limitedLiabilityCompany" &&
                                                        <div className={st.positionInfo}><span className={st.star}>*</span> 유한책임회사의 경우, 등기부에는 업무집행자로 표기됩니다.</div>
                                                    }
                                                </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={st.table}>
                            <div className={st.title}>기타정보</div>
                            <div className={st.info}>
                                <div className={st.row}>
                                    <div className={st.dName}>사업목적</div>
                                    <div className={st.data}>
                                        <div className={clsx(st.data, st.businessPurpose)}>
                                            {FinalUploadVm.purposes.map((purposes, idx) => {
                                                return (
                                                    <span className={st.purpose} key={idx}>1. {purposes}</span>
                                                )
                                            })}
                                        </div>
                                    </div>             
                                </div>
                                {(FinalUploadVm.smartFormType() === "주식회사" || FinalUploadVm.smartFormType() === "농업회사법인" || FinalUploadVm.smartFormType() === "어업회사법인") ?
                                <>
                                <div className={st.row}>
                                    <div className={st.dName}>스톡옵션</div>
                                    <div className={st.data}>{FinalUploadVm.stockOptionYn()}</div>
                                </div>
                                <div className={st.row}>
                                    <div className={st.dName}>주식양도제한</div>
                                    <div className={st.data}>{FinalUploadVm.shareTransferRestrictionsYn()}</div>
                                </div>
                                <div className={st.row}>
                                    <div className={st.dName}>공고방법</div>
                                    <div className={st.data}>{FinalUploadVm.announcementMethod}</div>
                                </div>
                                <div className={st.row}>
                                    <div className={st.dName}>사회적기업</div>
                                    <div className={st.data}>{FinalUploadVm.prospectiveSocialEnterpriseYn()}</div>
                                </div>
                                </>
                                : null}
                                <div className={st.row}>
                                    <div className={st.dName}>법인인감</div>
                                    <div className={st.data}>{FinalUploadVm.corpSeals()}</div>
                                </div>
                                <div className={st.row}>
                                    <div className={st.dName}>사용인감</div>
                                    <div className={st.data}>{FinalUploadVm.usageSeal()}</div>
                                </div>
                                {FinalUploadVm.canUseYn() &&
                                    <div className={st.row}>
                                        <div className={st.dName}>공동인증서</div>
                                        <div className={st.data}>{FinalUploadVm.canUseYn()}</div>
                                    </div>
                                }
                                <div className={st.row}>
                                    <div className={st.dName}>무료사업자등록</div>
                                    <div className={st.data}>{FinalUploadVm.freeLicenseRegistrationYn()}</div>
                                </div>
                                {(FinalUploadVm.smartFormType() === '농업회사법인' || FinalUploadVm.smartFormType() === '어업회사법인') ?
                                <div className={st.row}>
                                    <div className={st.dName}>설립통지</div>
                                    <div className={st.data}>{FinalUploadVm.noticeOfEstablishmentYn()}</div>
                                </div>
                                : null}
                            </div>
                        </div>
                    </div>
                </ContentBox>
            </Question>
        </div>
    </>
    ));
}

export default FinalSubmissionUpload;
