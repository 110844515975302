import { observable } from 'mobx';

const StandbyFileUploadStepModel = observable({
    fileGroupTypes: [],
    deleteIds: [],
    regularShareholderMeetingYn: null,
    setStandbyFileUpload(data) {
        if(data.fileGroupTypes && data.fileGroupTypes.length !== 0) {
            this.fileGroupTypes = data.fileGroupTypes.map((file) => {
                return  {
                    fileGroupType: file.fileGroupType || "",
                    details: file.details.map((detail) => {
                        if(file.fileGroupType === "사업자등록증" || file.fileGroupType === "기존_정관" || file.fileGroupType === '주주명부') {
                            //기존_정관
                            const _aoaNewYn = detail.aoaNewYn;
                            return {
                                fileType: detail.fileType || "",
                                finalSubmittedDate: detail.finalSubmittedDate || null,
                                files: detail.files.length !== 0 ? detail.files.map((file) => {
                                    return {
                                        id: file.id,
                                        fileName: file.fileName,
                                        fileSize: file.fileSize,
                                        url: file.url,
                                        uploadFiles: null,
                                    }
                                }) : [{
                                    id: "",
                                    fileName: "",
                                    fileSize: "",
                                    url: "",
                                    uploadFiles: null
                                }],
                                ...(_aoaNewYn && {aoaNewYn: _aoaNewYn})
                            }
                        } else {
                            return {
                                fileType: detail.fileType || "",
                                finalSubmittedDate: detail.finalSubmittedDate || null,
                                files: detail.files || [],
                                uploadFiles: [],
                            }
                        }
                    }) || []
                }   
            })
        } else {
            this.fileGroupTypes = [];
        }
        this.deleteIds = [];
        this.regularShareholderMeetingYn = data?.regularShareholderMeetingYn ?? null;
    }
});

export default StandbyFileUploadStepModel;