import { useObserver } from 'mobx-react';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';

function RepresentativeAddressCheck(props) {
    return useObserver(() => (
        <div className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
                <div className={Qst.content}>   
                    <div className={Qst.titleWrap}>
                        <h2 className={Qst.title}>
                            <span className={Qst.bold}>{props.item.koreanName}</span>님의 <span className={Qst.bold}>현재 주민등록상 주소</span>가 <span className={Qst.bold}>등기부상 주소</span>와 같나요?
                        </h2>
                    </div>
                    <div className={Qst.addressCheck}>
                        <div className={Qst.address}>{props.item.koreanName}님의 등기부상 주소</div>
                        <div className={Qst.originAddress}>{props.item.originAddress}</div>
                    </div>
                    <ul className={Qst.answerList}>
                        <li><Radio className={Qst.answerLabel} value={true} activeBackground checked={props.item.hasSameAddressYn === true} onChange={(e) => props.parentVm.setHasSameAddressYn(e, props.idx)} name={`${props.name}[${props.idx}].hasSameAddressYn`}>네. 같습니다.</Radio></li>
                        <li><Radio className={Qst.answerLabel} value={false} activeBackground checked={props.item.hasSameAddressYn === false} onChange={(e) => props.parentVm.setHasSameAddressYn(e, props.idx)} name={`${props.name}[${props.idx}].hasSameAddressYn`}>아니오. 다릅니다.</Radio></li>
                    </ul>
                    <Error name={`${props.name}[${props.idx}].hasSameAddressYn`} value={props.item.hasSameAddressYn} errorCase={{required:'현재 주민등록상 주소가 등기부상 주소와 같은지 선택해 주세요.'}}/>
                </div>
            </div>
        </div>
    ));
}

export default RepresentativeAddressCheck;