import { useObserver } from 'mobx-react';
import st from '../../AdminFinalCheckView.module.scss';
import clsx from 'clsx';
import { addCommas } from '@common/module/replaceNumber';
import { Fragment } from 'react';
import copyText from '@common/module/copyText';
import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';


function ConvertibleBondStep(props) {
    return useObserver(() => (
        <>
            <table className={st.lightTable}>
                <colgroup>
                    <col width="200px"/>
                    <col width="550px"/>
                </colgroup>
                <thead>
                    <tr>
                        <th style={{textAlign:"left"}}></th>
                        <th style={{textAlign:"center"}}>인적사항</th>
                        <th style={{textAlign:"right"}}>인수금액</th>
                    </tr>
                </thead>
                <tbody>
                    {props.step.convertibleBondAcquirers.length === 0 &&
                        <tr>
                            <td style={{textAlign:"left"}}>-</td>
                            <td style={{textAlign:"center"}}>-</td>
                            <td style={{textAlign:"right"}}>-</td>
                        </tr>
                    }
                    {props.step.convertibleBondAcquirers.map((item, index) => (
                        <Fragment key={index}>
                            <tr>
                                <td className={clsx(st.bottom, st.top)} style={{paddingLeft:'0'}}>
                                    <div className={st.name}>
                                        <CopyBtn onClick={() => copyText(item.koreanName)} className={st.addressCopyBtn} style={{textAlign:'left'}}>{item.koreanName}</CopyBtn>
                                        {item.englishName && 
                                            <div style={{marginBottom:'2px', fontSize:"13px"}}>
                                                <CopyBtn onClick={() => copyText(item.englishName)} className={st.addressCopyBtn} style={{textAlign:'left'}}>{item.englishName}</CopyBtn>
                                            </div>
                                        }
                                    </div>
                                    <span>{item.existingShareholdersYn ? '기존주주' : '제3자'}</span>
                                </td>
                                <td className={clsx(st.bottom, st.top)} style={{textAlign:"center"}}>
                                    {(item.acquirerType === "FOREIGNER_COMPANY" || item.acquirerType === "KOREAN_COMPANY") && 
                                        <div style={{marginBottom:"5px"}}>
                                            {item.representatives.map((representative, index2) => (
                                                <div key={index2}>
                                                    {representative.position} <CopyBtn onClick={() => copyText(representative.koreanName)} className={st.addressCopyBtn}>{representative.koreanName}</CopyBtn>
                                                    {representative.englishName && 
                                                        <span>(<CopyBtn onClick={() => copyText(representative.englishName)} className={st.addressCopyBtn}>{representative.englishName}</CopyBtn>)</span>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {item.acquirerType === "FUND_AND_INVESTMENT_ASSOCIATION" && 
                                        <div style={{marginBottom:"5px"}}>
                                            {item.representatives.map((representative, index2) => (
                                                <div key={index2}>
                                                    대표 <CopyBtn onClick={() => copyText(representative.koreanName)} className={st.addressCopyBtn}>{representative.koreanName}</CopyBtn>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {item.englishName &&
                                        <div style={{marginBottom:"5px"}}>{item.nationality}</div>
                                    }
                                    <div>
                                        {item?.koreanAddress !== null &&
                                            <span><CopyBtn onClick={() => copyText(item.koreanAddress)} className={st.addressCopyBtn}>{item.koreanAddress}</CopyBtn></span>
                                        }
                                        {item.englishAddress && 
                                            <span>(<CopyBtn onClick={() => copyText(item.englishAddress)} className={st.addressCopyBtn}>{item.englishAddress}</CopyBtn>)</span>
                                        }
                                    </div>
                                </td>
                                <td className={clsx(st.bottom, st.top)} style={{textAlign:'right'}}>
                                    <CopyBtn onClick={() => copyText(item.acquisitionAmount)} style={{textAlign:'right'}}>{addCommas(item.acquisitionAmount)}</CopyBtn>원
                                </td>
                            </tr>
                        </Fragment>
                    ))}
                </tbody>
            </table>
            <div className={st.row}> 
                <div className={st.dName}>사채대금 입금<br/>방법</div>
                <div className={st.data}>
                    {props?.step?.depositProofType || "-"}
                </div>
            </div>
        </>
    ));
}
export default ConvertibleBondStep;