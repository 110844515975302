import { observable } from 'mobx';

const agriculturalFisheryDocumentUploadModel = observable({
    //농업 어업 서류 업로드
    agriculturalFisheryDocumentsFiles: [],
    agriculturalFisheryDocumentsUploadFiles: [], //파일 업로드
    agriculturalFisheryDocumentsDeleteFiles: [], //파일 삭제
    isFarmerFile: null,
    setAgriculturalFisheryDocuments(agriculturalFisheryDocuments) {
        if(agriculturalFisheryDocuments?.agriculturalFisheryDocumentsFiles && agriculturalFisheryDocuments?.agriculturalFisheryDocumentsFiles.length !== 0) {
            this.agriculturalFisheryDocumentsFiles = agriculturalFisheryDocuments.agriculturalFisheryDocumentsFiles.map((file) => {
                return {
                    fileName: file.fileName || "",
                    fileSize: file.fileSize ?? null,
                    id: file.id || null,
                    url: file.url || ""
                }
            })
        } else {
            this.agriculturalFisheryDocumentsFiles = [];
        }
        this.isFarmerFile = agriculturalFisheryDocuments?.isFarmerFile ?? null;
    }
});

export default agriculturalFisheryDocumentUploadModel;