import { useObserver } from 'mobx-react';
import CompanySize from './CompanySize/CompanySize';
import CompanyName from './CompanyName/CompanyName';
import CompanyAddress from './CompanyAddress/CompanyAddress';
import { Route } from "react-router-dom";

function CompanyInfo() {
  return useObserver(() => (
    <>
        {/* 회사정보 */}
        <Route path="/step/establish/CompanyInfo/CompanySize" component={CompanySize}></Route>
        {/* 주소 */}
        <Route path="/step/establish/CompanyInfo/CompanyAddress" component={CompanyAddress}></Route>
        {/* 이름 */}
        <Route path="/step/establish/CompanyInfo/CompanyName" component={CompanyName}></Route>
    </>
  ));
}

export default CompanyInfo;