import React,{ useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import gray from '../../../asset/icon/btn_drop_down.svg';
import deepGray from '../../../asset/icon/btn_drop_down_black.svg';
import './Select.css';
import clsx from 'clsx';

const SelectWrap = styled.div`
    position: relative;
    display: inline-block;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
        vertical-align: top;
    }
`;

const Select = styled.select`
    line-height:29px;
    cursor: pointer;
    display: block;
    appearance: none;
    &::-ms-expand {
        display: none;
    }
    width: 277px;
    padding: 13px 18px 14px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
        padding: 12px 18px 13px;
    }
    ${(props) => props.defaultValue === null ? ' color: #999fac;' : 'color: #000;'};
    ${(props) => props.value === null ? ' color: #999fac;' : 'color: #000;'};
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -0.5px;
    line-height: 29px;
    border: 1px solid #c6cede;
    border-radius: 2px;
    background-color: #fff;
    background-image: url(${gray});
    background-size: 19px;
    background-repeat: no-repeat;
    background-position: right 18px top 20px;

    option {
        color: #000;
    }    
    
    ${(props) => props.error !== "error" && 
    `
    &:focus {
        outline: 1px solid #006ac3;
        border : 1px solid #006ac3;
        border-radius: 2px;
    }
    `
    }
    
    ${(props) => (props.defaultValue !== null || props.value !== null) && 
    `
    background-image: url(${deepGray});
    `}

    ${(props) => (props.defaultValue === "" || props.value === "") && 
    `color:#999fac;
    background-image: url(${gray});
    `}

    &:disabled {
        cursor: not-allowed;
        opacity: 0.3;
    }
`;

const Explain = styled.div`  
    ${(props) => props.explain !== null &&  
    `
    display: inline-block;
    position: relative;
    margin-bottom: 5px;
    height: 18px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.64px;
    color: #777c86;
    
    &:after {
        content: '*';
        display: inline-block;
        position: absolute;
        right: -10px;
        top: 0;
        color: #fc526e;
    }
    `
    }
`;

const ErrorText = styled.div`
    position: absolute;
    bottom: 0;
    transform: ${(props) => props.errorFixed ? 'translateY(100%)' : 'translateY(calc(100% - 27px))'};;
`;

/**
* 필수값으로 select name 필요
*/

const Input = React.forwardRef((props, ref) => {

    const [error, setError] = useState(null);
    
    useEffect(() => {
        if(document.querySelector(`[name="${props.name}"]`).hasAttribute("data-error")) {
            setError(document.querySelector(`[name="${props.name}"]`).getAttribute("data-error"));
        }
    });
    
    return (
        <SelectWrap className={clsx("selectWrap", props.parentClassName)} error={error}>
            <Explain explain={props.explain}>{props.explain}</Explain>
            <Select name={props.name} className={clsx("select", props.className, props.errorFixed ? "errorFixed" : "")} style={props.style}
            required={props.required} disabled={props.disabled} value={props.value}
            onChange={(e) => props.onChange(e)} error={error} ref={ref}
            >
                {props.children}
            </Select>

            {props.errorText && 
                <ErrorText errorFixed={props.errorFixed}>{props.errorText}</ErrorText>
            } 
        </SelectWrap>
    )

});

Input.propTypes = {
    /**
    * 셀렉트 이름 
    */
    name: PropTypes.string,
    /**
     * class명
     */
    className: PropTypes.string,
    /**
     * select를 감싸고있는 class명
     */
    parentClassName: PropTypes.string,
    /**
    * style 속성
    */
    style: PropTypes.object,
    /**
    * 필수값 
    */
    required: PropTypes.bool,
    /**
    *  셀렉트 비활성화
    */
    disabled: PropTypes.bool,
    /**
    * 셀렉트 defaultValue값 
    */
    defaultValue: PropTypes.any,
    /**
    * 셀렉트 value값 
    */
    value: PropTypes.any,
    /**
    * change 이벤트
    */
    onChange: PropTypes.func,
    /**
    * 셀렉트 설명
    */
    explain: PropTypes.string,
    /**
    * option 태그 값
    */
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    /**
    *  &lt;ErrorText&gt; 사용 <br/>
    *  강조: className="bold"
    */
    errorText: PropTypes.object,
    errorFixed: PropTypes.bool
};

Input.defaultProps = {
    name: null,
    className: null,
    parentClassName: null,
    style: {},
    required: false,
    disabled: false,
    defaultValue: null,
    value: null,
    onChange : () => {},
    explain: null, 
    children : <option>Empty</option>,
    errorText: null,
    errorFixed: false
};

export default Input;

