import { observable, runInAction } from 'mobx';

const adminFinalCheckViewModel = observable({
    steps: [],
    companyType: "",
    standbyYn: null,
    agendaTitles: [],
    setAdminFinalCheckView(data) {
        runInAction(() => {
            this.companyType = data?.companyType || "";
            this.standbyYn = data?.standbyYn ?? null;
            this.agendaTitles = data?.agendaTitles || [];
            if(data.steps) {
                this.steps = data.steps.map((step) => {
                    if(step.code === "CorpNameStep") { //상호
                        step.changeEnglishCorpNameYn = step.changeEnglishCorpNameYn ?? null;
                        step.changeKoreanCorpNameYn = step.changeKoreanCorpNameYn ?? null;
                        step.newEnglishName = step.newEnglishName || "";
                        step.newKoreanName = step.newKoreanName || "";
                        step.originEnglishName = step.originEnglishName || null;
                        step.originKoreanName = step.originKoreanName || null;
                    }
                    if(step.code === "HeadOfficeRelocationStep") { //본점이전
                        step.address = step.address || null;
                        step.relocationDate = step.relocationDate || "";
                    }
                    if(step.code === "PaidInCapitalIncreaseStep") { //신주발행
                        step.issuingNewShareProcess = step.issuingNewShareProcess || null;
                        step.noticeType = step.noticeType || null;
                        step.noticeDate = step.noticeDate || null;
                        step.newShareAssignmentMethod = step.newShareAssignmentMethod || null;
                        step.shareholders = step.shareholders || [{
                            birth: "",
                            englishAddress: "",
                            englishName: "",
                            existingShareholdersYn: null,
                            koreanAddress: "",
                            koreanName: "",
                            nationality: "",
                            representatives: [{
                                englishName: "",
                                koreanName: "",
                                position: ""
                            }],
                            shareholderType: "",
                            가수금증자Stocks: [{
                                investment: null,
                                name: "",
                                number: null
                            }],
                            유상증자Stocks: [{
                                investment: null,
                                name: "",
                                number: null
                            }]
                        }];
                    }
                    if(step.code === "NumOfSharesToBeIssuedStep") { //발행예정주식수
                        step.numOfShersToBeIssued = step.numOfShersToBeIssued || "";
                    }
                    if(step.code === "ExecutiveMemberChangeStep") { //임원변경
                        step.keepProceedYn = step.keepProceedYn ?? null;
                        step.standbyFormYn = step.standbyFormYn ?? false;
                        step.executiveMemberCount = step.executiveMemberCount || [{
                            position: "",
                            count: null
                        }]
                        step.executiveMembers = step.executiveMembers || [{
                            addressCauseDate: "",
                            addressSameYn: null,
                            birth: "",
                            changeType: "",
                            changedInPage4: null,
                            englishName: "",
                            koreanName: "",
                            nationality: "",
                            newAddresses: [{
                                addressCauseDate: "",
                                newAddress: {
                                    bname: "",
                                    bname1: "",
                                    buildingNo: "",
                                    detailAddress: "",
                                    englishAddress: "",
                                    fullAddress: "",
                                    nameOfBuilding: "",
                                    roadname: "",
                                    sido: "",
                                    sigungu: ""
                                }
                            }],
                            newPosition: "",
                            newYn: null,
                            originCauseDate: "",
                            originPosition: ""
                        }]
                    }
                    if(step.code === "BusinessPurposeStep") { //목적
                        step.newBusinessPurposes = step.newBusinessPurposes || [{
                            content: "",
                            id: ""
                        }];
                        step.originalBusinessPurposes = step.originalBusinessPurposes || [{
                            content: "",
                            deleteYn: null,
                            id: ""
                        }];
                    }
                    if(step.code === "AddStockOptionStep") { //스톡옵션
                        step.helpmeRegulationYn = step.helpmeRegulationYn ?? null;
                        step.regulation = step.regulation || "";
                        step.ventureYn = step.ventureYn ?? null;
                    }
                    if(step.code === "AnnounceMethodStep") { //공고방법
                        step.hasHomepageYn = step.hasHomepageYn ?? null;
                        step.homepageUrl = step.homepageUrl || "";
                        step.newspaperCompany = step.newspaperCompany || "";
                    }
                    if(step.code === "BranchOfficeDeleteStep") { //지점폐지
                        step.branches = step.branches || [{
                            address: ""
                        }];
                    }
                    if(step.code === "BranchOfficeRelocateStep") { //지점이전
                        step.branches = step.branches || [{
                            causeDate: "",
                            newBranchAddress: "",
                            newBranchName: "",
                            originBranchAddress: ""
                        }];
                    }
                    if(step.code === "BranchOfficeChangeStep") { //지점변경
                        step.branches = step.branches || [{
                            branchAddress: "",
                            causeDate: "",
                            manager: {
                                address: "",
                                causeDate: "",
                                koreanYn: null,
                                kssnOrBirth: "",
                                name: "",
                                nationality: "",
                                originKssnOrBirth: "",
                                originName: ""
                            },
                            managerChangeType: "",
                            newBranchName: ""
                        }];
                    }
                    if(step.code === "BranchOfficeInstallStep") { //신규지점 설치
                        step.branches = step.branches || [{
                            address: "",
                            hasManagerYn: null,
                            installDate: "",
                            manager: {
                                address: "",
                                causeDate: "",
                                koreanYn: null,
                                kssnOrBirth: "",
                                name: "",
                                nationality: ""
                            },
                            name: ""
                        }];
                    }
                    if(step.code === "BoardMeetingSealProvidingStep") { //이사회 인감제공
                        step.directors = step.directors || [];
                    }
                    if(step.code === "StockholdersMeetingSealProvidingStep") { //주주총회 인감제공
                        step.containsAuditorInaugurationYn = step.containsAuditorInaugurationYn ?? null;
                        step.stockholderMeetingType = step.stockholderMeetingType || "";
                        step.stockholders = step.stockholders || [];
                    }
                    if(step.code === "RepresentativeAddressChangeStep") { //대표자 주소, 주민번호 확인
                        step.has등기원인_대표자주소 = step.has등기원인_대표자주소 ?? null;
                        step.representatives = step.representatives || [{
                            addressSameYn: null,
                            birth: "",
                            causeDate: "",
                            englishName: "",
                            foreignerYn: null,
                            koreanName: "",
                            newAddresses: [{
                                causeDate: "",
                                newAddress: {
                                    bname: "",
                                    bname1: "",
                                    buildingNo: "",
                                    detailAddress: "",
                                    englishAddress: "",
                                    fullAddress: "",
                                    nameOfBuilding: "",
                                    roadname: "",
                                    sido: "",
                                    sigungu: ""
                                }
                            }],
                            originPosition: "",
                            originCauseDate: ""
                        }];
                    }
                    if(step.code === "TCAgreeAndDeliveryAddressStep") { //우편물 받을 주소
                        step.address = step.address || "";
                    }
                    if(step.code === "FreeIssueOfNewSharesStep") { //무상증자
                        step.amount = step.amount || null;
                        step.number = step.number || null;
                        step.recordDateForNewSharesAllotment = step.recordDateForNewSharesAllotment || "";
                        step.resourceType = step.resourceType || ""
                    }
                    if(step.code === "FileUploadStep") { //파일업로드
                        step.filesByFileType = step.filesByFileType || [{
                            fileType: "",
                            files: [{
                                fileName: "",
                                url: ""
                            }]
                        }];
                    }
                    if(step.code === "CapitalIncreaseStep") { //자본금의 총액 변경(=주식회사의 신주)
                        step.shareholders = step.shareholders || [{
                            birth: "",
                            englishAddress: "",
                            englishName: "",
                            existingShareholdersYn: null,
                            koreanAddress: "",
                            koreanName: "",
                            nationality: "",
                            representatives: [{
                                englishName: "",
                                koreanName: "",
                                position: ""
                            }],
                            shareholderType: "",
                            가수금증자Stocks: null,
                            유상증자Stocks: null
                        }];
                    }
                    if(step.code === "ParValueDivisionStep") { //액면금 변경
                        step.newParValue = step.newParValue || "";
                    }
                    if(step.code === "LiquidationStep") { //법인해산, 청산
                        step.directors = step.directors || [{
                            addressChangeDate: "",
                            addressSameYn: null,
                            birth: "",
                            englishName: "",
                            foreignerYn: null,
                            koreanName: "",
                            nationality: "",
                            newAddress: {
                                bname: "",
                                bname1: "",
                                buildingNo: "",
                                detailAddress: "",
                                englishAddress: "",
                                fullAddress: "",
                                nameOfBuilding: "",
                                roadname: "",
                                sido: "",
                                sigungu: ""
                            },
                            originAddress: "",
                            position: "",
                            selectedYn: null
                        }];
                        step.liquidationDate = step.liquidationDate || "";
                        step.liquidationStepType = step.liquidationStepType || ""
                    }
                    if(step.code === "StockCompanySealStep") { //주주총회 정보입력
                        step.stockholderMeetingType = step.stockholderMeetingType || "";
                        step.stockholderMeetingMusterNoticeType = step.stockholderMeetingMusterNoticeType || "";
                        step.musterNoticeMethod = step.musterNoticeMethod || "";
                        step.musterNoticeSendMethod = step.musterNoticeSendMethod || "";
                        step.stockholderMeetingDate = step.stockholderMeetingDate || "";
                        step.stockholderMeetingAtHeadOfficeYn = step.stockholderMeetingAtHeadOfficeYn || "";
                        step.stockholderMeetingPlaceAddress = step.stockholderMeetingPlaceAddress || "";
                        step.musterNoticeFiles = step.musterNoticeFiles || [{
                            url: "",
                            fileName: ""
                        }];
                        step.musterNoticeDate = step.musterNoticeDate || "";
                        step.stockholders = step.stockholders || [{
                            name: "",
                            sealProviderType: "",
                            sealProvidingYn: null
                        }];
                        step.files = step.files || null;
                    }
                    if(step.code === "ConvertibleBondStep") { //전환사채
                        step.depositProofType = step.depositProofType || "";
                        step.convertibleBondAcquirers = step.convertibleBondAcquirers || [{
                            koreanName: "",
                            englishName: "",
                            acquirerType: "",
                            existingShareholdersYn: null,
                            nationality: "",
                            koreanAddress: "",
                            englishAddress: "",
                            representatives: "",
                            acquisitionAmount: null
                        }]
                    }
                    if(step.code === "StandbyPaidInCapitalIncreaseStep") { //스탠바이_신주발행
                        step.issuingNewShareProcess = step.issuingNewShareProcess || null;
                        step.newShareAssignmentMethod = step.newShareAssignmentMethod || null;
                        step.noticeType = step.noticeType || "";
                        step.noticeDate = step.noticeDate || null;
                        step.shareholders = step.shareholders || [{
                            birth: "",
                            englishAddress: "",
                            englishName: "",
                            existingShareholdersYn: null,
                            koreanAddress: "",
                            koreanName: "",
                            nationality: "",
                            representatives: [{
                                englishName: "",
                                koreanName: "",
                                position: ""
                            }],
                            shareholderType: "",
                            가수금증자Stocks: [{
                                investment: null,
                                name: "",
                                number: null
                            }],
                            유상증자Stocks: [{
                                investment: null,
                                name: "",
                                number: null
                            }],
                            subscriptionDate: "",
                            paymentDate: ""
                        }];
                    }
                    if(step.code === "StandbyFreeIssueOfNewSharesStep") { //스탠바이_무상증자
                        step.freeIssueOfNewSharesType = step.freeIssueOfNewSharesType || "";
                        step.amount = step.amount || "";
                        step.parValue = step.parValue || "";
                        step.keepGoingYn = step.keepGoingYn ?? null;
                        step.freeIssueOfNewSharesAllotmentInfos = step.freeIssueOfNewSharesAllotmentInfos || [{
                            name: "",
                            newStockAmount: null,
                            shareRatio: ""
                        }];
                        step.recordDateForNewSharesAllotment = step.recordDateForNewSharesAllotment || "";
                    }

                    if(step.code === "StandbyParValueDivisionStep") { //스탠바이_액면금변경
                        step.keepGoingYn = step.keepGoingYn ?? null;
                        step.newParValue = step.newParValue || null;
                    }
                    if(step.code === "StandByStockholderMeetingInfoStep") { //스탠바이_주주총회 정보입력
                        step.chairman = step.chairman || "";
                        step.musterNoticeMethod = step.musterNoticeMethod || "";
                        step.stockholderMeetingDate = step.stockholderMeetingDate || "";
                        step.stockholders = step.stockholders || [{
                            name: "",
                            stockholderType: "",
                            attendYn: null,
                            agreeYn: null,
                            sealProvidingYn: null
                        }];
                    }
                    if(step.code === "StandByBoardMeetingInfoStep") { //스탠바이_이사회 정보입력
                        step.chairman = step.chairman || "";
                        step.boardMeetingDate = step.boardMeetingDate || "";
                        step.directors = step.directors || [{
                            name: "",
                            directorType: "",
                            attendYn: null,
                            agreeYn: null,
                            sealProvidingYn: null
                        }]
                    }
                    return step;
                })
            } else {
                this.steps = [];
            }
        })
    }
});


export default adminFinalCheckViewModel;
