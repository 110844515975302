import { toJS } from 'mobx';
import { Fragment } from 'react';
import { useObserver } from 'mobx-react';
import st from '../FileUploadStep.module.scss';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import Upload from '@comComponents/molecules/FileUpload/FileUpload';
import clsx from 'clsx';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';

function FinancialPosition(props) {
    return useObserver(() => (
        <Question>
            <ContentBox>
                <div className={Qst.spaceBetween}>
                    <div className={Qst.infoSection}>
                        {props.item.details.map((detail, idx2) => (
                            <Fragment key={idx2}>
                                {detail.fileType === '재무상태표' &&
                                    <>
                                        <div className={Qst.titleWrap}>
                                            <h2 className={Qst.title}>
                                                최종의 <span className={Qst.bold}>재무상태표</span>를 업로드해 주세요.
                                            </h2>
                                        </div>
                                        <Badge className={st.financialStatementBadge} title="예시" type="example" border={false}>
                                            <img className={st.financialStatementBadgeImg} src="/images/financial_statement.png"
                                            alt="재무상태표 예시" />
                                        </Badge>
                                    </>
                                }
                                {detail.fileType === '재산목록' &&
                                    <>
                                        <div className={Qst.titleWrap} style={{marginTop:'100px'}}>
                                            <h2 className={Qst.title}>
                                                최종의 <span className={Qst.bold}>재산목록</span>을 업로드해 주세요.
                                            </h2>
                                        </div>
                                        <LikeBtn className={Qst.likeBtn} style={{marginTop: '-12px', marginBottom: '24px'}} href="https://sc.help-me.kr/smartformV3/%E1%84%8C%E1%85%A2%E1%84%89%E1%85%A1%E1%86%AB%E1%84%86%E1%85%A9%E1%86%A8%E1%84%85%E1%85%A9%E1%86%A8_%E1%84%89%E1%85%A2%E1%86%B7%E1%84%91%E1%85%B3%E1%86%AF.docx" target="_blank">재산목록 샘플 다운로드 받기</LikeBtn>
                                        <Badge className={st.financialListBadge} title="예시" type="example" border={false}>
                                            <img className={st.financialListBadgeImg} src="/images/financial_list.png"
                                            alt="재산목록 예시" />
                                        </Badge>
                                    </>
                                }
                                <Upload className={clsx(st.fileUpload, `${detail.fileType}`)} multiple accept=".pdf, .jpg, .png"
                                model={props.parentVm.fileGroupTypes[props.idx].details[idx2].files}
                                uploadModel={props.parentVm.fileGroupTypes[props.idx].details[idx2].uploadFiles}
                                deleteModel={props.parentVm.deleteIds}
                                files={toJS(props.parentVm.files(props.idx, idx2))} uploadFiles={toJS(props.parentVm.uploadFiles(props.idx, idx2))}       
                                explainText={<div>재무제표상 자산이 <span className="bold">마이너스</span>이면 해산, 청산등기는 <span className="bold">불가능</span>합니다.<br/>(파산절차 진행해야함)</div>}
                                >
                                    <span className={st.bold}>여기</span>를 눌러 업로드해 주세요.
                                </Upload>
                                {(props.parentVm.state.errorCheckYn && props.parentVm.displayErrorText(detail.fileType)) && <ErrorText>재무상태표를 업로드해 주세요.</ErrorText>}
                            </Fragment> 
                        ))}
                    </div>
                    <div className={Qst.qnaSection}>
                        <Qna className={Qst.noTitle}>
                            <div className={Qst.qnaBox}>
                                <QText>재무제표상 자산이 마이너스면 어떻게 처리해야 하나요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>재무제표상 자산이 마이너스면, 법인파산 절차를 진행하셔야 합니다.</Answer>
                                    <div className={Qst.explain}>
                                        법인파산 절차는 비용이 다소 고가이며(착수금 500만 원 이상), 아쉽게도 헬프미에서는 법인파산 서비스는 제공하고 있지 않습니다.
                                    </div>
                                </div>
                            </div>
                        </Qna>
                    </div>
                </div>
            </ContentBox>
        </Question>
    ));
}

export default FinancialPosition;