import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { Fragment, useEffect } from 'react';
import Ast from '../../AdminFinalCheckView.module.scss';
import st from './LiquidationStep.module.scss';
import clsx from 'clsx';
import copyText from '@common/module/copyText';
import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';
import Icon from '@comComponents/atoms/Icon/Icon';

function LiquidationStep(props) {
    return useObserver(() => (
        <> 
            <div className={Ast.row}> 
                <div className={Ast.dName}>청산인</div>
                <div className={Ast.data}>
                    {props.step.directors.length === 0 && '-'}
                    {(props.step.liquidationStepType === 'CASE2' && props.step.directors[0].selectedYn === null) && '-'}
                    {props.step.directors.map((item, idx) => {
                        return (
                            <Fragment key={idx}>
                                {item.selectedYn &&
                                    <div className={st.liquidationInfo}>
                                        <div className={st.liquidationNamePosition}>
                                            <CopyBtn onClick={() => copyText(item.koreanName)}>{item.koreanName}</CopyBtn>
                                            <CopyBtn onClick={() => copyText(item.position)} style={{marginLeft:'5px'}}>{item.position}</CopyBtn>
                                            {item.englishName && 
                                                <div>(<CopyBtn onClick={() => copyText(item.englishName)}>{item.englishName}</CopyBtn>)</div>
                                            }
                                        </div>
                                        <div className={st.liquidationDetail}>
                                            <div>{item.foreignerYn ? '생년월일' : '주민등록번호'} : {item.birth ? <CopyBtn onClick={() => copyText(item.birth)}>{item.birth}</CopyBtn> : '-'}</div>
                                            {item.foreignerYn && <div>국적 : <CopyBtn onClick={() => copyText(item.nationality)}>{item.nationality}</CopyBtn></div>}
                                            <div>
                                                주소 :&nbsp;
                                                {item.newAddress !== null ?
                                                    <>  
                                                        <CopyBtn onClick={() => copyText(item?.newAddress?.sido)} className={Ast.addressCopyBtn}>{item?.newAddress?.sido}</CopyBtn>
                                                        <CopyBtn onClick={() => copyText(item?.newAddress?.sigungu)} className={Ast.addressCopyBtn}>{item?.newAddress?.sigungu}</CopyBtn>
                                                        {item?.address?.bname1 && <CopyBtn onClick={() => copyText(item?.address?.bname1)} className={Ast.addressCopyBtn}>{item?.newAddress?.bname1}</CopyBtn>}
                                                        <CopyBtn onClick={() => copyText(item?.newAddress?.roadname)} className={Ast.addressCopyBtn}>{item?.newAddress?.roadname}</CopyBtn>
                                                        <CopyBtn onClick={() => copyText(item?.newAddress?.buildingNo)} className={Ast.addressCopyBtn}>{item?.newAddress?.buildingNo}</CopyBtn>,
                                                        <CopyBtn onClick={() => copyText(item?.newAddress?.detailAddress)} className={Ast.addressCopyBtn}>{item?.newAddress?.detailAddress}</CopyBtn>
                                                        <div className={Ast.addressCopyBracket}>
                                                            (<CopyBtn onClick={() => copyText(item?.newAddress?.bname)} className={Ast.addressCopyBtn}>{item?.newAddress?.bname}</CopyBtn>,
                                                            <CopyBtn onClick={() => copyText(item?.newAddress?.nameOfBuilding)} className={Ast.addressCopyBtn}>{item?.newAddress?.nameOfBuilding}</CopyBtn>)
                                                        </div>
                                                        <CopyBtn onClick={() => copyText(item?.newAddress?.fullAddress)} className={Ast.addressCopyAllBtn}><Icon icon="copy" className={Ast.copyIcon}/></CopyBtn>
                                                    </>
                                                    : 
                                                    <>
                                                        {item.originAddress}
                                                        <CopyBtn onClick={() => copyText(item.originAddress)} className={Ast.addressCopyAllBtn}><Icon icon="copy" className={Ast.copyIcon}/></CopyBtn>
                                                    </>
                                                }
                                            </div>
                                            {item.addressSameYn === false && <div>전입신고일자 : <CopyBtn onClick={() => copyText(item.addressChangeDate)}>{item.addressChangeDate}</CopyBtn></div>}
                                        </div>
                                    </div>
                                }  
                            </Fragment>
                        )
                    })}
                </div> 
            </div>
            {props.step.liquidationStepType === 'CASE2' &&
                <div className={Ast.row}>  
                    <div className={Ast.dName}>등기부상 이사</div>
                    <div className={Ast.data}>
                        {(props.step.liquidationStepType === 'CASE2' && props.step.directors[0].selectedYn === null) && '-'}
                        {props.step.directors.map((item, idx) => {
                            return (
                                <Fragment key={idx}>
                                    {item.selectedYn === false &&
                                        <div className={st.executiveInfo}>
                                            <div className={st.executiveNamePosition}>
                                                <CopyBtn onClick={() => copyText(item.koreanName)}>{item.koreanName}</CopyBtn>
                                                <CopyBtn onClick={() => copyText(item.position)} style={{marginLeft:'5px'}}>{item.position}</CopyBtn>
                                                {item.englishName && 
                                                    <div>(<CopyBtn onClick={() => copyText(item.englishName)} style={{marginLeft:'5px'}}>{item.englishName}</CopyBtn>)</div>
                                                }
                                            </div>
                                            <div className={st.executiveDetail}>
                                                {item.foreignerYn === false && <div>주민등록번호 : <CopyBtn onClick={() => copyText(item.birth)} style={{marginLeft:'5px'}}>{item.birth}</CopyBtn></div>}
                                            </div>
                                        </div>
                                    }  
                                </Fragment>
                            )
                        })}
                    </div> 
                </div>
            }
            <div className={Ast.row}> 
                <div className={Ast.dName}>해산일자</div>
                <div className={Ast.data}>
                    {props?.step?.liquidationDate ?
                        <CopyBtn onClick={() => copyText(props.step.liquidationDate)}>{props.step.liquidationDate}</CopyBtn>
                    :
                        '-'
                    }
                </div> 
            </div>
        </>
    ));
}
export default LiquidationStep;