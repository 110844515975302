import { observable, runInAction, toJS } from "mobx";
import corpNameStepModel from '@model/changeRegistration/corpNameStepModel';
import smartformAppModel from '@model/smartformAppModel';
import moduleService from "@service/moduleService";
import { _alert } from "@model/dialogModel";
import stepService from "@service/stepService";
import formDataToJson from '@common/module/submit';
import regExp from '@common/module/regExp';

class viewModel {
    constructor() {
        this.state = observable({
            companyNameCheckDisabled : false
        });
    }
    submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error === false) {
            let param = formDataToJson(e.target).data;
            if(this.changeKoreanCorpNameYn) {
                if(this.corpNameDuplicationCheckType === "CANNOT_USE") {
                    return _alert('회사이름이 중복입니다.');
                }
                if(!this.corpNameDuplicationCheckType) {
                    return _alert('회사이름 중복확인을 해주세요.');
                }
            }
            param.corpNameDuplicationCheckType = this.corpNameDuplicationCheckType;
            stepService.saveStep(param);
        }
    }
    get orininalCorpName() {
        return corpNameStepModel.orininalCorpName;
    }
    get changeKoreanCorpNameYn() {
        return corpNameStepModel.changeKoreanCorpNameYn;
    }
    setChangeKoreanCorpNameYn(e) {
        runInAction(() => {
            corpNameStepModel.changeKoreanCorpNameYn = e.target.value === "true";
        })
    }
    get 광역자치단체List() {
        return corpNameStepModel.소재지List.광역자치단체s;
    }
    get 광역자치단체() {
        return corpNameStepModel.광역자치단체;
    }
    set광역자치단체(e) {
        runInAction(() => {
            corpNameStepModel.광역자치단체 = e.target.value;
            corpNameStepModel.시군구 = '';
            corpNameStepModel.corpNameDuplicationCheckType = null;
        })
    }
    get 시군구List() {
        let returnArr = [];
        this.광역자치단체List.forEach(item => {
            if(item.name === this.광역자치단체) {
                returnArr = item.시군구s
            }
        });
        return returnArr;
    }
    get 시군구() {
        return corpNameStepModel.시군구;
    }
    set시군구(e) {
        runInAction(() => {
            corpNameStepModel.시군구 = e.target.value;
            corpNameStepModel.corpNameDuplicationCheckType = null;
        })
    }
    get newKoreanName() {
        return corpNameStepModel.newKoreanName;
    }
    setNewKoreanName(e) {
        runInAction(() => {
            corpNameStepModel.newKoreanName = e.target.value;
            corpNameStepModel.corpNameDuplicationCheckType = null;
        })
    }
    get smartFormType() {
        return smartformAppModel.smartFormType;
    }
    get text() { //폼타입별 문구
        switch(this.smartFormType) {
            case 'STOCK_COMPANY': return { formType: '주식회사' };
            case 'AGRICULTURAL_STOCK_COMPANY': return { formType: '농업회사법인 주식회사' };
            case 'FISHERY_STOCK_COMPANY': return { formType: '어업회사법인 주식회사' };
            case 'LIMITED_COMPANY': return { formType: '유한회사' };
            case 'LIMITED_LIABILITY_COMPANY': return { formType: '유한책임회사' };
        }
    }
    get validateFunc() {
        const companyName = this.newKoreanName;
        if(companyName.includes('주식회사')) {
            return {'includeYn': false, 'message' : '[주식회사] 문구는 제외하고 입력해 주세요.'}
        } else if(companyName.includes('농업회사법인')) {
            return {'includeYn': false, 'message' : '[농업회사법인] 문구는 제외하고 입력해 주세요.'}
        } else if(companyName.includes('어업회사법인')) {
            return {'includeYn': false, 'message' : '[어업회사법인] 문구는 제외하고 입력해 주세요.'}
        } else if(companyName.includes('유한회사')) {
            return {'includeYn': false, 'message' : '[유한회사] 문구는 제외하고 입력해 주세요.'}
        } else if(companyName.includes('유한책임회사')) {
            return {'includeYn': false, 'message' : '[유한책임회사] 문구는 제외하고 입력해 주세요.'}
        } else {
            return {'includeYn' : true, 'message' : null}
        }
    }
    async companyNameCheck() {
        if(this.시군구List.length && !this.시군구) {
            return await _alert('시군구를 입력해 주세요.'); 
        }
        if(!regExp.onlyKorNum_noSpacing().test(this.newKoreanName)) {
            return await _alert('한글 회사이름에는 한글, 숫자만 사용 가능합니다. 띄어쓰기는 불가능합니다.')
        }
        if(this.newKoreanName && this.광역자치단체) {
            runInAction(() => {
                this.state.companyNameCheckDisabled = true;
            })
            corpNameStepModel.corpNameDuplicationCheckType = await moduleService.corpNameDuplicationCheckWithAddress(this.newKoreanName, this.광역자치단체, this.시군구);
            runInAction(() => {
                this.state.companyNameCheckDisabled = false;
            })
        } else if(!this.newKoreanName) {
            await _alert('회사이름을 입력해 주세요.')
        } else if(!this.광역자치단체) {
            await _alert('소재지를 입력해 주세요.')
        }
    }
    get corpNameDuplicationCheckType() {
        return corpNameStepModel.corpNameDuplicationCheckType;
    }
    get prefixType() {
        return corpNameStepModel.prefixType;
    }
    setPrefixType(e) {
        runInAction(() => {
            corpNameStepModel.prefixType = e.target.value;
        })
    }
    get englishChangeYnText() {
        if(this.orininalCorpName.englishName === "없음" || !this.orininalCorpName.englishName) {
            return '추가'
        }else{
            return '변경'
        }
    }
    get changeEnglishCorpNameYn() {
        return corpNameStepModel.changeEnglishCorpNameYn;
    }
    setChangeEnglishCorpNameYn(e) {
        runInAction(() => {
            corpNameStepModel.changeEnglishCorpNameYn = e.target.value === "true";
        })
    }
    get newEnglishName() {
        return corpNameStepModel.newEnglishName;
    }
    setNewEnglishName(e) {
        runInAction(() => {
            corpNameStepModel.checkPronunciation = null
            corpNameStepModel.newEnglishName = e.target.value;
        })
    }
    get englishNotation() {
        return corpNameStepModel.englishNotation;
    }
    setEnglishNotation(e) {
        runInAction(() => {
            corpNameStepModel.englishNotation = e.target.value;
        })
    }
    get englishNotationSelectList() { //표기법
        const formType = this.smartFormType;
        switch(formType) {
            case 'STOCK_COMPANY': return [{text: 'Corp.', value: 'CORP'},  {text: 'Inc.', value: 'INC'},  {text: '표기안함', value: 'NONE'}];
            case 'AGRICULTURAL_STOCK_COMPANY': return [{text: 'Corp.', value: 'CORP'},  {text: 'Inc.', value: 'INC'},  {text: '표기안함', value: 'NONE'}];
            case 'FISHERY_STOCK_COMPANY': return [{text: 'Corp.', value: 'CORP'},  {text: 'Inc.', value: 'INC'},  {text: '표기안함', value: 'NONE'}];
            case 'LIMITED_COMPANY': return [{text: 'Ltd.', value: 'LTD'},  {text: '표기안함', value: 'NONE'}];
            case 'LIMITED_LIABILITY_COMPANY': return [{text: 'LLC.', value: 'LLC'},  {text: '표기안함', value: 'NONE'}];
        }
    }
    get finalKoreanName() {
        if(this.changeKoreanCorpNameYn === true) {
            if(this.newKoreanName) {
                if(this.smartFormType !== "AGRICULTURAL_STOCK_COMPANY" && this.smartFormType !== "FISHERY_STOCK_COMPANY") { //농어업아닐때 
                    if(this.prefixType === `${this.text.formType}_회사이름`) {
                        return `${this.text.formType} ${this.newKoreanName}`
                    }else if(this.prefixType === `회사이름_${this.text.formType}`) {
                        return `${this.newKoreanName} ${this.text.formType}`
                    }else{
                        return this.newKoreanName;
                    }
                }else{ //농어업일때
                    if(`${this.text.formType.replace(' 주식회사','')}_주식회사_회사이름` === this.prefixType) {
                        return `${this.text.formType.replace(' 주식회사','')} 주식회사 ${this.newKoreanName}`;
                    }else if(`주식회사_${this.text.formType.replace(' 주식회사','')}_회사이름` === this.prefixType) {
                        return `주식회사 ${this.text.formType.replace(' 주식회사','')} ${this.newKoreanName}`
                    }else if(`회사이름_${this.text.formType.replace(' 주식회사','')}_주식회사` === this.prefixType) {
                        return `${this.newKoreanName} ${this.text.formType.replace(' 주식회사','')} 주식회사`;
                    }else if(`회사이름_주식회사_${this.text.formType.replace(' 주식회사','')}` === this.prefixType) {
                        return `${this.newKoreanName} 주식회사 ${this.text.formType.replace(' 주식회사','')}`;
                    }else{
                        return this.newKoreanName;
                    }
                }
            }else{
                return "한글 회사이름을 입력해주세요.";
            }
        }else{
            return this.orininalCorpName.koreanName;
        }        
    }
    get finalEnglishName() {
        if(this.changeEnglishCorpNameYn === true) {
            return this.newEnglishName ? `${this.newEnglishName} ${this.selectedNotation}` : "영문 회사이름을 입력해주세요.";
        }else{
            return this.orininalCorpName.englishName;
        }        
    }
    get selectedNotation() {
        switch(this.englishNotation) {
            case 'CO_LTD' : return 'Co., Ltd';
            case 'CORP' : return 'Corp.';
            case 'INC' : return 'Inc.';
            case 'LTD' : return 'Ltd.';
            case 'LLC' : return 'LLC.';
            default : return '';
        }
    }
    get checkPronunciation() {
        return corpNameStepModel.checkPronunciation;
    }
    setCheckPronunciation(e) {
        runInAction(() => {
            corpNameStepModel.checkPronunciation = e.target.value === "true";
        })
    }
}
export default new viewModel();