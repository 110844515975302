import { observable, runInAction } from 'mobx';

const TCAgreeAndDeliveryAddressStepModel = observable({
    address: {},
    deliveryToCompanyAddressYn: null,
    detailAddress: "",
    nameOfBuilding: "",
    originAddress: "",
    tcagreements: [],
    setTCAgreeAndDeliveryAddress(data) {
        runInAction(() => {
            this.address = data?.address ?? {};
            this.deliveryToCompanyAddressYn = data?.deliveryToCompanyAddressYn ?? null;
            this.detailAddress = data?.detailAddress || "";
            this.nameOfBuilding = data?.nameOfBuilding || "";
            this.originAddress = data?.originAddress || "";
            if(data.tcagreements && data.tcagreements.length) {
                this.tcagreements = data.tcagreements.map((tcagreement) => {
                    return {
                        agreeYn: tcagreement.agreeYn ?? null, 
                        content: tcagreement.content || "",
                        id: tcagreement.id ?? "",
                        required: tcagreement.required ?? null,
                        termId: tcagreement.termId ?? ""
                    }
                })
            } else {
                this.tcagreements = [];
            }
        })
    }
});

export default TCAgreeAndDeliveryAddressStepModel;