import { observable, runInAction } from 'mobx';

const StockholdersMeetingSealProvidingStepModel = observable({
    shareholders: [],
    stockholderMeetingType: "",
    setStockholdersMeetingSealProviding(data) {
        runInAction(() => {
            if(data.shareholders && data.shareholders.length) {
                this.shareholders = data.shareholders.map((shareholder, idx) => {
                    return {
                        id: shareholder.id || "",
                        name: shareholder.name || "",
                        englishName: shareholder.englishName || "",
                        nationalityType: shareholder.nationalityType || "",
                        nationalityOthers: shareholder.nationalityOthers || "",
                        sealProviderType: shareholder.sealProviderType || ""                
                    }
                })
            } else {
                this.shareholders = [];
            }
            this.stockholderMeetingType = data.stockholderMeetingType || ""
        })
    }
});

export default StockholdersMeetingSealProvidingStepModel;