import smartformAppModel from '@model/smartformAppModel';
import pageStepModel, {pageRouter, pageDepth} from '@model/pageStepModel';

class viewModel {
    get userToken() {
        return smartformAppModel.userToken;
    }
    get smartformId() {
        return smartformAppModel.smartformId;
    }
    get isLoadData() {
        return smartformAppModel.isLoadData;
    }
    get isStep() {
        return pageStepModel.isStep;
    }
}

export default new viewModel();