import ExecutiveMemberChangeStepModel from '@model/changeRegistration/ExecutiveMemberChangeStepModel';
import smartformAppModel from '@model/smartformAppModel';
import stepService from "@service/stepService";
import formDataToJson from '@common/module/submit';
import { runInAction, observable } from 'mobx';
import { onlyNumber } from '@common/module/replaceNumber';
import regExp from '@common/module/regExp';
import { _alert, _confirm } from "@model/dialogModel";

class ExecutiveMemberChangeOriginChangeVm {
    constructor() {
        this.state = observable({
            addressModal: false,  
            addressIdx: null,
            newAddressIdx: null
        });
    }
    submit(e) {
        runInAction(() => {
            e.preventDefault();
            if(formDataToJson(e.target).error === false) {
                let param = formDataToJson(e.target).data;
                let changeYn = false; //견적대로 진행하지 않는 경우_변경할 임원을 한명이라도 선택했는지 여부
                param.executiveMembers.map((executiveMember, idx) => {
                    if(executiveMember.newAddresses && executiveMember.newAddresses.length !== 0) {
                        executiveMember.newAddresses.map((newAddress, idx2) => {
                            param.executiveMembers[idx].newAddresses[idx2].newAddress = ExecutiveMemberChangeStepModel.changeExecutiveMembers[idx].newAddresses[idx2].newAddress;
                        })
                    }
                    if(executiveMember.checkedYn) {
                        changeYn = true;
                    }
                })
                if(ExecutiveMemberChangeStepModel.keepProceedYn) {//견적대로 진행하는 경우
                    stepService.saveStep(param);
                } 
                else { //견적대로 진행하지 않는 경우
                    param.noChangeYn = ExecutiveMemberChangeStepModel.noChangeYn;
                    if(param.noChangeYn === false && changeYn === false) { //변경등기 진행할 임원, 변경할 사람 없음 둘다 선택하지 않은 경우
                        return _alert('변경등기 할 분을 선택해 주세요.<br/> 변경할 분이 없다면 변경할 사람 없음에 체크 해주세요.')
                    } else if(param.noChangeYn === true && changeYn === false) { //변경할 사람 없음 선택한 경우
                        param.executiveMembers = [];
                        stepService.saveStep(param);
                    } else {
                        stepService.saveStep(param);
                    }
                }
            }
        })
    }
    get smartFormType() {
        return smartformAppModel.smartFormType;
    }
    get keepProceedYn() {
        return ExecutiveMemberChangeStepModel.keepProceedYn;
    }
    get noChangeYn() {
        return ExecutiveMemberChangeStepModel.noChangeYn;
    }
    get changeExecutiveMembers() {
        return ExecutiveMemberChangeStepModel.changeExecutiveMembers;
    }
    setNoChangeYn(e) {
        runInAction(() => {
            const value = e.target.value !== 'true';
            if(value) {
                this.unChecked();
            }
            ExecutiveMemberChangeStepModel.noChangeYn = value;
        })
    }
    setCheckedYn(e, idx) {
        runInAction(() => {
            const value = e.target.value !== 'true';
            ExecutiveMemberChangeStepModel.changeExecutiveMembers[idx].checkedYn = value;
        })
    }
    unChecked() {
        runInAction(() => {
            ExecutiveMemberChangeStepModel.changeExecutiveMembers.forEach((executiveMember, idx) => {
                if(executiveMember.checkedYn) {
                    ExecutiveMemberChangeStepModel.changeExecutiveMembers[idx].checkedYn = false;
                }
            })
        })
    }
    setChangeType(e, idx) {
        runInAction(() => {
            ExecutiveMemberChangeStepModel.changeExecutiveMembers[idx].changeType = e.target.value;
        })
    }
    newPositionList(idx) {
        const smartFormType = this.smartFormType;
        const originPosition = this.changeExecutiveMembers[idx].originPosition;
        let newPositionList = [];
        switch(smartFormType) {
            case 'STOCK_COMPANY': 
                newPositionList = [{text: '단독 대표', value: '단독대표'}, {text: '각자 대표(대표 2명 이상)', value: '각자대표'}, {text: '공동 대표(대표 2명 이상)', value: '공동대표'}, {text: '사내이사', value: '사내이사'}, {text: '사외이사', value: '사외이사'}, {text: '기타비상무이사', value: '기타비상무이사'}, {text: '감사', value: '감사'}];
                break;
            case 'AGRICULTURAL_STOCK_COMPANY': 
                newPositionList = [{text: '단독 대표', value: '단독대표'}, {text: '각자 대표(대표 2명 이상)', value: '각자대표'}, {text: '공동 대표(대표 2명 이상)', value: '공동대표'}, {text: '사내이사', value: '사내이사'}, {text: '사외이사', value: '사외이사'}, {text: '기타비상무이사', value: '기타비상무이사'}, {text: '감사', value: '감사'}];
                break;
            case 'FISHERY_STOCK_COMPANY': 
                newPositionList = [{text: '단독 대표', value: '단독대표'}, {text: '각자 대표(대표 2명 이상)', value: '각자대표'}, {text: '공동 대표(대표 2명 이상)', value: '공동대표'}, {text: '사내이사', value: '사내이사'}, {text: '사외이사', value: '사외이사'}, {text: '기타비상무이사', value: '기타비상무이사'}, {text: '감사', value: '감사'}];
                break;
            case 'LIMITED_COMPANY': 
                newPositionList = [{text: '단독 대표', value: '단독대표'}, {text: '각자 대표(대표 2명 이상)', value: '각자대표'}, {text: '공동 대표(대표 2명 이상)', value: '공동대표'}, {text: '이사', value: '이사'}, {text: '감사', value: '감사'}];
                break;
            case 'LIMITED_LIABILITY_COMPANY': 
                newPositionList = [{text: '단독 대표', value: '단독대표'}, {text: '각자 대표(대표 2명 이상)', value: '각자대표'}, {text: '공동 대표(대표 2명 이상)', value: '공동대표'}, {text: '업무집행자', value: '업무집행자'}];
                break;
        }
        newPositionList.forEach((position, index) => {
            if(position.value === originPosition) {
                newPositionList.splice(index, 1);
            }
        })
        return newPositionList;
    }
    setNewPosition(e, idx) {
        runInAction(() => {
            ExecutiveMemberChangeStepModel.changeExecutiveMembers[idx].newPosition = e.target.value;
            if(ExecutiveMemberChangeStepModel.changeExecutiveMembers[idx].newAddresses.length === 0) {
                if(this.newPositionIsRepresentativeYn) {
                    ExecutiveMemberChangeStepModel.changeExecutiveMembers[idx].newAddresses.push({
                        id: "",
                        causeDate: {
                            year: "",
                            month: "",
                            day: ""
                        },
                        detailAddress: "",
                        nameOfBuilding: "",
                        newAddress: {}
                    })
                }
            }
        })
    }
    setKssn(e, idx) { 
        runInAction(() => {
            ExecutiveMemberChangeStepModel.changeExecutiveMembers[idx].kssn = e.target.value;
        })
    }
    setAddressSameYn(e, idx) {
        runInAction(() => {
            ExecutiveMemberChangeStepModel.changeExecutiveMembers[idx].addressSameYn = e.target.value === "true";
            if(ExecutiveMemberChangeStepModel.changeExecutiveMembers[idx].newAddresses.length === 0) {
                ExecutiveMemberChangeStepModel.changeExecutiveMembers[idx].newAddresses.push({
                    id: "",
                    causeDate: {
                        year: "",
                        month: "",
                        day: ""
                    },
                    detailAddress: "",
                    nameOfBuilding: "",
                    newAddress: {}
                })
            }
        })
    }
    get addressModal() {
        return this.state.addressModal; 
    }
    //도로명 주소
    openAddressModal(openYn, idx, idx2) {
        runInAction(() => {
            this.state.addressIdx = idx;
            this.state.newAddressIdx = idx2;
            this.state.addressModal = openYn;
        })
    }
    addressComplate(address) {
        runInAction(() => {
            ExecutiveMemberChangeStepModel.changeExecutiveMembers[this.state.addressIdx].newAddresses[this.state.newAddressIdx].newAddress = address;
            ExecutiveMemberChangeStepModel.changeExecutiveMembers[this.state.addressIdx].newAddresses[this.state.newAddressIdx].detailAddress = "";
            ExecutiveMemberChangeStepModel.changeExecutiveMembers[this.state.addressIdx].newAddresses[this.state.newAddressIdx].nameOfBuilding = address.buildingName;
        })
        this.openAddressModal(false,this.state.addressIdx,this.state.newAddressIdx);
    }
    setDetailAddress(e, idx, idx2) { //상세주소
        runInAction(() => {
            ExecutiveMemberChangeStepModel.changeExecutiveMembers[idx].newAddresses[idx2].detailAddress = e.target.value;
        })
    }
    setNameOfBuilding(e, idx, idx2) { //건물명
        runInAction(() => {
            ExecutiveMemberChangeStepModel.changeExecutiveMembers[idx].newAddresses[idx2].nameOfBuilding = e.target.value;
        })
    }
    //외국인 생년월일
    setYear(e, idx, idx2){
        runInAction(() => {
            ExecutiveMemberChangeStepModel.changeExecutiveMembers[idx].newAddresses[idx2].causeDate.year = onlyNumber(e.target.value);
        })
    }
    setMonth(e, idx, idx2){
        runInAction(() => {
            ExecutiveMemberChangeStepModel.changeExecutiveMembers[idx].newAddresses[idx2].causeDate.month = onlyNumber(e.target.value);
        })
    }
    setDay(e, idx, idx2){
        runInAction(() => {
            ExecutiveMemberChangeStepModel.changeExecutiveMembers[idx].newAddresses[idx2].causeDate.day = onlyNumber(e.target.value);
        })
    }
    monthBlur(e,idx, idx2) {
        runInAction(() => {
            let month = e.target.value;
            if(month && month.length===1){
                e.target.value = "0" + month;
            }
            ExecutiveMemberChangeStepModel.changeExecutiveMembers[idx].newAddresses[idx2].causeDate.month = e.target.value;
        })
    }
    dayBlur(e,idx, idx2) {
        runInAction(() => {
            let day = e.target.value;
            if(day && day.length===1){
                e.target.value = "0" + day;
            }
            ExecutiveMemberChangeStepModel.changeExecutiveMembers[idx].newAddresses[idx2].causeDate.day = e.target.value;
        })
    }
    displayBirthErrorMessage(idx, idx2, type) { 
        const year = this.changeExecutiveMembers[idx].newAddresses[idx2].causeDate.year;
        const month = this.changeExecutiveMembers[idx].newAddresses[idx2].causeDate.month;
        if(type === 'month') {
            if(year && regExp.year().test(year)) { //연도가 제대로 입력되었을 때, 월에 대한 error 메시지
                return true;
            } else {
                return false;
            }
        } else if(type === 'day') {
            if(year && regExp.year().test(year) && month && regExp.month().test(month)) { // 연도, 월이 제대로 입력 되었을 때, 일(날짜)에 대한 error 메시지
                return true;
            } else {
                return false;
            }
        }
    }
    originRepresentativeYn(idx) { //기존등기부상 대표 여부
        const originPosition = this.changeExecutiveMembers[idx].originPosition;
        if(originPosition === '단독대표' || originPosition === '각자대표' || originPosition === '공동대표' || originPosition === '사내이사_대표' || originPosition === '이사_대표' || originPosition === '대표업무집행자' || originPosition === '각자대표업무집행자' || originPosition === '공동대표업무집행자' || originPosition === '업무집행자_대표') {
            return true;
        } else {
            return false;
        }
    }
    newRepresentativeYn(idx) { //대표로 변경 여부
        const newPosition = this.changeExecutiveMembers[idx].newPosition;
        if(newPosition === '단독대표' || newPosition === '각자대표' || newPosition === '공동대표' || newPosition === '사내이사_대표' || newPosition === '이사_대표' || newPosition === '대표업무집행자' || newPosition === '각자대표업무집행자' || newPosition === '공동대표업무집행자' || newPosition === '업무집행자_대표') {
            return true;
        } else {
            return false;
        }
    }
    newPositionIsRepresentativeYn(idx) { //기존등기부상 대표X 일 때, 대표로 직책 변경 여부
        const originRepresentativeYn = this.originRepresentativeYn(idx);
        const newRepresentativeYn = this.newRepresentativeYn(idx);
        if(originRepresentativeYn === false && newRepresentativeYn === true) { //기존등기부상 대표X -> 대표
            return true;
        } else { //기존등기부상 대표X -> 대표X
            return false;
        }
    }
    displayOriginPosition(idx) { //기존직책 표시
        let position = this.changeExecutiveMembers[idx].originPosition;
        if(position === '단독대표' || position === '각자대표' || position === '사내이사_대표' || position === '이사_대표' || position === '업무집행자_대표' || position === '대표업무집행자' || position === '각자대표업무집행자') { //사내이사(대표), 대표이사(단독), 대표이사(각자) 대표님으로 표시
            return '대표';
        } else if(position === '공동대표업무집행자') {
            return '공동대표';
        } else {
            return position;
        }
    }
    displayKssn(idx) { //주민등록번호 표시 여부
        const foreignerYn = this.changeExecutiveMembers[idx].foreignerYn;
        if(this.keepProceedYn) { //견적대로 진행
            if(foreignerYn) return false;
            else return true;
        } else { //견적과 다르게 진행
            const changeType = this.changeExecutiveMembers[idx].changeType;
            if(changeType === '임기연장' || changeType === '사임_퇴임' || changeType === '보직변경') {
                if(foreignerYn) return false;
                else return true;
            } else if(changeType === '집주소변경') {
                return false;
            }
        }
    }
    displayAddressCheck(idx) { //주소확인 표시 여부
        if(this.keepProceedYn) { //견적대로 진행
            if(this.originRepresentativeYn(idx)) return true;
        } else { //견적과 다르게 진행
            const changeType = this.changeExecutiveMembers[idx].changeType;
            if(changeType === '임기연장' || changeType === '사임_퇴임' || changeType === '보직변경') {
                if(this.originRepresentativeYn(idx)) return true;
            } else if(changeType === '집주소변경') {
                return false;
            }
        }
    }
    displayAddress(idx) { //변경된 주소 입력
        const addressSameYn = this.changeExecutiveMembers[idx].addressSameYn;
        const newPositionIsRepresentativeYn = this.newPositionIsRepresentativeYn(idx);
        if(this.keepProceedYn) { //견적대로 진행
            if(addressSameYn === false) return true; //주민등록상 주소와 등기부상 주소가 다를 경우
            else return false;
        } else { //견적과 다르게 진행
            const changeType = this.changeExecutiveMembers[idx].changeType;
            if(changeType === '임기연장' || changeType === '사임_퇴임') {
                if(addressSameYn === false) return true; //주민등록상 주소와 등기부상 주소가 다를 경우 
                else return false;
            } else if(changeType === '보직변경')  {
                if(addressSameYn === false && newPositionIsRepresentativeYn === false) return true;
                else return false;
            } else if(changeType === '집주소변경') {
                return true;
            }
        }
    }
    displayNewAddress(idx) { //신규 주소 입력
        const newPositionIsRepresentativeYn = this.newPositionIsRepresentativeYn(idx);
        if(this.keepProceedYn) { //견적대로 진행
            if(newPositionIsRepresentativeYn) return true;
            else return false;
        } else { //견적과 다르게 진행
            const changeType = this.changeExecutiveMembers[idx].changeType;
            if(changeType === '보직변경') {
                if(newPositionIsRepresentativeYn) return true;
                else return false;
            }
        }
    }
    display임기연장() { //임기연장 표시여부
        const smartFormType = this.smartFormType;
        if(smartFormType === 'LIMITED_COMPANY' || smartFormType === 'LIMITED_LIABILITY_COMPANY') {
            return false;
        } else {
            return true;
        }
    }
    display보직변경Text() {
        const smartFormType = this.smartFormType;
        if(smartFormType === 'LIMITED_LIABILITY_COMPANY') {
            return '예시) 단독대표→공동대표, 대표→업무집행자';
        } else {
            return '예시) 대표→이사, 이사→대표, 감사→이사';
        }
    }
    addNewAddress(idx) {
        runInAction(() => {
            ExecutiveMemberChangeStepModel.changeExecutiveMembers[idx].newAddresses.push({
                id: "",
                causeDate: {
                    year: "",
                    month: "",
                    day: ""
                },
                detailAddress: "",
                nameOfBuilding: "",
                newAddress: {}
            })
        })
    }
    async deleteNewAddress(idx,idx2) {
        const conf = await _confirm('입력하신 변동내역 정보를 삭제하시겠습니까?')
        if(conf){
            return ExecutiveMemberChangeStepModel.changeExecutiveMembers[idx].newAddresses.splice(idx2,1);
        } else {
            return 
        }
    }
}

export default new ExecutiveMemberChangeOriginChangeVm();