import { observable } from 'mobx';

const passwordModel = observable({
    hasPassword : null,
    newPassword : "",
    passwordCheck : "",
    password : "",
    sendEmailLoading : false,
    setHasPassword(hasPassword) {
        this.hasPassword = hasPassword || false;
    },
});

export default passwordModel;