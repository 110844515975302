import pageService from "@service/pageService";
import agriculturalFisheryDocumentUploadModel from '@model/agriculturalFisheryDocumentUploadModel'
import { _alert, _confirm } from "@model/dialogModel";
import smartformAppModel from "@model/smartformAppModel";
import { runInAction, observable } from "mobx";

class viewModel {
    constructor() {
        this.state = observable({
            submitBtnDisabledYn: null
        });
    }
    loadAgriculturalFisheryDocuments() {
        runInAction(() => {
            agriculturalFisheryDocumentUploadModel.agriculturalFisheryDocumentsUploadFiles = [];
            agriculturalFisheryDocumentUploadModel.agriculturalFisheryDocumentsDeleteFiles = [];
        })
        pageService.loadAgriculturalFisheryDocuments();
    }
    get documentsFiles() {
        return agriculturalFisheryDocumentUploadModel.agriculturalFisheryDocumentsFiles;
    }
    get documentsUploadFiles() {
        return agriculturalFisheryDocumentUploadModel.agriculturalFisheryDocumentsUploadFiles;
    }
    get documentsDeleteFiles() {
        return agriculturalFisheryDocumentUploadModel.agriculturalFisheryDocumentsDeleteFiles;
    }
    async upload() {
        if(agriculturalFisheryDocumentUploadModel.agriculturalFisheryDocumentsUploadFiles.length === 0 && agriculturalFisheryDocumentUploadModel.agriculturalFisheryDocumentsFiles.length === 0) { //파일을 업로드를 하지 않은 경우
            await _alert('파일을 업로드해 주세요.')
        } 
        else if(agriculturalFisheryDocumentUploadModel.agriculturalFisheryDocumentsUploadFiles.length !== 0 || agriculturalFisheryDocumentUploadModel.agriculturalFisheryDocumentsDeleteFiles.length !== 0) { //업로드할 파일이 있는 경우(처음 파일을 업로드 하는 경우, 기존 파일을 변경한 경우)
            this.state.submitBtnDisabledYn = true;
            await pageService.uploadAgriculturalFisheryDocuments();
            await _alert('파일을 업로드 하였습니다.')
            this.state.submitBtnDisabledYn = false;
            if(smartformAppModel.corpId) {//대시보드에서 열었을 때(corpId가 있을 때)
                if(window.opener) {
                    window.opener.close()
                }
                sessionStorage.clear();
                window.open(`${process.env.REACT_APP_DASHBOARD_URL}corps/${smartformAppModel.corpId}/progress-stages/request-documents`, '_blank');
                window.close()
            } else { //어드민에서 열었을 때(corpId가 없을 때)
                sessionStorage.clear();
                window.close()
            }
            
        } 
        else { //기존 파일을 변경 없이 그대로 업로드 하는 경우
            if(await _confirm('기존 파일 그대로 업로드하시겠습니까?')) {
                await _alert('기존 파일을 업로드 하였습니다.');
                if(smartformAppModel.corpId) {//대시보드에서 열었을 때(corpId가 있을 때)
                    if(window.opener) {
                        window.opener.close()
                    }
                    sessionStorage.clear();
                    window.open(`${process.env.REACT_APP_DASHBOARD_URL}corps/${smartformAppModel.corpId}/progress-stages/request-documents`, '_blank');
                    window.close()
                } else { //어드민에서 열었을 때(corpId가 없을 때)
                    sessionStorage.clear();
                    window.close()
                }
            }
        }
    }
}

export default new viewModel();