import StandByStockholderMeetingInfoStepModel from '@model/changeRegistration/StandByStockholderMeetingInfoStepModel';
import stepService from "@service/stepService";
import formDataToJson, { errorFocus, findPos }  from '@common/module/submit';
import { runInAction, observable } from 'mobx';
import portalModel from '@model/portalModel';
import NormalModal from '@pages/ModalContent/changeRegistration/StandByStockholderMeetingBasicInfo/StockholdersNormalModal';
import SpecialModal from '@pages/ModalContent/changeRegistration/StandByStockholderMeetingBasicInfo/StockholdersSpecialModal';

class StandByStockholderMeetingBasicInfoVm {
    submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error === false) {
            let param = formDataToJson(e.target).data;
            if(!Array.isArray(param.participants)) {
                param.participants = [param.participants];
            }
            if(this.checkState === "부족") {
                const stockholderMeetingType = StandByStockholderMeetingInfoStepModel.stockholderMeetingType;
                let modal = {};
                if(stockholderMeetingType === '주주총회_일반결의') {
                    modal = NormalModal;
                } else {
                    modal = SpecialModal;
                }
                runInAction(() => {
                    portalModel.modalContent = modal.modalContent;
                    portalModel.btn = modal.btn ?? null;
                    portalModel.closeBtn = modal.closeBtn ?? true;
                })
                return;
            }
            stepService.saveStep(param);
        }
    }
    get chairmanId() {
        return StandByStockholderMeetingInfoStepModel.chairmanId;
    }
    get executiveMembers() {
        return StandByStockholderMeetingInfoStepModel.executiveMembers;
    }
    get stockholders() {
        return StandByStockholderMeetingInfoStepModel.stockholders;
    }
    setChairmanId(e) {
        runInAction(() => {
            StandByStockholderMeetingInfoStepModel.chairmanId = Number(e.target.value);
        })
    }
    get totalAmount() {
        let total = 0;
        StandByStockholderMeetingInfoStepModel.stockholders.forEach((stockholder) => {
            stockholder.stocks.forEach((stock) => {
                total = total + stock.amount;
            })
        })

        return total;
    }
    rate(idx, idx2) {
        const total = this.totalAmount;
        const amount = StandByStockholderMeetingInfoStepModel.stockholders[idx].stocks[idx2].amount;

        const rate = ((amount / total) * 100).toFixed(2);

        return rate;
    }
    get allChecked() {
        let allChecked = true;
        StandByStockholderMeetingInfoStepModel.stockholders.forEach((stockholder) => {
            if(stockholder.attendYn === false) {
                allChecked = false;
            }
        })
        return allChecked;
    }
    setCheckedAll(e) {
        runInAction(() => {
            StandByStockholderMeetingInfoStepModel.stockholders.forEach((stockholder) => {
                stockholder.attendYn = e.target.checked;
            })
        })
    }
    setChecked(e, idx) {
        runInAction(() => {
            StandByStockholderMeetingInfoStepModel.stockholders[idx].attendYn = e.target.checked;
        })
    } 
    get checkState() {
        const stockholderMeetingType = StandByStockholderMeetingInfoStepModel.stockholderMeetingType;
        let state = "부족";

        const totalAmount = this.totalAmount;
        let attendAmount = 0;
        StandByStockholderMeetingInfoStepModel.stockholders.forEach((stockholder) => {
            if(stockholder.attendYn) {
                stockholder.stocks.forEach((stock) => {
                    attendAmount = attendAmount + stock.amount;
                })
            }
        })

        if(stockholderMeetingType === "주주총회_일반결의") {
            if(totalAmount/4 <= attendAmount) {
                state = "충분"
            }
        } else {
            if(totalAmount/3 <= attendAmount) {
                state = "충분"
            }
        }
        return state;
    }
}

export default new StandByStockholderMeetingBasicInfoVm();