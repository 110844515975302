import { observable } from 'mobx';

const finalSubmissionModel = observable({
    isResponse: false, //데이터 조회 여부
    corp: { //회사정보
        companySize: "",
        corpAddress: "",
        englishCorpName: "",
        initiatorRole: "",
        koreanCorpName: ""
    },
    etc: { //기타정보
        announcementMethod: "",
        canUseYn: null,
        corpSeals: null,
        freeLicenseRegistrationYn: null,
        noticeOfEstablishmentYn: null,
        prospectiveSocialEnterpriseYn: null,
        purposes: [],
        shareTransferRestrictionsYn: null,
        stockOptionYn: null,
        usageSeal: {
            normalSealCount: null,
            premiumSealCount: null,
            usageSealInfo: []
        }
    },
    executive: { //임원정보
        executives: []
    },
    finalPayment: null, //최종결제금액
    shareholder: { //주주정보
        capital: null,
        parValue: null,
        shareholders: []
    },
    submittedAt: "", //제출시간
    setFinalSubmission(finalSubmission) {
        this.isResponse = true || false;
        this.corp = finalSubmission?.corp || {}
        this.corp.companySize = finalSubmission?.corp?.companySize || "";
        this.corp.corpAddress = finalSubmission?.corp?.corpAddress || "";
        this.corp.englishCorpName = finalSubmission?.corp?.englishCorpName || "";
        this.corp.initiatorRole = finalSubmission?.corp?.initiatorRole || "";
        this.corp.koreanCorpName = finalSubmission?.corp?.koreanCorpName || "";
        if(finalSubmission?.etc) {
            this.etc.announcementMethod = finalSubmission?.etc?.announcementMethod || "";
            this.etc.canUseYn = finalSubmission?.etc?.canUseYn ?? null;
            this.etc.corpSeals = finalSubmission?.etc?.corpSeals || [];
            this.etc.freeLicenseRegistrationYn = finalSubmission?.etc?.freeLicenseRegistrationYn ?? null;
            this.etc.noticeOfEstablishmentYn = finalSubmission?.etc?.noticeOfEstablishmentYn ?? null;
            this.etc.prospectiveSocialEnterpriseYn = finalSubmission?.etc?.prospectiveSocialEnterpriseYn ?? false;
            this.etc.purposes = finalSubmission?.etc?.purposes || [];
            this.etc.shareTransferRestrictionsYn = finalSubmission?.etc?.shareTransferRestrictionsYn ?? false;
            this.etc.stockOptionYn = finalSubmission?.etc?.stockOptionYn ?? false;
            this.etc.usageSeal = finalSubmission?.etc?.usageSeal || {
                normalSealCount: "",
                premiumSealCount: "",
                usageSealInfo: []
            };
        } else {
            this.etc = {
                announcementMethod: "",
                canUseYn: null,
                corpSeals: null,
                freeLicenseRegistrationYn: null,
                prospectiveSocialEnterpriseYn: false,
                purposes: [],
                shareTransferRestrictionsYn: false,
                stockOptionYn: false,
                usageSeal: {
                    normalSealCount: null,
                    premiumSealCount: null,
                    usageSealInfo: []
                }
            }
        }
        if(finalSubmission?.executive && finalSubmission?.executive.length !== 0) {
            this.executive = finalSubmission?.executive || [];
        } else {
            this.executive = {
                executives: [{
                    address: "",
                    birth: "",
                    englishName: "",
                    isFarmerOrFisherman: null,
                    isShareholder: null,
                    koreanName: "",
                    nationality: "",
                    position: "",
                    resignationYn: null
                }]
            }
        }
        this.finalPayment = finalSubmission?.finalPayment ?? null;
        if(finalSubmission?.shareholder) {
            this.shareholder.capital = finalSubmission?.shareholder.capital || null;
            this.shareholder.parValue = finalSubmission?.shareholder.parValue || null;
            this.shareholder.shareholders = finalSubmission?.shareholder.shareholders || [];
        } else {
            this.shareholder = {
                capital: null,
                parValue: null,
                shareholders: [{
                    address: "",
                    birth: "",
                    englishAddress: "",
                    englishName: "",
                    investment: "",
                    koreanName: "",
                    nationality: "",
                    shareholderType: "",
                    representatives: [{
                        koreanName: "",
                        englishName: "",
                        position: ""
                    }]
                }]
            }
        }
        this.submittedAt = finalSubmission?.submittedAt || "";
    }
});

export default finalSubmissionModel;