import st from './BuildingMove.module.scss';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useObserver } from 'mobx-react';
import img1 from './images/img_bridge.png';
import img2 from './images/img_building_1.png';
import img3 from './images/img_building_2.png';
import img4 from './images/img_building_3.png';
import img5 from './images/img_building_4.png';
import img6 from './images/img_building_5.png';
import img7 from './images/img_building_6.png';
import img8 from './images/img_building_7.png';
import img9 from './images/img_building_8.png';
import img10 from './images/img_building_9.png';
import img11 from './images/img_cloud_1.png';
import img12 from './images/img_cloud_2.png';
import img13 from './images/img_oper_1.png';
import img14 from './images/img_oper_2.png';
import img15 from './images/img_oper_3.png';
import img16 from './images/img_sun.png';

function BuildingMove(props) {
  const [active, setActive] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setActive(true)
    }, 300)
  })
  return useObserver(() => (
    <div className={st.wrap}>
      <div className={st.viewArea}>
          <div className={clsx(st.content, active ? st.active : null)}>
              <img src={img1} className={clsx(st.img1, st.build)}/>
              <img src={img2} className={clsx(st.img2, st.build)}/>
              <img src={img3} className={clsx(st.img3, st.build)}/>
              <img src={img4} className={clsx(st.img4, st.build)}/>
              <img src={img5} className={clsx(st.img5, st.build)}/>
              <img src={img6} className={clsx(st.img6, st.build)}/>
              <img src={img7} className={clsx(st.img7, st.build)}/>
              <img src={img8} className={clsx(st.img8, st.build)}/>
              <img src={img9} className={clsx(st.img9, st.build)}/>
              <img src={img10} className={clsx(st.img10, st.build)}/>
              <img src={img11} className={clsx(st.img11, st.cloud)}/>
              <img src={img12} className={clsx(st.img12, st.cloud)}/>
              <img src={img13} className={clsx(st.img13, st.shadow)}/>
              <img src={img14} className={clsx(st.img14, st.shadow)}/>
              <img src={img15} className={clsx(st.img15, st.shadow)}/>
              <img src={img16} className={clsx(st.img16, st.sun)}/>
          </div>
      </div>
    </div>
  ));
}

export default BuildingMove;
