import pageService from "@service/pageService";
import minorDocumentUploadModel from '@model/minorDocumentUploadModel'
import { runInAction, toJS, observable } from "mobx";
import pageStepModel, {pageRouter} from '@model/pageStepModel';
import smartformAppModel from "@model/smartformAppModel";
import { _alert, _confirm } from "@model/dialogModel";

class viewModel {
    constructor() {
        this.state = observable({
            submitBtnDisabledYn: false
        });
    }
    loadMinorShareholderDocuments() {
        runInAction(() => {
            minorDocumentUploadModel.basicCertificateUploadFiles = [];
            minorDocumentUploadModel.basicCertificateDeleteFiles = [];
        })
        pageService.loadMinorShareholderDocuments();
    }
    get saveState() {
        return pageStepModel.saveState;
    }
    get basicCertificate() {
        return minorDocumentUploadModel.basicCertificate;
    }
    get basicCertificateUploadFiles() {
        return minorDocumentUploadModel.basicCertificateUploadFiles;
    }
    get basicCertificateDeleteFiles() {
        return minorDocumentUploadModel.basicCertificateDeleteFiles;
    }
    async upload() {
        if(minorDocumentUploadModel.basicCertificateUploadFiles.length === 0 && minorDocumentUploadModel.basicCertificate.length === 0) { //파일을 업로드 하지 않은 경우
            await _alert('파일을 업로드해 주세요.')
        } 
        else if(minorDocumentUploadModel.basicCertificateUploadFiles.length !== 0 || minorDocumentUploadModel.basicCertificateDeleteFiles.length !== 0) {//업로드할 파일이 있는 경우(처음 파일을 업로드 하는 경우, 기존 파일을 변경한 경우)
            this.state.submitBtnDisabledYn = true;
            pageStepModel.saveState = 'load';
            await pageService.uploadMinorShareholderDocumentsBasic();
            await _alert('파일을 업로드 하였습니다.')
            this.state.submitBtnDisabledYn = false;
            window.opener.postMessage("loadApi", '*');
            runInAction(() => {
                pageStepModel.saveState = 'clear';
                setTimeout(() => {
                    pageStepModel.saveState = 'next';
                    pageRouter.replace('/MinorDocumentUpload/familyRelationshipCertificate');
                }, 600)
            })
        } 
        else { //기존 파일을 변경 없이 그대로 업로드 하는 경우
            pageStepModel.saveState = 'load';
            if(await _confirm('기존 파일 그대로 업로드하시겠습니까?')) {
                await _alert('기존 파일을 업로드 하였습니다.')
                window.opener.postMessage("loadApi", '*');
            }  
            runInAction(() => {
                pageStepModel.saveState = 'clear';
                setTimeout(() => {
                    pageStepModel.saveState = 'next';
                    pageRouter.replace('/MinorDocumentUpload/familyRelationshipCertificate');
                }, 600)
            })
        }
    }
    name() {
        const type = smartformAppModel.smartFormType;
        switch(type) {
            case 'ESTABLISHMENT_STOCK_COMPANY':
                return '주주/임원'
            case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY':
                return '주주/임원'
            case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY':
                return '주주/임원'
            case 'ESTABLISHMENT_LIMITED_COMPANY':
                return '출자자/임원'
            case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY':
                return '출자자/임원'
        }
    }  
}

export default new viewModel();