import { get, post } from '@common/module/httpRequest';
import smartformAppModel from '@model/smartformAppModel';
import pageStepModel, { pageDepth, pageRouter } from '@model/pageStepModel';
import companyInfoModel from '@model/establish/companyInfoModel';
import shareholderAndExecutiveInfoModel from '@model/establish/shareholderAndExecutiveInfoModel';
import etcInfoModel from '@model/establish/etcInfoModel'
import termsAndConditionsAgreeModel from '@model/establish/termsAndConditionsAgreeModel'
import finalSubmissionModel from '@model/establish/finalSubmissionModel'
import adminFinalCheckViewModel from '@model/establish/adminFinalCheckViewModel'
import { runInAction, toJS } from 'mobx';
import {_alert} from "@model/dialogModel";

class establishService {
    async loadEstablish() { //설립데이터 조회
        const res = await get(`/step/establish/${smartformAppModel.smartformId}`, {
            "lastPage" : pageStepModel.nowPage,
            "lastStep" : pageStepModel.nowStep
        } , {});
        const data = res.data;
        smartformAppModel.setIsLoadData(false);
        smartformAppModel.setIsSubmitData(true);
        //주주 또는 임원에 외국인, 외국법인, 한국법인이 있는 경우
        let notKorean = 0, allKorean = true;
        if(data.shareholders && data.shareholders.length !== 0) { //주주 중 외국인, 외국법인, 한국법인이 있는 경우
            data.shareholders.forEach((shareholder, idx) => {
                if(shareholder.shareholderType !== 'KOREAN_PERSON') {
                    notKorean++;
                } 
            })
        }
        if(data.executiveMembers && data.executiveMembers.length !== 0){ //임원 중 외국인, 외국법인, 한국법인이 있는 경우
            data.executiveMembers.forEach((executive, idx) => {
                if(!executive.shareholderId && executive.koreanYn === false) { 
                    notKorean++;
                }
            })
        }
        if(notKorean !== 0) { //한국인이 아닌 사람이 한명이라도 있는 경우 allKorean은 false
            allKorean = false;
        }
        runInAction(() => {
            //smartFormType
            smartformAppModel.setSmartFormType(data.smartFormType);
            pageDepth.setPageDepthEstablish(data.smartFormType, allKorean, data.capital);     
            //CompanyInfo
            companyInfoModel.setCompanySize(data.companySize);
            companyInfoModel.setCompanyAddress(data.companyAddress);
            companyInfoModel.setCompanyName(data.companyName);
            companyInfoModel.setFarmerCheck(data.farmerCheck); //농업회사법인
            companyInfoModel.setFishermanCheck(data.fishermanCheck); //어업회사법인
            companyInfoModel.setCheckPreReportingSystemYn(data?.farmerCheck?.checkPreReportingSystemYn || data?.fishermanCheck?.checkPreReportingSystemYn); //농업 || 어업회사법인
            //ShareholderAndExecutiveInfo
            shareholderAndExecutiveInfoModel.setParValue(data.parValue); //1주당 금액
            shareholderAndExecutiveInfoModel.setShareholders(data.shareholders); //주주
            shareholderAndExecutiveInfoModel.setInvestments(data.investments, data.capital); //자본금
            shareholderAndExecutiveInfoModel.setExecutiveMembers(data.executiveMembers); //임원
            //etcInfoModel
            etcInfoModel.setBusinessPurposes(data.businessPurposes);
            etcInfoModel.setChooseStamp(data.chooseStamp); 
            etcInfoModel.setOption(data.option);
            etcInfoModel.setCanUseCertificateYn(data.canUseCertificateYn);
            etcInfoModel.setFreeLicenseRegistrationYn(data.freeLicenseRegistrationYn);
            etcInfoModel.setNoticeOfEstablishmentYn(data.noticeOfEstablishmentYn);
            etcInfoModel.setNoticeOfEstablishmentYn(data.noticeOfEstablishmentYn);
            etcInfoModel.setTrademarkAgreeYn(data.trademarkAgreeYn);
            //termsAndConditionsAgreeModel
            termsAndConditionsAgreeModel.setTcagreements(data.tcagreements);
            //데이터가져온거 완료된상태
            smartformAppModel.setIsLoadData(true);
        })
        //입력하지 않고 넘어간 페이지가 있는 경우, 입력하지 않은 페이지로 이동하는 로직
        let pageList = []; 
        let preList = [];
        let url = '';
        pageDepth.cause.establish.steps.forEach((step, idx) => {  //스마트폼 전체 페이지에 대한 데이터 pageList에 넣어주기
            step.pages.forEach((page, idx2) => {
                pageList.push({step: step.code, page: page.code})
            })
        })
        pageList.forEach((list, idx) => { //pageList에서 현재 페이지를 찾아서 이전 페이지들에 대한 데이터 preList에 넣어주기
            if(list.page === pageStepModel.nowPage && list.step === pageStepModel.nowStep) {
                preList = pageList.slice(0, idx);
            }
        })
        preList.forEach((pre, idx) => { //입력하지 않은 페이지에 대한 데이터(data.invalidList)에 preList 데이터가 있는 경우, 해당 페이지로 이동
            data.invalidList.forEach((invalid, idx2) => {
                if(pre.step === invalid.step && pre.page === invalid.page) {
                    if(!url) {
                        url = `/step/establish/${pre.step}/${pre.page}`
                    }                  
                }
            })
        })
        if(url) {
            pageRouter.replace(url)
        }
    }
    async loadFinalSubmission() { //설립 최종 제출 데이터 조회
        try {
            const res = await get(`/step/establish/${smartformAppModel.smartformId}/FinalCheck`, {}, {})
            const data = res.data;
            runInAction(() => {
                smartformAppModel.setSmartFormType(data.smartFormType);
                finalSubmissionModel.setFinalSubmission(data);
            })
        } catch(e) { 
            await _alert('접근 불가능한 주소입니다.');
            pageDepth.setPageDepthEstablish(e.response.data.smartFormType);
            let pageList = [];
            let url = '';
            pageDepth.cause.establish.steps.forEach((step, idx) => {  //스마트폼 전체 페이지에 대한 데이터 pageList에 넣어주기
                step.pages.forEach((page, idx2) => {
                    pageList.push({step: step.code, page: page.code})
                })
            })
            pageList.forEach((list, idx) => {
                e.response.data.invalidList.forEach((invalid, idx2) => { //입력하지 않은 페이지에 대한 데이터(e.response.data.invalidList) 중 맨 처음 페이지로 이동
                    if(list.step === invalid.step && list.page === invalid.page) {
                        if(!url) {
                            url = `/step/establish/${invalid.step}/${invalid.page}`;
                        }
                    }
                })
            })
            pageRouter.replace(url);
        }
    }

}

export default new establishService();

