import { observable, runInAction } from 'mobx';

const NumOfSharesToBeIssuedStepModel = observable({
    sharesToBeIssuedType: "", //HELP_ME, DIRECT
    newNumOfSharesToBeIssued: null, //발행예정주식수 직접입력 값
    numOfSharesOnRegister: null, //등기부상 이미 발행된 주식수
    numOfNewShares: null, //이번에 발행할 신주식수
    numOfSharesToBeIssuedOnRegister: null, //등기부상 발행예정 주식수
    sharesToBeIssuedCase: "", //CASE_1, CASE_2, CASE_3
    setNumOfSharesToBeIssued(data) {
        runInAction(() => {
            this.sharesToBeIssuedType = data.sharesToBeIssuedType || "";
            this.newNumOfSharesToBeIssued = data.newNumOfSharesToBeIssued || null;
            this.numOfSharesOnRegister = data.numOfSharesOnRegister || null;
            this.numOfNewShares = data.numOfNewShares || null;
            this.numOfSharesToBeIssuedOnRegister = data.numOfSharesToBeIssuedOnRegister || null;
            this.sharesToBeIssuedCase = data.sharesToBeIssuedCase || "";
        })
    }
});

export default NumOfSharesToBeIssuedStepModel;