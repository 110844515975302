import { observable } from 'mobx';

const adminFinalCheckViewModel = observable({
    balanceCertificate: {
        balanceProofDate: "",
        bank: "",
        branchOffice: ""
    },
    corp: {
        companySize: "",
        corpAddress: {
            bname: "",
            bname1: "",
            buildingNo: "",
            detailAddress: "",
            fullAddress: "",
            nameOfBuilding: "",
            roadname: "",
            sido: "",
            sigungu: ""
        },
        deliveryAddress: {
            bname: "",
            bname1: "",
            buildingNo: "",
            detailAddress: "",
            fullAddress: "",
            nameOfBuilding: "",
            roadname: "",
            sido: "",
            sigungu: ""
        },
        englishCorpName: "",
        initiatorRole: "",
        koreanCorpName: ""
    },
    etc: {
        announcementMethod: "",
        canUseYn: null,
        corpSeals: [],
        freeLicenseRegistrationYn: null,
        homepageAddress: "",
        newspaperCompany: "",
        noticeOfEstablishmentYn: null,
        prospectiveSocialEnterpriseYn: null,
        purposes: [],
        shareTransferRestrictionsYn: null,
        stockOptionYn: null,
        usageSeal: {
            normalSealCount: null,
            premiumSealCount: null,
            usageSealInfo: []
        }
    },
    executive: {
        executives: []
    },
    shareholder: {
        capital: null,
        numOfSharesToBeIssued: null,
        parValue: null,
        shareholders: []
    },
    smartFormType: "",
    submittedYn: null,
    setAdminFinalCheckView(admin) {
        this.balanceCertificate = admin?.balanceCertificate || {};
        this.balanceCertificate.balanceProofDate = admin?.balanceCertificate?.balanceProofDate || "";
        this.balanceCertificate.bank = admin?.balanceCertificate?.bank || "";
        this.balanceCertificate.branchOffice = admin?.balanceCertificate?.branchOffice || "";
        if(admin?.corp) {
            this.corp.companySize = admin?.corp?.companySize || "";
            this.corp.corpAddress = admin?.corp?.corpAddress || {};
            this.corp.deliveryAddress = admin?.corp?.deliveryAddress || null;
            this.corp.englishCorpName = admin?.corp?.englishCorpName || "";
            this.corp.initiatorRole = admin?.corp?.initiatorRole || "";
            this.corp.koreanCorpName = admin?.corp?.koreanCorpName || "";
        } else {
            this.corp = {
                companySize: "",
                corpAddress: {
                    bname: "",
                    bname1: "",
                    buildingNo: "",
                    detailAddress: "",
                    fullAddress: "",
                    nameOfBuilding: "",
                    roadname: "",
                    sido: "",
                    sigungu: ""
                },
                deliveryAddress: {
                    bname: "",
                    bname1: "",
                    buildingNo: "",
                    detailAddress: "",
                    fullAddress: "",
                    nameOfBuilding: "",
                    roadname: "",
                    sido: "",
                    sigungu: ""
                },
                englishCorpName: "",
                initiatorRole: "",
                koreanCorpName: ""
            }
        }
        if(admin?.etc) {
            this.etc.announcementMethod = admin?.etc?.announcementMethod || "";
            this.etc.canUseYn = admin?.etc?.canUseYn ?? null;
            this.etc.corpSeals = admin?.etc?.corpSeals || [];
            this.etc.freeLicenseRegistrationYn = admin?.etc?.freeLicenseRegistrationYn ?? null;
            this.etc.homepageAddress = admin?.etc?.homepageAddress || "";
            this.etc.newspaperCompany = admin?.etc?.newspaperCompany || "";
            this.etc.noticeOfEstablishmentYn = admin?.etc?.noticeOfEstablishmentYn ?? null;
            this.etc.prospectiveSocialEnterpriseYn = admin?.etc?.prospectiveSocialEnterpriseYn ?? null;
            this.etc.purposes = admin?.etc?.purposes || [];
            this.etc.shareTransferRestrictionsYn = admin?.etc?.shareTransferRestrictionsYn ?? null;
            this.etc.stockOptionYn = admin?.etc?.stockOptionYn ?? null;
            this.etc.usageSeal = admin?.etc?.usageSeal || {
                normalSealCount: "",
                premiumSealCount: "",
                usageSealInfo: []
            };
        } else {
            this.etc = {
                announcementMethod: "",
                canUseYn: null,
                corpSeals: [],
                freeLicenseRegistrationYn: null,
                noticeOfEstablishmentYn: null,
                prospectiveSocialEnterpriseYn: null,
                purposes: [],
                shareTransferRestrictionsYn: null,
                stockOptionYn: null,
                usageSeal: {
                    normalSealCount: null,
                    premiumSealCount: null,
                    usageSealInfo: []
                }
            }
        }
        if(admin?.executive && admin?.executive.length !== 0) {
            this.executive = admin?.executive || [];
        } else {
            this.executive = {
                executives: [{
                    address: {
                        bname: "",
                        bname1: "",
                        buildingNo: "",
                        detailAddress: "",
                        fullAddress: "",
                        nameOfBuilding: "",
                        roadname: "",
                        sido: "",
                        sigungu: ""
                    },
                    birth: "",
                    englishName: "",
                    isFarmerOrFisherman: null,
                    isShareholder: null,
                    koreanName: "",
                    nationality: "",
                    position: "",
                    resignationYn: null
                }]
            }
        }
        if(admin?.shareholder) {
            this.shareholder.capital = admin?.shareholder.capital || null;
            this.shareholder.numOfSharesToBeIssued = admin?.shareholder.numOfSharesToBeIssued || null;
            this.shareholder.parValue = admin?.shareholder.parValue || null;
            this.shareholder.shareholders = admin?.shareholder.shareholders || [];
        } else {
            this.shareholder = {
                capital: null,
                parValue: null,
                shareholders: [{
                    address: {
                        bname: "",
                        bname1: "",
                        buildingNo: "",
                        detailAddress: "",
                        fullAddress: "",
                        nameOfBuilding: "",
                        roadname: "",
                        sido: "",
                        sigungu: ""
                    },
                    birth: "",
                    englishName: "",
                    investment: "",
                    koreanName: "",
                    nationality: "",
                    shareholderType: "",
                    representatives: [{
                        koreanName: "",
                        englishName: "",
                        position: ""
                    }]
                }]
            }
        }
        this.smartFormType = admin?.smartFormType || "";
        this.submittedYn = admin?.submittedYn ?? null;
    }
});

export default adminFinalCheckViewModel;