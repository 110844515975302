import adminFinalCheckViewModel from "@model/establish/adminFinalCheckViewModel";
import smartformAppModel from "@model/smartformAppModel";
import { runInAction, observable, toJS } from "mobx";
import st from './AdminFinalCheckView.module.scss';
import { addCommas } from '@common/module/replaceNumber';
import adminService from "@service/adminService";

class viewModel {
    constructor() {
        this.state = observable({
            redirectUrl: '',
            isChange: null
        });
    }
    loadAdminFinalSubmission() {
        adminService.loadAdminFinalSubmission();
    }
    async managerMode () {
        const url = await adminService.managerMode();
        this.state.redirectUrl = url;
    }
    async estimate() { //수정사항 견적반영하기
        this.state.isChange = false;
        await adminService.estimate();
        this.state.isChange = true;
    }
    changeBalanceCertificate() {
        adminService.changeBalanceCertificate(adminFinalCheckViewModel.balanceCertificate);
    }
    companySize() { //참여 인원
        const companySize = adminFinalCheckViewModel.corp.companySize;
        const shareholderCnt = adminFinalCheckViewModel.shareholder.shareholders.length; //주주 인원
        const executiveCnt = adminFinalCheckViewModel.executive.executives.length; //임원 인원
        let shareholder_executive = 0; //주주+임원 인원
        adminFinalCheckViewModel.executive.executives.forEach((executive, idx) => {
            if(executive.isShareholder) {
                shareholder_executive++;
            }
        })
        if(companySize === "TWO") {
            return '2명'
        }
        else if(companySize === "OTHER") {
            if(shareholderCnt) {
                return (shareholderCnt + executiveCnt - shareholder_executive) + '명'
            } else {
                return '0 / 3명 이상 설립'
            }            
        }
    }
    initiatorRole() { //참여자 역할
        const initiatorRole = adminFinalCheckViewModel.corp.initiatorRole;
        if(initiatorRole === "REPRESENTATIVE_DIRECTOR") {
            return '대표(지분 100%) + 이사(지분 0%)'
        }
        else if(initiatorRole === "REPRESENTATIVE_AUDITOR") {
            return '대표(지분 100%) + 감사(지분 0%)'
        }
        else if(initiatorRole === 'REPRESENTATIVE_SHAREHOLDERS') {
            return '대표(지분 0%) + 주주(지분 100%)'
        }
        else if(initiatorRole === 'REPRESENTATIVE_STOCKED_DIRECTOR') {
            return '대표(지분 0%) + 이사(지분 100%)'
        }
        else if(initiatorRole === 'REPRESENTATIVE_STOCKED_AUDITOR') {
            return '대표(지분 0%) + 감사(지분 100%)'
        }
    }
    get koreanCorpName() { //한글 회사명
        return adminFinalCheckViewModel.corp.koreanCorpName;
    }
    get englishCorpName() { //영문 회사명
        return adminFinalCheckViewModel.corp.englishCorpName;
    }
    get corpAddress() { //회사 주소
        if(adminFinalCheckViewModel.corp.corpAddress?.fullAddress) {
            return adminFinalCheckViewModel.corp.corpAddress;
        } else {
            return null;
        }
    }
    get deliveryAddress() { //우편물 받을 주소
        return adminFinalCheckViewModel.corp.deliveryAddress;
    }
    get parValue() { //1주당 금액
        if(adminFinalCheckViewModel.shareholder.parValue) {
            return String(adminFinalCheckViewModel.shareholder.parValue);
        } else {
            return null;
        }
    }
    get numOfSharesToBeIssued() { //발행예정주식수
        return adminFinalCheckViewModel.shareholder.numOfSharesToBeIssued;
    }
    get capital() { //자본금
        if(adminFinalCheckViewModel.shareholder.capital) {
            return String(adminFinalCheckViewModel.shareholder.capital);
        } else {
            return null;
        }
    }
    //주주정보
    get shareholders() {
        return adminFinalCheckViewModel.shareholder.shareholders;
    }
    investmentPercent(idx) {
        const capital = adminFinalCheckViewModel.shareholder.capital;
        const investment = adminFinalCheckViewModel.shareholder.shareholders[idx].investment;
        if(capital && investment) {
            return Number(investment / capital) * 100 ;
        } else {
            return null;
        }
    }
    stock(idx) {
        const parValue = adminFinalCheckViewModel.shareholder.parValue;
        const investment = adminFinalCheckViewModel.shareholder.shareholders[idx].investment;
        if(parValue && investment) {
            return Number(investment / parValue) ;
        }
    }
    get executives() { //임원정보
        return adminFinalCheckViewModel.executive.executives;
    }
    isShareholder(idx) { //주주+임원 여부
        return adminFinalCheckViewModel.executive.executives[idx].isShareholder;
    }
    isFarmerOrFisherman(idx) {
        return adminFinalCheckViewModel.executive.executives[idx].isFarmerOrFisherman;
    }
    positionInfoDisplay(idx) { //사내이사(대표), 이사(대표), 업무집행자(대표)일 경우, 안내 문구 추가
        if(this.executives[idx].position === '대표') {
            if(this.smartFormType() === '유한책임회사') { //유한책임회사인 경우
                return 'limitedLiabilityCompany'
            } else { //유한책임회사가 아닌 경우_주식회사, 유한회사
                return 'unLimitedLiabilityCompany'
            }
        }
    }
    get purposes() { //사업목적
        return adminFinalCheckViewModel.etc.purposes;
    }
    get stockOptionYn() { //스톡옵션
        const stockOptionYn = adminFinalCheckViewModel.etc.stockOptionYn;
        if(stockOptionYn === true) {
            return '추가함';
        } else if(stockOptionYn === false) {
            return '추가하지 않음';
        } else {
            return null;
        }
    }
    스톡옵션Text(num) {
        switch(num) {
            case 1:
                return '본 회사는 임직원에게 발행주식총수의 100분의 10의 범위내에서 상법에 의한 주식매수선택권을 주주총회의 특별결의에 의하여 부여할 수 있다.'
            case 2:
                return '주식매수선택권의 행사로 발행하거나 양도할 주식(주식매수선택권의 행사가격과 시가와의 차액을 현금 또는 자기주식으로 교부하는 경우에는 그 차액의 산정기준이 되는 주식을 말한다)은 기명식 보통주식(또는 정관으로 정하는 종류주식)으로 한다.'
            case 3:
                return '주식매수선택권을 부여받을 자는 회사의 설립, 경영과 기술혁신 등에 기여하거나 기여할 수 있는 임직원으로 한다. 다만 상법 제340조의2 제2항의 각호에 해당하는 자에게는 주식매수선택권을 부여할 수 없다.'
            case 4:
                return '주식매수선택권은 이를 부여하는 주주총회 특별결의일부터 2년 이상 재임 또는 재직하여야 행사할 수 있다.'
            case 5:
                return '다음 각 호의 1에 해당하는 경우에는 이사회의 결의로 주식매수선택권의 부여를 취소할 수 있다.\n(1) 주식매수선택권을 부여받은 자가 본인의 의사에 따라 사임하거나 사직한 경우\n(2) 주식매수선택권을 부여받은 자가 고의 또는 과실로 회사에 중대한 손해를 입힌 경우\n(3) 본 회사의 파산 등으로 주식매수선택권 행사에 응할 수 없는 경우\n(4) 그 밖에 주식매수선택권을 부여받은 자와 체결한 주식매수선택권 부여계약에서 정한 취소사유가 발생한 경우'
        }
    }
    shareTransferRestrictionsYn() { //주식양도제한
        const shareTransferRestrictionsYn = adminFinalCheckViewModel.etc.shareTransferRestrictionsYn;
        if(shareTransferRestrictionsYn === true) {
            return '추가함';
        } else if(shareTransferRestrictionsYn === false) {
            return '추가하지 않음';
        } else {
            return null;
        }
    }
    get 주식양도제한Text() {
        return '주주는 이사회의 승인을 얻어 그 주식을 양도할 수 있다. 다만 이사회가 성립되지 않는 경우 주주총회의 승인을 얻으 그 주식을 양도할 수 있다.'
    }
    get announcementNewspaperCompany() { //공고방법_신문사
        return adminFinalCheckViewModel.etc.newspaperCompany;
    }
    get announcementHomepageAddress() { //공고방법_홈페이지 주소
        return adminFinalCheckViewModel.etc.homepageAddress
    }
    get 공고방법Text() {
        if(adminFinalCheckViewModel.etc.homepageAddress) { //홈페이지 주소가 있는 경우
            return `본 회사의 공고방법은 회사의 인터넷 홈페이지 ${adminFinalCheckViewModel.etc.homepageAddress}에 전자공고로 한다. 다만 전산장애 또는 그밖의 사유로 전자공고 방법에 의한 공고를 하는 것이 불가능한 경우에는 서울특별시내에서 발행하는 일간 ${adminFinalCheckViewModel.etc.newspaperCompany}에 게재한다. `
        } else { 
            return `본 회사의 공고는 서울특별시내에서 발행하는 일간 ${adminFinalCheckViewModel.etc.newspaperCompany}에 게재한다.`
        }
    }
    prospectiveSocialEnterpriseYn() { //예비 사회적 기업 여부
        const prospectiveSocialEnterpriseYn = adminFinalCheckViewModel.etc.prospectiveSocialEnterpriseYn;
        if(prospectiveSocialEnterpriseYn === true) {
            return '예비 사회적 기업으로 설립';
        } else if(prospectiveSocialEnterpriseYn === false) {
            return '추가하지 않음';
        } else {
            return null;
        }
    }
    signText(signValue) {
        switch(signValue) {
            case '동글':
                return '동글(●)';
            case '별':
                return '별(★)';
            case '마름모':
                return '마름모(◆)';
            case '클로버':
                return '클로버(♣)';
            case '스페이드':
                return '스페이드(♠)';
            case '하트':
                return '하트(♥)';
            case '네모':
                return '네모(■)';
            case '삼각형':
                return '삼각형(▲)';
            case '동글1':
                return '동글1(①)';
            case '동글2':
                return '동글2(②)';
            case '동글3':
                return '동글3(③)';
            case '동글4':
                return '동글4(④)';
            case '동글5':
                return '동글5(⑤)';
            case '동글6':
                return '동글6(⑥)';
            case '동글7':
                return '동글7(⑦)';
            case '동글8':
                return '동글8(⑧)';
            case '동글9':
                return '동글9(⑨)';
        }
    }
    corpSeal() { //법인인감
        let corpSealsList = adminFinalCheckViewModel.etc.corpSeals.map((corpSeal, idx) => {
            if(idx === 0) {
                if(corpSeal.type !== null) {
                    return  <div key={idx}>
                                {corpSeal.name} 대표님 : {corpSeal.type === '기본형' ? '기본형 무료제공' : '고급형 선택 (+22,000원)'} {corpSeal?.symbol && <span className={st.stampSymbol}>비표 {this.signText(corpSeal.symbol)}</span>}
                            </div>
                }
            } 
            else {
                return  <div key={idx} className={st.stampData}>
                    {corpSeal.name} 대표님 : {corpSeal.type === '기본형' ? '기본형 선택 (+14,300원)' : '고급형 선택 (+36,300원)'} {corpSeal?.symbol && <span className={st.stampSymbol}>비표 {this.signText(corpSeal.symbol)}</span>}
                </div>
            }
        })
        return corpSealsList;
    }
    usageSeal() { //사용인감
        const normal = adminFinalCheckViewModel.etc.usageSeal.normalSealCount;
        const premium = adminFinalCheckViewModel.etc.usageSeal.premiumSealCount;
        let usageSealNormal = [], usageSealPremium = [];
        if(adminFinalCheckViewModel.etc.usageSeal.usageSealInfo && adminFinalCheckViewModel.etc.usageSeal.usageSealInfo.length) {
            adminFinalCheckViewModel.etc.usageSeal.usageSealInfo.forEach((item, idx) => {
                if(item.defaultYn === '기본형') {
                    usageSealNormal.push(item);
                } else if(item.defaultYn === '고급형') {
                    usageSealPremium.push(item);
                }
            })
        }; 
        if(normal && premium) { 
            return  <div>
                        기본형 도장 {normal}개 추가, 고급형 도장 {premium}개 추가 (+{addCommas(String(normal*14300 + premium*36300))}원)
                        {adminFinalCheckViewModel.etc.usageSeal.usageSealInfo.length !== 0 && 
                            <div>
                                <div style={{marginTop: '5px'}}>
                                    기본형 도장 비표 :
                                    {usageSealNormal.map((item, idx) => {
                                        return <span key={idx}> {this.signText(item.symbol)}{idx !== usageSealNormal.length-1 && ','}</span>
                                    })}
                                </div>
                                <div style={{marginTop: '5px'}}> 
                                    고급형 도장 비표 :
                                    {usageSealPremium.map((item, idx) => {
                                        return <span key={idx}> {this.signText(item.symbol)}{idx !== usageSealPremium.length-1 && ','}</span>
                                    })}
                                </div>
                            </div>
                        }
                    </div>
        } 
        else if(normal !== 0 && premium === 0) {  
            return  <div>
                        기본형 도장 {normal}개 추가 (+{addCommas(String(normal*14300))}원)
                        {adminFinalCheckViewModel.etc.usageSeal.usageSealInfo.length !== 0 && 
                            <div>
                                <div style={{marginTop: '5px'}}>
                                    기본형 도장 비표 :
                                    {usageSealNormal.map((item, idx) => {
                                        return <span key={idx}> {this.signText(item.symbol)}{idx !== usageSealNormal.length-1 && ','}</span>
                                    })}
                                </div>
                            </div>
                        }
                    </div> 
        }
        else if(normal === 0 && premium !== 0) {
            return  <div>
                        고급형 도장 {premium}개 추가 (+{addCommas(String(premium*36300))}원)
                        {adminFinalCheckViewModel.etc.usageSeal.usageSealInfo.length !== 0 && 
                            <div>
                                <div style={{marginTop: '5px'}}>
                                    고급형 도장 비표 :
                                    {usageSealPremium.map((item, idx) => {
                                        return <span key={idx}> {this.signText(item.symbol)}{idx !== usageSealPremium.length-1 && ','}</span>
                                    })}
                                </div>
                            </div>
                        }
                    </div> 
        }
        else if(normal === 0 || premium === 0) {
            return '추가하지 않음'
        }
    }
    canUseYn() { //공인인증서
        const canUseYn = adminFinalCheckViewModel.etc.canUseYn;
        if(canUseYn) {
            return '모두 사용가능 (전자등기로 진행)'
        } else if(canUseYn === false){
            return '사용하지 않음 (서류등기로 진행)'
        } else {
            return canUseYn; //공인인증서 페이지가 없는 경우
        }
    }
    freeLicenseRegistrationYn() { //무료사업자등록
        const freeLicenseRegistrationYn = adminFinalCheckViewModel.etc.freeLicenseRegistrationYn;
        if(freeLicenseRegistrationYn === true) {
            return '신청함';
        } else if(freeLicenseRegistrationYn === false) {
            return '신청하지 않음';
        } else {
            return null;
        }
    }
    noticeOfEstablishmentYn() { //설립통지 여부
        const noticeOfEstablishmentYn = adminFinalCheckViewModel.etc.noticeOfEstablishmentYn;
        if(noticeOfEstablishmentYn) {
            return '확인 완료'
        }
        return ''
    }
    get finalPayment() { //최종 결제금액
        return String(adminFinalCheckViewModel.finalPayment)
    }
    get submittedAt() {
        return adminFinalCheckViewModel.submittedAt
    }
    get isResponse() { //설립 최종 제출 데이터 조회 여부
        return adminFinalCheckViewModel.isResponse;
    }
    get 등기사유Text() {
        return '발기인이 회사가 발행할 주식전부를 인수하고 2021. . . 발기인회에서 상법 제298조의 회사설립에 관한 조사보고절차를 종결하여 주식회사 발기설립절차를 종료하였으므로 다음 사항의 등기를 구함.'
    }
    get balanceProofDate() {
        return adminFinalCheckViewModel.balanceCertificate.balanceProofDate;
    }
    setBalanceProofDate(date) {
        adminFinalCheckViewModel.balanceCertificate.balanceProofDate = date;
    }
    get bank() {
        return adminFinalCheckViewModel.balanceCertificate.bank;
    }
    setBank(e) {
        adminFinalCheckViewModel.balanceCertificate.bank = e.target.value;
    }
    smartFormType() { //스마트폼 타입
        const type = smartformAppModel.smartFormType;
        switch(type) {
            case 'ESTABLISHMENT_STOCK_COMPANY':
                return '주식회사'
            case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY':
                return '농업회사법인'
            case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY':
                return '어업회사법인'
            case 'ESTABLISHMENT_LIMITED_COMPANY':
                return '유한회사'
            case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY':
                return '유한책임회사'
        }
    } 
    name() {
        const type = smartformAppModel.smartFormType;
        switch(type) {
            case 'ESTABLISHMENT_STOCK_COMPANY':
                return '주주'
            case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY':
                return '주주'
            case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY':
                return '주주'
            case 'ESTABLISHMENT_LIMITED_COMPANY':
                return '출자자'
            case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY':
                return '출자자'
        }
    }
    copyText() {
        const balanceProofDate = this.balanceProofDate;
        if(balanceProofDate) {
            return `발기인이 회사가 발행할 주식전부를 인수하고 ${balanceProofDate} 발기인회에서 상법 제298조의 회사설립에 관한 조사보고절차를 종결하여 주식회사 발기설립절차를 종료하였으므로 다음 사항의 등기를 구함.`
        } else {
            return `발기인이 회사가 발행할 주식전부를 인수하고 2022. 0. 0. 발기인회에서 상법 제298조의 회사설립에 관한 조사보고절차를 종결하여 주식회사 발기설립절차를 종료하였으므로 다음 사항의 등기를 구함.`
        }
    }
}
export default new viewModel();