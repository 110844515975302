import { useObserver } from 'mobx-react';
import StockholdersMeetingSealProviding from './StockholdersMeetingSealProviding/StockholdersMeetingSealProviding'
import { Route } from "react-router-dom";

function StockholdersMeetingSealProvidingStep() {
    return useObserver(() => (
    <>
        {/* 주주총회 정보 입력 */}
        <Route path="/step/changeRegistration/StockholdersMeetingSealProvidingStep/StockholdersMeetingSealProviding" component={StockholdersMeetingSealProviding}></Route>
    </>
    ));
}

export default StockholdersMeetingSealProvidingStep;