import st from './PasswordModal.module.scss';
import { runInAction, observable } from "mobx";
import portalModel from '@model/portalModel';
import passwordService from '@service/changeRegistration/passwordService';
import { useObserver } from 'mobx-react';

const resetPassword = (param) => {
    runInAction(() => {
        portalModel.modalContent = null;
        portalModel.btn = null;
        portalModel.closeBtn = true;
        passwordService.resetPassword(param);
    })
}
const state = observable({
    loading : false
});
const modal = {
    modalContent: 
        <div className={st.Modal}>
            <div className={st.title} style={{width:'370px'}}><span className={st.bold}>고객님!</span> 비밀번호를 잊으셨나요?</div>
            <div className={st.modalContent}>
                <p className={st.content}>
                    예를 누르시면 등록된 이메일 주소로 비밀번호를 초기화 할 수 있는 메일을 받으실 수 있습니다.
                </p>
            </div>
        </div>
    ,
    btn: [
        {title: '아니오', type: 'negative', event: ()=> {portalModel.setModalClose()}},
        {title: '예', type: 'positive', event: ()=> {resetPassword()}}
    ]
}

export default modal;

