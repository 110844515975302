class CorpNameStepVm {
    koreanName(step) {
        if(step.changeKoreanCorpNameYn) {
            return step.newKoreanName;
        }else{
            return '변경없음'
        }
    }
    englishName(step) {
        if(step.changeEnglishCorpNameYn) {
            return step.newEnglishName;
        } else {
            if(step.originEnglishName) {
                return '변경없음'
            } else {
                return '추가없음';
            }
            
        }
    }
}
export default new CorpNameStepVm();