import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Error = styled.div`
    color: #eb4d59;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.72px;
    
    .bold {
        font-weight: 700;
        text-decoration: underline;
        text-decoration-thickness: 2px;
    }
`;

function ErrorText(props) {
    return (
        <Error className={props.className} style={props.style} data-error={props.dataError} data-inputname={props.dataInputName}>
            {props.children}
        </Error>
    );
};

ErrorText.propTypes = {
    /**
    * class명 <br/>
    * .bold: 강조Text
    */
    className: PropTypes.string,
    /**
    * style 속성
    */
    style: PropTypes.object,
    /**
    * error 텍스트
    */
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.element
    ]),
};

ErrorText.defaultProps = {
    className: null,
    style: {},
    children: 'Empty'
};

export default ErrorText;

