import React from 'react';
import DatePicker, {registerLocale} from "react-datepicker";
import moment from 'moment';
import './date.css';
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import ko from 'date-fns/locale/ko';
import MaskedInput from 'react-text-mask'
import PropTypes from 'prop-types';
// function dateFormat(val){
//     return val.replace(/(\d\d\d\d)(\d\d)(\d\d)/g, '$1.$2.$3');
// }
function Input(props) {
    registerLocale("ko", ko);
    const range = (start, end) => {
        return new Array(end - start).fill().map((d, i) => i + start);
    };
    const years = range(2000, getYear(new Date()) + 2, 1);
    const months = [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월"
    ];
    
    return (
        <DatePicker
            showPopperArrow={false}
            className="datePicker"
            locale="ko"
            minDate={moment(props.minDate, 'yyyy.MM.DD').toDate()}
            renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled
            }) => (
                <div
                style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "center"
                }}
                >
                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} style={{border : "1px solid #aeaeae"}}>
                    {"<"}
                </button>
                <select
                    style={{border : "1px solid #aeaeae"}}
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(value)}
                >
                    {years.map(option => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                    ))}
                </select>
                <select
                    style={{border : "1px solid #aeaeae"}}
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                    }
                >
                    {months.map((option, index) => (
                    <option key={option} value={months[index]}>
                        {option}
                    </option>
                    ))}
                </select>

                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} style={{border : "1px solid #aeaeae"}}>
                    {">"}
                </button>
                </div>
            )}
            dateFormat="yyyy.MM.dd"
            placeholderText="0000.00.00"
            selected={props.value ? moment(props.value, 'yyyy.MM.DD').toDate() : null}
            onChange={(date) => props.onChange(date ? moment(date).format('yyyy.MM.DD') : null)}
            required = {props.required}
            customInput={
                <MaskedInput
                    mask={[/\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/]}
                    keepCharPositions= {true}
                    guide = {true}
                />
            }
        />
    );
}
Input.propTypes = {
    value : PropTypes.any,
    onChange: PropTypes.func,
    required : PropTypes.bool,
    /** 최소 날짜 */
    minDate: PropTypes.string,
}
Input.defaultProps = {
    value : null,
    onChange : () => {},
    required : false,
    minDate : null
}
export default Input;