import LiquidationStepModel from '@model/changeRegistration/LiquidationStepModel';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';

class KssnVm {
    displayKssnTitle(idx) {
        const liquidationStepType = LiquidationStepModel.liquidationStepType;
        const position = LiquidationStepModel.directors[idx].position;
        const koreanName = LiquidationStepModel.directors[idx].koreanName;
        if(liquidationStepType === 'CASE1') {
            return <><span className={Qst.bold}>현재 등기부상 {position}인 {koreanName}님</span>의 <span className={Qst.bold}>주민등록번호</span>를 입력해 주세요.</>;
        } else if(liquidationStepType === 'CASE2') {
            return <><span className={Qst.bold}>{koreanName} {position}님</span>의 <span className={Qst.bold}>주민등록번호</span>를 입력해 주세요.</>;
        } else {
            return <><span className={Qst.bold}>{koreanName ? koreanName : '청산인'}님</span>의 <span className={Qst.bold}>주민등록번호</span>를 입력해 주세요.</>;
        }
    }
    displayTitleExplain() {
        const liquidationStepType = LiquidationStepModel.liquidationStepType;
        if(liquidationStepType === 'CASE1') {
            return true;
        } else {
            return false;
        }
    }
}

export default new KssnVm();