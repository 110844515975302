import axios from 'axios';
import smartformAppModel from '@model/smartformAppModel';
import { _alert } from "@model/dialogModel";

const baseURL = process.env.REACT_APP_API_URL || 'empty';
const httpRequest = axios.create({
    baseURL: baseURL,
    headers: { 'Cache-Control': 'no-cache' }
});
httpRequest.interceptors.response.use(
    function(response) {
        return response
    },
    async function(error) {
        if(error?.response?.data?.message) {
            _alert(error?.response?.data?.message)
        }
        if(error?.response?.data?.invalidTokenYn) {
            smartformAppModel.setUserToken(null);
            sessionStorage.removeItem('userToken');
        }
        if(error?.response?.data?.redirectUrl) {
            window.location.href=error?.response?.data?.redirectUrl;
        }
        return Promise.reject(error);
    }
);
function get(url, body, option) {
    let requestOption = {Authorization : `Bearer ${smartformAppModel.userToken}`, ...option}
    return new Promise((resolve, reject) => {
        httpRequest.get(url, {params : body, headers: requestOption}).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        })
    })
}
function _delete(url, body, option) {
    let requestOption = {Authorization : `Bearer ${smartformAppModel.userToken}`}
    return new Promise((resolve, reject) => {
        httpRequest.delete(url, body, {headers: requestOption}).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        })
    })
}
function put(url, body, option) {
    let requestOption = {Authorization : `Bearer ${smartformAppModel.userToken}`}
    return new Promise((resolve, reject) => {
        httpRequest.put(url, body, {headers: requestOption}).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        })
    })
}
function post(url, body, option) {
    let requestOption = {Authorization : `Bearer ${smartformAppModel.userToken}`, ...option}
    return new Promise((resolve, reject) => {
        httpRequest.post(url, body, {headers: requestOption}).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        })
    })
}

export { get, post, _delete, put };