import { useObserver } from 'mobx-react';
import st from './Case2Modal.module.scss';
import clsx from 'clsx';
import vm from './MethodOfIssuingNewSharesVm';

function Case2Modal(props) {
    return useObserver(() => (
        <div className={st.modalContent}>
            <div className={st.title}>
                <span className={st.bold}>온라인</span>으로 <span className={st.bold}>주주동의</span> 받으세요!
            </div>
            <div className={st.steps}>
                <div className={st.stepWrap}>
                    <div className={st.step}>
                        <div className={st.stepContent}>
                            상황에 맞는 <span className={st.bold}>주주 동의서</span>를<br/>
                            <span className={st.bold}>자동</span>으로 작성합니다.
                        </div>
                    </div>
                </div>
                <div className={st.stepWrap}>
                    <div className={st.step}>
                        <div className={st.stepContent}>
                            모든 주주에게 <span className={st.bold}>온라인 도장</span><br/>
                            <span className={st.bold}>날인 요청</span>을 보내드립니다.
                        </div>
                    </div>
                </div>
                <div className={st.stepWrap}>
                    <div className={st.step}>
                        <div className={st.stepContent}>
                            <span className={st.bold}>온라인 도장</span>이 찍힌<br/>
                            <span className={st.bold}>동의서</span>가 <span className={st.bold}>자동 보관</span>됩니다.
                        </div>
                    </div>
                </div>
            </div>
            <div className={st.btnArea}>
                <button type="button" className={st.directBtn} onClick={() => vm.direct(props.param)}>
                    <span className={st.text}>직접 주주들을 만나 동의서에<br/>도장날인을 받겠습니다.</span>
                </button>
                <button type="button" className={st.serviceBtn} onClick={() => vm.service(props.param)}>
                    <span className={st.text}>
                        <span className={st.bold}>온라인</span>으로 <span className={st.bold}>동의서</span>에<br/>
                        <span className={st.bold}>도장날인</span>을 받겠습니다.
                    </span>
                    <span className={st.tag}>추천</span>
                    <span className={st.explain}>※ 서비스 이용시 별도 회원가입이 필요해요.</span>
                </button>
            </div>
        </div>
    ))
}

export default Case2Modal;

