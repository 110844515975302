import { useObserver } from 'mobx-react';
import st from '../../../FinalCheck/FinalCheck.module.scss';
import clsx from 'clsx';
import {toJS} from "mobx";
import {addCommas, percent} from '@common/module/replaceNumber';
import { Fragment } from 'react';
import CapitalIncreaseStepVm from './CapitalIncreaseStepVm';

function CapitalIncreaseStep(props) {
    return useObserver(() => (
        <table className={st.lightTable}>
            <colgroup>
                {CapitalIncreaseStepVm.limitCompanyYn ? 
                <>
                    <col width="135px"/>
                    <col/>
                    <col width="140px"/>
                    <col width="140px"/>
                </>
                :
                <>
                    <col width="200px"/>
                    <col/>
                    <col width="200px"/>
                    <col width="230px"/>
                </>
                }
                {CapitalIncreaseStepVm.limitCompanyYn &&  <col width="140px"/>}
                {CapitalIncreaseStepVm.limitCompanyYn &&  <col width="180px"/>}
            </colgroup>
            <thead>
                <tr>
                    <th></th>
                    <th style={{textAlign:"center"}}>인적사항</th>
                    <th style={{textAlign:"center"}}>발행방법</th>
                    <th style={{textAlign:"right"}}>투자금</th>
                    {CapitalIncreaseStepVm.limitCompanyYn && <th style={{textAlign:"right"}}>출자좌수</th>}
                    {CapitalIncreaseStepVm.limitCompanyYn && <th style={{textAlign:"right"}}>발행가액<br/>(자동계산)</th>}
                </tr>
            </thead>
            <tbody>
                {props.step.shareholders.map((shareholder, index) => {
                    let 유상증자Cnt = 0; let 가수금증자Cnt= 0;
                    if(shareholder?.유상증자) {
                        유상증자Cnt = 1;
                    }
                    if(shareholder?.가수금증자) {
                        가수금증자Cnt = 1;
                    }
                    return (
                        <Fragment key={index}>
                            <tr>
                                <td className={clsx(st.bottom, st.top)} rowSpan={유상증자Cnt+가수금증자Cnt+1} style={{paddingLeft:"0"}}>
                                    <div className={st.name}>
                                        {shareholder.koreanName}
                                        {shareholder.englishName && <div className={st.englishName}>({shareholder.englishName})</div>}
                                    </div>
                                    <div>{shareholder.existingShareholdersYn ? '기존 출자자' : '제3자'}</div>
                                </td>
                                <td className={clsx(st.bottom, st.top)} rowSpan={유상증자Cnt+가수금증자Cnt+1} style={{textAlign:"center"}}>
                                    {(shareholder.shareholderType === "FOREIGNER_COMPANY" || shareholder.shareholderType === "KOREAN_COMPANY") && 
                                        <div style={{marginBottom:"8px"}}>
                                            {shareholder.representatives.map((representative, index2) => (
                                                <div key={index2}>
                                                    {representative.position} {representative.koreanName}
                                                    {representative.englishName && 
                                                        <span>({representative.englishName})</span>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {shareholder.shareholderType === "FUND_AND_INVESTMENT_ASSOCIATION" && 
                                        <div style={{marginBottom:"8px"}}>
                                            {shareholder.representatives.map((representative, index2) => (
                                                <div key={index2}>
                                                    대표 {representative.koreanName}
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {shareholder.birth && 
                                        <div style={{marginBottom:"8px"}}>{shareholder.birth}</div>
                                    }
                                    {shareholder.englishName &&
                                        <div style={{marginBottom:"8px"}}>{shareholder.nationality}</div>
                                    }
                                    <div>
                                        {shareholder.koreanAddress}
                                        {shareholder.englishAddress && 
                                            <div>({shareholder.englishAddress})</div>
                                        }
                                    </div>
                                </td>
                                <td></td>
                                <td></td>
                                {CapitalIncreaseStepVm.limitCompanyYn && <td></td>}
                                {CapitalIncreaseStepVm.limitCompanyYn && <td></td>}
                            </tr>
                            {shareholder?.유상증자 &&
                                <tr className={clsx(가수금증자Cnt + 유상증자Cnt === 1 ? st.bottom : null, 유상증자Cnt === 1 ? st.top : null)}>
                                    <td style={{textAlign:"center"}}>일반 자본금 증액</td>
                                    <td style={{textAlign:"right"}}>{addCommas(shareholder?.유상증자.investment)} 원</td>
                                    {CapitalIncreaseStepVm.limitCompanyYn && <td style={{textAlign:"right"}}>{addCommas(shareholder?.유상증자.number)} 주</td>}
                                    {CapitalIncreaseStepVm.limitCompanyYn && <td style={{textAlign:"right"}}>{addCommas(shareholder?.유상증자.investment/shareholder?.유상증자.number)} 원</td>}
                                </tr>
                            }
                            {shareholder?.가수금증자 &&
                                <tr className={clsx(((유상증자Cnt === 1 && 가수금증자Cnt === 1) || 가수금증자Cnt === 1) ? st.bottom : null, 가수금증자Cnt === 1 ? st.top : null)}>
                                    <td style={{textAlign:"center"}}>가수금증자</td>
                                    <td style={{textAlign:"right"}}>{addCommas(shareholder?.가수금증자.investment)} 원</td>
                                    {CapitalIncreaseStepVm.limitCompanyYn && <td style={{textAlign:"right"}}>{addCommas(shareholder?.가수금증자.number)} 주</td>}
                                    {CapitalIncreaseStepVm.limitCompanyYn && <td style={{textAlign:"right"}}>{addCommas(shareholder?.가수금증자.investment/shareholder?.가수금증자.number)} 원</td>}
                                </tr>
                            }
                        </Fragment>
                    )
                })}
            </tbody>
        </table>
    ));
}
export default CapitalIncreaseStep;