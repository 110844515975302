import { useObserver } from 'mobx-react';
import BranchOfficeChangeVm from './BranchOfficeChangeVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './BranchOfficeChange.module.scss';
import clsx from 'clsx';
import Check from '@comComponents/atoms/Input/Check/Check';
import TextBtn from '@comComponents/atoms/Button/TextBtn/TextBtn';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import Icon from '@comComponents/atoms/Icon/Icon';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import regExp from '@common/module/regExp';
import SearchAddress from '@comComponents/atoms/SearchAddress/SearchAddress';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Select from '@comComponents/atoms/Input/Select/Select';
import { Fragment } from 'react';
import CauseDate from '../CauseDate/CauseDate';
import Address from '../Address/Address';
import Kssn from '../Kssn/Kssn';

function BranchOfficeChange() {
    return useObserver(() => (
        <form onSubmit={(e) => BranchOfficeChangeVm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            <Question>
                <ContentBox className={Qst.spaceBetween}>
                    <div className={Qst.infoSection}>
                        <div className={Qst.titleWrap}>
                            <h2 className={Qst.title}>
                                아래는 <span className={Qst.bold}>등기부상 지점 목록</span>입니다.<br/><span className={Qst.bold}>명칭 또는 지배인을 변경할 지점</span>을 <span className={Qst.bold}>모두 선택</span>해 주세요.
                            </h2>
                            <div className={Qst.titleExplain}>고객님이 견적단계에서 말씀하신 변경할 지점이 미리 선택되어 있습니다.</div>
                            <div className={Qst.titleExplain}>만약 견적단계에서 말씀하신 변경할 지점 숫자 또는 이전하는 위치가 달라지는 경우, 공과금 및 수수료가 달라질 수 있습니다.</div>
                        </div>
                        <div className={st.checkHeader}>
                            <div>
                                <Check className={st.checkAll} style={{fontSize:'17px', fontWeight:'500', top:'1px', whiteSpace:'pre'}} checked={BranchOfficeChangeVm.checkAll} onChange={(e) => BranchOfficeChangeVm.setCheckAll(e)}>전체선택({BranchOfficeChangeVm.checkCount}/{BranchOfficeChangeVm.branchOffices.length})</Check>
                            </div>
                            <div>
                                <TextBtn className={st.unCheckAll} onClick={() => BranchOfficeChangeVm.setCheckAll(false)}>선택해제</TextBtn>
                            </div>
                        </div>
                        <ul className={st.branchInfoList}>
                            {BranchOfficeChangeVm.branchOffices.map((item, idx) => {
                                return (
                                    <Fragment key={idx}>
                                        <input type="hidden" name={`branchOffices[${idx}].id`} value={item.id}/>
                                        {(item.originManager?.koreanYn === true || item.originManager?.koreanYn === false) && <input type="hidden" name={`branchOffices[${idx}].originManager.koreanYn`} value={item.originManager.koreanYn}/>}
                                        <li className={item.changeYn ? st.checkLi : null}>
                                            <Check className={st.check} style={{top: '-3px', fontSize:"18px"}} name={`branchOffices[${idx}].changeYn`} value={item.changeYn} checked={item.changeYn === true} onChange={(e) => BranchOfficeChangeVm.setChangeYn(e, idx)}>
                                                <div>{item.address}</div>
                                                <div>
                                                    지배인 : {item.originManager.name ? item.originManager.name : '데이터 없음'}
                                                </div>
                                            </Check>
                                        </li>
                                    </Fragment>
                                )
                            })}
                        </ul>
                        {BranchOfficeChangeVm.changeYnError() && <div><ErrorText>명칭 또는 지배인을 변경할 지점을 선택해 주세요.</ErrorText></div>}
                    </div>
                </ContentBox>
            </Question>
            {BranchOfficeChangeVm.branchOffices.map((item, idx) => (
                <Fragment key={idx}>
                    {item.changeYn &&
                        <Question>
                            <ContentBox>
                                <div className={Qst.spaceBetween}>
                                    <div className={Qst.infoSection}>
                                        <div className={Qst.titleWrap}>
                                            <h2 className={Qst.title}>
                                                {item.address}의 <span className={Qst.bold}>지점명칭을 변경</span>하시나요?
                                            </h2>
                                        </div>
                                        <ul className={Qst.answerList}>
                                            <li><Radio className={Qst.answerLabel} value={true} activeBackground checked={item.changeNameYn === true} onChange={(e) => BranchOfficeChangeVm.setChangeNameYn(e, idx)} name={`branchOffices[${idx}].changeNameYn`}>네. 변경합니다.</Radio></li>
                                            <li><Radio className={Qst.answerLabel} value={false} activeBackground checked={item.changeNameYn === false} onChange={(e) => BranchOfficeChangeVm.setChangeNameYn(e, idx)} name={`branchOffices[${idx}].changeNameYn`}>아니오. 변경하지 않습니다.</Radio></li>
                                        </ul>
                                        <Error name={`branchOffices[${idx}].changeNameYn`} value={item.changeNameYn} errorCase={{required:'지점명칭을 변경하실지 선택해 주세요.'}}/>
                                    </div>
                                </div>
                                {item.changeNameYn &&
                                <>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={Qst.title}>
                                                    <span className={Qst.bold}>새로운 지점 명칭</span>을 입력해 주세요. (ex 은평지점)
                                                </h2>
                                            </div>
                                            <InputText style={{width:'560px'}} name={`branchOffices[${idx}].officeName`} value={item.officeName} onChange={(e) => BranchOfficeChangeVm.setOfficeName(e, idx)} errorIconNon errorText={<Error name={`branchOffices[${idx}].officeName`} value={item.officeName} errorCase={{required:'새로운 지점 명칭을 입력해 주세요.'}}/>}/>
                                        </div>
                                        <div className={Qst.qnaSection}>
                                            <Qna className={Qst.noTitle}>
                                                <div className={Qst.qnaBox}>
                                                    <QText>지점명칭은 어떻게 정하는 편이 좋나요?</QText>
                                                    <div className="answerBox">
                                                        <Answer className={Qst.answer}>지점명칭은 지역명을 넣되, 법인명을 넣지 않는 편이 좋습니다.</Answer>
                                                        <p className={Qst.explain}>
                                                            예를 들어 강남구에 있던 지점을 은평구로 이전하는 경우, 강남지점 → 은평지점으로 명칭을 변경하는 편을 추천드립니다. 다만 지점명칭에 법인명을 넣지 않는 것을 추천드립니다. 추후 법인명을 변경하게 되는 경우, 지점명칭을 모두 변경해야 하는데, 그 경우 비용이 많이 들고 번거로울 수 있기 때문입니다. 
                                                        </p>
                                                    </div>
                                                </div>
                                            </Qna>
                                        </div>
                                    </div>
                                    <CauseDate model={BranchOfficeChangeVm.branchOffices[idx].causeDate} name={`branchOffices[${idx}].causeDate`} title={<span>지점명칭 변경 <span className={Qst.bold}>일자</span>를 입력해 주세요.</span>} />
                                </>
                                }
                                <div className={Qst.spaceBetween}>
                                    <div className={Qst.infoSection}>
                                        <div className={Qst.titleWrap}>
                                            <h2 className={Qst.title}>
                                                {item.address}의 <span className={Qst.bold}>지배인을 추가하거나 변경</span>하시나요?
                                            </h2>
                                            {!item.originManager.name &&
                                                <div className={Qst.titleExplain}>
                                                    일반적으로 지배인을 두지 않는 경우가 더 많습니다.
                                                </div>
                                            }                                           
                                        </div>
                                        <table className={st.infoTable}>
                                            <colgroup>
                                                <col width="155px"/>
                                                <col/>
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <th>지배인</th>
                                                    <td>{item.originManager?.name ? item.originManager.name : '없음'}</td>
                                                </tr>
                                                {item.originManager?.originAddress ? 
                                                    <tr>
                                                        <th>지배인 집주소</th>
                                                        <td>{item.originManager.originAddress}</td>
                                                    </tr>
                                                    :
                                                    <></>
                                                }
                                            </tbody>
                                        </table>
                                        {item.originManager?.name ? 
                                        <>
                                            <ul className={Qst.answerList}>
                                                <li><Radio className={Qst.answerLabel} value="CHANGE_MANAGER" activeBackground checked={item.managerChangeType === "CHANGE_MANAGER"} onChange={(e) => BranchOfficeChangeVm.setManagerChangeType(e, idx)} name={`branchOffices[${idx}].managerChangeType`}>지배인을 새로운 사람으로 변경합니다.</Radio></li>
                                                <li><Radio className={Qst.answerLabel} value="CHANGE_ADDRESS" activeBackground checked={item.managerChangeType === "CHANGE_ADDRESS"} onChange={(e) => BranchOfficeChangeVm.setManagerChangeType(e, idx)} name={`branchOffices[${idx}].managerChangeType`}>기존 지배인의 집주소를 변경합니다.</Radio></li>
                                                <li><Radio className={Qst.answerLabel} value="DELETE" activeBackground checked={item.managerChangeType === "DELETE"} onChange={(e) => BranchOfficeChangeVm.setManagerChangeType(e, idx)} name={`branchOffices[${idx}].managerChangeType`}>기존 지배인을 삭제합니다.</Radio></li>
                                                <li><Radio className={Qst.answerLabel} value="NONE" activeBackground checked={item.managerChangeType === "NONE"} onChange={(e) => BranchOfficeChangeVm.setManagerChangeType(e, idx)} name={`branchOffices[${idx}].managerChangeType`}>지배인을 변경하지 않습니다.</Radio></li>
                                            </ul>
                                            <Error name={`branchOffices[${idx}].managerChangeType`} value={item.managerChangeType} errorCase={{required:'지배인 변경 방법을 선택해 주세요.'}}/>
                                        </>
                                        :
                                        <>
                                            <ul className={Qst.answerList}>
                                                <li><Radio className={Qst.answerLabel} value="ADD_NEW" activeBackground checked={item.managerChangeType === "ADD_NEW"} onChange={(e) => BranchOfficeChangeVm.setManagerChangeType(e, idx)} name={`branchOffices[${idx}].managerChangeType`}>지배인을 추가합니다.</Radio></li>
                                                <li><Radio className={Qst.answerLabel} value="NONE" activeBackground checked={item.managerChangeType === "NONE"} onChange={(e) => BranchOfficeChangeVm.setManagerChangeType(e, idx)} name={`branchOffices[${idx}].managerChangeType`}>지배인을 추가하지 않습니다.</Radio></li>
                                            </ul>
                                            <Error name={`branchOffices[${idx}].managerChangeType`} value={item.managerChangeType} errorCase={{required:'지배인 추가 여부를 선택해 주세요.'}}/>
                                        </>
                                        }
                                    </div>
                                    <div className={Qst.qnaSection}>
                                        <Qna className={Qst.noTitle}>
                                            <div className={Qst.qnaBox}>
                                                <QText>지배인은 무엇인가요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>지배인은 회사대표를 대신해서 지점에 관한 모든 영업행위를 할 수 있는 사람을 말합니다.</Answer>
                                                    <div className={Qst.explain}>
                                                        보통 은행의 경우 지점장을 지배인으로 두고, 필요한 업무를 자율적으로 할 수 있게 합니다.
                                                    </div>
                                                    <LikeBtn className={Qst.likeBtn} href="https://blog.help-me.kr/2018/11/지배인-총정리/" target="_blank">지배인에 대해 더 알아보기</LikeBtn>
                                                </div>
                                            </div>
                                            <div className={Qst.qnaBox}>
                                                <QText>지배인을 꼭 두어야 할까요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>지배인을 두지 않는 경우가 더 많습니다. 지배인은 필수사항이 아닙니다.</Answer>
                                                    <div className={Qst.explain}>
                                                        지배인을 두는 경우, 취임 및 사임시 등기비용이 들고, 지배인의 주소변경시에도 등기를 해야 하기 때문에 지배인을 두면 번거로운 경우가 많습니다.
                                                    </div>
                                                    <div className={Qst.explain}>
                                                        또한 등기된 지배인은 막강한 권한을 행사할 수 있기 때문에, 꼭 필요한 경우가 아니라면 추천드리지 않습니다.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={Qst.qnaBox}>
                                                <QText>지배인을 두는데 비용이 더 드나요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>지배인을 두는데 공과금, 수수료가 발생합니다.</Answer>
                                                    <div className={Qst.explain}>
                                                        지배인을 두려면 등기를 해야 하기 때문에, 국가에 내는 공과금, 수수료가 발생합니다. 견적을 받을 당시에 포함되어 있지 않다면, 헬프미에서 추후 알려드리겠습니다.
                                                    </div>
                                                </div>
                                            </div>
                                        </Qna>
                                    </div>
                                </div>
                                {(item.managerChangeType === "CHANGE_MANAGER" || item.managerChangeType === "ADD_NEW") && 
                                    <>
                                        <div className={Qst.spaceBetween}>
                                            <div className={Qst.infoSection}>
                                                <div className={Qst.titleWrap}>
                                                    <h2 className={clsx(Qst.title, Qst.inside)}>
                                                        신규 지배인님은 <span className={Qst.bold}>어떤 사람</span>인가요?
                                                    </h2>
                                                </div>
                                                <ul className={Qst.answerList}>
                                                    <li><Radio className={Qst.answerLabel} value={true} activeBackground checked={item.newManager.koreanYn === true} onChange={(e) => BranchOfficeChangeVm.setKoreanYn(e, idx)} name={`branchOffices[${idx}].newManager.koreanYn`}>한국인</Radio></li>
                                                    <li><Radio className={Qst.answerLabel} value={false} activeBackground checked={item.newManager.koreanYn === false} onChange={(e) => BranchOfficeChangeVm.setKoreanYn(e, idx)} name={`branchOffices[${idx}].newManager.koreanYn`}>외국인</Radio></li>
                                                </ul>
                                                <Error name={`branchOffices[${idx}].newManager.koreanYn`} value={item.newManager.koreanYn} errorCase={{required:'사람을 선택해 주세요.'}}/>
                                            </div>
                                        </div> 
                                        {item.newManager?.koreanYn && //한국인
                                        <>
                                            <div className={Qst.spaceBetween}>
                                                <div className={Qst.infoSection}>
                                                    <div className={Qst.titleWrap}>
                                                        <h2 className={Qst.title}>
                                                            신규 지배인님의 <span className={Qst.bold}>성함</span>을 입력해 주세요.
                                                        </h2>
                                                    </div>
                                                    <InputText placeholder="ex)홍길동" style={{width:'316px'}} value={item.newManager.name} onChange={(e) => BranchOfficeChangeVm.setNewManagerName(e,idx)} name={`branchOffices[${idx}].newManager.name`} errorText={<Error name={`branchOffices[${idx}].newManager.name`} value={item.newManager.name} errorCase={{required:'성함을 입력해 주세요.'}}/>}/><span className={Qst.nameInput}>님</span>
                                                </div>
                                            </div>
                                            <Kssn model={BranchOfficeChangeVm.branchOffices[idx].newManager} name={`branchOffices[${idx}].newManager`} title={<span><span className={Qst.bold}>{item.newManager.name ? item.newManager.name : '신규 지배인'}님</span>의 <span className={Qst.bold}>주민등록번호</span>를 입력해 주세요.</span>}/>
                                            <Address model={BranchOfficeChangeVm.branchOffices[idx].newManager} name={`branchOffices[${idx}].newManager`} idx={idx} parentVm={BranchOfficeChangeVm} target='newManager' title={<span><span className={Qst.bold}>{item.newManager.name ? item.newManager.name : '신규 지배인'}님</span>의 <span className={Qst.bold}>주민등록등본상 주소</span>를 입력해 주세요.</span>}  />
                                        </>
                                        }
                                        {item.newManager?.koreanYn === false && //외국인
                                        <>
                                            <div className={Qst.spaceBetween}>
                                                <div className={Qst.infoSection}>
                                                    <div className={Qst.titleWrap}>
                                                        <h2 className={clsx(Qst.title, Qst.inside)}>    
                                                            <span className={Qst.bold}>신규 지배인님은 한국에서 거소등록 또는 외국인등록을 한 외국인</span>인가요?
                                                        </h2>
                                                    </div>
                                                    <ul className={Qst.answerList}>
                                                        <li><Radio className={Qst.answerLabel} value={true} activeBackground checked={item.newManager.residenceRegistrationYn === true} onChange={(e) => BranchOfficeChangeVm.setResidenceReportYn(e, idx)} name={`branchOffices[${idx}].newManager.residenceRegistrationYn`}>네</Radio></li>
                                                        <li><Radio className={Qst.answerLabel} checked={false} onClick={() => BranchOfficeChangeVm.openModal()}>아니오</Radio></li>
                                                    </ul>
                                                    <Error name={`branchOffices[${idx}].newManager.residenceRegistrationYn`} value={item.newManager.residenceRegistrationYn} errorCase={{required:'거소등록 또는 외국인등록 여부를 선택해 주세요.'}} />
                                                    </div>
                                                <div className={Qst.qnaSection}>
                                                    <Qna className={Qst.noTitle}>
                                                        <div className={Qst.qnaBox}>
                                                            <QText>거소등록(외국인등록)하지 않은 외국인은 지배인이 되는<br/>데 제약이 있나요?</QText>
                                                            <div className="answerBox">
                                                                <Answer className={Qst.answer}>한국에서 거소등록(외국인등록)하지 않은 외국인의 경우, 서비스제공이 제한됩니다.</Answer>
                                                                <p className={Qst.explain}>
                                                                    거소등록(외국인등록)하지 않은 외국인을 지배인으로 등록하는 경우, 서비스 제공이 어렵습니다. 거소등록(외국인등록)하지 않은 외국인이 지배인으로 등록되려면, 아포스티유 인증 또는 영사관 인증이 필요하고, 절차가 상당히 복잡하기 떄문에, 서비스를 제공하고 있지 않습니다. 양해 부탁드립니다.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Qna>
                                                </div>
                                            </div>
                                            <div className={Qst.spaceBetween}>
                                                <div className={Qst.infoSection}>
                                                    <div className={Qst.titleWrap}>
                                                        <h2 className={clsx(Qst.title, Qst.inside)}>
                                                            <span className={Qst.bold}>신규 지배인님의 국적을 선택</span>해 주세요.
                                                        </h2>
                                                        <div className={Qst.titleExplain}>
                                                            외국인인 경우 국적, 외국법인인 경우 본점이 위치한 나라를 선택해 주세요.
                                                        </div>
                                                    </div>  
                                                    <Select className={Qst.select} style={{width:'142px'}} name={`branchOffices[${idx}].newManager.nationalityType`} value={item.newManager.nationalityType ?? ''} onChange={(e) => BranchOfficeChangeVm.setNationalityType(e,idx)} errorText={<Error name={`branchOffices[${idx}].newManager.nationalityType`} value={item.newManager.nationalityType} errorCase={{required:'국적을 선택해 주세요.'}} style={{width:'157px'}}/>}>
                                                        <option value="">-</option>
                                                        <option value="일본">일본</option>
                                                        <option value="미국">미국</option>
                                                        <option value="영국">영국</option>
                                                        <option value="중국">중국(본토)</option>
                                                        <option value="프랑스">프랑스</option>
                                                        <option value="독일">독일</option>
                                                        <option value="캐나다">캐나다</option>
                                                        <option value="홍콩">홍콩</option>
                                                        <option value="기타">기타</option>
                                                    </Select>
                                                    {item.newManager.nationalityType === "기타" &&
                                                        <div className={clsx(Qst.rowWrap, st.rowWrap)}>
                                                            <InputText className={Qst.inputText} style={{width:'276px'}} explain="기타 국적" name={`branchOffices[${idx}].newManager.nationalityOthers`} value={item.newManager.nationalityOthers} onChange={(e) => BranchOfficeChangeVm.setNationalityOthers(e,idx)} errorText={<Error name={`branchOffices[${idx}].newManager.nationalityOthers`} value={item.newManager.nationalityOthers} errorCase={{required:'기타국적을 입력해 주세요.'}}/>}/>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className={Qst.spaceBetween}>
                                                <div className={Qst.infoSection}>
                                                    <div className={Qst.titleWrap}>
                                                        <h2 className={clsx(Qst.title, Qst.inside)}>
                                                            <span className={Qst.bold}>신규 지배인님의 이름을 로마자와 한글로 입력</span>해 주세요.
                                                        </h2>
                                                        <div className={Qst.titleExplain}>
                                                            외국인의 정식 명칭을 로마자(영문)와 한글 발음으로 입력해 주세요.
                                                        </div>
                                                        <div className={Qst.titleExTextBox}>
                                                            <TextBox title="로마자(영문)" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>Jane Austen</span></TextBox>
                                                            <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>제인 오스틴</span></TextBox>
                                                        </div>
                                                        <div className={Qst.titleExplain}>
                                                            영문이 아닌 외국성함은 로마자(영문)으로 변환하여 한글 발음으로 입력해 주세요.
                                                        </div>
                                                        <div className={Qst.titleExTextBox}>
                                                            <TextBox title="로마자(영문)로 변환" titleWidth="145px" className={Qst.nameTextBox} style={{width:'171px', height:'40px', padding:'9px 20px'}}>
                                                            <span className={Qst.text}>高竹</span><Icon icon="arrow" className={Qst.arrow} /><span className={Qst.text}>Takamura</span>
                                                            </TextBox>
                                                            <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'134px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>타카무라</span></TextBox>
                                                        </div>
                                                    </div>
                                                    <div className={Qst.rowWrap}>
                                                        <InputText className={Qst.inputText} style={{width:'276px'}} explain="로마자(영문) 표기" name={`branchOffices[${idx}].newManager.englishName`} value={item.newManager.englishName} onChange={(e) => BranchOfficeChangeVm.setEnglishName(e,idx)} errorText={<Error name={`branchOffices[${idx}].newManager.englishName`} value={item.newManager.englishName} errorCase={{
                                                            required: '이름을 로마자로 입력해 주세요.',
                                                            pattern: {
                                                            value: regExp.onlyEnglish().test(item.newManager.englishName),
                                                            message: '로마자로 입력해 주세요.'
                                                            }
                                                        }}/>} 
                                                        />
                                                        <InputText className={Qst.inputText} style={{width:'276px'}} explain="한글 발음 표기" name={`branchOffices[${idx}].newManager.name`} value={item.newManager.name} onChange={(e) => BranchOfficeChangeVm.setNewManagerName(e,idx)} errorText={<Error name={`branchOffices[${idx}].newManager.name`} value={item.newManager.name} errorCase={{
                                                            required: '이름을 한글로 입력해 주세요.',
                                                            pattern: {
                                                            value: regExp.onlyKorean().test(item.newManager.name),
                                                            message: '한글로 입력해 주세요.'
                                                            }
                                                        }}/>} 
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={Qst.spaceBetween}>
                                                <div className={Qst.infoSection}>
                                                    <div className={Qst.titleWrap}>
                                                        <h2 className={clsx(Qst.title, Qst.inside)}>
                                                            <span className={Qst.bold}>{item.newManager.name ? item.newManager.name : '신규 지배인'}님의 여권에 기재된 생년월일을 입력</span>해 주세요.
                                                        </h2>
                                                    </div>
                                                    <div>
                                                        <div className={Qst.birthWrap}>
                                                            <InputText style={{width:'130px'}} placeholder="0000" name={`branchOffices[${idx}].newManager.birth.year`} value={item.newManager.birth.year} onChange={(e) => BranchOfficeChangeVm.setNewManagerBirthYear(e, idx)} maxLength="4" />
                                                            <span className={Qst.birthText}>년</span>
                                                        </div>
                                                        <div className={Qst.birthWrap}>
                                                            <InputText style={{width:'108px'}} placeholder="00" name={`branchOffices[${idx}].newManager.birth.month`} value={item.newManager.birth.month} onChange={(e) => BranchOfficeChangeVm.setNewManagerBirthMonth(e, idx)} maxLength="2" onBlur={(e) => BranchOfficeChangeVm.newManagerBirthDateBlur(e, idx, 'month')} />
                                                            <span className={Qst.birthText}>월</span>
                                                        </div>
                                                        <div className={Qst.birthWrap}>
                                                            <InputText style={{width:'108px'}} placeholder="00" name={`branchOffices[${idx}].newManager.birth.day`} value={item.newManager.birth.day} onChange={(e) => BranchOfficeChangeVm.setNewManagerBirthDay(e, idx)} maxLength="2" onBlur={(e) => BranchOfficeChangeVm.newManagerBirthDateBlur(e, idx, 'day')} />
                                                            <span className={Qst.birthText}>일</span>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop:'-27px'}}>
                                                        <Error name={`branchOffices[${idx}].newManager.birth.year`} value={item.newManager.birth.year} 
                                                        errorCase={{
                                                            required:'연도를 입력해 주세요.',
                                                            pattern: {
                                                            value: regExp.year().test(item.newManager.birth.year),
                                                            message: '올바른 연도를 입력해 주세요'               
                                                            }
                                                        }}
                                                        />
                                                        <Error name={`branchOffices[${idx}].newManager.birth.month`} value={item.newManager.birth.month} 
                                                        errorCase={{
                                                            required: BranchOfficeChangeVm.displayNewManagerBirthErrorMessage(idx, 'month') ? '월을 입력해 주세요.' : null,
                                                            pattern: {
                                                            value: BranchOfficeChangeVm.displayNewManagerBirthErrorMessage(idx, 'month') ? regExp.month().test(item.newManager.birth.month) : null,
                                                            message: '올바른 월을 입력해 주세요'               
                                                            }
                                                        }}
                                                        />
                                                        <Error name={`branchOffices[${idx}].newManager.birth.day`} value={item.newManager.birth.day} 
                                                        errorCase={{
                                                            required: BranchOfficeChangeVm.displayNewManagerBirthErrorMessage(idx, 'day') ? '일(날짜)을 입력해 주세요.' : null,
                                                            pattern: {
                                                            value: BranchOfficeChangeVm.displayNewManagerBirthErrorMessage(idx, 'day') ? regExp.day().test(item.newManager.birth.day) : null,
                                                            message: '올바른 일(날짜)을 입력해 주세요.'           
                                                            }
                                                        }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <Address model={BranchOfficeChangeVm.branchOffices[idx].newManager} name={`branchOffices[${idx}].newManager`} idx={idx} parentVm={BranchOfficeChangeVm} target='newManager' title={<span><span className={Qst.bold}>{item.newManager.name ? item.newManager.name : '신규 지배인'}님</span>의 <span className={Qst.bold}>거소신고(외국인등록) 사실증명상 도로명 주소</span>를 입력해 주세요.</span>}  />
                                        </>
                                        }
                                        {(item.managerChangeType === "CHANGE_MANAGER" && item.originManager?.koreanYn)&&
                                            <Kssn model={BranchOfficeChangeVm.branchOffices[idx].originManager} name={`branchOffices[${idx}].originManager`} title={<span>기존 지배인 <span className={Qst.bold}>{item.originManager.name}님</span>의 <span className={Qst.bold}>주민등록번호</span>을 입력해 주세요.</span>} />
                                        }
                                        {item.newManager?.koreanYn !== null && <CauseDate model={BranchOfficeChangeVm.branchOffices[idx].newManager.causeDate} name={`branchOffices[${idx}].newManager.causeDate`} title={<span>지배인 변경 <span className={Qst.bold}>일자</span>를 입력해 주세요.</span>} />}
                                    </>                                    
                                }
                                {item.managerChangeType === "CHANGE_ADDRESS" &&
                                    <>
                                        {item.originManager.koreanYn && //한국인
                                        <>
                                            <Kssn model={BranchOfficeChangeVm.branchOffices[idx].originManager} name={`branchOffices[${idx}].originManager`} title={<span><span className={Qst.bold}>{item.originManager.name}님</span>의 <span className={Qst.bold}>주민등록번호</span>을 입력해 주세요.</span>} />
                                            <Address model={BranchOfficeChangeVm.branchOffices[idx].originManager} name={`branchOffices[${idx}].originManager`} idx={idx} parentVm={BranchOfficeChangeVm} target='originManager' title={<span><span className={Qst.bold}>{item.originManager.name}님</span>의 <span className={Qst.bold}>주민등록등본상 주소</span>를 입력해 주세요.</span>} reportDate/>
                                        </>
                                        } 
                                        {item.originManager.koreanYn === false && //외국인
                                        <>
                                            <Address model={BranchOfficeChangeVm.branchOffices[idx].originManager} name={`branchOffices[${idx}].originManager`} idx={idx} parentVm={BranchOfficeChangeVm} target='originManager' title={<span><span className={Qst.bold}>{item.originManager.name}님</span>의 <span className={Qst.bold}>거소신고(외국인등록) 사실증명상 도로명 주소</span>를 입력해 주세요.</span>} reportDate/>
                                        </>    
                                        }
                                    </>
                                }
                                {item.managerChangeType === "DELETE" &&
                                <>
                                    {item.originManager.koreanYn &&
                                        <Kssn model={BranchOfficeChangeVm.branchOffices[idx].originManager} name={`branchOffices[${idx}].originManager`} title={<span><span className={Qst.bold}>{item.originManager.name}님</span>의 <span className={Qst.bold}>주민등록번호</span>을 입력해 주세요.</span>}/>
                                    }
                                    <CauseDate model={BranchOfficeChangeVm.branchOffices[idx].originManager.causeDate} name={`branchOffices[${idx}].originManager.causeDate`}title={<span>기존 지배인을 삭제할 <span className={Qst.bold}>일자</span>를 입력해 주세요.</span>} />
                                </>
                                }
                            </ContentBox>
                        </Question>
                    }
                </Fragment>
            ))}
        </form>
    ));
}
export default BranchOfficeChange;