import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import Ast from '../../AdminFinalCheckView.module.scss';
import clsx from 'clsx';
import st from './StockCompanySealStep.module.scss';
import copyText from '@common/module/copyText';
function StockCompanySealStep(props) {
    return useObserver(() => (
        <>
            <div className={clsx(Ast.row)}> 
                <div className={Ast.dName}>소집통지 방법</div>
                <div className={Ast.data}>
                    <span>{props.step.musterNoticeMethod}</span>
                </div>
            </div>
            {props.step.stockholderMeetingMusterNoticeType !== "FOUR" && 
                <>
                    <div className={clsx(Ast.row)}> 
                        <div className={Ast.dName}>주주총회일</div>
                        <div className={Ast.data}>
                            <span>{props.step.stockholderMeetingDate}</span>
                        </div>
                    </div>
                    <div className={clsx(Ast.row)}> 
                        <div className={Ast.dName}>주주총회 개최<br/>장소</div>
                        <div className={Ast.data}>
                            <span>{props.step.stockholderMeetingAtHeadOfficeYn ? "본점" : "본점에 가까운 장소"}</span>
                            <span className={st.subContent}>주소 : {props.step.stockholderMeetingPlaceAddress}</span>
                        </div>
                    </div>
                    <div className={clsx(Ast.row)}> 
                        <div className={Ast.dName}>온라인/우편</div>
                        <div className={Ast.data}>
                            <span>{props.step.musterNoticeSendMethod}</span>
                        </div>
                    </div>
                </>
            }
            {(props.step.stockholderMeetingMusterNoticeType === "ONE" || props.step.stockholderMeetingMusterNoticeType === "TWO") && 
                <>
                    <div className={clsx(Ast.row)}> 
                        <div className={Ast.dName}>소집통지문<br/>업로드</div>
                        <div className={Ast.data}>
                            {props.step.musterNoticeFiles.map((file, idx) => (
                                <a className={st.download} href={file.url} key={idx} download>{file.fileName}</a>
                            ))}
                        </div>
                    </div>
                </>
            }
            {props.step?.files && 
                <>
                    <div className={clsx(Ast.row)}> 
                        <div className={Ast.dName}>펀드투자조합<br/>등록원부,고유번호증</div>
                        <div className={Ast.data}>
                            {props.step.files.map((file, idx) => (
                                <a className={st.download} href={file.url} key={idx} download>{file.fileName}</a>
                            ))}
                        </div>
                    </div>
                </>
            }
            {props.step.stockholderMeetingMusterNoticeType === "THREE" && 
                <>
                    <div className={clsx(Ast.row)}> 
                        <div className={Ast.dName}>소집통지예정일</div>
                        <div className={Ast.data}>
                            <span>{props.step.musterNoticeDate}</span>
                        </div>
                    </div>
                </>
            }
            {props.step.stockholderMeetingMusterNoticeType === "FOUR" && 
                <>
                    <div className={clsx(Ast.row)}> 
                        <div className={Ast.dName}>참석, 인감증명서<br/>제공할 주주</div>
                        <div className={Ast.data}>
                            {props.step.stockholders.map((stockholder, idx) => (
                                <span className={st.person} key={idx}>{idx+1}. {stockholder.name} ({stockholder.sealProviderType})</span>
                            ))}
                        </div>
                    </div>
                </>
            }
            {props.step.stockholderMeetingMusterNoticeType !== "FOUR" && 
                <>
                    <div className={clsx(Ast.row)}> 
                        <div className={Ast.dName}>참석, 인감증명서<br/>제공할 주주</div>
                        <div className={Ast.data}>
                            <table className={st.table}>
                                <colgroup>
                                    <col/>
                                    <col width="220px"/>
                                    <col width="110px"/>
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th style={{textAlign:"left"}}>성함</th>
                                        <th style={{textAlign:"left"}}>구별</th>
                                        <th style={{textAlign:"center"}}>인감증명서 제공여부</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.step.stockholders.map((stockholder, idx) => (
                                        <tr key={idx}>
                                            <td style={{textAlign:"left"}}>{stockholder.name}</td>
                                            <td style={{textAlign:"left"}}>{stockholder.sealProviderType}</td>
                                            <td style={{textAlign:"center"}}>{stockholder.sealProvidingYn ? "O" : "X"}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            }
        </>
    ));
}
export default StockCompanySealStep;