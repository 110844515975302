import { useObserver } from 'mobx-react';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import clsx from 'clsx';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import SquareBtn from '@comComponents/atoms/Button/SquareBtn/SquareBtn';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import regExp from '@common/module/regExp';
import LiquidationDateVm from './LiquidationDateVm';

function LiquidationDate(props) {
    return useObserver(() => (      
        <div className={Qst.spaceBetween} style={props.style ? props.style : {marginTop:'70px'}}>
            <div className={Qst.infoSection}>
                <div className={Qst.titleWrap}>
                    <h2 className={Qst.title}>
                        원하시는 <span className={Qst.bold}>해산일자</span>를 입력해 주세요.
                    </h2>
                </div>
                <Badge className={Qst.registrationBadge} title="예시" type="example" border={false}>
                    <div className={Qst.registartionBadgeBox}>
                        <img className={Qst.registrationBadgeImg} src="/images/registration_date.png" alt="일자 입력 예시" />
                        <div className={Qst.registrationBadgeTextBox}>
                            <div className={Qst.registrationBadgeText}>
                                <div className={Qst.text}>
                                    <span className={Qst.boldBlue}>안정적으로 등기신청하기 위해 해산 날짜는 오늘(질문지 제출일)을 기준으로,<br/>1주 전 ~ 3주 이내로 입력해 주세요.</span>
                                </div>
                                <div className={Qst.text}>
                                    <span className={Qst.bold}>예시) 오늘(질문 제출일)이 3월 23일</span>이라면, <span className={Qst.bold}>안정적으로 등기신청 가능한 해산 날짜는 3월 16일 ~ 4월 12일</span> 입니다.
                                </div>
                            </div>
                            <div className={Qst.registrationBadgeTextExplain}>
                                특별한 사정이 없다면, <span className={Qst.bold}>오늘 날짜(질문 제출일)로 입력하는 것을 추천</span>드립니다.<br/>
                                입력한 해산 날짜로 부터 2주 이내 접수해야 과태료가 부가되지 않습니다.
                            </div>
                        </div>     
                    </div>                 
                </Badge>
                <div>
                    <div className={Qst.birthWrap}>
                        <InputText style={{width:'130px'}} placeholder="0000" name='liquidationDate.year' value={props.parentVm.liquidationDate?.year} onChange={(e) => props.parentVm.setLiquidationDateYear(e)} maxLength="4" />
                        <span className={Qst.birthText}>년</span>
                    </div>
                    <div className={Qst.birthWrap}>
                        <InputText style={{width:'108px'}} placeholder="00" name='liquidationDate.month' value={props.parentVm.liquidationDate?.month} onChange={(e) => props.parentVm.setLiquidationDateMonth(e)} maxLength="2" onBlur={(e) => props.parentVm.liquidationDateMonthBlur(e)} />
                        <span className={Qst.birthText}>월</span>
                    </div>
                    <div className={Qst.birthWrap}>
                        <InputText style={{width:'108px'}} placeholder="00" name='liquidationDate.day' value={props.parentVm.liquidationDate?.day} onChange={(e) => props.parentVm.setLiquidationDateDay(e)} maxLength="2" onBlur={(e) => props.parentVm.liquidationDateDayBlur(e)} />
                        <span className={Qst.birthText}>일</span>
                    </div>
                </div>
                <div style={{marginTop:'-27px'}}>
                    <Error name='liquidationDate.year' value={props.parentVm.liquidationDate?.year} 
                    errorCase={{
                    required:'연도를 입력해 주세요.',
                    pattern: {
                        value: regExp.year().test(props.parentVm.liquidationDate?.year),
                        message: '올바른 연도를 입력해 주세요'               
                    }
                    }}
                    />
                    <Error name='liquidationDate.month' value={props.parentVm.liquidationDate?.month} 
                    errorCase={{
                    required: props.parentVm.displayLiquidationDateErrorMessage('month') ? '월을 입력해 주세요.' : null,
                    pattern: {
                        value: props.parentVm.displayLiquidationDateErrorMessage('month') ? regExp.month().test(props.parentVm.liquidationDate?.month) : null,
                        message: '올바른 월을 입력해 주세요'               
                    }
                    }}
                    />
                    <Error name='liquidationDate.day' value={props.parentVm.liquidationDate?.day} 
                    errorCase={{
                    required: props.parentVm.displayLiquidationDateErrorMessage('day') ? '일(날짜)을 입력해 주세요.' : null,
                    pattern: {
                        value: props.parentVm.displayLiquidationDateErrorMessage('day') ? regExp.day().test(props.parentVm.liquidationDate?.day) : null,
                        message: '올바른 일(날짜)을 입력해 주세요.'           
                    }
                    }}
                    />
                </div>
            </div>
        </div>
    ));
}

export default LiquidationDate;