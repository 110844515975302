import { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import KoreanCompanyVm from './KoreanCompanyVm';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from '../ConvertibleBondAcquirer.module.scss';
import clsx from 'clsx';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import CloseBtn from '@comComponents/atoms/Button/CloseBtn/CloseBtn';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import Select from '@comComponents/atoms/Input/Select/Select';
import Icon from '@comComponents/atoms/Icon/Icon';

function KoreanCompany(props) {
    return useObserver(() => (
        <>
            <div className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    <div className={Qst.titleWrap}>
                        <h2 className={clsx(Qst.title, Qst.inside)}>
                            <span className={Qst.bold}>회사 이름을 입력</span>해 주세요.
                        </h2>
                        <div className={Qst.titleExplain}>
                            정식명칭을 입력해 주세요. ex) 주식회사 신한은행, 현대산업개발 유한회사
                        </div>
                    </div>
                    <div className={Qst.rowWrap}>
                        <InputText style={{width:'316px'}} name={`convertibleBondAcquirerInfos[${props.idx}].koreanName`} value={props.item.koreanName} onChange={(e) => KoreanCompanyVm.setKoreanName(e,props.idx)} errorText={<Error name={`convertibleBondAcquirerInfos[${props.idx}].koreanName`} value={props.item.koreanName} errorCase={{required:'회사 이름을 입력해 주세요.'}} />}/>
                    </div>
                </div>
            </div>
            <div className={Qst.spaceBetween} style={{paddingBottom:'30px'}}>
                <div className={Qst.infoSection}>
                    <div className={Qst.titleWrap}>
                        <h2 className={clsx(Qst.title, Qst.inside)}>
                            <span className={Qst.bold}>{props.item.koreanName? props.item.koreanName : '회사'}의 등기부 등본상 도로명주소를 입력</span>해 주세요.
                        </h2>
                    </div>
                    <InputText style={{width:'560px'}} value={props.item.koreanAddress} onChange={(e) => KoreanCompanyVm.setKoreanAddress(e,props.idx)} name={`convertibleBondAcquirerInfos[${props.idx}].koreanAddress`} errorText={<Error name={`convertibleBondAcquirerInfos[${props.idx}].koreanAddress`} value={props.item.koreanAddress} errorCase={{required:'주소를 입력해 주세요.'}}/>}/>
                </div>
            </div>
            <div className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    <div className={Qst.titleWrap}>
                        <h2 className={clsx(Qst.title, Qst.inside)}>
                            <span className={Qst.bold}>{props.item.koreanName? props.item.koreanName : '회사'}의 등기부상 대표자를 입력</span>해 주세요.
                        </h2>
                        <div className={Qst.titleExplain}>
                            대표이사가 2명 이사인 경우, 모두 입력해 주세요.
                        </div>
                    </div>
                    <Badge className={st.CompanyExecutiveBadge} title="예시" type="example" border={false}>
                        <img className={st.CompanyExecutiveBadgeImg} src="/images/executive_example.png"
                        alt="회사 등기부상 대표자 예시" />
                    </Badge>
                    {props.item.koreanCompanyRepresentatives?.map((item, idx2) => {
                        return (
                        <div className={Qst.rowWrap} key={idx2+props.item.koreanCompanyRepresentatives}>
                            <input type="hidden" name={`convertibleBondAcquirerInfos[${props.idx}].koreanCompanyRepresentatives[${idx2}].id`} value={item?.id || ''}/>
                            <Select className={clsx(Qst.select, st.select)} style={{width:'278px'}} name={`convertibleBondAcquirerInfos[${props.idx}].koreanCompanyRepresentatives[${idx2}].position`} value={item?.position ?? ''} explain={idx2 === 0 ? "직책" : null} onChange={(e) => KoreanCompanyVm.koreanCompanyRepresentatives_position(e,props.idx)} errorText={<Error name={`convertibleBondAcquirerInfos[${props.idx}].koreanCompanyRepresentatives[${idx2}].position`} value={item?.position} errorCase={{required:'직책을 선택해 주세요.'}}/>} 
                            disabled={KoreanCompanyVm.disabledRepresentativePositionYn(props.idx, idx2)}>
                                <option value="">-</option> 
                                <option value="대표이사">대표이사</option>
                                <option value="공동대표이사">공동대표이사</option>
                                <option value="사내이사">사내이사</option>
                                <option value="대표업무집행자">대표업무집행자</option>
                                <option value="공동대표업무집행자">공동대표업무집행자</option>
                                <option value="업무집행자">업무집행자</option>
                                <option value="업무집행조합원">업무집행조합원</option>
                                <option value="공동업무집행조합원">공동업무집행조합원</option>
                                <option value="대표자">대표자</option>
                                <option value="공동대표자">공동대표자</option>
                            </Select>
                            <div className={st.compoWrap}>
                                <InputText className={clsx(Qst.inputText,st.inputText)} style={{width:'278px'}} explain={idx2 === 0 ? "성함" : null} 
                                name={`convertibleBondAcquirerInfos[${props.idx}].koreanCompanyRepresentatives[${idx2}].name`} 
                                value={item?.name} 
                                onChange={(e) => KoreanCompanyVm.koreanCompanyRepresentatives_name(e,props.idx,idx2)} 
                                errorText={<Error name={`convertibleBondAcquirerInfos[${props.idx}].koreanCompanyRepresentatives[${idx2}].name`} value={item?.name} errorCase={{required:'성함을 입력해 주세요.'}}/>}/>
                                {idx2 !== 0  &&
                                    <CloseBtn className={st.closeBtn} onClick={() => KoreanCompanyVm.setKoreanCompanyRepresentativesCloseBtn(props.idx,idx2)} />
                                }
                            </div>
                        </div>  
                        )
                    })}
                    <div className={clsx(Qst.rowWrap, st.representativeAddRowWrap)}>
                        <button type="button" className={st.representativeAddBtn} onClick={() => KoreanCompanyVm.addKoreanCompanyRepresentatives(props.idx)} >대표자 추가하기<Icon icon="addGray" className={st.addGray} /></button>
                    </div>
                </div>
            </div>
        </>
    ));
}
export default KoreanCompany;