import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { Fragment, useEffect } from 'react';
import LiquidationVm from './LiquidationVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './Liquidation.module.scss'
import Kssn from '../Kssn/Kssn';
import RepresentativeAddressCheck from '../RepresentativeAddressCheck/RepresentativeAddressCheck';
import Address from '../Address/Address';
import LiquidationDate from '../LiquidationDate/LiquidationDate';
import SearchAddress from '@comComponents/atoms/SearchAddress/SearchAddress';
import TextBtn from '@comComponents/atoms/Button/TextBtn/TextBtn';
import Check from '@comComponents/atoms/Input/Check/Check';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import regExp from '@common/module/regExp';
import Select from '@comComponents/atoms/Input/Select/Select';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import Icon from '@comComponents/atoms/Icon/Icon';

function Liquidation() {
    return useObserver(() => (
        <form onSubmit={(e) => LiquidationVm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            {LiquidationVm.liquidationStepType === 'CASE2' &&
                <Question>
                    <ContentBox>
                        <div className={Qst.spaceBetween}>
                            <div className={Qst.infoSection}>
                                <div className={Qst.titleWrap}>
                                    <h2 className={Qst.title}>
                                        아래는 <span className={Qst.bold}>현재 등기부상 이사님 목록</span>입니다. 어떤 분이 <span className={Qst.bold}>청산인</span>이 될지 <span className={Qst.bold}>선택</span>해 주세요.
                                    </h2>
                                    <div className={Qst.titleExplain}>
                                        일반적으로 대표님 중에 1분이 청산인이 됩니다.
                                    </div>
                                </div>
                                <div className={st.directorBox}>
                                    <div className={st.unCheck}><TextBtn className={st.unCheckBtn} onClick={() => {LiquidationVm.unChecked()}}>선택해제</TextBtn></div>
                                    <ul className={st.directorList}>
                                        {LiquidationVm.directors.map((item, idx) => {
                                            return (
                                                <li key={idx}>
                                                    <Check name={`directors[${idx}].selectedYn`} className={st.director} style={{fontSize:'18px', letterSpacing:'-0.54px', whiteSpace:'pre'}}  value={item.selectedYn} checked={item.selectedYn} onChange={(e) => LiquidationVm.setSelectedYn(e, idx)} activeBackground>
                                                        {item.koreanName} {item.position}님
                                                    </Check>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    {LiquidationVm.selectYnError() && <div><ErrorText>청산인이 되실 분을 선택해 주세요.</ErrorText></div>}
                                </div>
                            </div>
                        </div>
                    </ContentBox>
                </Question>
            }
            {LiquidationVm.directors.map((item, idx) => (
                <Fragment key={idx}>
                    {LiquidationVm.liquidationStepType === 'CASE1' &&
                        <Question>
                            <ContentBox>
                                <input type="hidden" name={`directors[${idx}].id`} value={item.id} />
                                <Kssn name='directors' idx={idx} item={item} parentVm={LiquidationVm}/>
                                <RepresentativeAddressCheck name='directors' idx={idx} item={item} parentVm={LiquidationVm}/>
                                {item.hasSameAddressYn === false && 
                                    <Address name='directors' idx={idx} item={item} parentVm={LiquidationVm} causeDate/>
                                }
                                <LiquidationDate parentVm={LiquidationVm}/>
                            </ContentBox>
                        </Question>
                    }
                    {LiquidationVm.liquidationStepType === 'CASE2' &&
                        <>
                            {LiquidationVm.displayCase2Content() &&
                                <Question>
                                    <ContentBox>
                                        <input type="hidden" name={`directors[${idx}].id`} value={item.id} />
                                        {item.foreignerYn === false && <Kssn name='directors' idx={idx} item={item} parentVm={LiquidationVm}/>}
                                        {(item.selectedYn && item.originAddress) && 
                                            <RepresentativeAddressCheck name='directors' idx={idx} item={item} parentVm={LiquidationVm}/>
                                        }
                                        {(item.selectedYn && item.hasSameAddressYn === false)  && //대표
                                            <Address name='directors' idx={idx} item={item} parentVm={LiquidationVm} causeDate/>
                                        }
                                        {(item.selectedYn && !item.originAddress) && //이사
                                            <Address name='directors' idx={idx} item={item} parentVm={LiquidationVm} />
                                        }
                                    </ContentBox>
                                </Question>
                            }
                        </>
                    }
                    {LiquidationVm.liquidationStepType === 'CASE3' &&
                        <Question>
                            <ContentBox>
                                <input type="hidden" name={`directors[${idx}].id`} value={item.id} />
                                <div className={Qst.spaceBetween}>
                                    <div className={Qst.infoSection}>
                                        <div className={Qst.titleWrap}>
                                            <h2 className={Qst.title}>
                                                <span className={Qst.bold}>청산인님</span>이 될 분은 <span className={Qst.bold}>어떤 사람</span>인가요?
                                            </h2>
                                        </div>
                                        <ul className={Qst.answerList}>
                                            <li><Radio className={Qst.answerLabel} value={false} activeBackground checked={item.foreignerYn === false} onChange={(e) => LiquidationVm.setForeignerYn(e, idx)} name={`directors[${idx}].foreignerYn`}>한국인</Radio></li>
                                            <li><Radio className={Qst.answerLabel} value={true} activeBackground checked={item.foreignerYn === true} onChange={(e) => LiquidationVm.setForeignerYn(e, idx)} name={`directors[${idx}].foreignerYn`}>외국인</Radio></li>
                                        </ul>
                                        <Error name={`directors[${idx}].foreignerYn`} value={item.foreignerYn} errorCase={{required:'청산인이 될 분을 선택해 주세요.'}}/>
                                    </div>
                                </div>
                                {item.foreignerYn === false && //한국인
                                <>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={Qst.title}>
                                                    <span className={Qst.bold}>청산인님</span>의 <span className={Qst.bold}>성함</span>을 입력해 주세요.
                                                </h2>
                                            </div>
                                            <InputText placeholder="ex)홍길동" style={{width:'316px'}} value={item.koreanName} onChange={(e) => LiquidationVm.setKoreanName(e,idx)} name={`directors[${idx}].koreanName`} errorText={<Error name={`directors[${idx}].koreanName`} value={item.koreanName} errorCase={{required:'성함을 입력해 주세요.'}}/>}/><span className={Qst.nameInput}>님</span>
                                        </div>
                                    </div>
                                    <Kssn name='directors' idx={idx} item={item} parentVm={LiquidationVm}/>
                                    <Address name='directors' idx={idx} item={item} parentVm={LiquidationVm} />
                                </>
                                }
                                {item.foreignerYn && //외국인
                                <>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={Qst.title}>
                                                    <span className={Qst.bold}>청산인님은 한국에 거소등록 또는 외국인등록을 한 외국인</span> 인가요?
                                                </h2>
                                            </div>
                                            <ul className={Qst.answerList}>
                                                <li><Radio className={Qst.answerLabel} value={true} activeBackground checked={item.residenceReportYn === true} onChange={(e) => LiquidationVm.setResidenceReportYn(e, idx)} name={`directors[${idx}].residenceReportYn`}>네</Radio></li>
                                                <li><Radio className={Qst.answerLabel} checked={false} onClick={() => LiquidationVm.openModal()}>아니오</Radio></li>
                                            </ul>
                                            <Error name={`directors[${idx}].residenceReportYn`} value={item.residenceReportYn} errorCase={{required:'거소등록 또는 외국인등록 여부를 선택해 주세요.'}} />
                                        </div>
                                    </div>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={Qst.title}>
                                                    <span className={Qst.bold}>청산인님의 국적을 선택</span>해 주세요.
                                                </h2>
                                            </div>
                                            <Select className={Qst.select} style={{width:'142px'}} name={`directors[${idx}].nationalityType`} value={item.nationalityType ?? ''} onChange={(e) => LiquidationVm.setNationalityType(e,idx)} errorText={<Error name={`directors[${idx}].nationalityType`} value={item.nationalityType} errorCase={{required:'국적을 선택해 주세요.'}} style={{width:'157px'}}/>}>
                                                <option value="">-</option>
                                                <option value="일본">일본</option>
                                                <option value="미국">미국</option>
                                                <option value="영국">영국</option>
                                                <option value="중국">중국(본토)</option>
                                                <option value="프랑스">프랑스</option>
                                                <option value="독일">독일</option>
                                                <option value="캐나다">캐나다</option>
                                                <option value="홍콩">홍콩</option>
                                                <option value="기타">기타</option>
                                            </Select>
                                            {item.nationalityType === "기타" &&
                                                <div className={Qst.rowWrap}>
                                                    <InputText className={Qst.inputText} style={{width:'276px'}} explain="기타 국적" name={`directors[${idx}].nationalityOthers`} value={item.nationalityOthers} onChange={(e) => LiquidationVm.setNationalityOthers(e,idx)} errorText={<Error name={`directors[${idx}].nationalityOthers`} value={item.nationalityOthers} errorCase={{required:'기타국적을 입력해 주세요.'}}/>}/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={Qst.title}>
                                                    <span className={Qst.bold}>청산인님의 이름을 로마자와 한글로 입력</span>해 주세요.
                                                </h2>
                                                <div className={Qst.titleExplain}>
                                                    외국인의 정식 명칭을 로마자(영문)와 한글 발음으로 입력해 주세요.
                                                </div>
                                                <div className={Qst.titleExTextBox}>
                                                    <TextBox title="로마자(영문)" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>Jane Austen</span></TextBox>
                                                    <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>제인 오스틴</span></TextBox>
                                                </div>
                                                <div className={Qst.titleExplain}>
                                                    영문이 아닌 외국성함은 로마자(영문)으로 변환하여 한글 발음으로 입력해 주세요.
                                                </div>
                                                <div className={Qst.titleExTextBox}>
                                                    <TextBox title="로마자(영문)로 변환" titleWidth="145px" className={Qst.nameTextBox} style={{width:'171px', height:'40px', padding:'9px 20px'}}>
                                                    <span className={Qst.text}>高竹</span><Icon icon="arrow" className={Qst.arrow} /><span className={Qst.text}>Takamura</span>
                                                    </TextBox>
                                                    <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'134px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>타카무라</span></TextBox>
                                                </div>
                                            </div>
                                            <div className={Qst.rowWrap}>
                                                <InputText className={Qst.inputText} style={{width:'276px'}} explain="로마자(영문) 표기" name={`directors[${idx}].englishName`} value={item.englishName} onChange={(e) => LiquidationVm.setEnglishName(e,idx)} errorText={<Error name={`directors[${idx}].englishName`} value={item.englishName} errorCase={{
                                                    required: '이름을 로마자로 입력해 주세요.',
                                                    pattern: {
                                                    value: regExp.onlyEnglish().test(item.englishName),
                                                    message: '로마자로 입력해 주세요.'
                                                    }
                                                }}/>} 
                                                />
                                                <InputText className={Qst.inputText} style={{width:'276px'}} explain="한글 발음 표기" name={`directors[${idx}].koreanName`} value={item.koreanName} onChange={(e) => LiquidationVm.setKoreanName(e,idx)} errorText={<Error name={`directors[${idx}].koreanName`} value={item.koreanName} errorCase={{
                                                    required: '이름을 한글로 입력해 주세요.',
                                                    pattern: {
                                                    value: regExp.onlyKorean().test(item.koreanName),
                                                    message: '한글로 입력해 주세요.'
                                                    }
                                                }}/>} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={Qst.title}>
                                                    <span className={Qst.bold}>{item.koreanName ? item.koreanName : '청산인'}님의 여권에 기재된 생년월일을 선택</span>해 주세요.
                                                </h2>
                                            </div>
                                            <div>
                                                <div className={Qst.birthWrap}>
                                                    <InputText style={{width:'130px'}} placeholder="0000" name={`directors[${idx}].birth.year`} value={item.birth.year} onChange={(e) => LiquidationVm.setYear(e,idx)} maxLength="4" />
                                                    <span className={Qst.birthText}>년</span>
                                                </div>
                                                <div className={Qst.birthWrap}>
                                                    <InputText style={{width:'108px'}} placeholder="00" name={`directors[${idx}].birth.month`} value={item.birth.month} onChange={(e) => LiquidationVm.setMonth(e,idx)} maxLength="2" onBlur={(e) => LiquidationVm.monthBlur(e,idx)} />
                                                    <span className={Qst.birthText}>월</span>
                                                </div>
                                                <div className={Qst.birthWrap}>
                                                    <InputText style={{width:'108px'}} placeholder="00" name={`directors[${idx}].birth.day`} value={item.birth.day} onChange={(e) => LiquidationVm.setDay(e,idx)} maxLength="2" onBlur={(e) => LiquidationVm.dayBlur(e,idx)} />
                                                    <span className={Qst.birthText}>일</span>
                                                </div>
                                            </div>
                                            <div>
                                                <Error name={`directors[${idx}].birth.year`} value={item.birth.year} style={{marginTop:'-27px'}}
                                                errorCase={{
                                                required:'연도를 입력해 주세요.',
                                                pattern: {
                                                    value: regExp.year().test(item.birth.year),
                                                    message: '올바른 연도를 입력해 주세요'               
                                                }
                                                }}
                                                />
                                                <Error name={`directors[${idx}].birth.month`} value={item.birth.month} style={{marginTop:'-27px'}}
                                                errorCase={{
                                                required: LiquidationVm.displayBirthErrorMessage(idx, 'month') ? '월을 입력해 주세요.' : null,
                                                pattern: {
                                                    value: LiquidationVm.displayBirthErrorMessage(idx, 'month') ? regExp.month().test(item.birth.month) : null,
                                                    message: '올바른 월을 입력해 주세요'               
                                                }
                                                }}
                                                />
                                                <Error name={`directors[${idx}].birth.day`} value={item.birth.day} style={{marginTop:'-27px'}}
                                                errorCase={{
                                                required: LiquidationVm.displayBirthErrorMessage(idx, 'day') ? '일(날짜)을 입력해 주세요.' : null,
                                                pattern: {
                                                    value: LiquidationVm.displayBirthErrorMessage(idx, 'day') ? regExp.day().test(item.birth.day) : null,
                                                    message: '올바른 일(날짜)을 입력해 주세요.'           
                                                }
                                                }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <Address name='directors' idx={idx} item={item} parentVm={LiquidationVm} />
                                </>
                                }
                                {item.foreignerYn !== null &&
                                    <LiquidationDate parentVm={LiquidationVm}/>
                                }
                            </ContentBox>
                        </Question> 
                    }
                </Fragment>
            ))}
            {LiquidationVm.liquidationStepType === 'CASE2' &&
                <>
                    {LiquidationVm.displayCase2Content() &&
                        <Question>
                            <ContentBox>
                                <LiquidationDate parentVm={LiquidationVm} style={{marginTop:'0'}} />
                            </ContentBox>
                        </Question>
                    }
                </>
            }
            {/* 도로명주소 팝업 */}
            {LiquidationVm.state.addressModal && 
                <SearchAddress onClose={() => LiquidationVm.openAddressModal(false,null)} onComplete={(data) => LiquidationVm.addressComplate(data)} />
            }
        </form>
    ));
}
export default Liquidation;