import { useObserver } from 'mobx-react';
import Ast from '../../AdminFinalCheckView.module.scss';
import st from './BranchOfficeRelocateStep.module.scss';
import clsx from 'clsx';
import copyText from '@common/module/copyText';
import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';
import Icon from '@comComponents/atoms/Icon/Icon';

function BranchOfficeRelocateStep(props) {
    return useObserver(() => (
        <>
            <div className={Ast.row}> 
                <div className={Ast.dName}>구지점 주소</div>
                <div className={clsx(Ast.data, st.originBranchData)}>
                    {props.step.branches.length === 0 && '-'}
                    {props.step.branches.map((item, idx) => {
                        return (
                            <div key={idx} className={st.originBranchAddress}>{item.originBranchAddress}</div>
                        )
                    })}
                </div>
            </div>
            <div className={Ast.row}> 
                <div className={Ast.dName}>신지점 주소</div>
                <div className={clsx(Ast.data, st.newBranchData)}>
                    {props.step.branches.length === 0 && '-'}
                    {props.step.branches.map((item, idx) => {
                        return (
                            <div key={idx} className={st.newBranch}>
                                <div className={st.newBranchAddress}>
                                    <span className={st.newBranchNum}>{idx+1}.</span> 주소 : 
                                    {item?.newBranchAddress !== null &&
                                        <>
                                            <CopyBtn onClick={() => copyText(item?.newBranchAddress?.sido)} className={Ast.addressCopyBtn} style={{marginLeft:'3px'}}>{item?.newBranchAddress?.sido}</CopyBtn>
                                            <CopyBtn onClick={() => copyText(item?.newBranchAddress?.sigungu)} className={Ast.addressCopyBtn}>{item?.newBranchAddress?.sigungu}</CopyBtn>
                                            {item?.newBranchAddress?.bname1 && <CopyBtn onClick={() => copyText(item?.newBranchAddress?.bname1)} className={Ast.addressCopyBtn}>{item?.newBranchAddress?.bname1}</CopyBtn>}
                                            <CopyBtn onClick={() => copyText(item?.newBranchAddress?.roadname)} className={Ast.addressCopyBtn}>{item?.newBranchAddress?.roadname}</CopyBtn>
                                            <CopyBtn onClick={() => copyText(item?.newBranchAddress?.buildingNo)} className={Ast.addressCopyBtn}>{item?.newBranchAddress?.buildingNo}</CopyBtn>,
                                            <CopyBtn onClick={() => copyText(item?.newBranchAddress?.detailAddress)} className={Ast.addressCopyBtn}>{item?.newBranchAddress?.detailAddress}</CopyBtn>
                                            <div className={Ast.addressCopyBracket}>
                                                (<CopyBtn onClick={() => copyText(item?.newBranchAddress?.bname)} className={Ast.addressCopyBtn}>{item?.newBranchAddress?.bname}</CopyBtn>,
                                                <CopyBtn onClick={() => copyText(item?.newBranchAddress?.nameOfBuilding)} className={Ast.addressCopyBtn}>{item?.newBranchAddress?.nameOfBuilding}</CopyBtn>)
                                            </div>
                                            <CopyBtn onClick={() => copyText(item?.newBranchAddress?.fullAddress)} className={Ast.addressCopyAllBtn}><Icon icon="copy" className={Ast.copyIcon}/></CopyBtn>
                                        </>
                                    }
                                </div>
                                <div className={st.newBranchName}>지점명 : <CopyBtn onClick={() => copyText(item.newBranchName)} className={Ast.addressCopyBtn}>{item.newBranchName}</CopyBtn></div>
                                <div className={st.newBranchCauseDate}>지점 이전 일자 : <CopyBtn onClick={() => copyText(item.causeDate)} className={Ast.addressCopyBtn}>{item.causeDate}</CopyBtn></div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    ));
}

export default BranchOfficeRelocateStep;