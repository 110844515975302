import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import down from '../../../asset/icon/ico_down.svg';


const Btn = styled.button`
    border: none;
    padding: 2px 28px 2px 0;
    background-color: transparent;   
    background-image: url(${down});
    background-repeat: no-repeat;
    background-size: 22px 22px;
    background-position-x: 61px;
    background-position-y: 4px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.64px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
        padding: 7px 28px 6px 0;
    }
`;

function ReviseBtn(props) {
    return (
        <Btn type={props.type} className={props.className} style={props.style} onClick={(e) => props.onClick(e)}>
            {props.children}
        </Btn>
    );
};

ReviseBtn.propTypes = {
    /**
    * 버튼 타입
    */
    type: PropTypes.string,
    /**
    * class명
    */
    className: PropTypes.string,
    /**
    * 클릭 이벤트
    */
    onClick: PropTypes.func,
    /**
    * style 속성
    */
    style: PropTypes.object,
    /**
    * 버튼 텍스트 값
    */
    children: PropTypes.string,
};

ReviseBtn.defaultProps = {
    type : 'button',
    className : null,
    style : {},
    onClick : () => {},
    children: 'Emtpy'
};

export default ReviseBtn;

