import { observable, runInAction, toJS } from "mobx";
import etcInfoModel from "@model/establish/etcInfoModel";
import stepService from "@service/stepService";
import formDataToJson from '@common/module/submit';

class viewModel {
    constructor() {
        this.state = observable({
            signList: this.defaultSigns
        })
    }
    submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            stepService.saveStep(param);
        }
    }
    get establishmentCorporateSeals() {
        return etcInfoModel.establishmentCorporateSeals;
    }  
    setDefaultYn(e, idx) { //법인인감 종류 선택
        etcInfoModel.establishmentCorporateSeals[idx].defaultYn = e.target.value === "true";
    }
    get usageSealNormalCount() { //사용인감 기본형
        return etcInfoModel.usageSealNormalCount;
    }
    get usageSealPremiumCount() { //사용인감 고급형
        return etcInfoModel.usageSealPremiumCount;
    }
    usageSealCountMinus(e, type) { 
        e.preventDefault();
        let cnt = 0;
        if(type === "normal") { 
            cnt = etcInfoModel.usageSealNormalCount;
            if(cnt > 0) {
                let lastIdx = etcInfoModel.normalUsageSealSymbols.length-1;
                let lastSymbol = '';
                runInAction(() => {
                    etcInfoModel.usageSealNormalCount = cnt - 1;
                    lastSymbol = etcInfoModel.normalUsageSealSymbols[lastIdx].symbol;
                    etcInfoModel.normalUsageSealSymbols.splice(lastIdx, 1);
                    if(lastSymbol !== "") {
                        this.state.signList.push(lastSymbol);
                    }
                })
            }
        }
        else if(type === "premium") { 
            cnt = etcInfoModel.usageSealPremiumCount;
            if(cnt > 0) {
                let lastIdx = etcInfoModel.premiumUsageSealSymbols.length-1;
                let lastSymbol = '';
                runInAction(() => {
                    etcInfoModel.usageSealPremiumCount = cnt - 1;
                    lastSymbol = etcInfoModel.premiumUsageSealSymbols[lastIdx].symbol;
                    etcInfoModel.premiumUsageSealSymbols.splice(lastIdx, 1);
                    if(lastSymbol !== "") {
                        this.state.signList.push(lastSymbol);
                    }
                })
            }
        }
    }
    usageSealCountPlus(e, type) { 
        e.preventDefault();
        let cnt = 0;
        if(type === "normal") {
            cnt = etcInfoModel.usageSealNormalCount;
            if(cnt < 10) {
                runInAction(() => {
                    etcInfoModel.usageSealNormalCount = cnt + 1;
                    etcInfoModel.normalUsageSealSymbols.push({id:"", symbol: ""})
                })
            }
        }
        else if(type === "premium") {
            cnt = etcInfoModel.usageSealPremiumCount;
            if(cnt < 10) {
                runInAction(() => {
                    etcInfoModel.usageSealPremiumCount = cnt + 1;
                    etcInfoModel.premiumUsageSealSymbols.push({id:"", symbol: ""})
                })
            }
        }
    }
    setUsageSealCount(e, type) { 
        const val = Number(e.target.value);
        if(val < 0) {
            e.target.value = 0;
            if(type === "normal") etcInfoModel.usageSealNormalCount = 0;
            else if(type === "premium") etcInfoModel.usageSealPremiumCount = 0;
        } else if(val > 10) {
            e.target.value = 10;
            if(type === "normal") etcInfoModel.usageSealNormalCount = 10;
            else if(type === "premium") etcInfoModel.usageSealPremiumCount = 10;
        } else {
            if(type === "normal") etcInfoModel.usageSealNormalCount = val;
            else if(type === "premium") etcInfoModel.usageSealPremiumCount = val;
        }
    }
    usageSealCountSum(type) {
        if(type === "normal") {
            const sum = 14300 * etcInfoModel.usageSealNormalCount;
            return sum.toString();
        } 
        else if(type === "premium") {
            const sum = 36300 * etcInfoModel.usageSealPremiumCount;
            return sum.toString();
        }
        else if(type === "sum") {
            const sum = 14300 * etcInfoModel.usageSealNormalCount + 36300 * etcInfoModel.usageSealPremiumCount;
            return sum.toString();
        }
    }
    usageSealTotal(useType) { //도장 추가금액
        if(useType === 'corp') { //법인인감
            let basic = 0, premium = 0;
            etcInfoModel.establishmentCorporateSeals.forEach((seal, idx) => {
                if(seal.defaultYn) {
                    basic++;
                } else {
                    premium++;
                }
            })
            const sum = (basic * 14300) + (premium * 36300) - 14300;
            return sum.toString(); 

        } else if(useType === 'use') { //사용인감
            const sum = Number(this.usageSealCountSum('normal')) + Number(this.usageSealCountSum('premium'));
            return sum.toString();
        } else if(useType === 'total') { //법인인감+사용인감 = 도장 추가금액
            const sum = Number(this.usageSealTotal('corp')) + Number(this.usageSealTotal('use'))
            return sum.toString();
        }
    }

    get corporateSealsMultiYn() {
        if(this.establishmentCorporateSeals.length > 1) return true;
        else return false;
    } 
    get normalUsageSealSymbols() {
        return etcInfoModel.normalUsageSealSymbols;
    }
    get premiumUsageSealSymbols() {
        return etcInfoModel.premiumUsageSealSymbols;
    }
    setNormalUsageSealSymbols() {
        for(let i=0; i<this.usageSealNormalCount; i++) {
            runInAction(() => {
                etcInfoModel.normalUsageSealSymbols.push({id: "", symbol: ""});
            })
        }
    }
    setPremiumUsageSealSymbols() {
        for(let i=0; i<this.usageSealPremiumCount; i++) {
            runInAction(() => {
                etcInfoModel.premiumUsageSealSymbols.push({id: "", symbol: ""});
            })
        }
    }
    get defaultSigns() {
        return ['동글', '별', '마름모', '클로버', '스페이드', '하트', '네모', '삼각형', '동글1', '동글2', '동글3', '동글4', '동글5', '동글6', '동글7', '동글8', '동글9'];
    }
    signText(signValue) {
        switch(signValue) {
            case '동글':
                return '동글(●)';
            case '별':
                return '별(★)';
            case '마름모':
                return '마름모(◆)';
            case '클로버':
                return '클로버(♣)';
            case '스페이드':
                return '스페이드(♠)';
            case '하트':
                return '하트(♥)';
            case '네모':
                return '네모(■)';
            case '삼각형':
                return '삼각형(▲)';
            case '동글1':
                return '동글1(①)';
            case '동글2':
                return '동글2(②)';
            case '동글3':
                return '동글3(③)';
            case '동글4':
                return '동글4(④)';
            case '동글5':
                return '동글5(⑤)';
            case '동글6':
                return '동글6(⑥)';
            case '동글7':
                return '동글7(⑦)';
            case '동글8':
                return '동글8(⑧)';
            case '동글9':
                return '동글9(⑨)';
        }
    }
    get currentSymbolList() {
        let modelSymbolList = [];
        this.establishmentCorporateSeals.forEach((item) => { //법인인감
            modelSymbolList.push(item.symbol);
        });
        this.normalUsageSealSymbols.forEach((item) => { //사용인감_기본
            modelSymbolList.push(item.symbol)
        });
        this.premiumUsageSealSymbols.forEach((item) => { //사용인감_고급
            modelSymbolList.push(item.symbol);
        });
        return modelSymbolList;
    }
    setSealSymbols(e, idx, type) {
         //선택한 값 signList에서 삭제
        this.state.signList.forEach((item, index) => {
            if(item === e.target.value) {
                this.state.signList.splice(index, 1)
            } 
        });

        //default비표값과 선택된 비표값 비교(체크되었던 비표 list)
        let checkedSignList = [];
        this.defaultSigns.forEach((item) => {
            if(this.state.signList.indexOf(item) === -1) {
                checkedSignList.push(item)
            }
        });
        
        if(type === 'corp') {
            runInAction(() => {
                etcInfoModel.establishmentCorporateSeals[idx].symbol = e.target.value;
            })
        } else if(type === 'normal') {
            runInAction(() => {
                etcInfoModel.normalUsageSealSymbols[idx].symbol = e.target.value;
            })
        } else if(type === 'premium') {
            runInAction(() => {
                etcInfoModel.premiumUsageSealSymbols[idx].symbol = e.target.value;
            })
        }

        checkedSignList.forEach((item) => {
            if(this.currentSymbolList.indexOf(item) === -1) {
                this.state.signList.push(item);
            }
        });
    }
}

export default new viewModel();