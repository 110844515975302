import { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import KoreanPersonVm from './KoreanPersonVm';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from '../ConvertibleBondAcquirer.module.scss';
import clsx from 'clsx';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';

function KoreanPerson(props) {
    return useObserver(() => (
        <>
            <div className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    <div className={Qst.titleWrap}>
                        <h2 className={clsx(Qst.title, Qst.inside)}>
                            <span className={Qst.bold}>성함을 입력</span>해 주세요.
                        </h2>
                    </div>
                    <InputText placeholder="ex)홍길동" style={{width:'316px'}} value={props.item.koreanName} onChange={(e) => KoreanPersonVm.setKoreanName(e,props.idx)} name={`convertibleBondAcquirerInfos[${props.idx}].koreanName`} errorText={<Error name={`convertibleBondAcquirerInfos[${props.idx}].koreanName`} value={props.item.koreanName} errorCase={{required:'성함을 입력해 주세요.'}}/>} /><span className={Qst.nameInput}>님</span>
                </div>
            </div>
            <div className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    <div className={Qst.titleWrap}>
                        <h2 className={clsx(Qst.title, Qst.inside)}>
                            {props.item.koreanName? props.item.koreanName : '전환사채 인수인'}님의 <span className={Qst.bold}>주민등록등(초)본상 도로명주소</span>를 입력해 주세요. 
                        </h2>
                    </div>
                    <InputText style={{width:'560px'}} value={props.item.koreanAddress} onChange={(e) => KoreanPersonVm.setKoreanAddress(e,props.idx)} name={`convertibleBondAcquirerInfos[${props.idx}].koreanAddress`} errorText={<Error name={`convertibleBondAcquirerInfos[${props.idx}].koreanAddress`} value={props.item.koreanAddress} errorCase={{required:'주소를 입력해 주세요.'}}/>}/>
                </div>
            </div>
        </>
    ));
}
export default KoreanPerson;