import { post, get } from '@common/module/httpRequest';
import smartformAppModel from '@model/smartformAppModel';
import pageStepModel, {pageRouter, pageDepth} from '@model/pageStepModel';
import { runInAction } from 'mobx';

class stepService {
    get stepIdx() {
        let stepIdx = 0;
        pageDepth.cause[pageStepModel.cause].steps.forEach((step, index) => {
            if(step.code === pageStepModel.nowStep){
                stepIdx = index;
            }
        })
        return stepIdx;
    }
    get pageIdx() {
        let pageIdx = 0;
        const steps = pageDepth.cause[pageStepModel.cause].steps;
        steps.forEach((step, index) => {
            if(step.code === pageStepModel.nowStep) {
                step.pages.forEach((page, index2) => {
                    if(page.code === pageStepModel.nowPage) {
                        pageIdx = index2;
                    }
                })
            }
        })
        return pageIdx;
    }
    get canNext() {
        let stepIndex = this.stepIdx;
        let pageIndex = this.pageIdx;
        const steps = pageDepth.cause[pageStepModel.cause].steps;
        const stepsLength = steps.length;
        if(stepsLength-1 === stepIndex && steps[stepsLength-1].pages.length-1 === pageIndex) {
            return false;
        }
        return true;
    }
    async saveStep(param) { //스탭 저장
        pageStepModel.saveState = 'load';
        smartformAppModel.setIsSubmitData(false);
        await post(`/step/${pageStepModel.cause}/${smartformAppModel.smartformId}/${pageStepModel.nowStep}/${pageStepModel.nowPage}`, param , {}).then((e) => {
            runInAction(async () => {
                if(e.data.steps) { //스탭 변경이 있는 경우
                    pageDepth.cause[pageStepModel.cause].steps = e.data.steps;
                }
                if(this.canNext === false) {
                    if(smartformAppModel.authority === "manager") {
                        await post(`/admin/smartforms/${smartformAppModel.smartformId}/managerSubmit`, {}, {});
                    }
                    pageStepModel.saveState = 'clear';
                    setTimeout(() => {
                        pageStepModel.saveState = 'next';
                        if(smartformAppModel.authority === "manager") {
                            if(smartformAppModel.isEstablishment) {
                                pageRouter.replace(`/smartform/managerMode?smartformId=${smartformAppModel.smartformId}`);
                            } else {
                                pageRouter.replace(`/changeRegistration/smartform/managerMode?smartformId=${smartformAppModel.smartformId}&manager=true`);
                            }
                        }else{
                            pageRouter.replace(`/${pageStepModel.cause}/BeforeFinalCheck`);
                        }
                    },600)
                } 
                else {
                    let moveStep = '';
                    let movePage = '';
                    const steps = pageDepth.cause[pageStepModel.cause].steps;
                    steps.forEach((step, index) => {
                        if(step.code === pageStepModel.nowStep) {
                            step.pages.forEach((page, index2) => {
                                if(page.code === pageStepModel.nowPage) {
                                    if(index2 !== step.pages.length-1) {
                                        moveStep = step.code;
                                        movePage = step.pages[index2+1].code;
                                    }else{
                                        moveStep = steps[index+1].code;
                                        movePage = steps[index+1].pages[0].code;
                                    }
                                }
                            })
                        }
                    })
                    pageStepModel.saveState = 'clear';
                    setTimeout(() => {
                        pageStepModel.saveState = 'next';
                        pageRouter.replace(`/step/${pageStepModel.cause}/${moveStep}/${movePage}`);
                    },600)
                    
                }
            })
        }).catch(() => {
            smartformAppModel.setIsSubmitData(true);
            pageStepModel.saveState = 'next';
        })
    }
}
export default new stepService();