import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import arrow from '../../../asset/icon/ico_arrow_small.svg';
import arrowBlack from '../../../asset/icon/ico_arrow_small_black.svg';

const A = styled.a`  
    cursor: pointer;
    display: inline-block;
    position: relative;
    padding: 10px 33px 10px 22px;
    background-color: transparent;
    ${(props) => props.btnColor === "black" &&
        `border: 1px solid #000000;`
    }
    ${(props) => props.btnColor === "blue" &&
        `border: 2px solid #006ac3;`
    }
    font-size: 16px;
    text-decoration: none !important;
    &:before {
        content: '';
        display: inline-block;
        position: absolute;
        right:18px;
        top: 11px;
        ${(props) => props.btnColor === "black" &&
            `background-image: url(${arrowBlack});`
        }
        ${(props) => props.btnColor === "blue" &&
            `background-image: url(${arrow});`
        }
        background-repeat: no-repeat;
        background-size: cover;
        width: 9px;
        height: 14px;
    }
    &:hover {
        text-decoration: none;
    }
`;

const Text = styled.span`
    ${(props) => props.btnColor === "black" &&
        `color: #000000;`
    }
    ${(props) => props.btnColor === "blue" &&
        `color: #006ac3;`
    }
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.96px;
`;

function LikeBtn(props) {
    return (
        <A className={props.className} style={props.style} 
        href={props.href} target={props.target} download={props.download} btnColor={props.btnColor}>
            <Text btnColor={props.btnColor}>{props.children}</Text>          
        </A>
    );
};

LikeBtn.propTypes = {
    /**
     * class명
     */
    className: PropTypes.string,
    /**
     * style 속성
     */
    style: PropTypes.object,
    /**
    * URL
    */
    href: PropTypes.string,
    /**
    * 버튼색상 blue, black있음
    */
    btnColor: PropTypes.string,
    /**
    * target 속성
    */
    target: PropTypes.string,
    /**
    * 다운로드 여부
    */
    download: PropTypes.bool,
    /**
     * 버튼 텍스트 값
     */
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.any
    ]),
};

LikeBtn.defaultProps = {
    className : null,
    style : {},
    href: null,
    target: '_self',
    download: null,
    children : 'Empty',
    btnColor : "blue"
};

export default LikeBtn;

