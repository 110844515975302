import businessPurposeStepModel from '@model/changeRegistration/businessPurposeStepModel';
import { runInAction, toJS, observable } from "mobx";
import { _alert, _confirm } from "@model/dialogModel";
import smartformAppModel from "@model/smartformAppModel";
import formDataToJson from '@common/module/submit';
import stepService from "@service/stepService";

class BusinessPurposeRevisionVm {
    get originalBusinessPurposes() {
        return businessPurposeStepModel.originalBusinessPurposes;
    }
    deleteYn(e, index) {
        businessPurposeStepModel.originalBusinessPurposes[index].deleteYn = e.target.checked
    }
    get deleteCheckAll() {
        let checked = true;
        if(businessPurposeStepModel.originalBusinessPurposes.length) {
            businessPurposeStepModel.originalBusinessPurposes.forEach((item) => {
                if(item.deleteYn === false) {
                    checked = false
                }
            });
        }else{
            checked = false;
        }
        return checked;
    }
    get deleteCount() {
        let count = 0;
        businessPurposeStepModel.originalBusinessPurposes.forEach((item) => {
            if(item.deleteYn === true) {
                count += 1;
            }
        });
        return count;
    }
    setDeleteCheckAll(e) {
        businessPurposeStepModel.originalBusinessPurposes.forEach((item) => {
            item.deleteYn = e.target.checked
        })
    }
    unCheckAll() {
        businessPurposeStepModel.originalBusinessPurposes.forEach((item) => {
            item.deleteYn = false;
        })
    }
    get newBusinessPurposes() {
        return businessPurposeStepModel.newBusinessPurposes;
    }
    get checkedLength() {
        let length = 0;
        businessPurposeStepModel.newBusinessPurposes.forEach((item) => {
            if(item.checked === true) {
                length += 1;
            }
        })
        return length
    }
    get isCheckedAll() {
        let checked = true;
        if(businessPurposeStepModel.newBusinessPurposes.length) {
            businessPurposeStepModel.newBusinessPurposes.forEach((item) => {
                if(item.checked === false) {
                    checked = false
                }
            });
        }else{
            checked = false;
        }
        return checked;
    }
    setCheckedAll(e) {
        businessPurposeStepModel.newBusinessPurposes.forEach((item) => {
            item.checked = e.target.checked;
        })
    }
    async selectedDelete() {
        let newArr = [];
        let isDeleteYn = false;
        businessPurposeStepModel.newBusinessPurposes.forEach(item => {
            if(!item.checked) {
                newArr.push(item)
            } else{
                isDeleteYn = true;
            }
        })
        if(isDeleteYn === false) {
            return _alert('삭제할 목적을 선택해 주세요.')
        } else{
            if(await _confirm("선택한 목적을 삭제하시겠습니까?")) {
                businessPurposeStepModel.newBusinessPurposes = newArr;
            }
        }
    }
    onSortEnd(e) {
        this.arraymove(businessPurposeStepModel.newBusinessPurposes, e.oldIndex, e.newIndex);
    }
    arraymove(arr, fromIndex, toIndex) {
        const element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    }
    content(e, idx) {
        runInAction(() => {
            businessPurposeStepModel.newBusinessPurposes[idx].content = e.target.value;
        })
    }
    enterText(e, index) {
        const value = e.target.value;
        let splitArr = [];
        value.split('\n').map((item) => {
            if(item.length) {
                splitArr.push(item)
            }
        })
        runInAction(() => {
            if(splitArr.length > 1) {
                splitArr.map((item, idx) => {
                    if(idx === 0) {
                        let content = splitArr[0];
                        if(content.split('1. ').length !== 1) {
                            content = splitArr[0].split('1. ')[1];
                        }
                        businessPurposeStepModel.newBusinessPurposes[index].content = content
                    }else{
                        let content = splitArr[idx];
                        if(content.split('1. ').length !== 1) {
                            content = splitArr[idx].split('1. ')[1];
                        }
                        businessPurposeStepModel.newBusinessPurposes.push({
                            content: content,
                            checked : false
                        });
                    }
                })
            }
        })
    }
    checked(e, idx) {
        runInAction(() => {
            if(businessPurposeStepModel.newBusinessPurposes[idx].checked) {
                businessPurposeStepModel.newBusinessPurposes[idx].checked = false;
            }else{
                businessPurposeStepModel.newBusinessPurposes[idx].checked = true;
            }
        })
    }
    addRow(idx) {
        runInAction(() => {
            businessPurposeStepModel.newBusinessPurposes.splice(idx+1, 0, {
                content : "",
                checked : false
            });
        })
    }
    async deleteRow(idx) {
        if(await _confirm("선택한 목적을 삭제하시겠습니까?")) {
            businessPurposeStepModel.newBusinessPurposes.splice(idx, 1);
        }
    }
    addBusinessPurposes() {
        runInAction(() => {
            businessPurposeStepModel.newBusinessPurposes.push({
                content : "",
                checked : false
            })
        })
    }
    dataError(content) {
        let error = false;
        let errorMessage = '';
        let errorState = 'success';
        if(error === false) {
            if(content) {
                const 업regExp = /업\s*$/;
                const deleteSpaceRegExp = /\s/gi;
                const 글자수 = content.trim().length;
                let 업excepYn = false;
                let is중복 = false;
                let is기존중복 = false;
                const 업excep = [ //'~업'이 들어가지 않아도 되는 사업목적
                    '부동산중개업',
                    '상업용 건축물 및 주택의 임대관리 등 부동산의 관리대행',
                    '부동산의 이용 및 개발에 관한 상담',
                    '개업공인중개사를 대상으로 한 중개업의 경영기법 및 경영정보의 제공',
                    '중개의뢰인의 의뢰에 따른 도배, 이사업체의 소개 등 주거이전에 부수되는 용역의 알선',
                    '상업용 건축물과 주택의 분양대행',
                    '경매 또는 공매대상 부동산에 대한 권리분석 및 취득의 알선과 매수신청 또는 입찰신청의 대리'
                ]
                업excep.forEach((excepPurpose, idx) => {
                    if(content.replace(deleteSpaceRegExp, "") === excepPurpose.replace(deleteSpaceRegExp, "")) {
                        업excepYn = true;
                    }
                })
                let selectedLength = 0;
                this.newBusinessPurposes.map((item) => {
                    if(item.content.replace(deleteSpaceRegExp, "") === content.replace(deleteSpaceRegExp, "")) {
                        selectedLength += 1;
                    }
                })
                let originSelectedLength = 0;
                let originContent = '';
                this.originalBusinessPurposes.map((item) => {
                    originContent = item.content.replace('1. ', "");
                    if(content.replace(deleteSpaceRegExp, "") === originContent.replace(deleteSpaceRegExp, "")) {
                        originSelectedLength += 1;
                    }
                })
                if(selectedLength > 1) {
                    is중복 = true;
                }
                if(originSelectedLength > 0) {
                    is기존중복 = true;
                }
                if(업excepYn) { //~'업'이 들어가지 않아도 되는 사업목적인 경우
                    error = false;
                } else {
                    if(!업regExp.test(content)) {
                        error = true;
                        errorMessage = "사업목적의 마지막 문구가 '~업'으로 끝나도록 입력해주세요.";
                    } else if(글자수 < 3 || 글자수 > 80) {
                        error = true;
                        errorMessage = "3글자 이상 80글자 이하로 입력해주세요.";
                    } else if(is중복) {
                        error = true;
                        errorMessage = "중복된 목적입니다. 하나만 남기고 삭제해주세요.";
                    } else if(is기존중복) {
                        error = true;
                        errorMessage = "기존 등기부등본에 존재하는 목적입니다."
                    }
                }   
            }
        }
        if(error) {
            errorState = 'error'
        } else {
            errorState = 'success'
        }
        return {
            errorState,
            errorMessage
        }
    }
    get smartFormType() {
        return smartformAppModel.smartFormType;
    }
    get availableLength() {
        let length = 0;
        this.newBusinessPurposes.forEach((item) => {
            if(item.content) {
                length += 1;
            }
        })
        return length;
    }
    submit(e) {
        e.preventDefault();
        const regExp = /([^가-힣A-Za-z0-9\s.,\[\]{}()%$:\n-])+/;
        if(formDataToJson(e.target).error) {
            return
        }
        let param = formDataToJson(e.target).data;
        
        //목적 정규식 테스트
        let businessPurposesValidateYn = true;
        if(Array.isArray(param.deleteIds)) {
            param.deleteIds = param.deleteIds.filter((item) => {
                return item !== "delete"
            })
        }else{
            param.deleteIds = [];
        }
        if(param.newBusinessPurposes &&  param.newBusinessPurposes.length) {
            param.newBusinessPurposes.map((businessPurposes, idx) => {
                if(param.newBusinessPurposes[idx].content.trim()) {
                    param.newBusinessPurposes[idx].content = param.newBusinessPurposes[idx].content.trim();
                    if(regExp.test(businessPurposes.content)) {
                        businessPurposesValidateYn = false;
                    }
                }
            })
            param.newBusinessPurposes = param.newBusinessPurposes.filter((item) => {
                return item.content !== '';
            })   
        }else{
            param.newBusinessPurposes = [];
        }
        if(param.deleteIds.length === 0 && param.newBusinessPurposes.length === 0) {
            return _alert('삭제할 목적이나 추가할 목적을 입력해주세요.');
        }
        if(!businessPurposesValidateYn) {
            return _alert('목적에는 정해진 문자 이외에는 사용할 수 없습니다.\n정해진 문자: 한글/영문/숫자/하이픈/구두점/쉼표/스페이스/대괄호/중괄호/소괄호/퍼센트/달러/콜론/Enter');
        }
        stepService.saveStep(param);
    }
}
export default new BusinessPurposeRevisionVm();