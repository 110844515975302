import addStockOptionStepModel from '@model/changeRegistration/addStockOptionStepModel';
import { observable, runInAction, toJS, _allowStateReadsStart } from "mobx";
import formDataToJson, { findPos } from '@common/module/submit';
import stepService from "@service/stepService";
import { _alert, _confirm } from "@model/dialogModel";

class AddStockOptionVm {
    get ventureYn() {
        return addStockOptionStepModel.ventureYn;
    }
    setVentureYn(e) {
        runInAction(() => {
            addStockOptionStepModel.ventureYn = e.target.value === "true";
        })
    }
    get helpmeRegulationYn() {
        return addStockOptionStepModel.helpmeRegulationYn;
    }
    setHelpmeRegulationYn(e) {
        runInAction(() => {
            addStockOptionStepModel.helpmeRegulationYn = e.target.value === "true"
        })
    }
    get regulation() {
        return addStockOptionStepModel.regulation;
    }   
    setRegulation(e) {
        runInAction(() => {
            addStockOptionStepModel.regulation = e.target.value;
        })
    }
    get files() {
        return addStockOptionStepModel.files;
    }
    get uploadFiles() {
        return addStockOptionStepModel.uploadFiles;
    }
    get deleteIds() {
        return addStockOptionStepModel.deleteIds;
    }
    async submit(e) {
        e.preventDefault();
        if(addStockOptionStepModel.ventureYn) { //벤처기업확인서 업로드 validation
            if(addStockOptionStepModel.files.length === 0 && addStockOptionStepModel.uploadFiles.length === 0) {
                const fileCheck = setInterval(() => {
                    clearInterval(fileCheck);
                    let top = findPos(document.querySelector('.fileUpload')) - 185;
                    window.scroll(0, top);
                }, 30)
                await _alert('벤처기업확인서를 업로드해 주세요.')
                return 
            }
        } 
        if(formDataToJson(e.target).error) {
            return
        } else {
            let formData = new FormData();
            formData.append('helpmeRegulationYn', addStockOptionStepModel.helpmeRegulationYn);
            formData.append('ventureYn', addStockOptionStepModel.ventureYn);
            if(addStockOptionStepModel.regulation) {
                formData.append('regulation', addStockOptionStepModel.regulation);
            }
            if(addStockOptionStepModel.uploadFiles && addStockOptionStepModel.uploadFiles.length && addStockOptionStepModel.ventureYn) {
                for(let i=0; i<addStockOptionStepModel.uploadFiles.length; i++) {
                    formData.append('files', addStockOptionStepModel.uploadFiles[i]);
                }
            }
            if(addStockOptionStepModel.deleteIds && addStockOptionStepModel.deleteIds.length && addStockOptionStepModel.ventureYn) {
                formData.append('deleteFileIds', addStockOptionStepModel.deleteIds);
            }
            if(addStockOptionStepModel.ventureYn === false && addStockOptionStepModel.files && addStockOptionStepModel.files.length) {
                addStockOptionStepModel.files.forEach((item) => {
                    addStockOptionStepModel.deleteIds.push(item.id)
                })
                formData.append('deleteFileIds', addStockOptionStepModel.deleteIds);
            }
            stepService.saveStep(formData);
        }
    }
}

export default new AddStockOptionVm();