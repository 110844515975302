import { useObserver } from 'mobx-react';
import TCAgreeAndDeliveryAddress from './TCAgreeAndDeliveryAddress/TCAgreeAndDeliveryAddress';
import { Route } from "react-router-dom";

function TCAgreeAndDeliveryAddressStep() {
    return useObserver(() => (
    <>
        {/* 이용약관동의 및 송달우편주소 */}
        <Route path="/step/changeRegistration/TCAgreeAndDeliveryAddressStep/TCAgreeAndDeliveryAddress" component={TCAgreeAndDeliveryAddress}></Route>
    </>
    ));
}

export default TCAgreeAndDeliveryAddressStep;