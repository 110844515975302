import { observable } from 'mobx';

const StandByBoardMeetingInfoStepModel = observable({
    chairmanId: null,
    chairmanCandidates: [],
    executiveMembers: [],
    participants: [],
    setStandByBoardMeetingBasicInfo(data) {
        this.chairmanId = data.chairmanId || null;
        if(data.chairmanCandidates && data.chairmanCandidates.length !== 0) {
            this.chairmanCandidates = data.chairmanCandidates.map((item) => {
                return {
                    id: item.id || null,
                    name: item.name || "",
                    position: item.position || "",
                    representativeYn: item.representativeYn ?? false
                }
            })
        } else {
            this.chairmanCandidates = [];
        }
        if(data.executiveMembers && data.executiveMembers.length !== 0) {
            this.executiveMembers = data.executiveMembers.map((item) => {
                return {
                    id: item.id || null,
                    name: item.name || "",
                    position: item.position || "",
                    attendYn: item.attendYn ?? false
                }
            })
        } else {
            this.executiveMembers = [];
        }
    },
    setStandByBoardMeetingParticipant(data) {
        if(data.participants && data.participants.length !== 0) {
            this.participants = data.participants.map((item) => {
                return {
                    id: item.id || null,
                    name: item.name || "",
                    agreeYn: item.agreeYn ?? false,
                    sealProvidingYn: item.sealProvidingYn ?? false
                }
            })
        } else {
            this.participants = [];
        }
    }
});

export default StandByBoardMeetingInfoStepModel;