import StockCompanySealStepModel from '@model/changeRegistration/StockCompanySealStepModel';
import { runInAction, observable, toJS} from "mobx";
import formDataToJson, { errorFocus, findPos, objectToFormData }  from '@common/module/submit';
import { _alert, _confirm } from "@model/dialogModel";
import stepService from "@service/stepService";

class StockholdersMeetingAttendStockholdersVm {
    constructor() {
        this.state = observable({
            openIdx : null,
            errorArr: []
        });
    }
    get pageModel() {
        return StockCompanySealStepModel.StockholdersMeetingAttendStockholders;
    }
    get stockholders() {
        return this.pageModel.stockholders;
    }
    get stockholderMeetingType() {
        return this.pageModel.stockholderMeetingType;
    }
    setIsOpen(idx) {
        return this.state.openIdx === idx;
    }
    setTitle(idx) { 
        if(this.stockholders[idx].name) {
            return this.stockholders[idx].name+' 주주님';
        }else{
            return '주주총회에 참석한(할) 주주분의 정보를 입력해주세요.';
        }
    }
    personErrorYn(idx) {
        return this.state.errorArr.indexOf(idx) !== -1;
    }
    setDropOpen(idx) {
        if(this.state.openIdx === idx) {
            this.state.openIdx = null;
            this.errorState();
        } else {
            this.state.openIdx = idx;
        }
    }
    errorState() {
        const error = document.querySelectorAll('[data-error="error"]');
        let errorIdx = [];
        error.forEach((error) => {
            errorIdx.push(Number(error.closest('[data-eq]').dataset.eq))
        })
        errorIdx = errorIdx.filter((elem, idx) => {
            return errorIdx.indexOf(elem) === idx;
        })
        this.state.errorArr = errorIdx;
    }
    setSealProviderType(e, idx) {
        this.stockholders[idx].sealProviderType = e.target.value;
    }
    setName(e, idx) {
        this.stockholders[idx].name = e.target.value;
    }
    setEnglishName(e, idx) {
        this.stockholders[idx].englishName = e.target.value;
    }
    setNationalityType(e, idx) {
        this.stockholders[idx].nationalityType = e.target.value;
    }
    setNationalityOthers(e, idx) {
        this.stockholders[idx].nationalityOthers = e.target.value;
    }
    get stockholdersDefaultValue() {
        return {
            englishName :  "",
            id :  "",
            name :  "",
            nationalityOthers :  "",
            nationalityType :  "",
            sealProviderType :  ""
        }
    }
    addStockholder() { 
        runInAction(() => {
            this.stockholders.push(this.stockholdersDefaultValue)
            this.state.openIdx = this.stockholders.length-1;
            const newPerson = setInterval(() => {
                clearInterval(newPerson);
                if(this.state.openIdx !== 0) {
                    let top = findPos(document.querySelector('.open')) - 155;
                    window.scroll(0, top)
                }
            }, 30)
        })
    }
    async setCloseBtn(idx) {
    let name = this.stockholders[idx].name; 
        if(!name) {
            name = '해당 주주';
        }
        let confString = name+'님의 정보를 삭제하시겠습니까?';
        const conf  = await _confirm(confString);
        if(this.stockholders.length === 1) {
            if(conf){
                runInAction(() => {
                    this.pageModel.stockholders = [];
                    this.pageModel.stockholders.push(this.stockholdersDefaultValue)
                    this.state.openIdx=null;
                })
            } else {
                return 
            }
        } else {
            if(conf) {
                return this.stockholders.splice(idx,1);
            } else {
                return
            }
        }
    }
    submit(e) {
        e.preventDefault();
        runInAction(() => {
            if(formDataToJson(e.target, false).error) { //입력하지 않은 데이터가 있는 경우
                this.errorState();
                this.state.openIdx = this.state.errorArr[0];
                const findError = setInterval(() => {
                    if(document.querySelector('.open')) {
                        clearInterval(findError);
                        errorFocus();
                    }
                }, 30)
                return
            } 
            else { 
                let param = formDataToJson(e.target).data;
                stepService.saveStep(param);
            }
        })
    }
}
export default new StockholdersMeetingAttendStockholdersVm();