import LiquidationStepModel from '@model/changeRegistration/LiquidationStepModel';
import stepService from "@service/stepService";
import formDataToJson from '@common/module/submit';
import { runInAction, observable } from 'mobx';
import { onlyNumber } from '@common/module/replaceNumber';
import regExp from '@common/module/regExp';
import portalModel from '@model/portalModel';
import ResidenceRegistrationModal from '@pages/ModalContent/changeRegistration/Liquidation/ResidenceRegistrationModal';

class LiquidationVm {
    constructor() {
        this.state = observable({
            addressModal: false,  
            addressIdx: null
        });
    }
    submit(e) {
        e.preventDefault();
        if(this.selectYnError() === false && formDataToJson(e.target).error === false) {
            let param = formDataToJson(e.target).data;
            param?.directors?.forEach((director, idx) => {
                if(director.newAddress) {
                    param.directors[idx].newAddress = LiquidationStepModel.directors[idx].newAddress;
                }
                param.directors[idx].foreignerYn = LiquidationStepModel.directors[idx].foreignerYn;
            })
            stepService.saveStep(param);
        }
    }
    openModal() {
        const modal = ResidenceRegistrationModal;
        runInAction(() => {
            portalModel.modalContent = modal.modalContent;
            portalModel.btn = modal.btn ?? null;
            portalModel.closeBtn = modal.closeBtn ?? true;
        })
    }
    selectYnError() {
        let error = false;
        let selectCnt = 0;
        if(this.liquidationStepType === 'CASE2') {
            LiquidationStepModel.directors.forEach((director) => {
                if(director.selectedYn) selectCnt++;
            })
            if(selectCnt === 0) {
                error = true;
            }
        }
        return error;
    }
    get directors() {
        return LiquidationStepModel.directors;
    }
    get liquidationStepType() {
        return LiquidationStepModel.liquidationStepType;
        //return 'CASE3';
    }
    setKssn(e, idx) {
        runInAction(() => {
            LiquidationStepModel.directors[idx].kssn = e.target.value;
        })
    }
    setHasSameAddressYn(e, idx) {
        runInAction(() => {
            LiquidationStepModel.directors[idx].hasSameAddressYn = e.target.value === "true";
        })
    }
    //도로명 주소
    openAddressModal(openYn, idx) {
        runInAction(() => {
            this.state.addressIdx = idx;
            this.state.addressModal = openYn;
        })
    }
    addressComplate(address) {
        runInAction(() => {
            LiquidationStepModel.directors[this.state.addressIdx].newAddress = address;
            LiquidationStepModel.directors[this.state.addressIdx].detailAddress = "";
            LiquidationStepModel.directors[this.state.addressIdx].nameOfBuilding = address.buildingName;
        })
        this.openAddressModal(false,this.state.addressIdx);
    }
    setDetailAddress(e,idx) { //상세주소
        runInAction(() => {
            LiquidationStepModel.directors[idx].detailAddress = e.target.value;
        })
    }
    setNameOfBuilding(e,idx) { //건물명
        runInAction(() => {
            LiquidationStepModel.directors[idx].nameOfBuilding = e.target.value;
        })
    }
    setChangeDateYear(e, idx){
        runInAction(() => {
            LiquidationStepModel.directors[idx].addressChangeDate.year = onlyNumber(e.target.value);
        })
    }
    setChangeDateMonth(e, idx){
        runInAction(() => {
            LiquidationStepModel.directors[idx].addressChangeDate.month = onlyNumber(e.target.value);
        })
    }
    setChangeDateDay(e, idx){
        runInAction(() => {
            LiquidationStepModel.directors[idx].addressChangeDate.day = onlyNumber(e.target.value);
        })
    }
    changeDateMonthBlur(e,idx) {
        runInAction(() => {
            let month = e.target.value;
            if(month && month.length===1){
                e.target.value = "0" + month;
            }
            LiquidationStepModel.directors[idx].addressChangeDate.month = e.target.value;
        })
    }
    changeDateDayBlur(e,idx) {
        runInAction(() => {
            let day = e.target.value;
            if(day && day.length===1){
                e.target.value = "0" + day;
            }
            LiquidationStepModel.directors[idx].addressChangeDate.day = e.target.value;
        })
    }
    displayChangeDateErrorMessage(idx, type) { 
        const year = this.directors[idx].addressChangeDate.year;
        const month = this.directors[idx].addressChangeDate.month;
        if(type === 'month') {
            if(year && regExp.year().test(year)) { //연도가 제대로 입력되었을 때, 월에 대한 error 메시지
                return true;
            } else {
                return false;
            }
        } else if(type === 'day') {
            if(year && regExp.year().test(year) && month && regExp.month().test(month)) { // 연도, 월이 제대로 입력 되었을 때, 일(날짜)에 대한 error 메시지
                return true;
            } else {
                return false;
            }
        }
    }
    unChecked() {
        runInAction(() => {
            LiquidationStepModel.directors.forEach((director, idx) => {
                if(director.selectedYn) {
                    LiquidationStepModel.directors[idx].selectedYn = false;
                }
            })
        })
    }
    setSelectedYn(e, idx) {
        runInAction(() => {
            const value = e.target.value !== 'true';
            LiquidationStepModel.directors[idx].selectedYn = value;
        })
    }
    displayCase2Content() { //Case2일 때, 청산인 선택한 후에 아래 컨텐츠 나올 수 있도록
        let selectCnt = 0;
        LiquidationStepModel.directors.forEach((director) => {
            if(director.selectedYn) {
                selectCnt++;
            }
        })
        if(selectCnt > 0) {
            return true;
        } else {
            return false;
        }
    }
    get liquidationDate() {
        return LiquidationStepModel.liquidationDate;
    }
    setLiquidationDateYear(e){
        runInAction(() => {
            LiquidationStepModel.liquidationDate.year = onlyNumber(e.target.value);
        })
    }
    setLiquidationDateMonth(e){
        runInAction(() => {
            LiquidationStepModel.liquidationDate.month = onlyNumber(e.target.value);
        })
    }
    setLiquidationDateDay(e){
        runInAction(() => {
            LiquidationStepModel.liquidationDate.day = onlyNumber(e.target.value);
        })
    }
    liquidationDateMonthBlur(e) {
        runInAction(() => {
            let month = e.target.value;
            if(month && month.length===1){
                e.target.value = "0" + month;
            }
            LiquidationStepModel.liquidationDate.month = e.target.value;
        })
    }
    liquidationDateDayBlur(e) {
        runInAction(() => {
            let day = e.target.value;
            if(day && day.length===1){
                e.target.value = "0" + day;
            }
            LiquidationStepModel.liquidationDate.day = e.target.value;
        })
    }
    displayLiquidationDateErrorMessage(type) { 
        const year = this.liquidationDate.year;
        const month = this.liquidationDate.month;
        if(type === 'month') {
            if(year && regExp.year().test(year)) { //연도가 제대로 입력되었을 때, 월에 대한 error 메시지
                return true;
            } else {
                return false;
            }
        } else if(type === 'day') {
            if(year && regExp.year().test(year) && month && regExp.month().test(month)) { // 연도, 월이 제대로 입력 되었을 때, 일(날짜)에 대한 error 메시지
                return true;
            } else {
                return false;
            }
        }
    }
    setForeignerYn(e, idx) {
        runInAction(() => {
            LiquidationStepModel.directors[idx].foreignerYn = e.target.value === "true";
        })
    }
    setKoreanName(e, idx) {
        runInAction(() => {
            LiquidationStepModel.directors[idx].koreanName = e.target.value;
        })
    }
    setEnglishName(e, idx) {
        runInAction(() => {
            LiquidationStepModel.directors[idx].englishName = e.target.value;
        })
    }
    setResidenceReportYn(e, idx) {
        runInAction(() => {
            LiquidationStepModel.directors[idx].residenceReportYn = e.target.value === "true";
        })
    }
    setNationalityType(e, idx) {
        runInAction(() => {
            LiquidationStepModel.directors[idx].nationalityType = e.target.value;
        })
    }
    setNationalityOthers(e, idx) {
        runInAction(() => {
            LiquidationStepModel.directors[idx].nationalityOthers = e.target.value;
        })
    }
    setYear(e, idx){
        runInAction(() => {
            LiquidationStepModel.directors[idx].birth.year = onlyNumber(e.target.value);
        })
    }
    setMonth(e, idx){
        runInAction(() => {
            LiquidationStepModel.directors[idx].birth.month = onlyNumber(e.target.value);
        })
    }
    setDay(e, idx){
        runInAction(() => {
            LiquidationStepModel.directors[idx].birth.day = onlyNumber(e.target.value);
        })
    }
    monthBlur(e,idx) {
        runInAction(() => {
            let month = e.target.value;
            if(month && month.length===1){
                e.target.value = "0" + month;
            }
            LiquidationStepModel.directors[idx].birth.month = e.target.value;
        })
    }
    dayBlur(e,idx) {
        runInAction(() => {
            let day = e.target.value;
            if(day && day.length===1){
                e.target.value = "0" + day;
            }
            LiquidationStepModel.directors[idx].birth.day = e.target.value;
        })
    }
    displayBirthErrorMessage(idx, type) { 
        const year = this.directors[idx].birth.year;
        const month = this.directors[idx].birth.month;
        if(type === 'month') {
            if(year && regExp.year().test(year)) { //연도가 제대로 입력되었을 때, 월에 대한 error 메시지
                return true;
            } else {
                return false;
            }
        } else if(type === 'day') {
            if(year && regExp.year().test(year) && month && regExp.month().test(month)) { // 연도, 월이 제대로 입력 되었을 때, 일(날짜)에 대한 error 메시지
                return true;
            } else {
                return false;
            }
        }
    }
}

export default new LiquidationVm();