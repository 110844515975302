import { useObserver } from 'mobx-react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './NewSharesAcquirer.module.scss';
import clsx from 'clsx';
import AddBtn from '@comComponents/atoms/Button/AddBtn/AddBtn';
import Drop from '@comComponents/molecules/Dropdown/Dropdown';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import SquareBtn from '@comComponents/atoms/Button/SquareBtn/SquareBtn';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import Icon from '@comComponents/atoms/Icon/Icon';
import Select from '@comComponents/atoms/Input/Select/Select';
import CloseBtn from '@comComponents/atoms/Button/CloseBtn/CloseBtn';
import NewSharesAcquirerVm from './NewSharesAcquirerVm';
import SearchAddress from '@comComponents/atoms/SearchAddress/SearchAddress';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';
import { useEffect } from 'react';
import josa from '@common/module/josa';

function NewSharesAcquirer() {
    useEffect(() => {
        if(NewSharesAcquirerVm.newSharesAcquirers.length === 0) { //출자자 처음 입력할 때, 처음 리스트 추가 및 열기
            NewSharesAcquirerVm.addNewSharesAcquirers();
            NewSharesAcquirerVm.state.openIdx = 0;
        }
    }, [NewSharesAcquirerVm.newSharesAcquirers])

    return useObserver(() => (
        <form onSubmit={(e) => NewSharesAcquirerVm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            <Question list={true}>
                <ContentBox>
                    <div className={Qst.dropHeader}>
                    <div className={Qst.dropTitleWrap}>
                        <div className={Qst.dropTitle}>출자자(=사원)님의 정보를 입력해주세요.</div>
                        <div className={Qst.dropTitleExplain}>고객님께서 작성하신 출자자의 리스트입니다.</div>
                    </div>
                    <AddBtn onClick={() => NewSharesAcquirerVm.addNewSharesAcquirers()}>출자자 추가하기</AddBtn>
                    </div>
                </ContentBox>
            </Question>
            {NewSharesAcquirerVm.newSharesAcquirers.map((item, idx) => {
                return (
                    <Question list={true} key={idx}>
                        <ContentBox>
                            <Drop dataEq={`${idx}`} className={clsx(Qst.dropDown, NewSharesAcquirerVm.setIsOpen(idx) ? 'open' : 'close')} title={NewSharesAcquirerVm.setTitle(idx)} onClick={() => NewSharesAcquirerVm.setDropOpen(idx)} isOpen={NewSharesAcquirerVm.setIsOpen(idx)} closeBtn={() => NewSharesAcquirerVm.setCloseBtn(idx)} error={NewSharesAcquirerVm.personErrorYn(idx)}>
                                <input type="hidden" name={`newSharesAcquirers[${idx}].id`} value={item.id}/>
                                <div className={Qst.spaceBetween}>
                                    <div className={Qst.infoSection}>
                                        <div className={Qst.titleWrap}>
                                            <h2 className={clsx(Qst.title, Qst.inside)}>
                                                <span className={Qst.bold}>출자자님은 어떤 사람(법인)</span>인가요?
                                            </h2>
                                        </div>
                                        <ul className={Qst.answerList} key={idx+item.shareholderType}>
                                            <li><Radio className={Qst.answerLabel} value="KOREAN_PERSON" activeBackground checked={item.shareholderType === "KOREAN_PERSON"} onChange={(e) => NewSharesAcquirerVm.setShareholderType(e,idx)} name={`newSharesAcquirers[${idx}].shareholderType`}>한국인입니다.</Radio></li>
                                            <li><Radio className={Qst.answerLabel} value="FOREIGNER_PERSON" activeBackground checked={item.shareholderType === "FOREIGNER_PERSON"} onChange={(e) => NewSharesAcquirerVm.setShareholderType(e,idx)} name={`newSharesAcquirers[${idx}].shareholderType`}>외국인입니다.</Radio></li>
                                            <li><Radio className={Qst.answerLabel} value="KOREAN_COMPANY" activeBackground checked={item.shareholderType === "KOREAN_COMPANY"} onChange={(e) => NewSharesAcquirerVm.setShareholderType(e,idx)} name={`newSharesAcquirers[${idx}].shareholderType`}>한국법인입니다.</Radio></li>
                                            <li><Radio className={Qst.answerLabel} value="FOREIGNER_COMPANY" activeBackground checked={item.shareholderType === "FOREIGNER_COMPANY"} onChange={(e) => NewSharesAcquirerVm.setShareholderType(e,idx)} name={`newSharesAcquirers[${idx}].shareholderType`}>외국법인입니다.</Radio></li>
                                            <li><Radio className={Qst.answerLabel} value="FUND_AND_INVESTMENT_ASSOCIATION" activeBackground checked={item.shareholderType === "FUND_AND_INVESTMENT_ASSOCIATION"} onChange={(e) => NewSharesAcquirerVm.setShareholderType(e,idx)} name={`newSharesAcquirers[${idx}].shareholderType`}>펀드/투자조합입니다.</Radio></li>
                                        </ul>
                                        <Error name={`newSharesAcquirers[${idx}].shareholderType`} value={item.shareholderType} errorCase={{required:'사람(법인)을 선택해 주세요.'}}/>
                                    </div>
                                    <div className={Qst.qnaSection}>
                                        <Qna tip="출자자 입력" className={Qst.qnaTitle}>
                                            <div className={Qst.qnaBox}>
                                                <QText>출자자는 누구인가요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>출자자는 이번 자본금 증액시 회사에 자본금을 내고, 출자지분을 받을 사람/법인을 말합니다.</Answer>
                                                </div>
                                            </div>
                                        </Qna>
                                    </div>
                                </div>
                                {item.shareholderType === "KOREAN_PERSON" && //한국인
                                <>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    <span className={Qst.bold}>성함을 입력</span>해 주세요.
                                                </h2>
                                            </div>
                                            <InputText placeholder="ex)홍길동" style={{width:'316px'}} value={item.koreanName} onChange={(e) => NewSharesAcquirerVm.setKoreanName(e,idx)} name={`newSharesAcquirers[${idx}].koreanName`} errorText={<Error name={`newSharesAcquirers[${idx}].koreanName`} value={item.koreanName} errorCase={{required:'성함을 입력해 주세요.'}}/>}/><span className={Qst.nameInput}>님</span>
                                        </div>
                                    </div>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    <span className={Qst.bold}>{item.koreanName? item.koreanName : '출자자'}님의 주민등록번호를 입력</span>해 주세요.
                                                </h2>
                                                <div className={Qst.titleExplain}>주민등록번호는 헬프미 스마트 보안 시스템에 의하여 안전하게 보관됩니다.</div>
                                            </div>
                                            <InputText placeholder="111111-1111111" style={{width:'316px'}} textType="kssn" value={item.kssn} onChange={(e) => NewSharesAcquirerVm.setKssn(e,idx)} name={`newSharesAcquirers[${idx}].kssn`} errorText={<Error name={`newSharesAcquirers[${idx}].kssn`} value={item.kssn} errorCase={{
                                                required: '주민등록번호를 입력해 주세요.',
                                                validate: {
                                                func: regExp.kssn(item.kssn),
                                                message: '올바른 주민등록번호를 입력해 주세요.'
                                                }
                                            }}/>} />
                                        </div>
                                    </div>
                                    <div className={Qst.spaceBetween} style={{paddingBottom:'30px'}}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    {item.koreanName? item.koreanName : '출자자'}님의 <span className={Qst.bold}>주민등록등(초)본상 도로명주소</span>를 입력해 주세요. 
                                                </h2>
                                            </div>
                                            <InputText style={{width:'560px'}} value={item?.koreanAddress} onChange={(e) => NewSharesAcquirerVm.setKoreanAddress(e,idx)} name={`newSharesAcquirers[${idx}].koreanAddress`} errorText={<Error name={`newSharesAcquirers[${idx}].koreanAddress`} value={item?.koreanAddress} errorCase={{required:'주소를 입력해 주세요.'}}/>}/>
                                        </div>
                                        <div className={Qst.qnaSection}>
                                            <Qna className={Qst.noTitle}>
                                                <div className={Qst.qnaBox}>
                                                    <QText>주민등록상 주소는 어디서 확인하나요?</QText>
                                                    <div className="answerBox">
                                                        <Answer className={Qst.answer}>정부24 홈페이지에서 무료로 확인가능합니다.</Answer>
                                                        <p className={Qst.explain}>
                                                            정부24 홈페이지에 로그인하기 위해서는 금융인증서, 공동인증서(구 공인인증서), 간편인증서 등이 필요합니다.
                                                        </p>
                                                        <LikeBtn className={Qst.likeBtn} href="https://www.gov.kr/mw/AA020InfoCappView.do?CappBizCD=13100000015&HighCtgCD=A01010001&Mcode=10200" target="_blank">정부24 홈페이지 바로가기</LikeBtn>
                                                    </div>
                                                </div>
                                                <div className={Qst.qnaBox}>
                                                    <QText>주소에는 영어를 쓸 수 있나요?</QText>
                                                    <div className="answerBox">
                                                        <Answer className={Qst.answer}>등기소에서는 주소를 적을 때, 한글 숫자만 허용합니다. (영어X)</Answer>
                                                        <p className={Qst.explain}>
                                                            주소에 영어가 들어가 있다면, 한글로 변환해 주세요.
                                                        </p>
                                                        <TextBox className={clsx(Qst.textBox, Qst.addressTextBox)} title="예시">
                                                            <div className={Qst.textWrap}>
                                                                <span className={Qst.false}>B동, 202-1001</span><Icon icon="arrow" className={Qst.iconArrow}/><span className={Qst.true}>비동, 202동 1001호</span>
                                                            </div>
                                                        </TextBox>
                                                    </div>
                                                </div>
                                                <div className={Qst.qnaBox}>
                                                    <QText>주민등록상 주소와 다른주소를 입력하면 어떻게 되나요?</QText>
                                                    <div className="answerBox">
                                                        <Answer className={Qst.answer}>주민등록상 주소와 약간이라도 다른 주소를 입력하는 경우, 등기소에서 법인설립 거절을 합니다.</Answer>
                                                        <p className={Qst.explain}>
                                                            출자자님의 주소는 등기부에 기재하기 때문에, 주민등록상 주소와 완벽하게 일치해야 합니다. 만약 약간이라도 다른 주소를 입력하는 경우, 등기소에서 법인설립 거절을 하고, 이 경우 모든 서류를 바꾸어 재접수를 해야 합니다. (5~7일 시간 더 걸림) 따라서 꼭 주민등록상 주소를 정확히 확인한 후, 입력해주세요. 일반적으로 사용하는 아파트 이름 등이 주민등록상 기재된 주소와 약간 다른 경우가 존재하기 때문에, 확실히 확인이 필요합니다.
                                                        </p>
                                                    </div>
                                                </div>
                                            </Qna>
                                        </div>
                                    </div>
                                </>
                                }
                                {item.shareholderType === "FOREIGNER_PERSON" && //외국인
                                <>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    <span className={Qst.bold}>국적을 선택</span>해 주세요.
                                                </h2>
                                                <div className={Qst.titleExplain}>
                                                    외국인인 경우 국적, 외국법인인 경우 본점이 위치한 나라를 선택해 주세요.
                                                </div>
                                            </div>
                                            <Select className={Qst.select} style={{width:'142px'}} name={`newSharesAcquirers[${idx}].nationalityType`} value={item.nationalityType ?? ''} onChange={(e) => NewSharesAcquirerVm.setNationalityType(e,idx)} errorText={<Error name={`newSharesAcquirers[${idx}].nationalityType`} value={item.nationalityType} errorCase={{required:'국적을 선택해 주세요.'}} style={{width:'157px'}}/>}>
                                                <option value="">-</option>
                                                <option value="일본">일본</option>
                                                <option value="미국">미국</option>
                                                <option value="영국">영국</option>
                                                <option value="중국">중국(본토)</option>
                                                <option value="프랑스">프랑스</option>
                                                <option value="독일">독일</option>
                                                <option value="캐나다">캐나다</option>
                                                <option value="홍콩">홍콩</option>
                                                <option value="기타">기타</option>
                                            </Select>
                                            {item.nationalityType === "기타" &&
                                                <div className={clsx(Qst.rowWrap, st.rowWrap)}>
                                                    <InputText className={Qst.inputText} style={{width:'276px'}} explain="기타 국적" name={`newSharesAcquirers[${idx}].nationalityOthers`} value={item.nationalityOthers} onChange={(e) => NewSharesAcquirerVm.setNationalityOthers(e,idx)} errorText={<Error name={`newSharesAcquirers[${idx}].nationalityOthers`} value={item.nationalityOthers} errorCase={{required:'기타국적을 입력해 주세요.'}}/>}/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    <span className={Qst.bold}>출자자님의 이름을 로마자와 한글로 입력</span>해 주세요.
                                                </h2>
                                                <div className={Qst.titleExplain}>
                                                    외국인의 정식 명칭을 로마자(영문)와 한글 발음으로 입력해 주세요.
                                                </div>
                                                <div className={Qst.titleExTextBox}>
                                                    <TextBox title="로마자(영문)" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>Jane Austen</span></TextBox>
                                                    <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>제인 오스틴</span></TextBox>
                                                </div>
                                                <div className={Qst.titleExplain}>
                                                    영문이 아닌 외국성함은 로마자(영문)으로 변환하여 한글 발음으로 입력해 주세요.
                                                </div>
                                                <div className={Qst.titleExTextBox}>
                                                    <TextBox title="로마자(영문)로 변환" titleWidth="145px" className={Qst.nameTextBox} style={{width:'171px', height:'40px', padding:'9px 20px'}}>
                                                    <span className={Qst.text}>高竹</span><Icon icon="arrow" className={Qst.arrow} /><span className={Qst.text}>Takamura</span>
                                                    </TextBox>
                                                    <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'134px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>타카무라</span></TextBox>
                                                </div>
                                            </div>
                                            <div className={Qst.rowWrap}>
                                                <InputText className={Qst.inputText} style={{width:'276px'}} explain="로마자(영문) 표기" name={`newSharesAcquirers[${idx}].englishName`} value={item.englishName} onChange={(e) => NewSharesAcquirerVm.setEnglishName(e,idx)} errorText={<Error name={`newSharesAcquirers[${idx}].englishName`} value={item.englishName} errorCase={{
                                                    required: '이름을 로마자로 입력해 주세요.',
                                                    pattern: {
                                                    value: regExp.onlyEnglish().test(item.englishName),
                                                    message: '로마자로 입력해 주세요.'
                                                    }
                                                }}/>} 
                                                />
                                                <InputText className={Qst.inputText} style={{width:'276px'}} explain="한글 발음 표기" name={`newSharesAcquirers[${idx}].koreanName`} value={item.koreanName} onChange={(e) => NewSharesAcquirerVm.setKoreanName(e,idx)} errorText={<Error name={`newSharesAcquirers[${idx}].koreanName`} value={item.koreanName} errorCase={{
                                                    required: '이름을 한글로 입력해 주세요.',
                                                    pattern: {
                                                    value: regExp.onlyKorean().test(item.koreanName),
                                                    message: '한글로 입력해 주세요.'
                                                    }
                                                }}/>} 
                                                />
                                            </div>
                                        </div>
                                        <div className={Qst.qnaSection}>
                                        <Qna className={Qst.noTitle}>
                                            <QText>어떤 문서에 나온 이름을 입력해야 하나요?</QText>
                                            <div className="answerBox">
                                            <Answer className={Qst.answer}>한국에서 거소등록(외국인등록)을 한 외국인이라면, 거소등록증(외국인등록증) 또는 인감증명서에 기재된 성함으로 입력해 주세요.</Answer>
                                            </div>
                                        </Qna>   
                                        </div>
                                    </div>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    <span className={Qst.bold}>{item.koreanName? item.koreanName : '출자자'}님의 여권에 기재된 생년월일을 선택</span>해 주세요.
                                                </h2>
                                            </div>
                                            <div>
                                                <div className={Qst.birthWrap}>
                                                    <InputText style={{width:'130px'}} placeholder="0000" name={`newSharesAcquirers[${idx}].year`} value={item.year} onChange={(e) => NewSharesAcquirerVm.setYear(e,idx)} maxLength="4" />
                                                    <span className={Qst.birthText}>년</span>
                                                </div>
                                                <div className={Qst.birthWrap}>
                                                    <InputText style={{width:'108px'}} placeholder="00" name={`newSharesAcquirers[${idx}].month`} value={item.month} onChange={(e) => NewSharesAcquirerVm.setMonth(e,idx)} maxLength="2" onBlur={(e) => NewSharesAcquirerVm.monthBlur(e,idx)} />
                                                    <span className={Qst.birthText}>월</span>
                                                </div>
                                                <div className={Qst.birthWrap}>
                                                    <InputText style={{width:'108px'}} placeholder="00" name={`newSharesAcquirers[${idx}].day`} value={item.day} onChange={(e) => NewSharesAcquirerVm.setDay(e,idx)} maxLength="2" onBlur={(e) => NewSharesAcquirerVm.dayBlur(e,idx)} />
                                                    <span className={Qst.birthText}>일</span>
                                            </div>
                                        </div>
                                        <div style={{marginTop:'-27px'}}>
                                            <Error name={`newSharesAcquirers[${idx}].year`} value={item.year} 
                                            errorCase={{
                                            required:'연도를 입력해 주세요.',
                                            pattern: {
                                                value: regExp.year().test(item.year),
                                                message: '올바른 연도를 입력해 주세요'               
                                            }
                                            }}
                                            />
                                            <Error name={`newSharesAcquirers[${idx}].month`} value={item.month} 
                                            errorCase={{
                                            required: NewSharesAcquirerVm.displayBirthErrorMessage(idx, 'month') ? '월을 입력해 주세요.' : null,
                                            pattern: {
                                                value: NewSharesAcquirerVm.displayBirthErrorMessage(idx, 'month') ? regExp.month().test(item.month) : null,
                                                message: '올바른 월을 입력해 주세요'               
                                            }
                                            }}
                                            />
                                            <Error name={`newSharesAcquirers[${idx}].day`} value={item.day} 
                                            errorCase={{
                                            required: NewSharesAcquirerVm.displayBirthErrorMessage(idx, 'day') ? '일(날짜)을 입력해 주세요.' : null,
                                            pattern: {
                                                value: NewSharesAcquirerVm.displayBirthErrorMessage(idx, 'day') ? regExp.day().test(item.day) : null,
                                                message: '올바른 일(날짜)을 입력해 주세요.'           
                                            }
                                            }}
                                            />
                                        </div>
                                        </div>
                                    </div>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                        <div className={Qst.titleWrap}>
                                            <h2 className={clsx(Qst.title, Qst.inside)}>
                                                <span className={Qst.bold}>{item.koreanName? item.koreanName : '출자자'}님은 한국에 거소등록 또는 외국인등록을 한 외국인</span> 인가요?
                                            </h2>
                                        </div>
                                            <ul className={Qst.answerList}>
                                                <li><Radio className={Qst.answerLabel} value={true} activeBackground checked={NewSharesAcquirerVm.residenceRegistrationYn(idx) === true} onChange={(e) => NewSharesAcquirerVm.setResidenceRegistrationYn(e, idx)} name={`newSharesAcquirers[${idx}].residenceRegistrationYn`}>네</Radio></li>
                                                <li><Radio className={Qst.answerLabel} value={false} activeBackground checked={NewSharesAcquirerVm.residenceRegistrationYn(idx) === false} onChange={(e) => NewSharesAcquirerVm.setResidenceRegistrationYn(e, idx)} name={`newSharesAcquirers[${idx}].residenceRegistrationYn`}>아니오</Radio></li>
                                            </ul>
                                            <Error name={`newSharesAcquirers[${idx}].residenceRegistrationYn`} value={NewSharesAcquirerVm.residenceRegistrationYn(idx)} errorCase={{required:'거소등록 또는 외국인등록 여부를 선택해 주세요.'}} />
                                        </div>
                                    </div>
                                    {item.residenceRegistrationYn === true && 
                                        <div className={Qst.spaceBetween} style={{paddingBottom:'30px'}}>
                                            <div className={Qst.infoSection}>
                                                <div className={Qst.titleWrap}>
                                                    <h2 className={clsx(Qst.title, Qst.inside)}>
                                                        {item.koreanName? item.koreanName : '출자자'}님의 <span className={Qst.bold}>거소신고(외국인등록) 사실증명상 도로명주소</span>를 입력해 주세요.
                                                    </h2>
                                                </div>
                                                <InputText style={{width:'560px'}} value={item?.koreanAddress} onChange={(e) => NewSharesAcquirerVm.setKoreanAddress(e,idx)} name={`newSharesAcquirers[${idx}].koreanAddress`} errorText={<Error name={`newSharesAcquirers[${idx}].koreanAddress`} value={item?.koreanAddress} errorCase={{required:'주소를 입력해 주세요.'}}/>}/>
                                            </div>
                                        </div>
                                    }
                                    {item.residenceRegistrationYn === false && 
                                        <div className={Qst.spaceBetween}>
                                            <div className={Qst.infoSection}>
                                                <div className={Qst.titleWrap}>
                                                    <h2 className={clsx(Qst.title, Qst.inside)}>
                                                        <span className={Qst.bold}>{item.koreanName? item.koreanName : '출자자'}님의 주소를 로마자와 한글로 입력</span>해 주세요.
                                                    </h2>
                                                    <div className={Qst.titleExplain}>
                                                        외국 주소를 로마자(영문)와 한글 발음으로 입력해 주세요.
                                                    </div>
                                                    <div className={Qst.titleExTextBox}>
                                                        <TextBox title="로마자(영문)" className={Qst.foreignAddressTextBox} style={{width:'458px', height:'40px', padding:'9px 20px'}}>
                                                            <span className={Qst.text}>28445 tomless Ave Torrance CA United States</span>
                                                        </TextBox>
                                                        <TextBox title="한글 발음" className={Qst.foreignAddressTextBox} style={{width:'458px', height:'40px', padding:'9px 20px'}}>
                                                            <span className={Qst.text}>미국 캘리포니아주 토랜스시 애비뉴 28445</span>
                                                        </TextBox>
                                                    </div>
                                                </div>
                                                <div className={Qst.rowWrap}>
                                                    <InputText explain="로마자(영문) 표기" style={{width:'560px'}} value={item?.englishAddress} onChange={(e) => NewSharesAcquirerVm.setEnglishAddress(e,idx)} name={`newSharesAcquirers[${idx}].englishAddress`} errorText={<Error name={`newSharesAcquirers[${idx}].englishAddress`} value={item?.englishAddress} errorCase={{
                                                        required: '주소를 로마자로 입력해 주세요.',
                                                        pattern: {
                                                        value: regExp.onlyEngAddress().test(item?.englishAddress),
                                                        message: '로마자로 입력해 주세요.'
                                                        }
                                                    }}/>}/>
                                                </div>
                                                <div className={Qst.rowWrap}>
                                                    <InputText explain="한글 발음 표기" style={{width:'560px'}} value={item?.koreanAddress} onChange={(e) => NewSharesAcquirerVm.setKoreanAddress(e,idx)} name={`newSharesAcquirers[${idx}].koreanAddress`} errorText={<Error name={`newSharesAcquirers[${idx}].koreanAddress`} value={item?.koreanAddress} errorCase={{
                                                        required: '주소를 한글로 입력해 주세요.',
                                                        pattern: {
                                                        value: regExp.onlyKorNum().test(item?.koreanAddress),
                                                        message: '한글로 입력해 주세요.'
                                                        }
                                                    }}/>}/>
                                                </div>
                                            </div>
                                        </div>  
                                    }
                                </>
                                }
                                {item.shareholderType === "KOREAN_COMPANY" && //한국법인
                                <>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    <span className={Qst.bold}>회사 이름을 입력</span>해 주세요.
                                                </h2>
                                                <div className={Qst.titleExplain}>
                                                    정식명칭을 입력해 주세요. ex) 주식회사 신한은행, 현대산업개발 유한회사
                                                </div>
                                            </div>
                                            <div className={Qst.rowWrap}>
                                                <InputText style={{width:'316px'}} name={`newSharesAcquirers[${idx}].koreanName`} value={item.koreanName} onChange={(e) => NewSharesAcquirerVm.setKoreanName(e,idx)} errorText={<Error name={`newSharesAcquirers[${idx}].koreanName`} value={item.koreanName} errorCase={{required:'회사 이름을 입력해 주세요.'}} />}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={Qst.spaceBetween} style={{paddingBottom:'30px'}}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    <span className={Qst.bold}>{item.koreanName? item.koreanName : '회사'}의 등기부 등본상 도로명주소를 입력</span>해 주세요.
                                                </h2>
                                            </div>
                                            <Badge className={st.CompanyAddressBadge} title="예시" type="example" border={false}>
                                                <img className={st.CompanyAddressBadgeImg} src="/images/company_address_example.png"
                                                alt="회사 등기부 등록상 도로명주소 예시" />
                                            </Badge>
                                            <InputText style={{width:'560px'}} value={item?.koreanAddress} onChange={(e) => NewSharesAcquirerVm.setKoreanAddress(e,idx)} name={`newSharesAcquirers[${idx}].koreanAddress`} errorText={<Error name={`newSharesAcquirers[${idx}].koreanAddress`} value={item?.koreanAddress} errorCase={{required:'주소를 입력해 주세요.'}}/>}/>
                                        </div>
                                    </div>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    <span className={Qst.bold}>{item.koreanName? item.koreanName : '회사'}의 등기부상 대표자를 입력</span>해 주세요.
                                                </h2>
                                                <div className={Qst.titleExplain}>
                                                    대표이사가 2명 이사인 경우, 모두 입력해 주세요.
                                                </div>
                                            </div>
                                            <Badge className={st.CompanyExecutiveBadge} title="예시" type="example" border={false}>
                                                <img className={st.CompanyExecutiveBadgeImg} src="/images/executive_example.png"
                                                alt="회사 등기부상 대표자 예시" />
                                            </Badge>
                                            {item.koreanCompanyRepresentatives.map((item2, idx2) => {
                                                return (
                                                <div className={Qst.rowWrap} key={idx2+item.koreanCompanyRepresentatives}>
                                                    <input type="hidden" name={`newSharesAcquirers[${idx}].koreanCompanyRepresentatives[${idx2}].id`} value={item2?.id || ''}/>
                                                    <Select className={Qst.select} style={{width:'278px'}} name={`newSharesAcquirers[${idx}].koreanCompanyRepresentatives[${idx2}].position`} value={item2?.position ?? ''} explain={idx2 === 0 ? "직책" : null} onChange={(e) => NewSharesAcquirerVm.koreanCompanyRepresentatives_position(e,idx)} errorText={<Error name={`newSharesAcquirers[${idx}].koreanCompanyRepresentatives[${idx2}].position`} value={item2?.position} errorCase={{required:'직책을 선택해 주세요.'}}/>} disabled={idx2 === 0 ? false : true}>
                                                        <option value="">-</option> 
                                                        <option value="대표이사">대표이사</option>
                                                        <option value="공동대표이사">공동대표이사</option>
                                                        <option value="사내이사">사내이사</option>
                                                        <option value="대표업무집행자">대표업무집행자</option>
                                                        <option value="공동대표업무집행자">공동대표업무집행자</option>
                                                        <option value="업무집행자">업무집행자</option>
                                                        <option value="업무집행조합원">업무집행조합원</option>
                                                        <option value="공동업무집행조합원">공동업무집행조합원</option>
                                                        <option value="대표자">대표자</option>
                                                        <option value="공동대표자">공동대표자</option>
                                                    </Select>
                                                    <div className={st.compoWrap}>
                                                        <InputText className={clsx(Qst.inputText,st.inputText)} style={{width:'278px'}} explain={idx2 === 0 ? "성함" : null} 
                                                        name={`newSharesAcquirers[${idx}].koreanCompanyRepresentatives[${idx2}].name`} 
                                                        value={item2?.name} 
                                                        onChange={(e) => NewSharesAcquirerVm.koreanCompanyRepresentatives_name(e,idx,idx2)} 
                                                        errorText={<Error name={`newSharesAcquirers[${idx}].koreanCompanyRepresentatives[${idx2}].name`} value={item2?.name} errorCase={{required:'성함을 입력해 주세요.'}}/>}/>
                                                        {idx2 !== 0  &&
                                                            <CloseBtn className={st.closeBtn} onClick={() => NewSharesAcquirerVm.setKoreanCompanyRepresentativesCloseBtn(idx,idx2)} />
                                                        }
                                                    </div>
                                                </div>  
                                                )
                                            })}
                                            <div className={clsx(Qst.rowWrap, st.representativeAddRowWrap)}>
                                                <button type="button" className={st.representativeAddBtn} onClick={() => NewSharesAcquirerVm.addKoreanCompanyRepresentatives(idx)}>대표자 추가하기<Icon icon="addGray" className={st.addGray} /></button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                }
                                {item.shareholderType === "FOREIGNER_COMPANY" && //외국법인
                                <>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    <span className={Qst.bold}>국적을 선택</span>해 주세요.
                                                </h2>
                                                <div className={Qst.titleExplain}>
                                                    외국인인 경우 국적, 외국법인인 경우 본점이 위치한 나라를 선택해 주세요.
                                                </div>
                                            </div>
                                            <Select className={Qst.select} style={{width:'142px'}} name={`newSharesAcquirers[${idx}].nationalityType`} value={item.nationalityType ?? ''} onChange={(e) => NewSharesAcquirerVm.setNationalityType(e,idx)} errorText={<Error name={`newSharesAcquirers[${idx}].nationalityType`} value={item.nationalityType} errorCase={{required:'국적을 선택해 주세요.'}} style={{width:'157px'}}/>}>
                                                <option value="">-</option>
                                                <option value="일본">일본</option>
                                                <option value="미국">미국</option>
                                                <option value="영국">영국</option>
                                                <option value="중국">중국(본토)</option>
                                                <option value="프랑스">프랑스</option>
                                                <option value="독일">독일</option>
                                                <option value="캐나다">캐나다</option>
                                                <option value="홍콩">홍콩</option>
                                                <option value="기타">기타</option>
                                            </Select>
                                            {item.nationalityType === "기타" &&
                                                <div className={clsx(Qst.rowWrap, st.rowWrap)}>
                                                    <InputText className={Qst.inputText} style={{width:'276px'}} explain="기타 국적" name={`newSharesAcquirers[${idx}].nationalityOthers`} value={item.nationalityOthers} onChange={(e) => NewSharesAcquirerVm.setNationalityOthers(e,idx)} errorText={<Error name={`newSharesAcquirers[${idx}].nationalityOthers`} value={item.nationalityOthers} errorCase={{required:'기타국적을 입력해 주세요.'}}/>}/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    <span className={Qst.bold}>회사 이름을 로마자와 한글로 입력</span>해 주세요.
                                                </h2>
                                                <div className={Qst.titleExplain}>
                                                    외국법인의 정식 명칭을 로마자(영문)와 한글 발음으로 입력해 주세요.
                                                </div>
                                                <div className={Qst.titleExTextBox}>
                                                    <TextBox title="로마자(영문)" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>Apple</span></TextBox>
                                                    <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>애플</span></TextBox>
                                                </div>
                                                <div className={Qst.titleExplain} style={{marginTop:'8px'}}>
                                                    영문이 아닌 회사이름은, 로마자(영문)으로 변환하여 한글 발음으로 입력해 주세요.
                                                </div>
                                                <div className={Qst.titleExTextBox}>
                                                    <TextBox title="로마자(영문)로 변환" titleWidth="145px" className={Qst.nameTextBox} style={{width:'171px', height:'40px', padding:'9px 20px'}}>
                                                    <span className={Qst.text}>高竹</span><Icon icon="arrow" className={Qst.arrow} /><span className={Qst.text}>Takamura</span>
                                                    </TextBox>
                                                    <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'134px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>타카무라</span></TextBox>
                                                </div>
                                            </div>   
                                            <div className={Qst.rowWrap}>
                                                <InputText className={Qst.inputText} style={{width:'276px'}} explain="로마자(영문) 표기" name={`newSharesAcquirers[${idx}].englishName`} value={item.englishName}
                                                onChange={(e) => NewSharesAcquirerVm.setEnglishName(e, idx)} errorText={<Error name={`newSharesAcquirers[${idx}].englishName`} value={item.englishName} errorCase={{
                                                    required: '회사 이름을 로마자로 입력해 주세요.',
                                                    pattern: {
                                                    value: regExp.onlyEngNum().test(item.englishName),
                                                    message: '로마자로 입력해 주세요.'
                                                    }
                                                }}/>} />
                                                <InputText className={Qst.inputText} style={{width:'276px'}} explain="한글 발음 표기" name={`newSharesAcquirers[${idx}].koreanName`} value={item.koreanName} onChange={(e) => NewSharesAcquirerVm.setKoreanName(e,idx)} errorText={<Error name={`newSharesAcquirers[${idx}].koreanName`} value={item.koreanName} errorCase={{
                                                    required: '회사 이름을 한글로 입력해 주세요.',
                                                    pattern: {
                                                    value: regExp.onlyKorNum().test(item.koreanName),
                                                    message: '한글로 입력해 주세요.'
                                                    }
                                                }}/>} />
                                            </div> 
                                        </div>
                                    </div>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                        <div className={Qst.titleWrap}>
                                            <h2 className={clsx(Qst.title, Qst.inside)}>
                                            <span className={Qst.bold}>{item.koreanName? item.koreanName : '회사'}의 주소를 로마자와 한글로 입력</span>해 주세요.
                                            </h2>
                                            <div className={Qst.titleExplain}>
                                            외국 주소를 로마자(영문)와 한글 발음으로 입력해 주세요.
                                            </div>
                                            <div className={Qst.titleExTextBox}>
                                            <TextBox title="로마자(영문)" className={Qst.foreignAddressTextBox} style={{width:'458px', height:'40px', padding:'9px 20px'}}>
                                                <span className={Qst.text}>28445 tomless Ave Torrance CA United States</span>
                                            </TextBox>
                                            <TextBox title="한글 발음" className={Qst.foreignAddressTextBox} style={{width:'458px', height:'40px', padding:'9px 20px'}}>
                                                <span className={Qst.text}>미국 캘리포니아주 토랜스시 애비뉴 28445</span>
                                            </TextBox>
                                            </div>
                                        </div>
                                        <div className={Qst.rowWrap}>
                                            <InputText explain="로마자(영문) 표기" className={Qst.inputText} style={{width:'560px'}} name={`newSharesAcquirers[${idx}].foreignAddress.englishAddress`} value={item?.foreignAddress?.englishAddress} onChange={(e) => NewSharesAcquirerVm.foreignAddress_englishAddress(e,idx)} errorText={<Error name={`newSharesAcquirers[${idx}].foreignAddress.englishAddress`} value={item?.foreignAddress?.englishAddress} errorCase={{
                                            required: '회사 주소를 로마자로 입력해 주세요.',
                                            pattern: {
                                                value: regExp.onlyEngAddress().test(item?.foreignAddress?.englishAddress),
                                                message: '로마자로 입력해 주세요.'
                                            }
                                            }}/>} />
                                        </div>
                                        <div className={Qst.rowWrap}>
                                            <InputText explain="한글 발음 표기" className={Qst.inputText} style={{width:'560px'}} name={`newSharesAcquirers[${idx}].foreignAddress.koreanAddress`} value={item?.foreignAddress?.koreanAddress} onChange={(e) => NewSharesAcquirerVm.foreignAddress_koreanAddress(e,idx)} errorText={<Error name={`newSharesAcquirers[${idx}].foreignAddress.koreanAddress`} value={item?.foreignAddress?.koreanAddress} errorCase={{
                                            required: '회사 주소를 한글로 입력해 주세요.',
                                            pattern: {
                                                value: regExp.onlyKorNum().test(item?.foreignAddress?.koreanAddress),
                                                message: '한글로 입력해 주세요.'
                                            }
                                            }}/>} />
                                        </div>
                                        </div>
                                    </div>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                        <div className={Qst.titleWrap}>
                                            <h2 className={clsx(Qst.title, Qst.inside)}>
                                            <span className={Qst.bold}>{item.koreanName? item.koreanName : '회사'}의 대표자 성함을 로마자와 한글로 입력</span>해 주세요.
                                            </h2>
                                            <div className={Qst.titleExplain}>
                                            영문이 아닌 성함은 변환하여 로마자(영문)와 한글 발음으로 입력해 주세요.
                                            </div>
                                        </div>
                                        {item.foreignerCompanyRepresentatives.map((item2, idx2) => {
                                            return (
                                                <div className={Qst.rowWrap} key={idx2+item.foreignerCompanyRepresentatives}>
                                                    <input type="hidden" name={`newSharesAcquirers[${idx}].foreignerCompanyRepresentatives[${idx2}].id`} value={item2?.id || ''} />
                                                    <InputText explain={idx2===0 ? "로마자(영문) 표기" : null} className={Qst.inputText} style={{width:'276px'}} name={`newSharesAcquirers[${idx}].foreignerCompanyRepresentatives[${idx2}].englishName`} value={item2?.englishName} onChange={(e) => NewSharesAcquirerVm.foreignerCompanyRepresentatives_englishName(e,idx,idx2)} errorText={<Error name={`newSharesAcquirers[${idx}].foreignerCompanyRepresentatives[${idx2}].englishName`} value={item2?.englishName} errorCase={{
                                                    required: '성함을 로마자로 입력해 주세요.',
                                                    pattern: {
                                                        value: regExp.onlyEnglish().test(item2?.englishName),
                                                        message: '로마자로 입력해 주세요.'
                                                    }
                                                    }}/>} />
                                                    <div className={st.compoWrap}>
                                                        <InputText explain={idx2===0 ? "한글 발음 표기" : null} className={clsx(Qst.inputText, st.inputText)} style={{width:'276px'}} name={`newSharesAcquirers[${idx}].foreignerCompanyRepresentatives[${idx2}].koreanName`} value={item2?.koreanName} onChange={(e) => NewSharesAcquirerVm.foreignerCompanyRepresentatives_koreanName(e,idx,idx2)} errorText={<Error name={`newSharesAcquirers[${idx}].foreignerCompanyRepresentatives[${idx2}].koreanName`} value={item2?.koreanName} errorCase={{
                                                            required: '성함을 한글로 입력해 주세요.',
                                                            pattern: {
                                                            value: regExp.onlyKorean().test(item2?.koreanName),
                                                            message: '한글로 입력해 주세요.'
                                                            }
                                                        }}/>} />
                                                        {idx2 !== 0 && 
                                                            <CloseBtn className={st.closeBtn} onClick={() => NewSharesAcquirerVm.setForeignerCompanyRepresentativesCloseBtn(idx,idx2)}/>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <div className={clsx(Qst.rowWrap, st.representativeAddRowWrap)}>
                                            <button type="button" className={st.representativeAddBtn} onClick={() => NewSharesAcquirerVm.foreignerCompanyRepresentatives(idx)}>대표자 추가하기<Icon icon="addGray" className={st.addGray} /></button>
                                        </div>
                                        </div>
                                    </div>
                                </>
                                }
                                {item.shareholderType === "FUND_AND_INVESTMENT_ASSOCIATION" && //펀드/투자조합
                                <>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    <span className={Qst.bold}>펀드/투자조합의 이름을 입력</span>해 주세요.
                                                </h2>
                                                <div className={Qst.titleExplain}>
                                                    고유번호증에 나오는 정식명칭을 입력해 주세요.
                                                </div>
                                            </div>
                                            <Badge className={st.FundInvestmentBadge} title="예시" type="example" border={false}>
                                                <img className={st.FundInvestmentBadgeImg} src="/images/fund_Investment.png"
                                                alt="고유번호증 예시" />
                                            </Badge>
                                            <InputText style={{width:'316px'}} value={item.koreanName} onChange={(e) => NewSharesAcquirerVm.setKoreanName(e,idx)} name={`newSharesAcquirers[${idx}].koreanName`} errorText={<Error name={`newSharesAcquirers[${idx}].koreanName`} value={item.koreanName} errorCase={{required:'성함을 입력해 주세요.'}}/>}/>
                                        </div>
                                    </div>
                                    <div className={Qst.spaceBetween} style={{paddingBottom:'30px'}}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    {item.koreanName? item.koreanName : '출자자님'}의 <span className={Qst.bold}>도로명주소</span>를 입력해 주세요. 
                                                </h2>
                                            </div>
                                            <InputText style={{width:'560px'}} value={item?.koreanAddress} onChange={(e) => NewSharesAcquirerVm.setKoreanAddress(e,idx)} name={`newSharesAcquirers[${idx}].koreanAddress`} errorText={<Error name={`newSharesAcquirers[${idx}].koreanAddress`} value={item?.koreanAddress} errorCase={{required:'주소를 입력해 주세요.'}}/>}/>
                                        </div>
                                    </div>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    {item.koreanName? item.koreanName : '출자자님'}의 <span className={Qst.bold}>업무집행조합원 또는 대표자</span>를 입력해 주세요.
                                                </h2>
                                            </div>
                                            <Badge className={st.FundInvestmentBadge} title="예시" type="example" border={false}>
                                                <img className={st.FundInvestmentBadgeImg} src="/images/fund_Investment_2.png"
                                                alt="고유번호증 예시" />
                                            </Badge>
                                            {item.representatives.map((item2, idx2) => {
                                                return (
                                                    <>
                                                        <div className={clsx(Qst.rowWrap, st.representativeRowWrap)} key={idx2+item.representatives}>
                                                            <input type="hidden" name={`newSharesAcquirers[${idx}].representatives[${idx2}].id`} value={item2?.id || ''} />
                                                            <InputText className={Qst.inputText} style={{width:'536px'}} explain={idx2 === 0 ? "이름" : null} name={`newSharesAcquirers[${idx}].representatives[${idx2}].name`} value={item2.name} onChange={(e) => NewSharesAcquirerVm.setRepresentativesName(e, idx, idx2)} errorText={<Error name={`newSharesAcquirers[${idx}].representatives[${idx2}].name`} value={item2.name} errorCase={{required: '업무집행조합원 또는 대표자를 입력해 주세요.'}}/>} />
                                                            {idx2 !== 0 && 
                                                                <CloseBtn className={st.representativeCloseBtn} onClick={() => NewSharesAcquirerVm.setRepresentativesCloseBtn(idx,idx2)}/>
                                                            }
                                                        </div>
                                                    </>
                                                )
                                            })}
                                            <div className={clsx(Qst.rowWrap, st.representativeAddRowWrap)} style={{width:'536px'}}>
                                                <button type="button" className={st.representativeAddBtn} onClick={() => NewSharesAcquirerVm.addRepresentatives(idx)}>추가하기<Icon icon="addGray" className={st.addGray} /></button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                }
                                {item.shareholderType &&
                                <>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    {NewSharesAcquirerVm.displayKoreanName(item.shareholderType, item.koreanName)} <span className={Qst.bold}>기존 출자자(=사원)</span>인가요?
                                                </h2>
                                            </div>
                                            <ul className={Qst.answerList}>
                                                <li><Radio className={Qst.answerLabel} value={true} activeBackground checked={item.existingShareholdersYn === true} onChange={(e) => NewSharesAcquirerVm.setExistingShareholdersYn(e,idx)} name={`newSharesAcquirers[${idx}].existingShareholdersYn`}>기존 출자자입니다.</Radio></li>
                                                <li><Radio className={Qst.answerLabel} value={false} activeBackground checked={item.existingShareholdersYn === false} onChange={(e) => NewSharesAcquirerVm.setExistingShareholdersYn(e,idx)} name={`newSharesAcquirers[${idx}].existingShareholdersYn`}>새로운 출자자입니다. (기존 출자자X)</Radio></li>
                                            </ul>
                                            <Error name={`newSharesAcquirers[${idx}].existingShareholdersYn`} value={item.existingShareholdersYn} errorCase={{required:'기존 출자자인지 신규 출자자인지 선택해 주세요.'}}/>
                                        </div>
                                    </div>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                {NewSharesAcquirerVm.displayKoreanName(item.shareholderType, item.koreanName)} <span className={Qst.bold}>어떤 종류의 출자</span>를 하나요?
                                                </h2>
                                            </div>
                                            <ul className={Qst.answerList}>
                                                <li><Radio className={Qst.answerLabel} value="유상증자" activeBackground checked={item.resourceType === "유상증자"} onChange={(e) => NewSharesAcquirerVm.setResourceType(e,idx)} name={`newSharesAcquirers[${idx}].resourceType`}>일반 자본금 증액<br/><span className={st.resourceTypeExplain}>회사에 돈을 지급하고 새로운 지분을 발행받습니다.</span></Radio></li>
                                                <li><Radio className={Qst.answerLabel} value="가수금" activeBackground checked={item.resourceType === "가수금"} onChange={(e) => NewSharesAcquirerVm.setResourceType(e,idx)} name={`newSharesAcquirers[${idx}].resourceType`}>가수금 증자<br/><span className={st.resourceTypeExplain}>회사에 빌려준 돈(가수금)을 출자전환합니다.</span></Radio></li>
                                                <li><Radio className={Qst.answerLabel} value="유상증자_가수금" activeBackground checked={item.resourceType === "유상증자_가수금"} onChange={(e) => NewSharesAcquirerVm.setResourceType(e,idx)} name={`newSharesAcquirers[${idx}].resourceType`}>일반 자본금 증액 + 가수금 증자<br/><span className={st.resourceTypeExplain}>일반 자본금 증액 및 가수금 증자를 동시에 진행합니다.</span></Radio></li>
                                            </ul>
                                            <Error name={`newSharesAcquirers[${idx}].resourceType`} value={item.resourceType} errorCase={{required:'신주발행 종류를 선택해 주세요.'}}/>
                                        </div>
                                        <div className={Qst.qnaSection}>
                                            <Qna className={Qst.noTitle}>
                                                <div className={Qst.qnaBox}>
                                                    <QText>가수금 증자는 무엇인가요?</QText>
                                                    <div className="answerBox">
                                                        <Answer className={Qst.answer}>가수금 증자는 회사에 빌려준 돈이 있는 경우, 이 돈을 지분으로 출자전환하는 것입니다.</Answer>
                                                        <p className={Qst.explain}>
                                                            소형 법인이 자금조달을 할 때, 자본금을 증액하는 것은 번거롭고, 은행에서 돈을 빌리기에는 이자가 부담되어서 임원 또는 법인 관련된 사람의 돈을 회사 자금으로 입금하는 경우가 종종 있습니다. 이러한 돈을 재무제표상 "가수금"이라고 표현합니다.
                                                        </p>
                                                        <p className={Qst.explain}>
                                                            회사 입장에서는 가수금을 갚아 버릴 수 있지만, 충분한 현금이 없는 경우, 이를 지분으로 변경하는 가수금 증자(=가수금 출자전환)을 할 수 있습니다. 이는 회사가 가수금에 상응하는 지분을 발행하는 방식으로 이루어집니다.
                                                        </p>
                                                    </div>
                                                </div>
                                            </Qna>
                                        </div>
                                    </div>
                                </>
                                }
                            </Drop>
                        </ContentBox>
                    </Question>
                )
            })}
            <Question list={true}>
                <ContentBox>
                <button type="button" className={Qst.stockholderAddBtn} onClick={() => NewSharesAcquirerVm.addNewSharesAcquirers()}>출자자 추가하기<Icon icon="addGray" className={Qst.addGray}/></button>
                </ContentBox>
            </Question>
            {/* 도로명주소 팝업 */}
            {NewSharesAcquirerVm.koreanAddressModal && 
                <SearchAddress onClose={() => NewSharesAcquirerVm.openKoreanAddressModal(false,null)} onComplete={(data) => NewSharesAcquirerVm.koreanAddressComplate(data)} />
            }
        </form>
    ));
}

export default NewSharesAcquirer;