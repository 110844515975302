import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import minus from '../../../asset/icon/btn_minus.svg';
import minusDim from '../../../asset/icon/btn_minus_dim.svg';
import plus from '../../../asset/icon/btn_plus.svg';
import plusDim from '../../../asset/icon/btn_plus_dim.svg';

const InputWrap = styled.div`
    position: relative;
    display: inline-block;
    width: 102px;
    height: 38px;
    padding: 0 12px;
    border: 1px solid #9a9a9a;
    border-radius: 4px;
    background-color: #fff;
`;

const Numberinput = styled.input`
    position: absolute;
    top: 3px;
    left: 38px;
    width: 24px;
    color: #000;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.5px;
    text-align: center;
    background-color: transparent;

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button { 
        -webkit-appearance: none;
        margin: 0;
    }
    &[type=number] { 
        -moz-appearance: textfield;
    }
`;

const Minus = styled.button`
    position: absolute;
    left: 12px;
    top: 10px;
    width: 17px;
    height: 17px;
    border: none;
    background-color: transparent;
    background-image: url(${minus});
    background-size: cover;
    background-repeat: no-repeat;
    
    ${(props) => props.value === 0 && 
    `
    background-image: url(${minusDim});
    `}

    &:active {
        left: 13px;
        top: 11px;
    }

    &:disabled {
        cursor: not-allowed;
    }
`;

const Plus = styled.button`
    position: absolute;
    right: 12px;
    top: 10px;
    width: 17px;
    height: 17px;
    border: none;
    background-color: transparent;
    background-image: url(${plus});
    background-size: cover;
    background-repeat: no-repeat;

    ${(props) => props.value === 10 && 
    `
    background-image: url(${plusDim});
    `}

    &:active {
        right: 10px;
        top: 11px;
    }

    &:disabled {
        cursor: not-allowed;
    }
`;

/**
* NumberUpdown 컴포넌트는 최소 수량: 0, 최대 수량: 10 인 컴포넌트이다.  
*/

const Input = React.forwardRef((props, ref) => {

    const [isChange, setIsChange] = useState(false);

    const onMinus = (e) => {
        props.onMinus(e);
        if(isChange) setIsChange(false)
        else setIsChange(true)
    }

    const onPlus = (e) => {
        props.onPlus(e)
        if(isChange) setIsChange(false)
        else setIsChange(true)
    }

    const onBlur = (e) => { 
        if(e.target.value === '') {
            e.target.value = 0;
        }
    }

    const keyPress = (e) => {
        let val = e.keyCode;
        if((val >= 48) && (val <=57)) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <InputWrap>
            <Minus type="button" value={props.value} onClick={(e) => onMinus(e)} disabled={props.value === 0 ? true : null }/>

            <Numberinput type="number" key={isChange} name={props.name} className={props.className}
            value={props.value ?? ''} onChange={(e) => props.onChange(e)} ref={ref}
            onBlur={(e) => onBlur(e)} onKeyPress={(e) => keyPress(e)}/>
            
            <Plus type="button" value={props.value} onClick={(e) => onPlus(e)} disabled={props.value === Number(props.disabledCount) ? true : null }/>
        </InputWrap>        
    )
});

Input.propTypes = {
    /**
    *  input 이름
    */
    name: PropTypes.string,
    /**
    *  class명
    */
    className: PropTypes.string,
    /**
    *  style 속성
    */
    style: PropTypes.object,
    /**
    *  input 값
    */
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    /**
    *  minus 버튼 click 이벤트
    */
    onMinus: PropTypes.func,
    /**
    *  plus 버튼 click 이벤트
    */
    onPlus: PropTypes.func,
    /**
    *  input change 이벤트
    */
    onChange: PropTypes.func,
    /**
    *  input 포커스 해지될 때 이벤트
    */
    onBlur: PropTypes.func,
    /**
    *  input 입력(숫자만 입력 가능하도록 작성함)
    */
    onKeyPress: PropTypes.func,
    /**
    *  버튼 disbaled 수량
    */
    disabledCount: PropTypes.string,
};

Input.defaultProps = {
    name: null,
    className: null,
    style: {},
    value: '',
    onMinus: () => {},
    onPlus: () => {},
    onChange : () => {},
    onBlur : () => {},
    onKeyPress : () => {},
    disabledCount: '10000'
};

export default Input;

