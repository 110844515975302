import { runInAction, observable, toJS } from "mobx";
import announceMethodStepModel from '@model/changeRegistration/announceMethodStepModel';
import moduleService from "@service/moduleService";
import { _alert } from "@model/dialogModel";
import formDataToJson, { findPos } from '@common/module/submit';
import stepService from "@service/stepService";
class AnnounceMethodVm {
    constructor() {
        this.state = observable({
            homepageAddressDisabledYn: false
        });
    }
    get hasHomepageYn() {
        return announceMethodStepModel.hasHomepageYn;
    }
    setHasHomepageYn(e) {
        runInAction(() => {
            announceMethodStepModel.hasHomepageYn = e.target.value === "true";
        })
    }
    get homepageUrl() {
        return announceMethodStepModel.homepageUrl;
    }
    setHomepageUrl(e) {
        announceMethodStepModel.homepageUrl = e.target.value;
        announceMethodStepModel.validUrlCheckType = null;
    }
    get validUrlCheckType() {
        return announceMethodStepModel.validUrlCheckType;
    }
    async setValidUrlCheckType() {
        if(this.homepageUrl) {
            this.state.homepageAddressDisabledYn = true;
            announceMethodStepModel.validUrlCheckType = await moduleService.homepageAddressValidationCheck(this.homepageUrl);
            this.state.homepageAddressDisabledYn = false;
        } else {
            await _alert('홈페이지 주소를 입력해주세요.')
        }
    }
    get newspaperCompanyList() {
        return announceMethodStepModel.newspaperCompanyList;
    }
    get newspaperCompany() {
        return announceMethodStepModel.newspaperCompany;
    }
    setNewspaperCompany(e) {
        announceMethodStepModel.newspaperCompany = e.target.value;
    }
    get customNewspaperCompany() {
        return announceMethodStepModel.customNewspaperCompany;
    }
    get originAnnounceMethod() {
        return announceMethodStepModel.originAnnounceMethod;
    }
    setCustomNewspaperCompany(e) {
        announceMethodStepModel.customNewspaperCompany = e.target.value;
    }
    submit(e) { 
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        }else{
            let param = formDataToJson(e.target).data;
            if(this.hasHomepageYn === true) {
                if(this.validUrlCheckType) {
                    if(this.validUrlCheckType === 'CANNOT_ACCESS') {
                        return _alert('접속 불가능한 홈페이지 주소입니다.')
                    }
                    param.validUrlCheckType = this.validUrlCheckType;
                }else {
                    const urlCheck = setInterval(() => {
                        clearInterval(urlCheck);
                        let top = findPos(document.querySelector('.homepageUrl')) - 185;
                        window.scroll(0, top)
                    }, 30)
                    return _alert('홈페이지 접속확인을 해주세요.')
                }
            } 
            stepService.saveStep(param);         
        }
    }
}
export default new AnnounceMethodVm();