import { runInAction, toJS } from "mobx";
import etcInfoModel from "@model/establish/etcInfoModel";
import shareholderAndExecutiveInfoModel from "@model/establish/shareholderAndExecutiveInfoModel";
import stepService from "@service/stepService";
import smartformAppModel from "@model/smartformAppModel";
import formDataToJson from '@common/module/submit';

class viewModel {
    submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            stepService.saveStep(param);            
        }
    }
    get canUseCertificateYn() {
        return etcInfoModel.canUseCertificateYn;
    }
    setCanUseCertificateYn(e) {
        etcInfoModel.canUseCertificateYn = e.target.value === "true";
    }
    koreanName() {
        let name = [];
        shareholderAndExecutiveInfoModel.shareholders.forEach((shareholders) => {
            name.push(shareholders.koreanName);
        })
        shareholderAndExecutiveInfoModel.executiveMembers.forEach((executive) => {
            if(executive.shareholderId === "new") { //임원에서 새로 추가된 분
                name.push(executive.koreanName);
            }
        })
        return name;
    }
    name() {
        const type = smartformAppModel.smartFormType;
        switch(type) {
            case 'ESTABLISHMENT_STOCK_COMPANY':
                return '주주'
            case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY':
                return '주주'
            case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY':
                return '주주'
            case 'ESTABLISHMENT_LIMITED_COMPANY':
                return '출자자'
            case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY':
                return '출자자'
        }
    }
}   


export default new viewModel();