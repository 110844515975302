import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Btn = styled.button`
    width: 100%;
    height: 70px;
    border: none;
    background-color: #006ac3;

    ${(props) => props.btnType === 'negative' && `background-color: #999fac;`}
`;

const Text = styled.span`
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1.35;
`;

function ModalBtn(props) {
    return (
        <Btn type={props.type} className={props.className} style={props.style} onClick={(e) => props.onClick(e)} 
        btnType={props.btnType} 
        >
            <Text>{props.children}</Text>
        </Btn>
    );
};

ModalBtn.propTypes = {
    /**
    * 버튼 타입
    */
    type: PropTypes.string,
    /**
    * class명
    */
    className: PropTypes.string,
    /**
    * 클릭 이벤트
    */
    onClick: PropTypes.func,
    /**
    * style 속성
    */
    style: PropTypes.object,
    /**
    * 버튼 색상
    */
    btnType: PropTypes.oneOf(['positive','negative']),
};

ModalBtn.defaultProps = {
    type : 'button',
    className : null,
    style : {},
    onClick : () => {},
    btnType: 'positive'
};

export default ModalBtn;

