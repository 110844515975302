import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import Vm from './StockholdersMeetingAttendStockholdersVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import AddBtn from '@comComponents/atoms/Button/AddBtn/AddBtn';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import Drop from '@comComponents/molecules/Dropdown/Dropdown';
import st from './StockholdersMeetingAttendStockholders.module.scss';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import clsx from 'clsx';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import regExp from '@common/module/regExp';
import Select from '@comComponents/atoms/Input/Select/Select';
import Icon from '@comComponents/atoms/Icon/Icon';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import Upload from '@comComponents/molecules/FileUpload/FileUpload';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';
import {toJS} from 'mobx';

function StockholdersMeetingAttendStockholders() {

    useEffect(() => {
        if(Vm.stockholders.length === 0) { //이사회 인감제공 처음 입력할 때, 처음 리스트 추가 및 열기
            Vm.addStockholder();
            Vm.state.openIdx = 0;
        }
    }, [])

    return useObserver(() => (
        <form onSubmit={(e) => Vm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            <Question list>
                <ContentBox>
                    <div className={Qst.dropHeader}>
                    <div className={Qst.dropTitleWrap}>
                        <div className={Qst.dropTitle}>주주총회에 참석한(할) 주주님.</div>
                        <div className={Qst.dropTitleExplain}>고객님께서 작성하신 주주분들 리스트입니다.</div>
                    </div>
                    <AddBtn onClick={() => Vm.addStockholder()}>주주 추가하기</AddBtn>
                    </div>
                </ContentBox>
            </Question>
            {Vm.stockholders.map((stockholder, idx) => (
                <Question list key={idx}>
                    <ContentBox>
                        <Drop dataEq={`${idx}`} className={clsx(Qst.dropDown, Vm.setIsOpen(idx) ? 'open' : 'close')} title={Vm.setTitle(idx)} onClick={() => Vm.setDropOpen(idx)} isOpen={Vm.setIsOpen(idx)} closeBtn={() => Vm.setCloseBtn(idx)} error={Vm.personErrorYn(idx)}>
                            <input type="hidden" name={`stockholders[${idx}].id`} value={stockholder.id}/>
                            <div className={Qst.spaceBetween}>
                                <div className={Qst.infoSection}>
                                    <div className={st.explainImgBox}> 
                                        {Vm.stockholderMeetingType === "NORMAL" && 
                                            <img src='/images/StockholdersMeetingAttendStockholders_explain.png' alt="주주총회 참석할분의 정보입력 예시"/>
                                        }
                                        {Vm.stockholderMeetingType === "SPECIAL" && 
                                            <img src='/images/StockholdersMeetingAttendStockholders_explain_special.png' alt="주주총회 참석할분의 정보입력 예시"/>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={Qst.spaceBetween}>
                                <div className={Qst.infoSection}>
                                    <div className={Qst.titleWrap}>
                                        <h2 className={clsx(Qst.title, Qst.inside)}>
                                            해당 주주는 <span className={Qst.bold}>어떤 사람(법인)</span>인가요?
                                        </h2>
                                    </div>
                                    <ul className={Qst.answerList}>
                                        <li><Radio className={Qst.answerLabel} value="한국인" activeBackground checked={stockholder.sealProviderType === "한국인"} onChange={(e) => Vm.setSealProviderType(e,idx)} name={`stockholders[${idx}].sealProviderType`}>한국인</Radio></li>
                                        <li><Radio className={Qst.answerLabel} value="외국인_거주등록" activeBackground checked={stockholder.sealProviderType === "외국인_거주등록"} onChange={(e) => Vm.setSealProviderType(e,idx)} name={`stockholders[${idx}].sealProviderType`}>외국인 거주등록을 마친 국내 거주 외국인</Radio></li>
                                        <li><Radio className={Qst.answerLabel} value="외국인" activeBackground checked={stockholder.sealProviderType === "외국인"} onChange={(e) => Vm.setSealProviderType(e,idx)} name={`stockholders[${idx}].sealProviderType`}>해외거주 외국인</Radio></li>
                                        <li><Radio className={Qst.answerLabel} value="한국법인" activeBackground checked={stockholder.sealProviderType === "한국법인"} onChange={(e) => Vm.setSealProviderType(e,idx)} name={`stockholders[${idx}].sealProviderType`}>한국법인</Radio></li>
                                        <li><Radio className={Qst.answerLabel} value="외국법인" activeBackground checked={stockholder.sealProviderType === "외국법인"} onChange={(e) => Vm.setSealProviderType(e,idx)} name={`stockholders[${idx}].sealProviderType`}>외국법인</Radio></li>
                                        <li><Radio className={Qst.answerLabel} value="펀드_투자조합" activeBackground checked={stockholder.sealProviderType === "펀드_투자조합"} onChange={(e) => Vm.setSealProviderType(e,idx)} name={`stockholders[${idx}].sealProviderType`}>펀드 / 투자조합</Radio></li>
                                    </ul>
                                    <Error name={`stockholders[${idx}].sealProviderType`} value={stockholder.sealProviderType} errorCase={{required:'사람(법인)을 선택해 주세요.'}}/>
                                </div>
                            </div>
                            {(stockholder.sealProviderType && stockholder.sealProviderType !== "외국인_거주등록" && stockholder.sealProviderType !== "외국인" && stockholder.sealProviderType !== "외국법인") &&
                                <div className={Qst.spaceBetween}>
                                    <div className={Qst.infoSection}>
                                        <div className={Qst.titleWrap}>
                                            <h2 className={clsx(Qst.title, Qst.inside)}>
                                                {stockholder.sealProviderType === "한국인" &&
                                                    <><span className={Qst.bold}>성함을 입력</span>해 주세요.</>
                                                }
                                                {stockholder.sealProviderType === "펀드_투자조합" &&
                                                    <><span className={Qst.bold}>펀드/투자조합의 명칭을 입력</span>해 주세요.</>
                                                }
                                                {(stockholder.sealProviderType !== "한국인" && stockholder.sealProviderType !== "펀드_투자조합") && 
                                                    <><span className={Qst.bold}>회사 이름을 입력</span>해 주세요.</>
                                                }
                                            </h2>
                                        </div>
                                        <InputText placeholder="ex)데이비드김" style={{width:'316px'}} value={stockholder.name} onChange={(e) => Vm.setName(e,idx)} name={`stockholders[${idx}].name`} errorText={<Error name={`stockholders[${idx}].name`} value={stockholder.name} errorCase={{
                                            required:'성함을 입력해 주세요.',
                                            pattern : {
                                                value: (stockholder.sealProviderType === "펀드_투자조합" || stockholder.sealProviderType === "한국법인") ? regExp.onlyKorNum().test(stockholder.name) : regExp.onlyKorean().test(stockholder.name),
                                                message: (stockholder.sealProviderType === "펀드_투자조합" || stockholder.sealProviderType === "한국법인") ? '한글, 띄어쓰기, 숫자만 가능합니다.' : '성함을 한글로 입력해 주세요.'
                                            }
                                        }}/>}/><span className={Qst.nameInput}>님</span>
                                    </div>
                                </div>
                            }
                            {(stockholder.sealProviderType && (stockholder.sealProviderType === "외국인_거주등록" || stockholder.sealProviderType === "외국인" || stockholder.sealProviderType === "외국법인")) &&
                                <>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    <span className={Qst.bold}>국적을 선택</span>해 주세요.
                                                </h2>
                                                <div className={Qst.titleExplain}>
                                                    외국인인 경우 국적, 외국법인인 경우 본점이 위치한 나라를 선택해 주세요.
                                                </div>
                                            </div>
                                            <Select className={Qst.select} style={{width:'142px'}} name={`stockholders[${idx}].nationalityType`} value={stockholder.nationalityType ?? ''} onChange={(e) => Vm.setNationalityType(e,idx)} errorText={<Error name={`stockholders[${idx}].nationalityType`} value={stockholder.nationalityType} errorCase={{required:'국적을 선택해 주세요.'}} style={{width:'157px'}}/>}>
                                                <option value="">-</option>
                                                <option value="일본">일본</option>
                                                <option value="미국">미국</option>
                                                <option value="영국">영국</option>
                                                <option value="중국">중국(본토)</option>
                                                <option value="프랑스">프랑스</option>
                                                <option value="독일">독일</option>
                                                <option value="캐나다">캐나다</option>
                                                <option value="홍콩">홍콩</option>
                                                <option value="기타">기타</option>
                                            </Select>
                                            {stockholder.nationalityType === "기타" &&
                                                <div className={clsx(Qst.rowWrap, st.rowWrap)}>
                                                    <InputText className={Qst.inputText} style={{width:'276px'}} explain="기타 국적" name={`stockholders[${idx}].nationalityOthers`} value={stockholder.nationalityOthers} onChange={(e) => Vm.setNationalityOthers(e,idx)} errorText={<Error name={`stockholders[${idx}].nationalityOthers`} value={stockholder.nationalityOthers} errorCase={{required:'기타국적을 입력해 주세요.'}}/>}/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    <span className={Qst.bold}>{stockholder.sealProviderType === "외국법인" ? "회사 이름" : "주주님의 성함"}을 로마자와 한글로 입력</span>해 주세요.
                                                </h2>
                                                <div className={Qst.titleExplain}>
                                                    {stockholder.sealProviderType === "외국법인" ? "외국법인" : "외국인"}의 정식 명칭을 로마자(영문)와 한글 발음으로 입력해 주세요.
                                                </div>
                                                <div className={Qst.titleExTextBox}>
                                                    <TextBox title="로마자(영문)" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>Jane Austen</span></TextBox>
                                                    <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>제인 오스틴</span></TextBox>
                                                </div>
                                                <div className={Qst.titleExplain} style={{marginTop:'8px'}}>
                                                    영문이 아닌 {stockholder.sealProviderType === "외국법인" ? "회사 이름" : "성함"}은 로마자(영문)으로 변환하여 한글 발음으로 입력해 주세요.
                                                </div>
                                                <div className={Qst.titleExTextBox}>
                                                    <TextBox title="로마자(영문)로 변환" titleWidth="145px" className={Qst.nameTextBox} style={{width:'171px', height:'40px', padding:'9px 20px'}}>
                                                    <span className={Qst.text}>高竹</span><Icon icon="arrow" className={Qst.arrow} /><span className={Qst.text}>Takamura</span>
                                                    </TextBox>
                                                    <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'134px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>타카무라</span></TextBox>
                                                </div>
                                            </div>
                                            <div className={Qst.rowWrap}>
                                                <InputText className={Qst.inputText} style={{width:'276px'}} explain="로마자(영문) 표기" name={`stockholders[${idx}].englishName`} value={stockholder.englishName} onChange={(e) => Vm.setEnglishName(e,idx)} errorText={<Error name={`stockholders[${idx}].englishName`} value={stockholder.englishName} errorCase={{
                                                    required: '이름을 로마자로 입력해 주세요.',
                                                    pattern: {
                                                    value: regExp.onlyEnglish().test(stockholder.englishName),
                                                    message: '로마자로 입력해 주세요.'
                                                    }
                                                }}/>} 
                                                />
                                                <InputText className={Qst.inputText} style={{width:'276px'}} explain="한글 발음 표기" name={`stockholders[${idx}].name`} value={stockholder.name} onChange={(e) => Vm.setName(e,idx)} errorText={<Error name={`stockholders[${idx}].name`} value={stockholder.name} errorCase={{
                                                    required: '이름을 한글로 입력해 주세요.',
                                                    pattern: {
                                                        value: stockholder.sealProviderType === "외국법인" ? regExp.onlyKorNum().test(stockholder.name) : regExp.onlyKorean().test(stockholder.name),
                                                        message: stockholder.sealProviderType === "외국법인" ? '한글, 띄어쓰기, 숫자만 가능합니다.' : '성함을 한글로 입력해 주세요.'
                                                    }
                                                }}/>} 
                                                />
                                            </div>
                                        </div>
                                        <div className={Qst.qnaSection}>
                                            <Qna className={Qst.noTitle}>
                                                <QText>어떤 문서에 나온 이름을 입력해야 하나요?</QText>
                                                <div className="answerBox">
                                                <Answer className={Qst.answer}>한국에서 거소등록(외국인등록)을 한 외국인이라면, 거소등록증(외국인등록증) 또는 인감증명서에 기재된 성함으로 입력해 주세요.</Answer>
                                                </div>
                                            </Qna>   
                                        </div>
                                    </div>
                                </>
                            }
                        </Drop>
                    </ContentBox>
                </Question>
            ))}
            <Question list={true}>
                <ContentBox>
                <button type="button" className={Qst.stockholderAddBtn} onClick={() => Vm.addStockholder()}>주주 추가하기<Icon icon="addGray" className={Qst.addGray}/></button>
                </ContentBox>
            </Question>
        </form>
    ));
}
export default StockholdersMeetingAttendStockholders;