import { useObserver } from 'mobx-react';
import ForeignerPersonVm from './ForeignerPersonVm';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from '../StandbySimpleNewSharesAcquirer.module.scss';
import clsx from 'clsx';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import Icon from '@comComponents/atoms/Icon/Icon';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import Select from '@comComponents/atoms/Input/Select/Select';
import Radio from '@comComponents/atoms/Input/Radio/Radio';

function ForeignerPerson(props) {
    return useObserver(() => (
        <>
            <div className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    <div className={Qst.titleWrap}>
                        <h2 className={clsx(Qst.title, Qst.inside)}>
                            <span className={Qst.bold}>성함을 로마자와 한글로 입력</span>해 주세요.
                        </h2>
                        <div className={Qst.titleExplain}>
                            외국인의 정식 명칭을 로마자(영문)와 한글 발음으로 입력해 주세요.
                        </div>
                        <div className={Qst.titleExTextBox}>
                            <TextBox title="로마자(영문)" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>Jane Austen</span></TextBox>
                            <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>제인 오스틴</span></TextBox>
                        </div>
                        <div className={Qst.titleExplain}>
                            영문이 아닌 외국성함은 로마자(영문)으로 변환하여 한글 발음으로 입력해 주세요.
                        </div>
                        <div className={Qst.titleExTextBox}>
                            <TextBox title="로마자(영문)로 변환" titleWidth="145px" className={Qst.nameTextBox} style={{width:'171px', height:'40px', padding:'9px 20px'}}>
                            <span className={Qst.text}>高竹</span><Icon icon="arrow" className={Qst.arrow} /><span className={Qst.text}>Takamura</span>
                            </TextBox>
                            <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'134px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>타카무라</span></TextBox>
                        </div>
                    </div>
                    <div className={Qst.rowWrap}>
                        <InputText className={Qst.inputText} style={{width:'276px'}} explain="로마자(영문) 표기" name={`newSharesAcquirers[${props.idx}].englishName`} value={props.info.englishName} onChange={(e) => ForeignerPersonVm.setEnglishName(e,props.idx)} disabled={!props.reviseInfoList[props.idx]?.name} errorText={<Error name={`newSharesAcquirers[${props.idx}].englishName`} value={props.info.englishName} errorCase={{
                            required: '이름을 로마자로 입력해 주세요.',
                            pattern: {
                            value: regExp.onlyEnglish().test(props.info.englishName),
                            message: '로마자로 입력해 주세요.'
                            }
                        }}/>} 
                        />
                        <InputText className={Qst.inputText} style={{width:'276px'}} explain="한글 발음 표기" name={`newSharesAcquirers[${props.idx}].koreanName`} value={props.info.koreanName} onChange={(e) => ForeignerPersonVm.setKoreanName(e,props.idx)} disabled={!props.reviseInfoList[props.idx]?.name} errorText={<Error name={`newSharesAcquirers[${props.idx}].koreanName`} value={props.info.koreanName} errorCase={{
                            required: '이름을 한글로 입력해 주세요.',
                            pattern: {
                            value: regExp.onlyKorean().test(props.info.koreanName),
                            message: '한글로 입력해 주세요.'
                            }
                        }}/>} 
                        />
                    </div>
                    {!props.reviseInfoList[props.idx]?.name && 
                        <button type="button" className={st.reviseBtn} onClick={() => props.setReviseInfoList(props.idx, "name")}><span>답변 수정하기</span></button>
                    }
                </div>
                <div className={Qst.qnaSection}>
                <Qna className={Qst.noTitle}>
                    <QText>어떤 문서에 나온 이름을 입력해야 하나요?</QText>
                    <div className="answerBox">
                    <Answer className={Qst.answer}>한국에서 거소등록(외국인등록)을 한 외국인이라면, 거소등록증(외국인등록증) 또는 인감증명서에 기재된 성함으로 입력해 주세요.</Answer>
                    </div>
                </Qna>   
                </div>
            </div>
            <div className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    <div className={Qst.titleWrap}>
                        <h2 className={clsx(Qst.title, Qst.inside)}>
                            <span className={Qst.bold}>국적을 선택</span>해 주세요.
                        </h2>
                        <div className={Qst.titleExplain}>
                            외국인인 경우 국적, 외국법인인 경우 본점이 위치한 나라를 선택해 주세요.
                        </div>
                    </div>
                    <Select className={clsx(Qst.select, st.select)} style={{width:'142px'}} name={`newSharesAcquirers[${props.idx}].nationalityType`} value={props.info.nationalityType ?? ''} onChange={(e) => ForeignerPersonVm.setNationalityType(e,props.idx)} disabled={!props.reviseInfoList[props.idx]?.nationalityType} errorText={<Error name={`newSharesAcquirers[${props.idx}].nationalityType`} value={props.info.nationalityType} errorCase={{required:'국적을 선택해 주세요.'}} style={{width:'157px'}}/>}>
                        <option value="">-</option>
                        <option value="일본">일본</option>
                        <option value="미국">미국</option>
                        <option value="영국">영국</option>
                        <option value="중국">중국(본토)</option>
                        <option value="프랑스">프랑스</option>
                        <option value="독일">독일</option>
                        <option value="캐나다">캐나다</option>
                        <option value="홍콩">홍콩</option>
                        <option value="기타">기타</option>
                    </Select>
                    {props.info.nationalityType === "기타" &&
                        <div className={clsx(Qst.rowWrap, st.rowWrap)}>
                            <InputText className={Qst.inputText} style={{width:'276px'}} explain="기타 국적" name={`newSharesAcquirers[${props.idx}].nationalityOthers`} value={props.info.nationalityOthers} onChange={(e) => ForeignerPersonVm.setNationalityOthers(e,props.idx)} disabled={!props.reviseInfoList[props.idx]?.nationalityType} errorText={<Error name={`newSharesAcquirers[${props.idx}].nationalityOthers`} value={props.info.nationalityOthers} errorCase={{required:'기타국적을 입력해 주세요.'}}/>}/>
                        </div>
                    }
                    {!props.reviseInfoList[props.idx]?.nationalityType && 
                        <button type="button" className={st.reviseBtn} onClick={() => props.setReviseInfoList(props.idx, "nationalityType")}><span>답변 수정하기</span></button>
                    }
                </div>
            </div>
            <div className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                    <div className={Qst.titleWrap}>
                        <h2 className={clsx(Qst.title, Qst.inside)}>
                            <span className={Qst.bold}>{props.info.koreanName? props.info.koreanName : "신주인수인"}님의 여권에 기재된 생년월일을 선택</span>해 주세요.
                        </h2>
                    </div>
                    <div>
                        <div className={Qst.birthWrap}>
                            <InputText style={{width:'130px'}} placeholder="0000" name={`newSharesAcquirers[${props.idx}].birth.year`} value={props.info.birth.year} onChange={(e) => ForeignerPersonVm.setYear(e,props.idx)} maxLength="4" disabled={!props.reviseInfoList[props.idx]?.birth}/>
                            <span className={Qst.birthText}>년</span>
                        </div>
                        <div className={Qst.birthWrap}>
                            <InputText style={{width:'108px'}} placeholder="00" name={`newSharesAcquirers[${props.idx}].birth.month`} value={props.info.birth.month} onChange={(e) => ForeignerPersonVm.setMonth(e,props.idx)} maxLength="2" onBlur={(e) => ForeignerPersonVm.monthBlur(e,props.idx)} disabled={!props.reviseInfoList[props.idx]?.birth}/>
                            <span className={Qst.birthText}>월</span>
                        </div>
                        <div className={Qst.birthWrap}>
                            <InputText style={{width:'108px'}} placeholder="00" name={`newSharesAcquirers[${props.idx}].birth.day`} value={props.info.birth.day} onChange={(e) => ForeignerPersonVm.setDay(e,props.idx)} maxLength="2" onBlur={(e) => ForeignerPersonVm.dayBlur(e,props.idx)} disabled={!props.reviseInfoList[props.idx]?.birth}/>
                            <span className={Qst.birthText}>일</span>
                        </div>
                    </div>
                    <div style={{marginTop:'-27px'}}>
                        <Error name={`newSharesAcquirers[${props.idx}].birth.year`} value={props.info.birth.year} 
                        errorCase={{
                        required:'연도를 입력해 주세요.',
                        pattern: {
                            value: regExp.year().test(props.info.birth.year),
                            message: '올바른 연도를 입력해 주세요'               
                        }
                        }}
                        />
                        <Error name={`newSharesAcquirers[${props.idx}].birth.month`} value={props.info.birth.month} 
                        errorCase={{
                        required: ForeignerPersonVm.displayBirthErrorMessage(props.idx, 'month') ? '월을 입력해 주세요.' : null,
                        pattern: {
                            value: ForeignerPersonVm.displayBirthErrorMessage(props.idx, 'month') ? regExp.month().test(props.info.birth.month) : null,
                            message: '올바른 월을 입력해 주세요'               
                        }
                        }}
                        />
                        <Error name={`newSharesAcquirers[${props.idx}].birth.day`} value={props.info.birth.day} 
                        errorCase={{
                        required: ForeignerPersonVm.displayBirthErrorMessage(props.idx, 'day') ? '일(날짜)을 입력해 주세요.' : null,
                        pattern: {
                            value: ForeignerPersonVm.displayBirthErrorMessage(props.idx, 'day') ? regExp.day().test(props.info.birth.day) : null,
                            message: '올바른 일(날짜)을 입력해 주세요.'           
                        }
                        }}
                        />
                    </div>
                    {!props.reviseInfoList[props.idx]?.birth && 
                        <button type="button" style={{marginTop: '39px'}} className={st.reviseBtn} onClick={() => props.setReviseInfoList(props.idx, "birth")}><span>답변 수정하기</span></button>
                    }
                </div>
            </div>
            <div className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                <div className={Qst.titleWrap}>
                    <h2 className={clsx(Qst.title, Qst.inside)}>
                        <span className={Qst.bold}>{props.info.koreanName? props.info.koreanName : "신주인수인"}님은 한국에 거소등록 또는 외국인등록을 한 외국인</span> 인가요?
                    </h2>
                </div>
                    <ul className={Qst.answerList}>
                        {(ForeignerPersonVm.displayResidenceRegistrationYn(props.idx) || props.info.residenceRegistrationYn || props.reviseInfoList[props.idx]?.residenceRegistration) && 
                            <li><Radio className={Qst.answerLabel} value={true} activeBackground checked={props.info.residenceRegistrationYn === true} onChange={(e) => ForeignerPersonVm.setResidenceRegistrationYn(e, props.idx)} name={`newSharesAcquirers[${props.idx}].residenceRegistrationYn`}>네</Radio></li>
                        }
                        {(ForeignerPersonVm.displayResidenceRegistrationYn(props.idx) || props.info.residenceRegistrationYn === false || props.reviseInfoList[props.idx]?.residenceRegistration) && 
                            <li><Radio className={Qst.answerLabel} value={false} activeBackground checked={props.info.residenceRegistrationYn === false} onChange={(e) => ForeignerPersonVm.setResidenceRegistrationYn(e, props.idx)} name={`newSharesAcquirers[${props.idx}].residenceRegistrationYn`}>아니오</Radio></li>
                        }
                    </ul>
                    {(ForeignerPersonVm.displayResidenceRegistrationYn(props.idx) || props.info.residenceRegistrationYn || props.info.residenceRegistrationYn === false) && 
                        <Error name={`newSharesAcquirers[${props.idx}].residenceRegistrationYn`} value={props.info.residenceRegistrationYn} errorCase={{required:'거소등록 또는 외국인등록 여부를 선택해 주세요.'}} />
                    }
                    {!props.reviseInfoList[props.idx]?.residenceRegistration && 
                        <button type="button" className={st.reviseBtn} onClick={() => props.setReviseInfoList(props.idx, "residenceRegistration")}><span>답변 수정하기</span></button>
                    }
                </div>
            </div>
            {props.info.residenceRegistrationYn === true && 
                <div className={Qst.spaceBetween} style={{paddingBottom:'30px'}}>
                    <div className={Qst.infoSection}>
                        <div className={Qst.titleWrap}>
                            <h2 className={clsx(Qst.title, Qst.inside)}>
                                {props.info.koreanName ? props.info.koreanName : "신주인수인"}님의 <span className={Qst.bold}>거소신고(외국인등록) 사실증명상 도로명주소</span>를 입력해 주세요.
                            </h2>
                        </div>
                        <InputText style={{width:'560px'}} value={props.info.koreanAddress} onChange={(e) => ForeignerPersonVm.setKoreanAddress(e,props.idx)} name={`newSharesAcquirers[${props.idx}].koreanAddress`} disabled={!props.reviseInfoList[props.idx]?.address} errorText={<Error name={`newSharesAcquirers[${props.idx}].koreanAddress`} value={props.info.koreanAddress} errorCase={{required:'주소를 입력해 주세요.'}}/>}/>
                        {!props.reviseInfoList[props.idx]?.address && 
                            <button type="button" className={st.reviseBtn} onClick={() => props.setReviseInfoList(props.idx, "address")}><span>답변 수정하기</span></button>
                        }
                    </div>
                </div>
            }
            {props.info.residenceRegistrationYn === false && 
                <div className={Qst.spaceBetween}>
                    <div className={Qst.infoSection}>
                        <div className={Qst.titleWrap}>
                            <h2 className={clsx(Qst.title, Qst.inside)}>
                                <span className={Qst.bold}>{props.info.koreanName? props.info.koreanName : "신주인수인"}님의 주소를 로마자와 한글로 입력</span>해 주세요.
                            </h2>
                            <div className={Qst.titleExplain}>
                                외국 주소를 로마자(영문)와 한글 발음으로 입력해 주세요.
                            </div>
                            <div className={Qst.titleExTextBox}>
                                <TextBox title="로마자(영문)" className={Qst.foreignAddressTextBox} style={{width:'458px', height:'40px', padding:'9px 20px'}}>
                                    <span className={Qst.text}>28445 tomless Ave Torrance CA United States</span>
                                </TextBox>
                                <TextBox title="한글 발음" className={Qst.foreignAddressTextBox} style={{width:'458px', height:'40px', padding:'9px 20px'}}>
                                    <span className={Qst.text}>미국 캘리포니아주 토랜스시 애비뉴 28445</span>
                                </TextBox>
                            </div>
                        </div>
                        <div className={Qst.rowWrap}>
                            <InputText explain="로마자(영문) 표기" style={{width:'560px'}} value={props.info?.foreignAddress} onChange={(e) => ForeignerPersonVm.setEnglishAddress(e,props.idx)} name={`newSharesAcquirers[${props.idx}].foreignAddress`} disabled={!props.reviseInfoList[props.idx]?.address} errorText={<Error name={`newSharesAcquirers[${props.idx}].foreignAddress`} value={props.info?.foreignAddress} errorCase={{
                                required: '주소를 로마자로 입력해 주세요.',
                                pattern: {
                                value: regExp.onlyEngAddress().test(props.info?.foreignAddress),
                                message: '로마자로 입력해 주세요.'
                                }
                            }}/>}/>
                        </div>
                        <div className={Qst.rowWrap}>
                            <InputText explain="한글 발음 표기" style={{width:'560px'}} value={props.info?.koreanAddress} onChange={(e) => ForeignerPersonVm.setKoreanAddress(e,props.idx)} name={`newSharesAcquirers[${props.idx}].koreanAddress`} disabled={!props.reviseInfoList[props.idx]?.address} errorText={<Error name={`newSharesAcquirers[${props.idx}].koreanAddress`} value={props.info?.koreanAddress} errorCase={{
                                required: '주소를 한글로 입력해 주세요.',
                                pattern: {
                                value: regExp.onlyKorNum().test(props.info?.koreanAddress),
                                message: '한글로 입력해 주세요.'
                                }
                            }}/>}/>
                        </div>
                        {!props.reviseInfoList[props.idx]?.address && 
                            <button type="button" className={st.reviseBtn} onClick={() => props.setReviseInfoList(props.idx, "address")}><span>답변 수정하기</span></button>
                        }
                    </div>
                </div>  
            }
        </>
    ));
}
export default ForeignerPerson;