import { useObserver } from 'mobx-react';
import StockholdersMeetingSealProvidingVm from './StockholdersMeetingSealProvidingVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './StockholdersMeetingSealProviding.module.scss';
import clsx from 'clsx';
import AddBtn from '@comComponents/atoms/Button/AddBtn/AddBtn';
import Drop from '@comComponents/molecules/Dropdown/Dropdown';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import Select from '@comComponents/atoms/Input/Select/Select';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import Icon from '@comComponents/atoms/Icon/Icon';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';
import { useEffect } from 'react';

function StockholdersMeetingSealProviding() {
    useEffect(() => {
        if(StockholdersMeetingSealProvidingVm.shareholders.length === 0) { //주주총회 처음 입력할 때, 처음 리스트 추가 및 열기
            StockholdersMeetingSealProvidingVm.addShareholders();
            StockholdersMeetingSealProvidingVm.state.openIdx = 0;
        }
    }, [])

    return useObserver(() => (
        <form onSubmit={(e) => StockholdersMeetingSealProvidingVm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            <Question list={true}>
                <ContentBox>
                    <div className={Qst.dropHeader}>
                    <div className={Qst.dropTitleWrap}>
                        <div className={Qst.dropTitle}>인감증명서를 제공할 {StockholdersMeetingSealProvidingVm.text}님의 정보를 입력해 주세요.</div>
                        <div className={Qst.dropTitleExplain}>고객님께서 작성하신 {StockholdersMeetingSealProvidingVm.text}분들 리스트입니다.</div>
                    </div>
                    <AddBtn onClick={() => StockholdersMeetingSealProvidingVm.addShareholders()}>{StockholdersMeetingSealProvidingVm.text} 추가하기</AddBtn>
                    </div>
                </ContentBox>
            </Question>
            {StockholdersMeetingSealProvidingVm.shareholders.map((item, idx) => {
                return (
                    <Question list key={idx}>
                        <ContentBox>
                            <Drop dataEq={`${idx}`} className={clsx(Qst.dropDown, StockholdersMeetingSealProvidingVm.setIsOpen(idx) ? 'open' : 'close')} title={StockholdersMeetingSealProvidingVm.setTitle(idx)} onClick={() => StockholdersMeetingSealProvidingVm.setDropOpen(idx)} isOpen={StockholdersMeetingSealProvidingVm.setIsOpen(idx)} closeBtn={() => StockholdersMeetingSealProvidingVm.setCloseBtn(idx)} error={StockholdersMeetingSealProvidingVm.personErrorYn(idx)}> 
                                <input type="hidden" name={`sealProvidingShareholders[${idx}].id`} value={item.id}/>
                                <div className={Qst.spaceBetween}>
                                    <div className={Qst.infoSection}>
                                        <div className={StockholdersMeetingSealProvidingVm.companyTypeLimitYn ? st.explainImgBoxLimit : st.explainImgBox}>
                                            <img className={st.sealProvidingImg} src={StockholdersMeetingSealProvidingVm.displayBadgeImg()} alt="주주총회 인감증명서 준비방법"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={Qst.spaceBetween}>
                                    <div className={Qst.infoSection}>
                                        <div className={Qst.titleWrap}>
                                            <h2 className={clsx(Qst.title, Qst.inside)}>
                                                해당 {StockholdersMeetingSealProvidingVm.text은는()} <span className={Qst.bold}>어떤 사람(법인)</span>인가요?
                                            </h2>
                                            <div className={Qst.titleExplain}>
                                                외국인 거주등록을 하지 않은 외국인은 인감증명서 제공이 불가능합니다. 위임장을 현지에서 공증받는 절차가 필요하니 담당 매니저에게 채팅으로 문의주세요.
                                            </div>
                                        </div>
                                        <ul className={Qst.answerList}>
                                            <li><Radio className={Qst.answerLabel} value="한국인" activeBackground checked={item.sealProviderType === "한국인"} onChange={(e) => StockholdersMeetingSealProvidingVm.setSealProviderType(e,idx)} name={`sealProvidingShareholders[${idx}].sealProviderType`}>한국인</Radio></li>
                                            <li><Radio className={Qst.answerLabel} value="외국인_거주등록" activeBackground checked={item.sealProviderType === "외국인_거주등록"} onChange={(e) => StockholdersMeetingSealProvidingVm.setSealProviderType(e,idx)} name={`sealProvidingShareholders[${idx}].sealProviderType`}>외국인 거주등록을 마친 국내 거주 외국인</Radio></li>
                                            <li><Radio className={Qst.answerLabel} value="한국법인" activeBackground checked={item.sealProviderType === "한국법인"} onChange={(e) => StockholdersMeetingSealProvidingVm.setSealProviderType(e,idx)} name={`sealProvidingShareholders[${idx}].sealProviderType`}>한국법인</Radio></li>
                                            <li><Radio className={Qst.answerLabel} value="펀드_투자조합" activeBackground checked={item.sealProviderType === "펀드_투자조합"} onChange={(e) => StockholdersMeetingSealProvidingVm.setSealProviderType(e,idx)} name={`sealProvidingShareholders[${idx}].sealProviderType`}>펀드/투자조합</Radio></li>
                                            {/* <li><Radio className={Qst.answerLabel} value="외국법인" activeBackground checked={item.sealProviderType === "외국법인"} onChange={(e) => StockholdersMeetingSealProvidingVm.setSealProviderType(e,idx)} name={`sealProvidingShareholders[${idx}].sealProviderType`}>외국법인</Radio></li> */}
                                        </ul>
                                        <Error name={`sealProvidingShareholders[${idx}].sealProviderType`} value={item.sealProviderType} errorCase={{required:'사람(법인)을 선택해 주세요.'}}/>
                                    </div>
                                    <div className={Qst.qnaSection}>
                                        <Qna className={Qst.noTitle}>
                                            <div className={Qst.qnaBox}>
                                                <QText>거주등록을 하지 않은 외국인 또는 외국법인 {StockholdersMeetingSealProvidingVm.text이가()}<br/>{StockholdersMeetingSealProvidingVm.text}총회에서 찬성하려면 어떻게 해야 하나요?</QText>
                                                <div className="answerBox">
                                                    <Answer className={Qst.answer}>거주등록을 하지 않은 외국인 또는 외국법인 {StockholdersMeetingSealProvidingVm.text}의 경우, 인감증명서를 만들 수 없습니다.</Answer>
                                                    <div className={Qst.explain}>
                                                        이 경우 찬성한다는 내용의 위임장을 거주하는 국가의 언어로 작성한 후, 현지 공증 또는 아포스티유 인증을 거치는 복잡한 절차가 필요합니다. 해당 절차가 필요한 경우 담당 매니저에게 채팅으로 문의주세요.
                                                    </div>
                                                </div>
                                            </div>
                                        </Qna>
                                    </div>
                                </div>
                                {item.sealProviderType === "한국인" &&
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    <span className={Qst.bold}>성함을 입력</span>해 주세요.
                                                </h2>
                                            </div>
                                            <InputText placeholder="ex)데이비드김" style={{width:'316px'}} value={item.name} onChange={(e) => StockholdersMeetingSealProvidingVm.setName(e,idx)} name={`sealProvidingShareholders[${idx}].name`} errorText={<Error name={`sealProvidingShareholders[${idx}].name`} value={item.name} errorCase={{required:'성함을 입력해 주세요.'}}/>}/><span className={Qst.nameInput}>님</span>
                                        </div>
                                    </div>
                                }
                                {item.sealProviderType === "외국인_거주등록" &&
                                <>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    <span className={Qst.bold}>국적을 선택</span>해 주세요.
                                                </h2>
                                                <div className={Qst.titleExplain}>
                                                    외국인인 경우 국적, 외국법인인 경우 본점이 위치한 나라를 선택해 주세요.
                                                </div>
                                            </div>
                                            <Select className={Qst.select} style={{width:'142px'}} name={`sealProvidingShareholders[${idx}].nationalityType`} value={item.nationalityType ?? ''} onChange={(e) => StockholdersMeetingSealProvidingVm.setNationalityType(e,idx)} errorText={<Error name={`sealProvidingShareholders[${idx}].nationalityType`} value={item.nationalityType} errorCase={{required:'국적을 선택해 주세요.'}} style={{width:'157px'}}/>}>
                                                <option value="">-</option>
                                                <option value="일본">일본</option>
                                                <option value="미국">미국</option>
                                                <option value="영국">영국</option>
                                                <option value="중국">중국(본토)</option>
                                                <option value="프랑스">프랑스</option>
                                                <option value="독일">독일</option>
                                                <option value="캐나다">캐나다</option>
                                                <option value="홍콩">홍콩</option>
                                                <option value="기타">기타</option>
                                            </Select>
                                            {item.nationalityType === "기타" &&
                                                <div className={clsx(Qst.rowWrap, st.rowWrap)}>
                                                    <InputText className={Qst.inputText} style={{width:'276px'}} explain="기타 국적" name={`sealProvidingShareholders[${idx}].nationalityOthers`} value={item.nationalityOthers} onChange={(e) => StockholdersMeetingSealProvidingVm.setNationalityOthers(e,idx)} errorText={<Error name={`sealProvidingShareholders[${idx}].nationalityOthers`} value={item.nationalityOthers} errorCase={{required:'기타국적을 입력해 주세요.'}}/>}/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    {StockholdersMeetingSealProvidingVm.text}님의 <span className={Qst.bold}>성함</span>을 <span className={Qst.bold}>로마자와 한글</span>로 입력해 주세요.
                                                </h2>
                                                <div className={Qst.titleExplain}>
                                                    외국인의 정식 명칭을 로마자(영문)와 한글 발음으로 입력해 주세요.
                                                </div>
                                                <div className={Qst.titleExTextBox}>
                                                    <TextBox title="로마자(영문)" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>Jane Austen</span></TextBox>
                                                    <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>제인 오스틴</span></TextBox>
                                                </div>
                                                <div className={Qst.titleExplain}>
                                                    영문이 아닌 외국성함은 로마자(영문)으로 변환하여 한글 발음으로 입력해 주세요.
                                                </div>
                                                <div className={Qst.titleExTextBox}>
                                                    <TextBox title="로마자(영문)로 변환" titleWidth="145px" className={Qst.nameTextBox} style={{width:'171px', height:'40px', padding:'9px 20px'}}>
                                                    <span className={Qst.text}>高竹</span><Icon icon="arrow" className={Qst.arrow} /><span className={Qst.text}>Takamura</span>
                                                    </TextBox>
                                                    <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'134px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>타카무라</span></TextBox>
                                                </div>
                                            </div>
                                            <div className={Qst.rowWrap}>
                                                <InputText className={Qst.inputText} style={{width:'276px'}} explain="로마자(영문) 표기" name={`sealProvidingShareholders[${idx}].englishName`} value={item.englishName} onChange={(e) => StockholdersMeetingSealProvidingVm.setEnglishName(e,idx)} errorText={<Error name={`sealProvidingShareholders[${idx}].englishName`} value={item.englishName} errorCase={{
                                                    required: '이름을 로마자로 입력해 주세요.',
                                                    pattern: {
                                                    value: regExp.onlyEnglish().test(item.englishName),
                                                    message: '로마자로 입력해 주세요.'
                                                    }
                                                }}/>} 
                                                />
                                                <InputText className={Qst.inputText} style={{width:'276px'}} explain="한글 발음 표기" name={`sealProvidingShareholders[${idx}].name`} value={item.name} onChange={(e) => StockholdersMeetingSealProvidingVm.setName(e,idx)} errorText={<Error name={`sealProvidingShareholders[${idx}].name`} value={item.name} errorCase={{
                                                    required: '이름을 한글로 입력해 주세요.',
                                                    pattern: {
                                                    value: regExp.onlyKorean().test(item.name),
                                                    message: '한글로 입력해 주세요.'
                                                    }
                                                }}/>} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                }
                                {(item.sealProviderType === "한국법인" || item.sealProviderType === "펀드_투자조합") &&
                                    <div className={Qst.spaceBetween}>
                                        <div className={Qst.infoSection}>
                                            <div className={Qst.titleWrap}>
                                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                                    <span className={Qst.bold}>회사 이름</span>을 입력해 주세요.
                                                </h2>
                                                <div className={Qst.titleExplain}>
                                                    정식명칭을 입력해 주세요. ex) {item.sealProviderType === "펀드_투자조합" ? "헬프미 홀딩스 1호 개인투자조합" : "주식회사 신한은행, 현대산업개발 유한회사"}
                                                </div>
                                            </div>
                                            <InputText placeholder={item.sealProviderType === "펀드_투자조합" ? "ex)헬프미 홀딩스 1호 개인투자조합" : "ex)주식회사 신한은행"} style={{width:'316px'}} value={item.name} onChange={(e) => StockholdersMeetingSealProvidingVm.setName(e,idx)} name={`sealProvidingShareholders[${idx}].name`} errorText={<Error name={`sealProvidingShareholders[${idx}].name`} value={item.name} errorCase={{required:'회사 이름을 입력해 주세요.'}}/>}/><span className={Qst.nameInput}>님</span>
                                        </div>
                                    </div>
                                }
                                {/* {item.sealProviderType === "외국법인" &&
                                    <>
                                        <div className={Qst.spaceBetween}>
                                            <div className={Qst.infoSection}>
                                                <div className={Qst.titleWrap}>
                                                    <h2 className={clsx(Qst.title, Qst.inside)}>
                                                        <span className={Qst.bold}>국적을 선택</span>해 주세요.
                                                    </h2>
                                                    <div className={Qst.titleExplain}>
                                                        외국인인 경우 국적, 외국법인인 경우 본점이 위치한 나라를 선택해 주세요.
                                                    </div>
                                                </div>
                                                <Select className={Qst.select} style={{width:'142px'}} name={`sealProvidingShareholders[${idx}].nationalityType`} value={item.nationalityType ?? ''} onChange={(e) => StockholdersMeetingSealProvidingVm.setNationalityType(e,idx)} errorText={<Error name={`sealProvidingShareholders[${idx}].nationalityType`} value={item.nationalityType} errorCase={{required:'국적을 선택해 주세요.'}} style={{width:'157px'}}/>}>
                                                    <option value="">-</option>
                                                    <option value="일본">일본</option>
                                                    <option value="미국">미국</option>
                                                    <option value="영국">영국</option>
                                                    <option value="중국">중국(본토)</option>
                                                    <option value="프랑스">프랑스</option>
                                                    <option value="독일">독일</option>
                                                    <option value="캐나다">캐나다</option>
                                                    <option value="홍콩">홍콩</option>
                                                    <option value="기타">기타</option>
                                                </Select>
                                                {item.nationalityType === "기타" &&
                                                    <div className={clsx(Qst.rowWrap, st.rowWrap)}>
                                                        <InputText className={Qst.inputText} style={{width:'276px'}} explain="기타 국적" name={`sealProvidingShareholders[${idx}].nationalityOthers`} value={item.nationalityOthers} onChange={(e) => StockholdersMeetingSealProvidingVm.setNationalityOthers(e,idx)} errorText={<Error name={`sealProvidingShareholders[${idx}].nationalityOthers`} value={item.nationalityOthers} errorCase={{required:'기타국적을 입력해 주세요.'}}/>}/>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className={Qst.spaceBetween}>
                                            <div className={Qst.infoSection}>
                                                <div className={Qst.titleWrap}>
                                                    <h2 className={clsx(Qst.title, Qst.inside)}>
                                                        회사이름을 <span className={Qst.bold}>성함</span>을 <span className={Qst.bold}>로마자와 한글</span>로 입력해 주세요.
                                                    </h2>
                                                    <div className={Qst.titleExplain}>
                                                        외국법인의 정식 명칭을 로마자(영문)와 한글 발음으로 입력해 주세요.
                                                    </div>
                                                    <div className={Qst.titleExTextBox}>
                                                        <TextBox title="로마자(영문)" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>Apple</span></TextBox>
                                                        <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'174px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>애플</span></TextBox>
                                                    </div>
                                                    <div className={Qst.titleExplain}>
                                                        영문이 아닌 회사이름은, 로마자(영문)으로 변환하여 한글 발음으로 입력해 주세요.
                                                    </div>
                                                    <div className={Qst.titleExTextBox}>
                                                        <TextBox title="로마자(영문)로 변환" titleWidth="145px" className={Qst.nameTextBox} style={{width:'171px', height:'40px', padding:'9px 20px'}}>
                                                        <span className={Qst.text}>高竹</span><Icon icon="arrow" className={Qst.arrow} /><span className={Qst.text}>Takamura</span>
                                                        </TextBox>
                                                        <TextBox title="한글 발음" className={Qst.nameTextBox} style={{width:'134px', height:'40px', padding:'9px 20px'}}><span className={Qst.text}>타카무라</span></TextBox>
                                                    </div>
                                                </div>
                                                <div className={Qst.rowWrap}>
                                                    <InputText className={Qst.inputText} style={{width:'276px'}} explain="로마자(영문) 표기" name={`sealProvidingShareholders[${idx}].englishName`} value={item.englishName} onChange={(e) => StockholdersMeetingSealProvidingVm.setEnglishName(e,idx)} errorText={<Error name={`sealProvidingShareholders[${idx}].englishName`} value={item.englishName} errorCase={{
                                                        required: '회사이름을 로마자로 입력해 주세요.',
                                                        pattern: {
                                                        value: regExp.onlyEnglish().test(item.englishName),
                                                        message: '로마자로 입력해 주세요.'
                                                        }
                                                    }}/>} 
                                                    />
                                                    <InputText className={Qst.inputText} style={{width:'276px'}} explain="한글 발음 표기" name={`sealProvidingShareholders[${idx}].name`} value={item.name} onChange={(e) => StockholdersMeetingSealProvidingVm.setName(e,idx)} errorText={<Error name={`sealProvidingShareholders[${idx}].name`} value={item.name} errorCase={{
                                                        required: '회사이름을 한글로 입력해 주세요.',
                                                        pattern: {
                                                        value: regExp.onlyKorean().test(item.name),
                                                        message: '한글로 입력해 주세요.'
                                                        }
                                                    }}/>} 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                } */}
                            </Drop>
                        </ContentBox>
                    </Question>
                )
            })}
            <Question list={true}>
                <ContentBox>
                <button type="button" className={Qst.stockholderAddBtn} onClick={() => StockholdersMeetingSealProvidingVm.addShareholders()}>{StockholdersMeetingSealProvidingVm.text} 추가하기<Icon icon="addGray" className={Qst.addGray}/></button>
                </ContentBox>
            </Question>
        </form>
    ));
}
export default StockholdersMeetingSealProviding;