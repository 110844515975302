import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import badgeActive from '../../asset/icon/badge_active.svg';
import badge from '../../asset/icon/badge_activeNo.svg'

const BadgeBox = styled.div`
    position: relative;
    min-width: 100px;
    min-height: 100px;
    border: 1px solid #006ac3;
    outline: 1px solid #006ac3;
    border-radius: 4px;

    ${(props) => props.active === false && 'border: 1px solid #c5cbd5; outline: none;'}
    ${(props) => props.border === false && 'border: none; outline: none;'}
`;

const BadgeCircle = styled.div`
    position: absolute;
    top: -1px;
    left: -1px;
    width: 51px;
    height: 51px;
    ${(props) => props.active === true && 'width: 50px; height: 50px;'};
    ${(props) => props.border === false && 'top: 0; left: 0;'};
    background-image: url(${(props) => props.active ? badgeActive : badge});
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
`;

const BadgeTitle = styled.div`
    position: absolute;
    bottom: 23px;
    right: 16px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.56px;
`;

function Badge(props) {
    return (
        <>
            <BadgeBox className={props.className} style={props.style} onClick={props.onClick}
            active={props.active} border={props.border}>
                <BadgeCircle active={props.active} border={props.border} type={props.type}>
                    <BadgeTitle>{props.title}</BadgeTitle>
                </BadgeCircle>
                {props.children}
            </BadgeBox>
        </>
    );
};

Badge.propTypes = {
    /**
    * class명
    */
    className: PropTypes.string,
    /**
    * style 속성
    */
    style: PropTypes.object,
    /**
    * 클릭 이벤트
    */
    onClick: PropTypes.func,
    /**
    * title명
    */
    title: PropTypes.string,
    /**
    * 활성화 여부
    */
    active: PropTypes.bool,
    /**
    * 테두리 여부
    */
    border: PropTypes.bool,
    /**
    * BadgeBox type
    */
    type: PropTypes.oneOf(['basic', 'example']),  
    /**
    * BadgeBox 콘텐츠
    */
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.any
    ]),
};

Badge.defaultProps = {
    className: null,
    style: {},
    onClick: () => {},
    title: 'Empty',
    active: true,
    border: true,    
    type: 'basic',
    chidlren: null,
    
};

export default Badge;

