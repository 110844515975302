import React from 'react';
import PropTypes from 'prop-types';
import styled, {keyframes} from 'styled-components';
import preArrowSmall from '../../../asset/icon/ico_pre_small.svg';
import preArrow from '../../../asset/icon/ico_pre_arrow.svg';
import nextArrowSmall from '../../../asset/icon/ico_next_arrow_small.svg';
import nextArrow from '../../../asset/icon/ico_next_arrow.svg';
import nextClear from '../../../asset/icon/ico_next_clear.svg';

const Btn = styled.button`  
    position: relative;
    top: -1px;
    height: ${(props) => props.height};
    padding: ${(props) => props.padding};
    background-color: transparent;
    border: solid 2px #fff;
    border-radius: 160px;
    ${(props) => props.disabled === true && `cursor: not-allowed;`}
    color: #fff;
    ${(props) => props.step === 'next' && `color: #006ac3`};
    font-size: ${(props) => props.fontSetting.fontSize};
    letter-spacing: ${(props) => props.fontSetting.letterSpacing};
    font-weight: 400;
    vertical-align: middle;
    ${(props) => props.step === 'pre' && 
    `
    &:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: ${props.stepPosition.left};
        top: ${props.stepPosition.top};
        background-image: ${props.stepImg};
        ${props.size === 'small' && `transform: rotate(180deg);`}
        background-repeat: no-repeat;
        background-size: cover;
        width: ${props.stepSize.width};
        height: ${props.stepSize.height};
        margin: ${props.stepMargin};
    }`
    }
    ${(props) => props.step === 'next' && 
    `border: none;
    background: #fff;
    &:after {
        content: '';
        display: inline-block;
        position: absolute;
        right: ${props.stepPosition.right};
        top: ${props.stepPosition.top};
        background-image: ${props.stepImg};
        background-repeat: no-repeat;
        background-size: cover;
        width: ${props.stepSize.width};
        height: ${props.stepSize.height};
        margin: ${props.stepMargin};
    }`
    }
    ${(props) => props.step === 'load' &&
    `width: 181px;
    border: none;
    background: #fff;
    `
    }
    ${(props) => props.step === 'clear' &&
    `width: 167px;
    border: none;
    background: #fff;
    `
    }
`;

function BtnHeight(size) {
    if(size === 'small') return '40px'
    else if(size === 'medium') return '50px'
}

function BtnPadding(size, step) {
    if(size === 'small') {
        if(step === 'pre') return '0 23px 0 44px'
        else if(step === 'next') return '0 38px 0 18px'
    } else if(size === 'medium') {
        if(step === 'pre') return '0 25px 0 51px'
        else if(step === 'next') return '0 49px 0 25px'
    } 
    return '0 33px' //기본 padding값
}

function StepImg(size, step) {
    if(size === 'small') {
        if(step === 'pre') return `url(${preArrowSmall})`
        else if(step === 'next') return `url(${nextArrowSmall})`

    } else if(size === 'medium') {
        if(step === 'pre') return `url(${preArrow})`
        else if(step === 'next') return `url(${nextArrow})`


    } 
}

function StepPosition(size, step) {
    if(size === 'small') {
        if(step === 'pre') return {left: '17px', top: '9px'}
        else if(step === 'next') return {right: '17px', top: '14px'}
    } else if(size === 'medium') {
        if(step === 'pre') return {left: '20px', top: '11px'}
        else if(step === 'next') return {right: '21px', top: '17px'}
    } 
}

function StepSize(size, step) {
    if(size === 'small') {
        if(step === 'pre') return {width: '21px', height: '21px'}
        else if(step === 'next') return {width: '14px', height: '12px'}
    } else if(size === 'medium') {
        if(step === 'pre') return {width: '27px', height: '27px'}
        else if(step === 'next') return {width: '18px', height: '16px'}
    } 
}

function TextFont(size) {
    if(size === 'small') return {fontSize: '15px', letterSpacing: '-0.38px'}
    else if(size === 'medium') return {fontSize: '18px', letterSpacing: '-0.45px'}
}

const Bounce = keyframes`
from {
    transform: scale(0.2);
}
to {
    transform: scale(1.0);
}
`;

const Dot = styled.i`
    animation: ${Bounce} 0.3s alternate infinite; 
    display: inline-block;
    margin-right: 12px;
    width: 8px;
    height: 8px;
    background: #006ac3;
    border-radius: 50%;
    position: relative;
    top: -1px;
    ${(props) => props.size === 'medium' && `top: -4px;`}    

    &:nth-child(2) {
        animation-delay: 0.1s;  
    }
    &:nth-child(3) {
        animation-delay: 0.3s;
        margin-right: 0;
    }
`;

const CheckPopup = keyframes`
from {
    opacity: 0;
    transform : scale(0);
}
to {
    opacity: 1;
    transform : scale(1.0);
}
`;

const Check = styled.span`
    position: relative;
    top: 3px;
    display: inline-block;
    width: 34px;
    height: 34px;
    background-image: url(${nextClear});
    background-repeat: no-repeat;
    background-position: center;
    animation: ${CheckPopup} 0.3s;
    animation-timing-function: cubic-bezier(1, -0.46, 0.46, 1.38);
`;

function StepBtn(props) {
    return (
        <Btn type={props.form ? "submit" : props.type} //button type
        className={props.className} onClick={(e) => props.onClick(e)} style={props.style} disabled={props.disabled} 
        size={props.size} padding={BtnPadding(props.size, props.step)} height={BtnHeight(props.size)} 
        step={props.step} stepImg={StepImg(props.size, props.step)} stepPosition={StepPosition(props.size, props.step)} stepSize={StepSize(props.size, props.step)} fontSetting={TextFont(props.size)} 
        form={props.form}
        >
            {(props.step === null || props.step === 'pre' || props.step === 'next') &&  props.children} 

            {props.step === 'load' && 
                <>
                    <Dot size={props.size} />
                    <Dot size={props.size} />
                    <Dot size={props.size} />       
                </>
            }

            {props.step === 'clear' && 
                <Check />
            }    
        </Btn>
    );
};

StepBtn.propTypes = {
    /**
     * 버튼 타입
     */
    type: PropTypes.string,
    /**
     * class명
     */
    className: PropTypes.string,
    /**
     * 클릭 이벤트
     */
    onClick: PropTypes.func,
    /**
     * style 속성
     */
    style: PropTypes.object,
    /**
     * button disabled 여부
     */
    disabled: PropTypes.bool,
    /**
     * 버튼 크기
     */
    size: PropTypes.oneOf(['small', 'medium']),
    /**
     * 버튼 속성
     */
    step: PropTypes.oneOf(['pre', 'next', 'load', 'clear']),  
    /**
     * 버튼 텍스트 값
     */
    children: PropTypes.string,
    /**
     * form으로 연결 이거쓰면 type은 submit이된다.
     */
    form: PropTypes.string,
};

StepBtn.defaultProps = {
    type : 'button',
    className : null,
    onClick : () => {},
    style : {},
    disabled: false,
    size : 'medium',
    step : null,
    children : 'Empty',
    form : null
};

export default StepBtn;

