import BranchOfficeDeleteStepModel from '@model/changeRegistration/BranchOfficeDeleteStepModel';
import stepService from "@service/stepService";
import formDataToJson from '@common/module/submit';
import { runInAction, observable } from 'mobx';

class BranchOfficeDeleteVm {
    dataError() {
        let error = false;
        let deleteCnt = 0;
        BranchOfficeDeleteStepModel.branchOffices.forEach((branchOffice, idx) => {
            if(branchOffice.deleteYn) deleteCnt++;
        })
        if(deleteCnt === 0) {
            error = true;
        }
        return error;
    }
    submit(e) {
        runInAction(() => {
            e.preventDefault();
            if(this.dataError() === false) {
                let param = formDataToJson(e.target).data;
                param.branchOfficeDeletes = param.branchOfficeDeletes.filter((item) => {
                    return item !== "delete"
                })
                stepService.saveStep(param);
            }
        })
    }
    get branchOffices() {
        return BranchOfficeDeleteStepModel.branchOffices;
    }
    get checkAll() {
        let checked = true;
        if(BranchOfficeDeleteStepModel.branchOffices.length){
            BranchOfficeDeleteStepModel.branchOffices.forEach((item) => {
                if(item.deleteYn === false) {
                    checked = false;
                }
            });
        } else {
            checked = false;
        } 
        return checked;
    }
    setDeleteCheckAll(_value) {
        let value;
        if(typeof _value === "boolean") {
            value = _value;
        } else {
            value = _value.target.checked;
        }
        runInAction(() => {
            BranchOfficeDeleteStepModel.branchOffices.forEach((branchOffice, idx) => {
                branchOffice.deleteYn = value;
            })
        })
    }
    get deleteCount() {
        let count = 0;
        BranchOfficeDeleteStepModel.branchOffices.forEach((branchOffice, idx) => {
            if(branchOffice.deleteYn) {
                count++;
            }
        })
        return count;
    }
    setDeleteYn(e, idx) {
        runInAction(() => {
            BranchOfficeDeleteStepModel.branchOffices[idx].deleteYn = e.target.checked;
        })
    }
}

export default new BranchOfficeDeleteVm();