function setOrPush(target, val) {
    let result = val;
    if (target) {
        if(Array.isArray(target)) {
            result = target;
        }else{
            result = [target];    
        }
        result.push(val);
    }
    return result;
}
function getFormResults(formElement, errorFocusYn) {
    let formElements = formElement.elements;
    let formParams = {};
    let i = 0;
    let elem = null;
    let unValidate = false;
    if(errorFocusYn === undefined) {
        errorFocusYn = true;
    }
    for (i = 0; i < formElements.length; i += 1) {
        elem = formElements[i];
        if(elem.name) {
            if(validation(elem)) {
                unValidate = true;
            }
            switch (elem.type) {
                case 'submit':
                case 'button':
                    break;
                case 'radio':
                    if (elem.checked) {
                        formParams[elem.name] = elem.value;
                    }
                    break;
                case 'checkbox':
                    if (elem.checked) {
                        formParams[elem.name] = setOrPush(formParams[elem.name], elem.value);
                    }else{
                        if(elem.value === "false") { //선택하지 않은 checkbox 값이 제출 되는 경우
                            formParams[elem.name] = false;
                        }
                    }
                    break;
                default:
                    formParams[elem.name] = setOrPush(formParams[elem.name], elem.value);
            }
        }
    }
    if(unValidate === true) {
        if(errorFocusYn) {
            errorFocus();
        } 
    }
    return {
        error : unValidate,
        data : formParams
    };
}
function errorFocus() {
    if(document.querySelector(`.errorText[data-error="error"]`)) {
        let top = findPos(document.querySelector(`.errorText[data-error="error"]`));
        if(top < 900) {
            top = 200;
        } else {
            top = top - 250;
        }
        window.scroll(0,top);
    }
}
function findPos(obj) {
    var curtop = 0;
    if (obj.offsetParent) {
        do {
            curtop += obj.offsetTop;
        } while (obj = obj.offsetParent);
    return [curtop];
    }
}
function validation(elem) {
    let errorState = elem?.dataset?.error;
    let errorInputName = elem?.name;
    if(errorState === 'yet' || errorState === 'error') {
        if(errorState === 'yet') {
            elem.dataset.error = "error";
            if(document.querySelector(`.errorText[data-inputname="${errorInputName}"]`)) {
                document.querySelector(`.errorText[data-inputname="${errorInputName}"]`).dataset.error = "error";
            }
        }
        return true;
    } else{
        return false;
    }
}
function formDataToJson(formElement, errorFocusYn) {
    const formResultData = getFormResults(formElement, errorFocusYn);
	const data = formResultData.data;
    const isValidate = formResultData.error;
	const param = {};

	// 배열인지 확인
	const isArray = (key) => (key.match(/[\[]/) !== null && key.match(/[\]]/) !== null);

	// 인덱스 추출
	const getIndex = (key) => (Number(key.slice(Number(key.search(/[\[]/))+1, Number(key.search(/[\]]/)))));

	for(let k in data) {
		const arr = k.split('.');
		let temp = param;
		for(let i=0;i<arr.length; i++) {
			let key = arr[i];
			let index = null;

			// 루프 마지막엔 데이터를 꽂아주고 바로 끝냄
			if(i === arr.length -1) { 
				temp[key] = data[k];
				break;
			} 
			// 배열 or 객체 초기화
			if(isArray(key)) { // 배열
				index = getIndex(key);
				key = key.slice(0, key.search(/[\[]/)); // 배열명 제거한 순수 key 추출
				if(!(temp[key] instanceof Array)) temp[key] = [];
				if(!(temp[key][index] instanceof Object)) temp[key][index] = {};
			} else if(!(temp[key] instanceof Object)) {
				temp[key] = {}; // 객체
			}
			
			temp = (index !== null ? temp[key][index] : temp[key]);
		}

	}
	return {
        error : isValidate,
		data : param
	};
}

function objectToFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
        if(Array.isArray(data)) {
            objectToFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        }else{
            objectToFormData(formData, data[key], parentKey ? `${parentKey}.${key}` : key);
        }
        });
    } else {
        const value = data == null ? '' : data;
        formData.append(parentKey, value);
    }
}


export { formDataToJson as default, errorFocus, findPos, objectToFormData };
