import { useObserver } from 'mobx-react';
import ExecutiveVm from './ExecutiveVm';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import clsx from 'clsx';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';

function ExecutivePosition(props) {
    return useObserver(() => (
        <div className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
                <div className={Qst.titleWrap}>
                    <h2 className={clsx(Qst.title, Qst.inside)}>
                        <span className={Qst.bold}>{ExecutiveVm.positionExecutiveName(props.idx)}의 직책을 입력</span>해 주세요.
                    </h2>
                </div>
                <ul className={Qst.answerList}>
                    {ExecutiveVm.positionList.map((position, positionIdx) => {
                        return (
                            <li key={positionIdx+position.value}>
                                <Radio className={Qst.answerLabel} value={position.value} activeBackground checked={props.position === position.value} onChange={(e) => ExecutiveVm.setPosition(e,props.idx)} name={props.name}>{position.text}</Radio>
                            </li>
                        )
                    })}
                </ul>
                <Error name={props.name} value={props.position} errorCase={{required:'직책을 선택해 주세요.'}}/>
            </div>
            <div className={Qst.qnaSection}>
                <Qna className={Qst.noTitle}>
                    <div className={Qst.qnaBox}>
                        <QText>단독대표, 각자대표, 공동대표의 차이점은 무엇인가요?</QText>
                        <div className="answerBox">
                            <Answer className={Qst.answer} iconText="A1">단독대표는 1명이 회사의 대표를 맡는 경우입니다.</Answer>
                            <p className={Qst.explain}>
                                90% 고객님들은 단독대표를 선택합니다. 
                            </p>
                            <Answer className={Qst.answer} iconText="A2">각자대표는 2명 이상의 대표가 각각 독립적으로 회사의 업무를 결정하는 경우입니다.</Answer>
                            <p className={Qst.explain}>
                                각자 대표의 경우, 각 대표가 단독으로 무엇이든 결정할 수 있기에, 회사 경영이 편리합니다. 다만 대표가 잘못된 결정을 할 때, 사전에 막기 어렵다는 단점이 있습니다.
                            </p>
                            <Answer className={Qst.answer} iconText="A3">공동대표는 2명 이상의 대표가 항상 함께 회사의 업무를 결정하는 경우입니다.</Answer>
                            <p className={Qst.explain}>
                                공동대표는 항상 함께 결정해야 하고, 계약서에도 공동대표의 도장이 모두 들어가야 하므로, 회사 경영시, 번거롭습니다. 하지만 경영권을 감시하기 위한 목적에는 적합합니다.
                            </p>
                        </div>
                    </div>
                    {ExecutiveVm.smartFormType !== 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY' &&  //주식회사, 농업회사법인, 어업회사법인, 유한회사일 경우
                    <>   
                        <div className={Qst.qnaBox}>
                            <QText>{ExecutiveVm.text.director}, 감사의 차이점은 무엇인가요?</QText>
                            <div className="answerBox">
                                <Answer className={Qst.answer} iconText={ExecutiveVm.smartFormType === 'ESTABLISHMENT_LIMITED_COMPANY' ? 'A' : 'A1'}>{ExecutiveVm.text.director}는 회사에 상근하면서, 회사 경영에 참여하는 사람입니다.</Answer>
                                <p className={Qst.explain}>
                                    {ExecutiveVm.text.director}는 중요 의사결정을 하고, 업무추진을 합니다. 주요 직원이 모두 {ExecutiveVm.text.director}가 될 필요는 없습니다. 오히려 {ExecutiveVm.text.director}를 지나치게 늘리는 경우, 번거로운 일이 많아집니다.
                                </p>
                                {ExecutiveVm.smartFormType !== 'ESTABLISHMENT_LIMITED_COMPANY' && //주식회사, 농업회사법인, 어업회사법인일 경우 
                                <>
                                    <Answer className={Qst.answer} iconText="A2">감사는 회사에 상근하면서 이사의 업무를 감시하는 사람입니다.</Answer>
                                    <p className={Qst.explain}>
                                    감사는 직접 회사를 운영하는 사람은 아니고, 필요에 따라 회사의 업무를 조사하고 주주총회에서 보고하는 역할을 맡습니다. 설립할 때만 잠시 참여하고, 사임할 사람이라면, 이사 보다는 감사를 선택하는 편이 좋습니다. 감사의 사임등기가 더 간편하기 때문입니다.
                                    </p>
                                </>
                                }
                            </div>
                        </div>
                    </>
                    }
                </Qna>
            </div>
        </div> 
    ));
}

export default ExecutivePosition;