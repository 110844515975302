import { useObserver } from 'mobx-react';
import BusinessPurposeRevision from './BusinessPurposeRevision/BusinessPurposeRevision';
import { Route } from "react-router-dom";

function BusinessPurposeStep() {
  return useObserver(() => (
    <>
        {/* 목적 */}
        <Route path="/step/changeRegistration/BusinessPurposeStep/BusinessPurposeRevision" component={BusinessPurposeRevision}></Route>
    </>
  ));
}

export default BusinessPurposeStep;