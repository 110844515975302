import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import Cst from '../../../FinalCheck/FinalCheck.module.scss';
import clsx from 'clsx';
import { addCommas } from '@common/module/replaceNumber';

function FreeIssueOfNewSharesStep(props) {
    return useObserver(() => (
        <>
            <div className={clsx(Cst.row)}> 
                <div className={Cst.dName}>무상증자의 재원</div>
                <div className={Cst.data}>
                    {props?.step?.resourceType || "-"}
                </div>
            </div>
            <div className={clsx(Cst.row)}> 
                <div className={Cst.dName}>무상증자할 금액</div>
                <div className={Cst.data}>
                    {addCommas(props?.step?.amount) + ' 원'|| "-"}
                </div>
            </div>
            <div className={clsx(Cst.row)}> 
                <div className={Cst.dName}>발행할 주식수</div>
                <div className={Cst.data}>
                    {addCommas(props?.step?.number) + ' 주'|| "-"}
                </div>
            </div>
            <div className={clsx(Cst.row)}> 
                <div className={Cst.dName}>배정기준일</div>
                <div className={Cst.data}>
                    {props?.step?.recordDateForNewSharesAllotment || "-"}
                </div>
            </div>
        </>
    ));
}
export default FreeIssueOfNewSharesStep;

