import { runInAction } from "mobx";
import portalModel from '@model/portalModel';
import ConfirmAndGuidanceModal from '@pages/ModalContent/establish/ConfirmAndGuidance/ConfirmAndGuidanceModal';
import smartformAppModel from "@model/smartformAppModel";

class viewModel {
    openModal() {
        const modal = ConfirmAndGuidanceModal;
        runInAction(() => {
            portalModel.modalContent = modal.modalContent;
            portalModel.btn = modal.btn ?? null;
            portalModel.closeBtn = modal.closeBtn ?? true;
        })
    }
    name() {
        const type = smartformAppModel.smartFormType;
        switch(type) {
            case 'ESTABLISHMENT_STOCK_COMPANY':
                return '주주'
            case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY':
                return '주주'
            case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY':
                return '주주'
            case 'ESTABLISHMENT_LIMITED_COMPANY':
                return '출자자'
            case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY':
                return '출자자'
        }
    }  
}

export default new viewModel();