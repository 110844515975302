import { useObserver } from 'mobx-react';
import BranchOfficeInstall from './BranchOfficeInstall/BranchOfficeInstall';
import { Route } from "react-router-dom";

function BranchOfficeInstallStep() {
    return useObserver(() => (
        <>
            {/* 신규지점 설치 */}
            <Route path="/step/changeRegistration/BranchOfficeInstallStep/BranchOfficeInstall" component={BranchOfficeInstall}></Route>
        </>
    ));
}

export default BranchOfficeInstallStep;