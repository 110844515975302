import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import add from '../../../asset/icon/btn_add_circle.svg';

const Btn = styled.button`    
    border: none;
    padding: 6px 39px 4px 0;
    background-color: transparent;
    background-image: url(${add});
    background-repeat: no-repeat;
    background-size: 31px;
    background-position: right 0 top 4px;
    color: #006ac3;
    font-size: 19px;
    font-weight: 500;
    letter-spacing: -0.76px;   
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
        padding: 10px 39px 7px 0;
    }
`;

function AddBtn(props) {
    return (
        <Btn type={props.type} className={props.className} style={props.style} onClick={(e) => props.onClick(e)}>
            {props.children}
        </Btn>
    );
};

AddBtn.propTypes = {
    /**
    * 버튼 타입
    */
    type: PropTypes.string,
    /**
    * class명
    */
    className: PropTypes.string,
    /**
    * 클릭 이벤트
    */
    onClick: PropTypes.func,
    /**
    * style 속성
    */
    style: PropTypes.object,
};

AddBtn.defaultProps = {
    type: 'button',
    className : null,
    style : {},
    onClick : () => {},
};

export default AddBtn;

