import { useObserver } from 'mobx-react';
import Cst from '../../../FinalCheck/FinalCheck.module.scss';
import st from './BranchOfficeRelocateStep.module.scss';
import clsx from 'clsx';

function BranchOfficeRelocateStep(props) {
    return useObserver(() => (
        <div className={clsx(Cst.row, st.branchOfficeRelocate)} style={{display:'block'}}>
            {props.step.branches.map((item,idx) => {
                return (
                    <div className={st.relocateBox} key={idx}>
                        <div className={st.originAddressBox}>
                            <div className={st.dName}>구지점 주소</div>
                            <div className={st.data}>
                                <div>{item.originBranchAddress}</div>
                            </div>
                        </div>
                        <div className={st.newAddressBox}>
                            <div className={st.dName}>신지점 주소</div>
                            <div className={st.data}>
                                <div key={idx} className={st.newBranch}>
                                    <div className={st.newBranchAddress}>주소 : {item.newBranchAddress}</div>
                                    <div className={st.newBranchName}>지점명 : {item.newBranchName}</div>
                                    <div className={st.newBranchCauseDate}>지점 이전 일자 : {item.causeDate}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                )
            })}
        </div>
    ));
}

export default BranchOfficeRelocateStep;