import { useObserver } from 'mobx-react';
import Cst from '../../../FinalCheck/FinalCheck.module.scss';
import st from './RepresentativeCheck.module.scss';
import clsx from 'clsx';
import { Fragment } from 'react';

function RepresentativeCheck(props) {

    let addressSameCnt = 0;
    let marginTopYn = false;
    props.step.representatives.forEach((item, idx) => {
        if(item.addressSameYn) {
            addressSameCnt++;
        }
    })
    if(addressSameCnt === 0) {
        marginTopYn = true;
    }

    return useObserver(() => (
        <div className={clsx(Cst.row, st.representativeRow)}>
            <div className={st.representative}>
                {props.step.representatives.map((item, idx) => {
                    return (
                        <Fragment key={idx}>
                            {item.addressSameYn &&
                                <div className={st.noChangeRepresentative}>
                                    <div className={clsx(Cst.dName, st.dName)} style={{width:'148px'}}>
                                        <div className={st.name}>{item.koreanName}</div>
                                        <div className={st.position}>{item.originPosition}</div>
                                    </div>
                                    <div className={clsx(Cst.data, st.data)} style={{fontWeight:'400'}}>
                                        <div className={st.birth}>{item.foreignerYn ? '생년월일' : '주민등록번호'} : {item.birth}</div>
                                        <div className={st.address}>주소 : 등기부상 주소 변동 없음</div>
                                    </div>
                                </div>
                            }
                        </Fragment>
                    )
                })}
            </div>
            <div className={st.representative} style={marginTopYn ? {marginTop:'0'} : null}>
                {props.step.representatives.map((item, idx) => {
                    return (
                        <Fragment key={idx}>
                            {item.addressSameYn === false &&
                                <div className={st.changeRepresentative}>
                                    <div className={clsx(Cst.dName, st.dName)} style={{width:'148px'}}>
                                        <div className={st.name}>{item.koreanName}</div>
                                        <div className={st.position}>{item.originPosition}</div>
                                    </div>
                                    <div className={clsx(Cst.data, st.data)} style={{fontWeight:'400'}}>
                                        <div className={st.birth}>{item.foreignerYn ? '생년월일' : '주민등록번호'} : {item.birth}</div>
                                        <div className={st.address}>주소 : <span>등기부상 대표님의 집주소 변경됨</span></div>
                                        <div className={st.newAddressInfo}>
                                            {item.newAddresses.map((item2, idx2) => {
                                                return (
                                                    <div key={idx2} className={st.addressBox}>
                                                        <div className={st.newAddress}>{item2.newAddress}</div>
                                                        <div className={st.causeDate}>{item.foreignerYn ? '주소변경 일자' : '전입신고 일자'} : {item2.causeDate}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        {item.has등기원인_대표자주소 && 
                                            <div className={st.alert}>
                                                대표님의 집 주소가 변경되었으므로, 추가적인 과태료 발생을 방지하기 위하여 이번에 함께 대표자 주소변경등기를 진행해드리겠습니다. 대표님 집주소 변경등기 추가 공과금, 수수료는 별도로 안내해 드리겠습니다.    
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </Fragment>
                    )
                })}
            </div>
        </div>
    ));
}

export default RepresentativeCheck;