import StandbyPaidInCapitalIncreaseStepModel from '@model/changeRegistration/StandbyPaidInCapitalIncreaseStepModel';
import { runInAction, observable } from 'mobx';
import { onlyNumber } from '@common/module/replaceNumber';
import regExp from '@common/module/regExp';

class ForeignerPersonVm {
    setEnglishName(e, idx) {
        runInAction(() => {
            StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].englishName = e.target.value;
        })
    }
    setKoreanName(e, idx) {
        runInAction(() => {
            StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanName = e.target.value;
        })
    }
    setNationalityType(e, idx) {
        runInAction(() => {
            StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].nationalityType = e.target.value;
        })
    }
    setNationalityOthers(e, idx) {
        runInAction(() => {
            StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].nationalityOthers = e.target.value;
        })
    }
    setYear(e, idx){
        runInAction(() => {
            StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].birth.year = onlyNumber(e.target.value);
        })
    }
    setMonth(e, idx){
        runInAction(() => {
            StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].birth.month = onlyNumber(e.target.value);
        })
    }
    monthBlur(e,idx) {
        let month = e.target.value;
        if(month && month.length===1){
            e.target.value = "0" + month;
        }
        runInAction(() => {
            StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].birth.month = e.target.value;
        })
    }
    setDay(e, idx){
        runInAction(() => {
            StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].birth.day = onlyNumber(e.target.value);
        })
    }
    dayBlur(e,idx) {
        let day = e.target.value;
        if(day && day.length===1){
            e.target.value = "0" + day;
        }
        runInAction(() => {
            StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].birth.day = e.target.value;
        })
    }
    displayBirthErrorMessage(idx, type) { 
        const year = StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].birth.year;
        const month = StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].birth.month;

        if(type === 'month') {
            if(year && regExp.year().test(year)) { //연도가 제대로 입력되었을 때, 월에 대한 error 메시지
                return true;
            } else {
                return false;
            }
        } else if(type === 'day') {
            if(year && regExp.year().test(year) && month && regExp.month().test(month)) { // 연도, 월이 제대로 입력 되었을 때, 일(날짜)에 대한 error 메시지
                return true;
            } else {
                return false;
            }
        }
    }
    setResidenceRegistrationYn(e, idx) {
        runInAction(() => {
            const residenceRegistrationYn = e.target.value === "true";
            StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].residenceRegistrationYn = residenceRegistrationYn;

            const koreanAddress = StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanAddress;
            const foreignAddress = StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].foreignAddress;
        })
    }
    displayResidenceRegistrationYn(idx) {
        let displayYn = false;
        const residenceRegistrationYn = StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].residenceRegistrationYn;
        if(residenceRegistrationYn === null) {
            displayYn = true;
        } 
        return displayYn;
    }
    setKoreanAddress(e, idx) {
        runInAction(() => {
            StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanAddress = e.target.value;
        })
    }
    setEnglishAddress(e, idx) {
        runInAction(() => {
            StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].foreignAddress = e.target.value;
        })
    }
}

export default new ForeignerPersonVm();