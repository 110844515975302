import { observable, runInAction } from 'mobx';

const addStockOptionStepModel = observable({
    helpmeRegulationYn : null,
    ventureYn : null,
    regulation : "",
    files: [],
    uploadFiles: [], //업로드 파일
    deleteIds: [], //삭제 파일 id 목록
    setAddStockOption(data) {
        runInAction(() => {
            this.helpmeRegulationYn = data?.helpmeRegulationYn ?? null
            this.ventureYn = data?.ventureYn ?? null
            this.regulation = data?.regulation ?? ""
            this.files = data?.files || []
            this.uploadFiles = []
        })
    }
});

export default addStockOptionStepModel;