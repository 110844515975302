import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import BeforeFinalCheckVm from './BeforeFinalCheckVm';
import SignleQuestionHeader from '@commonComponents/SingleQuestionHeader/SingleQuestionHeader';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import scrollToAnimate from '@common/module/scrollToAnimate';
import FocusBox from '@commonComponents/FocusBox/FocusBox';
import st from '../../FinalCheck/FinalCheck.module.scss';
import CorpNameStep from '../steps/CorpNameStep/CorpNameStep';
import HeadOfficeRelocationStep from '../steps/HeadOfficeRelocationStep/HeadOfficeRelocationStep';
import PaidInCapitalIncreaseStep from '../steps/PaidInCapitalIncreaseStep/PaidInCapitalIncreaseStep';
import NumOfSharesToBeIssuedStep from '../steps/NumOfSharesToBeIssuedStep/NumOfSharesToBeIssuedStep';
import ExecutiveMemberChangeStep from '../steps/ExecutiveMemberChangeStep/ExecutiveMemberChangeStep';
import BusinessPurposeStep from '../steps/BusinessPurposeStep/BusinessPurposeStep';
import AddStockOptionStep from '../steps/AddStockOptionStep/AddStockOptionStep';
import AnnounceMethodStep from '../steps/AnnounceMethodStep/AnnounceMethodStep';
import BranchOfficeDeleteStep from '../steps/BranchOfficeDeleteStep/BranchOfficeDeleteStep';
import BranchOfficeRelocateStep from '../steps/BranchOfficeRelocateStep/BranchOfficeRelocateStep';
import BranchOfficeChangeStep from '../steps/BranchOfficeChangeStep/BranchOfficeChangeStep';
import BranchOfficeInstallStep from '../steps/BranchOfficeInstallStep/BranchOfficeInstallStep';
import BoardMeetingSealProvidingStep from '../steps/BoardMeetingSealProvidingStep/BoardMeetingSealProvidingStep';
import StockholdersMeetingSealProvidingStep from '../steps/StockholdersMeetingSealProvidingStep/StockholdersMeetingSealProvidingStep';
import RepresentativeAddressChangeStep from '../steps/RepresentativeAddressChangeStep/RepresentativeAddressChangeStep';
import TCAgreeAndDeliveryAddressStep from '../steps/TCAgreeAndDeliveryAddressStep/TCAgreeAndDeliveryAddressStep';
import FreeIssueOfNewSharesStep from '../steps/FreeIssueOfNewSharesStep/FreeIssueOfNewSharesStep';
import CapitalIncreaseStep from '../steps/CapitalIncreaseStep/CapitalIncreaseStep';
import ParValueDivisionStep from '../steps/ParValueDivisionStep/ParValueDivisionStep';
import LiquidationStep from '../steps/LiquidationStep/LiquidationStep';
import NextBtn from '@comComponents/atoms/Button/LastStepBtn/LastStepBtn';
import StockCompanySealStep from '../steps/StockCompanySealStep/StockCompanySealStep';
import StandbyParValueDivisionStep from '../steps/StandbyParValueDivisionStep/StandbyParValueDivisionStep';
import StandbyFreeIssueOfNewSharesStep from '../steps/StandbyFreeIssueOfNewSharesStep/StandbyFreeIssueOfNewSharesStep';
import StandByStockholderMeetingInfoStep from '../steps/StandByStockholderMeetingInfoStep/StandByStockholderMeetingInfoStep';
import StandByBoardMeetingInfoStep from '../steps/StandByBoardMeetingInfoStep/StandByBoardMeetingInfoStep';
import StandbyPaidInCapitalIncreaseStep from '../steps/StandbyPaidInCapitalIncreaseStep/StandbyPaidInCapitalIncreaseStep';
import ConvertibleBondStep from '../steps/ConvertibleBondStep/ConvertibleBondStep';

import { toJS } from 'mobx';

function RowContent(props) {
    useEffect(() => {
      if(props.checkPage === BeforeFinalCheckVm.state.checkPage && BeforeFinalCheckVm.state.checkPage !== "start") {
        scrollToAnimate(window.pageYOffset, window.pageYOffset+moveScroll.current.getBoundingClientRect().top-180, 700)
      }
    }, [BeforeFinalCheckVm.state.checkPage])
  
    const moveScroll = useRef(null);
  
    let className = st.start;
    if(props.checkPage !== "start") {
      className = st.focus;
    }
    
    return useObserver(() => (
      <div className={props.checkPage === BeforeFinalCheckVm.state.checkPage ? className : null} ref={moveScroll}>
        {props.children}
      </div>
    ));
  }

function BeforeFinalCheck() {
    useEffect(() => {
        BeforeFinalCheckVm.loadFinalCheck();
    }, [])
    return useObserver(() => (
        <>
          {BeforeFinalCheckVm.steps.length !== 0 &&
          <>
            <SignleQuestionHeader>
                <h1 className="title">최종제출 전 내용 확인</h1>
                <div className="explain">최종제출 후 수정이 불가능 합니다. 내용을 꼼꼼히 확인해 주세요.</div>
            </SignleQuestionHeader>
            <div className="mainContent">
                <Question>
                    {BeforeFinalCheckVm.steps.length !== 0 && 
                        <RowContent checkPage="start">
                            <FocusBox page={BeforeFinalCheckVm.Question('start')} checkPage={BeforeFinalCheckVm.state.checkPage} btnYes={() => BeforeFinalCheckVm.btnYes()} btnSubmit={() => BeforeFinalCheckVm.submit()}/>
                        </RowContent>
                    }
                    <ContentBox style={BeforeFinalCheckVm.steps.length !== 0 ? null : {height:window.innerHeight}}>
                      <div className={st.content}>
                        {BeforeFinalCheckVm.steps.map((step, index) => (
                          <div className={st.table} key={index}>
                            {!BeforeFinalCheckVm.state.checkPage && 
                              <Link to={step.editUrl}><button className={st.reviseBtn}>수정하기</button></Link>
                            }
                            <div className={st.title}>{step.title}</div>
                            <div className={st.info}>
                              <RowContent checkPage={step.code}>
                                <FocusBox page={BeforeFinalCheckVm.Question(step.code)} checkPage={BeforeFinalCheckVm.state.checkPage} btnYes={() => BeforeFinalCheckVm.btnYes()} btnBack={() => BeforeFinalCheckVm.btnBack()} />
                                {/* 헬프미 */}
                                { step.code === "CorpNameStep" && <CorpNameStep step={step}/> }
                                { step.code === "HeadOfficeRelocationStep" && <HeadOfficeRelocationStep step={step}/> }
                                { step.code === "PaidInCapitalIncreaseStep" && <PaidInCapitalIncreaseStep step={step}/> }
                                { step.code === "NumOfSharesToBeIssuedStep" && <NumOfSharesToBeIssuedStep step={step}/> }
                                { step.code === "ExecutiveMemberChangeStep" && <ExecutiveMemberChangeStep step={step}/> }
                                { step.code === "BusinessPurposeStep" && <BusinessPurposeStep step={step}/> }
                                { step.code === "AddStockOptionStep" && <AddStockOptionStep step={step}/> }
                                { step.code === "AnnounceMethodStep" && <AnnounceMethodStep step={step}/> }
                                { step.code === "BranchOfficeDeleteStep" && <BranchOfficeDeleteStep step={step}/> }
                                { step.code === "BranchOfficeRelocateStep" && <BranchOfficeRelocateStep step={step}/> }
                                { step.code === "BranchOfficeChangeStep" && <BranchOfficeChangeStep step={step}/> }
                                { step.code === "BranchOfficeInstallStep" && <BranchOfficeInstallStep step={step}/> }
                                { step.code === "BoardMeetingSealProvidingStep" && <BoardMeetingSealProvidingStep step={step}/> }
                                { step.code === "StockholdersMeetingSealProvidingStep" && <StockholdersMeetingSealProvidingStep step={step}/> }
                                { step.code === "RepresentativeAddressChangeStep" && <RepresentativeAddressChangeStep step={step}/> }
                                { step.code === "TCAgreeAndDeliveryAddressStep" && <TCAgreeAndDeliveryAddressStep step={step}/> }
                                { step.code === "FreeIssueOfNewSharesStep" && <FreeIssueOfNewSharesStep step={step}/> }
                                { step.code === "CapitalIncreaseStep" && <CapitalIncreaseStep step={step}/> }
                                { step.code === "ParValueDivisionStep" && <ParValueDivisionStep step={step}/> }
                                { step.code === "LiquidationStep" && <LiquidationStep step={step}/> }
                                { step.code === "StockCompanySealStep" && <StockCompanySealStep step={step}/> }
                                { step.code === "ConvertibleBondStep" && <ConvertibleBondStep step={step}/> }
                                {/* 스탠바이 */}
                                { step.code === "StandbyParValueDivisionStep" && <StandbyParValueDivisionStep step={step}/> }
                                { step.code === "StandbyFreeIssueOfNewSharesStep" && <StandbyFreeIssueOfNewSharesStep step={step}/> }
                                { step.code === "StandByStockholderMeetingInfoStep" && <StandByStockholderMeetingInfoStep step={step}/> }
                                { step.code === "StandByBoardMeetingInfoStep" && <StandByBoardMeetingInfoStep step={step}/> }
                                { step.code === "StandbyPaidInCapitalIncreaseStep" && <StandbyPaidInCapitalIncreaseStep step={step}/> }
                              </RowContent>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className={st.finalSubmitBtn}>
                        <div className={st.explain}>
                          <div>※ 입력내용을 꼼꼼히 확인하고, [최종 제출하기] 버튼을 눌러주세요.</div>
                          <div>※ 정보 수정을 원하실 경우, [정보 수정하기] 버튼을 눌러서 수정해 주세요.</div>
                          <div>
                            ※ [최종제출] 이후에는 서류작성, 공과금납부가 순차적으로 진행됩니다.
                            <ul className={st.infoList}>
                              <li>
                                  진행과정에서 특이사항이 있다면 채팅방으로 안내드릴 예정이며,<br/>특이사항이 없는 경우, 필요 서류 작성후 바로 서명요청 또는 도장날인 요청을 드리겠습니다.
                              </li>
                            </ul>
                          </div>
                          <div>※ [최종 제출] 이후에 정보수정을 원하실 경우, 채팅 상담을 통해 요청해 주세요.</div>
                          <div>
                            ※ 정보 수정 수수료 안내
                            <ul className={st.infoList}>
                              <li>
                                결제 ~ 전자서명 요청 전 / 도장찍을 서류 발송 전 : 무료로 수정 가능
                                <ul className={st.balanceCertificateList}>
                                  <li>다만 전자서명 요청 전 / 도장찍을 서류 발송 전에 최종확인 절차를 거친 경우, 최종확인을 한 때부터는 33,000원/1회의 수정 수수료가 발생합니다.</li>
                                </ul>  
                              </li>
                              <li>전자서명 요청 후 / 도장찍을 서류 발송 후 ~ 등기 접수 전 : 33,000원/1회 (공증을 이미 진행한 경우, 수정 수수료에 덧붙여 공증료가 추가로 들 수 있습니다.)</li>
                              <li>등기 접수 후 : 수정 불가능</li>
                            </ul>
                          </div>
                        </div>
                        <div className={st.sumbitBtn}>
                          <NextBtn type="submit" onClick={() => BeforeFinalCheckVm.submit()} disabled={BeforeFinalCheckVm.state.submitBtnDisabledYn}>최종제출</NextBtn>
                        </div>
                      </div>
                    </ContentBox>
                </Question>
            </div>
          </> 
          }
            
        </>
    ));
}
export default BeforeFinalCheck;