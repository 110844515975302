import st from './ExecutiveModal.module.scss';
import { runInAction } from "mobx";
import portalModel from '@model/portalModel';

const modal = {
    modalContent: 
        <div className={st.Modal}>
            <div className={st.title} style={{width:'400px'}}>자본금을 <span className={st.bold}>10억 원 이상</span>으로 설정하였으므로, <span className={st.bold}>이사 3인 이상, 감사 1인 이상</span>을 입력해주세요.</div>
            <div className={st.modalContent}>
                <p className={st.content}>
                    상법상 자본금 10억 원 이상인 경우, 반드시 이사 3인 이상, 감사 1인 이상이 필요합니다. 따라서 상법에 맞게 이사, 감사를 추가로 입력해주시거나, 자본금을 10억 원 미만으로 수정해주세요.
                </p>
            </div>
        </div>
    ,
    btn: [{title: '확인', type: 'positive', event: ()=> {portalModel.setModalClose()}}]
}

export default modal;