import { post, get } from '@common/module/httpRequest';
import smartformAppModel from '@model/smartformAppModel';
import changeRegistrationModel from '@model/changeRegistrationModel';
import passwordModel from '@model/changeRegistration/passwordModel';
import { runInAction } from 'mobx';
import { _alert, _confirm } from "@model/dialogModel";

class passwordService {
    async setPassword(check, password) {
        const res = await post(`/changeRegistration/${smartformAppModel.smartformId}/setPassword`, {
            check : check,
            password : password
        }, {});
        const token = res.data;
        return token;
    }
    
    async checkPassword(password) {
        try {
            const res = await post(`/changeRegistration/${smartformAppModel.smartformId}/checkPassword`, {
                password : password
            }, {});
            const token = res.data;
            return token;
        } catch(e) {
            return ;
        }
    }
    async resetPassword() {
        passwordModel.sendEmailLoading = true;
        try {
            const res = await post(`/changeRegistration/${smartformAppModel.smartformId}/sendInitPasswordEmail`, {
            }, {});
            passwordModel.sendEmailLoading = false;
            _alert('이메일을 발송했습니다.');
        } catch(e) {
            passwordModel.sendEmailLoading = false;
            _alert('이메일발송이 실패했습니다. 채널톡으로 문의주세요.');
            return ;
        }
    }
}
export default new passwordService();