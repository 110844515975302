import { useObserver } from 'mobx-react';
import ShareholderInvestmentVm from './ShareholderInvestmentVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './ShareholderInvestment.module.scss';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import {addCommas, percent} from '@common/module/replaceNumber';
import { numToKorean } from 'num-to-korean';
import React from 'react';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';

function ShareholderInvestment() {
    return useObserver(() => (
        <form onSubmit={(e) => ShareholderInvestmentVm.submit(e)}>
            <input type="submit" value="submit" id="stepForm" style={{display:"none"}}/>
            <Question>
                <ContentBox className={Qst.spaceBetween}>
                    <div className={Qst.infoSection}>
                        <div className={Qst.titleWrap}>
                            <h2 className={Qst.title}>
                                <span className={Qst.bold}>{ShareholderInvestmentVm.text.name}들이 각각 투자하는 자본금을 입력</span>해 주세요.
                            </h2>
                            {ShareholderInvestmentVm.smartFormType !== 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY' ?
                            <div className={Qst.titleExplain}>
                                {ShareholderInvestmentVm.text.parValue}은 {addCommas(ShareholderInvestmentVm.parValue)}원 이므로, {addCommas(ShareholderInvestmentVm.parValue)}원 단위로 입력하실 수 있습니다.
                            </div>
                            : null
                            }
                        </div>
                        <table className={st.table}>
                            <colgroup>
                                <col width="184px"/>
                                <col width="222px"/>
                                <col width="154px"/>
                            </colgroup>
                            <thead className={st.thead}>
                                <tr>
                                    <th colSpan="2" className={st.investment}>자본금(투자금)</th>
                                    <th className={st.investmentPercent}>지분율</th>
                                </tr>
                            </thead>
                            <tbody className={st.tbody}>
                                {ShareholderInvestmentVm.investments.map((item, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>{ShareholderInvestmentVm.shareholdersName(idx)}</td>
                                            <td>
                                                <input type="hidden" name={`investments[${idx}].id`} value={item.id}/>
                                                <InputText style={{width:'222px', height:'52px', fontSize:'18px'}} placeholder="0" placeholderAlign="right"
                                                name={`investments[${idx}].investment`} value={addCommas(String(item.investment))}
                                                onChange={(e) => ShareholderInvestmentVm.setInvestment(e,idx)} 
                                                errorText={<Error name={`investments[${idx}].investment`} style={{width:'375px', textAlign:'left'}} value={item.investment} 
                                                errorCase={ShareholderInvestmentVm.smartFormType !== 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY' ? {
                                                    required: '자본금을 입력해 주세요.',
                                                    validate: {
                                                        func: ShareholderInvestmentVm.validateFunc(idx),
                                                        message: `액면금인 ${addCommas(ShareholderInvestmentVm.parValue)}원 단위로 입력해 주세요.`
                                                    },
                                                    min: {
                                                        value: ShareholderInvestmentVm.parValue,
                                                        message: `액면금 이상의 금액(${addCommas(ShareholderInvestmentVm.parValue)}원)을 입력해 주세요.`
                                                    }} 
                                                    :
                                                    {required: '자본금을 입력해 주세요.'}
                                                }/>} readNumber={true} />
                                            </td>
                                            <td>{percent(ShareholderInvestmentVm.investmentPercent(idx))}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                            <tfoot className={st.tfoot}>
                                <tr>
                                    <td style={{"fontWeight":"500"}}>설립 자본금</td>
                                    <td>
                                        <div className={st.companyInvestment}>
                                            <div className={ShareholderInvestmentVm.establishInvestment ? null : st.sum} style={{"fontWeight":"500"}}>
                                                {addCommas(ShareholderInvestmentVm.establishInvestment)}
                                            </div>
                                            <div className={st.numToKorean} style={{"fontWeight":"500"}}>
                                                {ShareholderInvestmentVm.establishInvestment? numToKorean(ShareholderInvestmentVm.establishInvestment) : '영'}&nbsp;원
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className={Qst.qnaSection}>
                        {(ShareholderInvestmentVm.smartFormType === 'ESTABLISHMENT_STOCK_COMPANY' || ShareholderInvestmentVm.smartFormType === 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY' || ShareholderInvestmentVm.smartFormType === 'ESTABLISHMENT_FISHERY_STOCK_COMPANY') && //주식회사, 농업회사법인, 어업회사법인일 경우
                        <Qna tip='주주 투자금 설정방법' className={Qst.qnaTitle}>
                            <div className={Qst.qnaBox}>
                                <QText>왜 액면금 단위로 자본금(투자금)을 입력할 수 있나요?</QText>
                                <div className="answerBox">
                                    <Answer className={Qst.answer}>액면금 X 주식수 &#61; 자본금(투자금)이 되므로, 액면금 단위로 자본금(투자금)을 입력할 수 있습니다.</Answer>
                                    <p className={Qst.explain}>
                                        상법상 주식수는 정수여야 하고, 소수점을 허용하지 않습니다.
                                    </p>
                                </div>
                            </div>
                        </Qna>
                        }
                        {(ShareholderInvestmentVm.smartFormType === 'ESTABLISHMENT_LIMITED_COMPANY' || ShareholderInvestmentVm.smartFormType === 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY') && //유한회사, 유한책임회사일 경우
                        <Qna tip='출자자 투자금 설정방법' className={Qst.qnaTitle}>
                            <div className={Qst.qnaBox}>
                                {ShareholderInvestmentVm.smartFormType === 'ESTABLISHMENT_LIMITED_COMPANY' ? //유한회사일 경우
                                <>
                                    <QText>왜 출자 1좌의 금액 단위로 자본금(투자금)을 입력할 수 있나요?</QText>
                                    <div className="answerBox">
                                        <Answer className={Qst.answer}>출자 1좌의 금액 &times; 출자좌수 &#61; 자본금(투자금)이 되므로, 출자 1좌의 금액 단위로 자본금(투자금)을 입력할 수 있습니다.</Answer>
                                    </div>
                                </>
                                :
                                <>
                                    <QText>원 단위로 자본금(투자금)을 정할 수 있나요?</QText> 
                                    <div className="answerBox">
                                        <Answer className={Qst.answer}>네. 유한책임회사는 출자자 투자금을 원단위로도 정할 수 있습니다.</Answer>
                                    </div>
                                </>
                                }
                            </div>
                        </Qna>
                        }
                        <Qna tip="자본금 설정방법" className={Qst.qnaTitle} style={{marginTop:'57px'}}>
                        <div className={Qst.qnaBox}>
                            <QText>자본금이란 무엇인가요?</QText>
                            <div className="answerBox">
                                <Answer className={Qst.answer}>자본금이란 회사를 설립하면서, {ShareholderInvestmentVm.text.name}들이 회사에 내는 자금입니다.</Answer>
                                <p className={Qst.explain}>
                                    다른 말로 출자금이라고 표현합니다. 자본금은 {ShareholderInvestmentVm.text.name} 중 1명의 개인통장에 넣어두었다가, 나중에 회사를 설립하고, 회사통장을 만든 후에 이체를 해주시면 됩니다.
                                </p>
                            </div>
                        </div>
                        <div className={Qst.qnaBox}>
                            <QText>자본금에 따라 세금이 달라지나요?</QText>
                            <div className="answerBox">
                                <Answer className={Qst.answer}>네. 자본금에 따라 설립시 납부하는 세금이 달라집니다.</Answer>
                                <p className={Qst.explain}>
                                    세금은 자본금 2,800만 원까지 동일하고, 초과시 자본금에 비례하여 결정됩니다. 최초 간단견적을 받을 때 입력한 자본금과 다른 자본금을 입력하는 경우, 최종 견적이 달라질 수 있습니다.
                                </p>
                            </div>
                        </div>
                        <div className={Qst.qnaBox}>
                            <QText>자본금은 얼마가 적당할까요?</QText>
                            <div className="answerBox">
                                <Answer className={Qst.answer}>자본금 100~1,000만 원으로 설립하는 경우가 많습니다.</Answer>
                                <p className={Qst.explain}>
                                    다른 방법으로는 약 3달치 운영비용을 자본금으로 정하기도 합니다. 한 번 자본금으로 설정하게 되면, 나중에 회사에서 자본금을 다시 인출하기 어려워지므로, 적당한 금액으로 선택해 주세요. 참고로 업종에 따라서는 최소로 자본금을 ~원 이상으로 설정해야 하는 경우가 있습니다.
                                </p>
                                <LikeBtn className={Qst.likeBtn} href="https://info.help-me.kr/hc/ko/articles/360022125351" target="_blank">업종별 최소자본금 총정리 바로가기</LikeBtn>
                            </div>
                        </div>
                        <div className={Qst.qnaBox}>
                            <QText>자본금을 100원 등으로 아주 적게 정해도 되나요?</QText>
                            <div className="answerBox">
                                <Answer className={Qst.answer}>자본금을 100원으로 정하는 경우, 은행계좌개설이 거절될 수 있습니다.</Answer>
                                <p className={Qst.explain}>
                                    이론적으로는 자본금 100원으로도 법인설립이 가능합니다. 다만 은행에서는 자본금이 아주 소액인 경우, 제대로 사업을 할 의사가 없다고 보아서 계좌개설을 거절할 우려가 있고, 세무서에서도 사업자 등록을 거절할 우려가 있습니다. 따라서 자본금을 100만 원 이상을 설정하는 편을 추천드립니다.
                                </p>
                            </div>
                        </div>
                        </Qna>
                    </div>
                </ContentBox>
            </Question>
        </form>
    ));
}

export default ShareholderInvestment;
