import StandByBoardMeetingInfoStepModel from '@model/changeRegistration/StandByBoardMeetingInfoStepModel';
import stepService from "@service/stepService";
import formDataToJson, { errorFocus, findPos }  from '@common/module/submit';
import { runInAction, observable } from 'mobx';
import portalModel from '@model/portalModel';
import BoardMeetingAttendModal from '@pages/ModalContent/changeRegistration/StandByBoardMeetingBasicInfo/BoardMeetingAttendModal';

class StandByBoardMeetingBasicInfoVm {
    submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error === false) {
            let param = formDataToJson(e.target).data;
            if(!Array.isArray(param.participants)) {
                param.participants = [param.participants];
            }
            if(this.checkState === "부족") {
                let modal = BoardMeetingAttendModal;
                runInAction(() => {
                    portalModel.modalContent = modal.modalContent;
                    portalModel.btn = modal.btn ?? null;
                    portalModel.closeBtn = modal.closeBtn ?? true;
                })
                return;
            }
            stepService.saveStep(param);
        }
    }
    get chairmanId() {
        return StandByBoardMeetingInfoStepModel.chairmanId;
    }
    get chairmanCandidates() {
        return StandByBoardMeetingInfoStepModel.chairmanCandidates;
    }
    get executiveMembers() {
        return StandByBoardMeetingInfoStepModel.executiveMembers;
    }
    setChairmanId(e) {
        const id = Number(e.target.value);
        runInAction(() => {
            StandByBoardMeetingInfoStepModel.chairmanId = id;
            StandByBoardMeetingInfoStepModel.executiveMembers.forEach((item) => {
                if(item.id === id) {
                    item.attendYn = true;
                }
            })
        })
    }
    get allChecked() {
        let allChecked = true;
        StandByBoardMeetingInfoStepModel.executiveMembers.forEach((executiveMember) => {
            if(executiveMember.attendYn === false) {
                allChecked = false;
            }
        })
        return allChecked;
    }
    setCheckedAll(e) {
        const checked = e.target.checked;
        runInAction(() => {
            StandByBoardMeetingInfoStepModel.executiveMembers.forEach((executiveMember) => {
                executiveMember.attendYn = checked
            })
            if(checked === false) {
                StandByBoardMeetingInfoStepModel.chairmanId = null;
            }
        })
    }
    setChecked(e, idx) {
        const checked = e.target.checked
        const chairmanId = StandByBoardMeetingInfoStepModel.chairmanId;
        const id = StandByBoardMeetingInfoStepModel.executiveMembers[idx].id || null;
        runInAction(() => {
            StandByBoardMeetingInfoStepModel.executiveMembers[idx].attendYn = e.target.checked;
            if(checked === false && chairmanId === id) {
                StandByBoardMeetingInfoStepModel.chairmanId = null;
            }
        })
    } 
    get checkState() {
        let state = "부족";

        const total = StandByBoardMeetingInfoStepModel.executiveMembers.length;
        let attendExecutiveCnt =  StandByBoardMeetingInfoStepModel.executiveMembers.filter(elem => elem.attendYn === true).length;
        
        if(total/2 < attendExecutiveCnt) {
            state = "충분"
        }
        
        return state;
    }
}

export default new StandByBoardMeetingBasicInfoVm();