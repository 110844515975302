import { runInAction, observable } from "mobx";
import etcInfoModel from "@model/establish/etcInfoModel";
import stepService from "@service/stepService";
import formDataToJson, { findPos } from '@common/module/submit';
import moduleService from "@service/moduleService";
import { _alert } from "@model/dialogModel";

class viewModel {
    constructor() {
        this.state = observable({
            homepageAddressDisabledYn: false
        });
    }
    submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            if(etcInfoModel.option.announcementMethodChangeYn && etcInfoModel.option.homepageAnnouncementYn) { //공고방법 직접 지정 선택 -> 홈페이지 공고방법 선택 했을 경우
                if(etcInfoModel.option.validUrlCheckType) {
                    if(etcInfoModel.option.validUrlCheckType === 'CANNOT_ACCESS') {
                        return _alert('접속 불가능한 홈페이지 주소입니다.')
                    }
                    param.validUrlCheckType = etcInfoModel.option.validUrlCheckType;
                } else {
                    const urlCheck = setInterval(() => {
                        clearInterval(urlCheck);
                        let top = findPos(document.querySelector('.homepageAddress')) - 185;
                        window.scroll(0, top)
                    }, 30)
                    return _alert('홈페이지 접속확인을 해주세요.')
                }
            }
            stepService.saveStep(param);            
        }
    }
    get stockOptionYn() {
        return etcInfoModel.option.stockOptionYn;
    }
    setStockOptionYn(e) {
        etcInfoModel.option.stockOptionYn = e.target.checked;
    }
    get shareTransferRestrictionsYn() {
        return etcInfoModel.option.shareTransferRestrictionsYn;
    }
    setShareTransferRestrictionsYn(e) {
        etcInfoModel.option.shareTransferRestrictionsYn = e.target.checked;
    }
    get announcementMethodChangeYn() {
        return etcInfoModel.option.announcementMethodChangeYn
    }
    setAnnouncementMethodChangeYn(e) {
        etcInfoModel.option.announcementMethodChangeYn = e.target.checked;
    } 
    get homepageAnnouncementYn() {
        return etcInfoModel.option.homepageAnnouncementYn;
    }
    setHomepageAnnouncementYn(e) {
        etcInfoModel.option.homepageAnnouncementYn = e.target.value === "true";
    }
    get homepageAddress() {
        return etcInfoModel.option.homepageAddress;
    }
    setHomepageAddress(e) {
        runInAction(() => {
            etcInfoModel.option.homepageAddress = e.target.value;
            etcInfoModel.option.validUrlCheckType = null;
        })
    }
    get optionInfo() {
        return etcInfoModel.option;
    }
    async validUrlCheckType() {
        if(etcInfoModel.option.homepageAddress) {
            this.state.homepageAddressDisabledYn = true;
            etcInfoModel.option.validUrlCheckType = await moduleService.homepageAddressValidationCheck(etcInfoModel.option.homepageAddress);
            this.state.homepageAddressDisabledYn = false;
        } else {
            await _alert('홈페이지 주소를 입력해주세요.')
        }
    }
    get newspaperCompany() {
        return etcInfoModel.option.newspaperCompany;
    }
    setNewspaperCompany(e) {
        etcInfoModel.option.newspaperCompany = e.target.value;
    }
    get prospectiveSocialEnterpriseYn() {
        return etcInfoModel.option.prospectiveSocialEnterpriseYn;
    }
    setProspectiveSocialEnterpriseYn(e) {
        etcInfoModel.option.prospectiveSocialEnterpriseYn = e.target.checked;
    }
}

export default new viewModel();